class FileUploadHelper {

  
  static splitBase64URL( base64FileUrl ) {
    const mimeType = base64FileUrl.substring( 5, base64FileUrl.indexOf( ';' ) )
    const data = base64FileUrl.substring( base64FileUrl.indexOf( ',' ) + 1 )
    return { mimeType, data }
  }


  static chunkBase64String( str, chunkLength = 100000 ) {
    const arr = []
    for( let idx = 0; idx < str.length; idx += chunkLength ) {
      arr.push( str.substring( idx, idx + chunkLength ) )
    }
    return arr
  }


}

export default FileUploadHelper