import Exim from '@/models/Exim.js'
import EknoStatDimensionQueryVariable from './EknoStatDimensionQueryVariable.js'
import Computable from './Computable'

class EknoStatDimension extends Exim {


	id = null;
	_name = null;
	
	// Input param: Course
	_courseID = null
	_courseVersionID = null

	// Definitions of DB query variables
	queryVariables = null        // Map { String name => EknoStatDimensionQueryVariable }
	queryVariableNames = []      // Vue display only
	queryVariableList = []       // Vue display only

	// Definitions of output variables
	outputVariables = null       // Map { str varName => Computable, ... }
	outputVariableNames = []     // Vue display only
	outputVariableList = []      // Vue display only


	// Client-only
	unsavedChanges = false



	constructor() {
		super()
		this.queryVariables = new Map()
		this.outputVariables = new Map()
	}


	get name() { return this._name }
	set name( val ) {
		this._name = val
		this.unsavedChanges = true
	}

	get courseID() { return this._courseID }
	set courseID( val ) {
		this._courseID = val
		this.unsavedChanges = true
	}

	get courseVersionID() { return this._courseVersionID }
	set courseVersionID( val ) {
		this._courseVersionID = val
		this.unsavedChanges = true
	}



	setQueryVariable( name, qvar ) {
		this.queryVariables.set( name, qvar )
		this.queryVariableNames.push( name )
		this.queryVariableList.push( qvar )
		this.unsavedChanges = true
	}
	hasQueryVariable( name ) {
		return this.queryVariables.has( name )
	}
	getQueryVariable( name ) {
		return this.queryVariables.get( name )
	}
	updateQueryVariableName( oldName, newName ) {
		var idx = this.queryVariableNames.indexOf( oldName )
		this.queryVariableNames.splice( idx, 1, newName )
		
		this.queryVariables.delete( oldName )
		this.queryVariables.set( newName, this.queryVariableList[idx] )

		for( var ovar of this.outputVariableList ) ovar.updateQueryVariableName( oldName, newName )
		this.unsavedChanges = true
	}

	deleteQueryVariable( name ) {
		this.queryVariables.delete( name )
		var idx = this.queryVariableNames.indexOf( name )
		if( idx < 0 ) return

		this.queryVariableNames.splice( idx, 1 )
		this.queryVariableList.splice( idx, 1 )
		this.unsavedChanges = true
	}


	findUnusedQueryVariables() {
		const unused = new Set( this.queryVariableNames )
		const names = []

		for( var ovar of this.outputVariableList ) {
			for( var op of ovar.operations ) {
				for( var operand of op.operands ) {
					unused.delete( operand )
				}
			}
		}

		for( var name of unused.values() ) names.push( name )
		return names
	}





	setOutputVariable( name, ovar ) {
		this.outputVariables.set( name, ovar )
		this.outputVariableNames.push( name )
		this.outputVariableList.push( ovar )
		this.unsavedChanges = true
	}
	hasOutputVariable( name ) {
		return this.outputVariables.has( name )
	}
	getOutputVariable( name ) {
		return this.outputVariables.get( name )
	}
	updateOutputVariableName( oldName, newName ) {
		var idx = this.outputVariableNames.indexOf( oldName )
		this.outputVariableNames.splice( idx, 1, newName )
		
		this.outputVariables.delete( oldName )
		this.outputVariables.set( newName, this.outputVariableList[idx] )
		this.unsavedChanges = true
	}
	
	deleteOutputVariable( name ) {
		this.outputVariables.delete( name )
		var idx = this.outputVariableNames.indexOf( name )
		if( idx < 0 ) return
		
		this.outputVariableNames.splice( idx, 1 )
		this.outputVariableList.splice( idx, 1 )
		this.unsavedChanges = true
	}



	getOutputVariableNames() {
		const names = []
		for( var name of this.outputVariables.keys() ) names.push( name )
		return names
	}






	static async import( obj ) {
		
		const dim = super.import( obj, [ 'queryVariables', 'queryVariableNames', 'queryVariableList', 'outputVariables', 'outputVariableNames', 'outputVariableList', 'unsavedChanges' ] )
		
		for( let qvName in obj.queryVariables ) {
			let qvar = EknoStatDimensionQueryVariable.import( obj.queryVariables[ qvName ] )
			dim.setQueryVariable( qvName, qvar )
			qvar.dim = dim
			await qvar.initialize( obj.queryVariables[ qvName ] )
		}
		
		for( let ovName in obj.outputVariables ) {
			let ovar = Computable.import( obj.outputVariables[ ovName ] )
			dim.setOutputVariable( ovName, ovar )
			ovar.dimension = dim
		}
		
		this.unsavedChanges = false
		return dim
	}



	export() {
		const data = super.export( [ '_name', '_courseID', '_courseVersionID', 'queryVariables', 'queryVariableNames', 'queryVariableList', 'outputVariables', 'outputVariableNames', 'outputVariableList', 'unsavedChanges' ] )

		data.name = this.name
		data.courseID = this.courseID
		data.courseVersionID = parseInt( this.courseVersionID ) || null
		
		data.queryVariables = {}
		for( let [name, qvar] of this.queryVariables.entries() ) {
			data.queryVariables[name] = qvar.export()
		}
		
		data.outputVariables = {}
		for( let [name, ovar] of this.outputVariables.entries() ) {
			data.outputVariables[name] = ovar.export()
		}

		return data
	}


}

export default EknoStatDimension