<template>
<section>
	
	<!-- Stars -->
	<div class="stars flex-row flex-justify-center mb-2">
		<div class="star star-1" :class="{'icon-star-full' : review.stars >= 1, 'icon-star-empty' : review.stars < 1 }" @click="review.stars = review.stars === 1 ? null : 1" />
		<div class="star star-2" :class="{'icon-star-full' : review.stars >= 2, 'icon-star-empty' : review.stars < 2 }" @click="review.stars = review.stars === 2 ? null : 2" />
		<div class="star star-3" :class="{'icon-star-full' : review.stars >= 3, 'icon-star-empty' : review.stars < 3 }" @click="review.stars = review.stars === 3 ? null : 3" />
		<div class="star star-4" :class="{'icon-star-full' : review.stars >= 4, 'icon-star-empty' : review.stars < 4 }" @click="review.stars = review.stars === 4 ? null : 4" />
		<div class="star star-5" :class="{'icon-star-full' : review.stars >= 5, 'icon-star-empty' : review.stars < 5 }" @click="review.stars = review.stars === 5 ? null : 5" />
	</div>


	<!-- Prompt & Title -->
	<div class="color-gray-40"><input type="text" class="w-100" v-model="review.title" placeholder="Title (optional)" /></div>

	<!-- Body -->
	<div class="mt-1 color-gray-40"><textarea class="w-100" style="height: 20em;" v-model="review.body" placeholder="Review Text" /></div>

	<!-- Author Info -->
	<div class="mt-2 flex-row flex-justify-center flex-gap-05">
		<div class="italic">Author:</div>
		<div>
			<div><input type="text" v-model="review.authorName" placeholder="Author's Name" /></div>
			<div><input type="text" v-model="review.authorLine2" placeholder="Line 2 (position)" /></div>
			<div><input type="text" v-model="review.authorLine3" placeholder="Line 3 (organization)" /></div>
			<div class="mt-05"><input type="text" v-model="review.email" placeholder="Email Address" /></div>
		</div>
	</div>

	<!-- Image -->
	<div class="mt-2" v-if="review.reviewID">
		<AttachmentFileViewer hostType="educatorReview" :hostID="review.reviewID" :select="true" v-model="review.selectedFileID" ref="fileViewer" />
		<AttachmentFileUploader hostType="educatorReview" :hostID="review.reviewID" @upload="$refs.fileViewer.initialize()" />
	</div>	


	<!-- Affiliates -->
	<div class="mt-2 pt-2 border-top center">
		<div class="flex-row flex-align-center flex-justify-center flex-gap-1"><b>Affiliate:</b><AffiliateSearchDropdown @update="aff => this.setAffiliate( aff )" :initialValue="review.affiliateName" placeholder="Add Affiliate" ref="affSearch" class="mt-05" /></div>
		<div class="flex-row flex-align-center flex-justify-center flex-gap-1"><b>Customer:</b><CustomerSearchDropdown @updateCustomer="cust => this.setCustomer( cust )" :initialValue="review.customerName" placeholder="Add Customer" ref="custSearch" class="mt-05" /></div>
	</div>


	<!-- Modal Message -->
	<div v-if="review.modalMessageName" class="mt-3 center">
		<div><b>From Modal Message:</b></div>
		<div><i>&ldquo;{{ review.modalMessageName }}&rdquo;</i></div>
	</div>


	<!-- Publication Settings -->
	<div class="mt-3 pt-3 center border-top">
		<div><b>Publication Settings</b></div>

		<div class="flex-row flex-align-start flex-justify-center flex-gap-3">
			<!-- <div> -->
				<div class="mt-05 flex-row flex-align-center flex-gap-05"><ToggleButton v-model="review.isPublished" :sync="true" />Publish</div>
			<!-- </div> -->
		</div>

	</div>


	<div v-if="!review.reviewID" class="flex-row flex-justify-end">
		<button class="button" @click="save()">Create Review</button>
	</div>


</section>
</template>


<script>
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import AttachmentFileUploader from '@/features/FileUploader/AttachmentFileUploader.vue'
import AttachmentFileViewer from '@/features/FileUploader/AttachmentFileViewer.vue'
import { ToggleButton } from 'vue-js-toggle-button'

import ReviewsAPI from '@/api/ReviewsAPI.js'
import GenericTagsAPI from '@/api/GenericTagsAPI.js'

export default {
	name: 'EducatorReviewDetails',


	components: {
		AffiliateSearchDropdown,
		CustomerSearchDropdown,
		AttachmentFileUploader,
		AttachmentFileViewer,
		ToggleButton,
	},


	props: {
		review: Object,
	},


	data() {
		return {
			titleTimeoutID: null,
			bodyTimeoutID: null,
			nameTimeoutID: null,
			line2TimeoutID: null,
			line3TimeoutID: null,
			emailTimeoutID: null,
		}
	},


	watch: {
		async 'review.selectedFileID'( fileID ) {
			this.patch( 'selectedFileID', this.review.selectedFileID )
			
			if( !fileID ) {
				this.review.imageURL = null
				return
			}

			const data = await FilesAPI.getPublicFilePath( fileID, 'attached_files' )
			this.review.imageURL = data.path
		},

		'review.stars'( value ) { this.patch( 'stars', value ) },
		'review.title'( value ) {
			clearTimeout( this.titleTimeoutID )
			this.titleTimeoutID = setTimeout( () => { this.patch( 'title', value ) }, 500 )
		},
		'review.body'( value ) {
			clearTimeout( this.bodyTimeoutID )
			this.bodyTimeoutID = setTimeout( () => { this.patch( 'body', value ) }, 500 )
		},
		'review.authorName'( value ) {
			clearTimeout( this.nameTimeoutID )
			this.nameTimeoutID = setTimeout( () => { this.patch( 'authorName', value ) }, 500 )
		},
		'review.authorLine2'( value ) {
			clearTimeout( this.line2TimeoutID )
			this.line2TimeoutID = setTimeout( () => { this.patch( 'authorLine2', value ) }, 500 )
		},
		'review.authorLine3'( value ) {
			clearTimeout( this.line3TimeoutID )
			this.line3TimeoutID = setTimeout( () => { this.patch( 'authorLine3', value ) }, 500 )
		},
		'review.email'( value ) {
			clearTimeout( this.emailTimeoutID )
			this.emailTimeoutID = setTimeout( () => { this.patch( 'email', value ) }, 500 )
		},
		'review.imageURL'( value ) { this.patch( 'imageURL', value ) },
		'review.affiliateID'( value ) { this.patch( 'affiliateID', value ) },
		'review.customerID'( value ) { this.patch( 'customerID', value ) },
		'review.isPublished'( value ) { this.patch( 'isPublished', value ) },
	},


	methods: {


		async save() {
			if( this.review.reviewID ) return null

			const res = await ReviewsAPI.addEducatorReview( this.review )
			this.review.reviewID = res.data
			
			if( this.review.reviewID ) this.$emit( 'create' )
		},


		async setAffiliate( aff = null ) {
			this.review.affiliateName = aff ? aff.name : null
			this.review.affiliateID = aff ? aff.id : null
		},


		async setCustomer( customer = null ) {
			console.log( "setCustomer" )
			this.review.customerName = customer ? customer.name : null
			this.review.customerID = customer ? customer.id : null
		},


		async patch( param, value ) {
			if( !this.review.reviewID ) return
      await ReviewsAPI.patchEducatorReview( { id: this.review.reviewID, key: param, value } )
    },


		async addTag( tag ) {
			await GenericTagsAPI.addTag( 'review', this.review.reviewID, tag )
			this.review.addTag( tag )
		},


		async removeTag( tag ) {
			await GenericTagsAPI.removeTag( 'review', this.review.reviewID, tag )
			this.review.removeTag( tag )
		}

	}
}
</script>


<style scoped>

.star {
	color: lightgray;
	font-size: 2.5em;
	padding: 0 0.125em;
}

.icon-star-full {
	color: gold;
}

.star:hover {
	color: gold;
}

.label {
	text-align: right;
	width: 150px;
}
.value {
	text-align: left;
	font-weight: bold;
	width: 150px;
}
</style>