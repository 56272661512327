<template>
<section class="gaugeWrapper" :class="{'block': gaugeModel.lineBreakAfter}" ref="gaugeWrapper">
<section id="OverlayWrapper">
	<svg :viewBox="'0 -15 '+overallWidth+' '+(gaugeRadius+35)" :height="gaugeRadius+20" :width="overallWidth" id="gauge" :class="{'disabled': !enabled}" ref="gauge">
		<rect width="100%" height="100%" stroke="none" visibility="hidden" pointer-events="none" />


		<path id="bgArc" :d="bgArcPath" stroke="#ddd" :stroke-width="arcThickness" fill="none" />
		<path id="scArc" :d="scoreArcPath" stroke="#0b59c6" :stroke-width="arcThickness" fill="none" :tooltip="gaugeModel.scoreTooltip1+': '+currScore.scaledScore" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" />
		<path id="psArc" :d="potentialScoreArcPath" stroke="#7a9ccc" :stroke-width="arcThickness" fill="none" :tooltip="gaugeModel.scoreTooltip2+': '+currScore.scaledScore+' - '+potentialScore.scaledScore" @mouseenter="showTip($event)" @mouseleave="hideTip($event)" />

		<!-- Center label ("Expected Score") -->
		<text :x="xCenter" :y="yCenter-(Math.min(70, .4*gaugeRadius))" :style="'text-anchor:middle;font-weight:bold;font-size:'+(.015*gaugeRadius)+'em'" ref="score"></text>

		<!-- Gauge Name Label -->
		<text v-if="gaugeModel.name.length >= 18" :x="xCenter" :y="yCenter-(Math.min(25, .15*gaugeRadius))" :style="'text-anchor:middle;font-size:'+(.008*gaugeRadius)+'em'" ref="nameLabel">
			<tspan v-for="(word, i) in gaugeModel.name.split(' ')" :key="i" :x="xCenter" :dy="(i > 0) ? '1.2em' : '0'">{{word}}</tspan>
		</text>
		<text v-else :x="xCenter" :y="yCenter-(Math.min(25, .15*gaugeRadius))" :style="'text-anchor:middle;font-size:'+(.008*gaugeRadius)+'em'" ref="nameLabel">{{gaugeModel.name}}</text>

		<!-- Tick lines on gauge -->
		<line class="darkLine" :x1="xCenter-gaugeRadius" :y1="yCenter" :x2="xCenter-gaugeRadius+arcThickness" :y2="yCenter" ref="zeroScore" />
		<line class="markerLine" :x1="angleXValue(gaugeRadius, zeroAngle)" :y1="angleYValue(gaugeRadius, zeroAngle)" :x2="angleXValue(gaugeRadius-arcThickness, zeroAngle)" :y2="angleYValue(gaugeRadius-arcThickness, zeroAngle)" ref="currScoreMarker1" />
		<line class="markerLine" :x1="angleXValue(gaugeRadius, zeroAngle)" :y1="angleYValue(gaugeRadius, zeroAngle)" :x2="angleXValue(gaugeRadius-arcThickness, zeroAngle)" :y2="angleYValue(gaugeRadius-arcThickness, zeroAngle)" ref="currScoreMarker2" />
		<line class="darkLine" :x1="angleXValue(gaugeRadius, zeroAngle)" :y1="angleYValue(gaugeRadius, zeroAngle)" :x2="angleXValue(gaugeRadius-arcThickness, zeroAngle)" :y2="angleYValue(gaugeRadius-arcThickness, zeroAngle)" ref="goalScore" />
		<line class="darkLine" :x1="xCenter+gaugeRadius" :y1="yCenter" :x2="xCenter+gaugeRadius-arcThickness" :y2="yCenter" ref="perfectScore" />

		<!-- Score labels -->
		<text class="label" :x="angleXValue(gaugeRadius-arcThickness-5, zeroAngle)" :y="yCenter+5" style="text-anchor:start;" ref="zeroNum">{{minimumScore.scaledScore}}</text>
		<text class="label" :x="angleXValue(gaugeRadius-25, zeroAngle)" :y="angleYValue(gaugeRadius-25, zeroAngle)+5" style="text-anchor:end;" ref="goalNum"></text>
		<text class="label" :x="angleXValue(gaugeRadius+5, zeroAngle)" :y="angleYValue(gaugeRadius+5, zeroAngle)+5" style="text-anchor:start;" ref="goalLabel"></text>
		<text class="label" :x="angleXValue(gaugeRadius-arcThickness-5, perfectAngle)" :y="yCenter+5" style="text-anchor:end;" ref="perfectNum"></text>

	</svg>

	<div id="DisabledOverlay" v-if="!enabled && !allDisabled">
		<div id="DisabledMessage">{{gaugeModel.disabledMessage}}</div>
	</div>

	<!-- <input type="checkbox" id="what-if-mode" v-model="whatIfMode" /> <label>What-if mode</label><br /> -->
	<!-- <div v-if="whatIfMode">
		<label>Correct:&nbsp;</label> <input type="text" v-model="whatIfCorrect" class="what-if-text" />
		/
		<label>Total:&nbsp;</label> <input type="text" v-model="whatIfTotal" class="what-if-text" disabled />
	</div> -->

	<!-- <div class="debugOutput">
		<div v-for="(node, index) in gaugeModel.predictionBucket.nodeBucket"
			:title="node.getPath()">
			{{index+1}}. {{node.name}}
		</div>
	</div> -->

</section>


<!-- <div id="ActionButton">
	<button v-if="!enabled && !allDisabled && !libraryMode && gaugeModel.primaryDiag"
					class="SquareBlueButton"
					@click.prevent.stop="$store.dispatch('setActiveNode', gaugeModel.primaryDiag)">
		{{gaugeModel.disabledButtonLabel}}
	</button>
</div> -->
</section>
</template>



<script>
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

export default {
	name: "ScoreGauge",

	props: {
		gaugeModel: Object,
		allDisabled: Boolean,
		// libraryMode: { // Display gauges in course library
		// 	type: Boolean,
		// 	default: false
		// },
		// correctQuestionMap: { // Only needed for Library mode
		// 	type: Map,
		// 	default: null
		// },
	},

	data(){
		return {
			xmlns: "http://www.w3.org/2000/svg",
			// initScore: new ScoreDTO(),
			// testList: [],

			// whatIfMode: false,
			// whatIfScoreDTO: new ScoreDTO(),

			formattedName: '',			// Set in created hook
			gaugeRadius: 0, 				// Set in created hook
			arcRadius: 0,						// Set in created hook

			zeroAngle: 0,						// 0.00 rad =   0 degrees
			initAngle: 0.52,				// 0.52 rad =  30 degrees
			perfectAngle: Math.PI,	// pi rad   = 180 degrees

			spreadAngle: 0,					// Set automatically
			radiansPerPoint: 0,			// Set automatically
		}
	},

	created() {
		this.gaugeRadius = this.gaugeModel.gaugeRadius;
		this.arcThickness = 0.18*this.gaugeRadius
		this.arcRadius = this.gaugeRadius - (this.arcThickness/2)

		let name = this.gaugeModel.name
		if( name.length >= 18 ) {
			name.split(' ').forEach((word) => {
				this.formattedName += '<tspan x="0" dy="1.2em">'+word+'</tspan>'
			})
		} else this.formattedName = name
	},

	mounted() {
		this.initialize();
		this.updateRender();
	},

	computed: {
		scoreArcPath(){
			let currScoreDegree = this.currScore.percent * this.radiansPerPoint + this.zeroAngle

			let x2 = this.arcRadius * (-Math.cos(currScoreDegree)) + this.xCenter;
			let y2 = (this.arcRadius * Math.sin(currScoreDegree))*(-1) + this.yCenter;

			let d = [
				'M',
				this.xCenter-this.arcRadius,	// xM: Starting point (x-axis)
				this.yCenter, 								// yM: Starting point (y-axis)
				'A',
				this.arcRadius, 							// rx
				this.arcRadius,								// ry
				0, 														// x-axis-rotation
				0, 														// large-arc-flag
				1, 														// sweep-flag
				x2, 													// x-end
				y2 														// y-end
			].join(" ")
			return d
		},

		potentialScoreArcPath() {
			let currScoreDegree = this.currScore.percent * this.radiansPerPoint + this.zeroAngle;
			let potentialScoreDegree = this.potentialScore.percent * this.radiansPerPoint + this.zeroAngle;

			let x1 = this.arcRadius * (-Math.cos(currScoreDegree+.02)) + this.xCenter;
			let y1 = (this.arcRadius * Math.sin(currScoreDegree+.02))*(-1) + this.yCenter;

			let x2 = this.arcRadius * (-Math.cos(potentialScoreDegree)) + this.xCenter;
			let y2 = (this.arcRadius * Math.sin(potentialScoreDegree))*(-1) + this.yCenter;

			let d = [
				'M',
				x1,								// xM: Starting point (x-axis)
				y1, 							// yM: Starting point (y-axis)
				'A',
				this.arcRadius,		// rx
				this.arcRadius,		// ry
				0, 								// x-axis-rotation
				0, 								// large-arc-flag
				1, 								// sweep-flag
				x2, 							// x-end
				y2 								// y-end
			].join(" ")
			return d
		},

		bgArcPath() {
			let currScoreDegree = this.potentialScore.percent * this.radiansPerPoint + this.zeroAngle

			let x1 = this.arcRadius * (-Math.cos(currScoreDegree+.02)) + this.xCenter;
			let y1 = (this.arcRadius * Math.sin(currScoreDegree+.02))*(-1) + this.yCenter;

			let d = [
				'M',
				x1,																// xM: Starting point (x-axis)
				y1, 															// yM: Starting point (y-axis)
				'A',
				this.arcRadius, 									// rx
				this.arcRadius,										// ry
				0, 																// x-axis-rotation
				0, 																// large-arc-flag
				1, 																// sweep-flag
				(this.xCenter+(this.arcRadius)),	// x-end
				this.yCenter 											// y-end
			].join(" ")
			return d
		},

		enabled() {
			return true
			// return this.gaugeModel.enabled;
		},

		overallWidth: {
			get(){
				return 2 * this.gaugeRadius + 70;
			},
			set(value){
				this.gaugeRadius = (value - 70) / 2
			}
		},
		xCenter(){
			return this.overallWidth / 2;
		},
		yCenter(){ return this.gaugeRadius },

		goalScore(){
			return this.gaugeModel.targetScore
		},

		minimumScore() {
			return this.gaugeModel.minimumScore
		},

		perfectScore() {
			return this.gaugeModel.perfectScore
		},


		currScore() {
			const adjusted = this.gaugeModel.showAdjustedScore;
			const real     = this.gaugeModel.showRealScore;

			if( real && adjusted ) return this.realScore.correct <= this.adjustedScore.correct ? this.realScore : this.adjustedScore;
			if( real && !adjusted ) return this.realScore;
			if( !real && adjusted ) return this.adjustedScore;
			if( !adjusted && !real ) return this.minimumScore;
			return null
			
		},


		/**
		 * Chooses a "potential" score as a "high-water mark"
		 * MAX() of:
		 * - Computed real score (from buckets)
		 * - Computed adjusted score (from buckets)
		 * - All diag (test) scores
		 */
		potentialScore() {
			const adjusted  = this.gaugeModel.showAdjustedScore;
			const real      = this.gaugeModel.showRealScore;
			if( !real || !adjusted ) return this.currScore


			const scores = new Map();

			// Computed (raw & adjusted) scores
			scores.set( this.realScore.scaledScore, this.realScore )
			scores.set( this.adjustedScore.scaledScore, this.adjustedScore )
			
			// Diag test scores (dots on gauge)
			let scoreList = this.gaugeModel.testScores;
			for(var score of scoreList) scores.set( score.scaledScore, score )

			// Choose the highest score
			const scoreNum = Math.max(...scores.keys())
			const chosenScore = scores.get(scoreNum)
			return chosenScore
			
		},


		realScore() {
			return this.gaugeModel.realScore;
		},

		adjustedScore() {
			return this.gaugeModel.adjustedScore;
		},

	},



	watch: {
		goalScore(){
			this.updateRender();
		},
		gaugeModel(){
			this.initialize();
			this.updateRender();
		},
		// whatIfMode(){
		// 	this.updateRender();
		// },
		currScore(value) {
			this.updateRender();
		}
	},

	methods: {

		initialize() {

			let domWrapper = document.getElementById("GaugesWidget");
			let pl = parseInt(window.getComputedStyle(domWrapper).getPropertyValue('padding-left'))
			let pr = parseInt(window.getComputedStyle(domWrapper).getPropertyValue('padding-right'))
			let domWidth = domWrapper.clientWidth;
			let innerWidth = domWidth - pl - pr;
			if(this.overallWidth > innerWidth) {
				this.overallWidth = innerWidth;
			}

			this.spreadAngle = this.perfectAngle - this.zeroAngle
			this.radiansPerPoint = this.spreadAngle / this.perfectScore.percent
		},

		updateRender() {

			if(this.enabled) {
				const highScore = this.potentialScore.scaledScore
				const lowScore = this.currScore.scaledScore
				this.$refs.score.textContent = (highScore - lowScore > 0) ? lowScore + ' - ' + highScore : highScore
			}

			if(this.goalScore.percent) {
				// this.$refs.goalScore.setAttribute("style", "stroke:white");
				this.$refs.goalLabel.textContent = "Goal";
				this.$refs.goalNum.textContent = this.goalScore.scaledScore;
				let angle = this.goalScore.percent * this.radiansPerPoint + this.zeroAngle
				this.adjustLines(angle, this.$refs.goalScore, this.$refs.goalLabel, this.$refs.goalNum)
			}

			if(this.currScore.percent) {
				let angle = this.currScore.percent * this.radiansPerPoint + this.zeroAngle;
				let line = this.$refs.currScoreMarker1;
				line.setAttributeNS( null, "x1", this.angleXValue(this.gaugeRadius, angle-.01) )
				line.setAttributeNS( null, "y1", this.angleYValue(this.gaugeRadius, angle-.01) )
				line.setAttributeNS( null, "x2", this.angleXValue(this.gaugeRadius+0.25*this.arcThickness, angle-.01) )
				line.setAttributeNS( null, "y2", this.angleYValue(this.gaugeRadius+0.25*this.arcThickness, angle-.01) )
				line = this.$refs.currScoreMarker2;
				line.setAttributeNS( null, "x1", this.angleXValue(this.gaugeRadius-this.arcThickness, angle-.01) )
				line.setAttributeNS( null, "y1", this.angleYValue(this.gaugeRadius-this.arcThickness, angle-.01) )
				line.setAttributeNS( null, "x2", this.angleXValue(this.gaugeRadius-1.25*this.arcThickness, angle-.01) )
				line.setAttributeNS( null, "y2", this.angleYValue(this.gaugeRadius-1.25*this.arcThickness, angle-.01) )
			}

			this.$refs.perfectNum.textContent = this.perfectScore.scaledScore;


			// Reset Test score dots
			let domItem = null;
			while( (domItem = document.getElementById("TestScoreDot-"+this.gaugeModel.name)) != null ) {
				domItem.parentNode.removeChild(domItem);
				// console.log("removed diag score from "+this.gaugeModel.name+" gauge")
			}
			// let scoreList = (this.libraryMode ? this.gaugeModel.getScoreDTOList(this.correctQuestionMap) : this.gaugeModel.scoreDTOList);
			let scoreList = this.gaugeModel.testScores
			for(let scoreDTO of scoreList) {
				this.insertScore(scoreDTO);
			}

			// Resize "widget" box to fit the gauge
			this.$refs.gaugeWrapper.style.width = this.overallWidth;
			this.$refs.gauge.style.width = this.overallWidth;
		},

		insertScore(scoreDTO) {
			let angle;
			angle = scoreDTO.percent * this.radiansPerPoint + this.zeroAngle

			let x = this.angleXValue(this.gaugeRadius, angle);
			let y = this.angleYValue(this.gaugeRadius, angle);

			let dot = document.createElementNS(this.xmlns, "circle");
			dot.setAttributeNS(null, "cx", x);
			dot.setAttributeNS(null, "cy", y);
			dot.setAttributeNS(null, "r", Math.max(6, 0.2*this.arcThickness));
			dot.setAttributeNS(null, "fill", "#aaa");
			dot.setAttributeNS(null, "id", "TestScoreDot-"+this.gaugeModel.name);
			dot.setAttributeNS(null, "style", "cursor:pointer;")
			this.$refs.gauge.appendChild(dot);

			let title = `${scoreDTO.testName}\n\nYour score: ${scoreDTO.scaledScore}`;
			dot.setAttributeNS(null, "tooltip", title)

			dot.addEventListener('mouseenter', ev => {this.showTip(ev, "top", 0); dot.setAttributeNS(null, "fill", "var(--pp10-purple)"); });
			dot.addEventListener('mouseleave', ev => {this.hideTip(ev, "top", 0); dot.setAttributeNS(null, "fill", "#aaa"); });
		},

		angleXValue(radius, angleRadians) {
			return this.xCenter - radius*Math.cos(angleRadians);
		},
		angleYValue(radius, angleRadians) {
			return this.yCenter - radius*Math.sin(angleRadians);
		},

		adjustLines(angle, line, textLabel, numLabel) {
			let half = this.perfectScore.percent / 2

			// Line
			line.setAttributeNS( null, "x1", this.angleXValue(this.gaugeRadius, angle) )
			line.setAttributeNS( null, "y1", this.angleYValue(this.gaugeRadius, angle) )
			line.setAttributeNS( null, "x2", this.angleXValue(this.gaugeRadius-this.arcThickness, angle) )
			line.setAttributeNS( null, "y2", this.angleYValue(this.gaugeRadius-this.arcThickness, angle) )

			// Text Label
			textLabel.setAttributeNS( null, "x", this.angleXValue(this.gaugeRadius+5, angle) )
			textLabel.setAttributeNS( null, "y", this.angleYValue(this.gaugeRadius+5, angle)+5 )

			// Text Label Justification
			let textCtrPct = this.perfectScore.percent * .08
			if(angle < (half - textCtrPct)*this.radiansPerPoint) {
				textLabel.setAttributeNS( null, "style", "text-anchor:end")
			}else if(angle < (half + textCtrPct)*this.radiansPerPoint) {
				textLabel.setAttributeNS( null, "style", "text-anchor:middle")
			}else{
				textLabel.setAttributeNS( null, "style", "text-anchor:start")
			}

			// Number Label
			let vAdjust = angle > (this.perfectScore / 2) ? 9 : -9
			numLabel.setAttributeNS( null, "x", this.angleXValue(this.gaugeRadius-this.arcThickness-5, angle) )
			numLabel.setAttributeNS( null, "y", this.angleYValue(this.gaugeRadius-this.arcThickness-5 + vAdjust, angle) )

			// Number Label Justification
			let numCtrPct = this.perfectScore.percent * .16
			half = this.perfectScore.percent / 2
			if(angle < (half - numCtrPct)*this.radiansPerPoint) {
				numLabel.setAttributeNS( null, "style", "text-anchor:start")
			}else if(angle < (half + numCtrPct)*this.radiansPerPoint) {
				numLabel.setAttributeNS( null, "style", "text-anchor:middle")
			}else{
				numLabel.setAttributeNS( null, "style", "text-anchor:end")
			}
		},

		showTip(ev, position = "top", offset = 0){
			return Tooltips.showTooltip(ev, position, offset)
		},
		hideTip(ev){
			return Tooltips.hideTooltip(ev)
		},
	}
}
</script>


<style scoped>
svg {width:auto}
.stop1 { stop-color: red; }
.stop2 { stop-color: yellow; }
.stop3 { stop-color: green; }

.gaugeWrapper {
	display: inline-block;
	position:relative;
	margin-bottom: 1em;
}
.block {
	display:block;
}
#gauge {
	display: inline-block;
}

.disabled {
	filter: blur(7px);
	opacity: 0.6;
}
#OverlayWrapper {
	position: relative;
}
#DisabledOverlay {
	position: absolute;
	left: 2rem;
	top: 0.5rem;
	right: 2rem;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	/* background: rgba(255,255,255,0.3); */
	font-size: 1.1em;
}
#DisabledMessage {
	text-shadow: 0 0 0.5em white;
	max-width: 20em;
	align-self: center;
}
.SquareBlueButton {
	margin: 0.25em;
}

#nameLabel {
	text-anchor:middle;
	font-weight: bold;
}

.what-if-text {
	width: 3em;
}

.darkLine {
	stroke:#999;
	stroke-width: 3;
}
.markerLine {
	stroke: var(--ekno-blue);
	stroke-width: 3;
}

.label {
	fill: #666;
}
#scArc { stroke: var(--ekno-blue); }
#psArc { stroke: #bbddee; }
#psArc, #scArc { transition: stroke 0.25s; }
#scArc:hover, #psArc:hover {
	stroke: var(--pp10-purple);
	transition: stroke 0.25s;
}

.dot { fill: #ff0000; stroke: #ff0000; }
.dot.active {
	fill: var(--pp10-orange);
}
</style>
