

/**
 * Optional parameters by which to filter
 * ModalMessage selection (from DB)
 */
class ModalMessageRequest {

	// Currently-loaded license (if applicable)
	activeLicenseKey = null;

	// Sort & Page
	sortBy = null;
	sortAsc = true;
	page = 1;
	pageSize = 50;

	// Trigger logic
	isActive = null;
	numLogins = null;
	triggerDate = null;
	minutesInProgram = null;
	minutesPurchase = null;
	minutesAccount = null;
	displayInCourse = null;
	displayInLibrary = null;

	typeIDs = null; // array of included modalMessageTypeIDs

	// Assignments
	// Logic: (user IN $courseIDs && user IN $affiliateIDs && user IN $customerIDs && user IN $cohortIDs) || user IN $userIDs
	courseIDs = null;
	affiliateIDs = null;
	customerIDs = null;
	cohortIDs = null;
	userIDs = null;
	includeNulls = true;
	honorRequired = true;


	// When getting ModalMessageList, get ALL MM's IFF not filtering by ANYTHING
	shouldIncludeNulls() {
		return this.courseIDs === null
		&& this.affiliateIDs === null
		&& this.customerIDs === null
		&& this.cohortIDs === null
		&& this.userIDs === null
	}

}

export default ModalMessageRequest