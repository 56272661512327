<template>
<section>
	<section class="Tab">
		<button @click="mode = 'general'" :class="{ active: mode === 'general' }">General</button>
		<button @click="mode = 'orgchart'" :class="{ active: mode === 'orgchart' }">Org Chart</button>
		<button @click="mode='users'" :class="{ active: mode === 'users' }">Users</button>

		<button @click="mode = 'orders'" class="ml-1" :class="{ active: mode === 'orders' }">Orders</button>
		<button @click="mode = 'licenses'" :class="{ active: mode === 'licenses' }">Licenses</button>
		<button @click="mode='programtime'" :class="{ active: mode === 'programtime' }">Study Time</button>

		<button @click="mode='testData'" class="ml-1" :class="{ active: mode === 'testData' }">Test Data</button>
		<button @click="mode = 'modalmessages'" :class="{ active: mode === 'modalmessages' }">Modal Messages</button>
		<button @click="mode = 'reviews'" :class="{ active: mode === 'reviews' }">Reviews</button>
	</section>

	<div class="px-1 pb-1">
		<section v-if="mode == 'general'">
			<div class="StickySaveButton">
				<button class="pillButton" @click="save" :class="{ 'disabled' : !changesMade }" :disabled="!changesMade">
					Save Changes
				</button>
			</div>

			<div class="flex-row flex-gap-15 mb-2">
				<fieldset class="center" style="width: 100%">
					<legend>Customer Name &amp; Address</legend>
					<p>
						<b><input
								type="text"
								v-model="newCustomer.name"
								placeholder="Name"
						/></b>
					</p>
					<p>
						<input
							type="text"
							v-model="newCustomer.address"
							placeholder="Address"
						/><br />
						<input
							type="text"
							v-model="newCustomer.city"
							placeholder="City"
						/><br />
						<input
							type="text"
							v-model="newCustomer.state"
							placeholder="State"
						/><br />
						<input
							type="text"
							v-model="newCustomer.zip"
							placeholder="Zip"
						/><br />
						<input
							type="text"
							v-model="newCustomer.country"
							placeholder="Country"
						/>
					</p>
				</fieldset>

				<fieldset v-if="newCustomer.id" style="width: 100%; max-height: 273px; overflow: auto;">
					<legend>Cohorts / Classes ({{ cohortCount || 0 }})</legend>
					<div v-for="cohort in cohorts" :key="cohort.cohortID">
						{{ cohort.cohortName }} ({{ cohort.licenseCount }})
						<button @click="getSummary(cohort.cohortID)">Report</button>
					</div>
				</fieldset>

				<fieldset v-if="newCustomer.id" style="width: 100%;">
					<legend>Commissions</legend>
					<CommissionWidget :hostType="'customer'" :hostID="customerID" />
				</fieldset>
			</div>

			<div class="flex-row flex-gap-15 mb-2">
				<fieldset v-if="newCustomer.id" style="width: 50%;">
					<legend>General Notes:</legend>
					<textarea
						v-if="newCustomer"
						v-model="newCustomer.notes"
						style="width: 100%; height: 12.5em"
					/>
				</fieldset>

				<fieldset v-if="newCustomer && newCustomer.id" style="padding: 0;">
					<legend>Contacts</legend>
					<PocList :customerID="newCustomer.id" style="max-height: 450px; overflow: auto; padding: 1em;" />
				</fieldset>

				<fieldset style="width: 50%; height: 14.25em; max-height: 14.25em; overflow: auto;">
					<legend>
						Tasks
						<button @click="addActionItem">
							<span class="icon-plus" />
						</button>
					</legend>
					<ActionItemList
						:hostID="this.customerID"
						hostType="customer"
						ref="actionItemList"
					/>
				</fieldset>
			</div>

			<div class="flex-row flex-gap-15 mb-2">
				<fieldset v-if="customerID" style="width: 50%;">
					<legend>Attachments (Financial)</legend>
					<FileAttachments hostType="customer" :hostID="customerID" />
				</fieldset>
				<fieldset v-if="customerID" style="width: 50%;">
					<legend>Attachments (Organizational)</legend>
					<FileAttachments hostType="customerOrg" :hostID="customerID" />
				</fieldset>
			</div>

			<fieldset id="Interactions" v-if="customerID">
				<legend>
					Interactions
					<button @click="addInteraction">
						<span class="icon-plus" />
					</button>
				</legend>
				<CustomerInteractionComponent
					v-for="interaction in newCustomer.customerInteractions"
					:key="interaction.id"
					:interaction="interaction"
				/>
			</fieldset>
			<div v-if="newCustomer.notInterested" class="link" @click="newCustomer.notInterested = false">Un-archive Customer</div>
			<div v-else class="link" @click="newCustomer.notInterested = true">Archive Customer</div>
		</section>



		<section v-if="mode == 'orgchart'">
			<!-- <fieldset v-if="newCustomer.id" style="width: 100%; max-height: 273px; overflow: auto;"> -->
					<!-- <legend>Org Chart</legend> -->
					<UserTreeItem
						v-for="user in rootUsers"
						:key="user.id"
						:user="user"
						:customer="newCustomer"
						@deleteUser="deleteRootUser"
					/>
					<div class="mt-1">
						<UserSearchDropdown v-model="rootUserToAdd" style="margin-bottom: 2px" />
						<button @click="addRootUser">Add Root User</button>
					</div>
				<!-- </fieldset> -->
		</section>



		<section v-if="mode == 'licenses'">
			<LicensesList :Customer="customer" :StudentStatistics="true" :isArchived="false" />
		</section>

		<section v-if="mode == 'users'">
			<UsersList :customerID="newCustomer.id" :Deletable="true" />
		</section>

		<UserTimeList v-if="mode == 'programtime'" :customerID="newCustomer.id" />

		<section v-if="mode == 'testData'">
			<ExternalTestsList :customerID="newCustomer.id" />
		</section>

		<section v-if="mode == 'orders'">
			<div class="StickySaveButton">
				<ButtonModal
					buttonText="New Order"
					:modalTitle="'New Order for ' + newCustomer.name"
				>
					<CreateOrderPane :externalCustomer="newCustomer" />
				</ButtonModal>
			</div>
			<OrderSearchPane :customerID="customerID" :showDashboard="false" />
		</section>

		<section v-if="mode == 'modalmessages'">
			<ModalMessageList :customerID="newCustomerID" />
		</section>

		<section v-if="mode === 'reviews'">
      <ReviewsDashboard :customerID="newCustomerID" />
    </section>

	</div>


	<ExternalTestReportSettingsDialog :customerID="newCustomer.id" ref="externalTestReportSettings" />

</section>
</template>


<script>
import SalesManagementAPI from "@/api/SalesManagementAPI.js"
import CohortAPI from "@/api/CohortAPI.js"

// import User from '@/features/Users/User.js'
import Customer from "./Customer.js"
import CustomerInteraction from "./CustomerInteraction.js"
import CustomerUser from "./CustomerUser.js"
import Cohort from '@/features/Cohorts/Cohort.js'

import CustomerInteractionComponent from "./CustomerInteractionComponent.vue"
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import LicensesList from "@/features/Licenses/LicensesList.vue"
// import UsersList from "@/features/Users/UsersList.vue"
import UserTimeList from '@/features/LoginTracking/UserTimeList.vue'
import ExternalTestsList from "@/features/ExternalTests/ExternalTestsList.vue"
import ExternalTestReportSettingsDialog from "@/features/ExternalTests/ExternalTestReportSettingsDialog.vue"
import FileAttachments from "@/features/FileAttachments/FileAttachments.vue"
import ActionItemList from "../ActionItems/ActionItemList.vue"
import ButtonModal from "@/components/utilities/ButtonModal.vue"
import UserTreeItem from "./UserTreeItem.vue"
import CommissionWidget from '@/features/SalesManagement/CommissionWidget.vue'
import ModalMessageList from '@/features/ModalMessages/ModalMessageList.vue'
import ReviewsDashboard from '@/features/Reviews/ReviewsDashboard.vue'
import PocList from '../POC/PocList.vue'

export default {
	name: "CustomerDetails",

	components: {
		CustomerInteractionComponent,
		UserSearchDropdown,
		LicensesList,
		UsersList: () => import("@/features/Users/UsersList.vue"),
		UserTimeList,
		ExternalTestsList,
		ExternalTestReportSettingsDialog,
		FileAttachments,
		ActionItemList,
		ButtonModal,
		UserTreeItem,
		CommissionWidget,
		PocList,
		ModalMessageList,
		ReviewsDashboard,
		OrderSearchPane: () => import("@/features/orders/OrderSearchPane.vue"),
		CreateOrderPane: () => import("@/features/orders/CreateOrderPane.vue"),
	},

	props: {
		customerID: Number,
		isNew: Boolean,
	},

	data() {
		return {
			customer: {},
			newCustomer: {},
			primaryUser: null,
			users: [],
			rootUsers: [],
			cohorts: [],
			cohortCount: null,

			showAddRootUser: false,
			rootUserToAdd: null,
			userToAdd: null,
			changeCounter: 0,

			showTestReportSettings: false,
			testReportCohort: null,

			mode: "general",
		};
	},

	async created() {
		
		if ( this.isNew && !this.customerID ) {
			this.customer = new Customer()
			this.newCustomer = new Customer()
			return
		}

		let res = await SalesManagementAPI.getCustomer( this.customerID );
		this.customer = Customer.import(res);
		this.newCustomer = Customer.import(this.customer.export());

		// Get interactions (for existing customer)
		if (this.customerID) {
			const res = await SalesManagementAPI.getCustomerInteractions(
				this.customerID
			);
			this.newCustomer.customerInteractions = [];
			for (let item of res) {
				this.newCustomer.customerInteractions.push(
					CustomerInteraction.import(item)
				);
			}
		}

		// Populate Users v2 -- do anything with primary user?
		this.initializeUserTree();
		this.initializeCohorts();
	},



	computed: {
		// This is called when EITHER: changeCounter changes, or one of newCustomer's fields changes.
		// I think (??) Vue is just smart and is including property changes for the function's dependencies (i.e. newCustomer).
		changesMade() {
			this.changeCounter;
			if (!this.newCustomer.export) return false;
			return !this.customer.isSame(this.newCustomer);
		},
	},


	methods: {
		async save() {
			if (this.isNew) {
				const res = await SalesManagementAPI.createCustomer( this.newCustomer.export(false) );
				// console.debug(res.customerID);
				if (!res.customerID)
					alert("Failed to get an ID for the new customer.  Data may not have been saved!");
				this.newCustomer.id = res.customerID;
				this.$emit("itemAdded", this.newCustomer);
			} else {
				await SalesManagementAPI.editCustomer(
					this.newCustomer.export(false)
				);
				this.$emit("itemEdited", this.newCustomer);
			}
			this.changeCounter++;
		},


		deleteUser(user) {
			const choice = confirm(
				`Really remove user ${user.userID} - ${user.firstName} ${user.lastName}?`
			);
			if (!choice) return;

			let index = this.newCustomer.users.indexOf(user.userID);
			if (index != -1) this.newCustomer.users.splice(index, 1);

			index = this.users.indexOf(user);
			if (index != -1) this.users.splice(index, 1);
		},

		async addInteraction() {
			let custInt = new CustomerInteraction();
			custInt.timestamp = new Date();
			this.newCustomer.customerInteractions.unshift(custInt);
			const res = await SalesManagementAPI.createCustomerInteraction(
				this.newCustomer.id,
				custInt.export()
			);
			custInt.id = res.id;
			console.debug(custInt);
		},

		// async deleteInteraction(interactionID) {},

		async editInteraction(interaction) {
			await SalesManagementAPI.editCustomerInteraction(interaction);
		},

		addActionItem() {
			this.$refs.actionItemList.addActionItem();
		},


		

		async initializeCohorts() {
			const data = await CohortAPI.getCustomerCohorts(this.newCustomer.id)
			this.cohorts = []
			for(const item of data.customerCohorts) this.cohorts.push( Cohort.import(item) )
			this.cohortCount = data.totalCount
		},



		async initializeUserTree() {
			if(!this.newCustomer.id) return

			const rawUsers = await SalesManagementAPI.getCustomerUsers(this.newCustomer.id);
			let users = {};
			rawUsers.forEach((user) => {
				users[user.id] = CustomerUser.import(user);
			});

			const edges = await SalesManagementAPI.getCustomerUserRelationships(
				this.newCustomer.id
			);

			this.rootUsers = Object.values(users).filter((user) => {
				return !edges.some((edge) => edge.childID === user.id);
			});

			this.rootUsers.forEach((root) => {
				this.connectUserTree(root, users, edges);
			});
		},

		connectUserTree(root, users, edges) {
			let childEdges = edges.filter((edge) => edge.parentID === root.id);
			childEdges.forEach((edge) => {
				if (!root.children.includes(users[edge.childID])) {
					root.children.push(users[edge.childID]);
				}
			});
			// if (root.children.length > 0) {
				root.children.forEach((child) =>
					this.connectUserTree(child, users, edges)
				);
			// }
		},

		addRootUser() {
			this.showAddRootUser = false;
			SalesManagementAPI.createRootUser(
				this.rootUserToAdd.userID,
				this.newCustomer.id
			);

			let newRootUser = CustomerUser.import({
				id: this.rootUserToAdd.userID,
				firstName: this.rootUserToAdd.firstName,
				lastName: this.rootUserToAdd.lastName,
				email: this.rootUserToAdd.emailAddress,
			});

			this.rootUsers.push(newRootUser);
			this.rootUserToAdd = null;
		},

		async deleteRootUser(user) {
			const choice = confirm(
				`Remove user ${user.id} - ${user.firstName} ${user.lastName}?`
			);
			if (!choice) return;

			const userIndex = this.rootUsers.indexOf(user);

			//todo: might need to make removeuserfromtree two separate functions
			//      because root user doesn't have any parents and could lead to errors
			await SalesManagementAPI.removeUserFromTree(
				user.id,
				user.id,
				this.newCustomer.id
			);
			this.rootUsers.splice(userIndex, 1);
		},


		async getSummary(cohortID) { this.$refs.externalTestReportSettings.open( cohortID ) },
	},
};
</script>


<style scoped>
#Interactions {
	max-height: 40vh;
	overflow: auto;
	margin-top: 2em;
}

fieldset {
	margin-bottom: 1em;
}

input,
textarea {
	border: 1px solid #ccc;
	transition: border 0.25s, background-color 0.25s;
}
input:hover,
textarea:hover,
input:active,
textarea:active,
input:focus,
textarea:focus {
	border: 1px solid #888;
	background-color: #f3f3f3;
	transition: border 0.25s, background-color 0.25s;
}
input[type="text"] {
	width: 20em;
	margin: 0.25em;
	padding: 0.25em;
}

.userRow {
	font-size: 12pt;
	padding: 0.5em;
	/* border-collapse: collapse; */
}
.userRow:hover {
	cursor: pointer;
	background: #f7f7f7;
}
</style>
