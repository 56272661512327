import Exim from '@/models/Exim.js'

class UserTimeSummary extends Exim {

	
	userCount = null                 // Total # users in dataset
	averageUserSeconds = null        // avg time per user
	averageSessionSeconds = null     // avg time per session
	totalSessionSeconds = null       // total time for all users
	averageSessionCount = null       // avg # sessions per user
	totalSessionCount = null         // total sessions for all users
	averageSecondsPerLicense = null  // average session time per license
	averageSessionsPerLicense = null // average session count per license
	averageLicenseCount = null       // average licenses per user
	totalLicenseCount = null         // total licenses for all users



	get userCountString() { return this._getNumericString( this.userCount ) }

	get averageUserSecondsString() { return this._getTimeString( this.averageUserSeconds) }
	get averageSessionSecondsString() { return this._getTimeString( this.averageSessionSeconds) }
	get totalSessionSecondsString() { return this._getTimeString( this.totalSessionSeconds) }

	get averageSessionCountString() { return this._getNumericString( this.averageSessionCount ) }
	get totalSessionCountString() { return this._getNumericString( this.totalSessionCount ) }
	get averageSecondsPerLicenseString() { return this._getTimeString( this.averageSecondsPerLicense ) }
	get averageSessionsPerLicenseString() { return this._getNumericString( this.averageSessionsPerLicense ) }
	get averageLicenseCountString() { return this._getNumericString( this.averageLicenseCount ) }
	get totalLicenseCountString() { return this._getNumericString( this.totalLicenseCount ) }
	
	
	_getNumericString( number ) { return number ? number.toLocaleString() : '&mdash;' }
	_getTimeString( seconds ) {
		if( seconds >= 3600 ) return (Math.round( (seconds / 360 ) ) / 10).toLocaleString() + ' hrs'
		else return Math.round( (seconds / 60 ) ).toLocaleString() + ' mins'
	}

	
}
export default UserTimeSummary