import Exim from '@/models/Exim.js'



class Taxonomy extends Exim {

	
	
	taxonomyID = null
	name = null
	attributes = []
	primaryKey = null
	displayKey = null
	display = false
	items = []
	allowMultiple = false
	taxonomyGroups = []


	getPrimaryKeyID() {
		if( !this.primaryKey ) return null

		for( var obj of this.attributes ) {
			if( obj.name === this.primaryKey ) return obj.id
		}
	}

	getDisplayKeyID() {
		if( !this.displayKey ) return null

		for( var obj of this.attributes ) {
			if( obj.name === this.displayKey ) return obj.id
		}
	}

}

export default Taxonomy;