<template>
<section>
	<div id="TopBar" class="flex-row flex-justify-between flex-align-center" :class="statusColor">
		<p style="font-size: 14pt; font-weight: bold; margin: 0;">Type:
			<select v-model="mode" style="font-size: 14pt;" @change="selectMode">
				<option value="proposal">Proposal</option>
				<option value="invoice">Invoice</option>
			</select>
		</p>
		<p style="font-size: 14pt; margin: 0;">Status: <b>{{newOrder.status ? newOrder.status() : 'N/A'}}</b></p>
		<button class='button mr-1 right' @click='saveOrder' :disabled='!changesMade'> Save Changes</button>
	</div>


	<section class="flex-row flex-gap-15 mb-3 px-1">
		<div class="flex-column flex-gap-15" style="width: 50%">
			<fieldset style="flex-grow: 1">
				<legend>People</legend>

				<div class="flex-row flex-align-center mb-05">
					<div>User: &nbsp;</div>
					<UserSearchDropdown v-if="user || editUser" v-model="user" placeholder="Search Existing Users" style="flex-grow:1;" />
					<span v-else-if="newOrder.userID">#{{newOrder.userID}} [user not found] <button @click="editUser = true">Change</button></span>
					<span v-else class="flex-row flex-gap-1">
						<button @click="editUser = true"><span class="icon-user" /> Attach Existing User</button>
						<button @click="createUser()"><span class="icon-user-plus" style="color: darkgreen;" /> Create New User</button>
					</span>
					<button v-if="!user && editUser" @click="editUser = false">cancel</button>
					<button v-if="user" @click="showUser = true">View</button>
				</div>
				<UserDetailsModal :show="showUser" :user="user" @close="showUser = false" height="80%" width="80%" maxWidth="1600px" />

				<div class="flex-row flex-align-center">
					<div>Customer: &nbsp;</div>
					<CustomerSearchDropdown v-model="customer" :initialValue="newOrder.customerName" style="flex-grow:1;" />
					<button v-if="customer" @click="showCustomer = true">View</button>
					<CustomerDetailsModal :show="showCustomer" :customer="customer" @close="showCustomer = false" width="80%" />
				</div>

			</fieldset>


			<fieldset class="flex-row flex-justify-center" style="flex-grow: 1; text-align: right;">
				<legend>Dates</legend>
				<div>
					<p>
						<span><b>Created</b> &nbsp; </span>
						<v-date-picker v-model="creationDateString" mode="dateTime">
							<template v-slot="{ inputValue, inputEvents }">
								<input class="date" placeholder="Order Origination Date" :value="inputValue" v-on="inputEvents" />
							</template>
						</v-date-picker>
					</p>

					<p v-if="mode == 'invoice'">
						<span><b>Invoiced</b> &nbsp; </span>
						<v-date-picker v-model="invoiceDateString" mode="dateTime">
							<template v-slot="{ inputValue, inputEvents }">
								<input class="date" placeholder="Invoice Date" :value="inputValue" v-on="inputEvents" />
							</template>
						</v-date-picker>
					</p>

					<p v-if="newOrder.dateCanceled" style="color: purple;">
						<span><b>Canceled</b> &nbsp; </span>
						<v-date-picker v-model="canceledDateString" mode="dateTime">
							<template v-slot="{ inputValue, inputEvents }">
								<input class="date" placeholder="Date Canceled" :value="inputValue" v-on="inputEvents" style="color: purple;" />
							</template>
						</v-date-picker>
					</p>

					<p v-if="mode == 'invoice'">
						<span><b>Due</b> &nbsp; </span>
						<v-date-picker v-model="dueDateString" mode="dateTime">
							<template v-slot="{ inputValue, inputEvents }">
								<input class="date" placeholder="Due Date" :value="inputValue" v-on="inputEvents" />
							</template>
						</v-date-picker>
					</p>
				</div>
			</fieldset>
		</div>


		<!-- Payment / Price section -->
		<fieldset class="flex-column flex-justify-between flex-align-center" style="width: 50%">
			<legend>
				Pay &amp; Fulfill
				<span class="link" v-if="!editPayment" @click="editPayment = allowEdit">[edit]</span>
				<span class="link" v-else @click="editPayment = false">[done]</span>
			</legend>

			<!-- Price & Payment (big digits) -->
			<section class="flex-row flex-justify-around flex-gap-3 w-100">
				<div id="price">
					<div class="center bold" style="font-size: 1.25em">Price</div>
					<div v-if="!editPayment" class="bold center" style="font-size: 2rem;">${{ !isNaN(newOrder.totalCost) ? newOrder.totalCost.toLocaleString(undefined, {minimumFractionDigits:2}) : '--' }}</div>
					<span v-if="editPayment" style="font-size: 2rem; font-weight: bold;">$<input type="number" min="0.00" step="1" v-model="newOrder.totalCostString" style="width:6em; font-size: 2rem; font-weight: bold;" /></span>
					<div style="text-align: center; font-size: 0.85em;">
						<!-- <span class="link" @click.stop="updatePrice">Recalculate Price</span> -->
						<span v-if="!newOrder.isCanceled && !newOrder.isPaid"><!-- &nbsp;|&nbsp; --> <span class="link" style="color:red;" @click.stop="cancelOrder">Cancel {{mode}}</span></span>
					</div>
				</div>
				<div id="payment">
					<div class="center bold" style="font-size: 1.25em">Paid</div>
					<div class="center bold" :style="{color: newOrder.isPaid ? 'green' : 'red'}" style="font-size: 2.5rem;">${{ newOrder.totalPaid ? newOrder.totalPaid.toLocaleString(undefined, {minimumFractionDigits:2}) : '--' }}</div>
					<div style="text-align: center; font-size: 0.85em;">
						<span v-if="newOrder.isPaid || (newOrder.transactions && newOrder.transactions.length)">
							<a :href="baseURL+'/ecommerceAPI/viewOrderReceipt/'+newOrder.orderID" target="_blank">View Receipt</a>
							|
							<span v-if="newOrder.orderID" class="link" @click.stop="sendReceipt()">Re-send Receipt</span>
						</span>
					</div>
				</div>
			</section>

			<!-- Transaction List -->
			<section class="flex-row flex-gap-1 flex-align-center">
				
				<div v-if="!txLoading && !txError && newOrder.transactions && newOrder.transactions.length" class="border round-05 pa-05 my-05" id="transactions">
					<table>
						<tr>
							<th>Amount</th>
							<th>Trans ID</th>
							<th>Auth Code</th>
							<th>Type</th>
							<th></th>
						</tr>
						<tr v-for="t in newOrder.transactions" :key="t.id">
							<td>
								${{ t.getOrderAmount( newOrder.orderID ) }}
								<span v-if="t.getOrderAmount( newOrder.orderID ) !== t.amount">*</span>
							</td>
							<td>{{ t.ccTransactionID }}</td>
							<td>{{ t.authCode }}</td>
							<td>{{ t.type }}</td>
							<td><div class="link" @click.stop="editTransaction(t)">details</div></td>
						</tr>
					</table>
				</div>

				<div class="">
					<div class="mb-05"><button class="SquareBlueButton tight" id="payButton" @click="takePayment"><span class="icon-credit-card" /> Take Credit Card</button></div>
					<div><button @click.stop="addTransaction()">Record Offline Transaction</button></div>
				</div>
				
			</section>

			<section>
				<div>
					<div v-if="newOrder.transactionID !== null">Order's TransactionID: <b>{{ newOrder.transactionID }}</b></div>
					<div v-if="newOrder.authCode !== null">Order's Auth Code: <b>{{ newOrder.authCode }}</b></div>
					<div v-if="newOrder.paymentMethod !== null">Order's Payment Method: <b>{{ newOrder.paymentMethod }}</b></div>
				</div>
				
				<div v-if='txLoading' class='flex justify-center'>
					<div class='icon-spinner4 spin-loader'></div>
				</div>
				
				<div v-if="txError" class="warning">
					Failed to get transactions
				</div>
			</section>


			<!-- <p>
				<b v-if="!editPayment">{{newOrder.subscriptionID || 'No Subscription'}}</b>
				<input v-else type="number" v-model="newOrder.subscriptionIDString" placeholder="Subscription ID" />
			</p> -->


			<!-- 3 Dates (PO, pay, fulfill) -->
			<section v-if="mode == 'invoice'" class="flex-row border-top mt-1" style="width: 100%;">

				<div class="col1-3 coldiv center">
					<div v-if="newOrder.poIsReceived">
						<div class="headline green">PO RECVD</div>
					</div>
					<div v-else>
						<div class="flex-row flex-justify-center flex-align-center">
							<button class="SquareBlueButton tight" id="POButton" @click="markPOReceived()">Receive PO</button>
							<v-date-picker v-model="POReceivedDateString" mode="date" @input="markPOReceived()" v-slot="{ inputEvents }" class="flex-column">
									<span class="icon-calendar" style="font-size: 2em; padding: 0.25em;" v-on="inputEvents" />
							</v-date-picker>
						</div>
						<span class="link inline-block" @click="markPOReceived()">Now</span> |
						<span v-if="newOrder.dateInvoiced !== null" class="link inline-block" @click="markPOReceived(newOrder.dateInvoiced)">Invoice</span>
						<span v-if="newOrder.dateInvoiced === null" class="link inline-block" @click="markPOReceived(newOrder.timestamp)">Creation</span>
					</div>

					<span>
						{{newOrder.POReceivedDateOnlyString}}
						<span v-if="POReceivedDateString" class="icon-cross red" @click="clearPOReceivedDate()" />
					</span>
				</div>


				<div class="col1-3 coldiv center">
					<div v-if="newOrder.isPaid">
						<div class="headline green">PAID</div>
					</div>
					<div v-else>
						<div class="flex-row flex-justify-center flex-align-center">
							<!-- <button class="SquareBlueButton tight" id="payButton" @click="takePayment">Pay Now</button> -->
							<div style="color:darkgray; font-size: 1.5em;">Payment Date</div>
							<v-date-picker v-if="newOrder.totalCost - newOrder.totalPaid <= epsilon" v-model="paidDateString" @input="markPaid()" mode="date" v-slot="{ inputEvents }" class="flex-column">
									<span class="icon-calendar" style="font-size: 2em; padding: 0.25em;" v-on="inputEvents" />
							</v-date-picker>
						</div>
						<div v-if="newOrder.totalCost - newOrder.totalPaid <= epsilon">
							<span class="link inline-block" @click="markPaid()">Now</span> |
							<span v-if="newOrder.dateInvoiced !== null" class="link inline-block" @click="markPaid(newOrder.dateInvoiced)">Invoice</span>
							<span v-if="newOrder.dateInvoiced === null" class="link inline-block" @click="markPaid(newOrder.timestamp)">Creation</span>
						</div>
					</div>

					<span>
						{{ newOrder.paidDateOnlyString }}
						<span v-if="paidDateString" class="icon-cross red" @click.stop="clearPaidDate()" />
					</span>
				</div>


				<div class="col1-3 col-last center">
					<div v-if="newOrder.isFulfilled">
						<div class="headline green">FULFILLED</div>
					</div>
					<div v-else>
						<span v-if="newOrder.isPaid || !newOrder.containsSubscription()">

							<div class="flex-row flex-justify-center flex-align-center">
								<button v-if="changesMade" class="disabled SquareBlueButton tight">Save changes to Fulfill</button>
								<button v-else class="SquareBlueButton tight" id="fulfillButton" @click="fulfillOrder">Fulfill</button>

								<v-date-picker v-model="fulfilledDateString" @input="markFulfilled()" mode="date" v-slot="{ inputEvents }" class="flex-column">
										<span class="icon-calendar" style="font-size: 2em; padding: 0.25em;" v-on="inputEvents" />
								</v-date-picker>
							</div>

							<span class="link inline-block" @click="markFulfilled()">Now</span> |
							<span v-if="newOrder.dateInvoiced !== null" class="link inline-block" @click="markFulfilled(newOrder.dateInvoiced)">Invoice</span>
							<span v-if="newOrder.dateInvoiced === null" class="link inline-block" @click="markFulfilled(newOrder.timestamp)">Creation</span>
						</span>
						<span v-if="!newOrder.isPaid && newOrder.containsSubscription()"> (must pay first due to included subscription)</span>
					</div>

					<span>
						{{newOrder.fulfilledDateOnlyString}}
						<span v-if="fulfilledDateString" class="icon-cross red" @click="clearFulfilledDate()" />
					</span>
				</div>

			</section> <!-- 3-column layout -->

		</fieldset> <!-- Payment section -->
	</section>



	<section class="flex-row flex-gap-15 mb-3 px-1">
		<div class="flex-column flex-gap-15" style="width: 34%">

			<fieldset>
				<legend>Contract Settings</legend>
				<div v-if="!newOrder.customerID">
					Add a customer to this order to modify Contract settings.
				</div>
				<label v-if="newOrder.customerID"><input type="checkbox" v-model="isContract" />Contract with renewal date</label>
				<br><br>
				<div v-if="isContract">Contract Renewal Date:<br>
					<v-date-picker v-model="contractRenewalDateString" mode="dateTime">
						<template v-slot="{ inputValue, inputEvents }">
							<input
							class="date"
							placeholder="No Renewal Date"
							:value="inputValue"
							v-on="inputEvents"
							/>
						</template>
					</v-date-picker>
					<div>(Creates reminder "task" attached to the customer)</div>
				</div>
				<br>
				<div v-if="isContract">License Expiration Date:<br>
					<v-date-picker v-model="contractExpirationDateString" mode="dateTime" @input="updateLicenseExpirationDates()">
						<template v-slot="{ inputValue, inputEvents }">
							<input
							class="date"
							placeholder="No Expiration Date"
							:value="inputValue"
							v-on="inputEvents"
							/>
						</template>
					</v-date-picker>
					<div>(Updates line-items' expiration dates)</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Commissions</legend>
				<CommissionWidget v-if="newOrder && newOrder.orderID" :hostType="'order'" :hostID="newOrder.orderID" :order="newOrder" />
			</fieldset>

			<fieldset v-if="mode == 'invoice'">
				<legend>Affiliate &amp; Sales Rep</legend>
				<p>
					<span class="flex-row">Affiliate: &nbsp; <AffiliateDetailsModal v-model="newOrder.affiliateID" view="general" link="details" /></span>
					<AffiliateSearchDropdown :affiliate="affiliate" :initialValue="newOrder.affiliateName" width="100%" @update="a => updateAffiliate(a)" />
					<span>Campaign Tag: <b>{{ newOrder.campaignTag }}</b></span>
				</p>
				<p>Landing Page: <b>{{landingPage ? landingPage.adminLabel : 'none'}}</b> &nbsp; <a v-if="affiliate" :href="`${baseURL}/affiliate/${affiliate.urlSlug}`" target="_new">visit</a></p>
				<p>Storefront: <b>{{storefrontSlug || 'none'}}</b> &nbsp; <a v-if="storefrontSlug" :href="`${baseURL}/storefront/${storefrontSlug}`" target="_new">visit</a></p>
				<p>Sales Rep: <b>{{salesRep || 'none'}}</b></p>
			</fieldset>

		</div>

		<fieldset style="width: 66%">
			<legend>Items Ordered</legend>
			<div
				v-for="(item, index) in newOrder.lineItems"
				:key="item.id"
				:class="{'statusOrange' : item.productIsGlobal || item.pricingOptionIsGlobal, 'statusRed' : !item.product || !item.pricingOption}"
			>
				<LineItemDetails
					:item="item"
					:allowEdit="allowEdit"
					:allowDelete="!newOrder.isPaid && !newOrder.isFulfilled"
					:hasContractExpirationDate="newOrder.dateContractExpires !== null"
					@update="updatePrice()"
					@deleteItem="deleteLineItem(index)"
				/>
			</div>
			<button v-if="!newOrder.isPaid" @click.stop="addLineItem"><span class="icon-plus" /> Add Item</button>
		</fieldset>

	</section>

	<section id="notes" class="flex-row flex-gap-15 mb-3 px-1">
		<fieldset style="width: 50%">
			<legend>Invoice Notes</legend>
			<textarea v-model="newOrder.customerNotes" style="width:100%; height: 8em;" />
		</fieldset>

		<fieldset style="width: 50%">
			<legend>Admin (Internal) Notes</legend>
			<textarea v-model="newOrder.adminNotes" style="width:100%; height: 8em;" />
		</fieldset>
	</section>

	<section class="flex-row flex-gap-15 mb-3 px-1">
		<fieldset style="flex-grow:4">
			<legend>Attachments</legend>
			<FileAttachments v-if="order.orderID" :expand="false" hostType="order" :hostID="order.orderID" />
		</fieldset>

		<!-- <fieldset style="flex-grow:1">
			<legend>DANGER</legend>
			<button @click="syncProductCopies">Sync w/ Products Table</button>
		</fieldset> -->
	</section>

	<div class="flex-row flex-justify-center"><button class="button" @click="displayInvoice">View Invoice fullscreen</button></div>
	<OrderInvoice v-if="newOrder" :order="newOrder" :customer="customer" />


	<StretchModal ref="takePayment" :clickToClose="false" :width="'70%'">
		<template #header>Take Payment for Order # {{ newOrder.orderID }}</template>
		<div class="ma-1">
			<PayInvoice
				v-if="newOrder.userID"
				:orderID="newOrder.orderID"
				:hasSubscription="hasSubscription"
				:totalPrice="newOrder.totalCost"
				:userID="newOrder.userID"
				:customer="customer"
				:fulfill="newOrder.isFulfilled"
				@paymentSuccess="paymentSuccess" />
			<div v-else>Please add a user to this order before taking a credit card payment</div>
		</div>
	</StretchModal>


	<TransactionModal :transaction="selectedTransaction" :create="isNewTransaction" :createWithUser="user" :createWithCustomer="customer" :orderID="newOrder.orderID" ref="transactionDetails" @close="closeTransaction()" />


</section>
</template>

<script>
import {base_url} from '@/Config.js'
import Order from '@/features/orders/Order.js'
import User from '@/features/Users/User.js'
import Customer from '@/features/SalesManagement/Customers/Customer.js'
import LandingPage from '@/features/SalesManagement/LandingPages/LandingPage.js'
import Transaction from './Transactions/Transaction.js'

import PaginatedRequest from '@/api/PaginatedRequest.js'
import EcommerceAPI from '@/api/EcommerceAPI.js'
import OrdersAPI from '@/api/OrdersAPI.js'
import UserAPI from '@/api/UserAPI.js'
import StorefrontAPI from '@/api/StorefrontAPI.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import Product from '@/models/Product.js'
import LineItem from './LineItem.js'
import PricingOption from '@/models/PricingOption.js'

import StretchModal from "@/components/utilities/StretchModal.vue"
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import LineItemDetails from './LineItemDetails.vue'
import OrderInvoice from './OrderInvoice.vue'
import PayInvoice from './PayInvoice.vue'
import FileAttachments from '@/features/FileAttachments/FileAttachments.vue'
import AffiliateDetailsModal from '@/features/SalesManagement/Affiliates/AffiliateDetailsModal.vue'
import TransactionModal from './Transactions/TransactionModal.vue'

export default {
	name: 'OrderDetails',

	components: {
		StretchModal,
		UserSearchDropdown,
		CustomerSearchDropdown,
		AffiliateSearchDropdown,
		LineItemDetails,
		OrderInvoice,
		PayInvoice,
		FileAttachments,
		AffiliateDetailsModal,
		TransactionModal,
		UserDetailsModal: () => import('@/features/Users/UserDetailsModal.vue'),
		CustomerDetailsModal: () => import('@/features/SalesManagement/Customers/CustomerDetailsModal.vue'),
		CommissionWidget: () => import('@/features/SalesManagement/CommissionWidget.vue'),
	},

	props: {
		order: Object
	},

	data() {
		return {
			txLoading: false,
			txError: false,
			epsilon: 0.009,

			newOrder: {},
			changeCounter: 0,
			editUser: false,
			editPayment: false,

			showUser: false,
			showCustomer: false,
			selectedTransaction: null,
			isNewTransaction: false,

			mode: 'proposal', // or 'invoice'
			isContract: false,

			user: null,
			customer: null,
			affiliate: null,
			landingPage: null,
			storefrontSlug: null,
			salesRep: null,

			nestedTitle: '',
			nestedComponent: '',
			nestedProps: () => {},
		}
	},

	created() {
		document.addEventListener("keydown", this.saveKeyboardEvent, false)
		this.initialize()
	},
	destroyed() {
		document.removeEventListener("keydown", this.saveKeyboardEvent, false)
	},


	computed: {

		baseURL() { return base_url },

		allowEdit() { return true },

		isV9() { return this.newOrder && this.newOrder.paymentMethod && this.newOrder.paymentMethod.toUpperCase().includes('V9') },

		changesMade() {
			this.changeCounter;
			if(!this.newOrder.export) {
				return false;
			}
			let changesMade = !this.order.isSame(this.newOrder);
			return changesMade;
		},

		isGlobal() {
			for(let item of this.order.lineItems) if(item.productIsGlobal || item.pricingOptionIsGlobal) return true
			return false
		},


		statusColor() {
			if(this.newOrder.dateCanceled) return 'statusCanceled'
			if(this.newOrder.isProposal) return 'statusBlue'
			if(this.newOrder.isPaid && this.newOrder.isFulfilled) return 'statusGreen';
			// if(this.newOrder.dateCanceled) return 'statusBlue';
			if(this.newOrder.isPaid) return 'statusRed';
			if(this.newOrder.isFulfilled) return 'statusRed';

			if(this.newOrder.dateDue) {
				let now = new Date();
				let due = new Date(this.newOrder.dateDue);
				if(now - due > 0) return 'statusRed';
			}
			return 'statusOrange'
		},


		hasSubscription() {
			if(!this.newOrder || !this.newOrder.lineItems) return false;
			for(let item of this.newOrder.lineItems) {
				if(item.pricingOption) return item.pricingOption.isSubscription;
			}
			return false;
		},



		creationDateString: {
			get() { return this.newOrder.creationDateString },
			set(value) {
				this.changeCounter++;
				if(value) value = new Date(value);
				else this.newOrder.timestamp = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newOrder.timestamp = value;
			}
		},
		invoiceDateString: {
			get() { return this.newOrder.invoiceDateString },
			set(value) {
				this.changeCounter++;
				if(value) value = new Date(value);
				else this.newOrder.dateInvoiced = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newOrder.dateInvoiced = value;
			}
		},
		POReceivedDateString: {
			get() { return this.newOrder.POReceivedString },
			set(value) {
				this.changeCounter++;
				if(value) value = new Date(value);
				else this.newOrder.datePurchaseOrderReceived = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newOrder.datePurchaseOrderReceived = value;
			}
		},
		paidDateString: {
			get() { return this.newOrder.paidDateString },
			set(value) {
				this.changeCounter++;
				if(value) value = new Date(value);
				else this.newOrder.datePaid = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newOrder.datePaid = value;
			}
		},
		dueDateString: {
			get() { return this.newOrder.dueDateString },
			set(value) {
				this.changeCounter++;
				if(value) value = new Date(value);
				else this.newOrder.dateDue = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newOrder.dateDue = value;
			}
		},
		fulfilledDateString: {
			get() { return this.newOrder.fulfilledDateString },
			set(value) {
				this.changeCounter++;
				if(value) value = new Date(value);
				else this.newOrder.dateFulfilled = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newOrder.dateFulfilled = value;
			}
		},
		canceledDateString: {
			get() { return this.newOrder.canceledDateString },
			set(value) {
				this.changeCounter++;
				if(value) value = new Date(value);
				else this.newOrder.dateCanceled = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newOrder.dateCanceled = value;
			}
		},
		contractRenewalDateString: {
			get() { return this.newOrder.contractRenewalDateString },
			set(value) {
				this.changeCounter++;
				if(value) value = new Date(value);
				else this.newOrder.dateContractRenews = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newOrder.dateContractRenews = value;
			}
		},
		contractExpirationDateString: {
			get() { return this.newOrder.contractExpirationDateString },
			set(value) {
				this.changeCounter++;
				if(value) value = new Date(value);
				else this.newOrder.dateContractExpires = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newOrder.dateContractExpires = value;
			}
		},

		hasContractExpirationDate() { return this.newOrder.dateContractExpires !== null },

		expirationDateMismatch() {
			var itemHasExpDate = false
			for(let item of this.newOrder.lineItems) {
				itemHasExpDate = (item.licenseExpirationDate !== null)
				if(this.hasContractExpirationDate != itemHasExpDate) return true
			}
			return false
		},

	},


	watch: {
		order() { this.initialize() },

		isGlobal(value) { console.debug("isGlobal:", value) },

		mode(value) {
			if(value == 'proposal') this.newOrder.isProposal = true
			else this.newOrder.isProposal = false
		},

		user(userObj) {
			this.newOrder.userID = userObj ? userObj.userID : null;
		},

		customer( cust ) { this.newOrder.customerID = cust ? cust.id : null; },

		isContract(value) {
			if(value && this.newOrder.dateContractExpires) return

			if(value) {
				// Contract expiration date
				const expDate = new Date()
				expDate.setFullYear(expDate.getFullYear() +1)
				this.newOrder.dateContractExpires = expDate

				// Contract renewal (payment) date
				const renewDate = new Date()
				renewDate.setYear(expDate.getFullYear())
				renewDate.setMonth(expDate.getMonth() -3)
				this.newOrder.dateContractRenews = renewDate

			} else {
				this.newOrder.dateContractExpires = null
				this.newOrder.dateContractRenews = null
			}
		},

		hasContractExpirationDate(value) {
			for(let item of this.newOrder.lineItems) {
				item.fulfill = !value
				item.renewOnAnyContract = value
				item.licenseExpirationDate = this.newOrder.dateContractExpires
			}
		},


	},



	methods: {

		saveKeyboardEvent(e) {
			if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)  && e.keyCode == 83) {
				console.debug("Save command received for order #"+this.newOrder.orderID);
				e.preventDefault();
				if(this.changesMade) this.saveOrder();
			}
		},


		async initialize() {
			if(!this.order) return null;
			this.user = null
			this.customer = null
			this.affiliate = null
			this.landingPage = null
			this.storefrontSlug = null
			this.salesRep = null

			let promises = [];

			// User
			if(this.order.userID) {
				let userPromise = UserAPI.getUser(this.order.userID).then(res => {
					this.user = User.import(res)
				}).catch( e => {
					console.error(e)
					this.user = null
				})
				promises.push(userPromise);
			}

			// Customer
			if(this.order.customerID) {
				let customerPromise = SalesManagementAPI.getCustomer(this.order.customerID).then(res => {
					this.customer = Customer.import(res);
				}).catch( e => {console.error(e)} )
				promises.push(customerPromise);
			}

			// Landing Page
			if(this.order.landingPageID) {
				let landingPromise = SalesManagementAPI.getLandingPageByID(this.order.landingPageID).then(res => {
					this.landingPage = LandingPage.import(res);
				}).catch(e => {console.error(e) })
				promises.push(landingPromise);
			}

			// storefront
			if(this.order.storefrontID) {
				let storePromise = StorefrontAPI.getStorefrontSlug(this.order.storefrontID).then(res => {
					this.storefrontSlug = res.slug
				}).catch(e => {console.error(e) })
				promises.push(storePromise);
			}

			// Sales Rep
			if(this.order.salesRepresentativeID) {
				let repPromise = SalesManagementAPI.getSalesRepresentative(this.order.salesRepresentativeID).then(res => {
					this.salesRep = res;
				}).catch(e => {console.error(e) })
				promises.push(repPromise);
			}


			var newOrder = null
			await OrdersAPI.getOrder(this.order.orderID).then( async res => {
				newOrder = Order.import(res)

				for(let lineItem of newOrder.lineItems) {
					if(!lineItem.product) {
						console.debug("NO PRODUCT EMBEDDED")
						let prodProm = EcommerceAPI.getProduct(lineItem.sku).then(res => {
							lineItem.product = Product.import(res)
							lineItem.productIsGlobal = true
						}).catch(err => console.debug(err))
						promises.push(prodProm)
					}
					if(!lineItem.pricingOption) {
						console.debug("NO PO EMBEDDED")
						let poProm = EcommerceAPI.getPricingOption(lineItem.pricingOptionID).then(res => {
							lineItem.pricingOption = PricingOption.import(res)
							lineItem.pricingOptionIsGlobal = true
						}).catch(err => console.debug(err))
						promises.push(poProm)
					}
				}

				await Promise.allSettled(promises).then( () => {
					this.order.lineItems = newOrder.lineItems
					this.order.commissions = newOrder.commissions
					this.order.transactions = newOrder.transactions
					this.newOrder = this.order.clone(true, true)
					this.mode = this.newOrder.isProposal ? 'proposal' : 'invoice'
					if(newOrder.dateContractExpires) this.isContract = true
					window.order = this.newOrder
				})
			}).catch(e => {console.error(e) })


		},



		selectMode(event) {
			var value = event.target.selectedOptions[0].value
			if(value == 'invoice' && this.newOrder.dateInvoiced === null) this.newOrder.dateInvoiced = new Date()
		},



		async fulfillOrder() {
			if( this.changesMade ) return

			var res = null
			try {
				res = await EcommerceAPI.fulfillOrder(this.newOrder.orderID);
			} catch (e) {
				console.debug(e)
				alert(e + '\n\nEnsure the order has a User associated with it, to whom licenses will be assigned')
				throw e
			}
			if(!res.dateFulfilled) {
				throw new Error("fulfillOrder failed - invalid or missing 'dateFulfilled' from server");
			}
			this.newOrder.dateFulfilled = new Date(res.dateFulfilled * 1000);
			this.order.dateFulfilled = new Date(res.dateFulfilled * 1000);
		},

		async markFulfilled(dateObj = null) {
			let ts = null
			if(dateObj) ts = Math.floor(dateObj.getTime() / 1000)
			else if(this.newOrder.dateFulfilled) {
				ts = Math.floor(this.newOrder.dateFulfilled.getTime() / 1000);
			}

			OrdersAPI.markOrderFulfilled(this.newOrder.orderID, ts)
			.then(res => {
				this.order.dateFulfilled = new Date(res.dateFulfilled * 1000);
				this.newOrder.dateFulfilled = new Date(res.dateFulfilled * 1000);
			}).catch(err => {
				alert("FAILED to mark order paid.\n\n"+err);
			})
		},

		clearFulfilledDate() {
			OrdersAPI.clearDateFulfilled( this.newOrder.orderID )
			.then(res => {
				const date = res.dateFulfilled
				this.order.dateFulfilled = date !== null ? new Date(res.date * 1000) : null
				this.newOrder.dateFulfilled = date !== null ? new Date(res.date * 1000) : null
			}).catch(err => {
				alert("FAILED to update payment date.\n\n"+err)
			})
		},

		markPOReceived(dateObj = null) {
			let ts = null
			if(dateObj) ts = Math.floor(dateObj.getTime() / 1000)
			else if(this.newOrder.datePurchaseOrderReceived) {
				ts = Math.floor(this.newOrder.datePurchaseOrderReceived.getTime() / 1000)
			}

			OrdersAPI.markPOReceived(this.newOrder.orderID, ts)
			.then(res => {
				this.order.datePurchaseOrderReceived = new Date(res.datePurchaseOrderReceived * 1000)
				this.newOrder.datePurchaseOrderReceived = new Date(res.datePurchaseOrderReceived * 1000)
			}).catch(err => {
				alert("FAILED to update purchase order received date.\n\n"+err)
			})
		},

		clearPOReceivedDate() {
			OrdersAPI.clearDatePOReceived( this.newOrder.orderID )
			.then(res => {
				const date = res.datePurchaseOrderReceived
				this.order.datePurchaseOrderReceived = date !== null ? new Date(res.date * 1000) : null
				this.newOrder.datePurchaseOrderReceived = date !== null ? new Date(res.date * 1000) : null
			}).catch(err => {
				alert("FAILED to update purchase order received date.\n\n"+err)
			})
		},

		markPaid(dateObj = null) {
			let ts = null;
			if(dateObj) ts = Math.floor(dateObj.getTime() / 1000)
			else if(this.newOrder.datePaid) {
				ts = Math.floor(this.newOrder.datePaid.getTime() / 1000);
			}

			OrdersAPI.markOrderPaid(this.newOrder.orderID, ts)
			.then(res => {
				this.order.datePaid = new Date(res.datePaid * 1000);
				this.newOrder.datePaid = new Date(res.datePaid * 1000);
			}).catch(err => {
				alert("FAILED to mark order paid.\n\n"+err);
			})
		},

		clearPaidDate() {
			OrdersAPI.clearDatePaid( this.newOrder.orderID )
			.then(res => {
				const date = res.datePaid
				this.order.datePaid = date !== null ? new Date(res.date * 1000) : null
				this.newOrder.datePaid = date !== null ? new Date(res.date * 1000) : null
			}).catch(err => {
				alert("FAILED to update payment date.\n\n"+err)
			})
		},

		takePayment() {
			this.$refs.takePayment.open()
		},

		paymentSuccess() {
			alert("Order paid!");
			this.$refs.takePayment.close()
			const now = new Date();
			this.newOrder.datePaid = now;
			this.order.datePaid = now;
		},

		createUser() {
			this.user = new User();
			this.showUser = true;
		},

		saveOrder() {
			if(this.expirationDateMismatch) {
				let yes = confirm("This order has an unusual configuration with respect to contract expiration vs license expiration dates.\n\nSave anyway?")
				if(!yes) return
			}

			if( !this.newOrder.userID && this.user && this.user.userID ) this.newOrder.userID = this.user.userID

			OrdersAPI.updateOrder(this.newOrder.export())
			.then( () => {
				this.$emit( 'orderSaved', this.newOrder.clone(true) );
				console.log(`order #${this.newOrder.orderID} saved`);
				this.changeCounter++;
			})
		},

		// async syncProductCopies() {
		// 	const newOrder = await OrdersAPI.syncProductCopies(this.newOrder.orderID)
		// 	this.newOrder.lineItems = newOrder.lineItems
		// },

		updateLicenseExpirationDates() {
			for(let item of this.newOrder.lineItems) {
				item.licenseExpirationDate = this.newOrder.dateContractExpires
			}
		},

		updateAffiliate( aff ) {
			this.affiliate = aff
			this.newOrder.affiliateID = aff ? aff.id : 0
		},

		displayInvoice() {
			let routeData = this.$router.resolve({name: 'displayinvoice', params: {orderID: this.newOrder.orderID}});
			window.open(routeData.href, '_blank');
		},


		sendReceipt() {
			if( !this.newOrder.orderID ) return
			if( !this.newOrder.userID ) {
				alert('Please specify a user to send the receipt to.')
				return
			}

			OrdersAPI.sendReceiptEmail( this.newOrder.orderID, this.newOrder.userID )
			.then( () => { alert('Receipt sent via email.') } )
			.catch( () => { alert('ERROR: Receipt NOT sent.') } )
		},


		addLineItem() {
			if(this.newOrder.isPaid) return
			const li = new LineItem()
			li.quantity = 1
			this.newOrder.addLineItem( li )
		},


		deleteLineItem(index) {
			if(this.newOrder.isPaid || this.newOrder.isFulfilled) {
				alert(`You cannot delete items from an order that's been paid or fulfilled`)
				return
			}
			const yes = confirm( `Are you sure you want to delete item #${index+1}?` )
			if(!yes) return

			this.newOrder.deleteLineItem(index)
		},

		updatePrice() { this.newOrder.updatePrice() },


		cancelOrder() {
			let yes = confirm(`Are you sure you want to cancel this ${this.mode}?\n\nNote: you can still access this ${this.mode} by viewing 'canceled' orders.`)
			if(!yes) return

			let ts = null
			if(this.newOrder.dateCanceled) {
				ts = Math.floor(this.newOrder.dateCanceled.getTime() / 1000)
			}

			OrdersAPI.cancelOrder(this.newOrder.orderID, ts)
			.then(res => {
				this.order.dateCanceled = new Date(res.dateCanceled * 1000)
				this.newOrder.dateCanceled = this.order.dateCanceled
			}).catch(err => {
				alert("FAILED to cancel order.\n\n"+err);
			})
		},


		fixOrder() {
			if( !this.isV9 ) {
				alert("Not a v9 order.  Doing nothing...")
				return
			}


			console.debug('Moving license keys...')
			for(let lineItem of this.newOrder.lineItems) {
				if(lineItem.licenseKeys.length) {
					if(lineItem.newLicenseKeys.length) {
						alert("There are both renewed and NEW license keys. Aborting...")
						return
					}
					for(let key of lineItem.licenseKeys) {
						lineItem.newLicenseKeys.push(key)
					}
					lineItem.licenseKeys = []
				}
			}


			console.debug('Attempting to set poID\'s...')
			if(this.newOrder.lineItems.length == 1) {
				var lineItem = this.newOrder.lineItems[0]
				if(lineItem.quantity == 1 && !lineItem.pricingOptionID) {
					var prod = this.$store.state.Products.find(p => p.sku == lineItem.sku)
					var poArray = prod.pricingOptions ? prod.pricingOptions : []
					for(let po of poArray) {
						if( Math.abs(this.newOrder.totalCost - po.initialPrice) < 0.01 ) {
							lineItem.pricingOptionID = po.id
							lineItem.price = po.initialPrice
							lineItem.subscriptionPrice = po.subscriptionPrice
						}
					}
				}
			}

		},



		async getTransactions() {
			this.txError = false
			this.txLoading = true
			
			try {
				const req = new PaginatedRequest()
				req.orderIDs = [ this.newOrder.orderID ]
				req.paginate = false
				req.includeBillingAddress = true

				const res = await OrdersAPI.getTransactions( req )
				
				this.newOrder.transactions = []
				for( var item of res.data ) {
					const t = Transaction.import( item )
					this.newOrder.transactions.push( t )
					if( this.selectedTransaction && this.selectedTransaction.id === t.id ) this.selectedTransaction = t
				}
			
			} catch (e) {
				this.txError = true
			
			} finally {
				this.txLoading = false
			}
		},


		addTransaction() {
			this.selectedTransaction = new Transaction()
			this.selectedTransaction.timestamp = new Date()

			// Set defaults if recording an external (v9) Tx for an already-paid order
			if( this.newOrder.datePaid !== null && !this.newOrder.transactions || !this.newOrder.transactions.length ) {
				this.selectedTransaction.timestamp = this.newOrder.datePaid
				this.selectedTransaction.type = 'creditcard'
				this.selectedTransaction.amount = this.newOrder.totalCost
				this.selectedTransaction.ccTransactionID = this.newOrder.transactionID
				this.selectedTransaction.authCode = this.newOrder.authCode
				this.selectedTransaction.paymentMethod = this.newOrder.paymentMethod
			}
			this.isNewTransaction = true
			this.$refs.transactionDetails.open()
		},
		
		
		editTransaction( transaction ) {
			this.selectedTransaction = transaction
			this.$refs.transactionDetails.open()
		},


		async closeTransaction() {
			await this.getTransactions()

			this.markIfPaid()

			this.isNewTransaction = false
			this.selectedTransaction = null
		},


		markIfPaid() {

			if( !this.isNewTransaction ) return
			if( this.newOrder.datePaid !== null ) return

			const orderTotal = this.newOrder.totalCost
			
			var recentPayDate = null
			var amtPaid = 0

			for( var tx of this.newOrder.transactions ) {
				amtPaid += tx.getOrderAmount( this.newOrder.orderID )
				if( recentPayDate === null || recentPayDate < tx.timestamp ) recentPayDate = tx.timestamp
			}

			if( recentPayDate === null ) return
			if( orderTotal - amtPaid <= this.epsilon ) this.markPaid( recentPayDate )

		},



	}



}
</script>

<style scoped>
input {
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 0.25em;
	padding: 0.25em 0.5em;
	font-weight: bold;
}
input.date { width: 175px; }

#TopBar {
	padding: 0.75em 0 0.75em 0.75em;
	margin-bottom: 1.5em;
}

#transactions {
	max-height: 7em;
	overflow: auto;
}

#transactions table td {
	padding: 0.1em 0.5em;
}

.headline { font-size: 1.5em; font-weight: bold; }
.green { color: green; }
.red { color: red; }

.statusBlue { background-color: skyblue; }
.statusRed { background-color: pink;}
.statusOrange { background-color: orange;}
.statusGreen { background-color: lightgreen;}
.statusCanceled { background-color: purple; color: white !important; }
</style>
