import API from './API.js';

class ExternalTestsAPI extends API {

	static controllerName = 'ExternalTestsAPI'

	static async getDataEntryReport(customerID = null) {
		return await this.newGet('getDataEntryReport', [customerID])
	}


	static async getCohortTestSummary(cohortID, customerID) {
		return await this.newGet('getCohortTestSummary', [cohortID, customerID])
	}


	static async downloadCohortTestReport( cohortID, customerID, reqBody = {} ) {
		const res = await this.newPost('getCohortScores', [cohortID, customerID], reqBody, 'blob')
		
		const downloadURL = window.URL.createObjectURL(res.data)
		window.open(downloadURL, '__blank')
		window.URL.revokeObjectURL(downloadURL)
	}

}

export default ExternalTestsAPI