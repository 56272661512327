<template>
<section>
	<StretchModal maxWidth="1600px" minHeight="60vh" maxHeight="90vh" ref="modal" @close="$emit('close')">
    <template #header>Scheduled Registration Emails for {{ selectedItem.customerName }} on {{ scheduledDate }}</template>
		
		<section class="ma-2">
			<section v-if="!loading">
				<ObjectTable
					:Columns="columns"
					:Source="items"
					:Numbered="true"
					:Deletable="true"
					:SortBy="sortBy"
					:SortAsc="sortAsc"
					:PageNum="page"
					:PageSize="pageSize"
					@sort="prop => sort(prop)"
					@delete="item => showDeleteSelected(item)"
					class="mt-1"
					ref="objectTable"
				/>
				<Paginator v-model="page" @input="initialize()" :numPages="pages" />
			</section>

			<div v-if="!loading && !error && !items.length" class="NoResults">No Records</div>

			<section v-if='loading' class='flex justify-center'>
				<div class='icon-spinner4 spin-loader'></div>
			</section>

			<section v-if="error" class="warning">
				Failed to get records
			</section>

			<ConfirmDialog ref="confirmDelete" @cancel="itemToDelete = null" @confirm="deleteItem()">
				<div v-if="itemToDelete">Un-schedule registration email for {{ itemToDelete.firstName }} {{ itemToDelete.lastName }}?</div>
			</ConfirmDialog>

		</section>

	</StretchModal>
</section>
</template>



<script>
import StretchModal from '@/components/utilities/StretchModal.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

import MessageAPI from '@/api/MessageAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'

export default {
	name: 'ScheduledRegistrationListModal',

  components: {
    StretchModal,
		ObjectTable,
    Paginator,
		ConfirmDialog,
  },


	props: {
		selectedItem: Object,
	},


	data() {
    return {
			items: [],

			page: 1,
			pages: 0,
			pageSize: 100,

			sortBy: 'scheduledDate',
			sortAsc: true,

			loading: false,
			error: false,

			itemToDelete: null,

			columns: [
				{
					displayName: 'User ID',
					propertyName: 'userID',
					sortable: true
				},
				{
					displayName: 'User',
					propertyName: 'email',
					sortByName: 'firstName',
					displayFunction: function( item ) {
						return `<div>${item.firstName} ${item.lastName}</div><div>${item.email}</div>`
					},
					sortable: true
				},
				{
					displayName: 'Customer',
					propertyName: 'customerName',
          sortable: true
				},
				{
					displayName: 'Scheduled To Send',
					propertyName: 'scheduledDate',
					displayFunction: function( item ) {
						if( !item.scheduledDate ) return ''
						const d = new Date( item.scheduledDate * 1000 )
						return d.toLocaleDateString()
					},
					sortable: true
				},
				{
					displayName: 'Sent',
					propertyName: 'sent',
					sortable: true
				},
				{
					displayName: 'Date Sent',
					propertyName: 'dateSent',
					displayFunction: function( item ) {
						if( !item.dateSent ) return ''
						const d = new Date( item.dateSent * 1000 )
						return d.toLocaleDateString()
					},
          sortable: true
				}
			]
    }
  },



	computed: {
		scheduledDate() {
			if( !this.selectedItem ) return ''

			const d = new Date( this.selectedItem.scheduledDate * 1000 )
			return d.toLocaleDateString()
		}
	},



	watch: {
		selectedItem( value ) {
			if( !value ) return

			this.$refs.modal.open()
			this.initialize()
		}
	},



	methods: {
		
		async initialize() {

			this.loading = true
			this.error = false
			
			this.items = []

			try {
				var req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
				req.customerID = this.selectedItem.customerID
				req.scheduledDate = this.selectedItem.scheduledDate

				const res = await MessageAPI.getScheduledRegistrationEmails( req )
				this.items = res.data
				this.pages = res.pages

			} catch( err ) {
				this.error = true
				console.error( err )

			} finally {
				this.loading = false
			}
		},

		sort( propName ) {
			if( this.sortBy === propName ) {
				this.sortAsc = !this.sortAsc
				this.initialize()
				return
			}

      this.sortBy = propName
			this.sortAsc = true
			this.initialize()
		},


		showDeleteSelected( item ) {
			this.itemToDelete = item
			this.$refs.confirmDelete.open()
		},

		async deleteItem() {
			if( !this.itemToDelete ) return

			try {
				await MessageAPI.unscheduleRegistrationEmails( this.itemToDelete.scheduledDate, this.itemToDelete.customerID, this.itemToDelete.userID )
			} finally {
				this.initialize()
			}
		}

	}

}
</script>



<style scoped>

</style>