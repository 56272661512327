<template>
  <div>

    <section class='ActionButtons'>
      <button class='button mr-1' @click='save' :disabled='!changesMade'> Save Changes</button><br><br>
      <button class='button' @click="openProductImages">Images</button>
    </section>

    <section class='DetailSection'>

      <h1 class="section-header">Basic Information</h1>

      <div class="form-entry-block">
        <label class='flex-column'>
          <span>SKU:</span>
          <input type="text" v-model="newProduct.sku" :disabled='disableSKUEditing' />
        </label>
      </div>

      <div class="form-entry-block">
        <label class='flex-column'>
          <span>Product Name:</span>
          <input type="text" v-model="newProduct.name" />
        </label>
      </div>

      <div class="form-entry-block">
        <label class='flex-column'>
          <span>Short Name:</span>
          <input type="text" v-model="newProduct.shortName" />
        </label>
      </div>

      <div class="form-entry-block">
        <label class='flex-column'>
          <span>Description:</span>
          <textarea v-model="newProduct.description" />
        </label>
      </div>

      <div class="form-entry-block">
        <label class='flex-column'>
          <span>Short Description:</span>
          <textarea v-model="newProduct.shortDescription" />
        </label>
      </div>

      <div class="form-entry-block">
        <label class='flex-column'>
          <span>Invoice Description:</span>
          <textarea v-model="newProduct.invoiceDescription" />
        </label>
      </div>

      <div class="form-entry-block">
        <label>
          <input type="checkbox" v-model="newProduct.teacherChatEnabled" />
          <span>Teacher Chat Enabled</span>
        </label>
      </div>

      <div class="form-entry-block">
        <label>
          <input type="checkbox" v-model="newProduct.showInStore" />
          <span>Show In Store</span>
        </label>
      </div>
    </section>

    <section class='DetailSection'>
      <h1 class="section-header">Product Type</h1>
      <div class="form-entry-block">
        <p><b>{{ isUpgrade ? "Upgrade Product" : "Base Product" }}</b>&nbsp;&nbsp;<a class="link" @click="isUpgrade = !isUpgrade">[switch to {{ isUpgrade ? "Base Product" : "Upgrade" }}]</a></p>
        <p v-if="isUpgrade">This product will be sold as an <strong>add-on</strong> to the following products:</p>
        <p v-else>This product will be sold as a <strong>"base" product</strong> (i.e. it will create new license(s)).</p>
      </div>

      <div v-if="isUpgrade" class="ml-1">
        <div v-for="product in $store.state.Products" :key='product.sku'>
          <label>
            <input type="checkbox" :checked="newProduct.upgradableFrom.includes(product.sku)" @change="toggleUpgradableFrom(product.sku)" />
            <span :class="{'selectedText' : newProduct.upgradableFrom.includes(product.sku)}">{{product.sku}}</span>
          </label>
        </div>
      </div>
    </section>



    <section class='DetailSection'>
      <h1 class="section-header">Pricing Options</h1>

      <div v-for="(pricingOption, index) in newProduct.pricingOptions" :key="pricingOption.id" class="flex-row flex-align-center relative" style="border: 1px solid black; padding: 0.5em; margin: 0.5em 0;" >
        <div>
          <div @click.prevent="newProduct.swapPricingOptions(index, index-1)" class="icon-arrow-up" />&nbsp;
          <div @click.prevent="newProduct.swapPricingOptions(index, index+1)" class="icon-arrow-down" />
        </div>&nbsp;
        <span>ID:<br>{{pricingOption.id}}</span>
        <label class='flex-column form-entry-block'>
          <span>Display Name:</span>
          <input type="text" style="width:14em;" v-model="pricingOption.name" />
          <label><input type="number" style="width:4em;" v-model="pricingOption.defaultQuantity" /> Default Quantity</label>
          <label><input type="checkbox" v-model="pricingOption.isSponsored" /> Sponsored Pricing</label>
          <label><input type="checkbox" v-model="pricingOption.perUnitPricing" /> Show unit price on invoice</label>
        </label>


        <label class='flex-column form-entry-block'>
          <span>Initial Price / unit ($):</span>
          <input type="number" style="width:7em;" v-model="pricingOption.initialFullPrice" step='.01' />
          <span>Sale Price / unit ($):</span>
          <input type="number" style="width:7em;" v-model="pricingOption.initialSalePrice" step='.01' />
          <div><input type="checkbox" v-model="pricingOption.itemizeDiscountOnInvoice" /> Itemize discount on invoice</div>
          <input v-model="pricingOption.saleDescription" placeholder="Sale Price Description (for invoice)" />
        </label>


        <div v-if="!isUpgrade" class='flex-column form-entry-block'>
          <span>License Length:</span>
          <input type="number" class="mr-1" style="width:4em;" v-model="pricingOption.licenseLength" />
          <select v-model='pricingOption.lengthUnit'>
            <option value='days'>days</option>
            <option value='months'>months</option>
            <option value='unlimited'>unlimited</option>
          </select>
        </div>


        <span v-if="!isUpgrade" class='flex-column form-entry-block'>
        </span>


        <label class='flex-column form-entry-block'>
          <label>
            <input type="checkbox" v-model="pricingOption.isSubscription" />
            <span :class="{'selectedText' : pricingOption.isSubscription}">Subscription</span>
          </label>
          <div>
            <span>Full sub $:</span>
            <input type="number" style="width:7em;" v-model="pricingOption.subscriptionFullPrice" step='.01' :disabled="!pricingOption.isSubscription && !isUpgrade" />
          </div>
          <div>
            <span>Sale sub $:</span>
            <input type="number" style="width:7em;" v-model="pricingOption.subscriptionSalePrice" step='.01' :disabled="!pricingOption.isSubscription && !isUpgrade" />
          </div>
        </label>


        <span v-if="isUpgrade" class="flex-column form-entry-block">
          <span>Upgrade from PricingOption:</span>
          <select @change="toggleUpgradableFromPricingOption($event.target.value, pricingOption.id)">
            <option :value="null">SELECT</option>
            <option v-for="po in upgradableFromPricingOptions" :key="po.id" :value="po.id" :selected="newProduct.upgradableFromPricingOption(po.id, pricingOption.id)">
              {{po.id}}: {{po.name}} ({{po.product ? po.product.name : ''}})
            </option>
          </select>
        </span>

        <div class="flex-grow ml-1 border-left pl-2">
          <div><b>Product description</b> <br>(overrides product's short description)</div>
          <textarea :placeholder="newProduct.shortDescription" v-model="pricingOption.description" style="min-height: 10em; width: 100%; max-width: 40em;" />
        </div>

        <span class="icon-cross absolute" style="right: 0.5em; top: 0.5em;" @click.stop="deletePricingOption(index)" />

      </div>
      <div class="flex-row flex-justify-end"><button class="SquareBlueButton" @click.stop="addPricingOption"><span class="icon-plus" /> Add Pricing Option</button></div>
    </section>



    <section class='DetailSection'>
      <h1 class="section-header">Courses &amp; Variations</h1>
      <p v-if="isUpgrade">This is an <b>"upgrade"</b> product.  <br>Buyers will receive the selected variation(s) on the <b>SINGLE</b> course/license they purchased this upgrade for.</p>
      <p v-else>This is a <b>"base"</b> product.  <br>A buyer of this product will receive new licenses for <b>ALL</b> courses selected below, along with the selected variations.</p>
      <div v-for="course in $store.state.Courses" :key='course.courseID' class="mb-1">
        <label>
          <input type="checkbox" :checked="newProduct.hasCourseID(course.courseID)" @change="toggleCourse(course.courseID)" />
          <span :class="{'selectedText': newProduct.hasCourseID(course.courseID)}">{{course.courseName}} ({{ course.courseVariations.length }} variations)</span>
        </label>
        <div v-if="newProduct.hasCourseID(course.courseID)" class="ml-1">
          <div v-for="variation in course.courseVariations" :key='variation.id'>
            <label>
              <input type="checkbox" :checked="newProduct.hasVariation(variation.id)" @change="toggleVariation(variation.id)" />
              <span :class="{'selectedText': newProduct.hasVariation(variation.id)}">{{variation.name}} ({{variation.id}})</span>
            </label>
          </div>
        </div>
      </div>
    </section>

    <StretchModal ref="productImages" :clickToClose="false">
      <template #header>Product Images</template>
      <ProductImages :sku="Product.sku" />
    </StretchModal>

  </div>
</template>

<script>
import StretchModal from '@/components/utilities/StretchModal.vue'
import Product from '@/models/Product.js';
import ProductsAPI from '@/api/ProductsAPI.js';
import PricingOption from '@/models/PricingOption';

import ProductImages from './ProductImages.vue';

export default {
  name: 'ProductDetails',

  components: {
    StretchModal,
    ProductImages,
  },

  props: {
    Product: Product,
    EditMode: Boolean
  },

  data() {
    return {
      newProduct: new Product(),
      initialSKU: '',
      disableSKUEditing: false,
      isUpgrade: false,
      changeCounter: 0,
    }
  },


  created() {
    this.newProduct = this.cloneProduct( this.Product );
    this.initialSKU = this.Product.sku;
    if (this.initialSKU) {
      this.disableSKUEditing = true;
    }

    // Product Type (upgrade vs base)
    if(this.newProduct.upgradableFrom.length) this.isUpgrade = true;
    else this.isUpgrade = false;
  },


  computed: {
    changesMade() {
      this.changeCounter;
      let changesMade = !this.Product.isSame(this.newProduct);
      return changesMade;
    },

    showLicenseInterval() { return this.newProduct.licenseLengthUnit !== 'unlimited'; },

    upgradableFromPricingOptions() {
      let poArray = [];
      for(let sku of this.newProduct.upgradableFrom) {
        console.log("Upgradable from:", sku);
        let prod = this.$store.state.Products.findByAttribute('sku', sku);
        if(!prod) continue;
        for(let po of prod.pricingOptions) poArray.push(po);
      }

      return poArray;
    },
  },


  watch: {
    isUpgrade(value) {
      if(!value) this.newProduct.upgradableFrom.length = 0;
    },
  },


  methods: {
    async save() {
      this.verifySKUExists();

      try {
        if (this.EditMode) {
          await ProductsAPI.editProduct(this.newProduct, this.initialSKU);
          this.$store.state.Products.updateElement( 'sku', this.newProduct )
          this.$emit( 'change', this.newProduct )
          this.newProduct = this.cloneProduct( this.newProduct );
        } else {
          await ProductsAPI.addProduct(this.newProduct)
          this.$store.state.Products.push( this.newProduct )
          this.$refs.productImages.close()
        }


      } catch (e) {
        console.error(e);
        alert(`Could not save changes: ${e}`);
      }

      this.changeCounter++
    },

    cloneProduct( prod ) {
      return Product.import( prod.export() );
    },

    verifySKUExists() {
      if (!this.newProduct.sku) {
        alert('Please enter a SKU for this product.');
        throw new Error('Missing property "sku".');
      }
    },


    addPricingOption() {
      this.newProduct.pricingOptions.push(new PricingOption());
    },
    deletePricingOption(index) {
      const yes = confirm("Really delete this pricing option?")
      if( !yes ) return

      this.newProduct.pricingOptions.splice(index, 1);
    },

    openProductImages() {
      this.verifySKUExists();
      this.$refs.productImages.open()
    },


    toggleCourse(id) {
      this.changeCounter++;
      if(this.newProduct.hasCourseID(id)) this.newProduct.removeCourseID(id);
      else this.newProduct.addCourseID(id);
    },

    toggleVariation(id) {
      this.changeCounter++;
      if(this.newProduct.hasVariation(id)) this.newProduct.removeVariation(id);
      else this.newProduct.addVariation(id);
    },

    toggleUpgradableFrom(sku) {
      this.changeCounter++;
      if(this.newProduct.hasUpgradableFrom(sku)) this.newProduct.removeUpgradableFrom(sku);
      else this.newProduct.addUpgradableFrom(sku);
    },

    toggleUpgradableFromPricingOption(fromID, toID) {
      this.changeCounter++;
      this.newProduct.removeUpgradePricingOption(toID)
      this.newProduct.addUpgradePricingOption(fromID, toID);
    }


  }
}
</script>

<style scoped>
.ActionButtons {
  position: fixed;
  top: calc(50vh - 5em);
  right: 1em;

  padding: 0.5em;
  background: white;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  box-shadow: 2px 2px 12px 0 #888;
}

.form-entry-block {
  margin: auto 1em;
}
.form-entry-block input {
  margin: 0.25em 0;
}
</style>
