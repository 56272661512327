import Exim from '@/models/Exim.js'
import Address from './Address.js'

class Transaction extends Exim {

	
	id = null;
	ccTransactionID = null;
	authCode = null;
	type = null;
	timestamp = null;
	amount = null;
	currency = 'USD';
	paymentMethod = null;
	userID = null;
	customerID = null;
	pocID = null;
	notes = null;

	billingAddress = null;

	// Getter-only (optional) props
	orderAssignments = null;
	userFirstName = null
	userLastName = null
	customerName = null
	pocFirstName = null
	pocLastName = null


	get orderAssignmentTotal() {
		if( !this.orderAssignments ) return 0
		return this.orderAssignments.reduce( (acc, elem) => acc + elem.amount, 0 )
	}
	
	
	getOrderAmount( orderID ) {
		if( !Array.isArray(this.orderAssignments) ) return null;
		for( var a of this.orderAssignments ) {
			if( a.orderID !== orderID ) continue
			return a.amount
		}
	}



	addOrderAssignment( item ) {
		this.orderAssignments.push( item )
	}
	
	deleteOrderAssignment( orderID ) {
		const idx = this.orderAssignments.findIndex( oa => oa.orderID == orderID )
		this.orderAssignments.splice( idx, 1 )
	}



	isSame( other ) {
		return JSON.stringify( this.export() ) == JSON.stringify( other.export() )
	}
	
	clone() {
		return Transaction.import( this.export() )
	}



	static import( obj ) {
		const item = super.import( obj )
		if( item.timestamp ) item.timestamp = new Date( item.timestamp * 1000 )
		if( item.billingAddress ) item.billingAddress = Address.import( item.billingAddress )
		return item
	}



	export() {
		const data = super.export()
		if( this.timestamp ) data.timestamp = Math.round( this.timestamp.getTime() / 1000 )
		return data
	}



}

export default Transaction