<template>
<section style="width: 100%;">
  <p v-if="isTemplate">Image Block</p>

  <div class='flex-column' style="width: 100%;">
    
    <div class="flex-row flex-justify-between">
      <div v-if="isTemplate">
        <input type="text" v-model="section.name" placeholder="Block Label (admin)" /><br /><br />
        <label><input type="checkbox" v-model="section.showMobile" /> Show on Mobile (&lt;700px wide)</label><br />
        <label><input type="checkbox" v-model="section.fullwidth" /> Fullwidth</label><br /><br />
      </div>
      <div v-else>{{section.name}}<span style="color: #888; font-size: 0.8em;"> - Image</span></div>
      
      <div v-if="!isTemplate && !isDefault" id="revert" @click="revert"><span class="icon-undo" /> Revert</div>
    </div>
    <div>Minimum Width: <input v-model="section.minWidth" @input="update" placeholder="None" class="invisible" :class="{'default': isDefault}" /></div>

    <input type="text" v-model="section.imageName" @input="update" placeholder="Image file name" class="invisible" :class="{'default': isDefault}" />
  </div>
</section>
</template>


<script>
export default {
  name: "LandingPageImageSection",

  props: {
    section: Object,

    isTemplate: {
      type: Boolean,
      default: true
    },

    isDefault: {
      type: Boolean,
      default: false
    },

  },

  methods: {
    update(e) { this.$emit('update'); },
    revert() { this.$emit('revert'); },
  }
}
</script>


<style scoped>
input, textarea { padding: 0.5em !important; }

.default {
  color: #777;
}
#revert {
  cursor: pointer;
}
</style>
