<template>
<section>

	<div class="mb-1 flex-row flex-justify-center flex-align-center flex-gap-1">
		<div class="link center" style="font-size: 1.5em; font-weight: bold;" @click.stop="getAssignedUsers()">
			<span>{{ assignedUserCount === null ? '?' : assignedUserCount }}</span>
			Users assigned
			</div>
		<button @click.stop="dryRun()">Dry Run</button>
		<span v-if="dryRunning" class="icon-spinner4 spin-loader-15" />
	</div>

	<AlertDialog :show="dryRunCourse !== null && dryRunLibrary !== null" @close="endDryRun()">
		<section style="max-height: 80vh; max-width: 90vw; overflow: auto;">
			<h1 class="border-bottom">Dry Run</h1>
			<p>The following students will receive this ModalMessage if they log in right now</p>
			<div class="flex-row flex-align-top flex-justify-center flex-gap-1">
				<div v-if="dryRunCourse && dryRunCourse.length">
					<h2>Show in Course ({{ dryRunCourse.length }})</h2>
					<div style="text-align: left">
						<div v-for="user in dryRunCourse" :key="user.userID">
							<span v-if="user.userID">{{ user.firstName }} {{ user.lastName }} ({{ user.emailAddress }})</span>
							<span v-else>User: {{ user }}</span>
						</div>
					</div>
				</div>
				<div v-if="dryRunLibrary && dryRunLibrary.length">
					<h2>Show in Library ({{ dryRunLibrary.length }})</h2>
					<div style="text-align: left">
						<div v-for="user in dryRunLibrary" :key="user.userID">
							<span v-if="user.userID">{{ user.firstName }} {{ user.lastName }} ({{ user.emailAddress }})</span>
							<span v-else>User: {{ user }}</span>
						</div>
					</div>
				</div>
			</div>

			<div v-if="dryRunMissing && dryRunMissing.length">
				<h2>MISSING USERS ({{dryRunMissing.length}})</h2>
				<div style="text-align: left">
					<div v-for="user in dryRunMissing" :key="user.userID">
						<span v-if="user.userID">{{ user.firstName }} {{ user.lastName }} ({{ user.emailAddress }})</span>
						<span v-else>User: {{ user }}</span>
					</div>
				</div>
			</div>

			<div v-if="dryRunExtra && dryRunExtra.length">
				<h2>EXTRA USERS ({{ dryRunExtra.length}})</h2>
				<div style="text-align: left">
					<div v-for="user in dryRunExtra" :key="user.userID">
						<span v-if="user.userID">{{ user.firstName }} {{ user.lastName }} ({{ user.emailAddress }})</span>
						<span v-else>User: {{ user }}</span>
					</div>
				</div>
			</div>
		</section>
	</AlertDialog>

	<AlertDialog :show="assignedUserList !== null" @close="assignedUserList = null">
		<h1>Assigned Users ({{assignedUserList && assignedUserList.length}}):</h1>
		<div style="max-height: 50vh; overflow: auto;">
			<div v-for="user in assignedUserList" :key="user.userID">{{ user.firstName }} {{ user.lastName }} ({{ user.emailAddress }})</div>
		</div>
	</AlertDialog>

</section>
</template>



<script>
import ModalMessageAPI from '@/api/ModalMessageAPI.js'
import User from '@/features/Users/User.js'

import AlertDialog from '@/components/utilities/AlertDialog.vue'

export default {
	name: 'ModalMessageDryRun',


	components: {
		AlertDialog,
	},


	props: {
		modalMessage: Object,
		includeTriggerLogic: {
			type: Boolean,
			default: false
		},
	},

	
	data() {
		return {
			assignedUserCount: null,

			dryRunning: false,
			assignedUserList: null,
			dryRunCourse: null,
			dryRunLibrary: null,
			dryRunMissing: null,
			dryRunExtra: null,
		}
	},


	created() { this.initialize() },


	methods: {

		initialize() {
			this.getAssignmentCount()
		},

		
		async getAssignmentCount() {
			if( this.modalMessage.modalMessageID === null ) return

			const data = await ModalMessageAPI.getAssignedUserCount( this.modalMessage.modalMessageID , this.includeTriggerLogic)
			this.assignedUserCount = data.count
			if(this.includeTriggerLogic) this.modalMessage.triggerUserCount = data.count
			else this.modalMessage.assignedUserCount = data.count
		},


		async getAssignedUsers() {
			if( this.modalMessage.modalMessageID === null ) return

			const data = await ModalMessageAPI.getAssignedUsers( this.modalMessage.modalMessageID, this.includeTriggerLogic )
			this.assignedUserList = []
			for( var item of data ) this.assignedUserList.push( User.import(item) )
		},


		async dryRun() {
			if( this.modalMessage.modalMessageID === null ) return

			try {
				this.dryRunning = true
				const data = await ModalMessageAPI.dryRun( this.modalMessage.modalMessageID, this.includeTriggerLogic )
				this.dryRunCourse = []
				for( let item of data.course ) {
					if( typeof item === 'object') this.dryRunCourse.push( User.import(item) )
					else this.dryRunCourse.push( item )
				}
				this.dryRunLibrary = []
				for( let item of data.library ) {
					if( typeof item === 'object' ) this.dryRunLibrary.push( User.import(item) )
					else this.dryRunLibrary.push( item )
				}
				this.dryRunMissing = []
				for( let item of data.missing ) {
					if( typeof item === 'object' ) this.dryRunMissing.push( User.import(item) )
					else this.dryRunMissing.push( item )
				}
				this.dryRunExtra = []
				for( let item of data.extra ) {
					if( typeof item === 'object' ) this.dryRunExtra.push( User.import(item) )
					else this.dryRunExtra.push( item )
				}

			} finally {
				this.dryRunning = false
			}
		},

		endDryRun() {
			this.dryRunCourse = null
			this.dryRunLibrary = null
			this.dryRunMissing = null
			this.dryRunExtra = null
		},
	}
}
</script>



<style scoped>

</style>