<template>
<section>

	<ModalMessageDryRun :modalMessage="modalMessage" :includeTriggerLogic="false" ref="dryrun" />

	<div class="warning" v-if="modalMessage.isGlobal">WARNING: This message will display to ALL students!</div>
	<section class="mt-1 flex-column flex-align-center">
		

		<!-- The Users Section -->
		<section class="border pa-2 round-025">
			<div class="widget pa-1">
				Users:
				<div class="flex-row flex-align-center">
					<UserSearchDropdown @updateUser="user => addUser(user)" width="100%" ref="userSearch" />
					<span class="ml-05 icon-info" tooltip="Any users specified here will see this ModalMessage, REGARDLESS of other assignment rules (courses, affiliates, customers, and cohorts)" @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
				</div>
				<TagField :Source="assignedUsers" DisplayPath="emailAddress" @remove="user => removeUser(user)" />
				<div v-if="!assignedCourses.length && !assignedAffiliates.length && !assignedCustomers.length && !assignedCohorts.length && !assignedRoles.length">
					<input type="checkbox" v-model="modalMessage.listedUsersOnly" @change="updateRequiredSections()" />
					ONLY include these users
				</div>
			</div>
		</section>

		<div class="py-05 center" :class="{'disabled' : usersOnlyMode}" style="font-size: 2em; font-weight:bold;">OR</div>


		<!-- The "LOGICAL AND" sections -->
		<section class="border pa-2 round-025" :class="{'disabled' : usersOnlyMode}">
			<div class="widget pa-1">
				Limit to Course(s):
				<div class="flex-row flex-align-center">
					<CourseSearchDropdown @update="course => addCourse(course)" width="100%" ref="courseSearch" />
					<span class="ml-05 icon-info" tooltip="If not specified, students in ALL courses will see this ModalMessage." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
				</div>
				<TagField :Source="assignedCourses" DisplayPath="courseName" @remove="course => removeCourse(course)" />
				<div v-if="!assignedCourses.length"><input type="checkbox" v-model="modalMessage.requireCourseID" @change="updateRequiredSections()" /> User must have at least 1 (ANY) course assigned</div>
			</div>

			<div class="center" style="font-size: 2em; font-weight:bold;">AND</div>

			<div class="widget pa-1">
				Limit to Affiliate(s):
				<div class="flex-row flex-align-center">
					<AffiliateSearchDropdown @update="aff => addAffiliate(aff)" width="100%" ref="affiliateSearch" />
					<span class="ml-05 icon-info" tooltip="If specified, only students who purchased through the selected affiliate(s) will see this ModalMessage.  If not specified, students attached to ANY affiliate (or no affiliate) will see this ModalMessage." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
				</div>
				<TagField :Source="assignedAffiliates" DisplayPath="name" @remove="aff => removeAffiliate(aff)" />
				<div v-if="!assignedAffiliates.length"><input type="checkbox" v-model="modalMessage.requireAffiliateID" @change="updateRequiredSections()" /> User must have a relationship to an affiliate</div>
			</div>

			<div class="center" style="font-size: 2em; font-weight:bold;">AND</div>

			<div class="widget pa-1">
				Limit to Customer(s):
				<div class="flex-row flex-align-center">
					<CustomerSearchDropdown @updateCustomer="cust => addCustomer(cust)" width="100%" ref="customerSearch" />
					<span class="ml-05 icon-info" tooltip="If specified, only students who purchased through the selected customer(s) will see this ModalMessage.  If not specified, students attached to ANY customer (or no customer) will see this ModalMessage." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
				</div>
				<TagField :Source="assignedCustomers" DisplayPath="name" @remove="customer => removeCustomer(customer)" />
				<div v-if="!assignedCustomers.length"><input type="checkbox" v-model="modalMessage.requireCustomerID" @change="updateRequiredSections()" /> User must be part of a customer</div>
			</div>

			<div class="center" style="font-size: 2em; font-weight:bold;">AND</div>

			<div class="widget pa-1">
				Limit to Cohort(s):
				<div class="flex-row flex-align-center">
					<CohortSearchDropdown :customerIDs="modalMessage.customerIDs" @update="cohort => addCohort(cohort)" width="100%" ref="cohortSearch" />
					<span class="ml-05 icon-info" tooltip="If one or more customer(s) are selected, you can only choose cohorts belonging to those customer(s).  If specified, only students in the selected cohort(s) will see this ModalMessage.  If not specified, students in any cohort (or no cohort) will see this ModalMessage." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
				</div>
				<!-- Show all cohorts (ID / name) for selected customer(s), organized by customer -->
				<TagField :Source="assignedCohorts" DisplayPath="cohortName" @remove="cohort => removeCohort(cohort)" />
				<div v-if="!assignedCohorts.length"><input type="checkbox" v-model="modalMessage.requireCohortID" @change="updateRequiredSections()" /> User must be in a cohort</div>
			</div>

			<div class="center" style="font-size: 2em; font-weight:bold;">AND</div>

			<div class="widget pa-1">
				Limit to Role(s):
				<div class="flex-row flex-align-center">
					<RoleSearchDropdown @update="role => addRole(role)" width="100%" ref="roleSearch" />
					<span class="ml-05 icon-info" tooltip="If specified, only users who have the selected role(s) will see this ModalMessage." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
				</div>
				<!-- Show all cohorts (ID / name) for selected customer(s), organized by customer -->
				<TagField :Source="assignedRoles" DisplayPath="name" @remove="role => removeRole(role)" />
				<div v-if="!assignedRoles.length"><input type="checkbox" v-model="modalMessage.requireRoleID" @change="updateRequiredSections()" /> User must have a role</div>
			</div>
		</section>

	</section>
	
</section>
</template>



<script>
import ModalMessageAPI from '@/api/ModalMessageAPI.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js';

import CourseAPI from '@/api/CourseAPI.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import RolesAPI from '@/api/RolesAPI.js'
import CohortAPI from '@/api/CohortAPI.js'
import UserAPI from '@/api/UserAPI.js'
import Affiliate from '@/features/SalesManagement/Affiliates/Affiliate.js'
import Customer from '@/features/SalesManagement/Customers/Customer.js'
import Cohort from '@/features/Cohorts/Cohort.js'
import Role from '@/features/Roles/Role.js'
import User from '@/features/Users/User.js'

import ModalMessageDryRun from './ModalMessageDryRun.vue'
import TagField from '@/components/utilities/TagField.vue'
import CourseSearchDropdown from '@/features/Courses/CourseSearchDropdown.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import CohortSearchDropdown from '@/features/Cohorts/CohortSearchDropdown.vue'
import RoleSearchDropdown from '@/features/Roles/RoleSearchDropdown.vue'
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'

export default {
	name: 'ModalMessageAssignments',


	components: {
		ModalMessageDryRun,
		TagField,
		CourseSearchDropdown,
		AffiliateSearchDropdown,
		CustomerSearchDropdown,
		CohortSearchDropdown,
		RoleSearchDropdown,
		UserSearchDropdown,
	},


	props: {
		modalMessage: Object,
	},


	data() {
		return {
			assignedCourses: [],
			assignedAffiliates: [],
			assignedCustomers: [],
			assignedCohorts: [],
			assignedRoles: [],
			assignedUsers: [],
		}
	},


	created() { this.initialize() },


	computed: {
		tooltips() { return Tooltips },
		usersOnlyMode() {
			return this.modalMessage.listedUsersOnly && !this.assignedCourses.length && !this.assignedAffiliates.length && !this.assignedCustomers.length && !this.assignedCohorts.length && !this.assignedRoles.length
		}
	},


	methods: {

		initialize() {
			// Course assignments
			CourseAPI.getCoursesByID( this.modalMessage.courseIDs )
			.then( res => {
				this.assignedCourses = []
				if( !res.data ) return
				for( var item of res.data ) this.assignedCourses.push( item )
			})

			// Affiliate Assignments
			SalesManagementAPI.getAffiliatesByID( this.modalMessage.affiliateIDs )
			.then( res => {
				this.assignedAffiliates = []
				if( !res.data ) return
				for( var item of res.data ) this.assignedAffiliates.push( Affiliate.import(item) )
			})

			// Customer Assignments
			SalesManagementAPI.getCustomersByID( this.modalMessage.customerIDs )
			.then( res => {
				this.assignedCustomers = []
				if( !res.data ) return
				for( var item of res.data ) this.assignedCustomers.push( Customer.import(item) )
			})

			// Cohort Assignments
			CohortAPI.getCohortsByID( this.modalMessage.cohortIDs )
			.then( res => {
				this.assignedCohorts = []
				if( !res.data ) return
				for( var item of res.data ) this.assignedCohorts.push( Cohort.import(item) )
			})

			RolesAPI.getRolesByID( this.modalMessage.roleIDs )
			.then( res => {
				this.assignedRoles = []
				if( !res.data ) return
				for( var item of res.data ) this.assignedRoles.push( Role.import(item) )
			})

			// User Assignments
			UserAPI.getUsersByID( this.modalMessage.userIDs )
			.then( res => {
				this.assignedUsers = []
				if( !res.data ) return
				for( var item of res.data ) this.assignedUsers.push( User.import(item) )
			})
		},



		async addCourse( course ) {
			if( !course ) return
			await ModalMessageAPI.assignCourse( this.modalMessage.modalMessageID, course.courseID )
			.catch(e => { this.$emit('saveError'); throw e })

			this.assignedCourses.push( course )
			this.modalMessage.courseIDs.push( course.courseID )
			this.$refs.courseSearch.clear()
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},


		async removeCourse( course ) {
			await ModalMessageAPI.removeCourse( this.modalMessage.modalMessageID, course.courseID )
			.catch(e => { this.$emit('saveError'); throw e })

			this._removeObject( this.assignedCourses, 'courseID', course )
			this._removeIndex( this.modalMessage.courseIDs, course.courseID )
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},


		async addAffiliate( aff ) {
			if( !aff ) return
			await ModalMessageAPI.assignAffiliate( this.modalMessage.modalMessageID, aff.id )
			.catch(e => { this.$emit('saveError'); throw e })

			this.assignedAffiliates.push( aff )
			this.modalMessage.affiliateIDs.push( aff.id )
			this.$refs.affiliateSearch.clear()
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},


		async removeAffiliate( aff ) {
			await ModalMessageAPI.removeAffiliate( this.modalMessage.modalMessageID, aff.id )
			.catch(e => { this.$emit('saveError'); throw e })

			this._removeObject( this.assignedAffiliates, 'id', aff )
			this._removeIndex( this.modalMessage.affiliateIDs, aff.id )
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},


		async addCustomer( customer ) {
			if( !customer ) return
			await ModalMessageAPI.assignCustomer( this.modalMessage.modalMessageID, customer.id )
			.catch(e => { this.$emit('saveError'); throw e })

			this.assignedCustomers.push( customer )
			this.modalMessage.customerIDs.push( customer.id )
			this.$refs.customerSearch.clear()
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},


		async removeCustomer( customer ) {
			await ModalMessageAPI.removeCustomer( this.modalMessage.modalMessageID, customer.id )
			.catch(e => { this.$emit('saveError'); throw e })

			this._removeObject( this.assignedCustomers, 'id', customer )
			this._removeIndex( this.modalMessage.customerIDs, customer.id )
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},


		async addCohort( cohort ) {
			if( !cohort ) return
			await ModalMessageAPI.assignCohort( this.modalMessage.modalMessageID, cohort.cohortID )
			.catch(e => { this.$emit('saveError'); throw e })

			this.assignedCohorts.push( cohort )
			this.modalMessage.cohortIDs.push( cohort.cohortID )
			this.$refs.cohortSearch.clear()
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},

		async removeCohort( cohort ) {
			await ModalMessageAPI.removeCohort( this.modalMessage.modalMessageID, cohort.cohortID )
			.catch(e => { this.$emit('saveError'); throw e })

			this._removeObject( this.assignedCohorts, 'cohortID', cohort )
			this._removeIndex( this.modalMessage.cohortIDs, cohort.cohortID )
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},


		async addRole( role ) {
			if( !role ) return
			await ModalMessageAPI.assignRole( this.modalMessage.modalMessageID, role.id )
			.catch(e => { this.$emit('saveError'); throw e })

			this.assignedRoles.push( role )
			this.modalMessage.roleIDs.push( role.id )
			this.$refs.roleSearch.clear()
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},


		async removeRole( role ) {
			await ModalMessageAPI.removeRole( this.modalMessage.modalMessageID, role.id )
			.catch(e => { this.$emit('saveError'); throw e })

			this._removeObject( this.assignedRoles, 'id', role )
			this._removeIndex( this.modalMessage.roleIDs, role.id )
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},


		async addUser( user ) {
			if( !user ) return
			await ModalMessageAPI.assignUser( this.modalMessage.modalMessageID, user.userID )
			.catch(e => { this.$emit('saveError'); throw e })

			this.assignedUsers.push( user )
			this.modalMessage.userIDs.push( user.userID )
			this.$refs.userSearch.clear()
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},


		async removeUser( user ) {
			await ModalMessageAPI.removeUser( this.modalMessage.modalMessageID, user.userID )
			.catch(e => { this.$emit('saveError'); throw e })

			this._removeObject( this.assignedUsers, 'userID', user )
			this._removeIndex( this.modalMessage.userIDs, user.userID )
			this.$refs.dryrun.getAssignmentCount()
			this.$emit('changed')
		},

		_removeObject( arr, keyPropName, itemToRemove ) {
			var index = null
			for(var i=0; i < arr.length; i++) {
				const currentItem = arr[i]
				if( currentItem[keyPropName] !== itemToRemove[keyPropName] ) continue
				index = i
			}
			if( index !== null ) arr.splice( index, 1 )
		},

		_removeIndex( arr, valueToRemove ) {
			const idx = arr.indexOf( valueToRemove )
			if( idx >= 0 ) arr.splice( idx, 1 )
		},



		async updateRequiredSections() {
			await ModalMessageAPI.updateRequiredSections( this.modalMessage )
			.catch(e => { this.$emit('saveError'); throw e })

			this.$refs.dryrun.getAssignmentCount()
		},


		// Called by parent!
		updateAssignedUsers() {
			this.$refs.dryrun.initialize()
		},
	}
}
</script>



<style scoped>
.widget {
	background: white;
	width: 650px;
	margin: auto;
}

.disabled {
	opacity: 0.3;
}

</style>