<template>
<section>
	
	<ApexChartGraph v-if="graph" :graph="graph" type="bar" height="300px" />
	<div v-else-if="loadingGraph" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
  <div v-else-if="graphError" class="warning">Failed to get graph</div>
  <div v-else class="NoResults">No Graph</div>

	<hr>
	<UserSessionList :userID="userID" :licenseKey="licenseKey" :start="startDate" :end="endDate" :showControls="false" :showActive="null" />
</section>
</template>



<script>
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import StudentStatisticsGraphRequest from './StudentStatisticsGraphRequest.js'
import GraphHelper from './GraphHelper.js'

import ApexChartGraph from '@/components/ApexChartGraph.vue'
import UserSessionList from '@/features/LoginTracking/UserSessionList.vue'

export default {
	name: 'StudySessionDetails',


	components: {
		ApexChartGraph,
		UserSessionList,
	},


	props: {
		userID: Number,
		licenseKey: String,
		startDate: {
			type: Date,
			default: null
		},
		endDate: {
			type: Date,
			default: null
		},
	},


	data() {
		return {
			graph: null,
			loadingGraph: false,
			graphError: false,

			details: null,
			loadingDetails: false,
			detailsError: false,
		}
	},


	created() { this.initialize() },


	watch: {
		startDate() { this.getGraph() },
		endDate() { this.getGraph() },
	},


	methods: {
		initialize() {
			this.getGraph()
		},

		async getGraph() {
			this.graph = null
			this.graphError = false
			this.loadingGraph = true
			try {
				const graphReq = new StudentStatisticsGraphRequest( this.userID, this.licenseKey, this.startDate, this.endDate )
				const graphRes = await StudentStatisticsAPI.getTimeInProgramGraph( graphReq )
				this.graph = graphRes.data
	
				GraphHelper.generateTimeLabels( this.graph )

			} catch(e) {
				this.graphError = true
				throw e

			} finally {
				this.loadingGraph = false
			}
		},

	}
}
</script>



<style scoped>

</style>