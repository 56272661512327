import Exim from "@/models/Exim";

class ModalMessageType extends Exim {

	typeID = null
	name = null
	_defaultPriority = null
	allowUserControl = null


	get defaultPriority() { return this._defaultPriority }
	set defaultPriority( value ) {
		if( !value && value !== null ) this._defaultPriority = null
		else this._defaultPriority = value
	}


	export() {
		const data = super.export()
		data.defaultPriority = data._defaultPriority
		delete(data._defaultPriority)
		return data
	}

}

export default ModalMessageType