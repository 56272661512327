<template>
<section>
	
	<!-- Stars -->
	<div v-if="review.starPrompt" v-html="review.starPrompt" class="center" style="font-weight: bold;" />
	<div v-if="review.stars > 0" class="stars flex-row flex-justify-center mb-2">
		<div class="star star-1" :class="{'icon-star-full' : review.stars >= 1, 'icon-star-empty' : review.stars < 1 }" />
		<div class="star star-2" :class="{'icon-star-full' : review.stars >= 2, 'icon-star-empty' : review.stars < 2 }" />
		<div class="star star-3" :class="{'icon-star-full' : review.stars >= 3, 'icon-star-empty' : review.stars < 3 }" />
		<div class="star star-4" :class="{'icon-star-full' : review.stars >= 4, 'icon-star-empty' : review.stars < 4 }" />
		<div class="star star-5" :class="{'icon-star-full' : review.stars >= 5, 'icon-star-empty' : review.stars < 5 }" />
	</div>


	<!-- Prompt & Title -->
	<div v-if="review.bodyPrompt" class="center">Prompt: <span v-html="review.bodyPrompt" style="font-weight: bold;" /></div>
	<div v-if="review.title">Title: <span v-html="review.title" /></div>

	<!-- Body -->
	<div v-if="review.body" class="bodyWrapper color-gray-40">
		<div v-if="modified" class="bold font-size-1-2">Published text:</div>
		<div v-if="editReviewBody" class="font-size-1-5">
			<textarea v-model="review.body" class="w-100" ref="editText" />
			<div class="flex-row flex-align-center flex-justify-end flex-gap-05 my-05">
				<button class="pillButton secondary" @click="cancelEditBody()">cancel</button>
				<button class="pillButton" @click="saveBody()">save</button>
			</div>
		</div>
		<div v-else class="bodyText" v-html="`&ldquo;${review.body}&rdquo;`" ref="bodyText" />
		
		<div class="flex-row flex-align-center flex-justify-between flex-gap-3 mt-05">
			<div class="flex-row flex-align-center flex-justify-end flex-gap-1">
				<div v-if="modified" class="font-size-09 color-gray-50 italic">review has been modified</div>
				<div v-if="modified && !showOriginalBody" class="link font-size-09" @click="showOriginalBody = true">see original</div>
			</div>
			<div v-if="!editReviewBody" class="link font-size-09" @click="editBody()">edit</div>
		</div>

	</div>
	<div v-else class="center" style="color: #888;">No written review</div>

	<!-- Original Body -->
	<div v-if="modified && showOriginalBody" class="bodyWrapper py-2 my-1 color-gray-40">
		<div class="bold font-size-1-2">Original text:</div>
		<div class="" v-html="`&ldquo;${ review.originalBody }&rdquo;`" />
			<div class="link font-size-09 mt-05" @click="showOriginalBody = false">hide</div>
	</div>
	

	<!-- Author Info -->
	<div class="mt-2 pt-2 border-top flex-row flex-justify-center flex-gap-05">
		<div class="italic">Author:</div>
		<div>
			<div v-if="!editingName">{{ review.authorName }} <span class="pa-05 icon-pencil color-gray-50" @click="editName()" /></div>
			<div v-else class="flex-row flex-align-center flex-justify-center flex-gap-05">
				<div class="mr-2"><input type="text" v-model="newAuthorName" /></div>
				<button class="pillButton secondary" @click="revertName()">cancel</button>
				<button class="pillButton" @click="saveName()">save</button>
			</div>
			{{ review.authorEmail }}
		</div>
	</div>
	
	<!-- License Info -->
	<div class="flex-row flex-justify-center flex-gap-05">
		<div class="italic">License:</div>
		<div>{{ review.licenseKey }}</div>
	</div>


	<!-- User Stats -->
	<table class="table mt-2" style="max-width: 600px; margin-left: auto; margin-right: auto;">
		<tr>
			<td class="table-data label">Time in Program:</td>
			<td class="table-data value">{{ review.hoursInProgramString || '--' }}</td>
		</tr>
		<tr>
			<td class="table-data label"># Logins:</td>
			<td class="table-data value">{{ review.loginCount || '--' }}</td>
		</tr>
		<tr>
			<td class="table-data label"># Study Sessions:</td>
			<td class="table-data value">{{ review.userSessionCount || '--' }}</td>
		</tr>
		<tr>
			<td class="table-data label">License Age:</td>
			<td class="table-data value">{{ review.yearsDaysPurchaseString || '--' }}</td>
		</tr>
		<tr>
			<td class="table-data label">Account Age:</td>
			<td class="table-data value">{{ review.yearsDaysAccountString || '--' }}</td>
		</tr>
	</table>


	<!-- Affiliates -->
	<div class="mt-2 pt-2 border-top center">
		<div><b>⭐ Primary Affiliate:</b> {{ review.primaryAffiliate ? review.primaryAffiliate.name : 'none' }}</div>
		<div class="flex-column flex-align-center mt-05">
			<b>Other Affiliates:</b>
			<TagField v-if="review.secondaryAffiliates.length" :Source="review.secondaryAffiliates" DisplayPath="name" :topMargin="false" @remove="aff => this.removeAffiliate( aff.id )" class="flex-justify-center" style="max-width: 600px;" />
			<AffiliateSearchDropdown @update="aff => this.addAffiliate( aff )" placeholder="Add Affiliate" ref="affSearch" class="mt-05" />
		</div>
	</div>


	<!-- Modal Message -->
	<div v-if="review.modalMessageName" class="mt-3 center">
		<div><b>From Modal Message:</b></div>
		<div><i>&ldquo;{{ review.modalMessageName }}&rdquo;</i></div>
	</div>


	<!-- Publication Settings -->
	<div class="mt-3 pt-3 center border-top">
		<div><b>Publication Settings</b></div>

		<div class="flex-row flex-align-start flex-justify-center flex-gap-3">
			<div>
				<div class="mt-05 flex-row flex-align-center flex-gap-05"><ToggleButton v-model="review.isPublished" :sync="true" />Published</div>
				<div class="mt-05 flex-row flex-align-center flex-gap-05"><ToggleButton v-model="review.isFeatured" :sync="true" />Featured</div>
				<div class="mt-05 flex-row flex-align-center flex-gap-05"><ToggleButton v-model="review.isSuper" :sync="true" />Super</div>
			</div>

			<div>
				<div class="mt-05 flex-row flex-align-center flex-gap-05">
          Rank: <input type="number" style="width: 4em;" v-model.number="review.rank" min="1" />
        </div>
        <div class="mt-05 flex-row flex-align-center flex-gap-05">
          Category:
          <select v-model="review.category">
						<option :value="null">No Category</option>
						<option value="" disabled>---------------------</option>
            <option value="program">Program</option>
            <option value="project">Project</option>
          </select>  
        </div>
			</div>
		</div>

		<div class="flex-row flex-align-center flex-gap-1 mt-2">
			<span class="bold">Tags:</span>
			<GenericTagAssignDropdown :taggedObject="review" :dropUp="true" tagType="review" @addTag="tag => addTag( tag )" @removeTag="tag => removeTag( tag )" />
		</div>
	</div>


</section>
</template>


<script>
import GenericTagAssignDropdown from '@/components/utilities/GenericTagAssignDropdown.vue'
import TagField from '@/components/utilities/TagField.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import { ToggleButton } from 'vue-js-toggle-button'

import ReviewsAPI from '@/api/ReviewsAPI.js'
import GenericTagsAPI from '@/api/GenericTagsAPI.js'

export default {
	name: 'ReviewDetails',


	components: {
		GenericTagAssignDropdown,
		TagField,
		AffiliateSearchDropdown,
		ToggleButton,
	},


	props: {
		review: Object,
	},


	data() {
		return {
			editReviewBody: false,
			showOriginalBody: false,
			wasVirgin: null,

			editingName: false,
			newAuthorName: null,
		}
	},


	computed: {
		modified() {
			return this.review.originalBody && this.review.originalBody != this.review.body
		}
	},


	watch: {
		'review.isPublished'( value ) { this.editPublication( 'isPublished', value ) },
		'review.isFeatured'( value ) { this.editPublication( 'isFeatured', value ) },
		'review.isSuper'( value ) { this.editPublication( 'isSuper', value ) },
		'review.rank'( value ) { this.editPublication( 'rank', value || 0 ) },
		'review.category'( value ) { this.editPublication( 'category', value || '' ) },
	},


	methods: {

		async editBody() {
			if( this.review.originalBody === null ) {
				this.wasVirgin = true
				this.review.originalBody = this.review.body
			} else this.wasVirgin = false

			this.editReviewBody = true
			
			const height = this.$refs.bodyText.offsetHeight
			await this.$nextTick()
			this.$refs.editText.style.height = height + 'px'
		},

		cancelEditBody() {
			this.editReviewBody = false
			if( this.wasVirgin ) {
				this.review.body = this.review.originalBody
				this.review.originalBody = null
			}
		},

		async saveBody() {
			if( !this.review.originalBody ) return

			await ReviewsAPI.modifyBody( this.review.reviewID, this.review.body )
			this.editReviewBody = false
		},

		editName() {
			this.newAuthorName = this.review.authorName
			this.editingName = true
		},

		revertName() {
			this.newAuthorName = null
			this.editingName = false
		},

		async saveName() {
			
			try {
				await ReviewsAPI.modifyAuthorName( this.review.reviewID, this.newAuthorName )
				this.review.authorName = this.newAuthorName

			} finally {
				this.newAuthorName = null
				this.editingName = false
			}
		},

		async addAffiliate( aff ) {
			if( !aff ) return

			await ReviewsAPI.addAffiliate( this.review.reviewID, aff.id )

			const affSummary = { id: aff.id, name: aff.name, primary: false }
			this.review.addAffiliate( affSummary )
			this.$refs.affSearch.clear()
		},

		async removeAffiliate( affID ) {
			await ReviewsAPI.removeAffiliate( this.review.reviewID, affID )
			this.review.removeAffiliate( affID )
		},


		async editPublication( param, value ) {
      const req = { reviewIDs: [ this.review.reviewID ] }
			req[ param ] = value

      await ReviewsAPI.multiEditPublication( req )
    },


		async addTag( tag ) {
			await GenericTagsAPI.addTag( 'review', this.review.reviewID, tag )
			this.review.addTag( tag )
		},


		async removeTag( tag ) {
			await GenericTagsAPI.removeTag( 'review', this.review.reviewID, tag )
			this.review.removeTag( tag )
		}

	}
}
</script>


<style scoped>
.bodyWrapper {
	max-width: 7in;
	margin: 0.5em auto;
}
.bodyText {
	color: #555;
	font-size: 1.5em;
	font-style: italic;
}

.star {
	color: lightgray;
	font-size: 2.5em;
	padding: 0 0.125em;
}

.icon-star-full {
	color: gold;
}

.star:hover {
	color: gold;
}

.label {
	text-align: right;
	width: 150px;
}
.value {
	text-align: left;
	font-weight: bold;
	width: 150px;
}
</style>