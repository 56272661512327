<template>
<section>
	<div id="uploader">
		<file-pond :files="uploadFiles" @processfile="fileUploaded" allow-multiple="true" instantUpload="true" :server="filepondConfig" />
	</div>

	<div class='image-grid w-100'>
		<div v-for='image in productImages' :key='image.filename'>
			<img :src='getImageURL(image.filename)' class='product-thumbnail' />
			<div>
				<span style="max-width:100%; font-size: 0.9em; overflow-wrap: break-word;">{{ image.filename }}</span>
				<i class='icon-bin' @click='showDeleteImage(image.filename)'></i>
			</div>
		</div>
	</div>

	<ConfirmDialog confirm="Delete Image" ref="confirmDeleteModal" @confirm="deleteCallback()">
		<div>Are you sure you want to delete this image?</div>
		<div class="bold font-size-1-3">This action CAN NOT be undone!</div>
	</ConfirmDialog>

</section>
</template>

<script>
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

import {base_url, base_url_product_image} from "@/Config";
// import vueFilePond from "vue-filepond";
import 'filepond/dist/filepond.min.css';
// const FilePond = vueFilePond();
import MediaLibraryAPI from '@/api/MediaLibraryAPI.js';


export default {
	name: 'ProductImages',


	components: {
		ConfirmDialog,
	},


	props: {
		sku: String,
	},


	data() {
		return {
			uploadFiles: [],
			filepondConfig: {
        process: {
          url: base_url + '/SiteAdmin/MediaLibraryAPI/uploadProductImage/' + this.sku,
          withCredentials: true,
          method: 'POST',
        }
      },

			productImages: [],
			imageToDelete: null,
		}
	},


	async created() {
		this.updateProductImages();
	},


	methods: {
		// Update the file preview list

		async fileUploaded(){
			//TODO: re-download the product's image file names (json)
			//ENDPOINT: /SiteAdmin/MediaLibraryAPI/getProductImages(sku)
			this.updateProductImages();
		},

		async updateProductImages() {
			this.productImages = await MediaLibraryAPI.getProductImages(this.sku);
		},

		getImageURL(filename) {
			return `${base_url_product_image}/${filename}`;
		},

		async showDeleteImage( filename ) {
			this.imageToDelete = filename
			this.$refs.confirmDeleteModal.open()
		},

		async deleteCallback() {

			try {
				await MediaLibraryAPI.deleteMedia( 'product_images', this.imageToDelete )
				await this.updateProductImages()
			} catch (e) {
				console.error(e)
				alert("Error communicating with server. Changes not saved.")
			
			} finally {
				this.imageToDelete = null
			}

		}
	}
}
</script>

<style scoped>
.product-thumbnail {
	width: 200px;
}

.product-thumbnail {
	width: 200px;
}

.image-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-gap: 1rem;
}
</style>
