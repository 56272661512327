class PricingOption {

  
  description = null      // Override product's "shortDescription" (i.e. display on product tiles)
  saleDescription = null
  itemizeDiscountOnInvoice = false

  constructor(product, id, name, initialPrice, initialSale, length, unit, singleUnit, isSub, subPrice, subSale, offset, isSponsored, perUnitPricing, defaultQuantity) {
    this.product = product
    this.id = id
    this.name = name

    this.initialFullPrice = initialPrice
    this._initialSalePrice = initialSale

    this.licenseLength = length
    this.lengthUnit = unit
    this.singularLengthUnit = singleUnit

    this.isSubscription = isSub
    this.subscriptionFullPrice = subPrice
    this._subscriptionSalePrice = subSale
    this.anniversaryOffset = offset
    this.isSponsored = isSponsored || false
    this.perUnitPricing = perUnitPricing || false
    this._defaultQuantity = defaultQuantity || 1
  }



  get defaultQuantity() { return this._defaultQuantity }
  set defaultQuantity(value) {
    value = parseInt(value)
    if(!value || value < 1) return
    this._defaultQuantity = value
  }


  get initialPrice() { return this._initialSalePrice !== null ? this._initialSalePrice : this.initialFullPrice }
  set initialPrice(value) { this.initialFullPrice = value }

  get initialSalePrice() { return this._initialSalePrice }
  set initialSalePrice(value) {
    value = parseFloat(value)
    if( value === null || isNaN(value) || value < 0.00 ) this._initialSalePrice = null
    else this._initialSalePrice = value
  }


  get subscriptionPrice() { return this._subscriptionSalePrice ? this._subscriptionSalePrice : this.subscriptionFullPrice }
  set subscriptionPrice(value) { this.subscriptionFullPrice = value }

  get subscriptionSalePrice() { return this._subscriptionSalePrice }
  set subscriptionSalePrice(value) {
    value = parseFloat(value)
    if(!value || isNaN(value) || value < 0.00) this._subscriptionSalePrice = null
    else this._subscriptionSalePrice = value
  }


  get intervalString() {
    if(!this.isSubscription) return '';

    if(this.licenseLength == 1) return this.singularLengthUnit;
    if(this.licenseLength == 7 && this.lengthUnit == 'days') return 'week';
    if(this.licenseLength == 12 && this.lengthUnit == 'months') return 'year';
    else return `${this.licenseLength} ${this.lengthUnit}`;
  }


  get priceString() {
    let subPrice = this.isSubscription ? ` + $${Math.round2fixed(this.subscriptionPrice)} / ${this.intervalString}` : '';
    return `$${Math.round2fixed(this.initialPrice)}${subPrice}`
  }


  get termString() {
    let length = this.licenseLength;
    let unit = this.lengthUnit;

    if(this.licenseLength == 1) unit = this.singularLengthUnit;
    if(this.licenseLength == 7 && this.lengthUnit == 'days') {
      length = 1;
      unit = 'week';
    }
    if(this.licenseLength == 12 && this.lengthUnit == 'months') {
      length = 1;
      unit = 'year';
    }
    return `${length} ${unit}`;
  }



  getSubscriptionString( itemPrice = null, showEach = true ) {
    if(!this.isSubscription) return ''
    const each = showEach ? ' each' : ''

    const priceOverride = itemPrice && itemPrice != this.subscriptionPrice
    if(priceOverride) return `<span style="text-decoration: line-through">$${this.subscriptionPrice}</span> <b>$${itemPrice.toLocaleString(undefined, {minimumFractionDigits: 2})}</b>${each}`
    else return `<b>$${this.initialPrice}</b>${each}`
  }



  getPricingString( itemPrice = null, showEach = true ) {
    const each = showEach ? ' each' : ''
    var fullPrice = this.initialFullPrice
    var salePrice = this._initialSalePrice
    var overridePrice = itemPrice

    var finalPrice = overridePrice !== null ? overridePrice : ( salePrice !== null ? salePrice : fullPrice )

    var str = ''
    if( (salePrice && salePrice !== fullPrice) || (overridePrice && overridePrice !== fullPrice) ) str = `<span style="text-decoration: line-through">$${fullPrice}</span> `
    if( salePrice && overridePrice && overridePrice !== salePrice ) str += `<span style="text-decoration: line-through">$${salePrice}</span>`
    
    str += ` <b>${ Math.moneyFormat( finalPrice ) }</b>${each}`

    // var priceOverride = (itemPrice && itemPrice !== this.initialPrice)
    // if(priceOverride) str = `<span style="text-decoration: line-through">$${this.initialPrice}</span> <b>$${itemPrice.toLocaleString(undefined, {minimumFractionDigits: 2})}</b> each`
    // else str = `<b>$${this.initialPrice}</b> each`
    
    return str
  }




  static import(obj, product = null) {
    const po = new PricingOption(
      product,
      obj.id,
      obj.name,
      obj.initialFullPrice,
      obj.initialSalePrice,
      obj.licenseLength,
      obj.lengthUnit,
      obj.singularLengthUnit,
      obj.isSubscription,
      obj.subscriptionFullPrice,
      obj.subscriptionSalePrice,
      obj.anniversaryOffset,
      obj.isSponsored,
      obj.perUnitPricing,
      obj.defaultQuantity
    );

    po.description = obj.description || null
    po.saleDescription = obj.saleDescription ? obj.saleDescription : null
    if( obj.itemizeDiscountOnInvoice ) po.itemizeDiscountOnInvoice = obj.itemizeDiscountOnInvoice
    return po
  }


  export() {
    return {
      product: this.product ? this.product.id : null,
      id: this.id,
      name: this.name,
      initialFullPrice: this.initialFullPrice,
      initialSalePrice: this.initialSalePrice,
      licenseLength: this.licenseLength,
      lengthUnit: this.lengthUnit,
      singularLengthUnit: this.singularLengthUnit,
      isSubscription: this.isSubscription,
      subscriptionFullPrice: this.subscriptionFullPrice,
      subscriptionSalePrice: this.subscriptionSalePrice,
      anniversaryOffset: this.anniversaryOffset,
      isSponsored: this.isSponsored || false,
      perUnitPricing: this.perUnitPricing || false,
      defaultQuantity: this.defaultQuantity || 1,
      description: this.description,
      saleDescription: this.saleDescription,
      itemizeDiscountOnInvoice: this.itemizeDiscountOnInvoice
    }
  }
}

export default PricingOption;
