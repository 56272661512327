<template>
<section class="px-1" style="max-width: 1200px; margin: auto;">


	<!-- Manually run cron jobs -->
	<div class="title mt-2">Cron Jobs</div>
	<section class="flex-row flex-gap-1 flex-align-center">
		<select v-model="cronCommand">
			<option value="GenerateStudentStats">Re-Generate student statistics</option>

			<option value="SendScheduledRegistrationEmails">Send SCHEDULED registration emails</option>
			<option value="SendRegistrationEmails">Send registration follow-up emails for existing, unregistered users</option>
		</select>
		<button class="btn btn-primary" @click="runCronjob()">Queue / Run Cron Job</button>
	</section>

	<!-- Manually run other jobs in the LongJob format -->
	<div class="title mt-2">Long Jobs (Background Tasks)</div>
	<section class="flex-row flex-gap-1 flex-align-center">
		<select v-model="longJobCommand">
			<option value="ImportV9CommentAddresses">Import V9 Comment Addresses (temporary)</option>
		</select>
		<button class="btn btn-primary" @click="runLongJob()">Run Long Job</button>
		<span v-if="longJobID" class="icon-spinner4 spin1" />
		<span v-if="longJobID">{{ longJobUnitsComplete }} / {{ longJobUnitsTotal }}</span>
		<span v-if="longJobErrors" class="warning">Error: {{ longJobErrors }}</span>
	</section>


	<!-- Regenerate Avatar images -->
	<!-- <section class="mt-3">
		<div class="title">Avatar Images</div>
		<button @click="regenerateAvatars()">Re-generate ALL user avatars</button>
		<div v-if='busyAvatars' class='icon-spinner4 spin-loader-15'></div>
		<div v-if="report" v-html="report" />
		<h2 v-if="errors.length">Errors:</h2>
		<div v-for="error in errors">{{error}}</div>
	</section> -->


	<!-- OLD ?? Regenerate UserSessionSummary DB table -->
	<!-- <div class="mt-3">
		<div class="title">User Session Data</div>
		<div>Populate the UserSessionSummary DB table:<br>Updates each user's total time in program and # of sessions.</div>
		<div>
			<button @click="generateUserSessionSummaries()">Re-generate user session summary data</button>
			<span v-if='busySessions' class='icon-spinner4 spin-loader-15'></span>
		</div>
	</div> -->



	<div class="mt-3">
		<div class="title">Student {{ trackingPlans ? 'Plans' : 'Tracking' }}</div>
		<div>Check students' {{ trackingPlans ? 'plans' : 'tracking' }} for errors</div>
		<div class="flex-row flex-align-center flex-gap-1">
			
			<UserSearchDropdown v-if="!trackingPlans" v-model="trackingUser" />
			<CustomerSearchDropdown v-model="trackingCustomer" />
			<button v-if="trackingUser && !trackingPlans" @click="verifyUserTracking()">Verify Student Tracking</button>
			<button v-if="trackingUser == null" @click="verifyAllTracking(1)" :disabled="busyTracking !== false">Find Errors (ACT)</button>
			<button v-if="trackingUser == null" @click="verifyAllTracking(3)" :disabled="busyTracking !== false">Find Errors (SAT)</button>
			<span v-if='busyTracking' class='icon-spinner4 spin-loader-15'></span>
		</div>

		<div class="mt-05 flex-row flex-gap-1">
			<div><ToggleButton v-model="trackingActiveOnly" /> Active Licenses Only</div>
			<div><ToggleButton v-model="trackingPlans" /> Verify {{ trackingPlans ? 'Plans' : 'Tracking' }}</div>
		</div>
		
		<div v-if="trackingProgress !== null" class="my-1">
			<ProgressBar :pct="trackingProgress" :showBusy="trackingProgress !== null && trackingProgress < 1" :label="Math.round( trackingProgress * 100 ) + '%'" :type="trackingErrors && trackingErrors.length ? 'error' : 'success'" />
		</div>
		
		<div v-if="trackingErrors && trackingErrors.length" class="mt-1 Warning">
			<div>There was an error while verifying the student tracking. Please check the logs for more details.</div>
			<div v-for="errorLine in trackingErrors" :key="errorLine">{{errorLine}}</div>
		</div>

		<div v-if="trackingReport" class="border round-05 my-1 pa-1" style="max-height: 35em; overflow: auto;">
			<h2 class="ma-0">{{ trackingPlans ? 'Plans' : 'Tracking' }} Data Error Report</h2>
			<div>{{ trackingReport.length }} users with errors <button v-if="trackingPlans && trackingReport.length > 0" class="pillButton" @click.stop="regenAllPlans()">Fix All Plans</button></div>
			<TrackingValidationReport v-for="line in trackingReport" :line="line" class="mt-1" :key="line.trackingID" />
		</div>
	</div>


	<div class="mt-3">
		<div class="title">"Available Media" Index</div>
		<div>Generate an index of the videos students have "discovered" in their programs.</div>
		<div class="flex-row flex-align-center flex-gap-1">
			<UserSearchDropdown v-model="mediaIndexUser" :disabled="busyMediaIndex !== false" />
			<button v-if="mediaIndexUser !== null" @click="initializeMediaIndex( null, mediaIndexUser ? mediaIndexUser.userID : null )" :disabled="busyMediaIndex !== false">
				Initialize Media Index
				<span v-if="busyMediaIndex">&nbsp;&nbsp;<span class="icon-loop2 spin1" /></span>
			</button>
			<div v-else class="flex-row flex-align-center flex-gap-1">
				<button @click="initializeMediaIndex(1)" :disabled="busyMediaIndex !== false">Initialize All ACT Media Indexes<span v-if="busyMediaIndex == 1">&nbsp;&nbsp;<span class="icon-loop2 spin1" /></span></button>
				<button @click="initializeMediaIndex(3)" :disabled="busyMediaIndex !== false">Initialize All SAT Media Indexes<span v-if="busyMediaIndex == 3">&nbsp;&nbsp;<span class="icon-loop2 spin1" /></span></button>
			</div>
		</div>

		<div v-if="mediaIndexProgress !== null || mediaIndexReport || mediaIndexErrors" class="px-3 my-1">
			<!-- Progress -->
			<div v-if="mediaIndexProgress !== null" class="my-1">
				<ProgressBar :showBusy="mediaIndexProgress !== null && mediaIndexProgress < 1" :pct="mediaIndexProgress" :label="Math.round( mediaIndexProgress * 100 ) + '%'" />
			</div>
			
			<!-- Report -->
			<h3 v-if="mediaIndexReport && mediaIndexReport.length > 0">Report ({{ mediaIndexReport.length }} lines)</h3>
			<div v-if="mediaIndexReport && mediaIndexReport.length > 0" class="round-05 pa-05 border" style="max-height: 25em; overflow: auto;">
				<div v-for="line in mediaIndexReport" :key="line.userKey">{{ line }}</div>
			</div>
			
			<!-- Errors -->
			<h3 v-if="mediaIndexErrors && mediaIndexErrors.length > 0">{{ mediaIndexErrors.length }} Errors</h3>
			<div v-if="mediaIndexErrors && mediaIndexErrors.length > 0" class="round-05 pa-05 border" style="max-height: 25em; overflow: auto;">
				<div v-for="error in mediaIndexErrors" :key="error">{{ error }}</div>
			</div>
		</div>

	</div>

</section>
</template>


<script>
import UserProfileAPI from '@/api/UserProfileAPI.js'
import SystemToolsAPI from '@/api/SystemToolsAPI.js'
import LongJobAPI from '@/api/LongJobAPI.js'

import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import TrackingValidationReport from './TrackingValidationReport.vue'
import ProgressBar from '@/components/utilities/ProgressBar.vue'
import { ToggleButton } from 'vue-js-toggle-button'


export default {
	name: 'ToolsPane',



	components: {
		UserSearchDropdown,
		CustomerSearchDropdown,
		TrackingValidationReport,
		ProgressBar,
		ToggleButton,
	},



	data() {
		return {
			report: '',
			errors: [],
			
			cronCommand: null,
			longJobCommand: null,
			longJobID: false,
			longJobUnitsComplete: 0,
			longJobUnitsTotal: 0,
			longJobErrors: null,
			longJobIntervalID: null,

			busyAvatars: false,
			busySessions: false,

			busyTracking: false,
			trackingUser: null,
			trackingCustomer: null,
			trackingActiveOnly: true,
			trackingPlans: false,      // Toggle between verifying Tracking (false) and verifying Plans (true)
			trackingReport: null,
			trackingErrors: null,
			trackingProgress: null,
			trackingIntervalID: null,

			mediaIndexUser: null,
			busyMediaIndex: false,

			mediaIndexReport: null,
			mediaIndexProgress: null,
			mediaIndexErrors: null,
			mediaIntervalID: null,
		}
	},



	beforeDestroy() {
		if( this.trackingIntervalID !== null ) clearInterval( this.trackingIntervalID )
		if( this.mediaIntervalID !== null ) clearInterval( this.mediaIntervalID )
	},



	methods: {

		runCronjob() {
			if( !this.cronCommand ) return
			SystemToolsAPI.runCronjob( this.cronCommand )
		},
		
		async runLongJob() {
			if( !this.longJobCommand ) return

			this.longJobErrors = null
			this.longJobID = null

			const data = await SystemToolsAPI.runLongJob( this.longJobCommand )

			this.longJobID = data.jobID
			this.pollLongJobProgress( this.longJobID )
		},


		pollLongJobProgress( jobID ) {

			// Poll for job updates until job is complete
			if( this.longJobIntervalID !== null ) clearInterval( this.longJobIntervalID )

			const updateJobStatus = async () => {
				const data = await LongJobAPI.getJobProgress( jobID )
				this.longJobUnitsComplete = data.workUnitsComplete
				this.longJobUnitsTotal = data.workUnitsTotal
				// this.sendingPercent = data.workUnitsTotal ? (data.workUnitsComplete / data.workUnitsTotal) : 1
				// this.sendingReport = data.jobReport

				if( data.error || data.errorReport ) this.longJobErrors = data.errorReport
				
				if( data.complete ) {
					this.longJobID = null
					
					clearInterval( this.longJobIntervalID )
					this.longJobIntervalID = null
				}
			}

			updateJobStatus()

			this.longJobIntervalID = setInterval( updateJobStatus, 2500 )
		},




		async regenerateAvatars() {
			this.busyAvatars = true
			var offset = 0

			var hasResults = true
			while(hasResults) {
				var res = null
				try {
					res = await UserProfileAPI.regenerateAvatars(offset)
					console.debug(res)

				} catch(e) {
					this.report = 'The server returned an error!'
					break

				} finally {
					offset++
				}

				if(!res) break
				if(!res || !res.total) break
				if(res.total == 0) hasResults = false  // stop when no more rows
				if(res.total > 0 && res.errors.length == res.total) hasResults = false // stop on 100% error rate

				this.report += `${res.updated} avatars updated...\n<br />`
				if(res.errors.length) this.errors = this.errors.concat(res.data.errors)
			}

			this.busyAvatars = false
		},


		async generateUserSessionSummaries() {
			this.busySessions = true

			try {
				await SystemToolsAPI.generateUserSessionSummaries()

			} finally {
				this.busySessions = false
			}
		},


		async verifyUserTracking() {
			this.busyTracking = true
			this.trackingProgress = null
			this.trackingReport = null
			this.trackingErrors = null

			try {
				const userIDs = this.trackingUser ? [ this.trackingUser.userID ] : []
				
				const res = await SystemToolsAPI.verifyUserTracking( userIDs )
				this.trackingReport = res.data


			} finally {
				this.busyTracking = false
			}
		},


		async verifyAllTracking( courseID ) {
			this.busyTracking = true
			this.trackingReport = null
			this.trackingErrors = null
			this.trackingProgress = 0

			var customerID = this.trackingCustomer ? this.trackingCustomer.id : null

			const fnName = this.trackingPlans ? 'verifyAllPlans' : 'verifyAllTracking'

			const data = await SystemToolsAPI[ fnName ]( courseID, customerID, this.trackingActiveOnly )
			const jobID = data
	
			// Poll for job updates until job is complete
			if( this.trackingIntervalID !== null ) clearInterval( this.trackingIntervalID )
			this.trackingIntervalID = setInterval( async () => {
				const data = await LongJobAPI.getJobProgress( jobID )
				this.trackingProgress = data.workUnitsTotal ? (data.workUnitsComplete / data.workUnitsTotal) : 1
				this.trackingReport = data.jobReport
				if( data.error ) this.trackingErrors = data.errorReport
				if( data.complete ) {
					this.busyTracking = false
					clearInterval( this.trackingIntervalID )
					this.trackingIntervalID = null
				}
			}, 2500 )
		},


		async initializeMediaIndex( courseID = null, userID = null ) {

			if( this.busyMediaIndex !== false ) return
			
			this.busyMediaIndex = courseID ? courseID : true
			this.mediaIndexReport = null
			this.mediaIndexProgress = null

			if( userID ) {
				try {
					const data = await SystemToolsAPI.initializeUserMediaIndex( userID )
					this.mediaIndexReport = data
				
				} finally {
					this.busyMediaIndex = false
				}

			} else {
				this.mediaIndexProgress = 0
				const data = await SystemToolsAPI.initializeMediaIndex( courseID )
				const jobID = data
		
				// Poll for job updates until job is complete
				if( this.mediaIntervalID !== null ) clearInterval( this.mediaIntervalID )
				this.mediaIntervalID = setInterval( async () => {
					const data = await LongJobAPI.getJobProgress( jobID )
					this.mediaIndexProgress = data.workUnitsComplete / data.workUnitsTotal
					this.mediaIndexReport = data.jobReport
					if( data.error ) this.mediaIndexErrors = data.errorReport
					if( data.complete ) {
						this.busyMediaIndex = false
						clearInterval( this.mediaIntervalID )
						this.mediaIntervalID = null
					}
				}, 2500 )

			}


		},


		async regenAllPlans() {
			if( !this.trackingPlans ) return  // exit if not in "plans" mode.

			for( var line of this.trackingReport ) {
				await SystemToolsAPI.setPlanRegenFlag( line.planID, true )
			}

			alert("All plans have been marked for regeneration.")
		},

	}
}
</script>

<style scoped>
.title {
	font-weight: bold;
	font-size: 1.5rem;
}
</style>
