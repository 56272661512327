import Exim from '@/models/Exim.js'


class PocAddress extends Exim {

	id = null
	pocID = null
	address = ''
	city = ''
	state = ''
	zip = ''
	country = ''
	label = null



	get addressString() {
		return `${ this.address }\n${ this.city }, ${ this.state } ${ this.zip }`
	}
	get addressHTML() {
		return `${ this.address }<br>${ this.city }, ${ this.state } ${ this.zip }`
	}



	isSame( other ) {
		if( this.id !== other.id ) return false
		if( this.pocID !== other.pocID ) return false
		if( this.address !== other.address ) return false
		if( this.city !== other.city ) return false
		if( this.state !== other.state ) return false
		if( this.zip !== other.zip ) return false
		if( this.country !== other.country ) return false
		if( this.label !== other.label ) return false
		return true
	}


}

export default PocAddress;