<template>
<section>

	<div class="flex-row flex-align-center flex-justify-center flex-gap-1 py-05 sticky-tools">

		<!-- FILTER: User -->
		<UserSearchDropdown
			v-if="!userID"
      placeholder="Filter by User..."
      width="175px"
      @updateUser="user => selectUser(user)" />

		<!-- FILTER: Customer -->
		<CustomerSearchDropdown
			v-if="!customerID && !affiliateID"
      placeholder="Filter by Customer..."
			width="175px"
			ref="customerSearch"
			@updateCustomer="cust => selectCustomer(cust)" />

		<!-- FILTER: Affiliate -->
		<AffiliateSearchDropdown
      v-if="!customerID && !affiliateID"
      placeholder="Filter by Affiliate..."
      width="175px"
      @update="aff => selectAffiliate(aff)" />

		<!-- FILTER: timestamp / date -->
		<div class="flex-row flex-gap-05 flex-wrap">
			<div class="nowrap">
				<v-date-picker v-model="startDate" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="Start Date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
			</div>
			<div>-</div>
			<div class="nowrap">
				<v-date-picker v-model="endDate" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="End Date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
			</div>
		</div>

		<!-- FILTER: Total User Time-in-Program -->
		<div>
			<div class="bold center">Total user time:</div>
			<div class="flex-row flex-gap-05">
				<div class="nowrap"><input type="number" v-model.number="minTime" @change="setMinTime()" min="0" style="width: 5em" />&nbsp;hrs</div>
				<div>-</div>
				<div class="nowrap"><input type="number" v-model.number="maxTime" @change="setMaxTime()" min="0" style="width: 5em" />&nbsp;hrs</div>
			</div>
		</div>

		<!-- FILTER: Expired/Unexpired Licenses -->
		<select v-model="activeLicenseFilter">
			<option :value="null">All Licenses</option>
			<option :value="true">Active only</option>
			<option :value="false">Expired only</option>
		</select>
	</div>

	<UserTimeStats ref="stats" />

	<section v-if="!loading">
		<ObjectTable
			:Columns="columns"
			:Source="items"
			:Numbered="true"
			:SortBy="sortBy"
			:SortAsc="sortAsc"
			:PageNum="page"
			:PageSize="pageSize"
			@edit="row => openUserDetails( row )"
			@sort="prop => sort(prop)"
			class="mt-1"
			ref="objectTable"
		/>
		<Paginator v-model="page" @input="initialize( false )" :numPages="pages" />
	</section>

  <div v-if="!loading && !error && !items.length" class="NoResults">No Records</div>

	<section v-if='loading' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

	<section v-if="error" class="warning">
		Failed to get records
	</section>

	<UserDetailsModal :user="selectedUser" :initialView="initialUserView" ref="userDetails" @close="$refs.objectTable.deselect()" />

</section>	
</template>



<script>
import LoginTrackingAPI from '@/api/LoginTrackingAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import UserAPI from '@/api/UserAPI.js'
import User from '@/features/Users/User.js'
import UserTime from '@/features/LoginTracking/UserTime.js'

import UserTimeStats from './UserTimeStats.vue'
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'

export default {
	name: 'UserTimeList',

	components: {
		UserTimeStats,
		UserSearchDropdown,
		CustomerSearchDropdown,
		AffiliateSearchDropdown,
		ObjectTable,
		Paginator,
		UserDetailsModal: () => import('@/features/Users/UserDetailsModal.vue'),
	},


	props: {
		userID: {
			type: Number,
			default: null
		},
		customerID: {
			type: Number,
			default: null
		},
		affiliateID: {
			type: Number,
			default: null
		},
		licenseKey: {
			type: String,
			default: null
		},
	},



	data() {
		return {
			user: null,
			selectedUser: null,
			initialUserView: null,

			customer: null,
			affiliate: null,
			startDate: null,
			endDate: null,
			
			minTime: null,
			maxTime: null,
			activeLicenseFilter: true,

			loading: false,
			error: false,

			sortBy: 'totalSeconds',
			sortAsc: false,
			page: 1,
			pageSize: 100,

			items: [],
			count: 0,
			pages: 1,			

			columns: [
				{
					displayName: 'User ID',
					propertyName: 'userID',
					sortable: true
				},
				{
					displayName: 'User',
					propertyName: 'email',
					displayFunction: function( item ) {
						return `<div>${item.firstName} ${item.lastName}</div><div>${item.email}</div>`
					},
					sortable: true
				},
				{
					displayName: 'Total Time',
					propertyName: 'totalSeconds',
					displayFunction: function( item ) { return item.totalTimeString },
					sortable: true
				},
				{
					displayName: 'Avg Session',
					propertyName: 'averageSessionSeconds',
					displayFunction: function( item ) { return item.averageSessionTimeString },
					sortable: true
				},
				{
					displayName: '# Sessions',
					propertyName: 'sessionCount',
					displayFunction: function( item ) { return item.sessionCountString },
					sortable: true
				},
				{
					displayName: '# Licenses',
					propertyName: 'licenseCount',
					displayFunction: function( item ) { return item.licenseCountString },

					sortable: true
				},
				{
					displayName: 'Reviews',
					propertyName: 'reviewStars',
					clickHandler: ( event, item ) => {
            event.stopPropagation()
						this.openUserDetails( item, 'reviews' )
					},
					displayFunction: function( item ) {
						var str = '<div style="color:orange; max-width: 5em;">'
						var rvwCount = 0
						for( var num of item.reviewStars ) {
							rvwCount++
							str += '<div>'
							for( var i=1; i <= num; i++ ) str += '<span class="icon-star-full" />'
							str += '</div>'
							if( rvwCount >= 3 ) {
								str += '<div class="link center">more</div>'
								break
							}
						}
						return str + '</div>'
					}
				},
			]
		}
	},



	computed: {
		start() {
      if (!this.startDate) return null;
      return Math.floor(this.startDate.getTime()/1000);
    },

    end() {
      if (!this.endDate) return null;
      return Math.floor(this.endDate.getTime()/1000);
    },
	},



	watch: {
		startDate() { this.page = 1; this.initialize() },
		endDate() { this.page = 1; this.initialize() },
		activeLicenseFilter() { this.page = 1; this.initialize() },
	},



	mounted() { this.initialize() },



	methods: {
		async initialize( getStats = true ) {

			try {
				this.loading = true
	
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
				req.userID = this.user ? this.user.userID : this.userID
				req.customerID = this.customer ? this.customer.id : this.customerID
				req.affiliateID = this.affiliate ? this.affiliate.id : this.affiliateID
				req.licenseKey = this.licenseKey
				req.startTimestamp = this.start
				req.endTimestamp = this.end
				req.minTotalSeconds = this.minTime !== null ? Math.round(this.minTime * 3600) : null // convert from hours to seconds
				req.maxTotalSeconds = this.maxTime !== null ? Math.round(this.maxTime * 3600) : null // convert from hours to seconds
				req.activeLicenseFilter = this.activeLicenseFilter
				if( getStats ) this.$refs.stats.initialize( req )
	
				// Get main table data
				const pr = await LoginTrackingAPI.getUserTimes( req )
				this.count = pr.count
				this.pages = pr.pages

				this.items = []
				for( var item of pr.data ) this.items.push( UserTime.import(item) )

				this.error = false
				
			} catch( e ) {
				this.items = []
				this.count = 0
				this.pages = 1
				this.error = true

			} finally {
				this.loading = false
			}
		},


		async openUserDetails( row, initialView = null ) {
			const userID = row.userID
			if( !userID ) return

			const res = await UserAPI.getUsersByID( [ userID ] )
			if( !res.data || !res.data.length ) return
			this.selectedUser = User.import( res.data[0] )
			this.initialUserView = initialView
			this.$refs.userDetails.open()
		},


		async selectUser( user = null ) {
      this.user = user
			this.page = 1
      this.initialize()
    },


		selectCustomer( customer = null ) {
			this.customer = customer
			this.page = 1
			this.initialize()
		},


		selectAffiliate( affiliate = null ) {
			this.affiliate = affiliate
			this.page = 1
			this.initialize()
		},


		setMinTime() {
			if( this.setMinTime.timeoutID ) clearTimeout( this.setMinTime.timeoutID )

			this.setMinTime.timeoutID = setTimeout( () => {
				if( isNaN( this.minTime ) || this.minTime === '' ) this.minTime = null
				this.page = 1
				this.initialize()
				this.setMinTime.timeoutID = null
			}, 500)
		},


		setMaxTime() {
			if( this.setMaxTime.timeoutID ) clearTimeout( this.setMaxTime.timeoutID )
			
			this.setMaxTime.timeoutID = setTimeout( () => {
				if( isNaN( this.maxTime ) || this.maxTime === '' ) this.maxTime = null
				this.page = 1
				this.initialize()
				this.setMaxTime.timeoutID = null
			}, 500)
		},


		sort( propName ) {
			if( this.sortBy === propName ) {
				this.sortAsc = !this.sortAsc
				this.initialize()
				return
			}

			this.sortBy = propName
			if( propName === 'userID' || propName === 'email' ) this.sortAsc = true
      else this.sortAsc = false

			this.initialize()
		},

	}


}
</script>



<style scoped>

</style>