<template>
  <div class='credit-card-form'>
    <form id="PaymentForm" @submit.prevent>
      <!-- <label for='input-cardholder-name'>Cardholder:</label> -->
      <input
        type='text'
        id='input-cardholder-name'
        placeholder='Name on Card'
        v-model='cardholderName'
        class="mx-0"
        :class="{'FieldError': !cardholderNameValid}"
        @focus="cardholderNameValid = true"
        @blur="validateCardholderName" />

      <!-- <label for='input-card-number'>Card Number:</label> -->
      <div id="CardNumber">
        <input
          type='text'
          id='input-card-number'
          placeholder='Card Number'
          v-model='cardNumber'
          :class="{'FieldError': !cardNumberValid}"
          @input="formatCardNumber"
          @focus="cardNumberValid = true"
          @blur="validateCardNumber" />
        <div id="CardType">{{cardType}}</div>
      </div>

      <div class="flex-row flex-align-start flex-justify-between w-100">
        <div>
          <input type='text'
            maxlength="4"
            size="4"
            id='input-card-code'
            placeholder='CVV'
            v-model='securityCode'
            class="mx-0"
            :class="{'FieldError': !securityCodeValid}"
            @focus="securityCodeValid = true"
            @blur="validateSecurityCode" />

          <input
            type='text'
            maxlength="5"
            size="5"
            id='input-zip-code'
            v-model='zipCode'
            placeholder='Zip'
            :class="{'FieldError': !zipCodeValid}"
            @focus="zipCodeValid = true"
            @blur="validateZipCode" />
        </div>

        <!-- <label>Expiration Date:</label> -->
        <div id="ExpirationDate" :class="{'FieldError': !exDateValid}">
          <span>EXP: </span>
          <select id="ExpirationMonth" @change="selectMonth">
            <option :value="null" default>Month</option>
            <option value="1">01 - Jan</option>
            <option value="2">02 - Feb</option>
            <option value="3">03 - Mar</option>
            <option value="4">04 - Apr</option>
            <option value="5">05 - May</option>
            <option value="6">06 - Jun</option>
            <option value="7">07 - Jul</option>
            <option value="8">08 - Aug</option>
            <option value="9">09 - Sep</option>
            <option value="10">10 - Oct</option>
            <option value="11">11 - Nov</option>
            <option value="12">12 - Dec</option>
          </select>

          <select id="ExpirationYear" @change="selectYear" class="mr-0">
            <option :value="null">Year</option>
            <option v-for="year in upcomingYears" :value="year" :key="year">{{year}}</option>
          </select>
        </div>
      </div>

      <div v-if="hasSubscription" id="SaveCard">Card data will be saved securely for subscription payments.</div>

      <div v-else id="SaveCard">
        <Checkbox v-model='saveCardData' class='ml-2'>
          Save card for future purchases
        </Checkbox>
      </div>
    </form>

    <div v-for="msg in errorMessages" :key="msg">
      <div v-if="msg != ''" class="ErrorMessage">{{msg}}</div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/utilities/Checkbox.vue';
import CreditCardValidator from './CreditCardValidator.js';

export default {
  name: 'CreditCardForm',

  components: {
    Checkbox
  },

  props: {
    data: Object,
    hasSubscription: Boolean,
    saveCard: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      saveCardData: true,
      cardType: 'unknown card type',
      cardholderNameValid: true,
      cardNumberValid: true,
      securityCodeValid: true,
      zipCodeValid: true,
      exDateValid: true,
      errorMessages: {
        cardholderName: '',
        cardNumber: '',
        cardType: '',
        securityCode: '',
        exDate: '',
        zipCode: '',
        processing: ''
      },
      acceptedCardTypes: ['VISA', 'MasterCard', 'Discover'],
    }
  },

  created() {
    this.saveCardData = this.saveCard;
  },

  computed: {
    cardholderName: {
      get() { return this.data ? this.data.cardholderName : ''; },
      set(value) { this.data.cardholderName = value; }
    },

    cardNumber: {
      get() { return this.data ? this.data.cardNumber : ''; },
      set(value) { this.data.cardNumber = value; }
    },

    securityCode: {
      get() { return this.data ? this.data.securityCode : ''; },
      set(value) { this.data.securityCode = value; }
    },

    zipCode: {
      get() { return this.data ? this.data.zipCode : ''; },
      set(value) { this.data.zipCode = value; }
    },

    exDateYear: {
      get() { return this.data ? this.data.exDateYear : ''; },
      set(value) { this.data.exDateYear = value; }
    },

    exDateMonth: {
      get() { return this.data ? this.data.exDateMonth : ''; },
      set(value) { this.data.exDateMonth = value }
    },

    upcomingYears() {
      let startYear = new Date().getFullYear();
      let currentYear = startYear;
      let years = [];
      while(currentYear <= startYear + 10) {
        years.push(currentYear);
        currentYear++;
      }
      return years;
    },

    isValid() {
      const isValid = this.cardholderNameValid &&
        this.cardNumberValid &&
        this.securityCodeValid &&
        this.zipCodeValid &&
        this.exDateValid;
      return isValid;
    }
  },

  watch: {
    isValid(value) {
      this.$emit('update-validity', value);
    },

    saveCardData() {
      this.$emit('saveCardData', this.saveCardData);
    }
  },

  methods: {
    selectMonth(event) {
      this.exDateMonth = event.target.value;
      if(this.exDateYear) this.validateExDate();
    },
    selectYear(event) {
      this.exDateYear = event.target.value;
      this.validateExDate();
    },


    formatCardNumber(event) {
      if( !this.cardNumber || this.cardNumber.length == 0 ) return;
      let cardNumber = this.cardNumber.replace(/\D/g, '');
      if( cardNumber.length == 0 ) {
        this.cardNumber = '';
        return;
      }

      // Detect card issuer (AMEX, VISA, etc)
      let AMEXDashes = false;
      let first = cardNumber.charAt(0);
      let second = null;
      if( cardNumber.length >= 2 ) second = cardNumber.charAt(1);
      if(first == '4') this.cardType = 'VISA';
      else if(first == '5') this.cardType = 'MasterCard';
      else if(first == '6') this.cardType = 'Discover';
      else if(first == '3' && (second == '4' || second == '7')) {
        this.cardType = 'American Express (AMEX)';
        AMEXDashes = true;
      } else this.cardType = 'unknown card type';

      // Format card number according to cardType
      let length = cardNumber.length;
      let newCardNumber = '';

      for(let index=0; index < cardNumber.length; index++) {
        if(index > 18) break;  // Max length = 19 digits
        let char = cardNumber.charAt(index);
        newCardNumber += char;

        if(index == length -1) break; // don't insert dash at end of string
        if(AMEXDashes) {
          if( index == 3 || index == 9 ) newCardNumber += '-';
        }else{
          if( (index+1) < 16 && ((index+1) % 4 == 0) ) newCardNumber += '-';
        }
      }

      this.cardNumber = newCardNumber;
      this.validateCardType();
    },


    validateCardholderName() {
      let valid = CreditCardValidator.cardholderNameIsValid(this.cardholderName);
      if(valid) {
        this.cardholderNameValid = true;
        this.errorMessages.cardholderName = '';
      } else {
        this.cardholderNameValid = false;
        this.errorMessages.cardholderName = "Please enter the cardholder's name as it appears on the card.";
        return false;
      }
    },
    validateCardNumber() {
      let validNumber = CreditCardValidator.cardNumberIsValid(this.cardNumber);
      let validType = this.validateCardType();
      if(validNumber) {
        if(validType) {
          this.cardNumberValid = true;
          this.errorMessages.cardNumber = '';
          return true;
        } else return false;

      } else {
        this.cardNumberValid = false;
        this.errorMessages.cardNumber = "Please enter a valid credit card number.";
        return false;
      }
    },
    validateCardType() {
      if(this.acceptedCardTypes.includes(this.cardType)) {
        this.errorMessages.cardType = '';
        return true;
      }

      if(this.cardNumber.length < 2) return true;

      const cardType = this.cardType == 'unknown card type' ? 'this type of card' : this.cardType;
      const cardTypeLabels = this.acceptedCardTypes.join(', ');

      this.cardNumberValid = false;
      this.errorMessages.cardType = `We don't accept ${cardType}.  Please use one of the following: ${cardTypeLabels}.`;
      return false;
    },
    validateSecurityCode() {
      let valid = CreditCardValidator.securityCodeIsValid(this.securityCode);
      if(valid) {
        this.securityCodeValid = true;
        this.errorMessages.securityCode = '';
        return true;
      } else {
        this.securityCodeValid = false;
        this.errorMessages.securityCode = "Please enter a valid CVV card code.";
        return false;
      }
    },
    validateExDate() {
      let valid = CreditCardValidator.expirationDateIsValid(this.exDateMonth, this.exDateYear);
      if(valid) {
        this.exDateValid = true;
        this.errorMessages.exDate = '';
        return true;
      } else {
        this.exDateValid = false;
        this.errorMessages.exDate = "It looks like your card has expired.";
        return false;
      }
    },
    validateZipCode() {
      let valid = CreditCardValidator.zipCodeIsValid(this.zipCode);
      if(valid) {
        this.zipCodeValid = true;
        this.errorMessages['zipCode'] = '';
        return true;
      } else {
        this.zipCodeValid = false;
        this.errorMessages['zipCode'] = "Please enter a valid zip code.";
        return false;
      }
    }
  }
}
</script>


<!-- <style scoped src='@/assets/css/Wizard.css'></style> -->


<style scoped>
.credit-card-form {
  font-size: 1.1em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#CardType {
  font-size: 0.8em;
  color: #555;
  text-align:right;
}

#PaymentForm {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 75%; */
  margin: 0;
  justify-content: flex-start;
  /* justify-items: start; */
  align-items: center;
}

#input-cardholder-name {
  width: 100%;
}

#CardNumber {
  width: 100%;
  padding: 0;
}
#input-card-number {
  width: 100%;
  margin: 0;
}



#ExpirationDate {
  margin-left: 0.5em;
}
#ExpirationDate input {
  margin-left: 0.25em;
  margin-right: 0.25em;
}

#ExpirationDate select {
  border: 1px solid #ddd;
  border-radius: 0.25em;
  padding: 0.5em;
}
#ExpirationDate select:focus, #ExpirationDate select:hover {
  border: 1px solid var(--ekno-blue);
}

.FieldError, .FieldError:hover {
  background-color: #FFEFEF;
  border-bottom: 1px solid red;
  color: darkred;
}

#SaveCard {
  /* width: 100%; */
  text-align: left;
  margin-top: 0.5em;
  color: #444;
  font-size: 0.9em;
}

.ErrorMessage {
  background-color: #FFEFEF;
  color: darkred;
}
</style>
