import POC from '../POC/POC.js';

class Customer {

	constructor(id) {
		this.id = id;
		this.name = null;
		this.address = null;
		this.city = null;
		this.state = null;
		this.zip = null;
		this.country = null;
		this.notes = null;
		this.dateCreated = null;				// Date object
		this.notInterested = false;
		this.customerInteractions = [];
		this.users = [];
		this.userID = null;

		this.confirmedOrderCount = null;
		this.inProcessOrderCount = null;
		this.activeLicenseCount = null;
		this.assignedLicenseCount = null;
		this.oldContractCount = null;
		this.newContractCount = null;
		this.newInvoicedCount = null;
		this.newPOReceivedCount = null;
		this.newPaidCount = null;

		this.nextActionDate = null;			// Date object
		this.nextActionName = null;

		this.initialStatus = null;
		this.renewStatus = null;
		this.pocs = [];
	}

	get userIDString() { return '' + this.userID; }
	set userIDString(value) {
		value = parseInt(value);
		this.userID = isNaN(value) ? 0 : value;
	}

	get createdDateString() {
		if(!this.dateCreated) return null;
		return this.dateCreated.toLocaleDateString();
	}
	get nextActionDateString() {
		if(!this.nextActionDate) return null;
		return this.nextActionDate.toLocaleString();
	}

	get nextActionPastDue() {
		if(!this.nextActionDate) return false
		return this.nextActionDate.getTime() < Date.now()
	}
	needsAttention(inDays = 7) {
		if(!this.nextActionDate) return false

		var targetDate = new Date()
		targetDate.setDate(targetDate.getDate() + inDays)
		if(this.nextActionDate - targetDate <= 0) return true
		return false
	}



	get initialStatusString() {
		return this.constructor.getStatus(this.initialStatus)
	}
	get renewStatusString() {
		return this.constructor.getStatus(this.renewStatus)
	}




	isSame(other) {
		return JSON.stringify(this.export(false)) == JSON.stringify(other.export(false));
	}



	static getStatus(status) {
		if(status == 0) return 'Not Interested'
		if(status == 1) return 'Proposal'
		if(status == 2) return 'Contact'
		if(status == 3) return 'Invoiced'
		if(status == 4) return 'PO Received'
		if(status == 5) return 'Paid'
		return ''
	}
	
	static getStatusBGColor(status) {
		if(status == 0) return 'purple'
		if(status == 1) return '#ddd'
		if(status == 2) return 'pink'
		if(status == 3) return 'yellow'
		if(status == 4) return 'orange'
		if(status == 5) return 'darkgreen'
		return ''
	}

	static getStatusFGColor(status) {
		if(status == 0) return 'white'
		if(status == 1) return '#555'
		if(status == 2) return '#555'
		if(status == 3) return '#555'
		if(status == 4) return '#555'
		if(status == 5) return 'white'
		return ''
	}



	static import(obj) {
		let customer = new Customer();

		for(let property in obj) {
			if (property == 'pointsOfContact') continue;
			customer[property] = obj[property];
		}

		if (obj.pointsOfContact) {
			for (let poc of obj.pointsOfContact) {
				customer.pocs.push(POC.import(poc));
			}
		}

		customer.dateCreated = obj.dateCreated ? new Date(obj.dateCreated*1000) : null
		customer.nextActionDate = obj.nextActionDate ? new Date(obj.nextActionDate*1000) : null

		return customer;
	}



	export(exportInteractions = true) {
		let data = {};
		for(let property in this) data[property] = this[property];

		data.users = [];
		for(let user of this.users) data.users.push(user);

		data.dateCreated = this.dateCreated ? Math.round(this.dateCreated.getTime() / 1000) : null
		data.nextActionDate = this.nextActionDate ? Math.round(this.nextActionDate.getTime() / 1000) : null

		if(exportInteractions) {
			for(let i=0; i<data.customerInteractions.length; i++) data.customerInteractions[i] = data.customerInteractions[i].export();

		} else {
			delete(data.customerInteractions);
		}

		return data;
	}




}

export default Customer;
