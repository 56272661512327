<template>
<section>

	<div class="flex-row flex-justify-between my-05">
		<SearchBox @search="str => searchString = str" />
		
		<div class="flex-row flex-justify-end flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} entries on {{ pages }} page{{ pages == 1 ? '' : 's' }}</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
		</div>

	</div>


	<div v-if='!loading && !error'>

		<div v-if='items.length'>
			<ObjectTable
				:Source='items'
				:Columns='columns'
				:StyleFunction='rowStyle'
				:Numbered='true'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:Deletable='!showSent'
				@edit='item => select( item )'
				@sort='prop => sort( prop )'
				@delete='item => showDeleteSelected( item )'
				ref='objectTable'
			/>
			<Paginator v-model="page" :numPages="pages" @input="pg => toPage( pg )" />
		</div>

		<div v-else class="NoResults">No results</div>

	</div>

	<div v-if="error" class="warning">Failed to get records</div>


	<StretchModal ref="messageDetailsModal" padding="1em" maxWidth="min( 100vw, 900px )" @close="deselect()">
		<template #header v-if="selectedItem">{{ selectedItem.sentTimestamp === null ? 'Draft' : 'Email Blast' }}</template>
		<div v-if="selectedItem" class="mt-1"><b>Subject:</b> {{ selectedItem.message.subject }}</div>
		<div v-if="selectedItem" class="mt-1 border-top" v-html="selectedItem.message.htmlBody" />
		<div v-if="selectedItem" class="mt-1 flex-row flex-justify-end sticky-bottom"><button class="pillButton" @click="useMessage( selectedItem )"> {{ selectedItem.sentTimestamp === null ? 'Edit / use this draft' : 'Create new draft from this message' }}</button></div>
	</StretchModal>


	<ConfirmDialog ref="confirmDeleteRow" @cancel="itemToDelete = null" @confirm="deleteItem()">
		<div v-if="itemToDelete">Really delete <b>&ldquo;{{ itemToDelete.message.subject || StrUtils.ellipsify( itemToDelete.message.plainBody, 60 ) }}&rdquo;</b>?</div>
	</ConfirmDialog>

</section>
</template>



<script>
import SearchBox from '@/components/utilities/SearchBox.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import MessageBlast from './MessageBlast.js'
import StringUtils from '@/libraries/StringUtils.js'

export default {
	name: 'MessageList',


	components: {
		SearchBox,
		ObjectTable,
		Paginator,
		StretchModal,
		ConfirmDialog,
	},


	props: {
		showDrafts: {
			type: Boolean,
			default: false
		},
		showSent: {
			type: Boolean,
			default: false
		},
	},

	
	data() {
		return {
			page: 1,
			pages: 1,
			pageSize: 100,
			count: 0,
			sortBy: 'modifiedTimestamp',
			sortAsc: false,
			searchString: null,

			items: [],
			loading: false,
			error: false,

			selectedItem: null,
			itemToDelete: null,

			columns: [
				{
					displayName: 'Sent',
					displayFunction: function( item ) {
						if( item.sentTimestamp != null ) return '<span class="icon-checkmark pa-05 bg-green-30 color-white" />'
						else return '<span class="icon-cross pa-05 bg-red-40 color-white" />'
					}
				},
				{
					displayName: 'Subject',
					propertyName: 'message.subject',
					displayFunction: function( item ) { return item.message.subject }
				},
				{
					displayName: 'Preview',
					propertyName: 'message.plainBody',
					displayFunction: function( item ) { return `<span class="color-gray-50">${ StringUtils.ellipsify( item.message.plainBody, 100 ) }</span>` }
				},
				{
					displayName: 'Creator',
					propertyName: 'creatorID',
					sortable: true,
				},
				{
					displayName: 'Owner',
					propertyName: 'ownerID',
					sortable: true,
				},
				{
					displayName: 'Recipients',
					propertyName: 'recipients',
					displayFunction: function( item ) {
						var recipCount = 0
						var listCount = 0
						for( const r of item.recipients ) {
							if( r.listID != null ) listCount++
							else recipCount++
						}
						const recipStr = `${ recipCount } individuals`
						const listStr = `${ listCount } lists`
						if( recipCount && listCount ) return `${ recipStr } & ${ listStr }`
						if( recipCount ) return recipStr
						if( listCount ) return listStr
						return null
					},
				},
				{
					displayName: 'Date Created',
					propertyName: 'createdTimestamp',
					displayFunction: function( item ) { return item.createdTimestamp ? item.createdTimestamp.toLocaleString() : null },
					sortable: true,
				},
				{
					displayName: 'Date Modified',
					propertyName: 'modifiedTimestamp',
					displayFunction: function( item ) { return item.modifiedTimestamp ? item.modifiedTimestamp.toLocaleString() : null },
					sortable: true,
				},
				{
					displayName: 'Date Sent',
					propertyName: 'sentTimestamp',
					displayFunction: function( item ) { return item.sentTimestamp ? item.sentTimestamp.toLocaleString() : null },
					sortable: true,
				}
			],
		}
	},



	computed: {
		StrUtils() { return StringUtils },
	},




	watch: {
		showDrafts() { this.page = 1; this.initialize() },
		showSent() { this.page = 1; this.initialize() },
		pageSize() { this.page = 1; this.initialize() },
		searchString() { this.page = 1; this.initialize() },
	},


	created() { this.initialize() },


	methods: {

		async initialize() {
			
			this.items = []
			this.error = false
			this.loading = true

			try {
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				req.includeUnsent = this.showDrafts
				req.includeSent = this.showSent
				const pr = await MessageCenterAPI.getMessageBlasts( req )

				this.count = pr.count
				this.pages = pr.pages

				for( var item of pr.data ) this.items.push( MessageBlast.import( item ) )

			} catch (e) {
				this.error = true
				throw e

			} finally {
				this.loading = false
			}
		},



		select( item ) {
			this.selectedItem = item
			this.$refs.messageDetailsModal.open()
		},

		deselect() {
			this.selectedItem = null
			this.$refs.objectTable.deselect()
		},
		
		
		useMessage( msg ) {
			this.$store.state.pipes.send( 'teleport', { mode: 'messages.compose', props: { draftIn: msg }, header: `Compose Message` } )
		},



		toPage( page ) {
			this.page = page
			this.initialize()
		},


		sort( prop ) {
			
			if( this.sortBy === prop ) {
				this.sortAsc = !this.sortAsc
				this.page = 1
				this.initialize()
				return
			}
			
			this.sortBy = prop
			if (
				prop === 'createdTimestamp' ||
				prop === 'modifiedTimestamp' ||
				prop === 'sentTimestamp'
			) {
				this.sortAsc = false
			} else {
				this.sortAsc = true
			}

			this.page = 1
			this.initialize()
		},


		showDeleteSelected( item ) {
			if( item.sentTimestamp !== null ) return

			this.itemToDelete = item
			this.$refs.confirmDeleteRow.open()
		},


		async deleteItem() {
			const item = this.itemToDelete

			if( item.sentTimestamp !== null ) return
			if( !item.messageBlastID ) return

			await MessageCenterAPI.deleteMessageBlast( item.messageBlastID )
			
			const idx = this.items.findIndex( elem => elem.messageBlastID == item.messageBlastID )
			if( idx < 0 ) return
			
			this.items.splice( idx, 1 )
			this.itemToDelete = null
		},



		rowStyle( row ) {
			const style = {}
			if( !row.sentTimestamp ) {
				style.fontWeight = 'bold'
				style.backgroundColor = 'var(--pp10-red-95)'
			} else {
				style.backgroundColor = 'var(--pp10-green-95)'
			}
			return style
		},


	}

}
</script>



<style scoped>

</style>