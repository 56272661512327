import Variation from "./Variation";

export default class Course {
  constructor() {
    this.courseID = ""
    this.courseName = ""
    this.activeVersionID = null
    this.courseVariations = []
    this.users = []
    this.baseUrlIcon = ""
    this.baseUrlImage = ""
    this.baseUrlVideo = ""
    this.baseUrlAudio = ""
    this.baseUrlPdf = ""
    this.baseUrlText = ""
    this.baseUrlOther = ""
  }

  isSame(course) {
    if (this.courseName == course.courseName &&
        this.baseUrlIcon == course.baseUrlIcon &&
        this.baseUrlImage == course.baseUrlImage &&
        this.baseUrlVideo == course.baseUrlVideo &&
        this.baseUrlAudio == course.baseUrlAudio &&
        this.baseUrlPdf == course.baseUrlPdf &&
        this.baseUrlText == course.baseUrlText &&
        this.baseUrlOther == course.baseUrlOther) {
      return true;
    }
    return false;
  }

  static import(json) {
    let course = new Course();

    course.courseID = json.course_id;
    course.courseName = json.course_name;
    course.activeVersionID = json.activeVersionID;

    course.courseVariations = [];
    if (json.course_variations) {
      json.course_variations.forEach(v => {
        course.courseVariations.push(Variation.import(v));
      });
    }

    course.baseUrlIcon = json.base_url_icon;
    course.baseUrlImage = json.base_url_image;
    course.baseUrlVideo = json.base_url_video;
    course.baseUrlAudio = json.base_url_audio;
    course.baseUrlPdf = json.base_url_pdf;
    course.baseUrlText = json.base_url_text;
    course.baseUrlOther = json.base_url_other;

    return course;
  }

  export() {
    let course = new Course();
    course.course_id = this.courseID;
    course.course_name = this.courseName;

    course.course_variations = [];
    this.courseVariations.forEach(v => {
      course.course_variations.push(v.export());
    });

    course.base_url_icon = this.baseUrlIcon;
    course.base_url_image = this.baseUrlImage;
    course.base_url_video = this.baseUrlVideo;
    course.base_url_audio = this.baseUrlAudio;
    course.base_url_pdf = this.baseUrlPdf;
    course.base_url_text = this.baseUrlText;
    course.base_url_other = this.baseUrlOther;

    return course;
  }
}
