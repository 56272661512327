<template>
<section :class="{'inline' : inline}">
	<slot name="button" v-if="showButton">
		<button :class='buttonClass' @click='open()'>{{buttonText}}</button>
	</slot>

	<StretchModal ref="modal" :width="width" :height="height" :padding="padding" :clickToClose="false">
		<template #header><div class="left">{{ modalTitle }}</div></template>
		<slot></slot>
	</StretchModal>
</section>
</template>


<script>
import StretchModal from '@/components/utilities/StretchModal.vue'
export default {
	name: 'ButtonModal',

	components: {
		StretchModal,
	},


	props: {
		buttonText: {
			type: String,
			default: 'Go'
		},
		modalTitle: {
			type: String,
			default: ''
		},
		inline: {
			type: Boolean,
			default: true
		},
		buttonClass: {
			type: String,
			default: 'button'
		},
		showButton: {
			type: Boolean,
			default: true
		},
		width: {
			type: String,
			default: '75%'
		},
		height: {
			type: String,
			default: 'auto'
		},
		padding: {
			type: String,
			default: '1em'
		}
	},



	methods: {
		open() { this.$refs.modal.open() },
	}


}
</script>

<style scoped>
.inline {
	display: inline-block;
}
</style>
