<template>
<section class="flex-row flex-align-start flex-justify-center" style="position: relative;">

  <div 
    v-if="busy" 
    class="flex-row flex-justify-center flex-align-center" 
    style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: rgba(75,75,75,0.75); z-index:1;"
    >
    <div style="color: black; font-size: 3em; padding: 0.75em; background: rgba(200,200,200,0.75); border-radius: 0.25em;">Processing {{ licenses.length }} Licenses ...</div>
  </div>

  <div style="flex-grow: 1; padding-right: 1em;">
    <p class="section-header">Selected Licenses</p>
    <div v-for="license in licenses" :key="license.serialKey">
      {{license.serialKey}} <span v-if="license.studentUser">(Student: {{license.studentUser.firstName}} {{license.studentUser.lastName}})</span><br />
    </div>
  </div>

  <div style="flex-grow: 1; padding-left: 1em;">
    <p class="section-header">Tools</p>
    <fieldset>
      <legend>Reset Passwords</legend>
      <input type="text" ref="newPassword" placeholder="New Password" /><br />

      <label for="init">
        <input id="init" type="checkbox" v-model="initOnly" /> Only update blank passwords
      </label>
      <br /><br />
      <button @click="changeStudentPasswords">Set students' passwords</button><br />

    </fieldset>

    <br />

    <fieldset>
      <legend>Renew Licenses</legend>
      <button @click="renewLicenses" :disabled="!allowRenew">Create Renewal Order</button><br />
      <div v-if="!allowRenew">
        <div v-if="!user && !customer">Cannot renew licenses - missing a user and/or customer to attribute the order to</div>
        <div v-else-if="!expireSoon()">One or more selected licenses does not expire soon</div>
      </div>
    </fieldset>

    <br />

    <fieldset>
      <legend>Change Expiration Dates</legend>
      <p>Expiration Date:
        <v-date-picker v-model="expirationDateString" mode="dateTime">
          <template v-slot="{ inputValue, inputEvents }">
            <input
            class=""
            placeholder="Exp Date"
            :value="inputValue"
            v-on="inputEvents"
            />
          </template>
        </v-date-picker>
        <button @click="changeExpirationDates">Change</button><br />
      </p>
    </fieldset>

    <br />

    <fieldset>
      <legend>Product</legend>
      <div>Base Product:</div>
      <select v-model='selectedProductSKU'>
        <option value="" disabled>SELECT A PRODUCT</option>
        <option v-for='product in products' :key='product.sku' :value='product.sku'>{{product.sku}} &nbsp; &nbsp; &mdash; &nbsp; &nbsp; "{{ product.name }}"</option>
      </select>
      <button @click="assignProductSKU">Assign Product to Licenses</button>
    </fieldset>

    <br />

    <fieldset>
      <legend>Assign Role</legend>
      User: <UserSearchDropdown v-model="selectedRoleUser" /><br>
      Role: <select v-model="selectedRole">
        <option disabled :value="null">Select Role</option>
        <option v-for="role in roles" :key="role.name" :value="role">
          {{role.name}} <br>
          <span v-if="role.description.length"> - {{role.description.substring(0, 45 - role.name.length)}} {{role.description.length > 45 - role.name.length ? '...' : ''}}</span>
        </option>
      </select>
      <!-- <CustomerSearchDropdown v-model="selectedRole" /> -->
      <button @click="assignRole">Assign Role on Licenses</button>
    </fieldset>

    <br />

    <fieldset>
      <legend>Assign Order</legend>
      <input v-model.number="orderID" placeholder="Order ID" />
      <input v-model.number="lineItemIndex" placeholder="Line Item Index (0-based)" />
      <button @click="assignToOrder">Assign Licenses to Order</button>
    </fieldset>

    <br />

    <fieldset>
      <legend>Assign Customer</legend>
      <CustomerSearchDropdown v-model="selectedCustomer" />
      <button @click="assignToCustomer">Assign Licenses to Customer</button>
    </fieldset>

    <br />

    <fieldset>
      <legend>Assign Master Slot</legend>
      <div class="flex-row flex-justify-between flex-align-center">
        <div class="flex-row flex-align-center flex-gap">
          <UserSearchDropdown v-model="selectedMaster" />
          <button @click="assignToMaster()">Assign Licenses to User</button>
        </div>
        <button @click="clearMaster()"><span class="icon-bin2" /> Remove master</button>
      </div>
    </fieldset>

    <br />

    <div>
      <fieldset>
        <legend>DELETE Licenses</legend>
        <button @click="deleteLicenses">Delete Licenses</button>
      </fieldset>
    </div>

  </div>

</section>
</template>


<script>
import UserAPI from '@/api/UserAPI.js';
import LicenseAPI from '@/api/LicenseAPI.js';
import EcommerceAPI from '@/api/EcommerceAPI.js';
import moment from 'moment';

import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue';

export default {
  name: 'BulkLicenseEdit',

  components: {
    UserSearchDropdown,
    CustomerSearchDropdown,
  },

  props: {
    licenses: Array,
    user: {
      type: Object,
      default: null
    },
    customer: {
      type: Object,
      default: null
    },
  },

  data() {
    return {
      busy: false,

      initOnly: false,
      allowRenew: true,
			orderID: null,
			lineItemIndex: null,
      selectedCustomer: null,
      selectedMaster: null,
      selectedRoleUser: null,
      selectedRole: null,
      selectedProductSKU: null,
      expirationDate: null,
    }
  },

  created() {
    if(!this.expireSoon()) this.allowRenew = false;
    if(!this.user && !this.customer) this.allowRenew = false;
  },



  computed: {
    roles() { return this.$store.state.Roles },
    products() { return this.$store.state.Products },

    expirationDateString: {
      get() { return this.expirationDate ? this.expirationDate.toLocaleDateString() : null },
      set(value) {
        if(value) value = new Date(value);
        else this.expirationDate = null;

        if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
        this.expirationDate = value;
      }
    },
  },



  methods: {

    async changeStudentPasswords() {
      this.busy = true

      const promises = [];
      for(let license of this.licenses) {
        if(!license.studentUser) continue;
        license.studentUser.userID = license.studentID;
        license.studentUser.newPassword = this.$refs.newPassword.value;
        promises.push( UserAPI.resetPassword(license.studentUser, this.initOnly) );
      }
      const res = await Promise.all(promises)

      this.busy = false
      return res
    },



    async assignToOrder() {
      if(!this.orderID) {
        alert("Please enter an orderID");
        return;
      }
      if(!Number.isInteger(this.lineItemIndex)) {
        alert("Please enter a line Item Index");
        return;
      }

      this.busy = true
      const licenseKeys = [];

      for(let license of this.licenses) licenseKeys.push(license.serialKey);
			try {
				await LicenseAPI.assignLicensesToOrder(this.orderID, this.lineItemIndex, licenseKeys)
        this.busy = false
				alert(`Licenses assigned to order ${this.orderID}, lineItem ${this.lineItemIndex}`)

			}catch(e) {
        this.busy = false
				alert(`There was an error: ${e}`)
			}
    },



    async assignToCustomer() {
      if(!this.selectedCustomer) {
        alert("Please select a customer");
        return;
      }

      this.busy = true
      const licenseKeys = [];

      for(let license of this.licenses) licenseKeys.push(license.serialKey);
      await LicenseAPI.assignLicensesToCustomer(this.selectedCustomer.id, licenseKeys);
      this.busy = false
      alert(`Licenses assigned to ${this.selectedCustomer.name}`)
    },



    async assignProductSKU() {
      if(!this.selectedProductSKU) {
        alert("Please select a product SKU to assign to these licenses")
        return
      }

      this.busy = true

      var i = 0
      for(let license of this.licenses) {
        license.sku = this.selectedProductSKU
        await LicenseAPI.editLicense(license)
        i++
      }

      this.busy = false
      alert(`${i} licenses are now ${this.selectedProductSKU}`)
    },



    async assignRole() {
      if(!this.selectedRoleUser || !this.selectedRole) {
        alert("please select a role, and a user to assign the role to")
        return
      }

      this.busy = true

      const licenseKeys = [];
      for(let license of this.licenses) licenseKeys.push(license.serialKey);
      await LicenseAPI.assignRoleOnLicenses(this.selectedRoleUser.userID, this.selectedRole.id, licenseKeys);

      this.busy = false
      alert(`${this.selectedRoleUser.firstName} is now a ${this.selectedRole.name} on ${licenseKeys.length} licenses`)
    },



    async assignToMaster() {
      console.log("Assign to master");
      if( !this.selectedMaster ) {
        alert("Please select a user to assign to the selected licenses")
        return
      }

      this.busy = true

      const licenseKeys = [];
      for(let license of this.licenses) licenseKeys.push(license.serialKey);
      await LicenseAPI.assignLicensesToMaster(this.selectedMaster.userID, licenseKeys);

      this.busy = false
      alert(`Licenses assigned to ${this.selectedMaster.firstName} ${this.selectedMaster.lastName} (${this.selectedMaster.emailAddress})`)
    },


    async clearMaster() {
      console.log("Clear Master slot")
      this.busy = true

      const licenseKeys = []
      for(let license of this.licenses) licenseKeys.push(license.serialKey)
      await LicenseAPI.assignLicensesToMaster(null, licenseKeys);

      this.busy = false
      alert(`Licenses' master slots cleared`)
    },



    async deleteLicenses() {
      const yes = confirm("Are you sure you want to DELETE these licenses?");
      if(!yes) return;

      this.busy = true
      for(let license of this.licenses) await LicenseAPI.deleteLicense(license);

      this.busy = false
      alert("Licenses deleted. Reload screen to see changes.");
    },



    async renewLicenses() {
      if(!this.allowRenew) {
        alert("Cannot bulk-renew these licenses. Check that all licenses expire within the next 30 days");
        return;
      }

      this.busy = true

      var licenseKeys = [];
      for(let license of this.licenses) {
        licenseKeys.push(license.serialKey);
      }
      console.debug(licenseKeys);
      var userID = this.user ? this.user.userID : 0;
      var customerID = this.customer ? this.customer.id : 0;
      try {
        await EcommerceAPI.bulkRenewalOrder(licenseKeys, userID, customerID);
        
        this.busy = false 
        alert("A renewal order has been created");
        this.allowRenew = false;
      
      }catch(e) {
        this.busy = false
        alert("FAILED to create renewal order. \nNote: cannot bulk-renew subscriptions.");
      }
    },


    async changeExpirationDates() {
      this.busy = true
      const ts = Math.round(this.expirationDate.getTime() / 1000)
      for(let license of this.licenses) {
        LicenseAPI.setExpirationDate(license.serialKey, ts)
      }
      this.busy = false
    },


    expireSoon() {
      var limit = moment();
      limit = limit.add(30, 'days');
      var enabled = true;
      for(let license of this.licenses) {
        if(!license.expirationDate) {
          enabled = false;
          break;
        }
        if(license.expirationDate.isAfter(limit)) {
          enabled = false;
          break;
        }
      }
      return enabled;
    }


  },
}
</script>


<style scoped>

</style>
