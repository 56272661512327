import Exim from "@/models/Exim";

class ModalMessage extends Exim {

	// Basic
	modalMessageID = null;
	typeID = null;
	name = null;
	displayOnLogin = false;
	displayOnEvent = false;
	displayInCourse = false;
	displayInLibrary = false;
	dateCreated = null;
	dateEdited = null;

	// Order
	_previousModalMessageID = null;
	priority = null;
	_maxPreviousMessages = null;
	get maxPreviousMessages() { return this._maxPreviousMessages }
	set maxPreviousMessages( value ) {
		if( !value && value !== null ) this._maxPreviousMessages = null
		else this._maxPreviousMessages = value
	}

	// Trigger logic
	isActive = false;
	_displayDelay = null;
	triggerStartDate = null;
	triggerEndDate = null;
	intervalDateMinutes = null;
	_triggerMinLogins = null;
	_triggerMaxLogins = null;
	_intervalLogins = null;
	_triggerMinMinutesInProgram = null;
	_triggerMaxMinutesInProgram = null;
	_intervalMinutesInProgram = null;
	_triggerMinMinutesPurchase = null;
	_triggerMaxMinutesPurchase = null;
	_intervalMinutesPurchase = null;
	_triggerMinMinutesAccount = null;
	_triggerMaxMinutesAccount = null;
	_intervalMinutesAccount = null;
	_maxRepeats = null;
	_triggerNodesCompleted = new Map(); // These nodes (nodeIDs) must be completed in order for the MM to trigger
                                      // [ nodeID: { score: Number, compare: -1|0|1, type: raw|scaled, required: Boolean }, ... ]
	
	// Event-driven logic
	_triggerNodeIDs = new Map() // Trigger WHEN these nodeIDs are completed (within the given score ranges if specified) 
															// [ nodeID: { score: Number, compare: -1|0|1, type: null|raw|adjusted }, ... ]

	triggerProjectedScore = null;    // { score: Number, rising: Boolean|null, type: projectedRawActual|projectedRawAdjusted|projectedScaledActual|projectedScaledAdjusted|percentToTarget }
	
	// Body
	body = null;
	_acceptButtonText = null;
	_acceptButtonNodeID = null;
	allowDismiss = true;
	showBody = false;
	showTextResponse = false;
	showVideoResponse = false;
	
	// Review
	showReviewResponse = false;
	showStarRating = false;
	showTextReview = false;
	showVideoReview = false;
	reviewType = null; // 'program' || 'affiliate'
	reviewStarPrompt = null;
	reviewBodyPrompt = null;

	// Dimensions
	_height = null;
	_minHeight = null;
	_maxHeight = null;
	_width = null;
	_minWidth = null;
	_maxWidth = null;


	// Assignments
	// Logic: (user IN $courseIDs && user IN $affiliateIDs && user IN $customerIDs && user IN $cohortIDs) || user IN $userIDs
	courseIDs = [];
	affiliateIDs = [];
	customerIDs = [];
	cohortIDs = [];
	roleIDs = [];
	userIDs = [];

	// Exclude NULLS
	requireCourseID = false;
	requireAffiliateID = false;
	requireCustomerID = false;
	requireCohortID = false;
	requireRoleID = false;

	// IFF only users are assigned (no course, aff, customer, cohort, or role), then TRUE makes this exclude ALL unlisted users.
	listedUsersOnly = false;

	// Assignment Counts
	triggerUserCount = null;
	assignedUserCount = null;

	// View Counts
	viewCount = 0;
	uniqueViewCount = 0;

	// Action Counts
	actionCount = 0;
	uniqueActionCount = 0;

	// Response Counts
	responseCount = 0;
	uniqueResponseCount = 0;

	// Review Counts
	reviewCount = 0;
	uniqueReviewCount = 0;




	
	get triggerNodesCompleted() {
		return this._triggerNodesCompleted;
	}
	set triggerNodesCompleted( obj ) {
		this._triggerNodesCompleted.clear()
		if( !obj ) return

		// Translate JSON object/map into js Map
		for( var prop in obj ) this._triggerNodesCompleted.set( parseInt(prop), obj[prop] )
	}
	hasTriggerNodeCompleted( nodeID ) {
		return this._triggerNodesCompleted.has( nodeID )
	}
	getTriggerNodeCompleted( nodeID ) {
		return this._triggerNodesCompleted.get( nodeID )
	}
	addTriggerNodeCompleted( nodeID, score = null, type = 'scaled', compare = 1, required = false ) {
		this._triggerNodesCompleted.set( nodeID, { score, type, compare, required } )
	}
	removeTriggerNodeCompleted( nodeID ) {
		this._triggerNodesCompleted.delete( nodeID )
	}
	


	get triggerNodeIDs() {
		return this._triggerNodeIDs;
	}
	set triggerNodeIDs( obj ) {
		this._triggerNodeIDs.clear()
		if( !obj ) return

		// Translate JSON object/map into js Map
		for( var prop in obj ) this._triggerNodeIDs.set( parseInt(prop), obj[prop] )
	}
	hasTriggerNodeID( nodeID ) {
		return this._triggerNodeIDs.has( nodeID )
	}
	getTriggerNodeID( nodeID ) {
		return this._triggerNodeIDs.get( nodeID )
	}

	// compare: 1 => greater than; 0 => equal to; -1 => less than
	addTriggerNodeID( nodeID, score = null, type = null, compare = 1 ) {
		this._triggerNodeIDs.set( nodeID, { score, type, compare } )
	}
	removeTriggerNodeID( nodeID ) {
		this._triggerNodeIDs.delete( nodeID )
	}




	// -------------------------------------------------------------
	// Validation & bounds checking
	// -------------------------------------------------------------

	// Order
	get previousModalMessageID() { return this._previousModalMessageID }
	set previousModalMessageID( value ) {
		if( value ) this._previousModalMessageID = value
		else this._previousModalMessageID = null
	}

	// Body
	get acceptButtonText() { return this._acceptButtonText }
	set acceptButtonText( value ) {
		if( value ) this._acceptButtonText = value
		else this._acceptButtonText = null
	}

	get acceptButtonNodeID() { return this._acceptButtonNodeID }
	set acceptButtonNodeID( value ) {
		if( value ) this._acceptButtonNodeID = value
		else this._acceptButtonNodeID = null
	}

	// displayDelay
	get displayDelay() { return this._displayDelay }
	set displayDelay( value ) {
		if( value ) this._displayDelay = value
		else this._displayDelay = null
	}

	// Max views
	get maxRepeats() { return this._maxRepeats }
	set maxRepeats( value ) {
		if( value ) this._maxRepeats = value
		else this._maxRepeats = null
	}

	// # Logins
	get triggerMinLogins() { return this._triggerMinLogins }
	set triggerMinLogins( value ) {
		if( !value ) value = null
		this._triggerMinLogins = value
	}
	
	get triggerMaxLogins() { return this._triggerMaxLogins }
	set triggerMaxLogins( value ) {
		if( !value ) value = null
		this._triggerMaxLogins = value
	}

	get intervalLogins() { return this._intervalLogins }
	set intervalLogins( value ) {
		if( !value ) value = null
		this._intervalLogins = value
	}


	// Time In Program
	get triggerMinMinutesInProgram() { return this._triggerMinMinutesInProgram }
	set triggerMinMinutesInProgram( value ) {
		if( !value ) value = null
		this._triggerMinMinutesInProgram = value
	}

	get triggerMaxMinutesInProgram() { return this._triggerMaxMinutesInProgram }
	set triggerMaxMinutesInProgram( value ) {
		if( !value ) value = null
		this._triggerMaxMinutesInProgram = value
	}

	get intervalMinutesInProgram() { return this._intervalMinutesInProgram }
	set intervalMinutesInProgram( value ) {
		if( !value ) value = null
		this._intervalMinutesInProgram = value
	}


	// Time since purchase
	get triggerMinMinutesPurchase() { return this._triggerMinMinutesPurchase }
	set triggerMinMinutesPurchase( value ) {
		if( !value ) value = null
		this._triggerMinMinutesPurchase = value
	}

	get triggerMaxMinutesPurchase() { return this._triggerMaxMinutesPurchase }
	set triggerMaxMinutesPurchase( value ) {
		if( !value ) value = null
		this._triggerMaxMinutesPurchase = value
	}

	get intervalMinutesPurchase() { return this._intervalMinutesPurchase }
	set intervalMinutesPurchase( value ) {
		if( !value ) value = null
		this._intervalMinutesPurchase = value
	}


	// Time since registration
	get triggerMinMinutesAccount() { return this._triggerMinMinutesAccount }
	set triggerMinMinutesAccount( value ) {
		if( !value ) value = null
		this._triggerMinMinutesAccount = value
	}

	get triggerMaxMinutesAccount() { return this._triggerMaxMinutesAccount }
	set triggerMaxMinutesAccount( value ) {
		if( !value ) value = null
		this._triggerMaxMinutesAccount = value
	}

	get intervalMinutesAccount() { return this._intervalMinutesAccount }
	set intervalMinutesAccount( value ) {
		if( !value ) value = null
		this._intervalMinutesAccount = value
	}


	// Dimensions
	get height() { return this._height }
	set height( value ) {
		if( value ) this._height = value
		else this._height = null
	}

	get minHeight() { return this._minHeight }
	set minHeight( value ) {
		if( value ) this._minHeight = value
		else this._minHeight = null
	}

	get maxHeight() { return this._maxHeight }
	set maxHeight( value ) {
		if( value ) this._maxHeight = value
		else this._maxHeight = null
	}

	get width() { return this._width }
	set width( value ) {
		if( value ) this._width = value
		else this._width = null
	}

	get minWidth() { return this._minWidth }
	set minWidth( value ) {
		if( value ) this._minWidth = value
		else this._minWidth = null
	}

	get maxWidth() { return this._maxWidth }
	set maxWidth( value ) {
		if( value ) this._maxWidth = value
		else this._maxWidth = null
	}


	// -------------------------------------------------------------
	// Computed properties
	// -------------------------------------------------------------

	
	get intervalDateDays() { return this.intervalDateMinutes !== null ? this.intervalDateMinutes / 1440 : this.intervalDateMinutes }
	set intervalDateDays( value ) { this.intervalDateMinutes = (value ? value * 1440 : null) }

	get triggerMinHoursInProgram() { return this.triggerMinMinutesInProgram !== null ? this.triggerMinMinutesInProgram / 60 : this.triggerMinMinutesInProgram }
	set triggerMinHoursInProgram( value ) { this.triggerMinMinutesInProgram = (value ? value * 60 : null) }
	get triggerMaxHoursInProgram() { return this.triggerMaxMinutesInProgram !== null ? this.triggerMaxMinutesInProgram / 60 : this.triggerMaxMinutesInProgram }
	set triggerMaxHoursInProgram( value ) { this.triggerMaxMinutesInProgram = (value ? value * 60 : null) }
	get intervalHoursInProgram() { return this.intervalMinutesInProgram !== null ? this.intervalMinutesInProgram / 60 : this.intervalMinutesInProgram }
	set intervalHoursInProgram( value ) { this.intervalMinutesInProgram = (value ? value * 60 : null) }
	
	get triggerMinDaysPurchase() { return this.triggerMinMinutesPurchase !== null ? this.triggerMinMinutesPurchase / 1440 : this.triggerMinMinutesPurchase }
	set triggerMinDaysPurchase( value ) { this.triggerMinMinutesPurchase = (value ? value * 1440 : null) }
	get triggerMaxDaysPurchase() { return this.triggerMaxMinutesPurchase !== null ? this.triggerMaxMinutesPurchase / 1440 : this.triggerMaxMinutesPurchase }
	set triggerMaxDaysPurchase( value ) { this.triggerMaxMinutesPurchase = (value ? value * 1440 : null) }
	get intervalDaysPurchase() { return this.intervalMinutesPurchase !== null ? this.intervalMinutesPurchase / 1440 : this.intervalMinutesPurchase }
	set intervalDaysPurchase( value ) { this.intervalMinutesPurchase = (value ? value * 1440 : null) }
	
	get triggerMinDaysAccount() { return this.triggerMinMinutesAccount !== null ? this.triggerMinMinutesAccount / 1440 : this.triggerMinMinutesAccount }
	set triggerMinDaysAccount( value ) { this.triggerMinMinutesAccount = (value ? value * 1440 : null) }
	get triggerMaxDaysAccount() { return this.triggerMaxMinutesAccount !== null ? this.triggerMaxMinutesAccount / 1440 : this.triggerMaxMinutesAccount }
	set triggerMaxDaysAccount( value ) { this.triggerMaxMinutesAccount = (value ? value * 1440 : null) }
	get intervalDaysAccount() { return this.intervalMinutesAccount !== null ? this.intervalMinutesAccount / 1440 : this.intervalMinutesAccount }
	set intervalDaysAccount( value ) { this.intervalMinutesAccount = (value ? value * 1440 : null) }
	
	
	get isAvailableCalendar() {
		const now = new Date()
		if( (this.triggerStartDate && this.triggerStartDate > now) || (this.triggerEndDate && this.triggerEndDate < now) ) return false
		return true
	}

	get hasLocation() {
		return this.displayInCourse || this.displayInLibrary
	}

	get hasDisplayEvent() {
		return this.displayOnLogin || this.displayOnEvent
	}

	get isUsed() {
		return this.hasLocation && this.hasDisplayEvent && this.isAvailableCalendar
	}

	
	get isGlobal() {
		return !this.courseIDs.length 
				&& !this.affiliateIDs.length
				&& !this.customerIDs.length
				&& !this.cohortIDs.length
				&& !this.roleIDs.length
				&& !this.userIDs.length
				&& !this.requireCourseID
				&& !this.requireAffiliateID
				&& !this.requireCustomerID
				&& !this.requireCohortID
				&& !this.requireRoleID
	}

	get hasInterval() {
		return this.intervalDateMinutes
				|| this.intervalLogins
				|| this.intervalMinutesInProgram
				|| this.intervalMinutesPurchase
				|| this.intervalMinutesAccount
	}



	// -------------------------------------------------------------
	// Date strings
	// -------------------------------------------------------------
	
	get triggerStartDateString() {
		if(!this.triggerStartDate) return null;
		return this.triggerStartDate.toLocaleString();
	}
	get triggerEndDateString() {
		if(!this.triggerEndDate) return null;
		return this.triggerEndDate.toLocaleString();
	}




	isSame(other) {
		return JSON.stringify( this.export() ) == JSON.stringify( other.export() );
	}


	clone() {
		return ModalMessage.import( this.export() )
	}



	static import(obj) {

		const newObj = super.import(obj)

		if(newObj.triggerStartDate) newObj.triggerStartDate = new Date(newObj.triggerStartDate * 1000)
		if(newObj.triggerEndDate) newObj.triggerEndDate = new Date(newObj.triggerEndDate * 1000)
		if(newObj.dateCreated) newObj.dateCreated = new Date(newObj.dateCreated * 1000)
		if(newObj.dateEdited) newObj.dateEdited = new Date(newObj.dateEdited * 1000)
		
		return newObj;
	}



	export() {
		const data = {};
		for(let property in this) {
			if( property === 'triggerUserCount' ) continue
			if( property === 'assignedUserCount' ) continue
			if( property.startsWith('_') ) property = property.substring(1)
			data[property] = this._exportRecursive( this[property] )
		}
		
		if(this.triggerStartDate) data.triggerStartDate = Math.round(this.triggerStartDate.getTime() / 1000)
		if(this.triggerEndDate) data.triggerEndDate = Math.round(this.triggerEndDate.getTime() / 1000)
		if(this.dateCreated) data.dateCreated = Math.round(this.dateCreated.getTime() / 1000)
		if(this.dateEdited) data.dateEdited = Math.round(this.dateEdited.getTime() / 1000)
		
		return data
	}

}
export default ModalMessage;