import Exim from '@/models/Exim.js'
import Discount from './Discount.js'



class Coupon extends Exim {
	
	code = null
	adminLabel = null
	adminDescription = null
	affiliateID = null
	
	enabled = true
	startTime = null
	endTime = null
	
	userUsageLimit = null
	globalUsageLimit = null
	globalUsageRateLimit = null        // Rate limit numerator (uses)
	globalRateLimitPerSeconds = null   // Rate limit denominator (seconds)
	
	discountAssignments = null         // { $poID => discount, ... }

	// OPTIONAL: usage information
	userUses = null
	globalUses = null
	orderIDs = null



	constructor() {
		super()
		this.discountAssignments = new Map()
	}



	addDiscount( pricingOptionID, discount ) {
		this.discountAssignments.set( pricingOptionID, discount )
	}

	removeDiscount( pricingOptionID ) {
		this.discountAssignments.delete( pricingOptionID )
	}

	isValidForPricingOption( pricingOptionID ) {
		return this.discountAssignments.has( pricingOptionID )
	}

	getDiscount( pricingOptionID ) {
		return this.discountAssignments.get( pricingOptionID )
	}



	isSame( other ) {
		return JSON.stringify( this.export() ) == JSON.stringify( other.export() )
	}

	clone() {
		return Coupon.import( this.export() )
	}



	static import( obj ) {
		const item = super.import( obj, ['discountAssignments'], ['startTime', 'endTime'] )
		
		if( obj.discountAssignments ) {
			for( var key in obj.discountAssignments ) {
				const d = Discount.import( obj.discountAssignments[ key ] )
				item.addDiscount( key, d )
			}
		}

		return item
	}


	export() {
		const data = super.export( [], ['startTime', 'endTime'] )
		return data
	}



}

export default Coupon