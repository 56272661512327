import Exim from '@/models/Exim.js'
import PocEmail from './PocEmail.js'
import PocPhone from './PocPhone.js'
import PocAddress from './PocAddress.js'


// Point of Contact
class POC extends Exim {

	id = null
	dateCreated = null
	isPrimary = false

	firstName = ''
	lastName = ''
	position = ''
	emails = []
	phones = []
	addresses = []
	
	notes = null
	importV9 = false

	// optional
	affIDs = []
	custIDs = []
	affiliates = null  // [ { id, name }, ... ]
	customers = null   // [ { id, name }, ... ]


	get emailAddress() { return this.emails.length ? this.emails[0].email : null }
	get phoneNumber() { return this.phones.length ? this.phones[0].phone : null }
	get primaryContact() { return this.emailAddress || this.phoneNumber || null }
	get stringValue() { return `${ this.firstName } ${ this.lastName } <${ this.primaryContact || '' }>`}


	
	shallowIsSame( other ) {
		if( this.id !== other.id ) return false
		if( this.firstName !== other.firstName ) return false
		if( this.lastName !== other.lastName ) return false
		if( this.position !== other.position ) return false
		if( this.notes !== other.notes ) return false
		return true
	}



	static import( obj ) {
		let poc = super.import( obj, [ 'dateCreated', 'emails', 'phones', 'addresses' ] )

		if( obj.dateCreated != null ) poc.dateCreated = new Date( obj.dateCreated * 1000 )

		for (let email of obj.emails) {
			poc.emails.push( PocEmail.import(email) )
		}
		for (let phone of obj.phones) {
			poc.phones.push( PocPhone.import(phone) )
		}
		for (let address of obj.addresses) {
			poc.addresses.push( PocAddress.import(address) )
		}

		return poc
	}


	export() {
		let data = super.export( [ 'dateCreated', 'emails', 'phones', 'addresses' ] )
		data.dateCreated = this.dateCreated ? Math.round( this.dateCreated.getTime() / 1000 ) : null

		data.emails = []
		for (let email of this.emails) data.emails.push( email.export() )

		data.phones = []
		for (let phone of this.phones) data.phones.push( phone.export() )

		data.addresses = []
		for (let address of this.addresses) data.addresses.push( address.export() )

		return data
	}
}

export default POC