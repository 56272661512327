class CartItem {

  
  // Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
  vueID = 0

  
  constructor(product, isSubscription = false, quantity = 1, licenseKeys = [], upgradeSKUs = [], prorateFactor = 1, pricingOptionID = null){

    this.vueID = 'cartItem' + ++CartItem.idCounter  // Ephemeral ID for Vue's v-for keys

    this.product = product;
    this.isSubscription = isSubscription;
    this._quantity = parseInt(quantity);
    this.licenseKeys = licenseKeys;
    this.upgradeSKUs = upgradeSKUs;
    this.isUpgrade = product.isUpgrade;
    this.prorateFactor = prorateFactor;
    this._pricingOptionID = parseInt(pricingOptionID);

    // For admin purchase overrides
    this._initialPrice = 0.00;
    this._subscriptionPrice = 0.00;

    // these are for display; set them after creating a CartItem
    this.startDate = null;
    this.endDate = null;
  }


  get quantity() { return this._quantity }
  set quantity(value) { this._quantity = parseInt(value) }

  get pricingOptionID() { return this._pricingOptionID }
  set pricingOptionID(value) {
    this._pricingOptionID = parseInt(value)
    let po = this.getPricingOption();
    if(!po) return;
    this.initialPrice = po.initialPrice;
    this.subscriptionPrice = po.subscriptionPrice;
    this.isSubscription = po.isSubscription;
  }

  get initialPrice() { return this._initialPrice; }
  set initialPrice(value) { this._initialPrice = parseFloat(value) }

  get subscriptionPrice() { return this._subscriptionPrice; }
  set subscriptionPrice(value) { this._subscriptionPrice = parseFloat(value) }


  getIndividualInitialPrice(prorateFactor = null) {
    // if(prorateFactor === null) prorateFactor = this.prorateFactor;
    // let price = this.product.getInitialPrice(prorateFactor, this.pricingOptionID);
    let price = this.initialPrice;
    price += this.getInitialUpgradePrice();
    return Number.parseFloat(price);
  }

  get individualRecurringPrice() {
    if(!this.isSubscription) return 0;
    // let price = this.product.getSubscriptionPrice(this.pricingOptionID);
    let price = this.subscriptionPrice;
    price += this.recurringUpgradePrice;
    return Number.parseFloat(price);
  }
  set individualRecurringPrice(value) {
    if(!this.isSubscription) this.subscriptionPrice = 0
    value = Number.parseFloat(value)
    this.subscriptionPrice = value
  }


  getInitialUpgradePrice(prorateFactor = null) {
    if(prorateFactor === null) prorateFactor = this.prorateFactor;

    let initialUpgradePrice = 0.00;
    let upgradePO = null;
    for (let upgrade of this.upgradeSKUs) {
      upgradePO = upgrade.getUpgradePricingOption(this.pricingOptionID);
      if(!upgradePO) console.error("Upgrade PricingOption not found for upgrade:", upgrade.name);
      initialUpgradePrice += upgrade.getInitialPrice(prorateFactor, upgradePO.id);
    }
    return Number.parseFloat(initialUpgradePrice);
  }

  get recurringUpgradePrice() {
    if(!this.isSubscription) return 0.00;

    let recurringUpgradePrice = 0.00;
    let upgradePO = null;
    for (let upgrade of this.upgradeSKUs) {
      upgradePO = upgrade.getUpgradePricingOption(this.pricingOptionID);
      if(!upgradePO) console.error("Upgrade PricingOption not found for upgrade:", upgrade.name);
      recurringUpgradePrice += upgrade.getSubscriptionPrice(upgradePO.id);
    }
    return Number.parseFloat(recurringUpgradePrice);
  }


  getPricingOption(poID = null) {
    return this.product.findPricingOption(poID || this.pricingOptionID);
  }


  getTotalInitialCost(prorateFactor = null) {
    return this.quantity * this.getIndividualInitialPrice(prorateFactor);
  }

  get totalInitialCost() { return this.getTotalInitialCost() }
  set totalInitialCost(value) {
    value = Number.parseFloat(value)
    this.initialPrice = value / this.quantity
  }

  get totalRecurringCost() {
    return this.quantity * this.individualRecurringPrice
  }
  set totalRecurringCost(value) {
    value = Number.parseFloat(value)
    this.individualRecurringPrice = value / this.quantity
  }



  get sku() { return this.product.sku; }

  get name() { return this.product.name; }

  get mainThumbnail() { return this.product.images[0].thumbnail; }

  get intervalString() {
    let po = this.getPricingOption();
    return po.intervalString
  }


  export(){
    let data = {};
    data.isSubscription = this.isSubscription;

    // Use 'licenseKeys' for renewal, and 'quantity' for new order.
    if(this.licenseKeys.length > 0) data.licenseKeys = this.licenseKeys;
    else data.quantity = this.quantity;

    if (this.product) {
      data.price = this.isSubscription ? this.product.subscriptionPrice : this.product.defaultPrice;
      data.sku = this.product.sku;
    }

    data.upgradeSKUs = [];
    for (let upgrade of this.upgradeSKUs) {
      data.upgradeSKUs.push({
        sku: upgrade.sku,
        pricingOptionID: upgrade.selectedPricingOption.id
      });
    }

    if (this.isUpgrade) data.isUpgrade = this.isUpgrade;
    data.pricingOptionID = this.pricingOptionID;
    data.initialPrice = this.initialPrice;
    data.subscriptionPrice = this.subscriptionPrice;

    return data;
  }
}
export default CartItem;
