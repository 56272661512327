<template>
<section class="wrapper">

  <div class="bar" :class="{'success': type == 'success', 'error': type == 'error'}" :style="{width: (pct*100) + '%'}" />
  <div class="label" v-if="label" v-html="label" />
  <div v-if="showBusy" class="shooter" id="shooter1" />
  <div v-if="showBusy" class="shooter" id="shooter2" />
</section>
</template>

<script>
export default {
  name: 'ProgressBar',

  props: {
    pct: { // between 0 and 1
      type: Number,
      default: 0.01
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'success'
    },
    showBusy: {
      type: Boolean,
      default: false
    }
  },

}
</script>

<style scoped>
.wrapper {
  position: relative;
  border-radius: 0.5em;
  margin: 5px 0;
  background-color: #eee;
}
.bar {
  border-radius: 0.5em;
  height: 20px;
  width: 100%;
  transition: width 0.5s ease-in-out;
}
.bar.success {
  /* background: #48c; */
  background: blueviolet;
}
.bar.error {
  background: red;
}

.label {
  font-size: 0.8em;
  position: absolute;
  background: rgba(255,255,255,0.65);
  left: calc( 50% - 2em );
  bottom: 2px;
  color: black;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.shooter {
  position: absolute;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 8px
}

#shooter1 {
  animation: shooter1 1.5s linear infinite;
  animation-delay: 0.07s;
  background: var(--ekno-blue);
}
#shooter2 {
  animation: shooter2 1.5s linear infinite;
  animation-delay: 0.35s;
  background: var(--pp10-orange);
}

@keyframes shooter1 {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
@keyframes shooter2 {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}
</style>
