class DemoRequest {

  constructor() {
    this.id = 0;
    this.timestamp = new Date(0);
    this.name = '';
    this.organization = '';
    this.email = '';
    this.phone = '';
    this.schedule = ''; // String - REQUESTED schedule
    this.demoDate = null;
    this.demoDateString = '';
    this.notes = '';
  }


  static import(obj) {

    const newObj = new this();
    for(let prop in obj) {
      if(newObj[prop] !== undefined) newObj[prop] = obj[prop];
    }

    if(newObj.timestamp) newObj.timestamp = new Date(newObj.timestamp * 1000);
    if(newObj.demoDate) newObj.demoDate = new Date(newObj.demoDate * 1000);
    if(newObj.demoDate) newObj.demoDateString = newObj.demoDate.toISOString();
    return newObj;
  }



  export() {
    return {
      id: this.id,
      timestamp: this.timestamp ? Math.round(this.timestamp.getTime() / 1000) : 0,
      name: this.name,
      organization: this.organization,
      email: this.email,
      phone: this.phone,
      schedule: this.schedule,
      demoDate: this.demoDate ? Math.round(this.demoDate.getTime() / 1000) : null,
      notes: this.notes
    }
  }



}

export default DemoRequest;
