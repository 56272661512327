<template>
  <div>
    <table class="table" v-if="Course && License">
      <tr class="table-row">
        <th class="table-header">Selected</th>
        <th class="table-header">ID</th>
        <th class="table-header">Name</th>
        <th class="table-header">Description</th>
      </tr>
      <tr v-for="(variation, index) in Course.courseVariations"
        :key="index"
        class="table-row clickable">
        <td class="table-data">
          <input type="checkbox"
            :checked="isSelected(variation)"
            @click="toggleVariation($event, variation)"/>
        </td>
        <td class="table-data">{{variation.id}}</td>
        <td class="table-data">{{variation.name}}</td>
        <td class="table-data">{{variation.description}}</td>
      </tr>
    </table>

    <button class="button" @click="save" :disabled="!changesMade">Save</button>

    <p v-if="changesSaved"
      :class="{'message-success': changesSaved}">
      Changes successfully saved
    </p>

    <p v-if="saveFailed" class="message-warning">
      Something went wrong; changes not saved.
    </p>
  </div>
</template>

<script>
import OrderedSet from "@/libraries/OrderedSet";
import LicenseAPI from "@/api/LicenseAPI";

export default {
  name: "variation-selector",

  props: {
    Course: Object,
    License: Object
  },

  data() {
    return {
      selectedVariations: null,
      changesSaved: false,
      saveFailed: false
    }
  },

  computed: {
    changesMade() {
      for (let v of this.selectedVariations) {
        if (!this.License.courseVariations.includes(v)) {
          this.changesSaved = false;
          this.saveFailed = false;
          return true;
        }
      }

      for (let v of this.License.courseVariations) {
        if (!this.selectedVariations.includes(v)) {
          this.changesSaved = false;
          this.saveFailed = false;
          return true;
        }
      }
      return false;
    }
  },

  created() {
    this.selectedVariations = new OrderedSet();
    for (let v of this.License.courseVariations) {
      this.selectedVariations.push(v);
    }
  },

  methods: {
    async save() {
      try {
        this.License.courseVariations = this.selectedVariations;
        await LicenseAPI.editLicense(this.License);
        this.changesSaved = true;
      } catch (e) {
        console.error(e);
        this.saveFailed = true;
      }
    },

    isSelected(variation) {
      const val = this.selectedVariations.includes( variation, 'id' )
      return val
    },

    toggleVariation(event, variation) {
      if (event.target.checked) {
        this.selectedVariations.push(variation);
      } else {
        this.selectedVariations.remove(variation);
      }
    },
  }
}
</script>
