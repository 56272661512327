import API from './API.js';
import {base_url} from '@/Config'

class EventMetricsAPI extends API {

  static controllerName = 'EventMetricsAPI';



  static async getExceptionSummary() {
    return await this.newGet('getExceptionSummary')
  }
  
  
  
  static async getEvents(label = '', start = '', end = '', olderThanID = '') {
    if(!start) start = ''
    if(!end) end = ''

    const url = `${base_url}/EventMetricsAPI/getEvents/${label}/${start}/${end}/${olderThanID}`;
    return await this.get(url);
  }



  static async getEvent(eventID) {
    const data = await this.newGet('getEvent', [eventID]);
    return data;
  }



  static async getAllMetrics() {
    const url = `${base_url}/EventMetricsAPI/getAllMetrics`;
    return await this.get(url);
  }



  static async getMetric(metricID) {
    const url = `${base_url}/EventMetricsAPI/getMetric/${metricID}`;
    return await this.get(url);
  }



  static async getMetricDataPoints(metricID, frequency, graphStart) {
    const url = `${base_url}/EventMetricsAPI/getMetricDataPoints/${metricID}/${frequency}/${graphStart}`;
    return await this.get(url);
  }



  static async healthCheck() {
    const url = `${base_url}/EventMetricsAPI/healthCheck`;
    return await this.get(url);
  }



  static async getRecentExceptionCount() {
    return this.newGet( 'getRecentExceptionCount', [86400] )
  }
}

export default EventMetricsAPI;
