<template>
<section>

  <!-- Folder Path -->
  <div class="flex-row flex-align-center flex-justify-center" style="font-weight: bold; font-size: 1.5em;">
    <span class="pathSegment icon-home pa-05" @click="$emit( 'goto', -1 )" />
    <span>/</span>
    <template v-for="(f,idx) in folderPath">
      <div :key="'seg'+f" @click="$emit( 'goto', idx )" class="pathSegment">{{ f }}</div>
      <div v-if="idx + 1 < folderPath.length" :key="'slash'+f" @click="$emit( 'goto', idx )">/</div>
    </template>
  </div>

  <!-- Search -->
  <div id="search">
    Search: <input type="text" v-model="searchText" /><span class="search-button icon-cross" @click="resetSearch" />
  </div>

  <!-- Toolbar -->
  <div id="controls" class="flex-row flex-align-center flex-justify-between">
    <button :class="{ 'disabled' : folderPath.length == 0 }" :disabled="folderPath.length == 0" @click="$emit( 'selectFolder', '..' )">
      <span class="icon-folder-upload">&nbsp;</span>
      <span class="filename">Up a level</span>
    </button>
    <div>
      Order by:
      <button
        :class="{'selected': sortBy === 'filename'}"
        @click.prevent="sort('filename')">Name {{sortBy === 'filename' ? (sortAscending ? '(A-Z)' : '(Z-A)') : ''}}
      </button>
      <button
        :class="{'selected': sortBy === 'size'}"
        @click.prevent="sort('size')">Size {{sortBy === 'size' ? (sortAscending ? '(Small -> Large)' : '(Large -> Small)') : '' }}
      </button>
      <button
        :class="{'selected': sortBy === 'ctime'}"
        @click.prevent="sort('ctime')">Date {{sortBy === 'ctime' ? (sortAscending ? '(Oldest First)' : '(Newest First)') : '' }}
      </button>
    </div>
    <div>
      <span class="icon icon-folder-plus pa-05" @click.prevent="$refs.createFolderModal.open()" />
    </div>
  </div>


  <!-- List of subfolders -->
  <div id="folder-list" v-for="folderName in filteredSubfolders"
    :key="folderName"
    @click="$emit( 'selectFolder', folderName )"
    class="list-item flex-row flex-align-center flex-justify-between"
  >
    <span class="list-item-name">
      <span class="icon-folder">&nbsp;</span>
      <span class="filename">{{ folderName }}</span>
    </span>

    <span class="icon-bin deleteButton list-button" @click.stop="showDeleteFolder( folderName )"></span>
  </div>


  <!-- List of files -->
  <div id="item-list" v-for="item in files"
    :key="item.filename"
    @click="selectItem(item)"
    class="list-item flex-row flex-align-center flex-justify-between"
    :class="{'selected': selectedItem === item}">

    <span v-if="editingItem === item" class="flex-row flex-align-center flex-justify-start flex-gap-1">
      <input
        v-model="newName"
        class="list-item-name"
        @click.stop />

      <button
        class="pointer"
        @click.prevent="renameItem(item)"
        @click.stop>
        save
      </button>
      <span @click="editingItem = null"><span class="icon-cross" /></span>
    </span>

    <span v-else class="list-item-name">
      <span :class="getIconName(item)">&nbsp;</span>
      <span class="filename">{{item.filename}}</span>
      <span class="fileinfo">{{displaySize(item)}} {{displayDate(item.ctime)}}</span>
      <span v-if="selectedItem === item && editingItem !== item" class="icon-pencil list-icon-button ml-1" @click.stop="editItem(item)"></span>
    </span>

    <span class="icon-bin deleteButton list-button" @click="deleteItem(item)"></span>
    <!-- <div class="reset-float"></div> -->
  </div>


  <StretchModal padding="2em" maxWidth="600px" ref="createFolderModal">
    <template #header>Create New Folder</template>
    <section>
      New Folder Name:<br>
      <input type="text" v-model="newFolderName" placeholder="folder name" class="w-100" @keyup.enter="createFolder()" />
      <div class="flex-row flex-justify-end mt-2"><button class="SquareBlueButton" @click="createFolder()">Create</button></div>
    </section>
  </StretchModal>

  <ConfirmDialog padding="2em" maxWidth="600px" ref="deleteFolderModal" @confirm="deleteFolder()">
    <div v-if="folderToDelete">Really delete folder <b>{{ folderToDelete }}</b>?</div>
  </ConfirmDialog>

</section>
</template>



<script>
// import LibUI from '@/libraries/LibUI';
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

export default {
  name: 'media-library-list',

  components: {
    ConfirmDialog,
    StretchModal,
  },


  props: {
    subfolders: Array,
    items: Array,
    selectNewest: Number,
    update: Number,
    folderPath: {
      type: Array,
      default: () => []
    },
  },


  data() {
    return {
      files: [],

      selectedItem: null,
      editingItem: null,
      newName: "",

      newFolderName: null,
      folderToDelete: null,

      sortBy: "",
      sortAscending: true,

      searchText: "",
      filteredItems: [],
      filteredSubfolders: [],
    }
  },



  created(){
    this.files = this.items
    this.filteredSubfolders = this.subfolders
  },



  watch: {
    selectNewest(){
      if(this.selectNewest === 0) return;

      let newestFile = this.items[0];
      for(let file of this.items){
        if(file.ctime > newestFile.ctime) newestFile = file;
      }

      this.selectItem(newestFile);
    },

    searchText(){
      this.updateSearch();
    },

    update(){
      this.updateSearch();
    },

    items() { if(this.searchText === '') this.files = this.items },
    subfolders() { if( this.searchText === '' ) this.filteredSubfolders = this.subfolders }
  },



  methods: {
    deleteItem(item){
      this.$emit('delete', item);
    },

    resetSearch(){
      this.searchText = '';
    },

    updateSearch(){
      if(this.searchText === '') {
        this.files = this.items
        this.filteredSubfolders = this.subfolders
        return
      }

      this.filteredItems = this.items.filter(item => {
        if(item.filename.toLowerCase().includes(this.searchText.toLowerCase())) return true;
        return false;
      });

      this.files = this.filteredItems;

      this.filteredSubfolders = this.subfolders.filter( folder => {
        if( folder.toLowerCase().includes( this.searchText.toLocaleLowerCase() ) ) return true
      })
    },

    selectItem(item) {
      if(item === this.selectedItem) {
        this.selectedItem = null;
        this.$emit('select', null);
        return;
      }

      this.editingItem = null;
      this.selectedItem = item;
      this.newName = item.filename;
      this.$emit('select', item);
    },

    editItem(item){
      this.editingItem = item;
    },

    renameItem(item){
      if( item.filename === this.newName ) {
        this.editingItem = null
        return
      }

      this.$emit( 'renameItem', item.filename, this.newName );
    },

    displaySize(file){
      if(file.size > 999 && file.size <= 999999 ) return Math.round(file.size/1000) + " KB";
      else if(file.size > 999999 && file.size <= 999999999) return Math.round(file.size/1000000) + " MB";
      else return Math.round(file.size/1000000000) + " GB";
    },

    displayDate(timestamp){
      let date = new Date(timestamp*1000);
      return date.toLocaleString()
    },

    sort(attrName){
      if(this.sortBy === attrName) this.sortAscending = !this.sortAscending;
      else if(attrName === 'ctime') this.sortAscending = false; // default NEWEST first for date
      else if(attrName === 'size') this.sortAscending = false // default LARGEST first for size
      else this.sortAscending = true; // default A - Z for alphabetical

      let neg = -1, pos = 1;
      if(!this.sortAscending){
        neg = 1;
        pos = -1;
      }
      this.items.sort((a,b) => {
        if(typeof a[attrName] === 'string'){
          let compareVal = a[attrName].toLowerCase().localeCompare(b[attrName].toLowerCase());
          if(compareVal < 0) return neg;
          if(compareVal === 0) return 0;
          else return pos;
        }else{
          if( a[attrName] < b[attrName] ) return neg;
          if( a[attrName] === b[attrName] ) return 0;
          else return pos;
        }

      });

      this.sortBy = attrName;
    },

    getIconName( file ) {
      if( !file || !file.filename ) return 'icon-file-empty'
      
      const fn = file.filename
      if( fn.endsWith('.png') || fn.endsWith('.jpg') || fn.endsWith('.jpeg') || fn.endsWith('.gif') || fn.endsWith('.jxl') ) return 'icon-file-picture'
      if( fn.endsWith('.mp4') || fn.endsWith('.m4v') || fn.endsWith('.mov') ) return 'icon-file-play'
      return 'icon-file-empty'
    },



    createFolder() {
      if( !this.newFolderName ) return
      this.$emit( 'createFolder', this.newFolderName )
      this.newFolderName = null
      this.$refs.createFolderModal.close()
    },

    showDeleteFolder( folder ) {
      this.folderToDelete = folder
      this.$refs.deleteFolderModal.open()
    },

    deleteFolder() {
      if( !this.folderToDelete ) return
      this.$emit( 'deleteFolder', this.folderToDelete )
      this.folderToDelete = null
    }
    
  }
}
</script>

<style scoped>

.pathSegment {
  background-color: var(--pp10-gray-90);
  border-radius: 0.25em;
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.pathSegment:hover {
  background-color: var(--ekno-blue-90);
}

/* See AdminViews.css */
.filename {
  margin-right: 1em;
}
.fileinfo {
  padding: 0.3em 0;
  color: #555;
  font-size: 0.8em;
}

#search {
  margin: 0.5em 0.25em;
}

.search-button {
  background-color: white;
  padding: 0.25em;
  margin: 0.25em;
  color: darkred;
  border: 1px solid lightgray;
}
.search-button:hover {
  color: red;
  border: 1px solid #ccc;
}

#controls {
  background-color: #eee;
  padding: 0.5em 0.25em;
  margin: 0;
}
#controls button {
  padding: 0.5em;
  margin: 0.25em;
  border: 1px gray solid;
  border-radius: 0.5em;
  cursor: pointer;
}
#controls button:hover {
  background: white;
}
#controls button.selected {
  background-color: #4E98FF;
  color: #FFF;
  font-weight: bold;
}
#controls button.selected:hover{
  background: #5EA8FF;
}
#controls button.disabled {
  background-color: inherit;
  cursor: not-allowed;
}

#controls .icon:hover {
  color: var(--pp10-green);
}


</style>
