<template>
<section class="lineItem">
	<section style="margin: 0 auto;">
		<div v-if="!editMode">
			<b style="font-size: 1.5em;">{{ item.product ? item.product.name : '' }} ({{ item.sku }})</b>
			&nbsp; <span v-if="allowEdit" class="link" @click.stop="edit()">edit</span>
			&nbsp; <button v-if="allowDelete" @click.stop="$emit('deleteItem')" style="float:right;"><span class="icon-bin" /></button>
		</div>
		<div v-if="editMode" class="flex-row flex-justify-between">
			<select v-model="selectedProductSKU">
				<option v-for="prod in $store.state.Products.objects" :value="prod.sku" :key="prod.sku">{{ prod.name }} ({{ prod.sku }})</option>
			</select>
			<div>
				<button @click.stop="revert"><span class="icon-undo" /> undo</button>
				&nbsp;<button @click.stop="editMode = false">done</button>
			</div>
		</div>

		<div v-if="editMode">
			<input v-model="item.productNameOverride" placeholder="Display Name" class="w-100" />
		</div>
		<div v-else-if="item.productNameOverride">(invoiced as <b>{{ item.productNameOverride }}</b>)</div>


		{{ item.transactionType }}<br><br>

		<!-- EDIT: Quantity + Pricing -->
		<div v-if="editMode">
			<input type="number" step="1" v-model="item.quantity" @change="$emit('update')" style="width: 6em;" />
			@ $<input type="number" step="1" min="0.00" v-model="item.price" @change="$emit('update')" style="width: 6em;" :disabled="!item.pricingOption || !item.pricingOption.perUnitPricing" /> each
			= $<input type="number" step="1" min="0.00" v-model="item.totalPrice" @change="$emit('update')" style="width: 6em;" :disabled="!item.pricingOption || item.pricingOption.perUnitPricing" />
		</div>

		<!-- DISPLAY: Quantity + Pricing -->
		<div v-if="!editMode" style="font-size: 1.25em;">
			<span><b>{{item.quantity}}</b></span>
			unit{{ item.quantity == 1 ? '' : 's' }} @

			<span v-if="item.pricingOption" v-html="item.pricingOption.getPricingString(item.price)" />
			<span v-else-if="item.price">{{ Math.moneyFormat( item.price ) }}</span>
			<span v-else>NULL_UNKNOWN</span>
			= <b>{{ Math.moneyFormat( item.totalPrice ) }}</b>
			<span v-if="item.discount">
				&nbsp;<span class="coupon">- {{ Math.moneyFormat( item.discount.actualDiscount ) }} <span class="icon-ticket" /></span>
				= <b>{{ Math.moneyFormat( item.totalPrice - item.discount.actualDiscount ) }}</b>
			</span>
		</div>

		<div v-if="item.discount" class="flex-row flex-align-center flex-justify-start flex-gap-05">
			<div>Coupon:</div>
			<div class="coupon"><span class="icon-ticket" /> {{ item.discount.couponCode }}</div>
		</div>



		<div>Auto-renew:
			<span v-if="!item.pricingOption">NULL_UNKNOWN (ID: {{item.pricingOptionID}})</span>
			<span v-else-if="item.pricingOption.isSubscription">
				<span v-if="editMode">
					{{item.quantity}} units
					@ $<input type="number" step="1" min="0.00" v-model="item.subscriptionPrice" style="width: 6em;" :disabled="!item.pricingOption || !item.pricingOption.perUnitPricing" /> each
					= $<input type="number" step="1" min="0.00" v-model="item.totalSubscriptionPrice" style="width: 6em;" :disabled="!item.pricingOption || item.pricingOption.perUnitPricing" />
				</span>
				<span v-if="!editMode">
					<b>{{item.quantity}}</b> units @ <span v-html="item.pricingOption.getSubscriptionString(item.subscriptionPrice)" /> =
					<b>{{ Math.moneyFormat( item.totalSubscriptionPrice ) }}</b> / {{item.pricingOption.intervalString}}
				</span>
			</span>
			<span v-else>NO</span>
		</div>
		<br>


		<!-- Pricing Option -->
		<div v-if="editMode">
			Pricing Option:
			<select v-model="selectedPricingOptionID">
				<option v-for="po in pricingOptions" :value="po.id" :key="po.id">#{{po.id}} &mdash; "{{po.name}}"</option>
			</select>
		</div>

		<div v-if="!editMode">
			<span v-if="item.pricingOption">Pricing Option: #{{item.pricingOption.id}} &mdash; "{{item.pricingOption.name}}"</span>
			<span v-else>Pricing Option: NULL_UNKNOWN (#{{ item.pricingOptionID }})</span>
		</div>


		<!-- License Term -->
		<div v-if="!hasContractExpirationDate">
			<div v-if="item.pricingOption">License Term: {{ item.pricingOption.termString }}</div>
			<div v-else>License Term: NULL_UNKNOWN</div>
		</div>

		<!-- Expiration Date override -->
		<div v-if="hasContractExpirationDate">
			<div v-if="editMode" :class="{'error' : hasContractExpirationDate != (item.licenseExpirationDate !== null)}">
				Expiration Date:
				<v-date-picker v-model="expirationDateString" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
						class=""
						placeholder="No Expiration Date"
						:value="inputValue"
						v-on="inputEvents"
						/>
					</template>
				</v-date-picker>
				<div>(Overrides Pricing Option's "license length")</div>
			</div>
			<div v-if="!editMode" :class="{'error' : hasContractExpirationDate != (item.licenseExpirationDate !== null)}">
				Expires: <b>{{expirationDateString || "Determined by Pricing Option"}}</b>
			</div>
		</div>
		<br>


		<!--Fulfillment option for site-licenses -->
		<div v-if="editMode && hasContractExpirationDate">
			<select v-model="item.fulfill">
				<option :value="true">Create licenses when fulfilling order</option>
				<option :value="false">Customer will create their OWN licenses</option>
			</select>
		</div>
		<div v-else style="font-size: 1.25em;">
			<span v-if="item.fulfill">Create licenses when fulfilling order</span>
			<span v-else>Customer will create their OWN licenses</span>
		</div>

		<!-- Renewal option for site-licenses -->
		<div v-if="editMode && hasContractExpirationDate">
			<select v-model="item.renewOnAnyContract">
				<option :value="false">Renewal contracts must have matching SKU &amp; pricing option</option>
				<option :value="true">Renew using ANY contract</option>
			</select>
		</div>
		<div v-else style="font-size: 1.25em;">
			<span v-if="!item.renewOnAnyContract">Renewal contracts must have matching SKU &amp; pricing option</span>
			<span v-else>Renew using ANY contract</span>
		</div>
	</section>

	<!-- License keys created & renewed -->
	<section class="flex-row flex-gap-2 flex-justify-center center mt-1 pt-1">
		<div style="width:100%;">
			<strong>Licenses Created:</strong><span> {{item.newLicenseKeys.length}}</span>&nbsp;
			<button v-if="editMode" @click.stop="showAddNewLicenseKey = true"><span class="icon-plus" /></button>
			<div v-if="item.newLicenseKeys.length" style="max-height: 150px; overflow: auto; padding: 0.5em; border: 1px solid #ddd; background: #fafafa;">
				<div v-for="(licenseKey, index) of item.newLicenseKeys" :key="licenseKey">{{licenseKey}} <button v-if="editMode" @click="removeNewLicenseKey(index)"><span class="icon-bin" /></button></div>
			</div>
			<div v-if="editMode && showAddNewLicenseKey"><input type="text" ref="addNewLicenseKey" /><button @click.stop="addNewLicenseKey">save</button></div>
		</div>

		<div style="width:100%;">
			<strong>Licenses renewed / upgraded:</strong><span> {{item.licenseKeys.length}}</span>&nbsp;
			<button v-if="editMode" @click.stop="showAddLicenseKey = true"><span class="icon-plus" /></button>
			<div v-if="item.licenseKeys.length" style="max-height: 150px; overflow: auto; padding: 0.5em; border: 1px solid #ddd; background: #fafafa;">
				<div v-for="(licenseKey, index) of item.licenseKeys" :key="licenseKey">{{licenseKey}} <button v-if="editMode" @click="removeLicenseKey(index)"><span class="icon-bin" /></button></div>
			</div>
			<div v-if="editMode && showAddLicenseKey"><input type="text" ref="addLicenseKey" /><button @click.stop="addLicenseKey">save</button></div>
		</div>

	</section>

	<div v-if="item.upgradeSKUs.length" class="mt-1 mb-05 header"><hr><b style="font-size: 1.5em;">Upgrades:</b></div>
	<div v-if="item.upgradeSKUs.length" class="flex-row">
		<div v-for="upgrade in item.upgradeSKUs" :key="upgrade.sku" class="border round-05 pa-05">
			{{ upgrade.sku }} ({{ upgrade.pricingOption ? upgrade.pricingOption.name : upgrade.pricingOptionID }})
			<div v-if="upgrade.pricingOption">
				<div>Initial Price: <b>${{ upgrade.pricingOption.initialSalePrice ? upgrade.pricingOption.initialSalePrice : upgrade.pricingOption.initialFullPrice }}</b></div>
				<div v-if="upgrade.pricingOption.subscriptionSalePrice || upgrade.pricingOption.subscriptionFullPrice">Subscription: <b>${{ upgrade.pricingOption.subscriptionSalePrice ? upgrade.pricingOption.subscriptionSalePrice : upgrade.pricingOption.subscriptionFullPrice }}</b></div>
			</div>
		</div>
	</div>
	<div v-if="!item.product || !item.pricingOption || item.productIsGlobal || item.pricingOptionIsGlobal">
		<br>
		<span style="color: red;">WARNING: </span>
		<span v-if="!item.product">No Product</span>
		<span v-if="!item.pricingOption">No PricingOption</span>
		<span v-if="item.productIsGlobal">Global Product</span>
		<span v-if="item.pricingOptionIsGlobal">Global PricingOption</span>
	</div>
</section>
</template>


<script>
import ProductsAPI from '@/api/ProductsAPI.js'
import Product from '@/models/Product.js'
import PricingOption from '@/models/PricingOption.js'

export default {
	name: 'LineItemDetails',


	props: {
		item: Object,
		allowEdit: {
			type: Boolean,
			default: true
		},
		allowDelete: {
			type: Boolean,
			default: true
		},
		hasContractExpirationDate: {
			type: Boolean,
			default: false
		}
	},


	data() {
		return {
			editMode: false,
			showAddLicenseKey: false,
			showAddNewLicenseKey: false,

			selectedProductSKU: null,
			selectedPricingOptionID: null,

			originalSKU: null,
			originalPOID: null,
			originalLicenseExpirationDate: null,

			originalProduct: null,
			originalPO: null,
			originalPrice: null,
			originalSubscriptionPrice: null,
			originalGlobalProduct: null,
			originalGlobalPO: null,
		}
	},



	created() { this.initialize() },



	computed: {
		products() { return this.$store.state.Products },
		pricingOptions() {
			var prod = this.$store.state.Products.find(p => p.sku == this.selectedProductSKU)
			return prod && prod.pricingOptions ? prod.pricingOptions : []
		},

		expirationDateString: {
			get() { return this.item.licenseExpirationDateString },
			set(value) {
				if(value) value = new Date(value);
				else this.item.licenseExpirationDate = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.item.licenseExpirationDate = value;
			}
		},

	},



	watch: {
		item() { this.initialize() },
		selectedProductSKU(value) { this.initializeProduct() },
		selectedPricingOptionID(value) { this.initializePricingOption() },

		async editMode(value) {
			if(!value) return
			if( !this.$store.state.Products.length ) this.$store.state.Products.objects = await ProductsAPI.getProducts()
		},

		showAddLicenseKey(value) { console.debug(`showAddLicenseKey: ${value}`) },
	},



	methods: {
		initialize() {
			this.selectedProductSKU = this.item.sku
			this.selectedPricingOptionID = this.item.pricingOptionID

			this.originalSKU = this.item.sku
			this.originalPOID = this.item.pricingOptionID
			this.originalProduct = this.item.product
			this.originalPO = this.item.pricingOption
			this.originalPrice = this.item.price
			this.originalSubscriptionPrice = this.item.subscriptionPrice
			this.originalLicenseExpirationDate = this.item.licenseExpirationDate

			this.originalFulfill = this.item.fulfill
			this.originalRenewOnAnyContract = this.item.renewOnAnyContract

			this.originalGlobalProduct = this.item.productIsGlobal
			this.originalGlobalPO = this.item.pricingOptionIsGlobal
		},


		initializeProduct() {
			if(!this.selectedProductSKU || this.selectedProductSKU === this.item.sku) return
			console.debug("INIT PRODUCT!")

			// find product
			var product = this.$store.state.Products.find( prod => prod.sku == this.selectedProductSKU)

			// Make copy
			product = Product.import( product.export() )

			// update order
			this.item.sku = product.sku
			this.item.product = product

			// If user changed the product, clear out poID & emit
			this.selectedPricingOptionID = null
			this.item.pricingOptionID = null
			this.item.pricingOption = null
			this.$emit('update')
		},


		initializePricingOption() {
			if(!this.selectedPricingOptionID || this.selectedPricingOptionID === this.item.pricingOptionID) return
			console.debug("INIT PRICING OPTION!")

			// find PO
			var pricingOption = this.pricingOptions.find( po => po.id == this.selectedPricingOptionID )

			// Make copy
			pricingOption = PricingOption.import( pricingOption.export() )

			// update order
			this.item.pricingOptionID = pricingOption.id
			this.item.pricingOption = pricingOption

			// If user changed the PO, updated prices & emit
			this.item.price = pricingOption.initialPrice
			this.item.subscriptionPrice = pricingOption.subscriptionPrice
			this.$emit('update')
		},



		// Bring back the original configuration of the LineItem (original prices,
		// original product & PO objects). This makes it possible to "undo" changes
		// to an order that has a product/PO that is both embedded AND different
		// from the current version in the Products table
		revert() {
			var prod = this.originalProduct
			this.item.product = this.originalProduct
			this.selectedProductSKU = this.originalProduct ? this.originalProduct.sku : null


			var po = this.originalPO
			this.item.pricingOption = po || null
			this.item.pricingOptionID = this.originalPOID // MUST be AFTER setting pricingOption
			this.item.price = this.originalPrice
			this.item.subscriptionPrice = this.originalSubscriptionPrice
			this.item.sku = this.originalSKU
			this.item.licenseExpirationDate = this.originalLicenseExpirationDate

			this.item.fulfill = this.originalFulfill
			this.item.renewOnAnyContract = this.originalRenewOnAnyContract

			this.item.productIsGlobal = this.originalGlobalProduct
			this.item.pricingOptionIsGlobal = this.originalGlobalPO

			this.selectedPricingOptionID = po ? po.id : null


			this.editMode = false
			this.$emit('update')
		},



		edit() { this.editMode = true },


		addLicenseKey() {
			if(!this.$refs.addLicenseKey.value) return

			this.item.licenseKeys.push(this.$refs.addLicenseKey.value)
			this.$refs.addLicenseKey.value = ''
			this.showAddLicenseKey = false
		},
		removeLicenseKey(index) {
			this.item.licenseKeys.splice(index, 1)
		},

		addNewLicenseKey() {
			if(!this.$refs.addNewLicenseKey.value) return

			this.item.newLicenseKeys.push(this.$refs.addNewLicenseKey.value)
			this.$refs.addNewLicenseKey.value = ''
			this.showAddNewLicenseKey = false
		},
		removeNewLicenseKey(index) {
			this.item.newLicenseKeys.splice(index, 1)
		},


	}


}
</script>


<style scoped>
.error {
	background-color: #ffe9e9;
	color: red;
	font-weight: bold;
}

.lineItem {
	background: #fff;
	border: 1px solid var(--ekno-blue);
	border-left: 0.75em solid var(--ekno-blue);
	border-radius: 0.5em;
	padding: 1em;
	margin-bottom: 1em;
}
input {
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 0.25em;
	padding: 0.25em 0.5em;
}

.coupon {
	background: #ecdcff;
	color: #472274;
	border-radius: 0.5em;
	padding: 0.25em 0.5em;
}
</style>
