<template>
<section class="center">

	<ApexChartGraph
		v-if="percentGraph"
		:graph="percentGraph"
		:type="'line'"
		:dataLabels="false"
		height="300px"
	/>
	<div v-else-if="loadingPercentGraph" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
	<div v-else-if="percentGraphError" class="warning">Failed to get %-to-target data</div>
	<div v-else class="NoResults">No %-to-target data</div>



	<ApexChartGraph
		v-if="scoreGraph"
		:graph="scoreGraph"
		:type="'line'"
		:dataLabels="false"
		height="300px"
	/>
	<div v-else-if="loadingScoreGraph" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
	<div v-else-if="scoreGraphError" class="warning">Failed to get score projections</div>
	<div v-else class="NoResults">No score projections</div>



</section>
</template>



<script>
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import StudentStatisticsGraphRequest from './StudentStatisticsGraphRequest.js'

import ApexChartGraph from '@/components/ApexChartGraph.vue'

export default {
	name: 'QuestionDetails',


	components: {
		ApexChartGraph,
	},


	props: {
		userID: Number,
		licenseKey: String,
		startDate: {
			type: Date,
			default: null
		},
		endDate: {
			type: Date,
			default: null
		},
	},


	data() {
		return {
			percentGraph: null,
			loadingPercentGraph: false,
			percentGraphError: false,

			scoreGraph: null,
			loadingScoreGraph: false,
      scoreGraphError: false,
		}
	},


	created() { this.initialize() },


	watch: {
		startDate() { this.initialize() },
		endDate() { this.initialize() },
	},


	methods: {
		initialize() {
			this.getPercentGraph()
			this.getScoreGraph()
		},


		async getPercentGraph() {
			
			this.percentGraph = null
			this.loadingPercentGraph = true
			this.percentGraphError = false

			try {
				const graphReq = new StudentStatisticsGraphRequest( this.userID, this.licenseKey, this.startDate, this.endDate )
				const graphRes = await StudentStatisticsAPI.getPercentTargetGraph( graphReq ).catch( e => {
					console.debug("ERROR")
					throw e;
				})
				this.percentGraph = graphRes.data
	
				if( !this.percentGraph.subtitle ) {
					const graphStart = new Date( this.percentGraph.start * 1000 )
					const startString = graphStart.toLocaleDateString()
					
					const graphEnd = new Date( this.percentGraph.end * 1000 )
					const endString = graphEnd.toLocaleDateString()

					this.percentGraph.subtitle = `${startString} - ${endString}, by ${this.percentGraph.xUnit}`
				}
		
				if( !this.percentGraph.xLabels.length && this.percentGraph.xUnit ) {
					for( var i=0; i < this.percentGraph.xValues.length; i++ ) {
						const currTS = this.percentGraph.xValues[i] * 1000
	
						const point = new Date( currTS )
	
						const midnightAligned = point.getHours() == 0 && point.getMinutes() == 0 && point.getSeconds() == 0 ? true : false
						const label = midnightAligned ? 'start of '+point.toLocaleDateString() : point.toLocaleString()
						this.percentGraph.xLabels.push( label )
					}
				}

			} catch(e) {
				this.percentGraphError = true
				console.debug(JSON.parse(JSON.stringify(e)))
				throw e

			} finally {
				this.loadingPercentGraph = false
			}

		},


		async getScoreGraph() {

			this.scoreGraph = null
			this.loadingScoreGraph = true
			this.scoreGraphError = false
			
			try {
				const graphReq = new StudentStatisticsGraphRequest( this.userID, this.licenseKey, this.startDate, this.endDate )
				const graphRes = await StudentStatisticsAPI.getPredictedScoreGraph( graphReq ).catch( e => {
					console.debug("ERROR")
					throw e;
				})
				this.scoreGraph = graphRes.data
	
				if( !this.scoreGraph.subtitle ) {
					const graphStart = new Date( this.scoreGraph.start * 1000 )
					const startString = graphStart.toLocaleDateString()
					
					const graphEnd = new Date( this.scoreGraph.end * 1000 )
					const endString = graphEnd.toLocaleDateString()

					this.scoreGraph.subtitle = `${startString} - ${endString}, by ${this.scoreGraph.xUnit}`
				}
		
				if( !this.scoreGraph.xLabels.length && this.scoreGraph.xUnit ) {
					for( var i=0; i < this.scoreGraph.xValues.length; i++ ) {
						const currTS = this.scoreGraph.xValues[i] * 1000
	
						const point = new Date( currTS )
	
						const midnightAligned = point.getHours() == 0 && point.getMinutes() == 0 && point.getSeconds() == 0 ? true : false
						const label = midnightAligned ? 'start of '+point.toLocaleDateString() : point.toLocaleString()
						this.scoreGraph.xLabels.push( label )
					}
				}

			} catch(e) {
				this.scoreGraphError = true
				console.debug(JSON.parse(JSON.stringify(e)))
				throw e

			} finally {
				this.loadingScoreGraph = false
			}

		},


	}

}
</script>



<style scoped>

</style>