import ThresholdReportThreshold from './ThresholdReportThreshold.js'
import Exim from '@/models/Exim.js'


class ThresholdReportRange extends Exim {


	_dimension = null   // Vue use only (not exported)
	dimensionID = null

	_variableName = null
	thresholds = []

	label = null
	description = null

	showStudentValue = true

	unit = null
	shortUnit = null

	// Student values
	values = null    // Map { int|string aggregateID => int|float value }



	constructor() {
		super()
		this.values = new Map()
	}


	
	get dimension() { return this._dimension }

	set dimension( dim ) {
		this._dimension = dim

		this._variableName = null

		if( dim ) {
			this.dimensionID = dim.id
		} else {
			this.dimensionID = null
		}

		this.setUnit()
	}


	get variableName() { return this._variableName }
	set variableName( value ) {
		this._variableName = value
		this.setUnit()
	}


	addThreshold() { this.thresholds.push( new ThresholdReportThreshold() ) }
	deleteThreshold( index ) { this.thresholds.splice( index, 1 ) }
	
	

	setUnit() {
		if( !this.dimension || !this.variableName ) {
			this.unit = null
			this.shortUnit = null
			return
		}


		const ovar = this.dimension.getOutputVariable( this.variableName )

		if( !ovar ) return

		this.unit = ovar.unit
		this.shortUnit = ovar.shortUnit
	}



	hasValue( aggregateID ) { return this.values.has( aggregateID ) }
	getValue( aggregateID ) { return this.values.get( aggregateID ) }



	updateOutputVariableName( dimension, newName, oldName ) {
		if( this.dimensionID !== dimension.id ) return
		if( this.variableName !== oldName ) return
		this.variableName = newName
	}





	static import( obj ) {
		var item = super.import( obj, [ '_dimension', '_variableName', 'variableName', 'thresholds', 'values' ] )
		
		if( obj.variableName !== undefined ) item._variableName = obj.variableName

		for( var t of obj.thresholds ) {
			var threshold = ThresholdReportThreshold.import( t )
			item.thresholds.push( threshold )
		}

		for( let name in obj.values ) item.values.set( name, obj.values[ name ] )
		
		return item
	}


	export() {
		var data = super.export( [ '_dimension', '_variableName', 'thresholds', 'values' ] )

		data.variableName = this._variableName

		data.thresholds = []
		for( var t of this.thresholds ) data.thresholds.push( t.export() )

		return data
	}


}

export default ThresholdReportRange