<template>
  <section class="AdminExpander" :class="{'Expanded': Expanded}">

    <div id="header" @click="toggle" :class="{'Modified': modified}">
      <span class="leftIcon" :class="{'icon-minus': Expanded, 'icon-plus': !Expanded, 'Modified':modified}"></span>
      {{title}}
      <span v-if="!Expanded"> ...</span>
    </div>

    <div id="body">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ExpandBox',
  props: {
    title: String,
    modified: Boolean,
    list: {
      type: [Object, null],
      default: null
    },
    mode: {
      type: String,
      default: "independent" // or "accordion"
    }
  },

  created(){
    this.Expanded = (this.expanderList[this.title]) ? true : false;
  },

  computed: {
    expanderList() {
      return this.list || this.$store.state.ExpandBoxes;
    },
    Expanded: {
      get() { return this.expanderList[this.title] || false },
      set(value) { this.$set(this.expanderList, this.title, value) }
    }
  },

  methods: {
    toggle(){
      this.Expanded = !this.Expanded;
      this.expanderList[this.title] = this.Expanded;

      if( !this.Expanded || this.mode != 'accordion') return;

      for(let title in this.expanderList) {
        if(title == this.title) continue;
        this.$set(this.expanderList, title, false)
      }
    },
  }
}
</script>

<style scoped>
/* ********************************************
 * Spin-down box used on the Admin Node view,
 * for items such as Taxonomies and Prerequisites
******************************************** */
.AdminExpander {
  margin: 1em 0;
  padding: 0;
  background-color: white;
  border-radius: 0.5em;
  border: 1px #bbb solid;
}
.AdminExpander #header {
  background-color: #ddd;
  font-size: 1.2em;
  padding: 0.2em 0.5em;
  cursor: pointer;
}
.AdminExpander #header .leftIcon {
  /* float:right; */
  color: #555;
  padding-right: 0.5em;
}
.AdminExpander #body {
  /* padding: 1em; */
}

.Modified {
  background-color: #0A0 !important;
  color: white !important;
}

.AdminExpander #body {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  transition: max-height 0.25s, padding 0.5s;
}

.AdminExpander.Expanded #body {
  max-height: 1000px;
  overflow: auto;
  padding: 1em;
  transition: max-height 0.25s, padding 0.5s;
  min-height: 200px;
}
</style>
