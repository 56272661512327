<template>
<div>

  <div class="flex-row flex-align-center flex-justify-end py-05">
    <button class='button' @click='newFeature()'>New Feature</button>
  </div>
  
  <ObjectTable
    :Source='features'
    :Columns='columns'
    :SortBy='sortBy'
    :SortAsc='sortAsc'
    Deletable
    @edit='editFeature'
    @delete='deleteFeature'
    @sort='selectSortBy'
    ref="objectTable"
  />

  <StretchModal ref="featureModal" @close="$refs.objectTable.deselect()">
    <div class="section-header pa-05"><h1>Feature Details</h1></div>
    <FeatureDetails :Feature="currentFeature" @added='selectFeature' class="ma-1" />
  </StretchModal>

  <ConfirmDialog :show="featureToDelete !== null" @cancel="featureToDelete = null" @confirm="confirmDeleteFeature()">
		<div>Are you sure you want to delete this feature?</div>
		<div style="font-size: 1.25em; font-weight: bold; margin: 0.25em 0;">{{ featureToDelete ? featureToDelete.name : '' }}</div>
		<div><strong>This action CANNOT be undone!</strong></div>
	</ConfirmDialog>
</div>
</template>

<script>
import ObjectTable from '@/components/utilities/ObjectTable'
import Feature from '@/models/Feature.js'
import FeatureDetails from './FeatureDetails'
import RolesAPI from '@/api/RolesAPI'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

export default {
  name: 'feature-list',

  data() {
    return {
      columns: [
        {
          displayName: 'ID',
          propertyName: 'id',
          sortable: true
        },
        {
          displayName: 'Name',
          propertyName: 'name',
          sortable: true
        }
      ],
      currentFeature: null,
      featureToDelete: null,
      sortBy: '',
      sortAsc: true
    }
  },

  async created() {
    this.getFeatures()
  },

  computed: {
    features() {
      const featSet = this.$store.state.Features
      featSet.sort( (a,b) => a.name.localeCompare(b.name))
      return featSet.objects
    }
  },

  methods: {
    selectFeature(feature) {
      this.currentFeature = feature;
      this.$refs.featureModal.close()
    },

    newFeature() {
      this.currentFeature = new Feature();
      this.$refs.featureModal.open()
    },

    editFeature(feature) {
      this.currentFeature = feature;
      this.$refs.featureModal.open()
    },

    deleteFeature(feature) {
      this.featureToDelete = feature
    },

    async confirmDeleteFeature() {
      const feature = this.featureToDelete
      try {
        await RolesAPI.deleteFeature(feature.id)
        this.$store.state.Features.remove(feature)
      } catch (e) {
        console.error(e)
        alert("Error communicating with server; changes not saved.")
      }
      this.featureToDelete = null
    },

    async getFeatures() {
      this.$store.state.Features.objects = await RolesAPI.getFeatures( null, { sortBy: this.sortBy, ascendingOrder: this.sortAsc } )
    },

    selectSortBy(prop) {
      console.log(prop);
      if (prop == this.sortBy) {
        this.sortAsc = !this.sortAsc;
        console.log(this.sortAsc)
      } else {
        this.sortBy = prop;
        this.sortAsc = true;
      }
      this.getFeatures();
    }
  },

  components: {
    ObjectTable,
    FeatureDetails,
    StretchModal,
    ConfirmDialog,
  }
}
</script>
