<template>
<section>
	<div class="flex-row flex-align-center flex-gap-2">
		<div v-if="line.trackingID">Tracking ID: {{ line.trackingID }}</div>
		<div v-if="line.userName">{{ line.userID }}: {{ line.userName }}</div>
		<div v-else>User ID: {{ line.userID }}</div>
		<div>Lic #: {{ line.licenseKey }}</div>
		<div v-if="line.purchaseDate">Purchase Date: {{ purchaseDate }}</div>
		<div v-if="line.planCreated">Plan Created: {{ planCreated }}</div>
		<div>Course: {{ line.courseName }}</div>
		<div v-if="line.startNodes">
			Start Nodes: {{ line.startNodes.length }}
			<button v-if="line.startNodes.length" @click="showStartNodes = !showStartNodes"><span class="icon-plus" /></button>
		</div>
		<div class="nowrap" :class="{'bold' : line.duplicates.length }">
			Dupes: {{ line.duplicates instanceof Array ? line.duplicates.length : line.duplicates }}
			<button v-if="line.duplicates.length" @click="showDuplicates = !showDuplicates"><span class="icon-plus" /></button>
		</div>
		<div class="nowrap" :class="{'bold' : line.errors.length }">
			Errors: {{ totalErrorCount }}
			<button v-if="line.errors.length" @click="showErrors = !showErrors"><span class="icon-plus" /></button>
		</div>
		<div v-if="line.planID" class="ml-1">
			<button @click="regenPlan(line.planID)" :disabled="regenMarked" class="pillButton secondary bg" :class="{'disabled': regenMarked}">Regen on load</button>
		</div>
	</div>
	<div class="ml-2 mt-05">
		<div v-if="line.startNodes instanceof Array && showStartNodes">
			<div v-for="node in line.startNodes" :key="node.id">{{ node.id }}: {{ node.name }}</div>	
		</div>
	</div>
	<div class="ml-2 mt-05">
		<div v-if="line.duplicates instanceof Array && showDuplicates">
			<div v-for="text in line.duplicates" v-html="text" :key="text" />
		</div>
	</div>
	<div class="ml-2 mt-05">
		<div v-if="showErrors">
			<div v-for="obj in line.errors" :key="obj.message">
				<div v-if="obj.message"><div>Message: {{ obj.message }}</div></div>
				<div v-if="obj.nodeIDs"><div>NodeIDs: {{ obj.nodeIDs }}</div></div>
				<div v-if="obj.lines">
					<div>Lines:</div>
					<div v-for="line in obj.lines" :key="line" v-html="line" class="ml-1" />
				</div>
			</div>
		</div>
	</div>
</section>	
</template>



<script>
import SystemToolsAPI from '@/api/SystemToolsAPI.js'

export default {
	
	name: 'TrackingValidationReport',


	props: {
		line: Object
	},



	data() {
		return {
			showStartNodes: false,
			showDuplicates: false,
			showErrors: false,

			regenMarked: false,
			regenUpdateCounter: 1,
		}
	},



	computed: {
		totalErrorCount() {
			if( !this.line || !this.line.errors || this.line.errors.length === 0 ) return 0

			var count = 0
			for( var err of this.line.errors ) {
				if( err.count !== undefined ) count += err.count
				else count++
			}

			return count
		},


		purchaseDate() {
			if( !this.line || !this.line.purchaseDate ) return null
			return new Date( this.line.purchaseDate * 1000 ).toLocaleDateString()
		},

		planCreated() {
			if( !this.line || !this.line.planCreated ) return null
			return new Date( this.line.planCreated * 1000 ).toLocaleDateString()
		},

		regenPlanList() {
			return this.regenUpdateCounter && Array.from( this.regenPlanIDs )
		}

	},


	methods: {
		async regenPlan(planID) {
			if( this.regenMarked ) return

			await SystemToolsAPI.setPlanRegenFlag( planID, true )
			this.regenMarked = true
			this.regenUpdateCounter++
    },
	}



}
</script>



<style scoped>
.disabled {
	opacity: 0.5;
}
</style>