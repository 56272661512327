import Exim from '@/models/Exim.js'

class UserTime extends Exim {

	
	userID = null
	firstName = null
	lastName = null
	email = null

	totalSeconds = null
	averageSessionSeconds = null
	sessionCount = null
	licenseCount = null
	reviewStars = []


	get totalTimeString() {
		return this._getTimeString( this.totalSeconds )
	}

	get sessionCountString() {
		return this.sessionCount !== null ? this.sessionCount.toLocaleString() : ''
	}

	get licenseCountString() {
		return this.licenseCount !== null ? this.licenseCount.toLocaleString() : ''
	}

	get averageSessionTimeString() {
		return this._getTimeString( this.averageSessionSeconds )
	}



	_getTimeString( seconds ) {
		if( seconds >= 3600 ) return (Math.round( (seconds / 360 ) ) / 10).toLocaleString() + ' hrs'
		else return Math.round( (seconds / 60 ) ).toLocaleString() + ' mins'
	}

	
}
export default UserTime