import Exim from '@/models/Exim.js'



class Discount extends Exim {

	couponCode = null
	type = null
	amount = null

	sku = null
	pricingOptionID = null
	isSponsored = false
	

	// For use AFTER order (i.e. OrderDetails)
	actualDiscount = null  // discount given, as recorded in DB. Already multiplied by quantity.

}

export default Discount