import ReportTemplate from './ReportTemplate.js'
import ThresholdReportRange from './ThresholdReportRange.js'



class ThresholdReportTemplate extends ReportTemplate {


	// Override
	type = 'threshold'

	// Template definitions
	ranges = null    // Map { rangeName => Range, ... }


	constructor() {
		super()
		this.ranges = new Map()
	}



	addRange( name ) {
		const newRange = new ThresholdReportRange()
		this.ranges.set( name, newRange )
		return newRange
	}

	renameRange( newName, oldName ) {
		const newRanges = new Map()
		for( var [ name, range ] of this.ranges.entries() ) {
			if( name === oldName ) newRanges.set( newName, range )
			else newRanges.set( name, range )
		}

		this.ranges = newRanges
	}

	deleteRange( name ) { this.ranges.delete( name ) }



	updateOutputVariableName( dimension, newName, oldName ) {
		for( var range of this.ranges.values() ) range.updateOutputVariableName( dimension, newName, oldName )
	}



	initialize( dimensionArray ) {
		const dimMap = new Map()
		for( var dim of dimensionArray ) dimMap.set( dim.id, dim )

		for( var range of this.ranges.values() ) {
			if( !range.dimensionID ) continue
			range._dimension = dimMap.get( range.dimensionID )
			range.setUnit()
		}
	}



	/**
	 * Gets all userIDs/licenseKeys/cohortIDs
	 * (whichever was used as the "aggregate key")
	 * that are present anywhere in any ThresholdRange value.
	 */
	getAllAggregateIDs() {
		var keySet = new Set()
		
		for( var range of this.ranges.values() ) {
			for( var aggID of range.values.keys() ) {
				keySet.add( aggID )
			}
		}
		
		var keys = []
		for( var key of keySet ) keys.push( key )

		return keys
	}



	static import( obj ) {
		var item = super.import( obj, [ 'ranges' ] )
		for( let name in obj.ranges ) item.ranges.set( name, ThresholdReportRange.import( obj.ranges[ name ] ) )

		return item
	}



	export() {
		const data = super.export( [ 'ranges' ] )
		
		data.ranges = {}
		for( var [name, item] of this.ranges.entries() ) {
			data.ranges[ name ] = item.export()
		}
		return data
	}


}


export default ThresholdReportTemplate