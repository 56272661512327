<template>
  <div class='upgrade-card'>
    <div class="pa-1">
      <div style="font-weight: bold;">{{ product.name }}</div>
      <div>({{ product.sku }})</div>
    </div>
    <!-- <p>{{ product.description }}</p> -->
    <button @click='$emit("remove")' class='button' style="width: 100%; border-top-left-radius: 0; border-top-right-radius: 0;">Remove Upgrade</button>
  </div>
</template>

<script>
export default {
  name: 'UpgradeDisplay',

  props: {
    sku: String
  },

  computed:{
    product() {
      return this.$store.state.Products.findByAttribute('sku', this.sku);
    }
  }
}
</script>

<style scoped>
.upgrade-card {
  border: 1px solid #aaa;
  box-shadow: 0 0 10px #ccc;
  border-radius: 5px;
}
</style>
