<template>
  <div>
    <table class="table font-small">
      <tr>
        <th class="table-header">Unread</th>
        <th class='table-header'>Title</th>
        <th class='table-header'>State</th>
        <th class='table-header'>Preview</th>
        <th class='table-header'>Type</th>
        <th class='table-header'>Importance</th>
        <th class='table-header'>Time</th>
      </tr>

      <tr v-for='(fb, index) in feedbackList' :key='index'
        class="table-row clickable"
        @click='showFeedbackDetails(fb)'
      >
        <td class="table-data">
          <div v-if="fb.state !== 'closed'" class="unread-indicator" :class='{"unread-active": fb.unread}' @click.stop="toggleRead(fb)" />
          <div v-else class="icon-checkmark color-green center" />
        </td>

        <td class='table-data'>{{ fb.title }}</td>
        <td class='table-data'>{{ fb.state }}</td>
        <td class='table-data'>{{ fb.preview }}</td>
        <td class='table-data'>{{ fb.type }}</td>
        <td class='table-data'>{{ fb.importance }}</td>
        <td class='table-data'>{{ fb.timestamp }}</td>

        <td><i class='icon-bin list-button' @click.stop='deleteFeedback(fb)'></i></td>
      </tr>
    </table>

    <StretchModal ref="detailsModal" :clickToClose="false" padding="1em">
      <template #header>Bug Report #{{ currentFBObj.id }} &mdash; &ldquo;{{ currentFBObj.title }}&rdquo;</template>
      <FeedbackDetails :FeedbackObj="currentFBObj" />
    </StretchModal>

    <ConfirmDialog :show="itemToDelete !== null" @cancel="itemToDelete = null" @confirm="confirmDelete()">
      <div>Are you sure you want to delete this Bug Report?</div>
      <div style="font-size: 1.25em; font-weight: bold; margin: 0.25em 0;">{{ itemToDelete ? itemToDelete.title : '' }}</div>
      <div><strong>This action CANNOT be undone!</strong></div>
    </ConfirmDialog>
  </div>
</template>


<script>
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import FeedbackDetails from './FeedbackDetails.vue'

import FeedbackAPI from '@/api/FeedbackAPI.js'
import OrderedSet from '@/libraries/OrderedSet.js'

export default {
  name: 'FeedbackList',

  components: {
    StretchModal,
    ConfirmDialog,
    FeedbackDetails,
  },

  data() {
    return {
      feedbackList: new OrderedSet(),
      currentFBObj: null,
      itemToDelete: null,
    }
  },


  async created() {
    this.initialize()
  },


  methods: {

    async initialize() {
      try {
        this.feedbackList.objects = await FeedbackAPI.getFeedback()

      } catch (e) {
        console.error(e);
        alert( "An error occurred while loading bug reports (feedback)" )
      }
    },
    

    async showFeedbackDetails(fbObj) {
      this.markRead(fbObj);

      this.currentFBObj = fbObj;
      this.$refs.detailsModal.open()
    },


    toggleRead(feedback) {
      if(feedback.unread) this.markRead(feedback)
      else this.markUnread(feedback)
    },


    async markRead(feedback) {
      if (feedback.unread) {
        await FeedbackAPI.readFeedback(feedback.id);
        feedback.unread = false;
      }
    },


    async markUnread(feedback) {
      if (!feedback.unread) {
        await FeedbackAPI.unreadFeedback(feedback.id);
        feedback.unread = true;
      }
    },


    deleteFeedback(feedback) { this.itemToDelete = feedback },

    async confirmDelete() {
      const feedback = this.itemToDelete
      await FeedbackAPI.deleteFeedback(feedback.id);
      this.feedbackList.remove( feedback )
      this.itemToDelete = null
    },
  }
}
</script>


<style scoped>
.unread-indicator {
  height: 1em;
  width: 1em;
  border-radius: 50%;
  background-color: lightgray;
  margin: auto;
}
.unread-indicator:hover {
  background-color: pink;
}

.unread-indicator.unread-active {
  background-color: var(--ekno-blue);
}
.unread-indicator.closed {
  background-color: var(--pp10-green);
}
</style>
