<template>
<section>

	<section class="flex-row flex-justify-between flex-gap-2 my-05">
		<SearchBox @search="str => searchString = str" />

		<div class="flex-row flex-align-center flex-justify-end flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} entries on {{ pages }} page{{ pages == 1 ? '' : 's' }}</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>

			<div class="center">
				<button class="button" @click="createReview()">New Educator Review</button>
				<div><a href="https://new.eknowledge.com/home/educators-love-us/" target="_blank" class="one-line">comments page</a></div>
			</div>

		</div>
	</section>

	<h2 class="mt-2 mb-0 center">Educator Reviews are manually ordered</h2>
	<p class="mt-05 mb-2 center">Drag &amp; drop rows to re-order reviews in the "Educator Reviews" slider on v10 landing pages</p>

	<section v-if="!loading && !error && reviews.length">
    <ObjectTable
      :Source="reviews"
			SortBy="superIndex"
      :Columns='columns'
      :Numbered="true"
			:draggable="true"
      :PageNum='page'
      :PageSize='pageSize'
			:StyleFunction='styleFunc'
			:Deletable="true"
      @edit="item => select(item)"
			@reorder="( oldIndex, newIndex ) => reorder( oldIndex, newIndex )"
			@delete="item => toDelete = item"
      ref="objectTable"
    />
    <Paginator v-model="page" @input="initialize()" :numPages="pages" />
  </section>


  <div v-else-if="!loading && !error && !reviews.length" class="NoResults">No Reviews</div>

  <section v-else-if='loading && !error' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

  <section v-else-if="error" class="warning">
		Failed to get records
	</section>

  <StretchModal ref="detailsModal" @close="deselect()" width="800px" padding="2em">
    <template #header>Educator Review #{{ selectedItem.reviewID }}</template>
    <EducatorReviewDetails :review="selectedItem" @create="reviewCreated()" />
  </StretchModal>

	<ConfirmDialog :show="toDelete !== null" @cancel="cancelDelete()" @confirm="deleteReview()">
		<div>Are you sure you want to delete this review by</div>
		<div style="font-size: 1.25em; margin: 0.25em 0;"><b>{{ toDelete ? toDelete.authorName : '' }}</b>?</div>
		<div><strong>This action CANNOT be undone!</strong></div>
	</ConfirmDialog>

</section>
</template>



<script>
import EducatorReviewDetails from './EducatorReviewDetails.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

import ReviewsAPI from '@/api/ReviewsAPI.js'
import Review from '@/features/Reviews/Review.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'

export default {
	name: 'EducatorReviewsDashboard',


	components: {
		EducatorReviewDetails,
		ObjectTable,
		Paginator,
		SearchBox,
		StretchModal,
		ConfirmDialog,
	},


	data() {
		return {
			reviews: [],
			count: 0,
			page: 1,
			pages: 1,
			pageSize: 100,

			searchString: null,
			
			loading: false,
			error: false,

			selectedItem: null,
			toDelete: null,
			
			columns: [
				{
					displayName: "Author",
					propertyName: 'authorName',
					displayFunction: function( item ) {
						return `<div>${item.userID !== null ? '<span class="icon-user mr-05 color-blue-60"></span>' : ''}${item.authorName} ${item.userID ? '(#' + item.userID + ')' : ''}</div><div class="color-gray-50">${item.authorEmail || ''}</div>`
					},
					sortable: false
				},
				{
					displayName: 'Stars',
					propertyName: 'stars',
					displayFunction: function( item ) {
						const prompt = item.starPrompt && item.starPrompt.length > 20 ? item.starPrompt.substring(0,15) + ' ...' : item.starPrompt
						var str = `<div style="font-style: italic; font-size: 0.9em; max-width: 8em;">${prompt || ''}</div>`
						for(var i=0; i < item.stars; i++) str += '<span class="icon-star-full" style="color: orange"></span>'
						return str
					},
					itemTooltip: function( item ) { return item.starPrompt && item.starPrompt.length > 20 ? item.starPrompt : null },
					sortable: false
				},
				{
					displayName: 'Body',
					propertyName: 'body',
					displayFunction: function( item ) {
						var str = item.bodyPrompt ? `<div style="font-style: italic; font-size: 0.9em;">PROMPT: ${item.bodyPrompt || ''}</div>` : ''
						if( item.title ) str = '<div style="font-weight:bold;">' + item.title + '</div>'
						str += '<div style="color:#888;">' + (item.body.length > 45 ? item.body.substring(0,40) + ' ...' : item.body) + '</div>'
						return str
					},
					itemTooltip: function( item ) { return item.body && item.body.length > 45 ? item.body : null },
					sortable: false
				},
				// {
				// 	displayName: 'Aff',
				// 	propertyName: 'affiliates',
				// 	displayFunction: function( item ) {
				// 		const affNames = item.getAffiliateNames( 15 )
				// 		return '<div>' + affNames.join( ',</div><div>' ) + '</div>'
				// 	},
				// 	itemTooltip: function( item ) {
				// 		return item.getAffiliateNames().join( ",\n" )
				// 	},
				// 	sortable: false
				// },
				// {
				// 	displayName: 'Cust',
				// 	propertyName: 'customerName',
				// 	displayFunction: function( item ) {
				// 		if( !item.customerName ) return item.customerID
				// 		return item.customerName.length > 15 ? item.customerName.substring(0,10) + ' ...' : item.customerName
				// 	},
				// 	itemTooltip: function( item ) { return item.customerName && item.customerName.length > 15 ? item.customerName : null },
				// 	sortable: false
				// },
				{
					displayName: 'Published',
					propertyName: 'isPublished',
					displayFunction: function( item ) { return item.isPublished ? '<span class="icon-checkmark message-success" />' : '' },
					sortable: false
				},
			]
		}
	},



	watch: {
		pageSize() { this.page = 1; this.initialize() },
		searchString() { this.page = 1; this.initialize() },
	},



	created() { this.initialize() },



	methods: {
		async initialize() {

			console.debug("Initializing")

			try {
				this.reviews = []
				this.loading = true
				this.error = false

				const reqBody = new PaginatedRequest( null, true, this.page, this.pageSize, this.searchString )
				const pr = await ReviewsAPI.getEducatorReviews( reqBody )

				for( let review of pr.data ) this.reviews.push( Review.import(review) )
				this.pages = pr.pages
				this.count = pr.count

			} catch( e ) {
				this.reviews = []

				this.pages = 1
				this.count = 0
				this.error = true
				console.error( e )

			} finally {
				this.loading = false
			}
		},


		createReview() {
			this.selectedItem = new Review()
			this.selectedItem.isPublished = false
			this.$refs.detailsModal.open()
		},


		reviewCreated() {
			this.reviews.push( this.selectedItem )
			this.selectedItem = null
			this.$refs.detailsModal.close()
		},


		cancelDelete() {
			this.toDelete = null
			this.$refs.objectTable.deselect()
		},


		async deleteReview() {
			if( this.toDelete == null ) return

			if( this.toDelete.reviewID ) await ReviewsAPI.deleteEducatorReview( this.toDelete.reviewID )
			
			const idx = this.reviews.indexOf( this.toDelete )
			if( idx > -1 ) this.reviews.splice( idx, 1 )

			this.toDelete = null
		},


		select( rvw ) {
      this.selectedItem = rvw
      this.$refs.detailsModal.open()
    },

    deselect() {
      this.selectedItem = null
      this.$refs.objectTable.deselect()
    },


		async reorder( oldIndex, newIndex ) {

			const target = this.reviews[ newIndex ]
			const aboveTarget = this.reviews[ newIndex - 1 >= 0 ? newIndex - 1 : newIndex ]
			if( target.superIndex === null && aboveTarget.superIndex === null && newIndex > 0 ) return
			
			const rvw = this.reviews[ oldIndex ]
			await ReviewsAPI.reorderEducatorReview( rvw.reviewID, newIndex )

			// Remove from old position
			this.reviews.splice( oldIndex, 1 )

			// Add to new position
			this.reviews.splice( newIndex, 0, rvw )

			rvw.superIndex = newIndex
		},


		styleFunc( review ) {
			if( review.superIndex === null ) return { backgroundColor: 'var(--pp10-gray-70)' }
			else return null
		}

	}
}
</script>



<style scoped>

</style>