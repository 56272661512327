<template>
<section>
	<StretchModal :showModal="show" @close="$emit('close')" width="auto">
		<template #header>Push Report Delivery Settings</template>
		
		<section v-if="show && loaded && settings.length" class="pa-1 flex-row flex-gap-1 flex-wrap">
			<div v-for="(item, index) in settings" :key="item.userID" class="border round-05 pa-05 ma-05">
				<div class="mb-1 flex-row flex-align-start flex-justify-between flex-gap-2">
					<div><b><span class="link" @click.stop="showUser(item.userID)">{{ item.firstName }} {{ item.lastName }}</span></b><br>{{ item.emailAddress }}<br>(user #{{ item.userID }})</div>
					<div class="icon-cross" @click="removeUserFromPushReports( item.userID )" />
				</div>
				<label :for="'session-'+index"><input type="checkbox" v-model="item.session" @change="setReportSettings(index)" :id="'session-'+index" />Session PushReports</label><br />
				<label :for="'daily-'+index"><input type="checkbox" v-model="item.daily" @change="setReportSettings(index)" :id="'daily-'+index" />Daily PushReports</label> &nbsp;<span v-if="item.daily" class="link" @click="sendManualReport('daily', item.userID)">send *</span><br />
				<label :for="'weekly-'+index"><input type="checkbox" v-model="item.weekly" @change="setReportSettings(index)" :id="'weekly-'+index" />Weekly PushReports</label> &nbsp;<span v-if="item.weekly" class="link" @click="sendManualReport('weekly', item.userID)">send *</span><br />
				<label :for="'monthly-'+index"><input type="checkbox" v-model="item.monthly" @change="setReportSettings(index)" :id="'monthly-'+index" />Monthly PushReports</label> &nbsp;<span v-if="item.monthly" class="link" @click="sendManualReport('monthly', item.userID)">send *</span><br /><br />
				<label :for="'absent-'+index"><input type="checkbox" v-model="item.absentStudent" @change="setDeliveryPreferences(index)" :id="'absent-'+index" />Absent Student Reports (Weekly)</label><br />
				<label :for="'email-'+index"><input type="checkbox" v-model="item.email" @change="setDeliveryPreferences(index)" :id="'email-'+index" />Reports by E-Mail</label><br />
				<label :for="'sms-'+index"><input type="checkbox" v-model="item.sms" @change="setDeliveryPreferences(index)" :id="'sms-'+index" />Reports by SMS</label><br />
				<div v-if="item.daily || item.weekly || item.monthly" class="my-1 color-gray-50 flex-row flex-align-start flex-gap-05"><span>*</span><span>manually send PushReport,<br>if the license is <b>not expired</b></span></div>
			</div>
		</section>

		<div v-if="!loaded" class="pa-2 flex-row flex-justify-center">Loading...</div>
		<div v-if="loaded && !settings.length" class="pa-2 flex-row flex-justify-center">No users configured</div>

		<section v-if="licenseUsers.length" class="ma-1">
			<b>Other users attached to this license:</b>
			<ul>
				<li v-for="user in licenseUsers" :key="user.userID" class="flex-row flex-align-center flex-gap-1 my-05">
					<span>#{{ user.userID }}: {{ user.firstName }} {{ user.lastName }} - {{ user.emailAddress }}</span>
					<button class="pillButton secondary bg small" @click="addUserToPushReports( user.userID )">Add</button>
				</li>
			</ul>
		</section>


	</StretchModal>
	<UserDetailsModal v-if="userDetails !== null" :show="userDetails !== null" :user="userDetails" width="70%" height="70%" @close="userDetails = null" />
	
	<AlertDialog ref="sendModal">
		<div v-if="sending"><span class="icon-spinner3 spin1" /> Sending {{ sendingType }} Progress Report...</div>
		<div v-else-if="sendError" class="warning">There was an error while sending the Progress Report</div>
		<div v-else>Progress Report queued for sending</div>
	</AlertDialog>

</section>
</template>

<script>
import StretchModal from '@/components/utilities/StretchModal.vue'
import AlertDialog from '@/components/utilities/AlertDialog.vue'

import LicenseAPI from '@/api/LicenseAPI.js'
import UserAPI from '@/api/UserAPI.js'
import User from '@/features/Users/User.js'

export default {
	name: 'LicenseReportSettings',

	components: {
		StretchModal,
		UserDetailsModal: () => import('@/features/Users/UserDetailsModal.vue'),
		AlertDialog,
	},

	props: {
		masterUser: Object,
		licenseKey: String,
		show: {
			type: Boolean,
			default: false
		}
	},


	data() {
		return {
			masterPreferences: null,
			settings: [],
			userDetails: null,
			loaded: false,

			licenseUsers: [],

			sendingType: null,
			sending: false,
			sendError: false,
		}
	},



	watch: {
		show(value) { if(value) this.initialize() },
	},



	methods: {
		async initialize() {
			await this.getReportSettings()
			this.getLicenseUsers()
		},


		async getLicenseUsers() {
			this.licenseUsers = []

			try {
				const data = await LicenseAPI.getAllLicenseUsers( this.licenseKey )
				for( const u of data ) {
					if( this.settings.find( elem => elem.userID == u.userID ) ) continue
					this.licenseUsers.push( User.import( u ) )
				}

			} catch( e ) {
				console.error( e.message )
			}

		},


		async getReportSettings() {
			this.loaded = false
			
			let settingsArray = await LicenseAPI.getPushReportDeliverySettings(null, this.licenseKey);
			this.settings = []

			for(var item of settingsArray) {
				var settings = {
					userID: item.userID,
					emailAddress: item.emailAddress,
					firstName: item.firstName,
					lastName: item.lastName,
					session: item.sessionReport,
					daily: item.dailyReport,
					weekly: item.weeklyReport,
					monthly: item.monthlyReport
				}

				await LicenseAPI.getPushReportPreferences(item.userID).then( prefs => {
					if( !prefs ) return
					settings.email = prefs.pushReportEmail
					settings.sms = prefs.pushReportSMS
					settings.absentStudent = prefs.absentStudentReport
				})

				this.settings.push( settings )
			}


			this.settings.sort( (a,b) => {
				if(this.masterUser && a.userID === this.masterUser.userID) return -1
				if(this.masterUser && b.userID === this.masterUser.userID) return 1

				if( !a.firstName || !a.lastName ) return -1
				if( !b.firstName || !b.lastName ) return 1
				var aName = `${a.firstName}${a.lastName}`
				var bName = `${b.firstName}${b.lastName}`
				return aName.localeCompare(bName)
			})

			this.loaded = true		
		},


		async setReportSettings(index) {
			let item = this.settings[index]
			let settings = {
				licenseKey: this.licenseKey,
				userID: item.userID,
				sessionReport: item.session,
				dailyReport: item.daily,
				weeklyReport: item.weekly,
				monthlyReport: item.monthly
			}
			await LicenseAPI.setPushReportDeliverySettings(item.userID, settings);
		},


		async setDeliveryPreferences(index) {
			let item = this.settings[index]
			let prefs = {
				pushReportEmail: item.email,
				pushReportSMS: item.sms,
				absentStudentReport: item.absentStudent
			}
			await LicenseAPI.setPushReportPreferences(item.userID, prefs);
		},


		async showUser(userID) {
			this.userDetails = await UserAPI.getUser(userID)
		},



		async addUserToPushReports( userID ) {
			await LicenseAPI.addUserToPushReports( this.licenseKey, userID )
			this.initialize()
		},


		async removeUserFromPushReports( userID ) {
			await LicenseAPI.removeUserFromPushReports( this.licenseKey, userID )
			this.initialize()
		},
		


		async sendManualReport( type, userID ) {
			this.sendingType = type
			this.sending = true
			this.sendError = false

			try {
				this.$refs.sendModal.open()
				await LicenseAPI.manualSendPushReport( type, userID, this.licenseKey );

			} catch( e ) {
				this.sendError = true
				console.error( e )

			} finally {
				this.sending = false
			}
		}
	}
}
</script>

<style scoped>

</style>
