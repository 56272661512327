class LandingPageHTMLSection {


  // Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
  vueID = 0

  type = 'html'
  name = null
  value = null


  constructor(name = '', value = '') {
    this.vueID = this.type + ++LandingPageHTMLSection.idCounter
    this.name = name
    this.value = value
  }



  clone() {
    return LandingPageHTMLSection.import(this.export())
  }

  isSame(obj) {
    return JSON.stringify(this.export()) == JSON.stringify(obj.export())
  }



  static import(obj) {
    const name = obj.name || ''
    const value = obj.value || ''
    return new LandingPageHTMLSection(name, value)
  }


  export() {
    return {
      type: this.type,
      name: this.name,
      value: this.value
    }
  }

}


export default LandingPageHTMLSection
