<template>
<section class="flex-row flex-justify-center flex-align-center w-100 h-100" style="font-size: 3em;">
	Coming soon...
</section>
</template>



<script>
export default {
	name: 'StatisticsDashboard',


}
</script>



<style scoped>

</style>