import API from './API.js';


class StudentStatisticsAPI extends API {

	static controllerName = 'StudentStatisticsAPI'



	static async getPlanProgress( req = {} ) {
		return await this.newPost( 'getPlanProgress', [], req )
	}

	
	
	static async getTimeInProgramGraph( req = {} ) {
		return await this.newPost( 'getTimeInProgramGraph', [], req )
	}

	static async getStudySessions( req = {} ) {
		return await this.newPost( 'getStudySessions', [], req )
	}



	static async getLessonsGraph( req = {} ) {
		return await this.newPost( 'getLessonsGraph', [], req )
	}

	static async getLessonsWorkedOn( req = {} ) {
		return await this.newPost( 'getLessonsWorkedOn', [], req )
	}

	static async getQuestionsGraph( req = {} ) {
		return await this.newPost( 'getQuestionsGraph', [], req )
	}

	static async getQuestionsWorkedOn( req = {} ) {
		return await this.newPost( 'getQuestionsWorkedOn', [], req )
	}


	static async getVideosGraph( req = {} ) {
		return await this.newPost( 'getVideosGraph', [], req )
	}

	static async getVideoWatchTimeGraph( req = {} ) {
		return await this.newPost( 'getVideoWatchTimeGraph', [], req )
	}

	static async getVideosWorkedOn( req = {} ) {
		return await this.newPost( 'getVideosWorkedOn', [], req )
	}

	static async getVideoWatchTimeTotals( req = {} ) {
		return await this.newPost( 'getVideoWatchTimeTotals', [], req )
	}



	static async getPercentPlanGraph( req = {} ) {
		return await this.newPost( 'getPercentPlanGraph', [], req )
	}

	static async getDaysScheduleGraph( req = {} ) {
		return await this.newPost( 'getDaysScheduleGraph', [], req )
	}

	

	static async getProjectedScores( req = {} ) {
		return await this.newPost( 'getProjectedScores', [], req )
	}

	static async getPercentTargetGraph( req = {} ) {
		return await this.newPost( 'getPercentTargetGraph', [], req )
	}

	static async getPredictedScoreGraph( req = {} ) {
		return await this.newPost( 'getPredictedScoreGraph', [], req )
	}



	static async getEScoreList( req = {} ) {

    const responseType = req.csv === true ? 'blob' : null
		const res = await this.newPost( 'getEScoreList', [], req, responseType )
		
		if( req.csv === true ) {
			this.processResponseAsFileDownload( res.data )
			return
		}
		
		return res
	}



	static async getEknoStatReportTemplates() {
		return await this.newGet( 'getEknoStatReportTemplates' )
	}

	static async saveEknoStatReportTemplate( template ) {
		return await this.newPost( 'saveEknoStatReportTemplate', [], template )
	}


	
	static async getEknoStatDimensions() {
		return await this.newGet( 'getEknoStatDimensions' )
	}

	static async getEknoStatDimension( id ) {
		return await this.newGet( 'getEknoStatDimension', [ id ] )
	}

	static async saveEknoStatDimension( dim ) {
		return await this.newPost( 'saveEknoStatDimension', [], dim )
	}

	static async runEknoStatBuilderQuery( req = {} ) {
		return await this.newPost( 'runEknoStatBuilderQuery', [], req )
	}

	static async getEknoStatVariableQueryPreview( eScoreVar ) {
		return await this.newPost( 'getEknoStatVariableQueryPreview', [], eScoreVar )
	}

}

export default StudentStatisticsAPI