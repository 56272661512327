<template>
<section id="wrapper" class="sticky-bottom">

  <div v-if="numPages > 1" class='pag-wrapper border round-05 bg'>
    <div v-if="value > 1" class="pag pag-item skip-item" @click="$emit('input', 1)">
      &laquo; 1
    </div>
    <div v-if="value-10 > 1" class="pag pag-item skip-item" @click="$emit('input', value - 10)">
      &lsaquo; {{ value - 10 }}
    </div>


    <div v-if="value > 5" class="pag">
      &hellip;
    </div>


    <div class="pag pag-item" v-for="pagItem in pagItemsLow" :key="pagItem" @click="$emit('input', pagItem)">
      {{pagItem}}
    </div>

    <div class="pag pag-item selected">
      {{value}}
    </div>

    <div class="pag pag-item" v-for="pagItem in pagItemsHigh" :key="pagItem" @click="$emit('input', pagItem)">
      {{pagItem}}
    </div>


    <div v-if="value<numPages-4" class="pag">
      &hellip;
    </div>


    <div v-if="value+10 < numPages" class="pag pag-item skip-item" @click="$emit('input', value + 10)">
      {{value+10}} &rsaquo;
    </div>
    <div v-if="value < numPages" class="pag pag-item skip-item" @click="$emit('input', numPages)">
      {{numPages}} &raquo;
    </div>
  </div>

</section>
</template>

<script>
export default {
  name: 'Paginator',

  props: {
    value: Number,
    numPages: Number
  },

  computed: {
    pagItemsLow() {
      let pagItemsLow = [];
      for (let i = Math.min(this.value - 4, this.numPages-8); i < this.value; ++i) {
        if (i > 1) pagItemsLow.push(i);
      }
      return pagItemsLow;
    },

    pagItemsHigh() {
      let pagItemsHigh = [];
      for (let i = this.value + 1; i < Math.max(this.value + 5, 10) ; ++i) {
        if (i < this.numPages) pagItemsHigh.push(i);
      }
      return pagItemsHigh;
    }
  }
}
</script>

<style scoped>
#wrapper {
  display: flex;
  justify-content: center;
}
.pag-wrapper {
  display: flex;
  justify-content: center;
  margin: 0.5em 0 0 0;
  font-size: 0.9em;
  padding: 0.5em;
  box-shadow: 0 0 8px 0px #ccc;
}
.pag {
  margin: 0;
  padding: 0.25em 0.75em;
}

.pag-item { cursor: pointer; }

.pag-item:hover {
  transition: background-color 0.25s linear;
  background-color: var(--color-highlight);
}
.pag-item.selected {
  background-color: #888;
  color: white;
}

.skip-item {
  font-weight: bold;
}
</style>
