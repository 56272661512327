<template>
<section>
	<section v-if="!loading">
		<ObjectTable
			:Columns="columns"
			:Source="items"
			:Numbered="true"
			:Deletable="true"
			:SortBy="sortBy"
			:SortAsc="sortAsc"
			:PageNum="page"
			:PageSize="pageSize"
			@edit="item => selectedItem = item"
			@sort="prop => sort(prop)"
			@delete="item => showDeleteSelected(item)"
			class="mt-1"
			ref="objectTable"
		/>
		<Paginator v-model="page" @input="initialize()" :numPages="pages" />
	</section>

  <div v-if="!loading && !error && !items.length" class="NoResults">No Records</div>

	<section v-if='loading' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

	<section v-if="error" class="warning">
		Failed to get records
	</section>


	
	<ScheduledRegistrationListModal :selectedItem="selectedItem" @close="deselect()" />
	
	<ConfirmDialog ref="confirmDelete" @cancel="deselect()" @confirm="deleteItem()">
		<div v-if="itemToDelete">
			Un-schedule <strong>{{ itemToDelete.messageCount }}</strong>
			registration emails <strong>{{ itemToDelete.customerName ? `for ${ itemToDelete.customerName}` : '' }} </strong>
			on <strong>{{ itemToDeleteScheduledDate }}</strong>?
		</div>
	</ConfirmDialog>

</section>
</template>



<script>
import ScheduledRegistrationListModal from './ScheduledRegistrationListModal.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

import MessageAPI from '@/api/MessageAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'

export default {
	name: 'ScheduledRegistrationSummaryList',

	components: {
		ScheduledRegistrationListModal,
		ObjectTable,
		Paginator,
		ConfirmDialog,
	},


	data() {
    return {
			items: [],
			selectedItem: null,
			itemToDelete: null,

			page: 1,
			pages: 0,
			pageSize: 100,

			sortBy: 'scheduledDate',
			sortAsc: false,

			loading: false,
			error: false,

			columns: [
				{
					displayName: 'Customer',
					propertyName: 'customerName',
          sortable: true
				},
				{
					displayName: 'Scheduled To Send',
					propertyName: 'scheduledDate',
					displayFunction: function( item ) {
						if( !item.scheduledDate ) return ''
						const d = new Date( item.scheduledDate * 1000 )
						return d.toLocaleDateString()
					},
					sortable: true
				},
				{
					displayName: 'Sent',
					propertyName: 'sentCount',
					displayFunction: function( item ) {
						return `${ Math.round( item.sentCount / item.messageCount * 100 ) }% (${ item.sentCount } / ${ item.messageCount })`
					},
					sortable: true
				},
				{
					displayName: 'Date Sent',
					propertyName: 'latestSendDate',
					displayFunction: function( item ) {
						if( !item.latestSendDate ) return ''
						const d = new Date( item.latestSendDate * 1000 )
						return d.toLocaleDateString()
					},
          sortable: true
				}
			],

    }
  },


	created() { this.initialize() },


	computed: {
		itemToDeleteScheduledDate() {
			if( !this.itemToDelete ) return ''
			if( !this.itemToDelete.scheduledDate ) return ''

			const d = new Date( this.itemToDelete.scheduledDate * 1000 )
			return d.toLocaleDateString()
		}
	},


	methods: {
		async initialize() {

			this.loading = true
			this.error = false
			
			this.items = []
			this.selectedItem = null
			this.itemToDelete = null

			try {
				var req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
				const res = await MessageAPI.getScheduledRegistrationEmailSummary( req )
				this.items = res.data
				this.pages = res.pages

			} catch( err ) {
				this.error = true
			} finally {
				this.loading = false
			}
		},

		sort( propName ) {
			if( this.sortBy === propName ) {
				this.sortAsc = !this.sortAsc
				this.initialize()
				return
			}

      this.sortBy = propName
			if( this.sortBy === 'scheduledDate' ) this.sortAsc = false
			else this.sortAsc = true
			
			this.initialize()
		},

		deselect() {
			this.selectedItem = null
			this.itemToDelete = null
			this.$refs.objectTable.deselect()
		},

		showDeleteSelected( item ) {
			this.itemToDelete = item
			this.$refs.confirmDelete.open()
		},

		async deleteItem() {
			if( !this.itemToDelete ) return

			try {
				await MessageAPI.unscheduleRegistrationEmails( this.itemToDelete.scheduledDate, this.itemToDelete.customerID )
			} finally {
				this.initialize()
			}
		}

	}
}
</script>



<style scoped>

</style>