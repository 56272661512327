<template>
<section class="flex-row flex-align-start flex-justify-start w-100 h-100">

	<section id="dimensionDetails" class="pa-1 h-100" style="max-width: min(33%, 400px); min-width: 350px; max-height: calc( 100vh - 12em ); overflow: auto;">

		<!-- Name -->
		<h2 class="center mt-0" style="color: var(--pp10-purple);">
			Factor: 
			<span v-if="!editName" class="name pointer round-05 pa-025" @click="editName = true">{{ dimension.name }}</span>
			<span v-else><input v-model="dimension.name" placeholder="Name" />&nbsp;<button class="pillButton bg secondary" @click="editName = false">done</button></span>
		</h2>

		<!-- ID -->
		<div>ID: {{ dimension.id || '(un-saved)' }}</div>

		<!-- Course -->
		<div class="flex-row flex-align-center flex-gap-05" :class="{ 'warning round-05' : course === null }">
			Course: <CourseSearchDropdown v-model="course" width="100%" />
		</div>
		<div>
			Course Version ID (optional):<br>
			<input type="number" v-model.number="dimension.courseVersionID" />
		</div>

		<!-- <div class="flex-row flex-align-center">
			Course Version (optional):
		</div> -->

		<!-- LIST: DB Query Vars -->
			
		<div id="queryVars" class="round-05 pa-05 mt-3 w-100" @click.stop="selectQueryVariable()">
			<div class="flex-row flex-align-start flex-justify-between">
				<div class="icon-database" />
				<div class="bold">DB Sources</div>
				<div id="queryVarI" @mouseenter="showQueryVariableInfo = true" @mouseleave="showQueryVariableInfo = false">
					<span class="icon-info" />
					<p v-if="showQueryVariableInfo" id="queryVarInfo">Each Input Parameter defines one "column" or "variable" pulled directly from the database. Each Input Parameter yields a vector (list) of values (one value per student).</p>
				</div>
			</div>

			<div v-for="(name, idx) in dimension.queryVariableNames" :key="name" class="listItem flex-row flex-justify-between flex-align-center w-100 round-05 pointer pa-05 mt-1" :class="{ 'selected' : dimension.queryVariableList[idx] === selectedQueryVariable }" @click.stop="selectQueryVariable( name, dimension.queryVariableList[idx] )">
				{{ name }}
				<span v-if="unusedQueryVariables.includes( name )">UNUSED</span>
				<span class="pillButton secondary red small icon-bin2" @click="deleteQueryVariable( name )" />
			</div>

			<div class="center mt-1"><button class="pillButton secondary bg" @click="createQueryVariable()">+ New Input Parameter</button></div>

			<StretchModal ref="newQVar" padding="1em" width="400px" :fullscreen="false">
				<h2 class="mt-0 mb-05">New Input (DB) Parameter</h2>
				<div><input v-model="newQueryVariableName" placeholder="Name this parameter" class="w-100" /></div>
				<div class="right mt-1"><button class="pillButton" @click="saveNewQueryVariable()">Save</button></div>
			</StretchModal>
		</div>


		<div class="center my-1" style="font-size: 2em;"><span class="icon-arrow-down2" /></div>


		<!-- Output (computed) Vars -->
		<div id="outputVars" class="round-05 pa-05 w-100" @click.stop="selectOutputVariable()">
			
			<div class="flex-row flex-align-start flex-justify-between">
				<div class="icon-calculator" />
				<div class="bold">Outputs</div>
				<div id="outputVarI" @mouseenter="showOutputVariableInfo = true" @mouseleave="showOutputVariableInfo = false">
					<span class="icon-info" />
					<p v-if="showOutputVariableInfo" id="outputVarInfo" @click.stop="showOutputVariableInfo = false" @mouseout="showOutputVariableInfo = false">Each Output Variable defines a series of mathematical operations to be performed on one or more Input Parameters, to yield a final "column" or "vector" of data to be used in reports.</p>
				</div>
			</div>

			<div v-for="(name, idx) in dimension.outputVariableNames" :key="name" class="listItem flex-row flex-justify-between flex-align-center w-100 round-05 pointer pa-05 mt-1" :class="{ 'selected' : dimension.outputVariableList[idx] === selectedOutputVariable }" @click.stop="selectOutputVariable( name, dimension.outputVariableList[idx] )">
				{{ name }}
				<span class="pillButton secondary red small icon-bin2" @click="deleteOutputVariable( name )" />
			</div>

			<div class="center mt-1"><button class="pillButton secondary bg" @click="createOutputVariable()">+ New Output Variable</button></div>

			<StretchModal ref="newOVar" padding="1em" width="400px" :fullscreen="false">
				<h2 class="mt-0 mb-05">New Output Variable</h2>
				<div><input v-model="newOutputVariableName" placeholder="Name this variable" class="w-100" /></div>
				<div class="right mt-1"><button class="pillButton" @click="saveNewOutputVariable()">Save</button></div>
			</StretchModal>
		</div>

		<div class="right mt-1">
			<span v-if="saveError" class="icon-alert" />
			<button v-if="!saving" class="pillButton" @click="saveDimension()">Save</button>
			<span v-if="saving" class="icon-spinner4 spin-loader-15" />
		</div>

	</section>




	<!-- Query Var editor pane -->
	<div class="flex-row flex-justify-center w-100 h-100">
		<EknoStatDimensionQueryVariableEditor v-if="selectedQueryVariable" :queryVariable="selectedQueryVariable" :name="selectedQueryVariableName" @updateName="n => updateQueryVariableName(n)" @updateDBQuery="getQueryPreview()" />
		<ComputableEditor v-if="selectedOutputVariable" :computable="selectedOutputVariable" :name="selectedOutputVariableName" :dimension="dimension" @updateName="n => updateOutputVariableName(n)" />
	</div>


</section>
</template>



<script>
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import CourseAPI from '@/api/CourseAPI.js'

import Course from '@/models/Course.js'
import EknoStatDimensionQueryVariable from './EknoStatDimensionQueryVariable.js'
import Computable from './Computable.js'

import EknoStatDimensionQueryVariableEditor from './EknoStatDimensionQueryVariableEditor.vue'
import ComputableEditor from './ComputableEditor.vue'
import CourseSearchDropdown from '@/features/Courses/CourseSearchDropdown.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

export default {
	name: 'EknoStatDimensionEditor',


	components: {
		EknoStatDimensionQueryVariableEditor,
		ComputableEditor,
		CourseSearchDropdown,
		StretchModal,
	},


	props: {
		dimension: {
			type: Object,
			required: true
		},
	},



	data() {
		return {
			editName: false,
			saving: false,
			saveError: false,

			showDetailPane: false,
			newQueryVariable: null,
			newQueryVariableName: null,
			selectedQueryVariable: null,
			selectedQueryVariableName: null,

			unusedQueryVariables: [],
			showQueryVariableInfo: false,
			showOutputVariableInfo: false,

			newOutputVariable: null,
			newOutputVariableName: null,
			selectedOutputVariable: null,
			selectedOutputVariableName: null,

			course: null,
		}
	},



	watch: {
		course( course ) {
			if( !course ) this.dimension.courseID = null
			else this.dimension.courseID = course.courseID
		},

		dimension() { this.initialize() }
	},



	created() {
		this.initialize()
	},



	methods: {


		async initialize() {
			this.selectedQueryVariable = null
			this.selectedQueryVariableName = null
			this.selectedOutputVariable = null
			this.selectedOutputVariableName = null
			this.course = null

			// TODO: if dimension.courseID is set, pre-load this.course from server!
			const res = await CourseAPI.getCoursesByID( [ this.dimension.courseID ] )
			if( !res.data || !res.data.length ) return

			this.course = Course.import( res.data[0] )
		},



		async saveDimension() {
			console.debug( "Saving dimension..." )
			this.saveError = false
			this.saving = true
			
			await StudentStatisticsAPI.saveEknoStatDimension( this.dimension.export() )
			.then( res => {
				this.dimension.id = res.data.id
				this.saveError = false
			}).catch( err => {
				this.saveError = true
			})

			this.saving = false
		},




		getQueryPreview() {
			this.$emit('updateDBQuery', this.selectedQueryVariableName)
			this.updateUnusedQueryVariables()
		},


		
		createQueryVariable() {
			this.newQueryVariable = new EknoStatDimensionQueryVariable( this.dimension )
			this.$refs.newQVar.open()
		},

		saveNewQueryVariable() {
			this.dimension.setQueryVariable( this.newQueryVariableName, this.newQueryVariable )
			this.selectQueryVariable( this.newQueryVariableName, this.newQueryVariable )
			this.newQueryVariable = null
			this.newQueryVariableName = null
			this.$refs.newQVar.close()
		},

		selectQueryVariable( name = null, qvar = null ) {
			this.selectedOutputVariableName = null
			this.selectedOutputVariable = null

			this.selectedQueryVariableName = name
			this.selectedQueryVariable = qvar
			this.updateUnusedQueryVariables()
		},

		updateQueryVariableName( newName ) {
			if( !this.selectedQueryVariableName || !newName ) return
			if( this.dimension.hasQueryVariable( newName ) ) return

			this.dimension.updateQueryVariableName( this.selectedQueryVariableName, newName )
			this.selectedQueryVariableName = newName
			this.updateUnusedQueryVariables()
		},

		deleteQueryVariable( name ) {
			this.dimension.deleteQueryVariable( name )
			this.updateUnusedQueryVariables()
		},




		updateUnusedQueryVariables() {
			var names = this.dimension.findUnusedQueryVariables()
			this.unusedQueryVariables = names
		},




		createOutputVariable() {
			this.newOutputVariable = new Computable( this.dimension )
			this.$refs.newOVar.open()
		},

		saveNewOutputVariable() {
			this.dimension.setOutputVariable( this.newOutputVariableName, this.newOutputVariable )
			this.selectOutputVariable( this.newOutputVariableName, this.newOutputVariable )
			this.newOutputVariable = null
			this.newOutputVariableName = null
			this.$refs.newOVar.close()
		},

		selectOutputVariable( name = null, ovar = null ) {
			this.selectedQueryVariable = null
			this.selectedQueryVariableName = null

			this.selectedOutputVariableName = name
			this.selectedOutputVariable = ovar
			this.updateUnusedQueryVariables()
		},

		updateOutputVariableName( newName ) {
			if( !this.selectedOutputVariableName || !newName ) return
			if( this.dimension.hasOutputVariable( newName ) ) return

			const oldName = this.selectedOutputVariableName
			this.dimension.updateOutputVariableName( this.selectedOutputVariableName, newName )
			this.selectedOutputVariableName = newName
			this.updateUnusedQueryVariables()
			this.$emit( 'updateOutputVariableName', newName, oldName )
		},

		deleteOutputVariable( name ) {
			this.dimension.deleteOutputVariable( name )
			this.updateUnusedQueryVariables()
		},




		// Used by parent component
		isQueryVariableSelected() { return this.selectedQueryVariableName !== null },
		getSelectedQueryVariable() { return this.selectedQueryVariable },
		getSelectedQueryVariableName() { return this.selectedQueryVariableName },
		isOutputVariableSelected() { return this.selectedOutputVariableName !== null },
		getSelectedOutputVariable() { return this.selectedOutputVariable },
		getSelectedOutputVariableName() { return this.selectedOutputVariableName },


	}

}
</script>



<style scoped>
#dimensionDetails {
	border: 1px solid var(--pp10-purple);
	background-color: var(--pp10-purple-95);
	border-radius: 0.5em;
}
.listItem {
	background-color: white;
}
.listItem:hover {
	background-color: var(--ekno-blue-95);
}
.listItem.selected {
	background-color: var(--ekno-blue);
	color: white;
}
#queryVars .listItem.selected {
	background-color: var(--pp10-orange);
	border-color: var(--pp10-orange);
}
#outputVars .listItem.selected {
	background-color: var(--pp10-green);
	border-color: var(--pp10-green);
}

#queryVars {
	background-color: var(--pp10-orange-90);
}
#outputVars {
	background-color: var(--pp10-green-pastel-80);
}
.name:hover {
	background-color: var(--ekno-blue-95);
}

#queryVarI, #outputVarI {
	position: relative;
}

#queryVarInfo, #outputVarInfo {
	position: absolute;
	right: 0.5em;
	top: 0.5em;
	width: 300px;
	background-color: white;
	border: 1px solid black;
	border-radius: 0.5em;
	padding: 1em;
	box-shadow: 3px 3px 15px -4px #888;
	z-index: 1;
	text-align: justify;
}
#queryVarInfo {
	border-color: var(--pp10-orange);
}
#outputVarInfo {
	border-color: var(--pp10-green);
}
</style>