<template>
	<div>
		<button class="pillButton StickySaveButton" @click="save" :class="{ 'disabled' : !canSave }" :disabled="!canSave">Save Changes</button>
		
		
		<!-- Basic Information -->
		<h1 class="section-header">Basic Information</h1>
		<section class='flex-row flex-gap-2 mb-4'>

			<div class="flex-column flex-gap-05">
				<div class="flex-row flex-align-center flex-justify-end">
					<span class="mr-05" style="text-align:right">First Name:</span>
					<input type="text" v-model="NewUser.firstName" @blur="autofillFullName" />
				</div>

				<div class="flex-row flex-align-center flex-justify-end">
					<span class="mr-05" style="text-align:right">Last Name:</span>
					<input type="text" v-model="NewUser.lastName" @blur="autofillFullName" />
				</div>

				<div class="flex-row flex-align-center flex-justify-end">
						<span class="mr-05" style="text-align:right">Full Name:</span>
						<input type="text" v-model="NewUser.fullName" @focus="autofillFullName" />
				</div>

				<div class="flex-row flex-align-center flex-justify-end mt-2">
					<span class="mr-05" style="text-align:right">Email:</span>
					<div>
						<input id="emailAddress" type="text" v-model="NewUser.emailAddress" @input='checkEmail' />
						<p v-if='userExists' class='message-warning'>This email address is already registered.</p>
					</div>
				</div>

				<div class="flex-row flex-align-center flex-justify-end">
					<span class="mr-05" style="text-align:right">Alias:</span>
					<input id="alias" type="text" v-model="NewUser.alias" disabled />
				</div>

				<div class="flex-row flex-align-center flex-justify-end">
					<span class="mr-05" style="text-align:right">Phone:</span>
					<input id="phone" type="text" disabled v-model="NewUser.phone" />
				</div>
			</div>


			<div>
				<div>User ID: {{NewUser.userID}}</div>
				<div>Licenses: {{ User.numLicenses }}</div>
				<div>User Created: {{ creationDateDisplay }}</div>
				<br>
				<div>Timezone: {{ timezone }} ({{ timezoneOffset }})</div>
				
				<div class="flex-row flex-align-center flex-justify-between flex-gap-05 flex-wrap mt-1">Created by:<UserSearchDropdown v-model="createdByUser" width="20rem" /></div>
				<div class="flex-row flex-align-center flex-justify-between flex-gap-05 flex-wrap mt-05">Customer:<CustomerSearchDropdown v-model="customer" width="20rem" /></div>

				<div class="mt-1">
					<label>
						<input id="emailVerified" type="checkbox" v-model="User.emailVerified" />
						<span>Email Verified</span>
					</label>
					<br>
					<label>
						<input id="emailVerifEmail" type="checkbox" v-model="NewUser.verificationEmailSent" />
						<span>Verification Email Sent</span>
					</label>
					<br>
					<label>
						<input type="checkbox" disabled :checked="User.registerEmailTimestamp !== null" />
						<span>Registration Email Sent <br>
							<span style="font-size: 0.8em; color: #666;">{{ User.registerEmailDate }}</span>
						</span>
					</label>
					<div v-if="User.registrationNonce" class="mt-1">
						Registration Link:<br>
						<a :href="programBaseURL + '/register?nonce=' + User.registrationNonce" target="_new">{{ programBaseURL }}/register?nonce={{ User.registrationNonce }}</a>
					</div>
				</div>
			</div>

			<div><AccountBalance v-if="User.userID" :userID="User.userID" /></div>
		</section>


		<!-- Actions -->
		<section v-if="NewUser.userID" class='my-1'>
			<h1 class="section-header">Actions</h1>

			<div class="flex-row flex-align-center flex-gap-1">
				<div id="password">
					<label>Reset User's Password</label><br />
					<label v-if='!NewUser.userID'>Please save the user before setting the password<br></label>
					<input type="text" v-model="NewUser.newPassword" :disabled='!NewUser.userID' />
					<button v-if='NewUser.userID' @click.prevent="updatePassword">Set Password</button>
				</div>

				<div>
					<input id="accountLocked" type="checkbox" v-model="NewUser.accountLocked" />
					<label for="accountLocked">Lock Account</label><br />

					<input id="changePassword" type="checkbox" v-model="NewUser.changePassword" />
					<label for="changePassword">Force user to change password</label>
				</div>
				<div><button class="button" style="margin-bottom:1em;" @click="impersonateUser">Impersonate User (new tab)</button></div>
			</div>

		</section>



		<section v-if="NewUser.userID" class='my-1'>
			<h4>Resend Emails</h4>
			<button class='button' @click='sendPasswordResetEmail'>Password Reset</button>
			<button class='button ml-1' @click='sendRegistrationEmail'>New User Registration</button>
		</section>
		<div v-if="User.passwordResetNonce" class="mt-1">
			Password Reset Link:<br>
			<a :href="programBaseURL + '/ResetPassword?nonce=' + User.passwordResetNonce" target="_new">{{ programBaseURL }}/ResetPassword?nonce={{ User.passwordResetNonce }}</a>
		</div>
	</div>
</template>


<script>
import SalesManagementAPI from "@/api/SalesManagementAPI.js"
import UserAPI from '@/api/UserAPI.js';
import API from '@/api/API.js';
import { powerprep_live_base_url } from '@/Config.js'
import Customer from '@/features/SalesManagement/Customers/Customer.js'

import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from "@/features/SalesManagement/Customers/CustomerSearchDropdown.vue"
import AccountBalance from '@/features/orders/AccountBalance.vue'

export default {
	name: 'GeneralUserDetails',


	components: {
		UserSearchDropdown,
		CustomerSearchDropdown,
		AccountBalance,
	},


	props: {
		NewUser: {
			type: Object,
			default: () => { return {}; }
		},

		User: {
			type: Object,
			default: () => { return {}; }
		}
	},

	data() {
		return {
			userExists: false,

			timezone: null,
			timezoneOffset: null,

      createdByUser: null,
			customer: null,
		}
	},


	created() { this.initialize() },


	computed: {
		changesMade() { return !this.User.isSame(this.NewUser); },
		canSave() { return this.changesMade && !this.userExists; },
		creationDateDisplay() {
			if (this.User.creationDate) return this.User.creationDate.toLocaleDateString();
			return '--';
		},

		customerID: {
			get() { return this.NewUser.customerID },
			set(value) {
				if( !value ) this.NewUser.customerID = null
				else this.NewUser.customerID = value
			}
		},

		programBaseURL() { return powerprep_live_base_url }
	},



	watch: {
		User() { this.getNonces() },
		NewUser() { this.getNonces() },

		createdByUser( user ) {
			if( !user ) this.NewUser.createdByUserID = null
			else this.NewUser.createdByUserID = user.userID
		},
		customer( customerObj ) {
			if( !customerObj ) this.NewUser.customerID = null;
			else this.NewUser.customerID = customerObj.id;
		},
	},



	methods: {
		async initialize() {
			const tzData = await UserAPI.getTimezone(this.NewUser.userID)
			this.timezone = tzData.timezone
			this.timezoneOffset = tzData.timezone_offset
			if( this.timezoneOffset ) this.timezoneOffset = 0 - Math.round( this.timezoneOffset / 6 ) / 10

			this.timezoneOffset = `UTC ${this.timezoneOffset > 0 ? '+' : ''}${this.timezoneOffset}`

			this.getCreatedByUser()
			this.getCustomer()
			this.getNonces()
		},


		async getCreatedByUser() {
			if( !this.NewUser.createdByUserID ) return
			const user = await UserAPI.getUser( this.NewUser.createdByUserID )
			if( !user ) return
			this.createdByUser = user
		},


		async getCustomer() {
			if( !this.NewUser.customerID ) return
			const res = await SalesManagementAPI.getCustomer( this.NewUser.customerID )
			if( !res ) return
			this.customer = Customer.import(res)
		},


		async getNonces() {
			const data = await UserAPI.getNonces( this.NewUser.userID )
			this.User.registrationNonce = data.registration
			this.User.passwordResetNonce = data.passwordReset
			this.NewUser.registrationNonce = data.registration
			this.NewUser.passwordResetNonce = data.passwordReset
		},


		autofillFullName() {
			if(this.NewUser.firstName && this.NewUser.lastName && !this.NewUser.fullName) {
				this.NewUser.fullName = this.NewUser.firstName + ' ' + this.NewUser.lastName;
			}
		},

		checkEmail() {
			const userExists = async () => {
				this.userExists = await UserAPI.checkUserExists(this.NewUser.emailAddress);
			}
			clearTimeout(this.timeoutID);
			this.timeoutID = setTimeout(userExists, 1000);
		},

		async updatePassword() {
			if (this.NewUser.userID) {
				try {
					let res = await UserAPI.resetPassword(this.NewUser);
					alert('Password updated.');
				} catch (e) {
					alert(`Error updating password: ${e}`);
				}
			} else {
				alert('Please finish creating user first, then set the password.');
			}
		},

		async impersonateUser() {
			API.impersonate(this.NewUser.emailAddress).then(res => {
				window.open(powerprep_live_base_url, '_blank');
			}).catch(err => {
				alert(`Couldn't impersonate ${this.NewUser.firstName}.  Make sure you're logged in as an administrator.`)
			});
		},

		async save() {
			try {
				let res;
				
				if (this.NewUser.userID) {
					// edit user
					res = await UserAPI.editUser(this.NewUser);
				} else {
					// add user
					res = await UserAPI.addUser(this.NewUser);
					this.NewUser.userID = res.userID;
					this.User.userID = res.userID;
				}

				// Copy FROM new TO original
				Object.assign(this.User, this.NewUser)
				
				return res;

			} catch (e) {
				console.error(e);
				alert(`Could not save changes: ${e}`);
			}
		},

		async sendPasswordResetEmail() {
			try {
				await UserAPI.requestPasswordReset(this.User.emailAddress);
				alert('Password reset email queued for sending');
				this.getNonces()
			} catch (e) {
				console.error(e);
				alert('Error: password reset email not sent.');
			}
		},

		async sendRegistrationEmail() {
			try {
				await UserAPI.sendNewUserEmail(this.User.userID);
				alert('New user email queued for sending.');
				this.getNonces()
			} catch (e) {
				console.error(e);
				alert('Error: new user email not sent.');
			}
		},

	}
}
</script>


<style>
#password {
	border: 1px solid red;
	background-color: #FFCCCC;
	padding: 1em;
}

.InfoBlock {
	/* display: inline-block; */
	margin: 0 1em 1em 0;
}
</style>
