import Exim from "@/models/Exim"

class Computable extends Exim {

	
	dimension = null // Reference to EknoStatDimension
	operations = [] // [ { operator: String, operands: [String QVarName|Number ... ] }, ... ]

	// Display parameters
	unit = null
	shortUnit = null


	constructor( dimension = null ) {
		super()
		this.dimension = dimension
	}



	addOperation( operator = null, operands = [] ) {
		this.operations.push( { operator, operands } )
	}

	deleteOperation( index ) {
		this.operations.splice( index, 1 )
	}


	updateQueryVariableName( oldName, newName ) {
		for( var op of this.operations ) {
			for( var i = 0; i < op.operands.length; i++ ) {
				if( op.operands[i] !== oldName ) continue
				else op.operands[i] = newName
			}
		}
	}



	static import( obj ) {
		return super.import( obj )
	}



	export() {
		return super.export( [ 'dimension' ] )
	}


}

export default Computable;