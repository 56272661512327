<template>
<section>

	<div v-if="showControls" class="flex-row flex-align-center flex-justify-center flex-gap-1 py-05 sticky-tools">

		<!-- FILTER: User -->
		<UserSearchDropdown
			v-if="!userID"
      placeholder="Filter by User..."
      width="175px"
      @updateUser="user => selectUser(user)" />

		<!-- FILTER: Customer -->
		<CustomerSearchDropdown
			v-if="!userID && !licenseKey"
      placeholder="Filter by Customer..."
			width="175px"
			ref="customerSearch"
			@updateCustomer="cust => selectCustomer(cust)" />

		<!-- FILTER: Affiliate -->
		<AffiliateSearchDropdown
      v-if="!userID && !licenseKey"
      placeholder="Filter by Affiliate..."
      width="175px"
      @update="aff => selectAffiliate(aff)" />

		<!-- FILTER: timestamp / date -->
		<div v-if="!start && !end" class="flex-row flex-gap-05 flex-wrap">
			<div class="nowrap">
				<v-date-picker v-model="startDate" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="Start Date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
			</div>
			<div>-</div>
			<div class="nowrap">
				<v-date-picker v-model="endDate" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="End Date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
			</div>
		</div>

		<!-- FILTER: Session Duration -->
		<div>
			<div class="bold center">Session Duration:</div>
			<div class="flex-row flex-gap-05">
				<div class="nowrap"><input type="number" v-model.number="minDuration" @change="setMinDuration()" min="0" style="width: 5em" />&nbsp;mins</div>
				<div>-</div>
				<div class="nowrap"><input type="number" v-model.number="maxDuration" @change="setMaxDuration()" min="0" style="width: 5em" />&nbsp;mins</div>
			</div>
		</div>

		<!-- FILTER: Expired/Unexpired Licenses -->
		<select v-model="activeLicenseFilter">
			<option :value="null">All Licenses</option>
			<option :value="true">Active only</option>
			<option :value="false">Expired only</option>
		</select>

	</div>


	<UserTimeStats ref="stats" />


	<section v-if="!loading && items.length">
		<ObjectTable
			:Columns="columns"
			:Source="items"
			:Numbered="true"
			:SortBy="sortBy"
			:SortAsc="sortAsc"
			:PageNum="page"
			:PageSize="pageSize"
			@sort="prop => sort( prop )"
			@edit="sess => selectSession( sess )"
			class="mt-1"
			ref="objectTable"
		/>
		<Paginator v-model="page" @input="initialize( false )" :numPages="pages" />
	</section>

	<section v-else-if='loading' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

	<section v-if="error" class="warning">
		Failed to get records
	</section>

	
	<StretchModal @close='closeDetails()' :closeIcon="true" :width="'80%'" :height="'80%'" ref="detailsModal" :padding="'1em'">
		<template #header v-if="selectedSession">Study Session #{{ selectedSession.sessionID }}</template>
		<UserSessionDetails v-if="selectedSession" :userSession="selectedSession" />
	</StretchModal>

</section>
</template>



<script>
import LoginTrackingAPI from '@/api/LoginTrackingAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import UserSession from '@/features/LoginTracking/UserSession.js'

import UserTimeStats from './UserTimeStats.vue'
import UserSessionDetails from './UserSessionDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'

export default {
	name: 'UserSessionList',


	components: {
		UserTimeStats,
		UserSessionDetails,
		StretchModal,
		UserSearchDropdown,
		CustomerSearchDropdown,
		AffiliateSearchDropdown,
		ObjectTable,
		Paginator,
	},


	props: {
		userID: {
			type: Number,
			default: null
		},
		licenseKey: {
			type: String,
			default: null
		},

		start: {
			type: Date,
			default: null
		},
		end: {
			type: Date,
			default: null
		},

		showControls: {
			type: Boolean,
			default: true
		},

		showActive: {
			type: Boolean,
      default: true
		},
	},


	data() {
		return {
			startDate: null,
			endDate: null,
			initStartDate: false,
			initEndDate: false,

			user: null,
			customer: null,
			affiliate: null,
			minDuration: null,
			maxDuration: null,
			activeLicenseFilter: true,     // TRUE: active only, FALSE: expired only, NULL: all licenses

			selectedSession: null,
			loading: false,
			error: false,

			sortBy: 'startTimestamp',
			sortAsc: false,
			page: 1,
			pageSize: 100,

			items: [],
			count: 0,
			pages: 1,

			columns: [
				{
					displayName: 'User ID',
					propertyName: 'userID',
					sortable: true
				},
				{
					displayName: 'User',
					propertyName: 'email',
					displayFunction: function( item ) {
						return `<div>${item.firstName} ${item.lastName}</div><div>${item.email}</div>`
					},
					sortable: true
				},
				{
					displayName: 'License Key',
					propertyName: 'licenseKey',
					sortable: true
				},
				{
					displayName: 'Duration',
					propertyName: 'durationSeconds',
					displayFunction: function( item ) {
						return item.durationString
					},
					sortable: true
				},
				{
					displayName: 'In',
					propertyName: 'startTimestamp',
					displayFunction: function( item ) { return item.startTimestamp ? item.startTimestamp.toLocaleString() : '' },
					sortable: true
				},
				{
					displayName: 'Out',
					propertyName: 'endTimestamp',
					displayFunction: function( item ) { return item.endTimestamp ? item.endTimestamp.toLocaleString() : '' },
					sortable: true
				},
			]
		}
	},



	watch: {
		start() {
			if( this.initStartDate ) this.initStartDate = false
			else this.page = 1; this.initialize()
		},
		end() {
			if( this.initEndDate ) this.initEndDate = false
			else this.page = 1; this.initialize()
		},
		startDate() { this.page = 1; this.initialize() },
		endDate() { this.page = 1; this.initialize() },
		activeLicenseFilter() { this.page = 1; this.initialize() },
	},



	mounted() {
		this.activeLicenseFilter = this.showActive
		this.initialize()
		},



	methods: {
		async initialize( getStats = true ) {

			this.startDate = this.start || null
			this.initStartDate = true
			this.endDate = this.end || null
			this.initEndDate = true

			try {
				this.loading = true
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
				req.userID = this.user ? this.user.userID : this.userID
				req.customerID = this.customer ? this.customer.id : null
				req.affiliateID = this.affiliate ? this.affiliate.id : null
				req.licenseKey = this.licenseKey
				req.startTimestamp = this.startDate ? Math.floor(this.startDate.getTime()/1000) : null
				req.endTimestamp = this.endDate ? Math.floor(this.endDate.getTime()/1000) : null
				req.minDurationSeconds = this.minDuration !== null ? (this.minDuration * 60) : null // convert to seconds
				req.maxDurationSeconds = this.maxDuration !== null ? (this.maxDuration * 60) : null // convert to seconds
				req.activeLicenseFilter = this.activeLicenseFilter

				// Get summary stats
				if( getStats ) {
					const req2 = {}
					Object.assign( req2, req )
					req2.sortBy = 'userID'
					this.$refs.stats.initialize( req2 )
				}
	
				// Get main table data
				const pr = await LoginTrackingAPI.getUserSessions( req )
				this.count = pr.count
				this.pages = pr.pages

				this.items = []
				for( var item of pr.data ) this.items.push( UserSession.import(item) )

				this.error = false
				
			} catch( e ) {
				this.items = []
				this.count = 0
				this.pages = 1
				this.error = true

			} finally {
				this.loading = false
			}



		},


		async selectUser( user = null ) {
      this.user = user
			this.page = 1
      this.initialize()
    },


		selectCustomer( customer = null ) {
			this.customer = customer
			this.page = 1
			this.initialize()
		},


		selectAffiliate( affiliate = null ) {
			this.affiliate = affiliate
			this.page = 1
			this.initialize()
		},


		setMinDuration() {
			if( this.setMinDuration.timeoutID ) clearTimeout( this.setMinDuration.timeoutID )

			this.setMinDuration.timeoutID = setTimeout( () => {
				if( isNaN( this.minDuration) || this.minDuration === '' ) this.minDuration = null
				this.page = 1
				this.initialize()
				this.setMinDuration.timeoutID = null
			}, 500)
		},


		setMaxDuration() {
			if( this.setMaxDuration.timeoutID ) clearTimeout( this.setMaxDuration.timeoutID )
			
			this.setMaxDuration.timeoutID = setTimeout( () => {
				if( isNaN( this.maxDuration) || this.maxDuration === '' ) this.maxDuration = null
				this.page = 1
				this.initialize()
				this.setMaxDuration.timeoutID = null
			}, 500)
		},


		sort( propName ) {
			if( this.sortBy === propName ) this.sortAsc = !this.sortAsc
			else {
				this.sortBy = propName
				this.sortAsc = false
			}

			this.initialize()
		},



		selectSession( session ) {
      this.selectedSession = session
      this.$refs.detailsModal.open()
    },


    closeDetails() {
      this.selectedSession = null
      this.$refs.objectTable.deselect()
    },

	}

}
</script>



<style scoped>
.bigDigits {
	font-size: 2em;
	font-weight: bold;
}
</style>