import Exim from '@/models/Exim.js';



class Location extends Exim {


	// Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
	vueID = 0


	scheme = null
	domain = null
	port = null
	path = null
	query = null


	checkDomain() {

		if( !this.domain ) return true

		const encoded = encodeURIComponent( this.domain )
		if( this.domain === encoded ) return true
		else return false
	}


	checkPath() {

		if( !this.path ) return true

		const encodedTokens = []
		const tokens = this.path.split( '/' )
		for( let token of tokens ) encodedTokens.push( encodeURIComponent( token ) )
		const encodedPath = encodedTokens.join( '/' )

		if( this.path === encodedPath ) return true
		else return false
	}


	checkQuery() {

		if( !this.query ) return true

		const encodedPairs = []
		const pairs = this.query.split( '&' )
		for( let pair of pairs ) {
			const encodedTokens = []
			const tokens = pair.split( '=' )
			for( let token of tokens ) encodedTokens.push( encodeURIComponent( token ) )
			encodedPairs.push( encodedTokens.join( '=' ) )
		}
		const encodedQuery = encodedPairs.join( '&' )

		if( this.query === encodedQuery ) return true
		else return false
	}


	/**
	 * 
	 * @param {boolean|null} defaultHTTPS - TRUE: default to 'https://' | FALSE: default to 'http://' | NULL: default to 'any://'
	 * @returns 
	 */
	getFQDN( defaultHTTPS = null ) {
		var scheme = this.scheme
		if( scheme === null && defaultHTTPS !== null ) {
			if( defaultHTTPS ) scheme = 'https'
			else scheme = 'http'
		}

		return `${ scheme ? scheme : 'any' }://${ this.domain ? this.domain : '*' }${ this.port ? ':' + this.port : '' }/${ this.path ? this.path : '' }${ this.query ? '?'+this.query : '' }`
	}



	static import( obj ) {
		const item = super.import( obj )
		item.vueID = ++Location.idCounter
		return item
	}



	export() {
		const data = {
			scheme: this.scheme || null,
			domain: this.domain || null,
			port: this.port || null,
			path: this.path, // empty-string is different from null
			query: this.query || null,
		}

		return data
	}


}
export default Location