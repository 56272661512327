<template>
<div class="mt-1">

  <OrdersSummaryByTime v-if='modeInternal === "summary"'
    :aggregateBy="'month'"
    @gotoMonth="(year, month) => goto('monthly', month, year)" />

  <OrdersSummaryByAffiliate v-if='modeInternal === "summary"'
    @gotoAffiliate="affID => goto('monthly', null, null, affID)" />

  <OrdersSummaryByProduct v-if='modeInternal === "summary"'
    @gotoProduct="affID => goto('product')" />

  <AggregateOrdersPane v-if='modeInternal === "monthly"'
    @back="modeInternal = 'summary'"
    @gotoFindOrders="orderState => goto('all', orderState)" :month="month" :year="year" :affiliateID="affiliateID" />
</div>
</template>


<script>
import AggregateOrdersPane from './AggregateOrdersPane.vue'
import OrdersSummaryByTime from './OrdersSummaryByTime.vue'
import OrdersSummaryByAffiliate from './OrdersSummaryByAffiliate.vue'
import OrdersSummaryByProduct from './OrdersSummaryByProduct.vue'


export default {
  name: 'OrdersPane',


  props: {
    mode: {
      type: String,
      default: 'summary'
    },
  },

  components: {
    AggregateOrdersPane,
    OrdersSummaryByTime,
    OrdersSummaryByAffiliate,
    OrdersSummaryByProduct,
  },

  data() {
    return {
      modeInternal: 'all',
      orderState: '',
      month: null,
      year: null,
      affiliateID: null,
    }
  },

  created() {
    this.modeInternal = this.mode
  },

  methods: {

    goto(mode, ...args) {
      console.debug(args)
      if(mode == 'all') this.orderState = args[0]
      else if(mode == 'monthly') {
        this.month = args[0]
        this.year = args[1]
        this.affiliateID = args[2]
      }

      this.modeInternal = mode
    },

  },



}
</script>
