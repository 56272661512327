class ActionItem {

	constructor(id = null) {
		this.id = id
		this.hostID = null
		this.hostType = ''
		this.name = null
		this.details = null
		this.dateDue = null
		this.dateCompleted = null
		this.completedByUserID = null
	}



	get dateDueString() {
		if(!this.dateDue) return null;
		return this.dateDue.toLocaleString();
	}
	set dateDueString(value) {
		if(value) value = new Date(value);
		else this.dateDue = null;

		if( (value instanceof Date) && !isNaN(value.valueOf()) ) this.dateDue = value;
	}

	get dateCompletedString() {
		if(!this.dateCompleted) return null;
		return this.dateCompleted.toLocaleString();
	}
	set dateCompletedString(value) {
		if(value) value = new Date(value);
		else this.dateDue = null;

		if( (value instanceof Date) && !isNaN(value.valueOf()) ) this.dateDue = value;
	}

	get dueTimestamp() { return this.dateDue ? Math.round(this.dateDue.getTime() / 1000) : null }
	get completedTimestamp() { return this.dateCompleted ? Math.round(this.dateCompleted.getTime() / 1000) : null }
	get isPastDue() { return this.dateDue && this.dateDue.getTime() < Date.now() && !this.isComplete }


	needsAttention(inDays = 7) {
		if(this.isComplete) return false

		var targetDate = new Date()
		targetDate.setDate(targetDate.getDate() + inDays)
		if(this.dateDue - targetDate <= 0) return true
		else return false
	}



	get isComplete() { return (this.dateCompleted === null) ? false : true }
	set isComplete(value) { if(value) this.complete(); else this.uncomplete() }

	complete() { this.dateCompleted = new Date() }
	uncomplete() { this.dateCompleted = null }



	static import(obj) {
		let item = new ActionItem();
		for(let property in obj) item[property] = obj[property];

		item.dateDue = obj.dateDue ? new Date(obj.dateDue*1000) : null
		item.dateCompleted = obj.dateCompleted ? new Date(obj.dateCompleted*1000) : null

		return item
	}

	export() {
		let data = {};

		for(let property in this) data[property] = this[property];
		data.dateDue = this.dueTimestamp
		data.dateCompleted = this.completedTimestamp

		return data
	}
}
export default ActionItem
