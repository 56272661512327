<template>
<section class="flex-row flex-align-stretch" style="position: relative;" :style="{'width': width}">
  <SearchDropdown v-if="multiselect"
    :selectedItems="selectedCohorts"
    :placeholder="placeholder"
    :DisplayFunction="displayFunction"
    :InitialValue="null"
    :ObjKey="'id'"
    :width="'100%'"
    :browseIcon="true"
    :pages="pages"
    :externalResults="items"
    :showSettingsButton="true"
    :dropUp="dropUp"
    @selected="item => select(item)"
    @remove="item => remove(item)"
    @search="(txt, pg) => getList(txt, pg)"
    @none="select(null)"
    @toggleSettings="showSettings = !showSettings"
    ref="search"
  />
  <SearchDropdown v-else
    :placeholder="placeholder"
    :DisplayFunction="displayFunction"
    :InitialValue="displayFunction(cohort)"
    :ObjKey="'id'"
    :width="'100%'"
    :browseIcon="true"
    :pages="pages"
    :externalResults="items"
    :showSettingsButton="true"
    :dropUp="dropUp"
    @selected="item => select(item)"
    @search="(txt, pg) => getList(txt, pg)"
    @none="select(null)"
    @toggleSettings="showSettings = !showSettings"
    ref="search"
  />

  <div v-if="showSettings" id="settingsPane" :class="{ 'above' : dropUp }">
    <div class="bold">Include:</div>
    <div class="mt-05"><input type="radio" id="all" :value="null" v-model="isArchived" /><label for="all">All Cohorts</label></div>
    <div class="mt-05"><input type="radio" id="unarchived" :value="false" v-model="isArchived" /><label for="unarchived">Current (un-archived) only</label></div>
    <div class="mt-05"><input type="radio" id="archived" :value="true" v-model="isArchived" /><label for="archived">Archived only</label></div>
  </div>
</section>
</template>


<script>
import CohortAPI from '@/api/CohortAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Cohort from '@/features/Cohorts/Cohort.js'
import SearchDropdown from '@/components/utilities/SearchDropdown.vue'

export default {
  name: 'CohortSearchDropdown',

  components: {
    SearchDropdown,
  },

  props: {
    cohort: {
      type: [Object, Array],
      default: null
    },

    width: {
      type: String,
      default: '50%'
    },

    customerIDs: {
      type: Array,
      default: () => []
    },

		externalGetter: {
			type: Function,
			default: null
		},


    placeholder: {
      type: String,
      default: 'Search Cohorts'
    },

    multiselect: {
      type: Boolean,
      default: false
    },

		dropUp: {
			type: Boolean,
			default: false
		},

  },


  model: {
    prop: 'cohort',
    event: 'update'
  },



  data() {
    return {
      selectedCohorts: [],
      items: [],
      pages: 1,
      showSettings: false,
      isArchived: false,
    }
  },



  watch: {
    // cohort() { this.initialize() },
    isArchived() { this.showSettings = false },
  },


  created() { this.initialize() },



  methods: {

    initialize() {
      if( !this.multiselect ) return
      if( !Array.isArray( this.cohort ) ) this.cohort = [ this.cohort ]
      
      this.selectedCohorts = [ ...this.cohort ]
      // this.$emit( 'update', this.selectedCohorts )
    },

    select( item ) {
      if( this.multiselect ) {
        if( !item ) return
        for( var c of this.selectedCohorts ) if( c.cohortID == item.cohortID ) return

        this.selectedCohorts.push( item )
        this.$emit( 'update', this.selectedCohorts )

      } else {
        this.$emit('update', item);
      }
    },

    remove( item ) {
      this.selectedCohorts = this.selectedCohorts.filter( c => c.cohortID !== item.cohortID )
      const outputCohorts = [ ...this.selectedCohorts ]
      this.$emit( 'update', outputCohorts )
    },


    clear() { this.$refs.search.clear() },

    displayFunction(obj) {
      if(!obj) return '';
      return `${ obj.cohortName }`
    },


    async getList( searchTerm, page = 1 ) {

      var data = null
      if( this.externalGetter ) {

				data = await this.externalGetter( searchTerm, page, 25 )

			} else {

				const req = new PaginatedRequest( null, null, page, 25 )
        req.searchString = searchTerm
        req.customerIDs = [ ...this.customerIDs ]
        req.isArchived = this.isArchived

        data = await CohortAPI.searchCohorts( req )

			}
      
      this.items = [];
			if ( !data || !data.data || !data.data.length ) return

      this.pages = data.pages
      for(let item of data.data) this.items.push( Cohort.import( item ) );
    },
  }


}
</script>



<style scoped>
#settingsPane {
  position: absolute;
  top: 2em;
  left: 0.5em;
  padding: 1.5em;
  background: #fff;
  z-index: 2;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  box-shadow: 0px 5px 20px -5px #555;
  white-space:nowrap;
}

#settingsPane.above {
  top: initial;
	bottom: 2em;
}
</style>
