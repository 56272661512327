<template>
<section>
  <div class='flex justify-center' v-if='showSpinner'>
    <div class='icon-spinner4 spin-loader'></div>
  </div>

  <div v-if='!showSpinner'>
    <div class="flex-row flex-justify-end flex-align-center py-05">
      <button class="button inline-block" @click="newItem">New Landing Page</button>
    </div>

    <div v-if='items.length'>
      <ObjectTable
        class='font-small'
        :Source='items'
        :Columns='columns'
        :Numbered='false'
        :SortBy='sortBy'
        :SortAsc='sortAsc'
        Duplicatable
        Deletable
        @duplicate="item => duplicateItem( item )"
        @delete='deleteItem'
        @edit="editItem"
        @sort='selectSortBy'
        ref="objectTable"
      />
    </div>
    <div v-else class="NoResults">No results</div>

  </div>

  <LandingPageDetailsModal :landingPage="currentItem" :isNew="isNew" @itemAdded="item => itemAdded(item)" @itemEdited="item => itemEdited(item)" @close="itemClosed()" ref="detailsModal" />

</section>
</template>



<script>
import ObjectTable from '@/components/utilities/ObjectTable';
import LandingPageDetailsModal from './LandingPageDetailsModal.vue';

import LandingPage from './LandingPage.js';
import SalesManagementAPI from '@/api/SalesManagementAPI.js';


export default {
  name: 'LandingPageList',


  components: {
    ObjectTable,
    LandingPageDetailsModal,
  },

  data() {
    return {
      items: [],
      currentItem: null,
      columns: [
        {
          displayName: 'id',
          propertyName: 'id',
          sortable: true
        },
        {
          displayName: 'Label',
          propertyName: 'adminLabel',
          sortable: true
        },
        {
          displayName: 'URL Slug',
          propertyName: 'urlSlug',
          sortable: true
        },
        {
          displayName: 'Used By Affiliates',
          propertyName: 'affiliates',
          sortable: true,
          displayFunction: function( item ) {
            return item.affiliates ? item.affiliates.length : 0
          },
        },
      ],

      sortBy: 'id',
      sortAsc: true,
      showSpinner: false,
      isNew: false,
    }
  },

  async created() {
    this.initialize();
  },


  methods: {

    async initialize() {
      this.showSpinner = true;
      this.items = [];
      let res = await SalesManagementAPI.getLandingPages();
      for(let item of res) this.items.push(LandingPage.import(item));
      this.showSpinner = false;
    },


    duplicateItem( item ) {
      this.isNew = true
      this.currentItem = item.clone()
      this.currentItem.id = 0
      this.currentItem.urlSlug = ''
      this.currentItem.adminLabel = ''
      this.$refs.detailsModal.open()
    },


    editItem(item) {
      this.isNew = false;
      this.currentItem = item;
      this.$refs.detailsModal.open()
    },


    async deleteItem(item) {
      let yes = confirm(`Really delete Landing Page '${item.adminLabel}' (${item.urlSlug})?`);
      if(!yes) return;

      let index = 0;
      for(let obj of this.items) {
        if(obj.id == item.id) break;
        index++;
      }
      this.items.splice(index, 1);
      if(!item.id) return;

      await SalesManagementAPI.deleteLandingPage(item.id);
    },


    newItem() {
      this.isNew = true;
      this.currentItem = new LandingPage();
      this.$refs.detailsModal.open()
    },


    itemAdded(addedItem) {
      this.currentItem = addedItem;
      this.items.push(addedItem);
      this.isNew = false;
    },


    itemEdited(changedItem) {
      this.currentItem = changedItem;

      for(let i=0; i<this.items.length; i++) {
        if(this.items[i].id != changedItem.id) continue;
        this.$set(this.items, i, changedItem)
        return;
      }
    },


    itemClosed() {
      this.currentItem = null
      this.$refs.objectTable.deselect()
    },

    selectSortBy(prop) {
      if (prop == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = prop;
      }
    }
  },



}
</script>



<style scoped>

</style>
