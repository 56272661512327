<template>
  <transition name='fade'>
    <div v-if='showModal || showInternal'
      class='pp-modal-overlay'
      :class='overlayClasses'
      @click.self='clickClose()'
    >
      <div id="xrayIcon" class="icon-eye" :class="{'xray': xray}" @mouseover.self="xray = true" @mouseout.self="xray = false" />
      <div class='pp-modal-body-container' :class='containerClasses' :style='containerStyles'>
				<header class="flex-row flex-justify-between" :class='headerClasses' :style="headerStyles">
					<div class="pl-05" style="width: calc(100% - 12em);"><slot name="header"></slot></div>
          <div>
            <i v-if="fullscreen" class="icon-enlarge pp-modal-button" @click="isFullscreen = !isFullscreen" />
            <i v-if='closeIcon' class='icon-cross pp-modal-button' @click='close()'></i>
          </div>
				</header>

				<div class='pp-modal-body' :class="{'xray' : xray}" style="overflow: auto; max-height: 100%; max-width: 100%;" :style='bodyStyles'>
					<slot :closed="close"></slot>
        </div>

				<footer><slot name="footer"></slot></footer>
      </div>

    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',

  props: {
    showModal: {
      type: Boolean,
      default: false
    },

		unsavedChanges: Boolean,

    clickToClose: {
      type: Boolean,
      default: true
    },

    closeIcon: {
			type: Boolean,
			default: true
		},

    scrollable: {
			type: Boolean,
			default: true
		},

    minWidth: {
      type: String,
      default: null,
    },

    width: {
			type: String,
			default: '80%'
		},

    maxWidth: {
      type: String,
      default: '100%'
    },

    minHeight: {
      type: String,
      default: null
    },

    height: {
      type: String,
      default: 'auto'
    },

    maxHeight: {
      type: String,
      default: '99vh'
    },

    padding: {
      type: String,
      default: '0'
    },

    borderColor: {
      type: String,
      default: null
    },

    headerColor: {
      type: String,
      default: null
    },

    dark: Boolean,
    darkBackground: {
      type: Boolean,
      default: false
    },

    blur: {
      type: Boolean,
      default: false
    },

    fullscreen: {
      type: Boolean,
      default: true
    },

  },

  
  data() {
    return {
      showInternal: false,
      isFullscreen: false,
      xray: false,
    }
  },


  computed: {
    overlayClasses() {
      return {
        "pp-modal-centered": !this.scrollable,
        "dark-overlay": this.darkBackground,
        "blur-overlay": this.blur && !this.xray,
        "xray": this.xray,
      };
    },

    containerClasses() {
      return {
        "pp-modal-dark": this.dark,
        "pp-modal-light": !this.dark,
        "fullscreen": this.isFullscreen,
        "xray": this.xray,
      }
    },

    headerClasses() {
      return {
        "pp-modal-header": this.$scopedSlots.header,
        "xray": this.xray,
      }
    },

    headerStyles() {
      const styles = {}
      if( this.headerColor ) styles.backgroundColor = this.headerColor
      return styles
    },

    containerStyles() {
      const styles = {};
      if (this.minWidth) styles.minWidth = this.minWidth;
      if (this.width) styles.width = this.width;
      if (this.maxWidth) styles.maxWidth = this.maxWidth;
      if (this.minHeight) styles.minHeight = this.minHeight;
      if (this.height) styles.height = this.height;
      if (this.maxHeight) styles.maxHeight = this.maxHeight;

      if( this.isFullscreen ) {
        styles.minWidth = '100vw'
        styles.width = '100vw'
        styles.maxWidth = '100vw'
        styles.height = '100vh'
        styles.minHeight = '100vh'
        styles.maxHeight = '100vh'
      }

      if( this.borderColor ) {
        styles.border = `3px solid ${this.borderColor}`
      }

      return styles;
    },

    bodyStyles() {
      const style = {}
      if( this.padding != '0' ) {
        style.padding = this.padding
      }
      return style
    },
  },


  watch: {
    showModal(value) { this.showInternal = value },
  },


  created() { this.showInternal = this.showModal },


  methods: {
    open() {
      this.showInternal = true
    },

    clickClose() {
      if ( !this.clickToClose ) return
      this.showInternal = false
      this.isFullscreen = false
      this.$emit('close');
    },

    close( emit = true ) {
      this.showInternal = false
      this.isFullscreen = false
			if( emit ) this.$emit('close');
    },
  }
}
</script>

<style scoped>
#xrayIcon {
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  font-size: 1.5rem;
  padding: 0.5em 0.75em;
  border-bottom-right-radius: 0.25em;
}
#xrayIcon.xray { background: red; }

.pp-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  display: flex;
  justify-content: center;
}
.dark-overlay {
  background-color: rgba(0,0,0, 0.8) !important;
}
.blur-overlay {
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}
.pp-modal-overlay.xray { background: rgba(0,0,0, 0.1); }

.pp-modal-body-container {
  margin: auto;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 5px 20px -5px #555;
	max-height: 100%;
	max-width: 100%;
  border: 1px solid black;

	display: flex;
	flex-direction: column;
}
.pp-modal-body-container.xray {
  background: rgba(255,255,255, 0.2);
}

.fullscreen {
  border-radius: 0 !important;
}

header { margin: 0; }

.pp-modal-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
	border-bottom: 1px solid #ccc;
	box-sizing: border-box;
	margin: 0;
	padding: 0.5em 0;
	font-size: 1.1rem;

  opacity: 1;
  transition: opacity 0.25s;
}
.pp-modal-header.xray {
  opacity: 0;
  transition: opacity 0.25s;
}

.pp-modal-body {
  width: 100%;
  height: 100%;
	max-width: 100%;
	max-height: 100%;
	flex-grow: 1;
  font-size: 1rem;

  opacity: 1;
  transition: opacity 0.25s;
}
.pp-modal-body.xray {
  opacity: 0;
  transition: opacity 0.25s;
}

.pp-modal-centered {
  align-items: center;
}

.pp-modal-scrollable {
  overflow: auto;
}
.pp-modal-dark {
  background-color: var(--dark);
  color: white;
}
.pp-modal-light {
  /* background-color: rgba(255,255,255,0.7);
  backdrop-filter: blur(15px); */
  background-color: white;
  color: #444;
}
.pp-modal-button {
  /* position: absolute;
  top: 0;
  right: 0; */
	font-size: 0.9rem;
  padding: 0.5em;
}
.pp-modal-button:first-child { padding-left: 1em; }
.pp-modal-button:last-child { padding-right: 1em; }
.pp-modal-button:hover {
  color: red;
}
.pp-modal-dark::-webkit-scrollbar-track{--webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); background-color: #000;}
.pp-modal-dark::-webkit-scrollbar{width: 6px;background-color: #363636;}
.pp-modal-dark::-webkit-scrollbar-thumb{background-color: #575757;}

@media screen and (max-width: 480px) {
  .pp-modal-body-container {
    width: 100%;
    max-width: 100% !important;
  }
}

@media screen and (min-width: 481px) {
  .pp-modal-body-container {
    min-width: 30em;
    /* max-width: calc(100vw - 8em); */
  }
}

</style>
