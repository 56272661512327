<template>
<section class="w-100 flex-column flex-align-center" style="max-height: calc( 100vh - 12em ); overflow: auto;">
	
	<TableReportTemplateEditor v-if="template.type === 'table'" :template="template" :dimensions="dimensions">
		<template #save>
			<button class="pillButton" @click="save()">Save</button>
		</template>
	</TableReportTemplateEditor>

	<ThresholdReportTemplateEditor v-else-if="template.type === 'threshold'" :template="template" :dimensions="dimensions">
		<template #save>
			<button class="pillButton" @click="save()">Save</button>
		</template>
	</ThresholdReportTemplateEditor>
	
</section>
</template>



<script>
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import TableReportTemplateEditor from './ReportTemplates/TableReportTemplateEditor.vue'
import ThresholdReportTemplateEditor from './ReportTemplates/ThresholdReportTemplateEditor.vue'

export default {
	name: 'EknoStatReportTemplateEditor',


	components: {
		TableReportTemplateEditor,
		ThresholdReportTemplateEditor,
	},


	props: {
		template: Object,
		dimensions: Array,
	},



	data() {
		return {

		}
	},



	methods: {
		async save() {
			const res = await StudentStatisticsAPI.saveEknoStatReportTemplate( this.template.export() )
			this.template.id = res.data.id
		}
	}

}
</script>



<style scoped>

</style>