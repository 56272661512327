<template>
<section>
	<VueApexCharts v-if="this.histogram" type="bar" :options="chartOptions" :series="series" />
</section>
</template>



<script>
import VueApexCharts from 'vue-apexcharts'
export default {
	name: 'ApexHistogram',

	components: {
		VueApexCharts,
	},


	props: {
		histogram: {
			type: Object,
			default: null
		},
	},


	data() {
		return {
			chartOptions: {},
			series: [],
		}
	},



	watch: {
		histogram() { this.initialize() },
	},



	created() { this.initialize() },



	methods: {

		initialize() {
			if( !this.histogram ) return


			this.chartOptions = {
				chart: {
          // height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          // formatter: function (val) {
          //   return val + "%";
          // },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        
        xaxis: {
					categories: this.histogram.labels,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          },
					title: {
						text: this.histogram.xLabel,
						align: 'center',
						style: {
							color: '#444',
							fontSize: '16pt'
						}
					}
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            // formatter: function (val) {
            //   return val + "%";
            // }
          },
					title: {
						text: this.histogram.yLabel,
						align: 'center',
						style: {
							color: '#444',
							fontSize: '13pt'
						}
					}
        
        },
        
			}

			this.series = [{
				name: this.histogram.yLabel,
				data: this.histogram.counts,
			}]

		}
	}


}
</script>



<style scoped>

</style>