import Exim from '@/models/Exim.js'



class TreeNode extends Exim {


	constructor(id = null, name = '', iconURL = '', type = 'node') {

		super()

		// Tree variables
		this.children = [];
		this.parent = null;
		this.path = null    // If path is provided, it'll be an array

		// Data variables
		this.id = parseInt(id);
		this.name = name;
		this.adminLabel = '';
		this.iconURL = iconURL;
		this.type = type;
		this.titleColor = '';

		// Difficulty & Time requirement
		this.difficulty = 0;
		this.timeToComplete = 0;

		// // Pre-requisites
		// this.Prerequisites = new PrerequisiteArray(this); // [ {Node: LinkedNode, enforced: Boolean}, ... ]

		// Completion tracking variables
		this.completedTimestamp = null; // When this node was "completed"
		this.completedLeafCount = 0; // Recursive total of completed descendents
		this.leafCount = 0; // Recursive total of ALL descendents

		// Time & plan (OPTIONAL & getter-only)
		this.nodeTime = null;               // Seconds spent on this node (total time)
		this.secondsBeforeComplete = null;  // Seconds spent before this node was "completed"
		this.secondsAfterComplete = null;   // Seconds spent after this node was "completed"
		this.isPlanned = null;   // Whether or not this node is in the student's plan

		// Required vs. optional descendents
		this.completedRequiredLeafCount = 0; // Recursive total of required descendents that ARE complete
		this.requiredLeafCount = 0; // Recursive total of descendents that must be complete in order for this node to be complete
		this.countCompletion = true; // Whether or not THIS node counts as a "required" descendent for its parent (if false, exempt from plans & gauges, too)

		// // This array contains any shortcut/symlink-style connections to
		// // remote locations in the Tree. (pointers to *Node objects)
		// this.crosslinks = new CountSet();

		// // The "course variations" to which this Node belongs.
		// this.restrictedVariations = new VariationSet();

		// this.TaxonomyManager = new TaxonomyAssignmentManager(this);

		// Game Mechanics
		this.pointsAwardedOnCompletion = 0;

		// // Collection of ScoreScales (if any) --
		// // reports use this to translate raw scores to the scale(s) used by the test.
		// this.scoreScales = new ScoreScaleNodeSet(this);

		// Whether or not to include this node in student Plans (PlanGraph)
		this.isPlanSource = false;

		// Config Object
		this.Config = null;

		// Display vars (ephemeral -- not saved)
		this.isActiveNode = false;
		this.expanded = false; // Whether or not to show immediate children in the filetree
		this.filterSearchHide = false; // True = do not render node or children, even if expanded.
		this._highlightedComment = null; // The ID of a comment on this node, which has been "selected" and should be rendered with emphasis (border, bgcolor, etc)

		// Persistent display info
		this.hideSelf = false         // Whether or not to hide this node in student view tree
		this.hideChildren = false;    // Whether or not the node's children are permanently hidden.

		// "More Info" section
		this._showMoreInfo = true;    // Whether or not to show Q&A, next/prev lists, etc. (below node)
		this.enableComments = true;   // Whether or not to allow users to leave new comments (Q&A)
		this.showComments = true;     // Show "Comments" tab (showMoreInfo must be TRUE)
		this.showRelated = true;      // Show "Related" tab (showMoreInfo must be TRUE)
		this.showTeacherChat = true;  // Show "teacher chat" tab (showMoreInfo must be TRUE)


		// Admin mode variables.  Tree can be used for selecting a node instead of navigating.
		this.selectMode = false;
		// this.selectModeCallback = null;

		// boolean representing whether "next" button is enabled on node; exposed via getter and setter
		this._nextNode = false;

		// introductory info on folder nodes; exposed via getter and setter
		this._prompt = '';

		// Supplemental Media (equivalent to TestNode.SupplementalMedia)
		this.SupplementalMedia = [];
		this.defaultSupplementalLabel = 'Passages';
		this.supplementalLabel = this.defaultSupplementalLabel;
		this.displaySupplementalLabel = true;


		// Map of all children, keyed by nodeID
		this._treeIndex = null


		// // Event Callbacks
		// this._eventCallbacks = new Map()


		// this.Reports = new ReportArray(this);

		this._scoreAdjustment = null;

		if (parseInt(this.id) === 'NaN') {
				console.error("Invalid TreeNode object (id is null or invalid)");
				console.error(this);
				alert("TreeNode " + this.name + " created with an invalid or null ID");
		}



		// Getter-only (OPTIONAL)
		this.embeddedNodeWatchTimes = null;   // Array of embedded videos' watch times (seconds); As of Oct 2023, used only for CourseLibrary stats.
	}



	get timestamp() {
		return +this.completedTimestamp;
	}

	get completedDateString() {
		return this.completedTimestamp.toLocaleDateString();
	}



	
	/**
     * Get the breadcrumb path of the node, not including the node itself.
     * @param {boolean} tokenized - If true, return the path as an array of Nodes.
     *                              If false, return the path as a string delimited by ' > '
     * @returns the breadcrumb path of the node.
     */
	getPath(tokenized = false) {

		// Operate in non-tree mode (i.e. outside of course)
		if( this.parent === null && Array.isArray( this.path ) ) {
			if( tokenized ) return this.path.slice()
			
			let pathString = ''
			for( var str of this.path ) pathString += str + ' > '
			return pathString
		}


		var node = this;
		var nodeStack = Array();

		while (node.hasParent()) {
			node = node.getParent();
			nodeStack.push(node);
		}

		// remove root node
		nodeStack.pop();

		if (tokenized) {
			nodeStack.reverse();
			return nodeStack;
		}

		let pathString = '';
		while (nodeStack.length > 0) {
			pathString += nodeStack.pop().name + ' > ';
		}
		return pathString;
	}

	getShortPath(maxCrumbs = 3, includeRootNode = true, maxTokenLength = 25) {
		const splitPath = this.getPath( true )
		if (!includeRootNode) splitPath.shift();

		const startIndex = splitPath.length < maxCrumbs ? 0 : splitPath.length - maxCrumbs;
		let shortPath = '';
		for (let i = startIndex; i < splitPath.length; ++i) {
			if (splitPath[i]) {
				let crumb = splitPath[i];
				if (crumb.length >= maxTokenLength) {
					crumb = crumb.substr(0, maxTokenLength - 6).trim() + '...';
				}
				shortPath += crumb;
				if (i < splitPath.length - 1) shortPath += ' > ';
			}
		}
		return shortPath;
	}




	static import(obj) {
		const newObj = super.import(obj)
		if( newObj.completedTimestamp !== null ) newObj.completedTimestamp = new Date( newObj.completedTimestamp * 1000 )
		return newObj
	}



}

export default TreeNode