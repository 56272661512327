<template>
<section>
<StretchModal @close='close()' :closeIcon="true" :width="width" :height="height" ref="modal">
	<template #header>
		Subscription #{{subscription ? subscription.subscriptionID : ''}}
	</template>
	<template #default="{ closed }">
		<SubscriptionDetails v-if="subscription" :subscription="subscription" @saved="newSub => $emit('saved', newSub)" @close="closed" ref="details" />
	</template>
</StretchModal>

<ConfirmDialog :confirm="'Close Anyway'" :cancel="'Go Back'" @confirm="confirmClose()" ref="confirmDialog">
	<div>Are you sure?  <b>Unsaved changes will be lost!</b></div>
</ConfirmDialog>
</section>
</template>



<script>
import SubscriptionDetails from './SubscriptionDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
export default {
	name: 'SubscriptionDetailsModal',

	components: {
		SubscriptionDetails,
		StretchModal,
		ConfirmDialog,
	},


	props: {
		subscription: {
			type: Object,
			default: null
		},
		width: {
			type: String,
			default: '80%'
		},
		height: {
			type: String,
			default: '80%'
		},
	},


	data() {
		return {

		}
	},


	methods: {
		open() {
			this.$refs.modal.open()
		},

		close() {
			if(this.$refs.details.changesMade) {
				this.$refs.confirmDialog.open()
				return
			}

			this.$emit('close')
		},

		confirmClose() {
			this.$refs.confirmDialog.close()
			this.$emit('close')
		},

		cancelClose() {
			this.$refs.confirmDialog.close()
		},

	}
}
</script>