import Exim from '@/models/Exim.js'



class ReportTemplate extends Exim {


	id = null
	type = 'report'
	name = null


	// Optional parameters
	timeWindow = null       // last n seconds preceding the current time or endTime
	startTime = null
	endTime = null
	


	// No-op; override if needed
	initialize() {}
	updateOutputVariableName( dimension, newName, oldName ) {}


}

export default ReportTemplate