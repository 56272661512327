import API from "./API";
import {base_url} from './../Config';
import License from "@/models/License";



export default class LicenseAPI extends API {


	static async getTeacherCohortLinks(licenseKeys = []) {
		let url = `${base_url}/ManagerDashboardAPI/getTeacherCohortLinks`;
		return await this.sendJSON(url, licenseKeys);
	}


	static async getLicense( licenseKey, userID = null ) {
		let url = `${base_url}/LicensesStandardAPI/getlicense/${licenseKey}`;
    if(userID) url += `/${userID}`;
		
		const data = await super.get( url );
		return License.import( data );
	}


	static async addLicense(license, sendEmail = false) {

		let url = base_url + '/SiteAdmin/LicensesAPI/addLicense';
		if(sendEmail) url += '/' + sendEmail;
		let res = await super.add(url, license.export());

		return License.import(res);
	}

	static async editLicense(license, sendEmail = false) {

		let url = base_url + '/SiteAdmin/LicensesAPI/editLicense/' + license.serialKey;
		if(sendEmail) url += '/' + sendEmail;
		let res = await super.edit(url, license.export());
		return License.import(res);
	}



	static async setExpirationDate(licenseKey, timestamp) {
		return await super.get(`${base_url}/SiteAdmin/LicensesAPI/setExpirationDate/${licenseKey}/${timestamp}`)
	}



	static async assignRoleOnLicenses(userID, roleID, licenseKeys) {
		if(!userID) throw new Error("Required 'userID' is missing")
		if(!userID) throw new Error("Required 'roleID' is missing")
		const url = `${base_url}/SiteAdmin/LicensesAPI/assignRoleOnLicenses/${userID}/${roleID}`;
		return await this.sendJSON(url, licenseKeys);
	}

	static async assignLicensesToOrder(orderID, lineItemIndex, licenseKeys = []) {
		if(!orderID) throw new Error("Required 'orderID' is missing");
		if(lineItemIndex === null || lineItemIndex === undefined) throw new Error("Required 'lineItemIndex' is missing");
		const url = `${base_url}/SiteAdmin/LicensesAPI/assignLicensesToOrder/${orderID}/${lineItemIndex}`;
		return await this.sendJSON(url, licenseKeys);
	}

	static async assignLicensesToCustomer(customerID, licenseKeys) {
		if(!customerID) throw new Error("Required 'customerID' is missing");
		const url = `${base_url}/SiteAdmin/LicensesAPI/assignLicensesToCustomer/${customerID}`;
		return await this.sendJSON(url, licenseKeys);
	}

	static async assignLicensesToMaster(masterID, licenseKeys) {
		// if(!masterID) throw new Error("Required 'masterID' is missing");
		const url = `${base_url}/SiteAdmin/LicensesAPI/assignLicensesToMaster/${masterID ? masterID : ''}`;
		return await this.sendJSON(url, licenseKeys);

	}

	static async deleteLicense(license) {
		let url = base_url + '/SiteAdmin/LicensesAPI/deleteLicense/' + license.serialKey;
		return await super.delete(url);
	}

	static async generateKey() {
		const url = `${base_url}/SiteAdmin/LicensesAPI/generateKey`;
		return await super.get(url);
	}

	static async sendLicenseRegistrationEmail(userID, licenseKey) {
		const url = `${base_url}/SiteAdmin/LicensesAPI/sendNewLicenseEmail/${userID}/${licenseKey}`;
		await super.get(url);
	}

	static async getPushReportPreferences(userID) {
		const url = `${base_url}/ProgressReportAPI/getPushReportPreferences/${userID}`;
		return await super.get(url);
	}

	static async setPushReportPreferences(userID, payload) {
		const url = `${base_url}/ProgressReportAPI/setPushReportPreferences/${userID}`;
		await super.edit(url, payload);
	}

	static async getPushReportDeliverySettings(userID = null, licenseKey = null) {
		const url = `${base_url}/ProgressReportAPI/getPushReportDeliverySettings/${userID ? userID : ''}/${licenseKey ? licenseKey : ''}`;
		return await super.get(url);
	}

	static async setPushReportDeliverySettings(userID, payload) {
		const url = `${base_url}/ProgressReportAPI/setPushReportDeliverySettings/${userID}`;
		await super.edit(url, payload);
	}


	static async manualSendPushReport( timescale, userID, licenseKey ) {
		const url = `${base_url}/ProgressReportAPI/manualSendPushReport/${timescale}/${userID}/${licenseKey}`;
		return super.get(url)
	}


	static async getAllLicenseUsers( licenseKey ) {
		let url = `${base_url}/SiteAdmin/LicensesAPI/getAllLicenseUsers/${licenseKey}`
		return super.get( url )
	}


	static async addUserToPushReports( licenseKey, userID ) {
		let url = `${base_url}/SiteAdmin/LicensesAPI/addUserToPushReports/${licenseKey}/${userID}`
		return super.get( url )
	}


	static async removeUserFromPushReports( licenseKey, userID ) {
		let url = `${base_url}/SiteAdmin/LicensesAPI/removeUserFromPushReports/${licenseKey}/${userID}`
		return super.get( url )
	}
}
