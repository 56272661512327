import API from '@/api/API.js'
import PaginatedResponse from '@/api/PaginatedResponse.js'


export default class MessageAPI extends API {

  static controllerName = 'MessageAPI';

  static async getScheduledRegistrationEmails( paginatedRequest ) {
    const res = await this.newPost( 'getScheduledRegistrationEmails', [], paginatedRequest )
    return PaginatedResponse.import( res.data )
  }

  static async getScheduledRegistrationEmailSummary( paginatedRequest ) {
    const res = await this.newPost( 'getScheduledRegistrationEmailSummary', [], paginatedRequest )
    return PaginatedResponse.import( res.data )
  }

  static async unscheduleRegistrationEmails( timestamp, customerID, userID ) {
    await this.newGet( 'unscheduleRegistrationEmails', [ timestamp, customerID, userID ] )
  }
}