<template>
<section>
  <OrderInvoice v-if="cleanOrderID && order" :order="order" :customer="customer" />
  <p v-else>Invalid order number</p>
</section>
</template>

<script>
import OrdersAPI from '@/api/OrdersAPI.js';
import SalesManagementAPI from '@/api/SalesManagementAPI.js';

import Order from './Order.js';
import Customer from '@/features/SalesManagement/Customers/Customer.js';

import OrderInvoice from './OrderInvoice.vue';

export default {
  name: 'InvoiceViewer',

  components: {
    OrderInvoice,
  },

  props: {
    orderID: {
      type: [Number, String],
      default: 0
    }
  },

  data() {
    return {
      order: null,
      customer: null,
    }
  },

  created() {
    this.initialize();
  },

  computed: {
    cleanOrderID() { return this.orderID ? parseInt(this.orderID) : 0 },
  },


  methods: {
    async initialize() {
      if(!this.orderID) return null;

      let promises = [];

      const res = await OrdersAPI.getOrder(this.cleanOrderID);
      this.order = Order.import(res);

      // Customer
      if(this.order.customerID) {
        let customerPromise = SalesManagementAPI.getCustomer(this.order.customerID).then(res => {
          this.customer = Customer.import(res);
        })
        promises.push(customerPromise);
      }

      Promise.allSettled(promises).then( () => {
        this.$forceUpdate();
      })
    },


  }


}
</script>
