<template>
  <div>
    <table class="table">
      <tr>
        <th class="table-header">ID</th>
        <th class="table-header">Name</th>
        <th class="table-header">Description</th>
      </tr>
      <tr v-for="(variation, index) in Variations"
        :key="index"
        class="table-row">
        <td class="table-data">{{variation.id}}</td>
        <td class="table-data">{{variation.name}}</td>
        <td class="table-data">{{variation.description}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'variations-list',

  props: {
    Variations: Array
  }
}
</script>
