class MessageTimeline {


	rows = null



	constructor() {
		this.rows = new Map()
	}



	addMessage( message ) {

		if( !message.sendTime ) return

		
		const tsMinute = Math.floor( message.sendTime.getTime() / 60000 ) // js timestamp: ms -> minutes
		
		
		var timelineItem = null
		if( this.rows.has( tsMinute ) ) timelineItem = this.rows.get( tsMinute )
		else {
			timelineItem = new TimelineItem ( message.sendTime )
			this.rows.set( tsMinute, timelineItem )
		}

		if( message.deliveryMethod === 'email' ) timelineItem.email.push( message )
		else if( message.deliveryMethod === 'sms' ) timelineItem.sms.push( message )
		else if( message.deliveryMethod === 'app' ) timelineItem.app.push( message )
	}



	getRows() {
		const rows = []
		for( var [key, value] of this.rows ) rows.push( value )

		rows.sort( function( a, b ) {
			return b.timestampMinute - a.timestampMinute
		})
		
		return rows
	}


}

export default MessageTimeline




class TimelineItem {

	timestampMinute = 0
	date = null
	email = []
	sms = []
	app = []

	constructor( date ) {
		this.date = date
		this.timestampMinute = Math.floor( date.getTime() / 60000 ) // js timestamp: ms -> minutes
	}

	get numEmailsDelivered() {
		return this.email.map( function( email ) {
			if( !email.to || !email.to.length ) return 0
			for( const to of email.to ) if( to.status === 'delivered' ) return 1
			return 0
		}).reduce( (prev, curr) => prev + curr, 0)
	}
	
	get numEmailsOpened() {
		return this.email.map( function( email ) {
			if( !email.to || !email.to.length ) return 0
			for( const to of email.to ) if( to.opens && to.opens.length > 0 ) return 1
			return 0
		}).reduce( (prev, curr) => prev + curr, 0)
	}

	getDate() {
		return this.date
	}

}



