<template>
	<div v-if="editor" ref="tiptap">
		<div class="controls-bar flex-row flex-wrap">
			<div class="flex-row controls-row">
				<div>
					<div v-if="savedInternal" class="icon-checkmark saveStatus" style="color: green" />
					<div v-else class="icon-spinner2 saveStatus rotate" /> 
				</div>
				<div class="separator"/>

				<div class="tt-button icon-undo" @click="editor.commands.undo()"/>
				<div class="tt-button icon-redo" @click="editor.commands.redo()"/>
				<div class="separator"/>
				
				<!-- Font Type -->
				<div class="dropdown-wrapper">
					<div @click="menu === 'font' ? menu = '' : menu = 'font'" :class="{ 'drop-active': menu === 'font', 'button-wrapper': true }">
						<div class="drop-button icon-font"/><div class="dropdown-button"><div class="down-triangle"/></div>
					</div>
					<div v-if="menu === 'font'" @mouseleave="menu = ''" class="options-dropdown">
						<div v-for="font in fontFamilies" :key="font" class="one-line">
							<div @click="editor.chain().focus().setFontFamily(font).run()" :class="{ 'is-active': editor.isActive('textStyle', { fontFamily: font }), 'button-wrapper': true }">
								<span class="font-item" :style="{ fontFamily: font }">{{ font }}</span>
							</div>
						</div>
					</div>
				</div>

				<!-- Font Size -->
				<div class="dropdown-wrapper">
					<div @click="menu === 'size' ? menu = '' : menu = 'size'" :class="{ 'drop-active': menu === 'size', 'button-wrapper': true }">
						<div class="drop-button icon-font-size"/><div class="dropdown-button"><div class="down-triangle"/></div>
					</div>
					<div v-if="menu === 'size'" @mouseleave="menu = ''" class="options-dropdown">
						<div v-for="size in fontSizes" :key="size" class="one-line">
							<div @click="setFontSize(size)" :class="{ 'is-active': editor.isActive('textStyle', { fontSize: size }), 'button-wrapper': true }">
								<span class="font-item">{{ size }}</span>
							</div>
						</div>
					</div>
				</div>

				<!-- Header Size -->
				<div class="dropdown-wrapper">
					<div @click="menu === 'header' ? menu = '' : menu = 'header'" :class="{ 'drop-active': menu === 'header', 'button-wrapper': true }">
						<div class="drop-button icon-text-height"/><div class="dropdown-button"><div class="down-triangle"/></div>
					</div>
					<div v-if="menu === 'header'" @mouseleave="menu = ''" class="options-dropdown">
						<div @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }), 'button-wrapper': true }">
							<h1 class="one-line" style="margin: 0">Heading 1</h1>
						</div>
						<div @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }), 'button-wrapper': true }">
							<h2 class="one-line" style="margin: 0">Heading 2</h2>
						</div>
						<div @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }), 'button-wrapper': true }">
							<h3 class="one-line" style="margin: 0">Heading 3</h3>
						</div>
						<div class="one-line" @click="editor.commands.setNode('paragraph')" :class="{ 'button-wrapper': true, 'is-active': editor.isActive('paragraph') }">
							Normal Text
						</div>
					</div>
				</div>

				<!-- Text Color -->
				<div class="dropdown-wrapper">
					<div :class="{ 'drop-active': menu === 'color', 'button-wrapper': true }">
						<div :style="{ color: currTextColor }" @click="setTextColor(currTextColor)" :class="{ 'drop-button': true, 'icon-text-color': true, 'is-active': editor.isActive('textStyle', { color: currTextColor })}"/>
						<div class="dropdown-button" @click="menu === 'color' ? menu = '' : menu = 'color'">
							<div class="down-triangle"/>
						</div>
					</div>
					<div v-if="menu === 'color'" @mouseleave="menu = ''" class="options-dropdown">
						<input type="color"
							@input="setTextColor($event.target.value)"
							:value="editor.getAttributes('textStyle').color"
							style="margin-bottom: 2px"
						>
						<div v-for="tColor in textColors" :key="tColor" class="flex-row">
							<div :style="{ color: tColor }" @click="setTextColor(tColor)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: tColor })}"/>
							<!-- <div :style="{ color: textColors.darkRed }" @click="setTextColor(textColors.darkRed)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: textColors.darkRed })}"/>
							<div :style="{ color: textColors.red }" @click="setTextColor(textColors.red)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: textColors.red })}"/>
							<div :style="{ color: textColors.orange }" @click="setTextColor(textColors.orange)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: textColors.orange })}"/>
							<div :style="{ color: textColors.yellow }" @click="setTextColor(textColors.yellow)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: textColors.yellow })}"/>
							<div :style="{ color: textColors.lightGreen }" @click="setTextColor(textColors.lightGreen)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: textColors.lightGreen })}"/>
							<div :style="{ color: textColors.green }" @click="setTextColor(textColors.green)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: textColors.green })}"/>
							<div :style="{ color: textColors.lightBlue }" @click="setTextColor(textColors.lightBlue)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: textColors.lightBlue })}"/>
							<div :style="{ color: textColors.blue }" @click="setTextColor(textColors.blue)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: textColors.blue })}"/>
							<div :style="{ color: textColors.purple }" @click="setTextColor(textColors.purple)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: textColors.purple })}"/>
							<div :style="{ color: textColors.black }" @click="setTextColor(textColors.black)" :class="{ 'icon-stop2': true, 'is-active': editor.isActive('textStyle', { color: textColors.black })}"/> -->
						</div>
					</div>
				</div>

				<!-- Highlight Color -->
				<div class="dropdown-wrapper">
					<div :class="{ 'drop-active': menu === 'highlight', 'button-wrapper': true }">
						<div class="drop-button icon-text-color" :style="{ background: currHighlightColor }" @click="toggleHighlight(currHighlightColor)" :class="{ 'is-active': editor.isActive('highlight', { color: currHighlightColor }) }"/><div class="dropdown-button" @click="menu === 'highlight' ? menu = '' : menu = 'highlight'"><div class="down-triangle"/></div>
					</div>
					<div v-if="menu === 'highlight'" @mouseleave="menu = ''" class="flex-row options-dropdown">
						<div v-for="hColor in highlightColors" :key="hColor">
							<div :style="{ background: hColor }" @click="toggleHighlight(hColor)" class="tt-button icon-text-color" :class="{ 'is-active': editor.isActive('highlight', { color: hColor }) }"/>
						</div>
						<div class="icon-blocked tt-button" @click="editor.chain().focus().unsetHighlight().run()"/>
					</div>
				</div>

				<div class="separator"/>

				<div @click="editor.chain().focus().toggleBold().run()" :class="{ 'tt-button': true, 'icon-bold': true, 'is-active': editor.isActive('bold')}"/>
				<div @click="editor.chain().focus().toggleItalic().run()" :class="{ 'tt-button': true, 'icon-italic': true, 'is-active': editor.isActive('italic')}"/>
				<div @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'tt-button': true, 'icon-underline': true, 'is-active': editor.isActive('underline')}"/>
				<div @click="editor.chain().focus().toggleStrike().run()" :class="{ 'tt-button': true, 'icon-strikethrough': true, 'is-active': editor.isActive('strike')}"/>
			</div>

			<div class="flex-row controls-row">
				<div class="flex-row">
					<div @click="setTextAlign('left')" :class="{ 'tt-button': true, 'icon-paragraph-left': true, 'is-active': editor.isActive({ textAlign: 'left' }) }"/>
					<div @click="setTextAlign('center')" :class="{ 'tt-button': true, 'icon-paragraph-center': true, 'is-active': editor.isActive({ textAlign: 'center' }) }"/>
					<div @click="setTextAlign('right')" :class="{ 'tt-button': true, 'icon-paragraph-right': true, 'is-active': editor.isActive({ textAlign: 'right' }) }"/>
					<div @click="setTextAlign('justify')" :class="{ 'tt-button': true, 'icon-paragraph-justify': true, 'is-active': editor.isActive({ textAlign: 'justify' }) }"/>
				</div>

				<div class="separator"/>

				<div @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'tt-button': true, 'icon-list2': true, 'is-active': editor.isActive('bulletList') }"/>
				<div @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'tt-button': true, 'icon-list-numbered': true, 'is-active': editor.isActive('orderedList') }"/>
				<!-- <div @click="editor.chain().focus().setHorizontalRule().run() :class="{ 'tt-button': true, 'icon-pagebreak': true, 'is-active': editor.isActive('horizontalRule') }""/> -->
				<div @click="editor.chain().focus().setBlockquote().run()" :class="{ 'tt-button': true, 'icon-indent-increase': true, 'is-active': editor.isActive('blockquote') }"/>
				<div class="tt-button icon-indent-decrease" @click="editor.chain().focus().unsetBlockquote().run()"/>
				<div class="separator"/>
				<div class="tt-button icon-image" @click="showAddImage()" />
				<div class="tt-button icon-link" @click="showSetLink()" />
				<div class="tt-button icon-blocked" v-if="editor.isActive('link')" @click="editor.chain().focus().unsetLink().run()" />
				
				<div class="separator"/>

				<div class="dropdown-wrapper" @mouseenter="e => t.s(e)" @mouseleave="e => t.h(e)" :tooltip="shortcodesError ? 'Error fetching mail merge tags;\n\nCheck for malformed recipient email addresses' : 'Mail Merge Tags'">
					<div v-if="loadingShortcodes"><span class="icon-spinner4 spin1" /></div>
					<div v-else-if="shortcodesError"><span class="icon-warning color-red" /></div>
					<div v-else @click="menu === 'shortcodes' ? menu = '' : menu = 'shortcodes'" :class="{ 'drop-active': menu === 'shortcodes', 'button-wrapper': true }">
						<div class="drop-button icon-embed2"/><div class="dropdown-button"><div class="down-triangle"/></div>
					</div>
					<div v-if="menu === 'shortcodes'" @mouseleave="menu = ''" class="options-dropdown">
						<div v-if="allowNodeLinkShortcode" class="tt-button pointer" @click="showSetNodeLink()">Node</div>
						<div v-for="(value, key) in allowedShortcodes" :key="key" class="tt-button pointer one-line" @click="addShortcode( key )">{{ value }}</div>
					</div>
				</div>

				<div class="separator"/>
				<div class="tt-button icon-clear-formatting" @click="clearFormatting"/>
			</div>
		</div>


		<BubbleMenu :editor="editor" :tippy-options="{ duration: 100 }" class="flex-row quick-controls">
				<span :style="{ color: currTextColor }" @click="setTextColor(currTextColor)" class="tt-button icon-text-color" :class="{ 'is-active': editor.isActive('textStyle', { color: currTextColor })}"/>
				<span :style="{ background: currHighlightColor }" @click="toggleHighlight(currHighlightColor)" class="tt-button icon-text-color" :class="{ 'is-active': editor.isActive('highlight', { color: currHighlightColor }) }"/>
				<span @click="editor.chain().focus().toggleBold().run()" class="tt-button icon-bold" :class="{ 'is-active': editor.isActive('bold') }"/>
				<span @click="editor.chain().focus().toggleItalic().run()" class="tt-button icon-italic" :class="{ 'is-active': editor.isActive('italic') }"/>
				<span @click="editor.chain().focus().toggleUnderline().run()" class="tt-button icon-underline" :class="{ 'is-active': editor.isActive('underline')}"/>
				<div class="separator"/>
				<div class="tt-button icon-link" @click="showSetLink()" />
				<div class="tt-button icon-blocked" v-if="editor.isActive('link')" @click="editor.chain().focus().unsetLink().run()" />
				<div class="tt-button icon-blocked" />
				<div class="separator"/>
				<span @click="editor.chain().focus().toggleStrike().run()" class="tt-button icon-strikethrough" :class="{ 'is-active': editor.isActive('strike')}"/>
		</BubbleMenu>

		<StretchModal ref="imageModal" padding="1em" width="20em" :fullscreen="false">
			<template #header><span class="icon-image" />&nbsp;Add Image</template>
			<div>Enter the URL of your image:</div>
			<div><input type="text" style="width: 100%; font-size: 1.2em;" v-model="addURL" placeholder="https://..." /></div>
			<div class="flex-row flex-align-between flex-gap-2">
				<div class="color-gray-50 mt-05"><b>Note:</b> To embed an image, drag-and-drop into the editor</div>
				<button class="button mt-1" @click="addImage()">Add Image</button>
			</div>
		</StretchModal>

		<StretchModal ref="linkModal" padding="1em" width="20em" :fullscreen="false">
			<template #header><span class="icon-link" />&nbsp;Add Link</template>
			<div>Enter the URL of your link:</div>
			<div><input type="text" style="width: 100%; font-size: 1.2em;" v-model="addURL" placeholder="https://..." /></div>
			<div><input type="checkbox" v-model="addLinkTarget" />Open in new tab</div>
			<button class="button right mt-1" @click="setLink()">Add Link</button>
		</StretchModal>

		<StretchModal ref="nodeLinkModal" padding="1em" width="20em" :fullscreen="false">
			<template #header><span class="icon-link" />&nbsp;Add Link</template>
			<div>Node ID:</div>
			<div><input type="number" style="width: 100%; font-size: 1.2em;" v-model="addURL" placeholder="example: 12345" /></div>
			<div class="mt-1">Text to display:</div>
			<div><input type="text" style="width: 100%; font-size: 1.2em;" v-model="addNodeText" placeholder="Text to Display" /></div>
			<button class="button right mt-1" @click="setNodeLink()">Add Node Link</button>
		</StretchModal>

		<editor-content :editor="editor" class="editor" />
	</div>
</template>

<script>
import FontFamily from '@tiptap/extension-font-family'
import Heading from '@tiptap/extension-heading'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import { Color } from '@tiptap/extension-color'
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-2'
import { FontSize } from './extension-font-size.js'
import FileHandler from '@tiptap-pro/extension-file-handler'

import StretchModal from '@/components/utilities/StretchModal.vue'

import { powerprep_base_url } from '@/Config.js'
import FilesAPI from '@/api/FilesAPI.js'
import FileUploadHelper from '@/features/FileUploader/FileUploadHelper.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'


export default {
	
	name: 'Tiptap',

	components: {
		BubbleMenu,
		EditorContent,
		StretchModal,
	},

	props: {
		placeholder: {
			type: String,
			default: "Insert text here"
		},
		content: {
			type: [Object, String],
			default: null
		},
		saveDelayMS: {
			type: Number,
			default: 2000
		},
		saved: Boolean,

		allowedShortcodes: {
			type: Object,
			default: () => { return { fname : 'First Name', lname : 'Last Name'} }
		},

		allowNodeLinkShortcode: {
			type: Boolean,
			default: true
		},

		loadingShortcodes: {
			type: Boolean,
			default: false
		},

		shortcodesError: {
			type: Boolean,
			default: false
		},

		// server-side "hostID" to use for uploading files (i.e. emailBlastID in MessageCenter)
		hostID: {
			type: Number,
			default: null
		},

		// "email" | "order" | "customer" | "customerOrg" | "customerInteraction" -- see FileModel.php (on server)
		hostType: {
			type: String,
			default: null
		},
	},



	data() {
		return {
			editor: null,
			headerMenu: false,
			menu: '',
			savedInternal: false,
			saving: false,

			addURL: null,
			addLinkTarget: false,
			addNodeText: null,

			textColors: {
				darkRed: '#c00000',
				red: '#ff0000',
				orange: '#ffc000',
				yellow: '#ffff00',
				lightGreen: '#92d050',
				green: '#00b050',
				lightBlue: '#00b0f0',
				blue: '#0070c0',
				darkBlue: '#002060',
				purple: '#7030a0',
				black: '#000000',
			},

			highlightColors: {
				lightRed: '#ffb0b0',
				orange: '#fdcc93',
				green: '#89ff9b',
				yellow: '#fff690',
				blue: '#84d6ff',
				purple: '#c8b0ff',
			},

			fontFamilies: [
				'Roboto',
				'Lato',
				'Yaldevi',
				'Merriweather',
				'Crimson Text',
				'Monospace',
				'Architects Daughter',
				'Patrick Hand',
				'Satisfy',
				'Bakbak One',
				'Righteous',
				'Cinzel Decorative'
			],

			fontSizes: [
				'8pt',
				'9pt',
				'10pt',
				'11pt',
				'12pt',
				'14pt',
				'18pt',
				'24pt',
				'30pt',
				'36pt',
				'48pt',
				'60pt',
				'72pt',
				'96pt',
			],

			currTextColor: "#ff0000",
			currHighlightColor: "#fff690",
			currFontSize: "12pt",
		}
	},



	computed: {
		t() { return Tooltips },
	},



	watch: {
		saved( value ) { this.savedInternal = this.saved }
	},



	created() {
		this.savedInternal = this.saved
	},


	mounted() {
		this.editor = new Editor({
			extensions: [ 
				BubbleMenu,
				Color,
				FontFamily,
				FontSize,
				Heading.configure({
					levels: [1, 2, 3],
				}),
				Highlight.configure({ multicolor: true }),
				Placeholder.configure({
					placeholder: this.placeholder,
				}),
				StarterKit,
				TextAlign.configure({
					types: ['heading', 'paragraph'],
				}),
				TextStyle,
				Typography,
				Underline,
				Image.configure({ inline: true, allowBase64: true, HTMLAttributes: { style: "max-width: 100%" } }),
				Link.configure({ openOnClick: false }),
				FileHandler.configure({
          allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
          onDrop: (currentEditor, files, pos) => {
						console.debug("DROP!")
            files.forEach(file => {
              const fileReader = new FileReader()

              fileReader.readAsDataURL(file)
              fileReader.onload = async () => {
								
								document.body.classList.add( 'globalWaitCursor' )
								
								// Show "uploading" text
								const startPos = pos
								currentEditor.chain().insertContentAt( startPos, ` <span style="color:green; font-weight: bold;">[ uploading...      ]</span> ` ).focus().run()
								const endPos = currentEditor.state.selection.anchor
								
								try {

									// Create empty file (metadata)
									const { mimeType, data: fileData } = FileUploadHelper.splitBase64URL( fileReader.result )
									const res = await FilesAPI.createEmptyFile( { hostID: this.hostID, hostType: this.hostType, fileName: 'email_embedded_image', mimeType  } )
									const fileID = res.data.id

									// get public URL for image
									const urlData = await FilesAPI.getPublicFilePath( fileID, 'attached_files' )
									if( !urlData.path ) throw new Error("no file URL")
									
									// Upload file chunks
									const chunks = FileUploadHelper.chunkBase64String( fileData )
									var numChunksComplete = 0
									for( const chunk of chunks ) {
										const pct = Math.round( numChunksComplete * 100 / chunks.length ) + '%'
										currentEditor.chain().insertContentAt( { from: endPos - 3 - pct.length, to: endPos - 3 }, pct ).focus().run()
										currentEditor.commands.setTextSelection( 0 )
										await FilesAPI.uploadFileBase64Chunk( fileID, chunk )
										numChunksComplete++
									}
	
									// Insert image tag <img src...>
									currentEditor.chain().insertContentAt( { from: startPos, to: endPos }, `<img src="${powerprep_base_url}/${urlData.path}" />` ).focus().run()

								} catch( e ) {
									currentEditor.chain().insertContentAt( { from: startPos, to: endPos }, '<span style="color:red">ERROR UPLOADING FILE</span>' ).focus().run()
									console.error( e.message )

								} finally {
									document.body.classList.remove( 'globalWaitCursor' )
								}

              }
            })
          },

          onPaste: (currentEditor, files) => {
            files.forEach(file => {
              const fileReader = new FileReader()

              fileReader.readAsDataURL(file)
              fileReader.onload = async () => {

								document.body.classList.add( 'globalWaitCursor' )
								
								// Show "uploading" text
								const startPos = currentEditor.state.selection.anchor
								currentEditor.chain().insertContentAt( startPos, ` <span style="color:green; font-weight: bold;">[ uploading...      ]</span> ` ).focus().run()
								const endPos = currentEditor.state.selection.anchor
								
								try {

									// Create empty file (metadata)
									const { mimeType, data: fileData } = FileUploadHelper.splitBase64URL( fileReader.result )
									const res = await FilesAPI.createEmptyFile( { hostID: this.hostID, hostType: this.hostType, fileName: 'email_embedded_image', mimeType  } )
									const fileID = res.data.id

									// get public URL for image
									const urlData = await FilesAPI.getPublicFilePath( fileID, 'attached_files' )
									if( !urlData.path ) throw new Error("no file URL")
									
									// Upload file chunks
									const chunks = FileUploadHelper.chunkBase64String( fileData )
									var numChunksComplete = 0
									for( const chunk of chunks ) {
										const pct = Math.round( numChunksComplete * 100 / chunks.length ) + '%'
										currentEditor.chain().insertContentAt( { from: endPos - 3 - pct.length, to: endPos - 3 }, pct ).focus().run()
										currentEditor.commands.setTextSelection( 0 )
										await FilesAPI.uploadFileBase64Chunk( fileID, chunk )
										numChunksComplete++
									}
	
									// Insert image tag <img src...>
									currentEditor.chain().insertContentAt( { from: startPos, to: endPos }, `<img src="${powerprep_base_url}/${urlData.path}" />` ).focus().run()

								} catch( e ) {
									currentEditor.chain().insertContentAt( { from: startPos, to: endPos }, '<span style="color:red">ERROR UPLOADING FILE</span>' ).focus().run()
									console.error( e.message )

								} finally {
									document.body.classList.remove( 'globalWaitCursor' )
								}

              }
            })
          },
        }),
			],
		});

		this.editor.on('update', ({ editor }) => {
			this.saveTimeout()
			this.$emit('update')
		});

		if (this.content) {
			this.editor.commands.setContent(this.content);
		}
	},


	beforeUnmount() {
		this.editor.destroy()
	},



	methods: {
		reload() {
			this.editor.commands.setContent(this.content)
		},

		setFontSize(size) {
			this.currFontSize = size;
			this.editor.chain().focus().setFontSize(size).run();
		},

		setTextColor(color) {
			this.currTextColor = color;
			this.editor.chain().focus().setColor(color).run();
		},

		toggleHighlight(highlightColor) {
			this.currHighlightColor = highlightColor;
			this.editor.chain().focus().toggleHighlight({ color: highlightColor }).run();
		},

		setTextAlign(alignment) {
			this.editor.chain().focus().setTextAlign(alignment).run();
		},

		showAddImage() {
			this.addURL = this.editor.getAttributes('img').src
			this.$refs.imageModal.open()
		},

		addImage() {
			if( !this.addURL ) return
			this.editor.commands.setImage({ src: this.addURL })
			
			this.addURL = null
			this.$refs.imageModal.close()
		},

		showSetLink() {
			this.addURL = this.editor.getAttributes('link').href
			this.addLinkTarget = this.editor.getAttributes('link').target || false
			this.$refs.linkModal.open()
		},

		setLink() {
			if( !this.addURL ) return
			const link = { href: this.addURL }
			if( this.addLinkTarget ) link.target = '_blank'

			this.editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink( link )
				.run()

			this.addURL = null
			this.addLinkTarget = false
			this.$refs.linkModal.close()
		},

		showSetNodeLink() {
			this.addURL = null
			this.$refs.nodeLinkModal.open()
		},

		setNodeLink() {
			if( !this.addURL ) return
			const shortcode = `{{ node: ${this.addURL}, text: '${this.addNodeText}' }}`
			this.editor.commands.insertContent( shortcode )

			this.addURL = null
			this.addNodeText = null
			this.$refs.nodeLinkModal.close()
		},

		addShortcode( key ) {
			this.editor.commands.insertContent( `{{ ${ key } }}` )
		},

		clearFormatting() {
			this.editor.commands.clearNodes();
			this.editor.commands.unsetAllMarks();
		},

		saveTimeout() {
			this.savedInternal = false;
			this.saving = true;

			clearTimeout(this.timeoutID);
			this.timeoutID = setTimeout( this.sendContentAsHTML, this.saveDelayMS);
		},

		sendContentAsJSON() {
			this.$emit('sendContentAsJSON', this.editor.getJSON());
		},

		sendContentAsHTML() {
			// console.debug("UPDATING...")
			var html = this.editor.getHTML()
			this.$emit('sendContentAsHTML', html);

			// var tab = '\t';
			// var result = '';
			// var indent = '';

			// html.split(/>\s*</).forEach(function(element) {
			// 		if (element.match( /^\/\w/ )) {
			// 				indent = indent.substring(tab.length);
			// 		}

			// 		result += indent + '<' + element + '>\r\n\r\n';

			// 		if (element.match( /^<?\w[^>]*[^\/]$/ ) && !element.startsWith("input")  ) { 
			// 				indent += tab;              
			// 		}
			// });

			// result = result.substring(1, result.length-3);

			// this.$emit('sendContentAsHTML', result);
		}
	}
}
</script>



<style scoped lang="css">
	
	.editor {
		cursor: text;
	}

	.button-wrapper {
		border-radius: 2px;
		display: flex;
		flex-direction: row;
		align-items: stretch;
	}
	.button-wrapper:hover {
		background-color: rgb(229, 229, 229);
		cursor: pointer;
	}

	.is-active {
		background-color: #eee;
		border-radius: 5px;
	}

	.dropdown-wrapper {
		position: relative;
	}

	.drop-button {
		padding: 4px 2px 4px 4px;
		border-radius: 5px;
	}

	.drop-active {
		background-color: rgb(229, 229, 229);
	}

	.tt-button {
		padding: 4px;
		margin: 0 1px 0 1px;
		border-radius: 5px;
	}
	.tt-button:hover {
		background-color: rgb(229, 229, 229);
	}


	.controls-row {
		align-items: center;
		margin-top: 4px;
	}

	.controls-bar {
		position: sticky;
		top: 0px;
		background-color: white;
		z-index: 10;
		padding: 2px 0 4px 0; 
		border-bottom: 1px solid #ccc;
		/* border-radius: 5px; */
	}

	.quick-controls {
		background-color: white;
		box-shadow: 3px 3px 12px -3px gray;
		border: 1px solid #aaa;
		border-radius: 5px;
		padding: 2px;
		opacity: 0.6;
		transition: opacity 0.5s;
	}

	.quick-controls:hover, .quick-controls:active {
		opacity: 1;
		transition: opacity 0.5s;
	}

	.separator {
		border-right: 3px solid rgb(213, 213, 213);
		height: 1.5em;
		margin-left: 5px;
		margin-right: 5px;
	}

	.dropdown-button {
		/* height: 100%; */
		width: auto;
		padding: 4px 4px 4px 2px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.down-triangle {
		margin: auto;
		border-top: 5px solid lightgray;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
	}
	.down-triangle:hover {
		cursor: pointer;
	}

	.options-dropdown {
		position: absolute;
		background-color: white;
		padding: 0.4em;
		box-shadow: 2px 2px 10px 2px #aaa;
		z-index: 2;
		/* border: 1px solid #aaa; */
		border-radius: 3px;
		top: 24px;
	}

	.font-item {
		padding-left: 3px;
		padding-right: 3px;
	}

	.editor {
		border: 1px solid transparent;
		border-radius: 5px;
		padding: 2px;
	}

	.saveStatus {
		padding-left: 2px;
		padding-right: 2px;
	}
	.saveStatus:hover {
		cursor: default;
	}
		
	.rotate {
		animation: rotation 2s infinite linear;
	}
	@keyframes rotation {
		from {
			transrom: rotate(0deg);
		} to {
			transform: rotate(359deg);
		}
	}

</style>

<style lang="css">

.ProseMirror {
	border: 1px solid #ccc;
	border-radius: 1em;
	margin-top: 0.5em;
	padding: 0.5em;
	min-height: 200px;
}
/* Basic editor styles */
/* .ProseMirror {
	> * + * {
		margin-top: 0.75em;
	}
} */

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
}

mark {
	background-color: #fff690;
	padding: 0.125em 0;
	border-radius: 0.25em;
	box-decoration-break: clone;
}

.ProseMirror p {
	line-height: 1.5em;
}

.ProseMirror > *:first-child {
	margin-top: 0;
}
</style>