import Location from './Location.js';


class Destination extends Location {


	weight = 1



	get weightPercent() { return this.weight * 100 }
	set weightPercent( value ) { this.weight = value / 100 }


	export() {
		const data = super.export()
		data.weight = this.weight
		return data
	}


}
export default Destination