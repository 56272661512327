<template>
  <div>
    <section>
      <label>Quote:</label><br />
      <textarea v-model="newQuote.quote" style="width:100%;" rows="10"/><br /><br />

      <label>Priority:</label>
      <select v-model="newQuote.priority">
        <option value="1">High</option>
        <option value="2">Medium</option>
        <option value="3">Low</option>
      </select>

    </section>

    <section>
      <button class="button" @click="save" :disabled="!changesMade">Save Changes</button>
    </section>
  </div>
</template>

<script>
import LoadingQuote from "@/models/LoadingQuote";
import SettingsAPI from "@/api/SettingsAPI";

export default {
  name: "quote-details",

  props: {
    Quote: Object
  },

  data() {
    return {
      newQuote: new LoadingQuote(),
    }
  },

  computed: {
    changesMade() { return !this.Quote.isSame(this.newQuote); }
  },

  watch: {
    Quote() { Object.assign(this.newQuote, this.Quote); }
  },

  created() {
    Object.assign(this.newQuote, this.Quote);
  },

  methods: {
    async save() {
      try {
        if (this.newQuote.id) {
          // edit quote
          await SettingsAPI.editLoadingQuote(this.newQuote);
          this.$emit('edit', this.newQuote.id, this.newQuote)
        } else {
          // add quote
          let res = await SettingsAPI.addLoadingQuote(this.newQuote);
          this.$emit('add', res)
        }

        this.$emit('close')
      } catch (e) {
        console.error(e);
        alert(`Changes could not be saved: ${e}`);
      }
    }
  }
}
</script>
