import API from './API.js';
import EcommerceAPI from './EcommerceAPI.js'


/**
 * Contains functions to interact with Authorize.net through Accept.js.
 *
 * Many functions in this class require a `cardData` argument. This argument should be an object that contains the following properties:
 *   cardNumber: String
 *   month: Number
 *   year: Number
 *   securityCode: Number
 *   fullName: String
 *   zipCode: Number
 *
 * @extends API
 * @memberof module:API
 */
class AcceptAPI extends API {

  /**
   * @static getOpaqueData - Fetches opaque card data from Accept.js based on the provided card data.
   *
   * @param  {number} userID   a valid user ID
   * @param  {object} cardData    information about a credit card; should contain the following fields:

   * @return {Promise}          A promise that resolves to an Accept.js opaque data object.
   */
  static getOpaqueData(userID, cardData) {
    if (!userID) throw new Error('Missing property "userID".');
    if (!cardData) throw new Error('Missing property "cardData".');

    const authData = {
      clientKey: window.acceptKey,
      apiLoginID: window.acceptLoginID
    };

    const secureData = {
      authData,
      cardData
    };

    return new Promise((resolve, reject) => {
      Accept.dispatchData(secureData, res => {
        if (res.messages.resultCode === 'Error') {

          // Accept.js response contained an error, so reject Promise
          let errorText = '';
          for (let msg of res.messages.message) {
            errorText += `${msg.code}: ${msg.text}\n`;
          }
          reject(errorText);

        } else {

          // Accept.js response was good so far, add payment profile via ekno server
          const dv = res.opaqueData.dataValue;
          const dd = res.opaqueData.dataDescriptor;

          if( !dv || !dd ) {
            reject("Invalid response from Accept.js -- OpaqueData is missing or invalid");
          }

          resolve({ dv, dd });
        }
      });
    });
  }



  /**
   * @static async - Adds the given data as a payment profile with eKnowledge and Authorize.net.
   *
   * @param  {number} userID   a valid user ID
   * @param  {object} cardData a card data object as described in the description for this class
   * @return {object}          An object containing the ID of the newly registered payment profile, as well as the last four digits of the registered credit card number.
   */
  static async addPaymentProfile(userID, cardData) {
    if (!userID) throw new Error('Missing property "userID".');
    if (!cardData) throw new Error('Missing property "cardData".');

    const opaqueData = await this.getOpaqueData(userID, cardData);
    const { dv, dd } = opaqueData;

    try {
      const response = await EcommerceAPI.addPaymentProfile(userID, dv, dd);
      if(!response || !response.status || response.status != 200 || !response.data || !response.data.paymentProfileID) {
        if(response.data && response.data.messageCode && response.data.description) throw new Error(response.data.description);
        else throw new Error("Empty or invalid response from addPaymentProfile");
      }

      return {
        paymentProfileID: response.data.paymentProfileID,
        cardNumber: cardData.cardNumber.substr(-4)
      };
    } catch (error) {
      console.error("Error in addPaymentProfile: " + error);
      throw error;
    }
  }
}


export default AcceptAPI;
