import Exim from "@/models/Exim.js"


class Message extends Exim {


	subject = null
	htmlBody = null
	plainBody = null


	useMailMerge() {
		if( this.htmlBody && this.htmlBody.indexOf( '{{' ) !== -1 ) return true
		if( this.plainBody && this.plainBody.indexOf( '{{' ) !== -1 ) return true
		return false
	}


	hasUnsubscribe() {
		if( this.htmlBody && this.htmlBody.indexOf( '{{unsubscribe' ) !== -1 ) return true
		if( this.htmlBody && this.htmlBody.indexOf( '{{ unsubscribe' ) !== -1 ) return true
		if( this.plainBody && this.plainBody.indexOf( '{{unsubscribe' ) !== -1 ) return true
		if( this.plainBody && this.plainBody.indexOf( '{{ unsubscribe' ) !== -1 ) return true

		return false
	}



	export() {
		const data = super.export()
		data.plainBody = this.plainBody ? this.plainBody.replaceAll( '\n', '&#10;' ) : this.plainBody;
		return data
	}


}

export default Message