class CustomerInteraction {

  constructor() {
    this.id = 0;
    this.customerID = 0;
    this.timestamp = 0;
    this.notes = '';
  }


  get dateString() {
    return this.timestamp.toLocaleString();
  }
  set dateString(value) {
    this.timestamp = new Date(value);
  }



  isSame(other) {
    return JSON.stringify(this.export()) == JSON.stringify(other.export());
  }



  static import(obj) {
    let dto = new CustomerInteraction();

    for(let property in obj) {
      dto[property] = obj[property];
    }

    if(Number.isInteger(dto.timestamp)) dto.timestamp = new Date(dto.timestamp * 1000);
    return dto;
  }



  export() {
    let data = {};
    for(let property in this) data[property] = this[property];
    if(data.timestamp instanceof Date) data.timestamp = Math.floor(data.timestamp.getTime() / 1000)
    return data;
  }

}

export default CustomerInteraction;
