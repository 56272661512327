<template>
<section id="InvoiceWrapper">


  <div id="Header">
    <div id="EknoHeader">
      <div class="flex-row">
        <div style="margin-right: 1em;"><img :src="logoURL" style="max-width: 120px;" /></div>
        <div v-if="order.isProposal">
          <h2>eKnowledge ACT/SAT Prep for Schools</h2>
          <h3 style="margin:0.5em 0;">Proven average improvement:
            <br>ACT: 4-10 points
            <br>SAT: 150-400 points</h3>
          <div>See <a target="_new" href="https://new.eknowledge.com/research">new.eknowledge.com/research</a></div>
        </div>
        <div v-if="!order.isProposal">
          <div>eKnowledge LLC</div>
          <div>2300 Bethelview Road Ste-110-320</div>
          <div>Cumming, GA 30040</div>
          <div>(951) 256-4076</div>
          <div><a href="http://eknowledge.com">http://eknowledge.com</a></div>
        </div>
      </div>
    </div>

    <div id="RightHeader" v-if="!order.isProposal">
      <div id="InvoiceNumber">Invoice # {{order.orderID}}</div>
      <div id="PaidStamp" v-if="order.isPaid" style="margin-left:0.25em;">PAID {{order.paidDateOnlyString}}</div>
    </div>
  </div>

  <div id="SecondaryHeader">
    <div id="InvoiceeInfo" v-if="customer">
      <h2>{{order.isProposal ? 'Proposal For:' : 'Bill To:'}}</h2>
      <div>{{customer.name || ''}}</div>
      <div>{{customer.address || ''}}</div>
      <div>{{customer.city || ''}}, {{customer.state || ''}} {{customer.zip || ''}}</div>
      <div>{{customer.phone || ''}}</div>
    </div>
    <div id="InvoiceeInfo" v-else>
      <h2>{{order.isProposal ? 'Proposal For:' : 'Bill To:'}}</h2>
      <div>{{order.firstName || ''}} {{order.lastName || ''}}</div>
      <div>{{order.emailAddress || ''}}</div>
    </div>

    <div class="flex-row">
      <div class="KeyBlock">
        <div class="KeyLine1">{{order.isProposal ? 'Proposal' : 'Invoice'}} Date:</div>
        <div class="KeyLine2">{{order.isProposal ? creationDate : invoiceDate}}</div>
      </div>
      <div v-if="order.dueDateOnlyString" class="KeyBlock">
        <div class="KeyLine1">Due Date:</div>
        <div class="KeyLine2">{{DueDate}}</div>
      </div>
      <div v-if="!order.isProposal" class="KeyBlock highlight">
        <div class="KeyLine1">Please Pay:</div>
        <div class="KeyLine2">${{order.totalCost ? order.totalCost.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00'}}</div>
      </div>
    </div>
  </div>

  <section id="LineItemTable">

    <div class="tr">
      <div class="th">Item</div>
      <div class="th">License Term</div>
      <div class="th">Each</div>
      <div class="th">Qty</div>
      <div class="th">Total</div>
    </div>

    <div v-for="item in order.lineItems" :key="item.id" :class="{ 'rowGroup' : item.pricingOption && item.pricingOption.itemizeDiscountOnInvoice }">
      
      <div :class="{ 'subGroup' : item.pricingOption && item.pricingOption.itemizeDiscountOnInvoice }">
        
        <!-- Multi-line product title (for multi-line discount display) -->
        <div v-if="item.pricingOption && item.pricingOption.itemizeDiscountOnInvoice" class="tr multiline title">
          <div class="td left">
            <span v-if="item.productNameOverride">{{ item.productNameOverride }}</span>
            <span v-else>{{item.product ? item.product.shortName : item.sku}}</span>
          </div>
        </div>

        <!-- Main row -->
        <div class="tr" :class="{ 'multiline' : item.pricingOption && item.pricingOption.itemizeDiscountOnInvoice }">
          <div class="td left">
            <!-- Product name -->
            <span v-if="item.pricingOption && item.pricingOption.itemizeDiscountOnInvoice">Full Price</span>
            <span v-else-if="item.productNameOverride">{{ item.productNameOverride }}</span>
            <span v-else>{{item.product ? item.product.shortName : item.sku}}</span>
            {{!order.isProposal ? `(${item.transactionType})` : ''}}
            <span v-if="item.pricingOption && item.pricingOption.perUnitPricing">&nbsp;&mdash;&nbsp;
              <span style="white-space:nowrap;">{{item.quantity}} @ {{ salePrice(item) }} each</span>
            </span>
          </div>
          <div v-if="!order.isProposal && item.licenseExpirationDate" class="td">Expires {{ item.licenseExpirationDate.toLocaleDateString() }}</div>
          <div v-else class="td">{{item.pricingOption ? item.pricingOption.termString : ''}}</div>
          
          <!-- Unit price (full price, if discounted) -->
          <div v-if="item.pricingOption && item.pricingOption.itemizeDiscountOnInvoice" class="td numbers">{{ Math.moneyFormat( item.pricingOption.initialFullPrice ) }}</div>
          <div v-else class="td numbers">{{ Math.moneyFormat( item.price ) }}</div>
          
          <div class="td">{{item.quantity}}</div>

          <!-- Total lineItem price (full price, if discounted) -->
          <div v-if="item.pricingOption && item.pricingOption.itemizeDiscountOnInvoice" class="td numbers">
            {{ Math.moneyFormat( item.pricingOption.initialFullPrice * item.quantity ) }}
            <span v-if="item.pricingOption.isSubscription">
              + {{ Math.moneyFormat( item.pricingOption.subscriptionFullPrice * item.quantity ) }} / {{ item.pricingOption.intervalString }}
            </span>
          </div>
          <div v-else class="td numbers">
            {{ salePrice(item, item.quantity) }}
            {{ item.pricingOption && item.pricingOption.isSubscription ? ` + ${ Math.moneyFormat( item.totalSubscriptionPrice ) } / ${item.pricingOption.intervalString}` : '' }}
          </div>
        </div>

        <!-- Discount subtraction (secondary row) -->
        <div v-if="item.pricingOption && item.pricingOption.itemizeDiscountOnInvoice" class="tr multiline">
          <div class="td discount left">{{ item.pricingOption.saleDescription }} ({{ Math.round(( (item.pricingOption.initialFullPrice - item.price) / item.pricingOption.initialFullPrice ) * 100) }}%)</div>
          
          <!-- <div v-if="!order.isProposal && item.licenseExpirationDate" class="td">Expires {{ item.licenseExpirationDate.toLocaleDateString() }}</div>
          <div v-else class="td">{{item.pricingOption ? item.pricingOption.termString : ''}}</div>
          
          <div class="td discount numbers">- {{ Math.moneyFormat( item.pricingOption.initialFullPrice - item.price ) }}</div>
          <div class="td">{{ item.quantity }}</div> -->
          <div></div>
          <div></div>
          <div></div>
          <div class="td discount numbers">- {{ Math.moneyFormat( (item.pricingOption.initialFullPrice - item.price) * item.quantity ) }}</div>
        </div>
      </div>

      <!-- Multiline subtotal -->
      <div v-if="item.pricingOption && item.pricingOption.itemizeDiscountOnInvoice" class="tr multiline subtotal">
        <div class="td"></div>
        <div class="td right bold">Subtotal</div>
        <div class="td numbers">{{ Math.moneyFormat( item.price ) }}</div>
        <div class="td"></div>
        <div class="td numbers"><b>{{ Math.moneyFormat( item.totalPrice ) }}</b></div>
      </div>
    </div>

  </section>

  <div id="Total" :class="{'break' : order.isProposal && !order.customerNotes}">
    {{order.isProposal ? 'You Pay' : 'Total Due'}}: ${{order.totalCost ? order.totalCost.toLocaleString(undefined, {minimumFractionDigits: 2}) : '0.00'}}
  </div>

  <section class="mt-3">
    <div v-for="item in order.lineItems" :key="item.id" class="description">
      <!-- Line Item's DESCRIPTION -->
      <h2>
        <span v-if="item.productNameOverride">{{ item.productNameOverride }}</span>
        <span v-else>{{item.product ? item.product.shortName : item.sku}}</span>
      </h2>
      <div v-if="item.product && item.product.invoiceDescription" v-html="invoiceDescription(item)" />
    </div>
  </section>

  <section class="flex-row-reverse flex-justify-between" :class="{'break' : order.isProposal}">
    <img style="max-width: 100%" :href="`${baseImageURL}/eknoLogoFooter.jpg`" />
    <div v-if="order.customerNotes" id="Notes">
      <div v-html="order.customerNotes" />
    </div>
  </section>

  <div v-if="!order.isProposal" id="FooterSpacer" />
  <div v-if="!order.isProposal" id="Footer">
    <div id="Terms">
      <h4>Terms And Conditions:</h4>
      Invoice payable on receipt.  Payment due on or before the "due date" listed above.
      Upon payment in full, client is granted license(s) for use of the software product(s)
      listed above, for the time period(s) listed above.  <br><br>
      We hope your experience with our products is outstanding.
      Please contact us immediately if you have any questions or concerns.
    </div>
    <div id="Payment">
      <h4>Payment:</h4>
      We accept Checks, Cash, and major Credit Cards.
      For well-qualified customers, we will fulfill an order upon receipt of a Purchase Order.
    </div>
  </div>




  <div v-if="order.isProposal" id="Collateral">
    <h2>eKnowledge PowerPrep Performance</h2>
    <img style="max-width: 100%;" :src="`${baseImageURL}/99th-percentile-graph.png`" />

    <br><br><br><br>

    <h2>The reason our students outperform ALL others</h2>
    <img style="max-width: 100%;" :src="`${baseImageURL}/instructional-time-graph.png`" />
    <div><i>PowerPrep by eKnowledge is the <b>most comprehensive</b> <br>
      ACT/SAT prep program available&mdash;BY FAR!</i></div>
  </div>

</section>
</template>

<script>
import {base_url_image} from '@/Config.js'
// import EcommerceAPI from '@/api/EcommerceAPI.js'
// import Product from '@/models/Product.js'

export default {
  name: 'OrderInvoice',

  props: {
    order: Object,
    customer: {
      type: Object,
      default: null
    },
  },


  data() {
    return {
      products: new Map(),
    }
  },


  created() { this.initialize() },


  computed: {
    creationDate() { return this.order.creationDateOnlyString || 'N/A' },
    invoiceDate() { return this.order.invoiceDateOnlyString || 'N/A' },
    DueDate() { return this.order.dueDateOnlyString || 'N/A' },
    logoURL() { return base_url_image + '/e_globe_cs2.jpg' },
    baseImageURL() { return base_url_image },
  },


  watch: {
    order() { this.initialize() },
  },


  methods: {
    async initialize() {
      // if(!this.order || !this.order.lineItems) return
      //
      // // this.products.clear()
      // for(let item of this.order.lineItems) {
      //   if(this.products.has(item.sku)) continue
      //   console.debug(item)
      //   if(item.product) this.products.set(item.product.sku, item.product)
      //   else {
      //     let data = await EcommerceAPI.getProduct(item.sku)
      //     this.products.set(item.sku, Product.import(data))
      //     item.productIsGlobal = true
      //   }
      //   item.product = this.products.get(item.sku)
      // }
      // this.$forceUpdate()
    },

    salePrice(lineItem, qty = 1) {
      if(!lineItem.pricingOption && lineItem.price === null) return 'ERROR'
      if(lineItem.price !== null) return Math.moneyFormat( lineItem.totalPrice )
      return Math.moneyFormat( qty * lineItem.pricingOption.initialPrice )
    },


    invoiceDescription(lineItem) {
      if(!lineItem || !lineItem.product || !lineItem.product.invoiceDescription) return
      return lineItem.product.invoiceDescription.replace(/\{\{quantity\}\}/gi, lineItem.quantity)
    }


  }

}
</script>

<style scoped>
#InvoiceWrapper {
  padding: 0.25in;
  max-width: 8.5in;
  min-height: 11in;
  margin-left: auto;
  margin-right: auto;

  position: relative;
}

#Header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
#InvoiceNumber {
  font-weight: bold;
  color: var(--ekno-blue);
  font-size: 20pt;
  text-align: right;
}

#PaidStamp {
  font-family: "Arial Black", "Roboto", sans-serif;
  color: red;
  font-size: 26pt;
  text-align: center;
  border: 1px solid red;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0 0.25em;
}

h2 {
  color: var(--ekno-blue);
  margin: 0;
}

h3 {
  margin: 0.5em 0;
}

h4 {
  display: inline;
  text-transform: uppercase;
  font-family: "Arial Black";
  color: var(--ekno-blue);
}

#InvoiceeInfo {
  border-top: 3px solid #f0f3ff;
  flex-grow: 1;
}

#SecondaryHeader {
  /* border-top: 3px solid #f0f3ff; */
  margin-top: 0.35in;
  /* padding-top: 0.25in; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.KeyBlock {
  /* border-left: 6px solid white; */
  margin-left: 6px;
  padding: 1em;
  text-align: center;
  font-family: "Arial Black";
  color: var(--ekno-blue);
  background-color: #f0f3ff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 0.5em;
}
.KeyBlock:last-child { border-right: none;}

.KeyLine1 {
  font-size: 1.2em;
}
.KeyLine2 {
  font-size: 1.4em;
}

.KeyBlock.highlight {
  color: white;
  background-color: var(--ekno-blue);

}


#LineItemTable {
  margin-top: 0.5in;
}

#LineItemTable .tr {
  display: grid;
  grid-template-columns: 1fr 7em 6em 2.5em 8em;
  gap: 10px;
  /* grid-auto-rows: minmax(100px, auto); */
  /* vertical-align: top; */
}

#LineItemTable .th {
  /* padding: 0 1em; */
  vertical-align: middle !important;
  text-align: center;
  font-weight: bold;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #ddd;
}

#LineItemTable .td {
  padding: 1em 0;
  text-align: center;
}


#LineItemTable .subGroup {
  border: 1px solid gray;
  padding: 0 0.25em;
}

#LineItemTable .tr.multiline { padding: 0.25em 0; }
#LineItemTable .multiline .td { padding: 0; }

#LineItemTable .multiline.title {
  font-size: 14pt;
  font-weight: bold;
}
#LineItemTable .multiline:not(.title):not(.subtotal) {
  padding-left: 2em;
}
#LineItemTable .multiline:not(.subtotal) {
  /* border-left: 1px solid gray; */
}
#LineItemTable .multiline.subtotal {
  border-top: 2px solid gray;
  padding: 0.5em;
  margin-bottom: 1em;
}



#LineItemTable .left { text-align: left !important; }
#LineItemTable .right { text-align: right !important; }
#LineItemTable .numbers {
  text-align: right !important;
}

.description {
  font-size: 11pt;
  color: #555;
  line-height: 13pt;
  /* border-bottom: 1px solid #ddd; */
  padding: 2em 1em;
}
.description h2 { margin-top: 0; margin-bottom: 0.5em; }

#LineItemTable .discount {
  color: red;
  /* font-weight: bold; */
}


#Notes { margin-top: 1em; }


#Total {
  margin: 0.25in 0 ;
  text-align: right;
  font-family: "Arial Black";
  color: var(--ekno-blue);
  font-size: 15pt;
}


#FooterSpacer {
  width: 100%;
  height: 15em;
}

#Footer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  margin-right: 0.5in;
  margin-bottom: 0.5in;
}
#Terms {
  max-width: 5in;
  text-align: justify;
  margin-right: 0.15in;
}
#Payment {
  max-width: 3in;
  text-align: justify;
  margin-left: 0.15in;
}

.break {
  page-break-after: always;
}

#Collateral {
  text-align: center;
}
</style>
