<template>
<div class="ma-1 card" :class="{ 'notAllowed' : disabledNodeTypes.includes(node.type), 'pointer' : !disabledNodeTypes.includes(node.type) }" @click.stop="openNode()">
	
	<div class="flex-row flex-align-stretch flex-justify-start flex-gap-05 pa-05">

		<div class="icon mr-05">
			<div :class="nodeIconClass" style="font-size: 24pt; color: var(--ekno-blue);" />
			<div class="center" style="color: #777; font-size: 0.75em;">{{nodeTypeName}}</div>
		</div>

		<div class="w-100">
			<div class="flex-row flex-justify-between flex-align-center w-100"> 
				<span><strong>{{ node.name }}</strong></span>
				<span v-if="node.type === 'question'">
					<span v-if="node.isCorrect()" class="icon-checkmark label correctLabel"></span>
					<span v-else class="icon-cross label incorrectLabel"></span>
				</span>
			</div>
			<div class="nodePath" @mouseenter="t.s($event, 'bottom', 0, 10000, 'left')" @mouseleave="t.h()" :tooltip="ttPath">{{ node.getShortPath(3, false, 40) }}</div>
			<div v-if="node.completedTimestamp != null">completed {{ node.completedTimestamp.toLocaleString() }}</div>
			<!-- <div v-else>NOT COMPLETED</div> -->
			
			<!-- Badges -->
			<div class="flex-row flex-justify-between flex-gap-025 mt-05">
				<div class="flex-row flex-align-start flex-justify-center flex-gap-025">
					<div v-if="node.isPlanned" class="label inPlan">IN PLAN</div>
					<div v-else class="label notInPlan">NOT IN PLAN</div>
					<div v-if="node.embedded" class="label embedded">EMBEDDED</div>
				</div>
				<div v-if="node.duration !== null && node.duration !== undefined"><div><span class="icon-stopwatch" /> {{ Math.hhmmss(node.duration) }}</div><div class="right" style="color: #777; font-size: 0.75em;">duration</div></div>
			</div>
		</div>

	</div>

	<div class="flex-row flex-justify-center flex-align-start flex-gap-05 px-05 pb-05">
		
		<!-- Timings -->
		<div v-if="!node.embedded" class="flex-row flex-align-center flex-gap-05 pl-2 pr-2 py-05 round-05" style="border-left: 1px solid #888; border-right: 1px solid #888; position: relative;">
			<span class="icon-stopwatch" style="font-size: 2rem; position: absolute; left: -0.5em; background: white; padding: 0.25em 0;" />
			<div>
				<div><span class="icon icon-pencil" />&nbsp; {{ Math.hhmmss( node.secondsBeforeComplete ) || '--:--:--' }}</div>
				<div style="padding-bottom: 0.25em; border-bottom: 1px solid black;"><span class="icon icon-aid-kit" />&nbsp; {{ Math.hhmmss( node.secondsAfterComplete ) || '--:--:--' }}</div>
				<div style="margin-top: 0.25em;"><span class="icon icon-clock" />&nbsp; {{ Math.hhmmss( node.nodeTime ) || '--:--:--' }}</div>
			</div>
		</div>

	</div>


	<!-- Video Watch Times -->
	<div v-if="node.watchTime || node.embeddedNodeWatchTimes" class="mt-1 pa-1" style="background-color: #f5f5f5; border-bottom-left-radius: 0.5em; border-bottom-right-radius: 0.5em;">
		
		<div class="flex-row flex-align-center flex-justify-center flex-gap-05 bold" style="font-size: 15pt;">
			<span class="icon-play" style="color: red; font-size: 18pt;" />
			<span>Video Watch Time:</span>
		</div>

		<!-- Summary of watch times -->
		<div v-if="!node.embeddedNodeWatchTimes || node.embeddedNodeWatchTimes.length != 1" class="flex-row flex-justify-center flex-gap-1 pt-05">
			<div>Student Time: <strong>{{ totalStudentVideoTime }}</strong></div> |
			<div>Video Time: <strong>{{ totalPlayheadVideoTime }}</strong></div> |
			<div>Speed: <strong>{{ playbackSpeed }}x</strong></div>
		</div>
		
		<!-- Watch times for embedded videos -->
		<div v-if="node.embeddedNodeWatchTimes && node.embeddedNodeWatchTimes.length > 0" class="flex-row flex-align-start flex-justify-center flex-wrap flex-gap-1">
			<div v-for="obj in node.embeddedNodeWatchTimes" :key="obj.nodeID" class="videoWatchTime">
				<div class="bold" style="padding-bottom: 0.25em;">{{ obj.videoName }}</div>
				<div>Student Time: {{ Math.hhmmss(obj.wallTimeDurationSeconds) || '--:--:--' }}</div>
				<div>Video Time: {{ Math.hhmmss(obj.playheadDurationSeconds) || '--:--:--' }}</div>
			</div>
		</div>
	
	</div>


</div>
</template>



<script>
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

export default {
	name: 'LessonWidget',

	props: {
		node: Object,
	},

	data() {
		return {
			disabledNodeTypes: [],
		}
	},

	computed: {
		t() { return Tooltips },
		ttPath() {
			var tokens = this.node.getPath( true )
			return tokens.join( ' \n\n' ) + '\n\n' + this.node.name
		},

		nodeIconClass() {
			if( this.node.type === 'question' ) return 'icon-question'
			else if( this.node.type === 'test' ) return 'icon-stats-bars'
			else if( this.node.type === 'collection' ) return 'icon-files-empty'
			else if( this.node.type === 'media-audio' ) return 'icon-file-music'
			else if( this.node.type === 'media-image' ) return 'icon-file-picture'
			else if( this.node.type === 'media-video' ) return 'icon-file-play'
			else if( this.node.type === 'media-pdf' ) return 'icon-file-pdf'
			else if( this.node.type === 'media-text' ) return 'icon-file-text2'
			else return 'icon-folder'
		},

		nodeTypeName() {
			if( this.node.type === 'question' ) return 'Question'
			else if( this.node.type === 'test' ) return 'Test'
			else if( this.node.type === 'collection' ) return 'Collection'
			else if( this.node.type === 'media-audio' ) return 'Audio'
			else if( this.node.type === 'media-image' ) return 'Image'
			else if( this.node.type === 'media-video' ) return 'Video'
			else if( this.node.type === 'media-pdf' ) return 'PDF'
			else if( this.node.type === 'media-text' ) return 'Text'
			else return 'Folder'
		},

		totalStudentVideoTime() {
			if( this.node.watchTime !== undefined && this.node.watchTime !== null ) return Math.hhmmss( this.node.watchTime.wallTimeDurationSeconds ) || '--:--:--'
			else if( this.node.embeddedNodeWatchTimes && this.node.embeddedNodeWatchTimes.length ) {
				var total = 0
				for( var watchTimeObject of this.node.embeddedNodeWatchTimes ) { total += watchTimeObject.wallTimeDurationSeconds }
				return Math.hhmmss( total )
			} else return '--:--:--'
				
		},

		totalPlayheadVideoTime() {
			if( this.node.watchTime !== undefined && this.node.watchTime !== null ) return Math.hhmmss( this.node.watchTime.playheadDurationSeconds ) || '--:--:--'
			else if( this.node.embeddedNodeWatchTimes && this.node.embeddedNodeWatchTimes.length ) {
				var total = 0
				for( var watchTimeObject of this.node.embeddedNodeWatchTimes ) { total += watchTimeObject.playheadDurationSeconds }
				return Math.hhmmss( total )
			} else return '--:--:--'
		},

		playbackSpeed() {
			if( this.node.watchTime !== undefined && this.node.watchTime !== null ) return this.node.watchTime.playbackSpeed
			else if( this.node.embeddedNodeWatchTimes && this.node.embeddedNodeWatchTimes.length ) {
				var total = 0
				for( var watchTimeObject of this.node.embeddedNodeWatchTimes ) { total += watchTimeObject.playbackSpeed }
				return Math.round( total * 100 / this.node.embeddedNodeWatchTimes.length ) / 100
			}

			return null
		}

	},



	methods: {
		openNode() {
			if( this.disabledNodeTypes.includes(this.node.type) ) return
			this.$emit('openNode')
		}
	}
}
</script>



<style scoped>
.card {
	border: 1px solid #ddd;
	border-radius: 0.5em;
	background: white;
	transition: border-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.card:hover {
	border-color: var(--ekno-blue);
	transform: scale(1.03);
	transition: border-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.icon {
	color: #888;
}

.nodePath {
	font-size: 0.7em;
	max-width: 450px;
}

.label {
	color: whitesmoke;
	border-radius: 5px;
	padding: 0.35em;
	font-size: 0.75em;
	opacity: 0.75;
}
.correctLabel {
	background: green;
	margin-left: 1em;
}
.incorrectLabel {
	background: darkred;
	margin-left: 1em;
}

.inPlan {
	background: var(--ekno-blue);
}
.notInPlan {
  background: var(--pp10-orange);
}
.embedded {
  /* background: blueviolet; */
	background: var(--pp10-purple);
}


.videoWatchTime {
	margin: 1em 0;
	padding: 0.25em 0.5em;
	border-left: 0.25em solid var(--ekno-blue);
}

.notAllowed {
	cursor: not-allowed;
}
</style>