class LandingPageImageSection {


  // Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
  vueID = 0

  type = 'image'
  minWidth = null


  constructor( name = '', imageName = '', showMobile = true, fullwidth = false ) {
    this.vueID = this.type + ++LandingPageImageSection.idCounter
    this.name = name;
    this.imageName = imageName;
    this.showMobile = showMobile;
    this.fullwidth = fullwidth;
  }



  clone() {
    return LandingPageImageSection.import(this.export());
  }

  isSame(obj) {
    return JSON.stringify(this.export()) == JSON.stringify(obj.export());
  }



  static import(obj) {
    const name = obj.name || '';
    const imageName = obj.imageName || '';
    const section = new LandingPageImageSection(name, imageName);
    if(obj.showMobile !== undefined) section.showMobile = obj.showMobile
    if(obj.fullwidth !== undefined) section.fullwidth = obj.fullwidth;
    if(obj.minWidth !== undefined) section.minWidth = obj.minWidth

    return section;
  }



  export() {
    return {
      type: this.type,
      name: this.name,
      imageName: this.imageName,
      showMobile: this.showMobile,
      fullwidth: this.fullwidth,
      minWidth: this.minWidth,
    };
  }


}


export default LandingPageImageSection;
