<template>
<section>

  <p>ID: {{newItem.id}}</p>

  <div class="form-entry-block">
    <label class='flex-column'>
      <span>First Name:</span>
      <input type="text" v-model="newItem.firstName" />
    </label>
  </div>

  <div class="form-entry-block">
    <label class='flex-column'>
      <span>Last Name:</span>
      <input type="text" v-model="newItem.lastName" />
    </label>
  </div>

  <section class='ActionButtons mt-2'>
    <button class='button mr-1' @click='save' :disabled='!changesMade'> Save Changes</button><br><br>
  </section>

</section>
</template>

<script>
import SalesRepresentative from './SalesRepresentative.js';
import SalesManagementAPI from '@/api/SalesManagementAPI.js';

export default {
  name: "SalesRepresentativeDetails",


  props: {
    itemIn: Object,
    isNew: Boolean,
  },

  data() {
    return {
      newItem: Object,
    }
  },

  async created() {
    this.newItem = this.itemIn.clone();
  },

  computed: {
    changesMade() {
      if(this.newItem && !this.itemIn.isSame(this.newItem)) return true;
      return false;
    }
  },


  methods: {

    async save() {
      if(this.isNew) {
        const id = await SalesManagementAPI.createSalesRepresentative(this.newItem.export());
        const obj = await SalesManagementAPI.getSalesRepresentative(id);
        const newItem = SalesRepresentative.import(obj);
        this.$emit('itemAdded', newItem);
      } else {
        await SalesManagementAPI.editSalesRepresentative(this.newItem.export());
        this.$emit('itemEdited', this.newItem.clone());
      }
    },


  }



}
</script>
