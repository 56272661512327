import API from "./API";



class GenericTagsAPI extends API {


	static controllerName = 'GenericTagsAPI'


	static async getTags( type = '', searchTerm = null ) {
		return await this.newGet( 'getTags', [ type, searchTerm ] )
	}


	static async addTag( type, key, tag ) {
		return await this.newGet( 'addTag', [ type, key, tag ] )
	}


	static async removeTag( type, key, tag ) {
		return await this.newGet( 'removeTag', [ type, key, tag ] )
	}


}

export default GenericTagsAPI