import Exim from '@/models/Exim.js'


class LogRecord extends Exim {


	id = null
	clientIP = null
	rawRequest = null
	requestHeaders = null
	requestFQDN = null
	routeID = null
	destinationFQDN = null
	rawResponse = null
	responseCode = null

	// Getter-only (set automatically in DB)
	timeCreated = null
	timeUpdated = null



	static import( obj ) {
		const item = super.import( obj, [], [ 'timeCreated', 'timeUpdated' ] )
		return item
	}


	export() {
		const data = super.export( [], [ 'timeCreated', 'timeUpdated' ] )
		return data
	}


}

export default LogRecord;