import Exim from "@/models/Exim"
import CourseAdminAPI from '@/api/CourseAdminAPI.js'
import TaxonomyAPI from '@/api/TaxonomyAPI.js'
import Taxonomy from '@/features/CourseCore/Taxonomies/Taxonomy.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'


class EknoStatDimensionQueryVariable extends Exim {


	dim = null;     // The Dimension to which this variable belongs
	
	queryType = null;        // String: 'user' | 'course' -> 'user' involves Tracking/Users/PerNodeTiming/Licenses/Cohorts tables; 'course' uses only MTOCNodes & MTOCClosures

	nodeTypes = null;        // array<NodeType>; null = don't filter.  DNF (multiple items are or'd together)
	embedded = null;         // TRUE: include ONLY embedded; FALSE: include ONLY non-embedded; NULL: include both
	nodeStates = null;       // array<NodeState>: NodeState's to include; null = don't filter.  DNF (multiple items are or'd together)
	field = null;            // MySQL col name
	function = null;         // MySQL aggregate function to apply to 'field'
	aggregateBasis = null;   // MySQL: what to aggregate by: ( 'userID' | 'licenseKey' | 'cohortID' )
	rootNodes = [];          // array<object>: sub-tree(s) to search; empty = don't filter (include entire MTOC); requires $dim->courseID to be set.
	includeTaxItems = [];    // array<taxonomy>; empty = don't filter.  Taxonomy objects include selectedTaxonomyItems array; DNF ( multiple items are or'd together: x || y || z ).
	excludeTaxItems = [];    // array<taxonomy>; empty = don't filter.  Taxonomy objects include selectedTaxonomyItems array; !CNF ( NOT-AND: !x && !y && !z ).


	// Client-side display
	queryPreview = null      // { String query, String types, Array args }
	queryPreviewError = false




	constructor( dimension = null ) {
		super()
		this.dim = dimension
	}



	addNodeType( value ) {

		if( this.nodeTypes === null ) this.nodeTypes = []
		this.nodeTypes.push( value )
	}


	removeNodeType( value ) {

		const idx = this.nodeTypes.indexOf( value )
		if( idx < 0 ) return
		this.nodeTypes.splice( idx, 1 )

		if( this.nodeTypes.length === 0 ) this.nodeTypes = null
	}


	addNodeState( value ) {
		if( this.nodeStates === null ) this.nodeStates = []
		this.nodeStates.push( value )
	}


	removeNodeState( value ) {

		const idx = this.nodeStates.indexOf( value )
		if( idx < 0 ) return
		this.nodeStates.splice( idx, 1 )

		if( this.nodeStates.length === 0 ) this.nodeStates = null
	}




	async initialize( obj ) {
		const courseID = this.dim.courseID
		const promises = []
		

		// Root Nodes
		if( courseID && obj.rootNodeIDs ) {

			const req = new PaginatedRequest('name', true, 1, 0)
			req.courseID = courseID
			req.nodeIDs = []
			for( let nodeID of obj.rootNodeIDs ) req.nodeIDs.push( nodeID )
			
			const rp = CourseAdminAPI.getMatchingNodesMeta( req ).then( res => {
				for( let item of res.data ) this.rootNodes.push( item )
			})

			promises.push( rp )
		}



		// Tax Items
		if( obj.includeTaxItems || obj.excludeTaxItems ) {
			
			
			// Include Taxonomies
			const includeTaxMap = new Map()
			if( obj.includeTaxItems ) for( let item of obj.includeTaxItems ) includeTaxMap.set( item.taxonomyID, null )
			
			const includeTaxReq = new PaginatedRequest('name', true, 1, 0)
			includeTaxReq.courseID = courseID
			includeTaxReq.taxonomyIDs = []
			for( let taxID of includeTaxMap.keys() ) includeTaxReq.taxonomyIDs.push( taxID )
			
			const rp1 = TaxonomyAPI.getMatchingTaxonomies( includeTaxReq ).then( pr => {
				for( let item of pr.data ) {
					let newTax = Taxonomy.import( item )
					newTax.selectedTaxonomyItems = []
					includeTaxMap.set( item.taxonomyID, newTax )
				}
			})
			
			await rp1
			
			// Include Tax Items
			for( let t of obj.includeTaxItems ) {
				let tax = includeTaxMap.get( t.taxonomyID )
				this.includeTaxItems.push( tax )

				const itemReq = new PaginatedRequest(null, true, 1, 0)
				itemReq.courseID = courseID
				itemReq.taxonomyID = t.taxonomyID
				itemReq.taxItemIDs = [ t.taxItemID ]
				
				const rp = TaxonomyAPI.getMatchingTaxonomyItems( itemReq ).then( pr => {
					for( let item of pr.data ) tax.selectedTaxonomyItems.push( item )
				});
			
				promises.push( rp )
				break;
			}
			


			// Exclude Taxonomies
			const excludeTaxMap = new Map()
			if( obj.excludeTaxItems ) for( let item of obj.excludeTaxItems ) excludeTaxMap.set( item.taxonomyID, null )
			
			const excludeTaxReq = new PaginatedRequest('name', true, 1, 0)
			excludeTaxReq.courseID = courseID
			excludeTaxReq.taxonomyIDs = []
			for( let taxID of excludeTaxMap.keys() ) excludeTaxReq.taxonomyIDs.push( taxID )

			const rp2 = TaxonomyAPI.getMatchingTaxonomies( excludeTaxReq ).then( pr => {
				for( let item of pr.data ) {
					let newTax = Taxonomy.import( item )
					newTax.selectedTaxonomyItems = []
					excludeTaxMap.set( item.taxonomyID, newTax )
				}
			})
			
			await rp2
			
			// Exclude Tax Items
			for( let t of obj.excludeTaxItems ) {
				let tax = excludeTaxMap.get( t.taxonomyID )
				this.excludeTaxItems.push( tax )
				
				const itemReq = new PaginatedRequest(null, true, 1, 0)
				itemReq.courseID = courseID
				itemReq.taxonomyID = t.taxonomyID
				itemReq.taxItemIDs = [ t.taxItemID ]

				const rp = TaxonomyAPI.getMatchingTaxonomyItems( itemReq ).then( pr => {
					for( let item of pr.data ) tax.selectedTaxonomyItems.push( item )
				})
			
				promises.push( rp )
			}
			
		}

		await Promise.all( promises )

	}



	static import( obj ) {
		return super.import( obj, [ 'rootNodes', 'includeTaxItems', 'excludeTaxItems' ] )
	}

	export() {
		const data = super.export( [ 'dim', 'rootNodes', 'queryPreview', 'queryPreviewError', 'includeTaxItems', 'excludeTaxItems' ] )

		if( this.rootNodes.length > 0 ) {
			data.rootNodeIDs = []
			for( let item of this.rootNodes ) data.rootNodeIDs.push( item.id )
		}

		data.includeTaxItems = []
		for( let tax of this.includeTaxItems) {
			for( let item of tax.selectedTaxonomyItems ) data.includeTaxItems.push( { taxonomyID: tax.taxonomyID, taxItemID: item.id } )
		}

		data.excludeTaxItems = []
		for( let tax of this.excludeTaxItems ) {
			for( let item of tax.selectedTaxonomyItems ) data.excludeTaxItems.push( { taxonomyID: tax.taxonomyID, taxItemID: item.id } )
		}

		return data
	}

}

export default EknoStatDimensionQueryVariable