<template>
<section>
<h2 class="mt-0 center">{{ report.name }}</h2>

<table class="table">
	<tr>
		<th></th>
		<th v-for="section in report.sections" :colspan="section.columns.length" :key="section.title">{{ section.title }}</th>
	</tr>
	<tr>
		<th></th>
		<template v-for="section in report.sections">
			<th v-for="col in section.columns" :key="col.title">{{ col.title }}</th>
		</template>
	</tr>
	<tr v-for="row in report.rows" :key="row.label">
		<td>{{ row.label }}&nbsp;&nbsp;</td>
		<td v-for="value in row.values" :key="value">{{ value }}</td>
	</tr>
</table>
</section>
</template>



<script>
export default {
	name: 'TableReportViewer',

	props: {
		report: {
			type: Object,
			required: true
		},
	},


	methods: {

	}

}
</script>



<style scoped>

</style>