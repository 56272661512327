<template>
<div id="body" :style="bgStyle">
  <LoginHeader />
  <header>
    <h2 v-if="randomScreen">{{randomScreen.title}}</h2>
  </header>
  <section id="focusBoxWrapper">
    <div id="focusBox">
      <div v-if="loading" id="spinnerWrapper" class="flex-row flex-align-center flex-justify-center">
        <div class='icon-spinner4 spin-loader' style=""></div>
      </div>
      <form @submit.prevent="login">
        <span class="welcomeMessage">Administrator Tools</span>
        <span class="errorMessage mb-1 round-05" :class="{'show': errorMessage !== ''}">{{errorMessage}}</span>
        <input type="email" name="email" v-model="email" placeholder="E-Mail Address" required autofocus />
        <input type="password" name="password" v-model="password" placeholder="Password" required />
        <input type="submit" id="submit" name="submit" value="LOGIN" />
        <div><router-link to="/forgotpassword">Forgot Password?</router-link></div>
      </form>
    </div>
  </section>
</div>
</template>

<script>
import LoginHeader from '@/components/LoginHeader.vue'

import { preloadStore } from '@/basicUtils.js'

import API from '@/api/API.js'
import SettingsAPI from '@/api/SettingsAPI'
import UserAPI from '@/api/UserAPI.js'
import User from '@/features/Users/User.js'
import {base_url} from '@/Config'

export default {

  name: "login",


  components: {
    LoginHeader,
  },


  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      randomScreen: null,
      loading: false,
    }
  },


  computed: {
    bgStyle() {
      if (this.randomScreen) {
        let url = base_url + '/webAssets/content/site_wide_media/images/' + this.randomScreen.imageUrl;
        return {
          background: 'url(' + url + ')',
          backgroundSize: 'cover'
        }
      }
      return {};
    }
  },


  async created() {

    try {
      const loginResponse = await API.login( {} )
      this.$store.state.user = User.import( loginResponse )
  
      const roles = await UserAPI.getRoles()
      this.$store.state.userRoles.reset()
      this.$store.state.userRoles.addRoles( roles )
  
      this.$router.push( { name: 'home' } )

    } catch( e ) {
      console.log( "not logged in" )
    }


    this.randomScreen = await SettingsAPI.getRandomLoginScreen();
  },


  methods: {

    async login() {
      this.loading = true

      try {
        const loginResponse = await API.login( { email: this.email, password: this.password } )
        this.$store.state.user = User.import( loginResponse )
  
        const roles = await UserAPI.getRoles()
        this.$store.state.userRoles.reset()
        this.$store.state.userRoles.addRoles( roles )

        preloadStore( this.$store )

        this.$router.push( { name: 'home' } )

      } catch( error ) {
        if(error && error.response && error.response.status === 403) this.errorMessage = "Invalid username and/or password";
        else if(error && error.response && error.response.status === 418) this.errorMessage = "Too many login attempts!";
        else this.errorMessage = "Sorry, there was a problem logging you in";

        console.error( error.message )

      } finally {
        this.loading = false
      }

    },

  }
}
</script>


<style scoped src="../assets/styles/Login.css" />
<style scoped>
#spinnerWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255,255,255, 0.25);
  backdrop-filter: blur(12px);
  border-radius: 0.5em;
}
.spin-loader {
  font-size: 48px;
  height: 48px;
  width: 48px;
  color: var(--ekno-blue);
}
</style>
