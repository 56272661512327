import API from './API'
import {base_url} from '@/Config'
import EmailTemplate from '@/models/EmailTemplate'
import EmailAddress from '@/models/EmailAddress'

export default class EmailAPI extends API {


  static async getEmails() {
    let url = base_url + '/SiteAdmin/EmailAPI/getEmails';
    let res = await this.get(url);

    if (!res) throw new Error('Error when loading email templates.');

    let emails = [];
    for (let email of res) {
      emails.push(EmailTemplate.import(email));
    }

    return emails;
  }

  static async putEmails(emails) {
    let url = base_url + '/SiteAdmin/EmailAPI/putEmails';
    await this.edit(url, emails.export());
    return true;
  }



  static async getTestAddresses() {
    let url = base_url + '/SiteAdmin/EmailAPI/getTestAddresses';
    let res = await this.get(url);

    if (!res) throw new Error('Error when loading test addresses.');

    let addresses = [];
    for (let addr of res) {
      addresses.push(new EmailAddress(addr));
    }
    return addresses;
  }

  static async putTestAddresses(addresses) {
    let url = base_url + '/SiteAdmin/EmailAPI/putTestAddresses';
    await this.edit(url, addresses.export());
    return true;
  }

  static async sendTestEmail(functionName) {
    let url = base_url + '/SiteAdmin/EmailAPI/sendTestEmail/' + functionName;
    await this.get(url);
  }
}
