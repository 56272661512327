<template>
<section>
	
	<div v-if="mode !== 'overview'" class="flex-row flex-justify-center flex-gap-05 mb-1">
		<div class="nowrap">
			<v-date-picker v-model="startDate" mode="dateTime">
				<template #default="{ inputValue, togglePopover }">
					<button @click="togglePopover">{{ inputValue }}</button>
				</template>
			</v-date-picker>
		</div>
		<div>-</div>
		<div class="nowrap">
			<v-date-picker v-model="endDate" mode="dateTime">
				<template v-slot="{ inputValue, togglePopover }">
					<button @click="togglePopover">{{ inputValue }}</button>
				</template>
			</v-date-picker>
		</div>
	</div>

	<StudentStatisticsOverview v-if="mode == 'overview'" :userID="userID" :licenseKey="license.serialKey" />
	<StudySessionDetails v-if="mode == 'time'" :userID="userID" :licenseKey="license.serialKey" :startDate="startDate" :endDate="endDate" />
	<LessonDetails v-else-if="mode == 'lessons'" :userID="userID" :licenseKey="license.serialKey" :startDate="startDate" :endDate="endDate" />
	<QuestionDetails v-else-if="mode == 'questions'" :userID="userID" :licenseKey="license.serialKey" :startDate="startDate" :endDate="endDate" />
	<VideoDetails v-else-if="mode == 'videos'" :userID="userID" :licenseKey="license.serialKey" :startDate="startDate" :endDate="endDate" />
	<PlanDetails v-else-if="mode == 'plan'" :userID="userID" :license="license" :startDate="startDate" :endDate="endDate" />
	<TargetScoreDetails v-else-if="mode == 'target'" :userID="userID" :licenseKey="license.serialKey" :startDate="startDate" :endDate="endDate" />

</section>
</template>



<script>
import StudentStatisticsOverview from './StudentStatisticsOverview.vue'
import StudySessionDetails from './StudySessionDetails.vue'
import LessonDetails from './LessonDetails.vue'
import QuestionDetails from './QuestionDetails.vue'
import VideoDetails from './VideoDetails.vue'
import PlanDetails from './PlanDetails.vue'
import TargetScoreDetails from './TargetScoreDetails.vue'

export default {
	name: 'StudentStatisticsWrapper',

	components: {
		StudentStatisticsOverview,
		StudySessionDetails,
		LessonDetails,
		QuestionDetails,
		VideoDetails,
		PlanDetails,
		TargetScoreDetails,
	},


	props: {
		userID: Number,
		license: Object,

		mode: {
			type: String,
			default: 'overview'
		},
	},


	data() {
		return {
			startDate: null,
			endDate: null,
			
		}
	},



	created() { this.initialize() },



	methods: {

		initialize() {
			this.setDates()
		},
		
		/**
		 * Set start and end dates.
		 * 
		 * If the license has purchase and/or
		 * expiration dates, these are used as
		 * the start and end datetimes.
		 * 
		 * Else, defaults to most recent 1-month period, 
		 * beginning at midnight on the start day 
		 * and ending at current moment.
		 */
		setDates() {
						
			this.endDate = new Date()
			if( this.license.expirationDate !== null ) this.endDate.setTime( Math.min( this.license.expirationDate.valueOf(), Date.now() ) )

			this.startDate = new Date()
			if( this.license.purchaseDate !== null ) this.startDate.setTime( this.license.purchaseDate.valueOf() )
			else {
				const currMonth = this.startDate.getMonth()
				const month = currMonth == 0 ? 12 : currMonth - 1;
				const year = currMonth == 0 ? this.startDate.getFullYear() - 1 : this.startDate.getFullYear()
				this.startDate.setMonth( month )
				this.startDate.setFullYear( year )
				this.startDate.setHours(0,0,0,0)
			}
		},

	}
}
</script>



<style scoped>

</style>