<template>
	<SearchDropdown v-if="taggedObject"
    :selectedItems="taggedObject.tags"
    :placeholder="placeholder"
		:DisplayFunction="displayFunction"
		:InitialValue="initialValue"
		:ObjKey="'id'"
		:width="width"
		:browseIcon="true"
		:pages="pages"
		:externalResults="items"
		:dropUp="dropUp"
		@selected="(item) => select(item)"
    @remove="item => remove(item)"
		@search="(txt, pg) => getItemList(txt, pg)"
		@confirm="item => confirmSelect(item)"
		@none="select(null)"
		ref="search"
	/>
</template>


<script>
import GenericTagsAPI from '@/api/GenericTagsAPI.js'
import SearchDropdown from "@/components/utilities/SearchDropdown.vue"

export default {
	name: "GenericTagAssignDropdown",



	components: {
		SearchDropdown,
	},



	props: {
		tagType: {
			type: String,
			default: ''     // i.e. 'affiliate' | 'review'
		},

		taggedObject: {   // i.e. an Affiliate or Review object (must have 'tags' attribute: { tags: [] })
			type: Object,
			default: null,
		},

		width: {
			type: String,
			default: '100%'
		},

		placeholder: {
      type: String,
      default: 'Search or Create Tags'
    },

		initialValue: {
			type: String,
			default: null
		},

		dropUp: {
			type: Boolean,
			default: false
		},
	},



	model: {
		prop: "item",
		event: "update",
	},



	data() {
		return {
			selectedItems: [],

			items: [],
			pages: 1,
		};
	},



	methods: {

		confirmSelect( item ) {
			this.select( item )
			this.$refs.search.clear()
		},

		select( item ) {
			this.$emit( "addTag", item )
		},

		remove( item ) {
			this.$emit( "removeTag", item )
		},

    clear() { this.$refs.search.clear() },

		displayFunction( tagString ) {
			if( !tagString ) return ""
			return tagString
		},

		async getItemList( searchTerm, page = 1 ) {
			this.items = await GenericTagsAPI.getTags( this.tagType, searchTerm )
		},
	},
};
</script>
