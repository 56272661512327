import Exim from '@/models/Exim.js'


class PocEmail extends Exim {

	id = null
	pocID = null
	email = null
	label = null

	// client read-only (cannot mutate in DB)
	lastEmailDate = null
	lastEmailID = null



	isSame( other ) {
		if( this.id !== other.id ) return false
		if( this.pocID !== other.pocID ) return false
		if( this.email !== other.email ) return false
		if( this.label !== other.label ) return false
		return true
	}


	static import( obj ) {
		const item = super.import( obj, [ 'lastEmailDate' ] )
		if( obj.lastEmailDate ) item.lastEmailDate = new Date( obj.lastEmailDate * 1000 )
		return item
	}


	export() {
		return super.export( [ 'lastEmailDate' ] )
	}

}

export default PocEmail;