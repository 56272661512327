<template>
<section>

	<ApexChartGraph
		v-if="videoGraph"
		:graph="videoGraph"
		type="bar"
		:stacked="true"
		:colors="['#1cba00', '#b1ff87']"
		:dataLabels="false"
		height="300px"
	/>
	<div v-else-if="loadingVideoGraph" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
  <div v-else-if="videoGraphError" class="warning">Failed to get Video Graph</div>
  <div v-else class="NoResults">No Video Graph</div>

	<ApexChartGraph
		v-if="watchTimeGraph"
		:graph="watchTimeGraph"
		type="bar"
		:stacked="true"
		:colors="['#1cba00', '#b1ff87']"
		:dataLabels="false"
		height="300px"
	/>
	<div v-else-if="loadingWatchTimeGraph" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
  <div v-else-if="watchTimeGraphError" class="warning">Failed to get Watch-Time Graph</div>
  <div v-else class="NoResults">No Watch-Time Graph</div>


	<div v-if="totals" class="my-4">
		<div class="flex-row flex-justify-center flex-align-center flex-gap-2">
			<div v-if="totals.playheadDurationSeconds !== undefined" class="center">
				<div class="bigDigits" v-html="Math.hhmmss(totals.playheadDurationSeconds)" />
				<div>Video Time</div>
			</div>
			watched in
			<div v-if="totals.wallTimeDurationSeconds !== undefined" class="center">
				<div class="bigDigits" v-html="Math.hhmmss(totals.wallTimeDurationSeconds)" />
				<div>Student Time</div>
			</div>
			at
			<div v-if="totals.playbackSpeed !== undefined" class="center">
				<div class="bigDigits" v-html="`${Math.round( totals.playbackSpeed * 100 ) / 100}x`" />
				<div>Avg Speed</div>
			</div>
			=
			<div v-if="totals.watchTimePercent !== undefined" class="center">
				<div class="bigDigits" v-html="`${ totals.watchTimePercent }%`" />
				<div>of Opened Videos<br>({{ Math.hhmmss(totals.availableSeconds) }})</div>
			</div>
		</div>
	</div>

	<LessonsList :userID="userID" :licenseKey="licenseKey" :videosOnly="true" :showTotal="false" :startDate="startDate" :endDate="endDate" />


</section>
</template>



<script>
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import StudentStatisticsRequest from './StudentStatisticsRequest.js'
import StudentStatisticsGraphRequest from './StudentStatisticsGraphRequest.js'
import GraphHelper from './GraphHelper.js'
import TreeNode from '@/features/CourseCore/Classes/TreeNode.js'
import MediaNode from '@/features/CourseCore/Classes/MediaNode.js'
import QuestionNode from '@/features/CourseCore/Classes/QuestionNode.js'

import ApexChartGraph from '@/components/ApexChartGraph.vue'
import LessonsList from './LessonsList.vue'

export default {
	name: 'VideoDetails',


	components: {
		ApexChartGraph,
		LessonsList,
	},


	props: {
		userID: Number,
		licenseKey: String,
		startDate: {
			type: Date,
			default: null
		},
		endDate: {
			type: Date,
			default: null
		},
	},


	data() {
		return {
			videoGraph: null,
			loadingVideoGraph: false,
			videovideoGraphError: false,

			watchTimeGraph: null,
			loadingWatchTimeGraph: false,
			watchTimevideoGraphError: false,

			itemsCompleted: [],
			itemsWorkedOn: [],
			totals: null,
			loadingDetails: false,
			detailsError: false,
		}
	},



	created() { this.initialize() },


	watch: {
		startDate() { this.initialize() },
		endDate() { this.initialize() },
	},


	methods: {
		initialize() {
			this.getVideoGraph()
			this.getWatchTimeGraph()
			this.getTotals()
		},


		async getVideoGraph() {
			this.videoGraph = null
			this.videoGraphError = false
			this.loadingVideoGraph = true
			try {
				const graphReq = new StudentStatisticsGraphRequest( this.userID, this.licenseKey, this.startDate, this.endDate )
				const graphRes = await StudentStatisticsAPI.getVideosGraph( graphReq )
				this.videoGraph = graphRes.data
	
				GraphHelper.generateTimeLabels( this.videoGraph )

			} catch(e) {
				this.videoGraphError = true
				throw e

			} finally {
				this.loadingVideoGraph = false
			}

		},


		async getWatchTimeGraph() {
			this.watchTimeGraph = null
			this.watchTimeGraphError = false
			this.loadingWatchTimeGraph = true
			try {
				const graphReq = new StudentStatisticsGraphRequest( this.userID, this.licenseKey, this.startDate, this.endDate )
				const graphRes = await StudentStatisticsAPI.getVideoWatchTimeGraph( graphReq )
				this.watchTimeGraph = graphRes.data
	
				GraphHelper.generateTimeLabels( this.watchTimeGraph )

			} catch(e) {
				this.watchTimeGraphError = true
				throw e

			} finally {
				this.loadingWatchTimeGraph = false
			}

		},


		async getTotals() {
			const req = new StudentStatisticsRequest( this.userID, this.licenseKey, this.startDate, this.endDate )
			const res = await StudentStatisticsAPI.getVideoWatchTimeTotals( req )
			this.totals = res.data
		}

	}

}
</script>



<style scoped>
.bigDigits {
	font-size: 2em;
	font-weight: bold;
}
</style>