
export const domain = window.ekno_base_url || 'local.powerprep.eknowledge.com';
export const base_url = location.protocol + '//' + domain;
export const powerprep_live_domain = 'app.powerprep10.eknowledge.com';
export const powerprep_live_base_url = 'https://' + 'app.powerprep10.eknowledge.com';


// Regardless of deployed URL, "powerprep_base_url" points to the standard, deployed PowerPrep instance (or local).  Used by "Impersonate" function, to open a new tab.
let _powerprep_base_url = 'https://';

if (window.ekno_base_url) {
  if (window.ekno_base_url.search('dev') !== -1) {
    _powerprep_base_url += 'dev.powerprep10.eknowledge.com';
  } else {
    _powerprep_base_url += 'app.powerprep10.eknowledge.com';
  }
} else {
  _powerprep_base_url += 'local.powerprep.eknowledge.com';
}
export const powerprep_base_url = _powerprep_base_url;

const local_url = 'local.powerprep.eknowledge.com';
export const DEV_MODE = ( base_url == location.protocol + '//' + local_url || base_url.includes('dev') );
export const LIVE_MODE = !DEV_MODE;

// Use Auth.net sandbox for local (developer-machine) deploys ONLY
export const ECOMM_SANDBOX = DEV_MODE;
export const ECOMM_PRODUCTION = !ECOMM_SANDBOX;




export const base_url_audio = "";
export const base_url_icon = "";
export const base_url_image = base_url + "/webAssets/content/site_wide_media/images";
export const base_url_product_image = base_url + "/webAssets/content/site_wide_media/product_images";
export const base_url_affiliate_image = base_url + "/webAssets/content/site_wide_media/affiliate_images";
export const base_url_pdf = "";
export const base_url_text = "";
export const base_url_video = base_url + "/webAssets/content/site_wide_media/videos";
