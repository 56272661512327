<template>
<section
	id="uploader"
	class="flex-row flex-align-center flex-justify-center round-05 pa-05"
	:class="{ 'drag' : dragging }"
	@dragover="ev => dragHandler( ev )"
	@dragleave="dragging = false"
	@dragend="dragging = false"
	@drop="ev => dropHandler( ev )"
	ref="uploader"
>
	<div v-if="!uploading">Drag file(s) here to upload</div>
	<div v-else>
		<div v-for="prog in progressObjects" :key="prog.name" >
			{{ prog.name }}: {{ prog.error ? 'ERROR UPLOADING FILE' : prog.pct }}
		</div>
	</div>
</section>
</template>



<script>
import MediaLibraryAPI from '@/api/MediaLibraryAPI.js'
import FileUploadHelper from './FileUploadHelper.js'

export default {
	name: 'AttachmentFileUploader.vue',


	props: {  
		path: {
			type: Array,
			default: () => []
		}
	},


	data() {
		return {
			dragging: false,
			uploading: false,
			progressObjects: []
		}
	},


	methods: {

		dragHandler( event ) {
			event.preventDefault()
			this.dragging = true
		},


		async dropHandler( event ) {
			
			event.preventDefault()
			this.dragging = false
			
			this.progressObjects = []
			this.uploading = true


			const promises = []

			if( !event.dataTransfer.items ) {
				this.uploading = false
				console.debug( 'no files dropped!' )
				return
			}

			for( const item of [ ...event.dataTransfer.items ] ) {
				if( item.kind !== 'file' ) {
					console.debug( 'item is not a file!' )
					continue
				}

				const file = item.getAsFile()
				
				// Don't upload if file exists! (otherwise, it would APPEND to the existing file!)
				const res = await MediaLibraryAPI.checkFileExists( [ ...this.path, file.name ] )
				if( res.data.exists == true ) {
					alert( `File named "${file.name}" already exists` )
					continue
				}

				promises.push( this.uploadWorker( file ) )
			}

			await Promise.all( promises )
			this.uploading = false
			this.$emit( 'upload' )

		},


		async uploadWorker( file ) {
			return new Promise( (resolve, reject) => {

				const fileReader = new FileReader()
	
				fileReader.readAsDataURL(file)
				fileReader.onload = () => {
						
					// Show "uploading" text
					const progress = { numChunksComplete: 0, numChunks: 0, pct: '0%', name: file.name, error: false }
					this.progressObjects.push( progress )
		
					// File path
					let path = this.path.join('/')
					if( this.path.length > 0 ) path += '/'

					// Create empty file (metadata)
					const { data: fileData } = FileUploadHelper.splitBase64URL( fileReader.result )
					
					// Prepare to upload
					const chunks = FileUploadHelper.chunkBase64String( fileData )
					progress.numChunks = chunks.length
					progress.numChunksComplete = 0
					
					const chunkPromises = []

					// Upload file chunks
					const uploadChunk = ( idx ) => {
						if( idx >= chunks.length ) {
							resolve()
							this.$emit( 'upload' )
							return
						}

						const chunk = chunks[idx]

						const p = MediaLibraryAPI.uploadFileBase64Chunk( path + file.name, chunk )
						chunkPromises.push( p )
						p.then( () => {
								progress.numChunksComplete++
								progress.pct = Math.round( progress.numChunksComplete * 100 / progress.numChunks ) + '%'
								uploadChunk( idx + 1 )
							})
						.catch( (e) => {
							console.error( e.message )
							progress.error = true
							reject()
						})
						
					}

					uploadChunk( 0 )
	
				}

			})

		},


	}

}
</script>



<style scoped>
#uploader {
	background-color: var(--pp10-gray-60);
	color: var(--pp10-gray-90);
	min-height: 50px;
}

#uploader.drag {
	background-color: var(--ekno-blue-40);
	color: var(--ekno-blue-80);
}
</style>