<template>
<section>

	<div class="StickySaveButton">
		<button class="pillButton" @click="save()" :class="{ 'disabled' : !changesMade }" :disabled="!changesMade">
			Save Changes
		</button>
	</div>

	<!-- <h1 class="section-header">Basic Information</h1> -->
	<div class="form-entry-block">
		<label class='flex-column'>
			<span>Title (Shows on Landing Pages):</span>
			<input type="text" v-model="newStorefront.title" />
		</label>
	</div>
	<div class="form-entry-block">
		<label class='flex-column'>
			<span>URL slug:</span>
			<input type="text" v-model="newStorefront.slug" />
		</label>
	</div>


	<!-- Title & Subtitle Display Options -->
	<div class="my-2 pl-05 border-left">
		<div class="bold mb-05">Title & Subtitle Display</div>
		<div class="mt-05 flex-row flex-align-center flex-gap-05">
			<ToggleButton v-model="newStorefront.showTitle" :sync="true" />
			<span>Show storefront title</span>
		</div>
		<div v-if="newStorefront.showTitle" class="ml-1 mt-05 flex-row flex-align-center flex-gap-05">
			<ToggleButton v-model="newStorefront.showSlashes" :sync="true" />
			<span>Show decorative slashes around storefront title</span>
		</div>
		
		<div class="mt-1 flex-row flex-align-center flex-gap-05">
			<ToggleButton v-model="newStorefront.showGroupTitle" :sync="true" />
			<span>Show Product Group Title</span>
		</div>
		<div class="mt-05 flex-row flex-align-center flex-gap-05">
			<ToggleButton v-model="newStorefront.showGroupDescription" :sync="true" />
			<span>Show Product Group Description</span>
		</div>
	</div>


	<!-- Pricing display options -->
	<div class="my-2 pl-05 border-left">
		<div class="bold mb-05">Price Settings</div>
		<div class="mt-05 flex-row flex-align-center flex-gap-05"><ToggleButton v-model="newStorefront.showComparePrograms" :sync="true" /> Show "Compare All Programs" button &amp; popup</div>
		<div class="mt-05 flex-row flex-align-center flex-gap-05"><ToggleButton v-model="newStorefront.quickSelectPrices" :sync="true" /> Show price selector on cards (quick select)</div>
	</div>
	
	<!-- Slider Mode -->
	<div class="my-2 pl-05 border-left">
		<div class="bold mb-05">Slider Mode Settings</div>
		<div class="flex-row flex-align-center flex-gap-05"><ToggleButton v-model="newStorefront.sliderMode" :sync="true" /> Slider Mode</div>
		<div class="ml-1 mt-05 flex-row flex-align-center flex-gap-05"><ToggleButton v-model="newStorefront.openByDefault" :sync="true" :disabled="!newStorefront.sliderMode" /> Open by default</div>
		<div v-if="newStorefront.sliderMode && newStorefront.openByDefault" class="ml-1 mt-05">
			Default Slide: 
			<select v-model="newStorefront.defaultSlideIndex">
				<option v-for="( pg, idx ) in newStorefront.productGroups" :key="pg.vueID" :value="idx">{{ pg.adminLabel || pg.title }}</option>
			</select>
		</div>
	</div>

	<!-- Default Coupon -->
	<div class="my-2 pl-05 border-left">
		<div class="bold mb-05">Coupon Code Settings</div>
		<div class="flex-row flex-align-center flex-gap-05"><ToggleButton v-model="newStorefront.autofillDefaultCoupon" :sync="true" /> Autofill default coupon</div>
		<div class="my-05 flex-row flex-align-center flex-gap-05"><ToggleButton v-model="newStorefront.showCouponCode" :sync="true" /> Show Coupon Code</div>
		<div class="mt-05">Default Coupon Code: <input type="text" v-model="newStorefront.defaultCoupon" /></div>
	</div>


	<h1 class="section-header">Product Groups</h1>
	<div v-for="(pg, index) in newStorefront.productGroups" :key="pg.vueID" class="relative flex-row">
		<span style="position:absolute; right: 1em; top: 1em;" class="icon-bin" @click="removeProductGroup(index)" />

		<div class="mr-1">
			<div @click.prevent="newStorefront.swapProductGroups(index, index-1)" class="icon-arrow-up" />&nbsp;
			<div @click.prevent="newStorefront.swapProductGroups(index, index+1)" class="icon-arrow-down" />
		</div>

		<div class="DetailSection flex-grow">

			<div class="flex-row flex-align-center flex-gap-1">

				<label class='flex-column flex-grow'>
					<span>Admin Label:</span>
					<input type="text" v-model="pg.adminLabel" />
					<div class="font-size-0-8">Only visible to admins</div>
				</label>

				<label class='flex-column flex-grow'>
					<span>Product Group Title:</span>
					<input type="text" v-model="pg.title" />
					<div class="font-size-0-8">displayed to customers</div>
				</label>

			</div>

			<div class="mt-1">
				<label class='flex-column'>
					<span>Product Group Description:</span>
					<textarea v-model="pg.description" rows="5" />
					<div class="font-size-0-8">displayed to customers</div>
				</label>
			</div>

			<!-- Settings -->
			<div class="my-2">
				<label class='flex-column'>
					<span><input type="checkbox" v-model="pg.showPriceToggle" />Show price toggle switch (i.e. subscription or one-time purchase) - switches between first-listed and second-listed pricing option for EACH product in this group</span>
				</label>

				<div v-if="pg.showPriceToggle" class="ml-2 mb-2">
					<div>
						Default toggle position: 
						<button @click.stop="pg.priceTogglePositionDefault = false" class="clusterButton" :class="{ 'selected' : pg.priceTogglePositionDefault == false }">Left</button>
						<button @click.stop="pg.priceTogglePositionDefault = true" class="clusterButton" :class="{ 'selected' : pg.priceTogglePositionDefault == true }">Right</button>
					</div>
					<div>Left Label:  <input v-model="pg.priceToggleLeftLabel" /></div>
					<div>Right Label: <input v-model="pg.priceToggleRightLabel" /></div>
				</div>
			</div>

			<!-- Each Product in ProductGroup -->
			<h3 class="section-header">Products</h3>
			<section v-for="(prod, idx) in pg.products" :key="prod.sku" class="flex-row flex-align-start flex-justify-start mb-1">
				<div class="mr-1">
					<div @click.stop="newStorefront.swapProducts(pg, idx, idx-1)" class="icon-arrow-up" />&nbsp;
					<div @click.stop="newStorefront.swapProducts(pg, idx, idx+1)" class="icon-arrow-down" />
				</div>
				<div>
					<div class="productName flex-row flex-align-center flex-gap-05" :class="{'highlight' : pg.highlightProductSKU === prod.sku }">
						<span @click.stop="toggleHighlight( pg, prod )" class="highlightButton" :class="{ 'selected' : pg.highlightProductSKU === prod.sku }" @mouseenter="t.s($event, 'top-right', 0, 10000, 'left')" @mouseleave="t.h()" tooltip="Highlight this Product"><span class="icon-sun" /></span>
						<div>{{prod.name}} ({{prod.sku}})</div>
						<div class="icon-bin" @click.stop="removeProduct(pg, prod)" />
						<div class="link ml-05" @click.stop="editProduct( prod, pg )">edit product</div>
					</div>
					<div v-for="po in prod.pricingOptions" :key="po.id" class="ml-1">
						<input type="checkbox" :checked="newStorefront.pricingOptionAvailable(prod.sku, po.id)" @input="togglePO(prod.sku, po.id, $event.target.checked)">
						{{po.name}} {{po.priceString}}
					</div>
				</div>
			</section>
			<hr>
			<select v-model="selectedProductSKU">
				<option value="" :selected="!selectedProductSKU">SELECT PRODUCT</option>
				<option v-for="prod in $store.state.Products" :key="prod.sku" :value="prod.sku">{{prod.name}} [{{prod.sku}}]</option>
			</select>
			<button class="" @click.stop="addProduct(pg)"><span class="icon-plus" /> Add Product</button>

		</div>
	</div>
	<button class="" @click.stop="addProductGroup()"><span class="icon-plus" /> Add Product Group</button>


	<!-- Edit product details -->
	<StretchModal ref="productModal" :clickToClose="false" :padding="'1em'">
		<template #header>Product Details</template>
		<ProductDetails v-if="selectedProduct" :Product="selectedProduct" :EditMode='true' @change="p => updateProduct( p )" />
	</StretchModal>

</section>
</template>

<script>
import ProductDetails from '@/features/Products/ProductDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import { ToggleButton } from 'vue-js-toggle-button'

import Storefront from './Storefront.js'
import StorefrontAPI from '@/api/StorefrontAPI.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

export default {
	name: "StorefrontDetails",

	components: {
		ProductDetails,
		StretchModal,
		ToggleButton,
	},

	props: {
		storefrontIn: Object,
		isNew: Boolean,
	},

	data() {
		return {
			storefront: Object,
			newStorefront: Object,
			selectedProductSKU: '',
			changeCounter: 0,

			selectedProduct: null,
			selectedProductGroup: null,
		}
	},

	async created() {
		if(this.isNew) {
			this.newStorefront = this.storefrontIn;
		} else {
			let res = await StorefrontAPI.getStorefront(this.storefrontIn.slug);
			this.storefront = Storefront.import(res);
			this.newStorefront = this.storefront.clone();
			this.$forceUpdate();
		}
	},

	computed: {
		
		t() { return Tooltips },

		changesMade() {
			// this.changeCounter;
			// if(!this.storefront || !this.newStorefront) return false;
			// if(typeof this.storefront !== 'Storefront') return false;
			// if(typeof this.newStorefront !== 'Storefront') return false;
			// let changesMade = !this.storefront.isSame(this.newStorefront);
			// return changesMade;
			return true;
		}
	},


	methods: {

		addProduct(prodGroup) {
			let product = this.$store.state.Products.findByAttribute('sku', this.selectedProductSKU);
			if(!product) return;
			prodGroup.addProduct(product);
			console.debug(prodGroup.products);
		},

		removeProduct(prodGroup, prod) {
			let yes = confirm(`Really remove product ${prod.sku}?`);
			if(!yes) return;

			prodGroup.removeProduct(prod);
			console.debug(prodGroup.products);
		},

		addProductGroup() {
			this.changeCounter++;
			this.newStorefront.addProductGroup();
		},

		removeProductGroup(index) {
			let prodGroup = this.newStorefront.productGroups[index];
			let yes = confirm(`Really remove product group '${prodGroup.title}'?`);
			if(!yes) return;

			this.newStorefront.removeProductGroup(index);
		},


		togglePO(sku, poID, value) {
			this.changeCounter++;
			if(value) this.newStorefront.addAvailablePricingOption(sku, poID);
			else this.newStorefront.removeAvailablePricingOption(sku, poID);
		},

		toggleHighlight( productGroup, product ) {
			
			if( productGroup.highlightProductSKU === product.sku ) {
				productGroup.highlightProductSKU = null
				return
			}

			productGroup.highlightProductSKU = product.sku
		},

		async save() {
			if(this.isNew) {
				const data = await StorefrontAPI.createStorefront(this.newStorefront.export());
				this.storefront.id = data.id
				this.newStorefront.id = data.id
				this.$emit( 'create', data.id )
			} else {
				await StorefrontAPI.editStorefront(this.newStorefront.export());
			}
		},


		editProduct( product, group ) {
			this.selectedProduct = product
			this.selectedProductGroup = group
			this.$refs.productModal.open()
		},

		updateProduct( newProduct ) {
			const idx = this.selectedProductGroup.products.indexOf( this.selectedProduct )
			if( idx < 0 ) return

			this.$set( this.selectedProductGroup.products, idx, newProduct )
			this.selectedProduct = newProduct
		}


	}



}
</script>


<style scoped>
.clusterButton {
	padding: 0.5em;
	border: 1px solid #888;
}
.clusterButton:first-of-type {
	border-top-left-radius: 0.5em;
	border-bottom-left-radius: 0.5em;
}
.clusterButton:last-of-type {
	border-top-right-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
}
.clusterButton.selected {
	background: #777;
	background: var(--ekno-blue);
	color: white;
}
.highlightButton {
	padding: 0.25em 0.25em 0.15em 0.25em;
}
.highlightButton.selected {
	color: orange;
}
.highlightButton:hover, .highlightButton:active {
	color: var(--pp10-orange-40);
	font-weight: bold;
}

.productName.highlight {
	background-color: yellow;
}
</style>
