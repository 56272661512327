<template>
<section>
	<div v-for="(commAssign, index) in assignments" :key="commAssign" class="CommissionAssignment flex-row flex-justify-between flex-align-center">
		<div>
			<span>{{commAssign.rate * 100}}% to </span>
			<span>{{findSalesRep(commAssign.salesRepID).firstName || ''}} {{findSalesRep(commAssign.salesRepID).lastName || ''}} </span>
			<span>({{commAssign.name}})</span>&nbsp;
			<span v-if="isOrder">&mdash; {{ Math.moneyFormat(commAssign.rate * order.totalCost) }}</span>
			<div v-if="!isOrder">
				<span v-if="commAssign.startDate && commAssign.endDate">{{commAssign.startDateOnlyString}} - {{commAssign.endDateOnlyString}}</span>
				<span v-else-if="!commAssign.startDate && commAssign.endDate">Until {{commAssign.endDateOnlyString}}</span>
				<span v-else-if="commAssign.startDate && !commAssign.endDate">After {{commAssign.startDateOnlyString}}</span>
				<span v-else>Applies perpetually</span>
			</div>
		</div>
		<span>
			<button class="button" v-if="isOrder && !commAssign.isPaid" @click.stop="markPaid(index)">Mark Paid</button>
			<span v-else>Paid {{commAssign.paidDateOnlyString}}</span>
			<button @click.stop="showEditAssignment(index)" v-if="!isOrder"><span class="icon-cog" /></button>
			<button @click.stop="unassignCommission(index)"><span class="icon-bin2" /></button>
		</span>
	</div>

	<div id="addCommission" v-if="assignCommissionVisible">
		<select v-model="addSalesRepIndex">
			<option :value="null" disabled>Select Sales Rep</option>
			<option v-for="(rep, index) in salesReps" :key="rep" :value="index">{{rep.firstName}} {{rep.lastName}}</option>
		</select>
		<select v-model="addCommissionIndex">
			<option :value="null">Select Commission Structure</option>
			<option v-for="(comm, index) in commissions" :key="comm" :value="index">{{comm.name}} ({{comm.rate*100}}%)</option>
		</select>
		<button @click.stop="showEditCommission(addCommissionIndex)" :disabled="addCommissionIndex === null">Edit</button>
		<button @click.stop="assignCommission()" :disabled="addSalesRepIndex === null || addCommissionIndex === null">Add</button>
	</div>

	<div>
		<button @click.stop="assignCommissionVisible = !assignCommissionVisible"><span class="icon-plus" /></button>
		<button @click.stop="showCreateCommission()">New Commission Structure</button>
	</div>

	<!-- Edit ASSIGNMENT -->
	<CommissionDetailsModal :commission="assignments[editCommissionIndex]" :show="editCommissionVisible" :isAssignment="true" :order="order" @close="editCommissionVisible = false" />

	<!-- Edit Commission -->
	<CommissionDetailsModal :commission="commissions[selectedCommissionIndex]" :show="commissionDetailsVisible" @close="commissionDetailsVisible = false" />

</section>
</template>



<script>
import Commission from './Commissions/Commission.js'
import SalesRepresentative from './SalesRepresentatives/SalesRepresentative.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'

import CommissionDetailsModal from './Commissions/CommissionDetailsModal'
export default {
	name: 'CommissionWidget',


	components: {
		CommissionDetailsModal,
	},


	props: {
		hostType: String,			// 'affiliate' or 'customer'
		hostID: Number,
		order: Object,
	},


	data() {
		return {
			assignments: [],

			salesReps: [],
			addSalesRepIndex: null,

			commissions: [],
			addCommissionIndex: null,
			selectedCommissionIndex: null,
			editCommissionIndex: null,

			editCommissionVisible: false,
			assignCommissionVisible: false,
			commissionDetailsVisible: false,

			isOrder: false,
		}
	},


	created() { this.initialize() },


	computed: {
		affiliateID() { return this.hostType === 'affiliate' ? this.hostID : null },
		customerID() { return this.hostType === 'customer' ? this.hostID : null },
		orderID() { return this.hostType === 'order' ? this.hostID : null },
		orderAssignments() { return this.order ? this.order : null}
	},


	watch: {
		hostType() { this.initialize() },
		hostID() { this.initialize() },
		order() { this.initialize() },
	},


	methods: {
		async initialize() {
			if(this.hostType === 'order') this.isOrder = true

			this.salesReps = []
      const res1 = await SalesManagementAPI.getSalesRepresentatives()
      for(let item of res1) this.salesReps.push( SalesRepresentative.import(item) )

			this.commissions = []
			const res2 = await SalesManagementAPI.getCommissions()
			for(let item of res2) this.commissions.push( Commission.import(item) )

			this.assignments = []
			if( this.isOrder ) this.assignments = this.order.commissions
			else {
				const res3 = await SalesManagementAPI.getCommissionAssignments(this.affiliateID, this.customerID)
				var com = null
				for(let item of res3) {
					com = Commission.import(item)
					com.affiliateID = this.affiliateID
					com.customerID = this.customerID
					this.assignments.push( com )
				}
			}


		},


		findSalesRep(repID) {
			return this.salesReps.find(item => item.id === repID)
		},


		// Commissions / Sales Reps
		showCreateCommission() {
			this.commissions.push( new Commission() )
			this.selectedCommissionIndex = this.commissions.length - 1
			this.commissionDetailsVisible = true
		},

		showEditCommission(index) {
			this.selectedCommissionIndex = index
			this.commissionDetailsVisible = true
		},

		async assignCommission() {
			if(this.addSalesRepIndex === null) return
			if(this.addCommissionIndex === null) return

			// add
			var newCommission = new Commission()
			Object.assign(newCommission, this.commissions[this.addCommissionIndex])
			newCommission.salesRepID = this.salesReps[this.addSalesRepIndex].id
			newCommission.startDate = new Date()
			newCommission.endDate = new Date(newCommission.startDate.getTime() + (newCommission.defaultLength * 1000))
			this.assignments.push(newCommission)

			if(!this.isOrder) await SalesManagementAPI.assignCommission(newCommission.commissionID, newCommission.salesRepID, this.affiliateID, this.customerID, newCommission.startDateTS, newCommission.endDateTS)

			this.assignCommissionVisible = false
			this.addCommissionIndex = null
			this.addSalesRepIndex = null
		},

		showEditAssignment(index) {
			this.editCommissionIndex = index
			this.editCommissionVisible = true
		},

		async editAssignment(index) {

		},

		async unassignCommission(index) {
			var commID = this.assignments[index].commissionID
			var repID = this.assignments[index].salesRepID
			if(!this.isOrder) await SalesManagementAPI.unassignCommission(commID, repID, this.affiliateID, this.customerID)

			this.assignments.splice(index, 1)
		},


		markPaid(index) {
			this.assignments[index].paidDate = new Date()
		},
	}

}

</script>


<style scoped>
#addCommission {
	margin-top: 0.5em;
	padding-top: 0.5em;
	border-top: 1px solid #ccc;
}

.CommissionAssignment {
	/* margin-bottom: 0.5em; */
	padding: 0.25em 0.5em;
}
.CommissionAssignment:hover {
	background: #eee;
}
</style>
