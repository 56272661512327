import LineItem from './LineItem.js'
import Transaction from './Transactions/Transaction.js'
import Commission from '@/features/SalesManagement/Commissions/Commission.js'


class Order {

  constructor() {
    this.orderID = 0;
    this.userID = null;
    this.customerID = null;
    this.lineItems = [];
    this.totalCost = 0;
    this.subscriptionID = null;
    this.isSubscriptionBilling = false;
    
    // OLD STUFF!!
    // this.paymentMethod = null;
    // this.transactionID = null;
    // this.authCode = null;
    this.transactions = null; // array of Transaction objects
    this.amountPaid = null;   // total paid, as calculated by server

    this.affiliateID = 0;
    this.affiliateName = '';
    this.landingPageID = 0;
    this.storefrontID = 0;
    this.salesRepresentativeID = 0;

    this.isProposal = false;

    this.timestamp = null;
		this.dateInvoiced = null;
    this.datePurchaseOrderReceived = null;
    this.dateDue = null;
    this.datePaid = null;
    this.dateFulfilled = null;
    this.dateCanceled = null;
		this.dateContractRenews = null;
    this.dateContractExpires = null;

		this.commissions = [];

    // Notes
    this.adminNotes = '';
    this.customerNotes = '';
  }


  get totalCostString() { return this.totalCost }
  set totalCostString(price) {
    this.totalCost = parseFloat(price)
    if(isNaN(this.totalCost)) this.totalCost = 0
  }

  get subscriptionIDString() { return this.subscriptionID }
  set subscriptionIDString(value) { this.subscriptionID = parseInt(value) }



  get creationDateString() {
    if(!this.timestamp) return null;
    return this.timestamp.toLocaleString();
  }
  get creationDateOnlyString() {
    if(!this.timestamp) return null;
    return this.timestamp.toLocaleDateString();
  }
  get invoiceDateString() {
    if(!this.dateInvoiced) return null;
    return this.dateInvoiced.toLocaleString();
  }
  get invoiceDateOnlyString() {
    if(!this.dateInvoiced) return null;
    return this.dateInvoiced.toLocaleDateString();
  }
  get dueDateString() {
    if(!this.dateDue) return null;
    return this.dateDue.toLocaleString();
  }
  get dueDateOnlyString() {
    if(!this.dateDue) return null;
    return this.dateDue.toLocaleDateString();
  }
  get POReceivedString() {
    if(!this.datePurchaseOrderReceived) return null;
    return this.datePurchaseOrderReceived.toLocaleString();
  }
  get POReceivedDateOnlyString() {
    if(!this.datePurchaseOrderReceived) return null;
    return this.datePurchaseOrderReceived.toLocaleDateString();
  }
  get paidDateString() {
    if(!this.datePaid) return null;
    return this.datePaid.toLocaleString();
  }
  get paidDateOnlyString() {
    if(!this.datePaid) return null;
    return this.datePaid.toLocaleDateString();
  }

  get poIsReceived() { return this.datePurchaseOrderReceived ? true : false }
  get isPaid() {
    if( !this.datePaid ) return false
    if( this.totalCost === 0 ) return true
    
    // Server-side calculation
    if( !Array.isArray( this.transactions ) ) return this.amountPaid >= this.totalCost
    
    // Client-side calculation
    if( this.totalPaid === null ) return false
    const epsilon = 0.009
    const amtPaid = this.totalPaid
    return (this.totalCost - amtPaid) <= epsilon
  }

  get totalPaid() {
    if( !Array.isArray( this.transactions ) ) return this.amountPaid
    return this.transactions.reduce( (acc, t) => {
      var amt = t.getOrderAmount( this.orderID )
      if( amt === null ) amt = 0
      return acc + amt
    }, 0)
  }

  get fulfilledDateString() {
    if(!this.dateFulfilled) return null;
    return this.dateFulfilled.toLocaleString();
  }
  get fulfilledDateOnlyString() {
    if(!this.dateFulfilled) return null;
    return this.dateFulfilled.toLocaleDateString();
  }
  get isFulfilled() {
    return this.dateFulfilled ? true : false;
  }

  get canceledDateString() {
    if(!this.dateCanceled) return null;
    return this.dateCanceled.toLocaleString();
  }
	get isCanceled() {
		return this.dateCanceled ? true : false;
	}

  get contractRenewalDateString() {
    if(!this.dateContractRenews) return null;
    return this.dateContractRenews.toLocaleString();
  }
  get contractExpirationDateString() {
    if(!this.dateContractExpires) return null;
    return this.dateContractExpires.toLocaleString();
  }

  containsSubscription() {
    for(let item of this.lineItems) {
      if(item.pricingOption && item.pricingOption.isSubscription) return true
    }
    return false
  }



  status() {
    var cancelStr = this.dateCanceled ? ' (CANCELED)' : '';

    if(this.isProposal) return 'Proposal' + cancelStr;
    if(this.isPaid && this.isFulfilled) return 'Complete' + cancelStr;
    if(this.isPaid) return 'Paid & Unfulfilled' + cancelStr;
    if(this.isFulfilled) return 'Fulfilled & Unpaid' + cancelStr;

    if(this.dateDue) {
      let now = new Date();
      let due = new Date(this.dateDue);
      if(now - due > 0) return 'Delinquent' + cancelStr;
    }
    return 'Order Placed' + cancelStr;
  }



  addLineItem(item) {
    this.lineItems.push(item)
    this.updatePrice()
  }

  deleteLineItem(index) {
    this.lineItems.splice(index, 1)
    this.updatePrice()
  }



  updatePrice() {
    var price = 0.00
    for(let li of this.lineItems) {
      if( li.upgradeSKUs.length ) {
        alert("Can't recalculate price for order with attached upgrades.")
        throw new Error("Can't calculate price")
      }
      price += li.totalPrice
    }
    this.totalCost = price
  }



  isSame(other) {
    return JSON.stringify(this.export()) == JSON.stringify(other.export());
  }



  clone(includeEmbeddedProducts, includeTransactions) {
    return Order.import( this.export(includeEmbeddedProducts, includeTransactions) )
  }



  static import(obj) {
    let order = new Order();

    // All properties
    for(let property in obj) order[property] = obj[property];

    // Create date objects
    if(order.timestamp) order.timestamp = new Date(order.timestamp * 1000);
    if(order.dateInvoiced) order.dateInvoiced = new Date(order.dateInvoiced * 1000);
    if(order.datePurchaseOrderReceived) order.datePurchaseOrderReceived = new Date(order.datePurchaseOrderReceived * 1000);
    if(order.dateDue) order.dateDue = new Date(order.dateDue * 1000);
    if(order.datePaid) order.datePaid = new Date(order.datePaid * 1000);
    if(order.dateFulfilled) order.dateFulfilled = new Date(order.dateFulfilled * 1000);
    if(order.dateCanceled) order.dateCanceled = new Date(order.dateCanceled * 1000);
    if(order.dateContractRenews) order.dateContractRenews = new Date(order.dateContractRenews * 1000);
    if(order.dateContractExpires) order.dateContractExpires = new Date(order.dateContractExpires * 1000);

    // Line Items
    for(let i=0; i < order.lineItems.length; i++) {
      order.lineItems[i] = LineItem.import(order.lineItems[i]);
    }

    // Transactions
    if( Array.isArray(order.transactions) ) {
      for(let i=0; i < order.transactions.length; i++) {
        order.transactions[i] = Transaction.import(order.transactions[i]);
      }
    }

		// Commissions
		if( !Array.isArray(order.commissions) ) order.commissions = []
    for(let i=0; i < order.commissions.length; i++) {
      order.commissions[i] = Commission.import(order.commissions[i]);
    }

    return order;
  }



  export(includeEmbeddedProducts = false, includeTransactions = false) {
    const data = {};
    for(let property in this) data[property] = this[property];

    if(this.timestamp) data.timestamp = Math.floor(this.timestamp.getTime() / 1000)
    if(this.dateInvoiced) data.dateInvoiced = Math.floor(this.dateInvoiced.getTime() / 1000)
    if(this.datePurchaseOrderReceived) data.datePurchaseOrderReceived = Math.floor(this.datePurchaseOrderReceived.getTime() / 1000)
    if(this.dateDue) data.dateDue = Math.floor(this.dateDue.getTime() / 1000)
    if(this.datePaid) data.datePaid = Math.floor(this.datePaid.getTime() / 1000)
    if(this.dateFulfilled) data.dateFulfilled = Math.floor(this.dateFulfilled.getTime() / 1000)
    if(this.dateCanceled) data.dateCanceled = Math.floor(this.dateCanceled.getTime() / 1000)
    if(this.dateContractRenews) data.dateContractRenews = Math.floor(this.dateContractRenews.getTime() / 1000)
    if(this.dateContractExpires) data.dateContractExpires = Math.floor(this.dateContractExpires.getTime() / 1000)

    data.lineItems = []
    for(let item of this.lineItems) data.lineItems.push( item.export(includeEmbeddedProducts) )

    if( includeTransactions ) {
      if( Array.isArray(this.transactions) ) {
        data.transactions = []
        for(let item of this.transactions) data.transactions.push( item.export() )
      }

    } else delete( data.transactions )

		data.commissions = []
    for(let item of this.commissions) data.commissions.push( item.export() )

    return data;
  }



}

export default Order;
