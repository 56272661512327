class Exim {


	static import( obj, skipProps = [], dateProps = [] ) {
		const newObj = new this();

    for(let prop in obj) {
			
			// Skip
			if( skipProps.includes(prop) ) continue
			
			// Date
			if( dateProps.includes(prop) ) {
				if( obj[ prop ] != null && newObj[ prop ] !== undefined ) newObj[ prop ] = new Date( obj[ prop ] * 1000 )
				continue
			}

			// All others
      if( newObj[prop] !== undefined ) newObj[prop] = obj[prop]
    }
		return newObj
	}



	export( skipProps = [], dateProps = [] ) {
		const data = {}

		for(let property in this) {

			// Skip
			if( skipProps.includes(property) ) continue

			// Date
			if( dateProps.includes(property) ) {
				data[ property ] = this[ property ] ? Math.round( this[ property ].getTime() / 1000 ) : null
				continue
			}

			// All others
			data[property] = this._exportRecursive( this[property] )
		}

		return data
	}



	_exportRecursive( obj ) {
		var data = null
		if( Array.isArray(obj) ) {
			data = []
			for( let item of obj ) data.push( this._exportRecursive( item ) )
		
		} else if ( obj instanceof Map ) {
			data = {}
			for( let [key, value] of obj ) data[ key ] = this._exportRecursive( value )

		} else if ( obj instanceof Set ) {
			data = []
			for( let value of obj ) data.push( this._exportRecursive( value ) )
		
		} else if ( typeof obj === 'object' && obj !== null ) {
			data = {}
			for( let item in obj ) data[ item ] = this._exportRecursive( obj[ item ] )

		} else data = obj

		return data
	}


}
export default Exim