import API from './API.js';
import {base_url} from '@/Config'

class CollegeAPI extends API {
  static async downloadLatestCollegeData() {
    const url = `${base_url}/CollegeAPI/downloadLatestColleges`;
    await this.get(url);
  }
}

export default CollegeAPI;