import API from './API.js';
import PaginatedResponse from "./PaginatedResponse.js";



class TaxonomyAPI extends API {

	static controllerName = 'TaxonomyAPI'

	
	// static async newGetTaxonomies( courseID, courseVersionID = null ) {
	// 	const data = await this.newGet( 'newGetTaxonomies', [ courseID, courseVersionID ] )
	// 	return data
	// }


	static async getMatchingTaxonomies( paginatedRequest ) {
		const res = await this.newPost( 'getMatchingTaxonomies', [], paginatedRequest )
		return PaginatedResponse.import( res.data )
	}

	static async getMatchingTaxonomyItems( paginatedRequest ) {
		const res = await this.newPost( 'getMatchingTaxonomyItems', [], paginatedRequest )
		return PaginatedResponse.import( res.data )
	}



}
export default TaxonomyAPI;