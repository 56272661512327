<template>
<section :class="{'busyCursor' : busy}">

	<section v-if='loading' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

	<section v-if="error" class="warning">
		Failed to get records
	</section>

	<section v-if="!loading && !error" class="mt-3 mb-2">
		<div class="flex-row flex-justify-center flex-gap-3">
			<div class="center">
				<div class="bigDigits" v-html="summary.totalSessionCountString" />
				<div>Study Sessions</div>
			</div>
			<div class="center">
				<div class="bigDigits" v-html="summary.userCountString" />
				<div>Unique Users</div>
			</div>
			<div class="center histogramLink" @click="getHistogram('timePerSession')">
				<div class="bigDigits" v-html="summary.averageSessionSecondsString" />
				<div>Average Session Time <span class="link icon-stats-bars" style="text-decoration:none;" /></div>
			</div>
		</div>
		<div class="mt-2 flex-row flex-justify-center flex-gap-3">
			<div class="center histogramLink" @click="getHistogram('timePerUser')">
				<div class="bigDigits" v-html="summary.averageUserSecondsString" />
				<div>Avg Time / User <span class="link icon-stats-bars" style="text-decoration:none;" /></div>
			</div>
			<div class="center histogramLink" @click="getHistogram('sessionsPerUser')">
				<div class="bigDigits" v-html="summary.averageSessionCountString" />
				<div>Avg # Sessions / User <span class="link icon-stats-bars" style="text-decoration:none;" /></div>
			</div>
			<div class="center">
				<div class="bigDigits" v-html="summary.totalSessionSecondsString" />
				<div>Total Man - Hours</div>
			</div>
		</div>
		<div class="mt-2 flex-row flex-justify-center flex-gap-3">
			<div class="center histogramLink" @click="getHistogram('timePerLicense')">
				<div class="bigDigits" v-html="summary.averageSecondsPerLicenseString" />
				<div>Avg Time / License <span class="link icon-stats-bars" style="text-decoration:none;" /></div>
			</div>
			<div class="center histogramLink" @click="getHistogram('sessionsPerLicense')">
				<div class="bigDigits" v-html="summary.averageSessionsPerLicenseString" />
				<div>Avg # Sessions / License <span class="link icon-stats-bars" style="text-decoration:none;" /></div>
			</div>
			<div class="center histogramLink" @click="getHistogram('licensesPerUser')">
				<div class="bigDigits" v-html="summary.averageLicenseCountString" />
				<div>Avg # Licenses / User <span class="link icon-stats-bars" style="text-decoration:none;" /></div>
			</div>
			<div class="center">
				<div class="bigDigits" v-html="summary.totalLicenseCountString" />
				<div>Total Licenses</div>
			</div>
		</div>
	</section>

	<StretchModal :padding="'2em'" :max-width="'700px'" ref="chartModal">
		<template #header>Histogram: {{ histogram ? histogram.title : '' }}</template>
		<template #default>
			<ApexHistogram :histogram="histogram" />
		</template>
	</StretchModal>

</section>
</template>



<script>
import LoginTrackingAPI from '@/api/LoginTrackingAPI.js'
import UserTimeSummary from '@/features/LoginTracking/UserTimeSummary.js'

import ApexHistogram from './ApexHistogram.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

export default {
	name: 'UserTimeStats',

	components: {
		ApexHistogram,
		StretchModal,
	},

	data() {
		return {
			loading: false,
			busy: false,
			error: false,
			request: null,
			summary: new UserTimeSummary(),
			histogram: {},		
		}
	},


	methods: {
		async initialize( req ) {
			
			try {
				this.loading = true
				this.request = req
				const res = await LoginTrackingAPI.getUserTimeSummary( req )
				this.summary = UserTimeSummary.import( res.data )
				this.error = false
				
			} catch( e ) {
				this.error = true

			} finally {
				this.loading = false
			}
		},



		async getHistogram( histogramName ) {
			try {
				this.busy = true
				const res = await LoginTrackingAPI.getUserTimeHistogram( histogramName, this.request )
				this.histogram = res.data
				this.$refs.chartModal.open()

			} finally {
				this.busy = false
			}
		},


	}
}
</script>



<style scoped>
.bigDigits {
	font-size: 2em;
	font-weight: bold;
}
.histogramLink {
	cursor: pointer;
}
.histogramLink:hover {
	color: var(--ekno-blue);
}
</style>