<template>
<section>
	<div class='flex-row flex-justify-end flex-align-center py-05' style="position: sticky; top: 0; background: white;">
		<button class="button" @click="newCourse">New Course</button>
	</div>

	<div>
		<ObjectTable
			:Source="courses.objects"
			:Columns='columns'
			:Deletable="true"
			@edit="editCourse"
			@delete="deleteCourse"
			ref="objectTable" />
	</div>


		<StretchModal ref="detailsModal" :clickToClose="false" padding="1em" @close="$refs.objectTable.deselect()">
			<template #header>Course Details: {{ currentCourse.courseName }}</template>
			<CourseDetails :Course="currentCourse" @add='course => addCourse( course )' @edit="course => courses.updateElement( 'courseID', course )" />
		</StretchModal>

		<ConfirmDialog :show="itemToDelete !== null" @cancel="itemToDelete = null" @confirm="confirmDelete()">
			<div>Are you sure you want to delete this course?</div>
			<div style="font-size: 1.25em; font-weight: bold; margin: 0.25em 0;">{{ itemToDelete ? itemToDelete.courseName : '' }}</div>
			<div><strong>This action CANNOT be undone!</strong></div>
		</ConfirmDialog>

</section>
</template>

<script>
import StretchModal from "@/components/utilities/StretchModal.vue"
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import ObjectTable from "@/components/utilities/ObjectTable.vue"
import CourseDetails from "./CourseDetails.vue"

import Course from "@/models/Course.js"
import CourseAPI from "@/api/CourseAPI.js"

export default {
	name: "courses-list",

	data() {
		return {
			columns: [
				{
					displayName: 'ID',
					propertyName: 'courseID'
				},
				{
					displayName: 'Name',
					propertyName: 'courseName'
				},
			],

			currentCourse: null,
			itemToDelete: null,
		}
	},

	async created() {
		try {
			this.courses.objects = await CourseAPI.getAllCourses()
		} catch (e) {
			console.error(e)
			alert( "An error occurred while loading courses" )
		}
	},

	
	computed: {
		courses() { return this.$store.state.Courses },
	},


	methods: {
		newCourse() {
			this.currentCourse = new Course();
			this.$refs.detailsModal.open()
		},

		editCourse(course) {
			this.currentCourse = course;
			this.$refs.detailsModal.open()
		},

		deleteCourse(course) { this.itemToDelete = course },

		async confirmDelete() {
			const course = this.itemToDelete
			try {
				await CourseAPI.deleteCourse(course);
				this.courses.remove( course )

			} catch (e) {
				console.error(e)
				alert("Error communicating with server; changes not saved.")
			}
			this.itemToDelete = null
		},

		addCourse(course) {
			this.currentCourse = course
			this.courses.push( course )
		},
	},

	components: {
		StretchModal,
		ConfirmDialog,
		ObjectTable,
		CourseDetails,
	}
}
</script>
