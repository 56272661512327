<template>
<section>
	
	<ModalMessageDryRun :modalMessage="modalMessage" :includeTriggerLogic="true" ref="dryrun" />

	<fieldset class="mt-1">
		<legend>General Parameters</legend>
		
		<section>
			<div class="mb-1">These rules suppress display when not matched</div>

			<section class="flex-row flex-wrap flex-justify-start flex-gap-1 mb-1">

				<div class="widget pa-05 border round-025" :class="{'disabled' : !modalMessage.displayInCourse && !modalMessage.displayInLibrary}">
					<div class="widgetHeader flex-row flex-gap-1 flex-justify-between">
						<div>Location</div>
						<span class="icon-info" :tooltip="'WARNING: changing this setting after publication will cause\nStudy Sessions / Logins interval\nto function incorrectly.'" @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
					</div>
					<div><ToggleButton v-model="modalMessage.displayInCourse" :sync="true" @change="$emit('updateActive')" /> Show in Course</div>
					<div><ToggleButton v-model="modalMessage.displayInLibrary" :sync="true" @change="$emit('updateActive')" /> Show in Library</div>
				</div>

				<div class="widget pa-05 border round-025">
					<div class="widgetHeader flex-row flex-gap-1 flex-justify-between">
						<div>Display Delay</div>
						<span class="icon-info" tooltip="After the user arrives in the course or library, wait this long before showing the ModalMessage." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
					</div>
					<div>
						<input type="number" v-model.number="modalMessage.displayDelay" min="1" /> secs
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.displayDelay !== dVals.displayDelay}" @click="reset('displayDelay')" />
					</div>
				</div>

				<div class="widget pa-05 border round-025" :class="{'disabled' : !isActiveDate}">
					<div class="widgetHeader flex-row flex-gap-1 flex-justify-between">
						<div>Start &amp; End Dates</div>
						<span class="icon-info" tooltip="ModalMessage will only display within the date range specified." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
					</div>
					<div class="flex-row">
						<v-date-picker v-model="triggerStartDateString" mode="dateTime">
							<template v-slot="{ inputValue, inputEvents }">
								<input
								class="date"
								placeholder="Start Date"
								:value="inputValue"
								v-on="inputEvents"
								style="color: purple;"
								/>
							</template>
						</v-date-picker>
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.triggerStartDate !== dVals.triggerStartDate}" @click="reset('triggerStartDate')" />
					</div>

					<div class="flex-row">
						<v-date-picker v-model="triggerEndDateString" mode="dateTime">
							<template v-slot="{ inputValue, inputEvents }">
								<input
								class="date"
								placeholder="End Date"
								:value="inputValue"
								v-on="inputEvents"
								style="color: purple;"
								/>
							</template>
						</v-date-picker>
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.triggerEndDate !== dVals.triggerEndDate}" @click="reset('triggerEndDate')" />
					</div>
					<div class="flex-row flex-justify-end">
						Min Interval:&nbsp;<input type="number" v-model.number="modalMessage.intervalDateDays" min="1" />&nbsp;Days
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.intervalDateDays !== dVals.intervalDateDays}" @click="reset('intervalDateDays')" />
					</div>
				</div>

				<div class="widget pa-05 border round-025">
					<div>
						<div class="widgetHeader flex-row flex-gap-1 flex-justify-between">
							<div>Max # Views:</div>
							<span class="icon-info" tooltip="Maximum # of times to show to any student" @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
						</div>
						<div>
							<input type="number" v-model.number="modalMessage.maxRepeats" min="1" />
							<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.maxRepeats !== dVals.maxRepeats}" @click="reset('maxRepeats')" />
						</div>
					</div>
				</div>

			</section>

			<section class="flex-row flex-wrap flex-justify-start flex-align-start flex-gap-1">
				<div class="widget pa-05 border round-025" :class="{'disabled' : modalMessage.triggerMaxLogins && (modalMessage.triggerMinLogins > modalMessage.triggerMaxLogins || modalMessage.intervalLogins > modalMessage.triggerMaxLogins)}">
					<div v-if="modalMessage.displayInCourse" class="widgetHeader flex-row flex-gap-1 flex-justify-between">
						<div># Study Sessions</div>
						<span class="icon-info" :tooltip="'The number of \'study sessions\' the user has initiated.'" @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
					</div>
					<div v-else class="widgetHeader flex-row flex-gap-1 flex-justify-between">
						<div># Logins</div>
						<span class="icon-info" :tooltip="'The number of times the user has successfully logged in to their account.'" @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
					</div>
					<div class="flex-row flex-justify-end">
						Min:&nbsp;<input type="number" v-model.number="modalMessage.triggerMinLogins" min="0" />
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.triggerMinLogins !== dVals.triggerMinLogins}" @click="reset('triggerMinLogins')" />
					</div>
					<div class="flex-row flex-justify-end">
						Max:&nbsp;<input type="number" v-model.number="modalMessage.triggerMaxLogins" min="0" />
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.triggerMaxLogins !== dVals.triggerMaxLogins}" @click="reset('triggerMaxLogins')" />
					</div>
					<div class="flex-row flex-justify-end">
						Interval:&nbsp;<input type="number" v-model.number="modalMessage.intervalLogins" min="1" />
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.intervalLogins !== dVals.intervalLogins}" @click="reset('intervalLogins')" />
					</div>
				</div>

				<div class="widget pa-05 border round-025" :class="{'disabled' : modalMessage.triggerMaxHoursInProgram && (modalMessage.triggerMinHoursInProgram > modalMessage.triggerMaxHoursInProgram || modalMessage.intervalHoursInProgram > modalMessage.triggerMaxHoursInProgram)}">
					<div class="widgetHeader">Time In Program</div>
					<div class="flex-row flex-justify-end">
						Min:&nbsp;<input type="number" v-model.number="modalMessage.triggerMinHoursInProgram" min="0" />&nbsp;hours
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.triggerMinHoursInProgram !== dVals.triggerMinHoursInProgram}" @click="reset('triggerMinHoursInProgram')" />
					</div>
					<div class="flex-row flex-justify-end">
						Max:&nbsp;<input type="number" v-model.number="modalMessage.triggerMaxHoursInProgram" min="0" />&nbsp;hours
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.triggerMaxHoursInProgram !== dVals.triggerMaxHoursInProgram}" @click="reset('triggerMaxHoursInProgram')" />
					</div>
					<div class="flex-row flex-justify-end">
						Interval:&nbsp;<input type="number" v-model.number="modalMessage.intervalHoursInProgram" min="0" />&nbsp;hours
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.intervalHoursInProgram !== dVals.intervalHoursInProgram}" @click="reset('intervalHoursInProgram')" />
					</div>
				</div>

				<div class="widget pa-05 border round-025" :class="{'off' : !modalMessage.displayInCourse, 'disabled' : modalMessage.triggerMaxDaysPurchase && (modalMessage.triggerMinDaysPurchase > modalMessage.triggerMaxDaysPurchase || modalMessage.intervalDaysPurchase > modalMessage.triggerMaxDaysPurchase)}">
					<div class="widgetHeader flex-row flex-gap-1 flex-justify-between">
						<div>License Age</div>
						<span class="icon-info" :tooltip="'Time since license creation.\nEffective ONLY when shown in course.'" @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
					</div>
					<div class="flex-row flex-justify-end">
						Min:&nbsp;<input type="number" v-model.number="modalMessage.triggerMinDaysPurchase" min="0" />&nbsp;days
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.triggerMinDaysPurchase !== dVals.triggerMinDaysPurchase}" @click="reset('triggerMinDaysPurchase')" />
					</div>
					<div class="flex-row flex-justify-end">
						Max:&nbsp;<input type="number" v-model.number="modalMessage.triggerMaxDaysPurchase" min="0" />&nbsp;days
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.triggerMaxDaysPurchase !== dVals.triggerMaxDaysPurchase}" @click="reset('triggerMaxDaysPurchase')" />
					</div>
					<div class="flex-row flex-justify-end">
						Interval:&nbsp;<input type="number" v-model.number="modalMessage.intervalDaysPurchase" min="0" />&nbsp;days
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.intervalDaysPurchase !== dVals.intervalDaysPurchase}" @click="reset('intervalDaysPurchase')" />
					</div>
				</div>

				<div class="widget pa-05 border round-025" :class="{'disabled' : modalMessage.triggerMaxDaysAccount && (modalMessage.triggerMinDaysAccount > modalMessage.triggerMaxDaysAccount || modalMessage.intervalDaysAccount > modalMessage.triggerMaxDaysAccount)}">
					<div class="widgetHeader flex-row flex-gap-1 flex-justify-between">
						<div>Account Age</div>
						<span class="icon-info" tooltip="Time since user registration (first password)." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
					</div>
					<div class="flex-row flex-justify-end">
						Min:&nbsp;<input type="number" v-model.number="modalMessage.triggerMinDaysAccount" min="0" />&nbsp;days
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.triggerMinDaysAccount !== dVals.triggerMinDaysAccount}" @click="reset('triggerMinDaysAccount')" />
					</div>
					<div class="flex-row flex-justify-end">
						Max:&nbsp;<input type="number" v-model.number="modalMessage.triggerMaxDaysAccount" min="0" />&nbsp;days
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.triggerMaxDaysAccount !== dVals.triggerMaxDaysAccount}" @click="reset('triggerMaxDaysAccount')" />
					</div>
					<div class="flex-row flex-justify-end">
						Interval:&nbsp;<input type="number" v-model.number="modalMessage.intervalDaysAccount" min="0" />&nbsp;days
						<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.intervalDaysAccount !== dVals.intervalDaysAccount}" @click="reset('intervalDaysAccount')" />
					</div>
				</div>

				<div class="widget pa-05 border round-025">
					<div class="widgetHeader flex-row flex-gap-1 flex-justify-between">
						<div>Previously Completed Nodes</div>
						<span class="icon-info" tooltip="If specified, this ModalMessage will display at login, if and only if ANY of the given nodes (and ALL marked 'required') have ALREADY been completed." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
					</div>
					
					<!-- Existing NodesCompleted -->
					<div style="max-height: 10em; overflow: auto;">
						<ScoreWidget
							v-for="[nodeID, obj] in modalMessage.triggerNodesCompleted.entries()"
							:key="nodeID"
							:nodeID="parseInt(nodeID)"
							:score="obj"
							:showRequired="true"
							@changed="$emit('changed')"
							@delete="removeCompletedNodeID( nodeID )"
						/>

						<!-- Add new NodeCompleted -->
						<div class="center"><div v-if="!showAddCompletedNode" class="icon-plus border round-1 inline-block pa-05" @click="showAddCompletedNode = true" /></div>
						<ScoreWidget
							v-if="showAddCompletedNode"
							v-model.number="newCompletedNodeID"
							:score="newCompletedNodeScore"
							:hasError="newCompletedNodeError"
							:showRequired="true"
							:isNew="true"
							@add="addCompletedNodeID()"
							@cancel="showAddCompletedNode = false"
						/>
					</div>
					
				</div>
			</section>
		</section>

	</fieldset>


	<fieldset class="mt-1" :class="{ 'disabled' : !modalMessage.displayOnLogin && !modalMessage.displayOnEvent }">
		<legend>Display at Login <ToggleButton v-model="modalMessage.displayOnLogin" @change="$emit('updateActive')" /></legend>
		<section v-if="modalMessage.displayOnLogin">
			<div v-if="(modalMessage.displayInCourse || modalMessage.displayInLibrary) && !modalMessage.hasInterval && modalMessage.maxRepeats === null" class="warning mb-1">WARNING: This message has no interval(s) or "max views" set -- it will display on EVERY login</div>
		</section>
	</fieldset>


	<fieldset class="mt-1" :class="{ 'disabled' : !modalMessage.displayOnLogin && !modalMessage.displayOnEvent }">
		<legend>Display on Events <ToggleButton v-model="modalMessage.displayOnEvent" @change="$emit('updateActive')" /></legend>
		<div v-if="modalMessage.displayOnEvent" class="mb-1">Display this ModalMessage when certain events occur inside the program. <br>Does NOT ignore any "general parameters" set above.</div>
		<section v-if="modalMessage.displayOnEvent" class="flex-row flex-wrap flex-justify-start flex-align-start flex-gap-1">
			

			<div class="widget pa-05 border round-025">
				<div class="widgetHeader flex-row flex-gap-1 flex-justify-between">
					<div>Display upon Node Completion</div>
					<span class="icon-info" tooltip="If specified, this ModalMessage will display ONLY upon completion of the given node(s)." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
				</div>


				<!-- Existing EventNodes -->
				<div style="max-height: 10em; overflow: auto;">
					<ScoreWidget
						v-for="[nodeID, obj] in modalMessage.triggerNodeIDs.entries()"
						:key="nodeID"
						:nodeID="parseInt(nodeID)"
						:score="obj"
						@changed="$emit('changed')"
						@delete="removeEventNodeID( nodeID )"
					/>
					
					<!-- Add new EventNode -->
					<div class="center"><div v-if="!showAddNode" class="icon-plus border round-1 inline-block pa-05" @click="showAddNode = true" /></div>
					<ScoreWidget
						v-if="showAddNode"
						v-model.number="newEventNodeID"
						:score="newEventNodeScore"
						:hasError="newEventNodeError"
						:isNew="true"
						@add="addEventNodeID()"
					/>
				</div>


			</div>


			<div class="widget pa-05 border round-025">
				<div class="widgetHeader flex-row flex-gap-1 flex-justify-between">
					<div>Display when Projected Score</div>
					<span class="icon-info" tooltip="If specified, this ModalMessage will display ONLY when the student's projected score passes through the given threshold." @mouseenter="tooltips.showTooltip($event, 'bottom', 0, 6000)" @mouseleave="tooltips.hideTooltip($event)" />
				</div>

				<div>
					<div><ToggleButton v-model="enableProjectedScore" :sync="true" /> Enable Projected Score</div>
					<div v-if="modalMessage.triggerProjectedScore">
						Student's
						<select v-model="modalMessage.triggerProjectedScore.type" @change="updateProjectedScoreType()">
							<option :value="null" disabled>Select Score Type</option>
							<option value="projectedRawActual">Projected Score (raw, actual)</option>
							<option value="projectedRawAdjusted">Projected Score (raw, adjusted)</option>
							<option value="projectedScaledActual">Projected Score (scaled, actual)</option>
							<option value="projectedScaledAdjusted">Projected Score (scaled, adjusted)</option>
							<option value="percentToTarget">%-to-Target</option>
						</select>
						<select v-model="modalMessage.triggerProjectedScore.rising" @change="$emit('changed')">
							<option :value="true">rises</option>
							<option :value="false">falls</option>
							<option :value="null">either</option>
						</select>
						to
						<input type="number" min="0" v-model.number="modalMessage.triggerProjectedScore.score" placeholder="score" style="width: 5em;" @input="$emit('changed')" />
						<span v-if="triggerProjectedScoreIsPercent">
							%
						</span>
					</div>
				</div>

			</div>

		</section>
	</fieldset>

</section>
</template>

<script>
import Tooltips from '@/libraries/Tooltips/Tooltips.js';

import ModalMessageDryRun from './ModalMessageDryRun.vue'
import ScoreWidget from './ScoreWidget.vue'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: 'ModalMessageTriggers',

	components: {
		ModalMessageDryRun,
		ScoreWidget,
		ToggleButton,
	},


	props: {
		modalMessage: Object,
		dVals: Object,
	},


	data() {
		return {
			showAddCompletedNode: false,
			newCompletedNodeID: null,
			newCompletedNodeScore: { score: null, type: null, compare: 1, required: false },
			newCompletedNodeError: false,

			showAddNode: false,
			newEventNodeID: null,
			newEventNodeScore: { score: null, type: null, compare: 1 },
			newEventNodeError: false,

			enableProjectedScore: this.modalMessage.triggerProjectedScore !== null,
			triggerProjectedScoreIsPercent: false,
		}
	},

	
	computed: {
		tooltips() { return Tooltips },
		isActive() {
			if( !this.modalMessage.isActive ) return false
			if( !this.modalMessage.displayInCourse && !this.modalMessage.displayInLibrary ) return false
			if( !this.isActiveDate ) return false
			return true
		},

		isActiveDate() {
			const now = new Date()
			if( this.modalMessage.triggerStartDate !== null && this.modalMessage.triggerStartDate > now ) return false
			if( this.modalMessage.triggerEndDate !== null && this.modalMessage.triggerEndDate < now ) return false
			return true
		},

		triggerStartDateString: {
			get() { return this.modalMessage.triggerStartDateString },
			set(value) {
				this.$emit('changed')
				if(value) value = new Date(value);
				else this.modalMessage.triggerStartDate = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.modalMessage.triggerStartDate = value;
			}
		},
		triggerEndDateString: {
			get() { return this.modalMessage.triggerEndDateString },
			set(value) {
				this.$emit('changed')
				if(value) value = new Date(value);
				else this.modalMessage.triggerEndDate = null;

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.modalMessage.triggerEndDate = value;
			}
		},

	},



	watch: {
		newCompletedNodeID( value ) {
			// console.debug( this.newCompletedNodeID, this.modalMessage.hasTriggerNodeCompleted(this.newCompletedNodeID), this.modalMessage.triggerNodesCompleted )
			this.newCompletedNodeError = this.modalMessage.hasTriggerNodeCompleted( value )
		},
		newEventNodeID( value ) {
			// console.debug( this.newEventNodeID, this.modalMessage.hasTriggerNodeCompleted(this.newEventNodeID) )
			this.newEventNodeError = this.modalMessage.hasTriggerNodeID( value )
		},
		enableProjectedScore( value ) {
			if( value ) this.modalMessage.triggerProjectedScore = { score: null, rising: true, type: null }
			else this.modalMessage.triggerProjectedScore = null
		},
		'modalMessage.displayInCourse'( value ) {
			if( value ) this.modalMessage.displayInLibrary = false
		},
		'modalMessage.displayInLibrary'( value ) {
			if( value ) this.modalMessage.displayInCourse = false
		},
	},




	methods: {

		addCompletedNodeID() {
			console.debug( this.newCompletedNodeID, this.modalMessage.hasTriggerNodeCompleted(this.newCompletedNodeID) )
			if( this.modalMessage.hasTriggerNodeCompleted( this.newCompletedNodeID ) ) {
				this.newCompletedNodeError = true
				return
			}
			const score = this.newCompletedNodeScore
			this.modalMessage.addTriggerNodeCompleted( this.newCompletedNodeID, score.score, score.type, score.compare, score.required )
			this.newCompletedNodeID = null
			this.newCompletedNodeScore = { score: null, type: null, compare: 1, required: false }
			this.newCompletedNodeError = false
			this.showAddCompletedNode = false
			this.$emit('changed')
		},

		removeCompletedNodeID( nodeID ) {
			this.modalMessage.removeTriggerNodeCompleted( nodeID )
			this.$emit('changed')
			this.$forceUpdate()
		},

		addEventNodeID() {
			if( this.modalMessage.hasTriggerNodeID( this.newEventNodeID ) ) {
				this.newEventNodeError = true
				return
			}
			const score = this.newEventNodeScore
			this.modalMessage.addTriggerNodeID( this.newEventNodeID, score.score, score.type, score.compare )
			this.newEventNodeID = null
			this.newEventNodeScore = { score: null, type: null, compare: 1 }
			this.newEventNodeError = false
			this.showAddNode = false
			this.$emit('changed')
		},

		removeEventNodeID( nodeID ) {
			this.modalMessage.removeTriggerNodeID( nodeID )
			this.$emit('changed')
			this.$forceUpdate()
		},


		updateProjectedScoreType() {
			this.$emit('changed')
			
			this.triggerProjectedScoreIsPercent = (this.modalMessage.triggerProjectedScore && this.modalMessage.triggerProjectedScore.type && (
				this.modalMessage.triggerProjectedScore.type == 'projectedRawActual' ||
				this.modalMessage.triggerProjectedScore.type == 'projectedRawAdjusted' ||
				this.modalMessage.triggerProjectedScore.type == 'percentToTarget')) ? true : false
			console.debug('updateProjectedScoreType:', this.triggerProjectedScoreIsPercent)
			console.debug(this.modalMessage.triggerProjectedScore)
			console.debug(this.modalMessage.triggerProjectedScore.type)
		},



		// Called by parent!
		updateAssignedUsers() {
			this.$refs.dryrun.initialize()
		},



		reset( prop ) { this.$emit( 'reset', prop ) },
	}
}
</script>



<style scoped>
.widget {
	background: white;
}

.widgetHeader {
	font-size: 1.1em;
	font-style: italic;
	margin-bottom: 0.5em;
	border-bottom: 1px solid #ccc;
}

.widget.disabled {
	border-color: red;
	background: #fdd;
	color: red;
}
.widget.off {
	background: #ccc;
}

fieldset.disabled {
	border-top: 1em solid red;
  border-bottom: 1px solid red;
  border-left: 1px solid red;
  border-right: 1px solid red;
	background: pink;
}

fieldset.enabled {
	border-top: 1em solid green;
  border-bottom: 1px solid green;
  border-left: 1px solid green;
  border-right: 1px solid green;
	/* background: #f7f7f7; */
}

input[type="number"] {
	width: 5em;
}
.undoIcon {
	color: #ccc;
	cursor: default;
}
.undoIcon.active {
	color: black;
	cursor: pointer;
}
</style>