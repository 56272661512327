export default class LoginScreen {
  constructor(title="", imageUrl="", priority=1, id=null) {
    this.title = title;
    this.imageUrl = imageUrl;
    this.priority = priority;
    this.id = id;
  }

  export() {
    let data = {};
    data.title = this.title;
    data.image_url = this.imageUrl;
    data.priority = this.priority;
    return data;
  }

  static import(json) {
    return new LoginScreen(json.title, json.image_url, json.priority, json.id);
  }

  isSame(ls) {
    if (this.title == ls.title &&
        this.imageUrl == ls.imageUrl &&
        this.priority == ls.priority) {
      return true;
    }
    return false;
  }
}
