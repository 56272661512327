<template>
<section>

	<div class="flex-row">
		<div>User:</div>
		<div class="ml-05 mb-05">
			<b>
				<div>{{ modalMessageView.userFirstName }} {{ modalMessageView.userLastName }}</div>
				<div>{{ modalMessageView.userEmail }}</div>
			</b>
		</div>
	</div>

	<div>Location: <b>{{ modalMessageView.displayLocation }}</b></div>
	<div>License Key: <b>{{ modalMessageView.licenseKey }}</b></div>
	<div>Time: <b>{{ modalMessageView.time.toLocaleString() }}</b></div>

	<table class="table mt-1">
		<tr>
			<td class="table-data label">Time in Program:</td>
			<td class="table-data value">{{ modalMessageView.hoursInProgramString || '--' }}</td>
		</tr>
		<tr>
			<td class="table-data label"># Logins:</td>
			<td class="table-data value">{{ modalMessageView.numLogins || '--' }}</td>
		</tr>
		<tr>
			<td class="table-data label">License Age:</td>
			<td class="table-data value">{{ modalMessageView.hoursPurchaseString || '--' }}</td>
		</tr>
		<tr>
			<td class="table-data label">Account Age:</td>
			<td class="table-data value">{{ modalMessageView.hoursAccountString || '--' }}</td>
		</tr>
	</table>

</section>
</template>


<script>
export default {
	name: 'ModalMessageViewDetails',

	props: {
		modalMessageView: Object,
	},
}
</script>


<style scoped>
.label {
	text-align: right;
}
.value {
	text-align: left;
	font-weight: bold;
}
</style>