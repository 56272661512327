import Exim from '@/models/Exim.js'


class PocPhone extends Exim {

	id = null
	pocID = null
	phone = null
	label = null



	isSame( other ) {
		if( this.id !== other.id ) return false
		if( this.pocID !== other.pocID ) return false
		if( this.phone !== other.phone ) return false
		if( this.label !== other.label ) return false
		return true
	}

	// constructor() {
	// 	this.id = 0;
	// 	this.pocID = 0;
	// 	this.phone = '';
	// }

	// static import(json) {
	// 	let pocPhone = new PocPhone();

	// 	pocPhone.id = json.id;
	// 	pocPhone.pocID = json.pocID;
	// 	pocPhone.phone = json.phone;
		
	// 	return pocPhone;
	// }

	// export() {
	// 	return {
	// 		id: this.id,
	// 		pocID: this.pocID,
	// 		phone: this.phone
	// 	}
	// }
}

export default PocPhone;