<template>
<section style="overflow: auto; height: 100%; max-height: calc( 100vh - 55px )">

	<div v-if="showControls" class="flex-row flex-align-center flex-justify-center flex-gap-1 py-05 sticky-tools">

		<div class="mr-3">

			<div class="flex-row">
				<div>Presets:&nbsp;</div>
				<select v-model="selectedReportTemplate" class="w-100">
					<option :value="null">Select a report template...</option>
					<option v-for="t in reportTemplates" :key="t.title" :value="t">{{ t.name }}</option>
				</select>
			</div>

			<div class="flex-row flex-align-center flex-justify-center flex-gap-1 mt-05">
				<!-- SELECT: Course -->
				<CourseSearchDropdown v-model="course" width="175px" />

				<!-- SELECT: Node(s) for columns -->
				<NodeSearchDropdown :courseID="course ? course.courseID : null" :nodes="selectedNodes" @update="n => selectNodes(n)" :width="course ? '500px' : '175px'" />
			</div>

		</div>
			
		<div>
			<div class="flex-row flex-align-center flex-gap-05">
				<!-- FILTER: Customer -->
				<CustomerSearchDropdown
					v-if="!customerID"
					placeholder="Filter by Customer..."
					width="175px"
					ref="customerSearch"
					@updateCustomer="cust => selectCustomer(cust)" />

				<!-- FILTER: Cohort -->
				<CohortSearchDropdown
					v-if="!cohortID"
					:multiselect="true"
					placeholder="Filter by Cohort..."
					:cohort="cohorts"
					:customerIDs="customer ? [ customer.id ] : []"
					@update="cohorts => selectCohort(cohorts)"
					:width="cohorts.length ? '500px' : '175px'" />
			</div>

			<div class="flex-row flex-gap-05 mt-05">
				<!-- FILTER: User -->
				<UserSearchDropdown
					v-if="!userID"
					placeholder="Filter by User..."
					width="175px"
					@updateUser="user => selectUser(user)" />
				
				<!-- FILTER: Affiliate -->
				<AffiliateSearchDropdown
					v-if="!affiliateID"
					placeholder="Filter by Affiliate..."
					width="175px"
					@update="aff => selectAffiliate(aff)" />
			</div>
		</div>

		<!-- <div>
			<select v-model="activeLicenseFilter">
				<option :value="null">Active AND Expired</option>
				<option :value="true">Active Only</option>
				<option :value="false">Expired Only</option>
			</select>
		</div> -->

		<button class="SquareBlueButton" :class="{'disabled' : !canRunReport}" :disabled="!canRunReport" @click="runReport()">Run Report</button>
		<div @mouseenter.stop="t.s($event, 'bottom')" @mouseleave.stop="t.h($event)" tooltip="Download Report as Spreadsheet">
			<button class="pillButton large secondary" :class="{'disabled' : !canRunReport}" :disabled="!canRunReport" @click.stop="runReport( true )"><span class="icon-file-excel" /></button>
		</div>

	</div>


	<div v-if="!popOut && !loading && items.length" class="flex-row flex-align-center flex-justify-between mt-05 sticky-left">
		<div class="link" @click.stop="summaryMode = !summaryMode">{{ summaryMode ? 'Expand Table' : 'View Summary' }}</div>
		<div class="pa-05 border round-05" @click.stop="popOut = true"><span class="icon-enlarge" style="background: white;" /></div>
	</div>


	<section v-if="!popOut">
		<section v-if="!loading && items.length">
			
			<!-- <div class="w-100 h-100" style="overflow: auto;"> -->
				<ObjectTable
					:superColumns="superColumns"
					:summaryMode="summaryMode"
					:Columns="columns"
					:StyleFunction="() => { return 'text-align: center;' }"
					:Source="items"
					:Numbered="true"
					:SortBy="sortBy"
					:SortAsc="sortAsc"
					:sortBySuperColumnIndex="sortByIndex"
					@sort="prop => sort( prop )"
					@edit="row => selectRow( row )"
					class="mt-05"
					ref="objectTable"
				/>
			<!-- </div> -->
		</section>

		<section v-else-if='loading' class='flex justify-center'>
			<div class='icon-spinner4 spin-loader'></div>
		</section>

		<section v-if="error" class="warning">
			Failed to get records
		</section>

		<section v-if="!loading && !error && !items.length" class="NoResults">No Matching Data</section>
	</section>
	<StretchModal v-else :showModal="popOut" @close="popOut = false" height="100vh" width="99.4vw">
		<ObjectTable
			:superColumns="superColumns"
			:summaryMode="summaryMode"
			:Columns="columns"
			:StyleFunction="() => { return 'text-align: center;' }"
			:Source="items"
			:Numbered="true"
			:SortBy="sortBy"
			:SortAsc="sortAsc"
			:sortBySuperColumnIndex="sortByIndex"
			@sort="prop => sort( prop )"
			@edit="row => selectRow( row )"
			class="mt-1"
			ref="objectTable"
		/>
	</StretchModal>


	<StretchModal ref="licenseModal">
		<LicenseDetails v-if="selectedLicense" :License="selectedLicense" tab="stats" />
	</StretchModal>
	
</section>
</template>



<script>
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import LicenseAPI from '@/api/LicenseAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js';

import LicenseDetails from '@/features/Licenses/LicenseDetails.vue'
import CourseSearchDropdown from '@/features/Courses/CourseSearchDropdown.vue'
import NodeSearchDropdown from '@/features/Courses/NodeSearchDropdown.vue'
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import CohortSearchDropdown from '@/features/Cohorts/CohortSearchDropdown.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

export default {
	name: 'EScoreList',

	components: {
		LicenseDetails,
		CourseSearchDropdown,
		NodeSearchDropdown,
		UserSearchDropdown,
    CustomerSearchDropdown,
		CohortSearchDropdown,
    AffiliateSearchDropdown,
    ObjectTable,
		StretchModal,
	},


	props: {
		userID: {
			type: Number,
			default: null
		},

		customerID: {
			type: Number,
			default: null
		},

		cohortID: {
			type: Number,
			default: null
		},

		affiliateID: {
      type: Number,
      default: null
    },

		showControls: {
			type: Boolean,
			default: true
		},
	},


	data() {
		return {
			course: null,
			nodes: [], // { nodeID, nodeName, etc }
			selectedNodes: [], // TreeNode format ( id, name, etc ) -- used to push nodes INTO NodeSearchDropdown
			user: null,
			customer: null,
			cohorts: [],
			affiliate: null,
			// activeLicenseFilter: null,

			loading: false,
			error: false,

			sortBy: 'sessionTime',
			sortByIndex: null,
			sortAsc: false,

			items: [],
			count: 0,

			superColumns: [],
			columns: [],
			summaryMode: true,

      popOut: false,
			selectedLicense: null,

			selectedReportTemplate: null,
			reportTemplates: [
				{
					name: 'ACT Diagnostic Test + Subtests',
					course: { courseID: 1, courseName: 'ACT' },
					reportNodes: [ { nodeIDs: [50483], title: 'ACT Diagnostic Test'}, { nodeIDs: [50486], title: 'EG' }, { nodeIDs: [50485], title: 'RC'}, { nodeIDs: [50487], title: 'SR' }, { nodeIDs: [57551], title: 'Math' } ]
				},
				{
					name: 'ACT Diag + Reinforcement Tests + Classroom',
					course: { courseID: 1, courseName: 'ACT' },
					reportNodes: [ { nodeIDs: [50483], title: 'ACT Diagnostic Test'}, { nodeIDs: [177, 60414, 29, 445], title: 'Reinforcement Tests' }, { nodeIDs: [4], title: 'Classroom'} ]
				},
				{
					name: 'ACT Testing Center + Classroom',
					course: { courseID: 1, courseName: 'ACT' },
					reportNodes: [ { nodeIDs: [23452], title: 'Testing Center'}, { nodeIDs: [4], title: 'Classroom' } ]
				},
				{
					name: 'ACT English: Tests + Classroom',
					course: { courseID: 1, courseName: 'ACT' },
					reportNodes: [ { nodeIDs: [50486], title: 'English Diagnostic Test'}, { nodeIDs: [177], title: 'English Reinforcement Tests' }, { nodeIDs: [12], title: 'English Classroom'} ]
				},
				{
					name: 'ACT Reading Comp: Tests + Classroom',
					course: { courseID: 1, courseName: 'ACT' },
					reportNodes: [ { nodeIDs: [50485], title: 'Reading Comp Diagnostic Test'}, { nodeIDs: [60414], title: 'Reading Comp Reinforcement Tests' }, { nodeIDs: [13], title: 'Reading Comp Classroom'} ]
				},
				{
					name: 'ACT Math: Tests + Classroom',
					course: { courseID: 1, courseName: 'ACT' },
					reportNodes: [ { nodeIDs: [57551], title: 'Math Diagnostic Test'}, { nodeIDs: [29], title: 'Math Reinforcement Tests' }, { nodeIDs: [15], title: 'Math Classroom'} ]
				},
				{
					name: 'ACT Science Reasoning: Tests + Classroom',
					course: { courseID: 1, courseName: 'ACT' },
					reportNodes: [ { nodeIDs: [50487], title: 'Science Diagnostic Test'}, { nodeIDs: [445], title: 'Science Reinforcement Tests' }, { nodeIDs: [32], title: 'Science Classroom'} ]
				},
				{
					name: 'SAT Diagnostic Test',
					course: { courseID: 3, courseName: 'SAT' },
					reportNodes: [ { nodeIDs: [58360], title: 'SAT Diagnostic Test' }, { nodeIDs: [58361], title: 'RC' }, { nodeIDs: [58367], title: 'EG' }, { nodeIDs: [58372], title: 'Math' } ]
				}
			],
		}
	},


	computed: {
		t() { return Tooltips },

		canRunReport() {
			if( !this.selectedReportTemplate && (!this.course || !this.nodes.length) ) return false

			// Must select user OR cohort OR affiliate
			if( (!this.user && !this.userID) && (!this.cohorts.length && !this.cohortID) && (!this.affiliate && !this.affiliateID) ) return false
			return true
		}
	},



	watch: {
		selectedReportTemplate( value ) { this.selectReportTemplate( value ) },
	},



	created() { this.initialize() },


	methods: {
		async initialize( asCSV = false ) {

			this.error = false
			this.items = []

			if( !this.canRunReport ) return

			try {
				this.loading = true

				const req = new PaginatedRequest( this.sortBy, this.sortAsc )
				req.sortByIndex = this.sortByIndex !== null ? this.sortByIndex - 2 : null // subtract 2 because sortByIndex is the index of the superColumn, NOT the node
				req.courseID = this.course.courseID
				req.reportNodeSets = this.nodes
				req.userIDs = this.user ? [ this.user.userID ] : ( this.userID ? [ this.userID ] : null )
				req.customerID = this.customer ? this.customer.id : this.customerID
				req.cohortIDs = this.cohorts.length ? this.cohorts.map( c => c.cohortID ) : ( this.cohortID ? [ this.cohortID ] : null )
				req.affiliateID = this.affiliate ? this.affiliate.id : this.affiliateID
				req.csv = asCSV
				// req.activeLicenses = this.activeLicenseFilter
	
				// Get main table data
				const res = await StudentStatisticsAPI.getEScoreList( req )
				if( asCSV === true ) return
				
				this.count = res.data.count

				this.nodes = res.data.nodes
				this.items = res.data.rows

				this.initializeColumns()
				this.error = false
				
			} catch( e ) {
				this.items = []
				this.count = 0
				this.error = true
				console.error( e )

			} finally {
				this.loading = false
			}
		},



		initializeColumns() {
			var superColumnIndex = 0

			const hues = []
			const hueSize = 360 / this.nodes.length
			for( var i=0; i < this.nodes.length; i++ ) {
				hues.push( hueSize * i )
			}
			

			this.superColumns = [ { displayName: '', colspan: 1 }, { displayName: '', colspan: 2, children: [ { displayName: 'TIP', colspan: 2 } ] } ]
			superColumnIndex = 1 // idx 0 and 1 are added above

			this.columns = [
				{
					displayName: 'Student Name',
					propertyName: 'firstName',
					sortable: true,
					sticky: true,
					summaryColumn: true,
					displayFunction: function( item ) { return `<span class="link">${item.firstName} ${item.lastName}</span>` },
					clickHandler: async ( e, item ) => {
						e.stopPropagation()
						const userID = item.userID
						const licenseKey = item.licenseKey
						const license = await LicenseAPI.getLicense( licenseKey, userID )

						if( !license ) return
						this.selectedLicense = license
						this.$refs.licenseModal.open()
					},
					style: function() { return 'background: rgba( 255,255,255, 0.75 ); backdrop-filter: blur(20px); border-bottom: 1px solid #ccc;' },
					headerStyle: 'background: rgba( 255,255,255, 0.75 ); backdrop-filter: blur(20px); z-index: 2; border-top-left-radius: 1em; border-top-right-radius: 1em;',
				},
				{
					displayName: 'Course-Wide Node Time',
					propertyName: 'nodeTime',
					tooltip: 'Sum of time spent on each node in the course',
					displayFunction: function( item ) { return Math.hms( item.nodeTime || 0 ) },
					sortable: true,
					summaryColumn: true,
				},
				{
					displayName: 'Session Time (TIP)',
					propertyName: 'sessionTime',
					tooltip: 'Total session time in the course',
					displayFunction: function( item ) { return Math.hms( item.sessionTime || 0 ) },
					sortable: true,
					summaryColumn: true,
				}
			];


			
			for( var [nodeIndex, nodeSet] of this.nodes.entries() ) {

				const hue = hues[ nodeIndex % hues.length ]

				superColumnIndex++
				this.superColumns.push( {
					displayName: nodeSet.title,
					colspan: 36,
					summaryColspan: 12,
					style: `background: hsla(${ hue }, 100%, 89%, 0.25); color: hsl(${hue}, 100%, 20%) !important;`,
					children: [
						{
							displayName: 'Questions Answered Correctly',
							colspan: 4,
							summaryColspan: 1,
						},
						{
							displayName: 'Questions Answered Incorrectly',
							colspan: 4,
							summaryColspan: 1,
						},
						{
							displayName: '"Zero" Questions',
              colspan: 4,
              summaryColspan: 1,
						},
						{
							displayName: 'All Questions',
              colspan: 4,
              summaryColspan: 2,
						},
						{
							displayName: 'Video Watch Time',
							colspan: 4,
							summaryColspan: 2,
						},
						{
							displayName: 'Non-Video Media',
							colspan: 4,
							summaryColspan: 2,
						},
						{
							displayName: 'All Nodes',
							colspan: 2,
							summaryColspan: 1,
						},
						{
							displayName: 'Remediation',
              colspan: 10,
              summaryColspan: 2,
							style: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						}
					]
				})

				this.columns.push(

					// Correct Qs
					{
						displayName: '# Qs',
						sortByName: [ 'numCorrect', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,  // sets "this.nodeIndex" for displayFunction
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numCorrect : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},
					{
						displayName: 'Total Time',
						sortByName: [ 'secondsCorrect', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].secondsCorrect || 0) : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},
					{
						displayName: 'Avg Time<br>(%R)',
						sortByName: [ 'avgTimeCorrect', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) {
							const score = item.effortScores[this.nodeIndex]
							if( score === undefined || score === null ) return '--'

							const mainTxt = Math.hms( item.effortScores[this.nodeIndex].avgTimeCorrect || 0 )
							const pct = Math.round( item.effortScores[this.nodeIndex].remediationSecondsCorrect * 100 / item.effortScores[this.nodeIndex].secondsCorrect )
							return `${ mainTxt }<br><span style="color: #777; font-size: 8pt;">(${ pct }%)</span>`
						},
						style: function() { return 'min-width: 6em;' },
						getPopover: function( item ) {
							return `<div>${ item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].secondsCorrect || 0) : '--' } 
							/ ${ item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numCorrect : '--' } Qs</div>`
						},
						summaryColumn: true,
						summaryStyle: `background: hsla(${ hue }, 100%, 89%, 0.5);`,
					},
					{
						displayName: 'Max Time',
						sortByName: [ 'maxTimeCorrect', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].maxTimeCorrect || 0) : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},


					// Incorrect Qs
					{
						displayName: '# Qs',
						sortByName: [ 'numIncorrect', superColumnIndex ],
            sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numIncorrect : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},
					{
						displayName: 'Total Time',
						sortByName: [ 'secondsIncorrect', superColumnIndex ],
            sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].secondsIncorrect || 0) : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},
					{
						displayName: 'Avg Time<br>(%R)',
						sortByName: [ 'avgTimeIncorrect', superColumnIndex ],
            sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) {
							const score = item.effortScores[this.nodeIndex]
							if( score === undefined || score === null ) return '--'

							const mainTxt = Math.hms( item.effortScores[this.nodeIndex].avgTimeIncorrect || 0 )
							const pct = Math.round( item.effortScores[this.nodeIndex].remediationSecondsIncorrect * 100 / item.effortScores[this.nodeIndex].secondsIncorrect )
							return `${ mainTxt }<br><span style="color: #777; font-size: 8pt;">(${ pct }%)</span>`
						},
						style: function() { return 'min-width: 6em;' },
						getPopover: function( item ) {
							return `<div>${ item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].secondsIncorrect || 0) : '--' } 
							/ ${ item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numIncorrect : '--' } Qs</div>`
						},
						summaryColumn: true,
						summaryStyle: `background: hsla(${ hue }, 100%, 89%, 0.5);`,
					},
					{
						displayName: 'Max Time',
						sortByName: [ 'maxTimeIncorrect', superColumnIndex ],
            sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].maxTimeIncorrect || 0) : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},


					// Zero Questions
					{
						displayName: '# Qs',
						sortByName: [ 'numZeroQs', superColumnIndex ],
            sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numZeroQs : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},
					{
						displayName: 'Total Time',
						sortByName: [ 'secondsZeroQs', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].secondsZeroQs || 0) : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},
					{
						displayName: 'Avg Time',
						sortByName: [ 'avgTimeZeroQs', superColumnIndex ],
            sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].avgTimeZeroQs || 0) : '--' },
						style: function() { return 'min-width: 6em;' },
						getPopover: function( item ) {
							return `<div>${ item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].secondsZeroQs || 0) : '--' } 
							/ ${ item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numZeroQs : '--' } Qs</div>`
						},
						summaryColumn: true,
						summaryStyle: `background: hsla(${ hue }, 100%, 89%, 0.5);`,
					},
					{
						displayName: 'Max Time',
						sortByName: [ 'maxTimeZeroQs', superColumnIndex ],
            sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].maxTimeZeroQs || 0) : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},


					// All Questions
					{
						displayName: '# Qs',
						sortByName: [ 'numQAnswered', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numQAnswered : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},
					{
						displayName: 'Total Time',
						sortByName: [ 'secondsQuestions', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].secondsQuestions || 0) : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryStyle: `background: hsla(${ hue }, 100%, 89%, 0.5);`,
						summaryColumn: true,
					},
					{
						displayName: 'Avg Time<br>(%R)',
						sortByName: [ 'avgTimeQuestions', superColumnIndex ],
            sortable: true,
						nodeIndex: nodeIndex,
						tooltipTime: 30000,
						tooltip: "%R = R Time in questions / Total time in questions",
            displayFunction: function( item ) {
							const score = item.effortScores[this.nodeIndex]
							if( score === undefined || score === null ) return '--'

							const mainTxt = Math.hms( item.effortScores[this.nodeIndex].avgTimeQuestions || 0 )
							const pct = Math.round( item.effortScores[this.nodeIndex].remediationTimePercentQuestions )
							return `${ mainTxt }<br><span style="color: #777; font-size: 8pt;">(${ pct }%)</span>`
						},
						getPopover: function( item ) {
							return `<div>${ item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].secondsQuestions || 0) : '--' } 
							/ ${ item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numQAnswered : '--' } Qs</div>`
						},
						style: function() { return 'min-width: 6em;' },
						summaryStyle: `background: hsla(${ hue }, 100%, 89%, 0.5);`,
						summaryColumn: true,
					},
					{
						displayName: 'Max Time',
						sortByName: [ 'maxTimeQuestions', superColumnIndex ],
            sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].maxTimeQuestions || 0) : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},


					// Videos
					{
						displayName: '# Videos',
						sortByName: [ 'numVideosExposed', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numVideosExposed : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryColumn: false,
					},
					{
						displayName: 'Video % Consumed',
						sortByName: [ 'percentVideoTimeConsumed', superColumnIndex ],
						sortable: true,
            nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? (item.effortScores[this.nodeIndex].percentVideoTimeConsumed || 0) + '%' : '--' },
            style: function( item ) { return'min-width: 6em;' },
						summaryStyle: `background: hsla(${ hue }, 100%, 89%, 0.5);`,
            summaryColumn: true,
					},
					{
						displayName: 'Video Time Avail.',
						sortByName: [ 'exposedVideoDurationSeconds', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].exposedVideoDurationSeconds || 0) : '--' },
						style: function() { return 'min-width: 6em;' },
						summaryStyle: `background: hsla(${ hue }, 100%, 89%, 0.5);`,
						summaryColumn: true,
					},
					{
						displayName: 'Video Time Consumed',
						sortByName: [ 'videoPlayheadSeconds', superColumnIndex ],
						sortable: true,
            nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].videoPlayheadSeconds || 0) : '--' },
            style: function( item ) { return'min-width: 6em;' },
            summaryColumn: false,
					},

					{
						displayName: '# Media Nodes',
						sortByName: [ 'numMedia', superColumnIndex ],
						sortable: true,
            nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numMedia : '--' },
            style: function( item ) { return'min-width: 6em;' },
            summaryColumn: false,
					},
					{
						displayName: 'Total Time',
						sortByName: [ 'secondsMedia', superColumnIndex ],
						sortable: true,
            nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].secondsMedia || 0) : '--' },
            style: function( item ) { return'min-width: 6em;' },
						summaryStyle: `background: hsla(${ hue }, 100%, 89%, 0.5);`,
            summaryColumn: true,
					},
					{
						displayName: 'Avg Time',
						sortByName: [ 'avgTimeMedia', superColumnIndex ],
            sortable: true,
            nodeIndex: nodeIndex,
            displayFunction: function( item ) {
							const score = item.effortScores[this.nodeIndex]
							if( score === undefined || score === null ) return '--'
							return Math.hms( item.effortScores[this.nodeIndex].avgTimeMedia || 0 )
						},
						
            style: function( item ) { return'min-width: 6em;' },
						summaryStyle: `background: hsla(${ hue }, 100%, 89%, 0.5);`,
            summaryColumn: true,
					},
					{
						displayName: 'Max Time',
						sortByName: ['maxTimeMedia', superColumnIndex ],
            sortable: true,
            nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms(item.effortScores[this.nodeIndex].maxTimeMedia || 0) : '--' },
            style: function( item ) { return'min-width: 6em;' },
						summaryColumn: false,
					},

					// All Nodes (total time)
					{
						displayName: '# Nodes',
            sortByName: [ 'numNodes', superColumnIndex ],
            sortable: true,
            nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? item.effortScores[this.nodeIndex].numNodes : '--' },
            style: function( item ) { return'min-width: 6em;' },
            summaryColumn: false,
					},
					{
						displayName: 'Total Time Spent',
						sortByName: [ 'secondsOverall', superColumnIndex ],
            sortable: true,
            nodeIndex: nodeIndex,
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex]? Math.hms(item.effortScores[this.nodeIndex].secondsOverall || 0) : '--' },
						style: function( item ) { return'min-width: 6em;' },
            summaryStyle: `background: hsla(${ hue }, 100%, 89%, 0.5);`,
						summaryColumn: true,
					},

          // Remediation Columns
					{
						displayName: 'Correct Qs: Total R Time',
						sortByName: [ 'remediationSecondsCorrect', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms( item.effortScores[this.nodeIndex].remediationSecondsCorrect || 0 ) : '--' },
						style: function( item ) { return `background: hsla(${ hue }, 100%, 70%, 0.25); min-width: 6em;` },
						headerStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryColumn: false,
					},
					{
						displayName: 'Correct Qs: Avg R Time',
						sortByName: [ 'avgRemediationTimeCorrect', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms( item.effortScores[this.nodeIndex].avgRemediationTimeCorrect || 0 ) : '--' },
						style: function( item ) { return `background: hsla(${ hue }, 100%, 70%, 0.25); min-width: 6em;` },
						headerStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryColumn: false,
					},
					{
						displayName: 'Incorrect Qs: Total R Time',
						sortByName: [ 'remediationSecondsIncorrect', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms( item.effortScores[this.nodeIndex].remediationSecondsIncorrect || 0 ) : '--' },
						style: function( item ) { return `background: hsla(${ hue }, 100%, 70%, 0.25); min-width: 6em;` },
						headerStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryColumn: false,
					},
					{
						displayName: 'Incorrect Qs: Avg R Time',
						sortByName: [ 'avgRemediationTimeIncorrect', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms( item.effortScores[this.nodeIndex].avgRemediationTimeIncorrect || 0 ) : '--' },
						style: function( item ) { return `background: hsla(${ hue }, 100%, 70%, 0.25); min-width: 6em;` },
						headerStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryColumn: false,
					},
					{
						displayName: 'All Qs: Total R Time',
						sortByName: [ 'remediationSecondsQuestions', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms( item.effortScores[this.nodeIndex].remediationSecondsQuestions || 0 ) : '--' },
						style: function( item ) { return `background: hsla(${ hue }, 100%, 70%, 0.25); min-width: 6em;` },
						headerStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryColumn: false,
					},
					{
						displayName: 'All Qs: Avg R Time',
						sortByName: [ 'avgRemediationTimeQuestions', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms( item.effortScores[this.nodeIndex].avgRemediationTimeQuestions || 0 ) : '--' },
						style: function( item ) { return `background: hsla(${ hue }, 100%, 70%, 0.25); min-width: 6em;` },
						headerStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryColumn: false,
					},					
					{
						displayName: 'Overall: Total R Time<br>(% of Total)',
						sortByName: [ 'remediationSecondsOverall', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						tooltipTime: 30000, // 30 seconds
						tooltip: "Review time on non-zero Q's + ALL time on zeroQ's, video, media, and collections",
						displayFunction: function( item ) {
							const score = item.effortScores[this.nodeIndex]
							if( score === undefined || score === null ) return '--'

							const mainTxt = Math.hms( item.effortScores[this.nodeIndex].remediationSecondsOverall || 0 )
							const pct = Math.round( item.effortScores[this.nodeIndex].remediationSecondsOverall * 100 / item.effortScores[this.nodeIndex].secondsOverall )
							return `${ mainTxt }<br><span style="color: #777; font-size: 8pt;">(${ pct }%)</span>`
						},
						style: function( item ) { return `background: hsla(${ hue }, 100%, 70%, 0.25); min-width: 6em;` },
						headerStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryColumn: true,
					},
					{
						displayName: 'Overall: Avg R Time',
						sortByName: [ 'avgRemediationTimeOverall', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						tooltipTime: 30000, // 30 seconds
						tooltip: "Review time on non-zero Q's + ALL time on zeroQ's, video, media, and collections\n------------------------------------------------------\n Total # Nodes",
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? Math.hms( item.effortScores[this.nodeIndex].avgRemediationTimeOverall || 0 ) : '--' },
						style: function( item ) { return `background: hsla(${ hue }, 100%, 70%, 0.25); min-width: 6em;` },
						headerStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						// summaryStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryColumn: false,
					},
					{
						displayName: 'Overall: R-Time %',
						sortByName: [ 'remediationTimePercentOverall', superColumnIndex ],
						sortable: true,
						nodeIndex: nodeIndex,
						tooltipTime: 30000, // 30 seconds
						tooltip: "Review time on non-zero Q's + ALL time on zeroQ's, video, media, and collections * 100\n------------------------------------------------------\nTotal Time",
						displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? ( item.effortScores[this.nodeIndex].remediationTimePercentOverall || 0 ) + '%' : '--' },
						style: function( item ) { return item.effortScores[this.nodeIndex] && item.effortScores[this.nodeIndex].remediationTimePercentOverall < 50 ? `background: orange` : `background: hsla(${ hue }, 100%, 70%, 0.25); min-width: 6em;` },
						headerStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
						summaryColumn: true,
					},
					{
						displayName: 'Overall: V / R (%)',
						sortByName: [ 'videoWallTimePercentOfRemediationTime', superColumnIndex ],
						sortable: true,
            nodeIndex: nodeIndex,
						tooltipTime: 30000, // 30 seconds
						tooltip: "videoWallTimeSeconds * 100\n------------------------------------------------------\nReview time on non-zero Q's + ALL time on zeroQ's, video, media, and collections",
            displayFunction: function( item ) { return item.effortScores[this.nodeIndex] ? ( item.effortScores[this.nodeIndex].videoWallTimePercentOfRemediationTime || 0 ) + '%' : '--' },
						style: function( item ) { return `background: hsla(${ hue }, 100%, 70%, 0.25); min-width: 6em;` },
						headerStyle: `background: hsla(${ hue }, 100%, 70%, 0.25);`,
            summaryColumn: false,
					},
				)

			}
		},


		sort( prop ) {
			
			if( !Array.isArray( prop ) ) {
				if( this.sortBy === prop ) {
					this.sortAsc = !this.sortAsc
					this.initialize()
					return
				}

				this.sortBy = prop
				this.sortByIndex = null
				this.sortAsc = false
				this.initialize()
				return
			}


			if( !Array.isArray(prop) || prop.length < 2 ) return

			const propName = prop[0]
			const superColIndex = prop[1]

			if( this.sortBy === propName && this.sortByIndex === superColIndex ) {
				this.sortAsc = !this.sortAsc
				this.initialize()
				return
			}

			this.sortBy = propName
			this.sortByIndex = superColIndex
			this.sortAsc = false
			this.initialize()
		},


		selectRow() {
			// TODO: what to do when clicking on a row? Open details?
		},


		selectNodes( nodeList ) {
			if( nodeList === null ) {
				this.nodes = []
				return
			}

      this.nodes = []
			for( var node of nodeList ) {
				// Use the "node" format from the getEScoreList API call (see this.initialize())
				this.nodes.push( { nodeIDs: [ node.id ], title: node.name, numQuestions: null } )
			}
		},


		async selectUser( user = null ) {
			this.user = user
		},


		selectCustomer( customer = null ) {
			this.customer = customer
		},


		selectCohort( cohorts = [] ) {
			this.cohorts = cohorts
		},


		selectAffiliate( affiliate = null ) {
			this.affiliate = affiliate
		},

		selectReportTemplate( template ) {
			if( template === null ) return

			console.debug("Selecting report template")

			const t = template
			this.course = t.course
			this.nodes = t.reportNodes

			this.selectedNodes = []
			for( var ns of t.reportNodes ) {
				for( var nid of ns.nodeIDs) {
					this.selectedNodes.push( { id: nid, name: ns.title } )
				}
			}
		},

		runReport( asCSV = false ) {
			this.page = 1
			this.initialize( asCSV )
		}
	}

}
</script>



<style scoped>
.thing {
	color: hsla(0, 100%, 89%, 0.5);
}
</style>