// import StoreManager from '../StoreManager/StoreManager'


export default class Tooltips {


	
	// -----------------------------------------------------------------
	// Short names to make Vue template lines shorter
	// -----------------------------------------------------------------
	
	// SHOW
	static s(ev, position = "top", distance = 0, hideTime = 3000, align = 'center') {
		this.showTooltip(ev, position, distance, hideTime, align)
	}
	
	// HIDE
	static h(ev) { this.hideTooltip(ev) }



	static showTooltip( ev, position = "top", distance = 0, hideTime = 3000, align = "center" ) {
		if( !ev || !ev.target || !ev.target.attributes["tooltip"] || !ev.target.attributes["tooltip"].value ) return;

    let tooltipID = "tooltip-" + Math.floor(Math.random() * 1000)
    ev.target.tooltipID = tooltipID

    let elem = document.elementFromPoint(ev.clientX, ev.clientY)
    let rect = elem.getBoundingClientRect()
		// console.log(rect)

		let tooltip = document.createElement("div")
		tooltip.id = tooltipID
		tooltip.style.position = "absolute"
		tooltip.classList.add("tooltip")
		tooltip.classList.add(`tooltip${align}`)

    tooltip.setAttribute("data-tooltip", ev.target.attributes["tooltip"].value)

    if(position == "left") {
			tooltip.style.top = rect.top + (rect.height / 2) + "px"
			tooltip.style.left = rect.left + distance + "px"
			tooltip.style.width = "1px"
			tooltip.style.height = "1px"
			tooltip.setAttribute("data-tooltip-location", "left")

		}else if(position == "right") {
			tooltip.style.top = rect.top + (rect.height / 2) + "px"
			tooltip.style.left = rect.right + distance + "px"
			tooltip.style.width = "1px"
			tooltip.style.height = "1px"
			tooltip.setAttribute("data-tooltip-location", "right")

		}else if(position == "top") {
			tooltip.style.top = rect.top + distance + "px"
			tooltip.style.left = rect.left + (rect.width / 2) + "px"
			tooltip.style.width = "1px"
			tooltip.style.height = "1px"
			tooltip.setAttribute("data-tooltip-location", "top")

		}else if(position == "bottom") {
			tooltip.style.top = rect.bottom + distance + "px"
			tooltip.style.left = rect.left + (rect.width / 2) + "px"
			tooltip.style.width = "1px"
			tooltip.style.height = "1px"
			tooltip.setAttribute("data-tooltip-location", "bottom")

		}
		else if(position == "top-left") {
			tooltip.style.top = rect.top + distance + "px"
			tooltip.style.left = rect.left + (rect.width / 2) + "px"
			tooltip.style.width = "1px"
			tooltip.style.height = "1px"
			tooltip.setAttribute("data-tooltip-location", "top-left")

		}else if(position == "top-right") {
			tooltip.style.top = rect.top + distance + "px"
			tooltip.style.left = rect.right - (rect.width / 2) + "px"
			tooltip.style.width = "1px"
			tooltip.style.height = "1px"
			tooltip.setAttribute("data-tooltip-location", "top-right")

		}else if(position == "bottom-left") {
			tooltip.style.top = rect.bottom + distance + "px"
			tooltip.style.left = rect.left + (rect.width / 2) + "px"
			tooltip.style.width = "1px"
			tooltip.style.height = "1px"
			tooltip.setAttribute("data-tooltip-location", "bottom-left")

		}else if(position == "bottom-right") {
			tooltip.style.top = rect.bottom + distance + "px"
			tooltip.style.left = rect.right - (rect.width / 2) + "px"
			tooltip.style.width = "1px"
			tooltip.style.height = "1px"
			tooltip.setAttribute("data-tooltip-location", "bottom-right")
		}

    document.body.appendChild(tooltip)
		// let height = document.getElementById(tooltipID).style.height
		let height = 50
		let width = 200
		let halfWidth = width / 2

		// console.log(height, width, halfWidth)

		if( position == "top" && rect.top < height ) {
			tooltip.style.top = rect.bottom + distance + "px"
			position = "bottom"
			tooltip.setAttribute("data-tooltip-location", "bottom")
		}

		if( halfWidth > rect.left && (position == "top" || position == "bottom") ) {
			tooltip.setAttribute("data-tooltip-location", (position + "-right"))
			// console.log(tooltip.getAttribute("data-tooltip-location"))
		}
		if( halfWidth > (window.innerWidth - rect.right) && (position == "top" || position == "bottom") ) {
			tooltip.setAttribute("data-tooltip-location", (position + "-left"))
			// console.log(tooltip.getAttribute("data-tooltip-location"))
		}


		window.setTimeout(() => {
			tooltip.classList.add("active")
		}, 10)


		tooltip.timeoutID = setTimeout(() => {
			tooltip.classList.remove("active")
			setTimeout(() => {
				if(!tooltip.parentNode) return;
				tooltip.parentNode.removeChild(tooltip)
			}, 500)
		}, hideTime)

		Tooltips.allTooltips.push( tooltip )
  }

  static hideTooltip(ev){

    // let id = ev.target.tooltipID
    // let elem = document.getElementById(id)
		// if(!elem) return

		// elem.classList.remove("active")

    // window.setTimeout(() => {
		// 	if(!elem.parentNode) return;
    //   elem.parentNode.removeChild(elem)
    // }, 1000)

		this.hideAllTooltips()
  }


	static hideAllTooltips() {
		for(var elem of Tooltips.allTooltips) {
			elem.classList.remove("active")
			clearTimeout( elem.timeoutID )

			window.setTimeout(() => {
				if(!elem.parentNode) return;
				elem.parentNode.removeChild(elem)
			}, 1000)
		}
		Tooltips.allTooltips = []
	}
}

// Static (singleton) array of ALL current tooltips
Tooltips.allTooltips = []
