<template>
<StretchModal ref="modal">
	<div class="pa-1">
		<div class="section-header">
			<h1 class="left">External Test Report for {{ summary.cohortName }}</h1>
		</div>
		<div class="reset-float"></div>

		<div>Students in cohort: {{ summary.studentCount }}</div>
		<br>
		<div v-if="summary && ((summary.practiceTests && summary.practiceTests.length) || (summary.realTests && summary.realTests.length))">Select test(s) to use:</div>
		<br>
		<section v-if="summary && ((summary.practiceTests && summary.practiceTests.length) || (summary.realTests && summary.realTests.length))" class="flex-row flex-justify-around flex-gap-1">
			<div v-if="summary.practiceTests">
				<div class="section-header">Practice Tests</div>
				<div v-for="test in summary.practiceTests" :key="test.testID">
					<input type="checkbox" :value="test.testID" :id="`test-${test.testID}`" v-model="practiceTestIDs" />
					<label :for="`test-${test.testID}`">{{ test.testName }} ({{ test.courseName }})</label>
					<ul>
						<li>{{ test.numStudents }} students with scores</li>
						<li>{{ test.numStudentsRaw }} raw scores ({{ test.numStudentsAllRaw }} complete)</li>
						<li>{{ test.numStudentsScaled }} scaled scores ({{ test.numStudentsAllScaled }} complete)</li>
					</ul>
				</div>
			</div>

			<div v-if="summary.realTests">
				<div class="section-header">Real Tests</div>
				<div v-for="test in summary.realTests" :key="test.testID">
					<input type="checkbox" :value="test.testID" :id="`test-${test.testID}`" v-model="realTestIDs" />
					<label :for="`test-${test.testID}`">{{ test.testName }} ({{ test.courseName }})</label>
					<ul>
						<li>{{ test.numStudents }} students with scores</li>
						<li>{{ test.numStudentsRaw }} raw scores ({{ test.numStudentsAllRaw }} complete)</li>
						<li>{{ test.numStudentsScaled }} scaled scores ({{ test.numStudentsAllScaled }} complete)</li>
					</ul>
				</div>
			</div>
		</section>
		<section v-else class="bold center" style="font-size: 1.5em;">No Tests Available</section>
		<div v-if="practiceTestIDs.length > 1">Practice test scores will be "inverse super-scored"</div>
		<div v-if="realTestIDs.length > 1">Real test scores will be "super-scored"</div>

		<br><br>
		<div v-if="summary.availableTestSections && summary.availableTestSections.length">Select Test Sections to Include:</div>
		<div>
			<div v-for="code in summary.availableTestSections" :key="code">
				<input type="checkbox" :value="code" :id="code" v-model="testSectionCodes" />
				<label :for="code">{{ code }}</label>
			</div>
		</div>

		<br><br>

		<div v-if="summary.availableTestSections && summary.availableTestSections.length" class="flex-row flex-wrap flex-gap-2 flex-justify-center">
			<div>
				<header>Analysis Settings</header>
				<div class="settingGroup"><ToggleButton v-model="redact" /> Redact student names</div>
				<div class="settingGroup">National Average: <input class="shortNumber" v-model.number="nationalAverage" /></div>
				<div class="settingGroup">
					<div class="title">Super Improvers</div>
					"Super Improvers" are students who:<br>
					improve by <input class="shortNumber" v-model.number="superImproverMinDelta" /> points or more.
				</div>
				<div class="settingGroup">
					<div class="title">High Flyers</div>
					<div>"High Flyers" are students who:</div>
					<ul>
						<li>Improved (&Delta; > 0)</li>
            <li>Scored <input class="shortNumber" v-model.number="highFlyerMinScore" /> or higher on the final (real) test.</li>
          </ul>
				</div>
				<div class="settingGroup">
					<div class="title">High Score Cohort</div>
					Include students who score <strong><input class="shortNumber" v-model.number="highScoreCohortMinScore" /> or higher</strong><br>
					on the final (real) test.
				</div>
				<div class="settingGroup">
					<div class="title">Under-Achievers / Unmotivated</div>
					<div>Under-achievers are students who:</div>
					<ul>
						<li>Did not improve (&Delta; &le; 0)</li>
						<li>Scored <input class="shortNumber" v-model.number="underAchieverMaxScore" /> or lower on the final (real) test.</li>
					</ul>
				</div>
				<div class="settingGroup">
					<div class="title">Top Student Lists</div>
					"Top Student" lists include at least <input class="shortNumber" v-model.number="topStudentListMinLength" /> students,<br>
					and ALL students who improve <input class="shortNumber" v-model.number="topStudentListMinDelta" /> or more points.<br>
					Highlight students who improve <input class="shortNumber" v-model.number="topStudentListMinHighlight" /> or more points.
				</div>

			</div>

			<div>
				<header>Title Page</header>
				<div class="settingGroup">
					<div>Report Title:<br><textarea style="width: 100%;" v-model="reportTitle"></textarea></div>
					<br>
					<div>Cohort Date: <input v-model="cohortDate" placeholder="2022-23" /></div>
					<br>
					<div>Title Page Description (Text / HTML):<br><textarea style="width: 100%;" v-model="titlePageDescription"></textarea></div><br>
					<br>
					<div>Principal Name: <input v-model="principalName" /></div>
					<div>Teacher Name: <input v-model="teacherName" /></div>
				</div>
				
				<header>About the School</header>
				<div class="settingGroup">
					<div>Year Founded: <input class="shortNumber" v-model="schoolFounded" placeholder="1999" /></div>
					<div># Faculty: <input class="shortNumber" v-model.number="facultyCount" /></div>
					<div>Grades: <input class="shortNumber" v-model="gradeRange" placeholder="9-12" /></div>
					<div># Students: <input class="shortNumber" v-model.number="studentCount" /></div>
					<div>Student/Teacher ratio: <input class="shortNumber" v-model="studentTeacherRatio" placeholder="20:1" /></div>
					<div>Setting: <input v-model="setting" placeholder="Suburban Knoxville" /></div>
				</div>
			</div>
			
			<div>
				<header>Experimental Design Description</header>
				<div class="settingGroup">
          <div>Baseline Data Design and Collection:<br><textarea style="width: 100%;" v-model="baselineDataParagraph"></textarea></div>
          <div>PowerPrep Paragraph:<br><textarea style="width: 100%;" v-model="powerPrepParagraph"></textarea></div>
          <div>Final/Real Data Design and Collection:<br><textarea style="width: 100%;" v-model="finalDataParagraph"></textarea></div>
          <div>Comparison of baseline to final:<br><textarea style="width: 100%;" v-model="comparisonParagraph"></textarea></div>
        </div>
			</div>

		</div>

		<div class="right">
			<button
				@click="downloadReport()"
				:disabled="practiceTestIDs.length == 0 || realTestIDs.length == 0"
				class="SquareBlueButton my-1" 
				:class="{'disabled' : practiceTestIDs.length == 0 || realTestIDs.length == 0}"
			>
				Download .zip File
				<span v-if="downloading" class='icon-spinner4 spin-loader'></span>
			</button>
			</div>

	</div>
</StretchModal>
</template>

<script>
import ExternalTestsAPI from '@/api/ExternalTestsAPI.js'
import StretchModal from '@/components/utilities/StretchModal.vue'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: 'ExternalTestReportSettingsDialog',

	components: {
		StretchModal,
		ToggleButton,
	},

	props: {
		customerID: Number,
	},

	
	data() {
		return {
			cohortID: null,
			summary: {},
			practiceTestIDs: [],
			realTestIDs: [],
			testSectionCodes: [],
			downloading: false,

			redact: false,
			nationalAverage: 19.5,
      topStudentListMinLength: 10,
      topStudentListMinDelta: 5,
			topStudentListMinHighlight: 10,
			superImproverMinDelta: 6,
      highFlyerMinScore: 23,
      highScoreCohortMinScore: 21,
      underAchieverMaxScore: 14,

			cohortDate: '',
			reportTitle: '',
			titlePageDescription: '',
			schoolFounded: '',
			principalName: '',
			teacherName: '',
			facultyCount: 0,
			gradeRange: '',
			studentCount: 0,
      studentTeacherRatio: '',
      setting: '',

			baselineDataParagraph: '',
			powerPrepParagraph: '',
			finalDataParagraph: '',
			comparisonParagraph: '',
		}
	},


	methods: {
		open( cohortID ) { this.initialize( cohortID ); this.$refs.modal.open() },
		close() { this.$refs.modal.close() },

		async initialize( cohortID ) {
			this.cohortID = cohortID
			this.practiceTestIDs  = []
			this.realTestIDs      = []
			this.testSectionCodes = []
			const res = await ExternalTestsAPI.getCohortTestSummary(this.cohortID, this.customerID)
			this.summary = res
			for( let test of this.summary.practiceTests ) this.practiceTestIDs.push( test.testID )
			for( let test of this.summary.realTests ) this.realTestIDs.push( test.testID )
			for( let code of this.summary.availableTestSections ) this.testSectionCodes.push( code );
		},

		async downloadReport() {
			this.downloading = true
			const req = {
				practiceTestIDs: this.practiceTestIDs,
				realTestIDs: this.realTestIDs,
        testSectionCodes: this.testSectionCodes,

				nationalAverage: this.nationalAverage,
				topStudentListMinLength: this.topStudentListMinLength,
				topStudentListMinDelta: this.topStudentListMinDelta,
				topStudentListMinHighlight: this.topStudentListMinHighlight,
				superImproverMinDelta: this.superImproverMinDelta,
				highFlyerMinScore: this.highFlyerMinScore,
				highScoreCohortMinScore: this.highScoreCohortMinScore,
				underAchieverMaxScore: this.underAchieverMaxScore,

				cohortDate: this.cohortDate,
				reportTitle: this.reportTitle,
				titlePageDescription: this.titlePageDescription,
				schoolFounded: this.schoolFounded,
				principalName: this.principalName,
				teacherName: this.teacherName,
				facultyCount: this.facultyCount,
				gradeRange: this.gradeRange,
				studentCount: this.studentCount,
				studentTeacherRatio: this.studentTeacherRatio,
				setting: this.setting,

				baselineDataParagraph: this.baselineDataParagraph,
				powerPrepParagraph: this.powerPrepParagraph,
				finalDataParagraph: this.finalDataParagraph,
				comparisonParagraph: this.comparisonParagraph,
			}
			await ExternalTestsAPI.downloadCohortTestReport( this.cohortID, this.customerID, req )
			.finally( () => { this.downloading = false } )
		}
	}
}
</script>

<style scoped>
.settingGroup {
	margin: 1em;
	padding: 0.5em;
	border: 1px solid #ccc;
	border-radius: 0.25em;
}
.settingGroup .title {
	font-weight: bold;
	font-size: 1.2em;
	text-decoration: underline;
	color: #555;
	margin-bottom: 0.5em;
}
.shortNumber {
	width: 4em;
	text-align: center;
}
</style>