<template>
<section>

	<div class="flex-row flex-justify-between flex-align-start flex-wrap flex-gap-1">


		<div>
			<input
				type="text"
				style="font-size: 1.4em; min-width: 30em;"
				v-model="modalMessage.name"
				placeholder="Subject / Name"
			/>
			<div class="flex-row flex-gap-1 mt-1">
				<div>
					<select v-model="modalMessage.typeID" @change="updateTypeID( $event )">
						<option :value="null"> --- Select Modal Type ---</option>
						<option v-for="type in modalMessageTypes" :key="type.typeID" :value="type.typeID">{{ type.name }}{{ type.defaultPriority !== null ? ` - priority ${type.defaultPriority}` : '' }}</option>
					</select>
					<button v-if="modalMessage.typeID" @click.stop="editType( modalMessage.typeID )">edit</button>
					<button @click.stop="addType()">new</button>
				</div>
				<select v-model="modalMessage.priority">
					<option :value="null">--- Select Priority ---</option>
					<option :value="10">10 - Very High</option>
					<option :value="9">9 - High</option>
					<option :value="8">8</option>
					<option :value="7">7 - Medium-high</option>
					<option :value="6">6</option>
					<option :value="5">5 - Medium</option>
					<option :value="4">4</option>
					<option :value="3">3 - Medium-low</option>
					<option :value="2">2</option>
					<option :value="1">1 - Low</option>
				</select>
				<div class="flex-row flex-align-center flex-gap-05">
					<input type="number" v-model="modalMessage.maxPreviousMessages" placeholder="max prev msgs" min="0" style="width: 10em;" />
					<span tooltip="If more than X messages display at login BEFORE this one, this message will not be included." @mouseenter="tooltips.showTooltip($event, 'bottom', 0)" @mouseleave="tooltips.hideTooltip($event)" class="icon-info" />
				</div>
			</div>
		</div>
		
		<div class="flex-column flex-align-end" :class="{'disabled' : warningNotCloseable}">
			<ToggleButton v-model="modalMessage.allowDismiss" :sync="true" />
			<span tooltip="Allow user to dismiss message (show X in top-right corner)" @mouseenter="tooltips.showTooltip($event, 'bottom', 0)" @mouseleave="tooltips.hideTooltip($event)"><span class="icon-info" /> Dismissable </span>
		</div>
	</div>




	<div class="flex-row flex-align-center mt-1">
		<div style="min-width: 6em">
			<ToggleButton v-model="modalMessage.showBody" :sync="true" />
			Body
		</div>
		<div v-if="modalMessage.showBody" class="Tab" style="background: none; margin-bottom: 0px;">
			<button :class="{'active' : bodyMode == 'tiptap'}" @click.stop="bodyMode = 'tiptap'">Rich Text</button>
			<button :class="{'active' : bodyMode == 'plain'}" @click.stop="bodyMode = 'plain'">Raw Text</button>
		</div>
	</div>

	<div v-if="modalMessage.showBody" class="border px-1 pb-1 round-05 widget">
		<div>
			<Tiptap
				v-show="bodyMode == 'tiptap'"
				:content="modalMessage.body"
				:saveDelayMS="200"
				:saved="tiptapSaved"
				@sendContentAsHTML="html => updateEditorContent(html)"
				ref="tiptap"
			/>
			<textarea
				v-show="bodyMode == 'plain'"
				v-model="modalMessage.body"
				class="border round-1 pa-1 mt-4"
				style="width: 100%; min-height: 30em"
			/>
		</div>

		<div class="mt-1 flex-row flex-gap-1" :class="{'disabled' : warningNotCloseable}">
			<div>
				<div class="flex-row flex-gap-1">
					<span>"Accept" Button Text:</span>
					<span class="icon-info" tooltip="If specified, then an action button will show in the lower-right.  The button closes the popup and records an 'accept' action from the user." @mouseenter="tooltips.showTooltip($event, 'bottom')" @mouseleave="tooltips.hideTooltip($event)" />
				</div>
				<input
					type="text"
					v-model="modalMessage.acceptButtonText"
					placeholder="Button Text"
				/>
			</div>
			<div>
				<div class="flex-row flex-gap-1">
					<span>Go to Node ID:</span>
					<span class="icon-info" tooltip="If specified, the action button will take the user to this node in the program.  Only works when displayed inside a course." @mouseenter="tooltips.showTooltip($event, 'bottom')" @mouseleave="tooltips.hideTooltip($event)" />
				</div>
				<input
					type="number"
					v-model.number="modalMessage.acceptButtonNodeID"
					placeholder="NodeID"
					style="width: 6em;"
				/>
			</div>
		</div>
	</div>

	<div class="mt-2 flex-row flex-wrap flex-gap-1">
		<div>
			<ToggleButton v-model="modalMessage.showTextResponse" :sync="true" />
			Show Text Response Widget
		</div>
		<div>
			<ToggleButton v-model="modalMessage.showVideoResponse" :sync="true" />
			Show Video Response Widget
		</div>
		<div :class="{'border round-05 pa-1' : modalMessage.showReviewResponse}">
			<div>
				<ToggleButton v-model="modalMessage.showReviewResponse" :sync="true" @change="value => updateShowReview(value)" />
				Show Review Widget
			</div>
			<div v-if="modalMessage.showReviewResponse" class="mt-05 ml-1">
				<div>
					Review Type:
					<select v-model="modalMessage.reviewType">
						<option value="program">Program</option>
						<option value="affiliate">Affiliate</option>
					</select>
				</div>
				<div>
					<ToggleButton v-model="modalMessage.showStarRating" :sync="true" />
					Show Star Rating
				</div>
				<div v-if="modalMessage.showStarRating" class="mb-05">
					<input type="text" v-model="modalMessage.reviewStarPrompt" placeholder="Custom star-rating prompt" />
				</div>
				<div>
					<ToggleButton v-model="modalMessage.showTextReview" :sync="true" />
					Allow Text Review
				</div>
				<div v-if="modalMessage.showTextReview" class="mb-05">
					<input type="text" v-model="modalMessage.reviewBodyPrompt" placeholder="Custom text-response prompt" />
				</div>
				<div>
					<ToggleButton v-model="modalMessage.showVideoReview" :sync="true" />
					Allow Video Review
				</div>
			</div>
		</div>
	</div>


	<!-- Dimensions -->
	<div class="mt-1 flex-row flex-gap-1">

		<div class="widget pa-1 border round-025">
			<div class="widgetHeader">Height (%):</div>
			<div class="flex-row flex-justify-end">
				Height:&nbsp;<input type="number" v-model.number="modalMessage.height" min="0" />&nbsp;%
				<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.height !== dVals.height}" @click="reset('height')" />
			</div>
			<div class="flex-row flex-justify-end">
				Min:&nbsp;<input type="number" v-model.number="modalMessage.minHeight" min="0" />&nbsp;%
				<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.minHeight !== dVals.minHeight}" @click="reset('minHeight')" />
			</div>
			<div class="flex-row flex-justify-end">
				Max:&nbsp;<input type="number" v-model.number="modalMessage.maxHeight" min="0" />&nbsp;%
				<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.maxHeight !== dVals.maxHeight}" @click="reset('maxHeight')" />
			</div>
		</div>
		
		<div class="widget pa-1 border round-025">
			<div class="widgetHeader">Width (%):</div>
			<div class="flex-row flex-justify-end">
				Width:&nbsp;<input type="number" v-model.number="modalMessage.width" min="0" />&nbsp;%
				<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.width !== dVals.width}" @click="reset('width')" />
			</div>
			<div class="flex-row flex-justify-end">
				Min:&nbsp;<input type="number" v-model.number="modalMessage.minWidth" min="0" />&nbsp;%
				<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.minWidth !== dVals.minWidth}" @click="reset('minWidth')" />
			</div>
			<div class="flex-row flex-justify-end">
				Max:&nbsp;<input type="number" v-model.number="modalMessage.maxWidth" min="0" />&nbsp;%
				<span class="undoIcon icon-undo ml-05" :class="{'active' : modalMessage.maxWidth !== dVals.maxWidth}" @click="reset('maxWidth')" />
			</div>
		</div>

		<div class="widget pa-1 border round-025">
			<div class="widgetHeader">Order: </div>
			Show immediately after:<br>
			<input type="number" v-model.number="modalMessage.previousModalMessageID" /> (ModalMessageID)
		</div>
	</div>

	<EditModalMessageTypeModal :type="editModalMessageType" ref="editTypeModal" @save="getTypes()" />

</section>
</template>



<script>
import ModalMessageAPI from '@/api/ModalMessageAPI.js'
import ModalMessageType from './ModalMessageType.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js';

import EditModalMessageTypeModal from './EditModalMessageTypeModal.vue'
import Tiptap from '@/features/Tiptap/Tiptap.vue'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: 'ModalMessageContents',


	components: {
		EditModalMessageTypeModal,
		Tiptap,
		ToggleButton,
	},


	props: {
		modalMessage: Object,
		dVals: Object,
	},


	data() {
		return {
			bodyMode: 'tiptap',
			tiptapSaved: false,
			modalMessageTypes: [],
			editModalMessageType: null,
		}
	},


	created() { this.initialize() },


	computed: {
		tooltips() { return Tooltips },
		warningNotCloseable() {	return !this.modalMessage.allowDismiss && !this.modalMessage.acceptButtonText },
	},


	watch: {
		bodyMode( value ) { if( value == 'tiptap' ) this.$refs.tiptap.reload() },
	},


	methods: {

		initialize() {
			this.getTypes()
		},

		async getTypes() {
			this.modalMessageTypes = []
			const data = await ModalMessageAPI.getTypes()
			for(var item of data) this.modalMessageTypes.push( ModalMessageType.import(item) )
		},

		reset( prop ) { this.$emit('reset', prop) },

		updateShowReview( value ) {
			if( !value ) return
			if( !this.modalMessage ) return
			if( !this.modalMessage.showStarRating && !this.modalMessage.showTextReview && !this.modalMessage.showVideoReview ) {
				this.modalMessage.showStarRating = true
				this.modalMessage.showTextReview = true
				this.modalMessage.showVideoReview = true
			}
		},


		updateEditorContent(html) {
			this.modalMessage.body = html;
			this.tiptapSaved = false;
		},


		addType() {
			this.editModalMessageType = null
			this.$refs.editTypeModal.open()
		},

		editType( typeID ) {

			// Get current type
			this.editModalMessageType = this.findType( typeID )

			// return if not present
			if( !this.editModalMessageType ) return

			// EDIT IT!
			this.$refs.editTypeModal.open()
		},

		updateTypeID( event ) {
			var typeID = event.srcElement.value
			typeID = ( typeID == '' ? null : parseInt( typeID ) )
			console.debug("UPDATE:", typeID)
			// this.modalMessage.typeID = typeID
			
			const type = this.findType( typeID )
			if( !type ) return
			
			if( type.defaultPriority !== null && this.modalMessage.priority === null ) {
				console.debug("PRIORITY:", type.defaultPriority)
				this.modalMessage.priority = type.defaultPriority
			}
		},
		

		findType( typeID ) {
			for( var type of this.modalMessageTypes ) {
				if( type.typeID !== typeID ) continue
				return type
			}
			return null
		},

	}
}
</script>



<style scoped>
.widget {
	background: white;
}

.widgetHeader {
	font-size: 1.1em;
	font-style: italic;
	margin-bottom: 0.5em;
	border-bottom: 1px solid #ccc;
}

.disabled {
	border-color: red;
	background: #fdd;
	color: red;
}

input[type="number"] {
	width: 5em;
}
.undoIcon {
	color: #ccc;
	cursor: default;
}
.undoIcon.active {
	color: black;
	cursor: pointer;
}
</style>