import Exim from '@/models/Exim.js'
import Location from './Location.js'
import Destination from './Destination.js'



class Route extends Exim {


	id = null
	label = null
	description = null 
	sources = []
	destinations = []
	enabled = false



	createSource() {
		const src = new Location()
		src.path = ''
		this.sources.push( src )
		return src
	}


	deleteSource( source ) {
		const idx = this.sources.indexOf( source )
		if( idx < 0 ) return

		this.sources.splice( idx, 1 )
	}



	createDestination() {
		const dst = new Destination()
		dst.path = ''
		this.destinations.push( dst )
		return dst
	}


	deleteDestination( destination ) {
		const idx = this.destinations.indexOf( destination )
		if( idx < 0 ) return

		this.destinations.splice( idx, 1 )
	}


	getTotalDestinationWeight() {
		return this.destinations.reduce( (prev, curr) => prev + curr.weight, 0 )
	}
	
	
	
	clone() {
		return Route.import( this.export() )
	}


	isSame( obj ) {
		return JSON.stringify( this.export() ) == JSON.stringify( obj.export() )
	}



	static import( obj ) {
		const item = super.import( obj, [ 'sources', 'destinations' ] );
		for( let src of obj.sources ) item.sources.push( Location.import( src ) )
		for( let dst of obj.destinations ) item.destinations.push( Destination.import( dst ) )

		return item
	}



	export() {
		const data = {
			id: this.id || null,
			label: this.label || null,
			description: this.description || null,
			sources: [],
			destinations: [],
			enabled: this.enabled || false,
		}

		for( let src of this.sources ) data.sources.push( src.export() )
		for( let dst of this.destinations ) data.destinations.push( dst.export() )

		return data
	}


}
export default Route