import Exim from '@/models/Exim.js'


class LandingPageStorefrontSection extends Exim {

  
  // Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
  vueID = 0

  type = 'storefront'
  name = ''
  slug = ''
  
  showTitle = true
  showSlashes = true
  showGroupTitle = true
  showGroupDescription = true
  showCouponCode = true

  sliderMode = null
  openByDefault = null
  defaultSlideIndex = null

  showComparePrograms = null  // whether or not to show the "Compare Programs" button (and popup table)
  quickSelectPrices = null    // whether or not to show price dropdown on product cards.

  autofillDefaultCoupon = null
  defaultCoupon = null


  constructor() {
    super()
    this.vueID = this.type + ++LandingPageStorefrontSection.idCounter
  }


  clone() {
    return LandingPageStorefrontSection.import( this.export() )
  }

  isSame(obj) {
    return JSON.stringify( this.export() ) == JSON.stringify( obj.export() )
  }

}

export default LandingPageStorefrontSection;
