<template>
<section>
	
	<div class="flex-row flex-justify-between">
			
		<div class="flex-row flex-justify-start">
			<SearchBox @search="str => search(str)" />
		</div>
		
		<div class="flex-row flex-align-center flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} records on {{ pages }} pages</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
			<!-- <button class="button inline-block" @click="newItem()">New Route</button> -->
		</div>

	</div>

	<div class='flex justify-center' v-if='loading'>
		<div class='icon-spinner4 spin-loader'></div>
	</div>

	<div v-if='!loading && !error'>

		<div v-if='items.length'>
			<ObjectTable
				class='font-small'
				:Source='items'
				:Columns='columns'
				:Numbered='false'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:StyleFunction='rowStyle'
				Deletable
				@delete='item => deleteItem( item )'
				@edit="item => openItem( item )"
				@sort='prop => selectSortBy( prop )'
				ref="objectTable"
			/>
			<Paginator v-model="page" :numPages="pages" />
		</div>
		<div v-else class="NoResults">No results</div>

	</div>

	<section v-if="error" class="warning">
		Failed to get records
	</section>


	<StretchModal ref="detailsModal" @close="closeItem()" padding="2em">
		<template #header>Request #{{ currentItem.id }}: {{ currentItem.requestFQDN }} <span class="icon-arrow-right2" /> {{ currentItem.destinationFQDN }}</template>
		<ProxyTrafficDetails v-if="currentItem" :item="currentItem" />
	</StretchModal>

</section>
</template>



<script>
import ProxyTrafficDetails from './ProxyTrafficDetails.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

import ProxyAPI from '@/api/ProxyAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import LogRecord from '@/features/Proxy/LogRecord.js'

export default {
	name: 'ProxyRouteList',

	
	components: {
		ProxyTrafficDetails,
		ObjectTable,
		Paginator,
		SearchBox,
		StretchModal,
	},


	props: {

	},


	data() {
		return {
			mode: 'routes',
			loading: false,
			error: false,

			searchString: null,
			sortBy: 'timeUpdated',
			sortAsc: false,
			count: 0,
			page: 1,
			pages: 1,
			pageSize: 250,

			items: [],
			currentItem: null,

			columns: [
				{
					displayName: 'Record #',
					propertyName: 'id',
					sortable: true
				},
				{
					displayName: 'Time',
					propertyName: 'timeUpdated',
					displayFunction: item => {
						return item.timeUpdated !== null ? item.timeUpdated.toLocaleString() : null
					},
					sortable: true
				},
				{
					displayName: 'Client IP',
					propertyName: 'clientIP',
					sortable: true
				},
				{
					displayName: 'Chosen Route ID',
					propertyName: 'routeID',
					sortable: true
				},
				{
					displayName: 'Requested URL',
					propertyName: 'requestFQDN',
					displayFunction: item => {
						return `<a href="${ item.requestFQDN }" target="_new">${ item.requestFQDN }</a>`
					},
					sortable: true
				},
				{
					displayName: 'Redirected to URL',
					propertyName: 'destinationFQDN',
					displayFunction: item => {
						return `<a href="${ item.destinationFQDN }" target="_new">${ item.destinationFQDN }</a>`
					},
					sortable: true
				},
				{
					displayName: 'Proxy Response Code',
					propertyName: 'responseCode',
					sortable: true
				},
			]
		}
	},


	created() { this.initialize() },


	watch: {
		page() { this.getTraffic() },
		pageSize() { this.getTraffic() },
	},


	methods: {

		initialize() {
			this.getTraffic()
		},


		rowStyle( route ) {
			if( !route.enabled ) return { color: "#888" }
		},


		async getTraffic() {

			try {
				this.error = false
				this.loading = true

				this.items = []
				this.currentItem = null

				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				const res = await ProxyAPI.getRouteActivity( req )
				for( let item of res.data.data ) this.items.push( LogRecord.import( item ) )


				this.count = res.data.count
				this.pages = res.data.pages

			} catch (e) {
				this.error = true
				console.error( e.message )

			} finally {
				this.loading = false
			}
			
		},


		search( str ) {
			this.searchString = str ? str : null
			this.getTraffic()
		},



		openItem( item ) {
			this.currentItem = item
			this.$refs.detailsModal.open()
		},


		closeItem() {
			this.currentItem = null
			this.$refs.objectTable.deselect()
		},




		selectSortBy( prop ) {
			if ( prop == this.sortBy ) {
				this.sortAsc = !this.sortAsc
				this.getTraffic()
				return
			}
			
			
			this.sortBy = prop
			this.sortAsc = true
			if( this.sortBy === 'timeUpdated' ) this.sortAsc = false

			this.getTraffic()
		},

	},
}
</script>



<style scoped>

</style>