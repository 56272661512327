<template>
<section id="outputVarDetails" class="mx-1 pa-1 w-100" style="max-height: calc( 100vh - 12em ); overflow: auto;">
	
	<div>
		<h2 class="center mt-0" style="color: var(--pp10-green);">
			Output Variable 
			<span v-if="!editName" class="name pointer round-05 pa-025" @click="editName = true">"{{ nameInternal }}"</span>
			<span v-else><input v-model="nameInternal" placeholder="Name" />&nbsp;<button class="pillButton bg secondary" @click="editName = false">done</button></span>
		</h2>
	</div>

	<div class="my-1 center">
		<div>Unit: <input v-model="computable.unit" placeholder="i.e. seconds, # questions, etc." /></div>
		<div>Short Unit: <input v-model="computable.shortUnit" placeholder="i.e. s, #Q, etc." /></div>
	</div>

	<div class="flex-column flex-align-center">
		<div class="bold center">Computations:</div>
		
		<section style="min-width: 600px;">
			<div v-for="(item, idx) in computable.operations" :key="item" class="listItem flex-row flex-align-center flex-justify-between flex-gap-2 mb-05">
				<div class="flex-row flex-align-center flex-justify-start flex-gap-1">
					<div>{{ idx + 1 }}</div>
					<select :value="item.operator" @change="updateOperator( item, $event.target.value )">
						<option :value="null">(select operation)</option>
						<option value="noop">No-op</option>
						<option value="add">+</option>
						<option value="sub">-</option>
						<option value="mult">*</option>
						<option value="div">/</option>
						<option value="mod">%</option>
						<option value="pow">^</option>
						<option value="exp">e^</option>
						<option value="sqrt">sqrt(x)</option>
						<option value="ln">ln(x)</option>
						<option value="log">log(x, base)</option>
						<option value="abs">abs(x)</option>
						<option value="round">round(x)</option>
						<option value="ceil">ceil(x)</option>
						<option value="floor">floor(x)</option>
						<option value="max">max(x, y)</option>
						<option value="min">min(x, y)</option>
					</select>:

					<div v-if="item.operator == 'exp'">e^</div>
					<div v-if="item.operator == 'sqrt'">sqrt(</div>
					<div v-if="item.operator == 'ln'">ln(</div>
					<div v-if="item.operator == 'log'">log(</div>
					<div v-if="item.operator == 'abs'">abs(</div>
					<div v-if="item.operator == 'round'">round(</div>
					<div v-if="item.operator == 'ceil'">ceil(</div>
					<div v-if="item.operator == 'floor'">floor(</div>
					<div v-if="item.operator == 'max'">max(</div>
					<div v-if="item.operator == 'min'">min(</div>

					<div v-if="item.operands.length >= 1">
						<select v-if="item.operands[0] === null || isNaN(item.operands[0])" :value="item.operands[0]" @change="updateFirstOperand( item, $event.target.value )">
							<option :value="null">(previous line)</option>
							<option v-for="qvarName in dimension.queryVariableNames" :value="qvarName" :key="qvarName">{{ qvarName }}</option>
							<option :value="0">(number)</option>
						</select>
						<div v-else><input type="number" v-model.number="item.operands[0]" style="max-width: 4em;" /><button class="pillButton secondary bg" @click="item.operands.splice(0, 1, null)"><span class="icon-cross" /></button></div>
					</div>

					<div v-if="item.operator == 'add'">+</div>
					<div v-if="item.operator == 'sub'">-</div>
					<div v-if="item.operator == 'mult'">x</div>
					<div v-if="item.operator == 'div'">/</div>
					<div v-if="item.operator == 'mod'">%</div>
					<div v-if="item.operator == 'pow'">^</div>
					<div v-if="item.operator == 'sqrt'">)</div>
					<div v-if="item.operator == 'ln'">)</div>
					<div v-if="item.operator == 'log'">,</div>
					<div v-if="item.operator == 'abs'">)</div>
					<div v-if="item.operator == 'round'">)</div>
					<div v-if="item.operator == 'ceil'">)</div>
					<div v-if="item.operator == 'floor'">)</div>
					<div v-if="item.operator == 'max'">,</div>
					<div v-if="item.operator == 'min'">,</div>

					<div v-if="item.operands.length >= 2">
						<select v-if="isNaN(item.operands[1]) || item.operands[1] === null" :value="item.operands[1]" @change="updateSecondOperand( item, $event.target.value )">
							<option :value="null">(previous line)</option>
							<option v-for="qvarName in dimension.queryVariableNames" :value="qvarName" :key="qvarName">{{ qvarName }}</option>
							<option :value="0">(number)</option>
						</select>
						<div v-else><input type="number" v-model.number="item.operands[1]" style="max-width: 4em;" /><button class="pillButton secondary" @click="item.operands.splice(1, 1, null)"><span class="icon-cross" /></button></div>
					</div>

					<div v-if="item.operator == 'log'">)</div>
					<div v-if="item.operator == 'max'">)</div>
					<div v-if="item.operator == 'min'">)</div>
				</div>

				<button class="pillButton secondary red" @click="deleteOperation(idx)"><span class="icon-bin2" /></button>

			</div>
		</section>

		<div class="mt-1 center"><button class="pillButton secondary bg" @click="addOperation()">+ New Computation</button></div>
	</div>


</section>
</template>



<script>
export default {
	name: 'ComputableEditor',


	props: {
		computable: {
			type: Object,
			required: true
		},

		dimension: {
			type: Object,
			required: true
		},

		name: {
			type: String,
			required: true
		},
	},


	data() {
		return {
			editName: false,
			nameInternal: null
		}
	},


	watch: {
		computable() { this.initialize() },
		nameInternal( value ) { this.$emit( 'updateName' , value )}
	},


	created() { this.initialize() },



	methods: {

		initialize() {
			this.nameInternal = this.name
		},

		addOperation() {
			this.computable.addOperation()
		},

		updateOperator( operation, operator ) {
			operation.operator = operator
			if(
				operator === 'add'
				|| operator === 'sub'
				|| operator === 'mult'
				|| operator === 'div'
				|| operator === 'log'
				|| operator === 'max'
				|| operator == 'min'
				|| operator === 'mod'
				|| operator === 'pow'
			) {
				while( operation.operands.length > 2 ) operation.operands.pop()
				while( operation.operands.length < 2 ) operation.operands.push( null )
			
			} else {
				while( operation.operands.length > 1 ) operation.operands.pop()
				while( operation.operands.length < 1 ) operation.operands.push( null )
			}
		},

		updateFirstOperand( operation, operand ) {
			if( operand === '' ) operand = null    // bugfix for value/@change instead of v-model
			operation.operands.splice(0, 1, operand)
		},

		updateSecondOperand( operation, operand ) {
			if( operand === '' ) operand = null    // bugfix for value/@change instead of v-model
			operation.operands.splice(1, 1, operand)
		},

		deleteOperation( index ) {
			this.computable.deleteOperation( index )
		}

	}


}
</script>



<style scoped>
#outputVarDetails {
	border: 1px solid var(--pp10-green);
	border-radius: 0.5em;
	min-height: 100%;
}

.name:hover {
	background-color: var(--ekno-blue-95);
}

.listItem {
	padding: 0.5em;
	margin: 0.25em 0 0.5em 0;
	border: 1px solid white;
	border-radius: 0.5em;
	box-shadow: 0 0 6px -3px #888;
}
.listItem:hover {
	background-color: var(--ekno-blue-95);
	border-color: var(--ekno-blue);
	box-shadow: 0 0 12px -6px #888;
}
</style>