<template>
<section>
	<ApexChartGraph
		v-if="scheduleGraph"
		:type="'area'"
		:graph="scheduleGraph"
		:dataLabels="false"
		height="300px"
		/>
	<div v-else-if="loadingScheduleGraph" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
  <div v-else-if="scheduleGraphError" class="warning">Failed to get %-to-schedule graph</div>
  <div v-else class="NoResults">No %-to-schedule graph</div>

	<ApexChartGraph
		v-if="planGraph"
		:type="'area'"
		:graph="planGraph"
		:dataLabels="false"
		height="300px"
	/>
	<div v-else-if="loadingPlanGraph" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
  <div v-else-if="planGraphError" class="warning">Failed to get plan-completion graph</div>
  <div v-else class="NoResults">No plan-completion graph</div>

	<div class="flex-row flex-justify-center flex-gap-2">
		<div>Study Start Date: {{ license.studyStartDate ? license.studyStartDate.toLocaleDateString() : '--' }}</div>
		<div>Test Date: {{ license.testDate ? license.testDate.toLocaleDateString() : '--' }}</div>
	</div>

	<!-- <div v-if="details">
	</div>
	<div v-else-if="loadingDetails" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
  <div v-else-if="detailsError" class="warning">Failed to get details</div>
  <div v-else class="NoResults">No Details</div> -->

</section>
</template>



<script>
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import StudentStatisticsRequest from './StudentStatisticsRequest.js'
import StudentStatisticsGraphRequest from './StudentStatisticsGraphRequest.js'
// import TreeNode from '@/features/CourseCore/Classes/TreeNode.js'
// import QuestionNode from '@/features/CourseCore/Classes/QuestionNode.js'

import ApexChartGraph from '@/components/ApexChartGraph.vue'
// import LessonWidget from './LessonWidget.vue'

export default {
	name: 'PlanDetails',


	components: {
		ApexChartGraph,
		// LessonWidget,
	},


	props: {
		userID: Number,
		license: Object,
		startDate: {
			type: Date,
			default: null
		},
		endDate: {
			type: Date,
			default: null
		},
	},


	data() {
		return {
			scheduleGraph: null,
			loadingScheduleGraph: false,
			scheduleGraphError: false,

			planGraph: null,
			loadingPlanGraph: false,
			planGraphError: false,

			details: null,
			loadingDetails: false,
			detailsError: false,
		}
	},


	created() { this.initialize() },


	watch: {
		startDate() { this.initialize() },
		endDate() { this.initialize() },
	},


	methods: {
		initialize() {
			this.getScheduleGraph()
			this.getPlanGraph()
			// this.getDetails()
		},


		async getScheduleGraph() {
			this.scheduleGraph = null
			this.scheduleGraphError = false
			this.loadingScheduleGraph = true
			try {
				const graphReq = new StudentStatisticsGraphRequest( this.userID, this.license.serialKey, this.startDate, this.endDate )
				const graphRes = await StudentStatisticsAPI.getDaysScheduleGraph( graphReq ).catch( e => {
					console.debug("ERROR")
					throw e;
				})
				this.scheduleGraph = graphRes.data
	
				if( !this.scheduleGraph.subtitle ) {
					const graphStart = new Date( this.scheduleGraph.start * 1000 )
					const startString = graphStart.toLocaleDateString()
					
					const graphEnd = new Date( this.scheduleGraph.end * 1000 )
					const endString = graphEnd.toLocaleDateString()

					this.scheduleGraph.subtitle = `${startString} - ${endString}, by ${this.scheduleGraph.xUnit}`
				}
		
				if( !this.scheduleGraph.xLabels.length && this.scheduleGraph.xUnit ) {
					for( var i=0; i < this.scheduleGraph.xValues.length; i++ ) {
						const currTS = this.scheduleGraph.xValues[i] * 1000
	
						const point = new Date( currTS )
	
						const midnightAligned = point.getHours() == 0 && point.getMinutes() == 0 && point.getSeconds() == 0 ? true : false
						const label = midnightAligned ? 'start of '+point.toLocaleDateString() : point.toLocaleString()
						this.scheduleGraph.xLabels.push( label )
					}
				}

			} catch(e) {
				this.scheduleGraphError = true
				console.debug(JSON.parse(JSON.stringify(e)))
				throw e

			} finally {
				this.loadingScheduleGraph = false
			}

		},



		async getPlanGraph() {
			this.planGraph = null
			this.planGraphError = false
			this.loadingPlanGraph = true
			try {
				const graphReq = new StudentStatisticsGraphRequest( this.userID, this.license.serialKey, this.startDate, this.endDate )
				const graphRes = await StudentStatisticsAPI.getPercentPlanGraph( graphReq ).catch( e => {
					console.debug("ERROR")
					throw e;
				})
				this.planGraph = graphRes.data
	
				if( !this.planGraph.subtitle ) {
					const graphStart = new Date( this.planGraph.start * 1000 )
					const startString = graphStart.toLocaleDateString()
					
					const graphEnd = new Date( this.planGraph.end * 1000 )
					const endString = graphEnd.toLocaleDateString()

					this.planGraph.subtitle = `${startString} - ${endString}, by ${this.planGraph.xUnit}`
				}
		
				if( !this.planGraph.xLabels.length && this.planGraph.xUnit ) {
					for( var i=0; i < this.planGraph.xValues.length; i++ ) {
						const currTS = this.planGraph.xValues[i] * 1000
	
						const point = new Date( currTS )
	
						const midnightAligned = point.getHours() == 0 && point.getMinutes() == 0 && point.getSeconds() == 0 ? true : false
						const label = midnightAligned ? 'start of '+point.toLocaleDateString() : point.toLocaleString()
						this.planGraph.xLabels.push( label )
					}
				}

			} catch(e) {
				this.planGraphError = true
				console.debug(JSON.parse(JSON.stringify(e)))
				throw e

			} finally {
				this.loadingPlanGraph = false
			}

		},


		// async getDetails() {
		// 	this.loadingDetails = true
		// 	try {
		// 		const req = new StudentStatisticsRequest( this.userID, this.licenseKey, this.startDate, this.endDate )
		// 		const res = await StudentStatisticsAPI.getQuestionsCompleted( req )
		// 		this.details = []

		// 		for( var item of res.data ) {
		// 			if( item.type == 'question' ) this.details.push( QuestionNode.import( item ) )
		// 			else this.details.push( TreeNode.import( item ) )
		// 		}
			
		// 	} catch(e) {
		// 		this.detailsError = true
		// 		throw e

		// 	} finally {
		// 		this.loadingDetails = false
		// 	}
		// }
	}

}
</script>



<style scoped>

</style>