import Exim from '@/models/Exim.js'
import LandingPageHTMLSection from './LandingPageSections/LandingPageHTMLSection.js'
import LandingPagePrimaryQuoteSection from './LandingPageSections/LandingPagePrimaryQuoteSection.js'
import LandingPageImageSection from './LandingPageSections/LandingPageImageSection.js'
import LandingPageSliderSection from './LandingPageSections/LandingPageSliderSection.js'
import LandingPageStorefrontSection from './LandingPageSections/LandingPageStorefrontSection.js'
import LandingPageChatSection from './LandingPageSections/LandingPageChatSection.js'
import LandingPageBuiltInSection from './LandingPageSections/LandingPageBuiltInSection.js'



class LandingPage extends Exim {


	id = 0
	urlSlug = ''
	adminLabel = ''
	sections = []
	affiliates = null
	

	clone() { return LandingPage.import( this.export() ) }
	isSame(obj) { return JSON.stringify( this.export() ) == JSON.stringify( obj.export() ) }


	addHTMLSection( index ) { this.sections.splice( index, 0, new LandingPageHTMLSection() ) }
	addPrimaryQuoteSection( index ) { this.sections.splice( index, 0, new LandingPagePrimaryQuoteSection() ) }
	addImageSection( index ) { this.sections.splice( index, 0, new LandingPageImageSection() ) }
	addSliderSection( index ) { this.sections.splice( index, 0, new LandingPageSliderSection() ) }
	addStorefrontSection( index ) { this.sections.splice( index, 0, new LandingPageStorefrontSection() ) }
	addChatSection( index ) { this.sections.splice( index, 0, new LandingPageChatSection() ) }
	addBuiltInSection( index ) { this.sections.splice( index, 0, new LandingPageBuiltInSection() ) }

	swapSections(idxa, idxb) {
		if(idxa < 0 || idxb < 0 || idxa >= this.sections.length || idxb >= this.sections.length) return;
		this.sections[idxa] = this.sections.splice(idxb, 1, this.sections[idxa])[0];
	}

	deleteSection(index) { this.sections.splice(index, 1); }




	static import( obj ) {

		const lp = super.import( obj, [ 'sections' ] )

		// Sections
		if(obj.sections) for(let section of obj.sections) {
			let importedSection = null;
			if(!section.type || section.type == 'html') importedSection = LandingPageHTMLSection.import(section);
			else if(section.type == 'primaryQuote') importedSection = LandingPagePrimaryQuoteSection.import(section);
			else if(section.type == 'image') importedSection = LandingPageImageSection.import(section);
			else if(section.type == 'slider') importedSection = LandingPageSliderSection.import(section);
			else if(section.type == 'storefront') importedSection = LandingPageStorefrontSection.import(section);
			else if(section.type == 'chat') importedSection = LandingPageChatSection.import(section);
			else if(section.type == 'builtin') importedSection = LandingPageBuiltInSection.import(section);
			lp.sections.push( importedSection );
		}

		return lp;
	}


	export() {

		const data = super.export( [ 'sections' ] )

		data.sections = []
		for( let sec of this.sections ) data.sections.push( sec.export() )
		return data
	}



}

export default LandingPage;
