import API from './API'
import Feedback from '@/models/Feedback'



export default class FeedbackAPI extends API {

  static controllerName = 'FeedbackAPI'
  

  static async getFeedback() {
    const res =  await this.newGet( 'getFeedback' );

    const feedback = []
    for (let fb of res) {
      feedback.push(Feedback.import(fb));
    }

    return feedback;
  }


  static async getUnreadFeedbackCount() {
    return await this.newGet( 'getUnreadFeedbackCount' )
  }



  static async readFeedback(feedbackID) {
    if (!feedbackID) {
      throw new Error('Missing property "feedbackID".');
    }

    await this.newEdit( 'readFeedback', [ feedbackID ] )
  }



  static async unreadFeedback(feedbackID) {
    if (!feedbackID) {
      throw new Error('Missing property "feedbackID".');
    }
    
    await this.newEdit( 'unreadFeedback', [ feedbackID ] )
  }



  static async deleteFeedback(feedbackID) {
    if (!feedbackID) {
      throw new Error('Missing property "feedbackID".');
    }

    await this.newDelete( 'deleteFeedback', [ feedbackID ] )
  }


  static patchFeedback( req ) {
    return this.newPost( 'patchFeedback', [], req )
  }
}
