<template>
<section>
	<!-- Name -->
	<h2 class="ma-0">
		<span v-if="!editName" class="name pointer round-05 pa-025" @click="editName = true">{{ template.name }}</span>
		<span v-else><input v-model="template.name" placeholder="Name" />&nbsp;<button class="pillButton bg secondary" @click="editName = false">done</button></span>
	</h2>
	<div class="">Table Report</div>
	<slot name="save" />
</section>
</template>



<script>
export default {
	name: 'TableReportTemplateEditor',


	props: {
		template: Object,
	},


	data() {
		return {
			editName: false,
		}
	},


	methods: {

	}

}
</script>



<style scoped>

</style>