import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';

// type FontSizeOptions = {
//   types: string[];
// };

// declare module '@tiptap/core' {
//   interface Commands<ReturnType> {
//     fontSize: {
//       /**
//        * Set the font size attribute
//        */
//       setFontSize: (size: string) => ReturnType;
//       /**
//        * Unset the font size attribute
//        */
//       unsetFontSize: () => ReturnType;
//     };
//   }
// }

export const FontSize = Extension.create({
  name: 'fontSize',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: element => element.style.fontSize.replace(/['"]+/g, ''),
            renderHTML: attributes => {
              if (!attributes.fontSize) {
                return {};
              }

              return {
                style: `font-size: ${attributes.fontSize}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize: fontSize => ({ chain }) => {
        return chain()
          .setMark('textStyle', { fontSize })
          .run();
      },
      unsetFontSize: () => ({ chain }) => {
        return chain()
          .setMark('textStyle', { fontSize: null })
          .removeEmptyTextStyle()
          .run();
      },
    };
  },
});