import API from "./API";



class LicensesAPI extends API {


	static controllerName = 'SiteAdmin/LicensesAPI'


	static async filterGetLicenses( paginatedRequest = null ) {

		return await super.newPost( 'filterGetLicenses', [], paginatedRequest )
  }


}
export default LicensesAPI