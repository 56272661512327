<template>
<section>
	<div class="center nowrap mb-1">
		<v-date-picker v-model="endDate" mode="dateTime">
			<template #default="{ inputValue, togglePopover }">
				<button @click="togglePopover">
					<span class="icon-clock" />
					{{ endDate ? inputValue : 'As of current date' }}
				</button>
			</template>
		</v-date-picker>
		<button v-if="endDate !== null" @click="endDate = null"><span class="icon-cross" /></button>
	</div>


	<section class="center">

		<div class="flex-row flex-gap-2 flex-justify-center">
			<div>Diagnostic Score: {{ diagScore }}</div>
			<div>Current Projected Score: {{ projectedScore }}</div>
			<div>Target Score: {{ targetScore }}</div>
		</div>

		<ScoreGauge v-if="primaryGauge" :gaugeModel="primaryGauge" />
		<div v-if="gauges.length" id='GaugesWidget'>
			<ScoreGauge v-for="gauge in gauges" :key="gauge.name" :gaugeModel="gauge" />
		</div>
		<div v-else-if="loadingGauges" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
		<div v-else-if="gaugeError" class="warning">Failed to get score projections</div>
		<div v-else class="NoResults">No score projections</div>
	</section>


	<section v-if="planProgress && !planProgressError" class="mb-3">
		<PlanProgressBar :progressData="planProgress.overall" />
		<div class="ml-2">
			<PlanProgressBar v-for="bar in planProgress.bars" :key="bar.name" :progressData="bar" />
		</div>
	</section>
	<div v-else-if="loadingPlanProgress" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
	<div v-else-if="planProgressError" class="warning">Failed to get Plan Progress</div>
	<div v-else class="NoResults">No Plan</div>

</section>
</template>



<script>
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import StudentStatisticsRequest from './StudentStatisticsRequest.js'
import { ScoreGauge as ScoreGaugeModel } from './ScoreGauge.js'

import ScoreGauge from './ScoreGauge.vue'
import PlanProgressBar from './PlanProgressBar.vue'

export default {
	name: 'StudentStatisticsOverview',

	
	components: {
		ScoreGauge,
		PlanProgressBar,
	},


	props: {
		userID: Number,
		licenseKey: String,
	},


	data() {
		return {
			gauges: [],
			primaryGauge: null,
			loadingGauges: false,
			gaugeError: false,

			endDate: null,

			planProgress: null,
			loadingPlanProgress: false,
			planProgressError: false,
		}
	},


	created() { this.initialize() },



	computed: {
		diagScore() {
			if( !this.primaryGauge || !this.primaryGauge.testScores || !this.primaryGauge.testScores.length ) return null;
			return this.primaryGauge.testScores[0].scaledScore;
		},

		projectedScore() {
			if( !this.primaryGauge || !this.primaryGauge.testScores || !this.primaryGauge.adjustedScore ) return null;
			return this.primaryGauge.adjustedScore.scaledScore;
		},

		targetScore() {
			if( !this.primaryGauge || !this.primaryGauge.testScores || !this.primaryGauge.targetScore ) return null;
			return this.primaryGauge.targetScore.scaledScore;
		},
	},


	watch: {
		endDate() { this.initialize() },
	},


	methods: {
		initialize() {
			this.getPlanProgress()
			this.getGauges()
		},


		async getPlanProgress() {
			this.planProgressError = false
			this.loadingPlanProgress = true
			
			try {
				const req = new StudentStatisticsRequest( this.userID, this.licenseKey, null, this.endDate )
				const res = await StudentStatisticsAPI.getPlanProgress( req )
	
				this.planProgress = res.data

			} catch (e) {
				this.planProgressError = true
			} finally {
				this.loadingPlanProgress = false
			}
		},


		async getGauges() {
			this.loadingGauges = true
			try {
				const req = new StudentStatisticsRequest( this.userID, this.licenseKey, null, this.endDate )
				const res = await StudentStatisticsAPI.getProjectedScores( req )
				
				this.gauges = []
				this.primaryGauge = ScoreGaugeModel.import( res.data.primaryScorePredictorResponse );
				for( const item of res.data.scorePredictorResponses ) {
					const gauge = ScoreGaugeModel.import( item )
					this.gauges.push( gauge )
				}
	

			} catch(e) {
				this.gaugeError = true
				throw e

			} finally {
				this.loadingGauges = false
			}

		},

	}


}
</script>



<style scoped>

</style>