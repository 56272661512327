<template>
<section>
	<div class="flex-row flex-justify-between flex-align-center py-05">

		<SearchBox @search="str => search(str)" />

		<div class="flex-row flex-align-center flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} coupons on {{ pages }} pages</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
			<button class="button inline-block" @click="newItem">New Coupon</button>
		</div>

	</div>

	<div class='flex justify-center' v-if='showSpinner'>
		<div class='icon-spinner4 spin-loader'></div>
	</div>

	<div v-if='!showSpinner'>

		<div v-if='items.length'>
			<ObjectTable
				class='font-small'
				:Source='items'
				:Columns='columns'
				:Numbered='false'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				Deletable
				Duplicatable
				@edit="editItem"
				@sort='selectSortBy'
				@duplicate='item => copyItem( item )'
				@delete='deleteItem'
				ref="objectTable"
			/>
			<Paginator v-model="page" :numPages="pages" />
		</div>
		<div v-else class="NoResults">No results</div>

	</div>

	<StretchModal ref="detailsModal" :clickToClose="false" @close="$refs.objectTable.deselect()" minHeight="95vh">
		<template #header>Coupon "{{ currentItem.code }}": {{ currentItem.adminLabel }}</template>
		<CouponDetails v-if="currentItem" :itemIn="currentItem" :isNew="isNew" @itemAdded="itemAdded" @itemEdited="itemEdited" />
	</StretchModal>
</section>
</template>



<script>
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import CouponDetails from './CouponDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'

import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import Coupon from './Coupon.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'

export default {
	name: 'CouponsList',


	components: {
		ObjectTable,
		Paginator,
		CouponDetails,
		StretchModal,
		SearchBox,
	},



	data() {
		return {
			items: [],
			currentItem: null,

			columns: [
				{
					displayName: 'Code',
					propertyName: 'code',
					sortable: true
				},
				{
					displayName: 'Label',
					propertyName: 'adminLabel',
					sortable: true
				},
				{
					displayName: 'Enabled',
					propertyName: 'enabled',
				},
				{
					displayName: 'Start Date',
					propertyName: 'startTime',
					sortable: true,
				},
				{
					displayName: 'End Date',
					propertyName: 'endTime',
					sortable: true,
				},
				{
					displayName: '# Uses',
					propertyName: 'globalUses',
					sortable: true
				},
			],

			searchString: null,
			sortBy: 'code',
			sortAsc: true,
			count: 0,
			page: 1,
			pageSize: 100,
			pages: null,
			
			showSpinner: false,
			isNew: false,

		}
	},


	async created() {
		this.getCoupons();
	},


	watch: {
		page() { this.getCoupons() },
		pageSize() { this.getCoupons() },
		selectedTag() {
			if( this.page === 1 ) this.getCoupons()
			else this.page = 1
		}
	},


	methods: {

		async getCoupons() {
			this.items = []
			const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
			const res = await SalesManagementAPI.getCoupons( req )
			for( var obj of res.data.data ) this.items.push( Coupon.import( obj ) )
		},


		search( str ) {
			this.searchString = str ? str : null
			this.getCoupons()
		},



		async editItem( item ) {
			this.isNew = false
			const data = await SalesManagementAPI.getCouponDetails( item.code )
			this.currentItem = Coupon.import( data )
			this.$refs.detailsModal.open()
		},

		async copyItem( item ) {
			this.isNew = true
			this.currentItem = Coupon.import( item.export() )
			this.currentItem.code = null
			this.$refs.detailsModal.open()
		},

		async deleteItem( item ) {
			let yes = confirm(`Really delete Coupon '${ item.code }' (${ item.adminLabel })?`)
			if( !yes ) return

			await SalesManagementAPI.deleteCoupon( item.code ).catch( e => {
				console.error( e.message )
				alert( `FAILED to delete coupon "${ item.code }":\n\n${e.message}` )
				throw e
			})

			let index = 0
			for( let obj of this.items ) {
				if( obj.code == item.code ) break
				index++
			}

			this.items.splice( index, 1 )
		},


		newItem() {
			this.isNew = true
			this.currentItem = new Coupon()
			this.$refs.detailsModal.open()
		},


		itemAdded( addedItem ) {
			this.currentItem = addedItem
			this.items.push( addedItem )
			this.isNew = false
		},


		itemEdited( changedItem ) {
			this.currentItem = changedItem

			for( let i=0; i < this.items.length; i++ ) {
				if( this.items[i].code != changedItem.code ) continue
				this.$set( this.items, i, changedItem )
				return
			}
		},

		selectSortBy(prop) {
			if (prop == this.sortBy) {
				this.sortAsc = !this.sortAsc;
				this.getCoupons()
				return
			}
			
			
			this.sortBy = prop
			if( this.sortBy === 'enabled' || this.sortBy === 'globalUses' ) this.sortAsc = false
			else this.sortAsc = true

			this.getCoupons()
		}

	}

}
</script>



<style scoped>

</style>