import PricingOption from './PricingOption.js';

class Product {
  constructor(
    sku = '',
    name = '',
    shortName = '',
    description = '',
    shortDescription = '',
    invoiceDescription = '',
    showInStore = false,
    teacherChatEnabled = false,
    variations = [],
    upgradableFrom = [],
    courseIDs = [],
    pricingOptions = [],
  ) {
    this.sku = sku;
    this.name = name;
    this.shortName = shortName;
    this.description = description;
    this.shortDescription = shortDescription;
    this.invoiceDescription = invoiceDescription;
    this.showInStore = showInStore;
    this.teacherChatEnabled = teacherChatEnabled;
    this.variations = variations;
    this.upgradableFrom = upgradableFrom;
    this.courseIDs = courseIDs;
    this.pricingOptions = pricingOptions;
    this.upgradeFromPricingOptions = {}
  }



  hasCourseID(id) { return this.courseIDs.includes(id); }

  addCourseID(id) {
    console.log(this.courseIDs);
    if( !this.courseIDs.includes(id) ) this.courseIDs.push(id);
    console.log(this.courseIDs);
  }

  removeCourseID(id) {
    console.log(this.courseIDs);
    let index = this.courseIDs.indexOf(id);
    if( index > -1 ) {
      this.courseIDs.splice(index, 1);
    }
    console.log(this.courseIDs);
  }



  hasVariation(id) { return this.variations.includes(id); }

  addVariation(id) {
    if( !this.variations.includes(id) ) this.variations.push(id);
  }

  removeVariation(id) {
    let index = this.variations.indexOf(id);
    if( index > -1 ) {
      this.variations.splice(index, 1);
    }
  }



  hasUpgradableFrom(sku) { return this.upgradableFrom.includes(sku); }

  addUpgradableFrom(sku) {
    if( !this.upgradableFrom.includes(sku) ) this.upgradableFrom.push(sku);
  }

  removeUpgradableFrom(sku) {
    let index = this.upgradableFrom.indexOf(sku);
    if( index > -1 ) {
      this.upgradableFrom.splice(index, 1);
    }
  }




  upgradableFromPricingOption(fromID, toID = null) {
    let val = this.upgradeFromPricingOptions[fromID];
    if(!val) return false;

    if(toID === null) return true;

    return val === toID
  }

  addUpgradePricingOption(fromID, toID) {
    // console.log(`adding ${fromID}:${toID} to upgradePricingOptions`);
    this.upgradeFromPricingOptions[fromID] = toID;
    // console.log(this.upgradeFromPricingOptions);
  }

  removeUpgradePricingOption(toID) {
    let toDelete = [];
    for(let key in this.upgradeFromPricingOptions){
      if(this.upgradeFromPricingOptions[key] != toID) continue;
      toDelete.push(key);
      // console.log(`deleting ${key}:${toID} from upgradePricingOptions`);
    }
    for(let key of toDelete) delete this.upgradeFromPricingOptions[key];
    // console.log(this.upgradeFromPricingOptions);
  }

  swapPricingOptions(idxa, idxb) {
    let po = this.pricingOptions;
    if(idxa < 0 || idxb < 0 || idxa >= po.length || idxb >= po.length) return;
    po[idxa] = po.splice(idxb, 1, po[idxa])[0];
  }



  findPricingOption(id) {
    for(let po of this.pricingOptions) {
      if(po.id !== id) continue;
      return po;
    }
    return null;
  }



  isSame(obj) {
    return JSON.stringify(this.export()) == JSON.stringify(obj.export());
  }


  static import(obj) {
    let product = new Product(
      obj.sku,
      obj.name,
      obj.shortName,
      obj.description,
      obj.shortDescription,
      obj.invoiceDescription,
      obj.showInStore,
      obj.teacherChatEnabled,
      obj.courseVariations,
      obj.upgradableFrom,
      obj.courseIDs,
    );

    // Populate pricingOptions
    if(Array.isArray(obj.pricingOptions)) {
      // console.log("   importaing pricingOptions...");
      for(let item of obj.pricingOptions) {
        // console.log(`      ${item.id}: ${item.name}`);
        product.pricingOptions.push( PricingOption.import(item, product) );
      }
    }

    // Populate mapping between base-product pricing options and upgrade pricing options (if applicable)
    if(typeof obj.upgradeFromPricingOptions === 'object' && obj.upgradeFromPricingOptions !== null) {
      // console.log("   importing PO upgrade map...");
      for(let index in obj.upgradeFromPricingOptions) {
        // console.log(`      ${index}:${obj.upgradeFromPricingOptions[index]}`);
        product.upgradeFromPricingOptions[index] = obj.upgradeFromPricingOptions[index];
      }
    }

    return product;
  }


  export() {
    const data = {
      sku: this.sku,
      name: this.name,
      shortName: this.shortName,
      description: this.description,
      shortDescription: this.shortDescription,
      invoiceDescription: this.invoiceDescription,
      showInStore: this.showInStore,
      teacherChatEnabled: this.teacherChatEnabled,
      courseVariations: this.variations.slice(),
      upgradableFrom: this.upgradableFrom.slice(),
      courseIDs: this.courseIDs.slice(),
      pricingOptions: [],
      upgradeFromPricingOptions: {}
    };

    for(let item of this.pricingOptions) data.pricingOptions.push(item.export());


    for(let index in this.upgradeFromPricingOptions) {
      data.upgradeFromPricingOptions[index] = this.upgradeFromPricingOptions[index];
    }

    return data;
  }
}


export default Product;
