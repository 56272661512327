<template>
<section>
	<div><ToggleButton v-model="showDate" :sync="true" /> Show Dates on "{{ label }}" view</div>
	<div><ToggleButton v-model="showAddress" :sync="true" /> Show Addresses on "{{ label }}" view</div>
</section>
</template>



<script>
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: 'ReviewsPageSettingsWidget',


	
	components: {
		ToggleButton,
	},



	props: {
		label: {
			type: String,
			default: ''
		},

		settings: {
			type: Object,
			required: true
		},
	},

	data() {
		return {
			showDate: this.$props.settings.showDate || false,
			showAddress: this.$props.settings.showAddress || false,
		}
	},



	watch: {
		showDate() { this.$emit( 'update', this.showDate, this.showAddress ) },
		showAddress() { this.$emit( 'update', this.showDate, this.showAddress ) },
	},
}
</script>
