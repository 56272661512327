import API from './API.js'
import {base_url} from '@/Config.js'
import Order from '@/features/orders/Order.js'
// import PaginatedRequest from './PaginatedRequest.js'
import PaginatedResponse from './PaginatedResponse.js'
// import Transaction from '@/features/orders/Transactions/Transaction.js'

class OrdersAPI extends API {

  static controllerName = 'SiteAdmin/OrdersAPI'


  /**
   * Fetches information about orders for a given month.
   * @param  {Number}  year  4-digit year.
   * @param  {Number}  month 1 or 2-digit month (1 - 12).
   * @param  {Number}  affiliateID OPTIONAL
   * @return {Promise}       Resolves to a list of objects that contain information regarding orders on a day of the given month.
   */
  static async getAggregateOrders(year, month, affiliateID = null) {
    const date = new Date();
    if (!year) {
      year = date.getFullYear();
    }
    if (!month) {
      month = date.getMonth() + 1;
    }

    const url = `${base_url}/SiteAdmin/OrdersAPI/getAggregateOrders/${year}/${month}${affiliateID ? '/'+affiliateID : ''}`;
    return await this.get(url);
  }



  static async getAccountBalance( id, type ) {
    return await this.newGet( 'getAccountBalance', [ id, type ] )
  }



  /**
   * Fetches orders based on the given OrdersRequest object. 
   * @param  {Object}  req OrdersRequest object to match the corresponding class on the server.
   * @param {Boolean}  asCSV - Defaults FALSE.  If TRUE, treats the response as a file download
   * @return {Promise} Resolves to a list of orders.
   */
  static async getOrders( req, asCSV ) {
    
    const responseType = asCSV ? 'blob' : null
    const res = await this.newPost( 'getOrders', [], req, responseType )
    
    if( !this.verifyStatus( res.status ) ) throw new Error(res.status + " response code from server");

    if( asCSV ) this.processResponseAsFileDownload( res.data )
    else return res.data
  }



  /**
   * Fetches a single order identified by the given orderID
   * @param {int} orderID - the order's unique identifier.
   * @return {Promise} - Resolves to a JSON object representing an order.
   */
  static async getOrder( orderID ) {
    if(!orderID) return null;
    const url = `${base_url}/SiteAdmin/OrdersAPI/getOrder/${orderID}`;
    return await this.get(url);
  }


  static async getSummaryByDay(startYear = null, startMonth = null, endYear = null, endMonth = null, affiliateID = null, salesRepID = null) {
    return await this.get(`${base_url}/SiteAdmin/OrdersAPI/getSummaryByDay/${startYear ? startYear : ''}/${startMonth ? startMonth : ''}/${endYear ? endYear : ''}/${endMonth ? endMonth : ''}/${affiliateID ? affiliateID : ''}/${salesRepID ? salesRepID : ''}`);
  }


  static async getSummaryByMonth(startYear = null, startMonth = null, endYear = null, endMonth = null, affiliateID = null, salesRepID = null) {
    return await this.get(`${base_url}/SiteAdmin/OrdersAPI/getSummaryByMonth/${startYear ? startYear : ''}/${startMonth ? startMonth : ''}/${endYear ? endYear : ''}/${endMonth ? endMonth : ''}/${affiliateID ? affiliateID : ''}/${salesRepID ? salesRepID : ''}`);
  }


  static async getSummaryByAffiliate(year = null, month = null) {
    return await this.get(`${base_url}/SiteAdmin/OrdersAPI/getSummaryByAffiliate/${year ? year : ''}/${month ? month : ''}`);
  }


  static async getSummaryByProduct(year = null, month = null, affiliateID = null, salesRepID = null) {
    return await this.get(`${base_url}/SiteAdmin/OrdersAPI/getSummaryByProduct/${year ? year : ''}/${month ? month : ''}/${affiliateID ? affiliateID : ''}/${salesRepID ? salesRepID : ''}`);
  }


  static async getOrderStatistics(startDate, endDate, affiliateID = null) {
    var url = `${base_url}/SiteAdmin/OrdersAPI/getOrderStatistics/${startDate}/${endDate}`
    if(affiliateID) url += `/${affiliateID}`
    return await this.get(url)
  }


	static async getContractStatistics() {
		return await( this.get(`${base_url}/SiteAdmin/OrdersAPI/getContractStatistics`) )
	}



  static async markPOReceived(orderID, timestamp = null) {
    let data = null;
    const url = `${base_url}/SiteAdmin/OrdersAPI/markPurchaseOrderReceived/${orderID}`;
    if(timestamp) data = await this.get(`${url}/${timestamp}`);
    else data = await this.get(url);

    return data;
  }

  static async clearDatePOReceived(orderID) {
    return await this.newGet( 'clearDatePOReceived', [orderID] )
  }



  static async markOrderFulfilled(orderID, timestamp = null) {
    let data = null;
    const url = `${base_url}/SiteAdmin/OrdersAPI/markOrderFulfilled/${orderID}`;
    if(timestamp) data = await this.get(`${url}/${timestamp}`);
    else data = await this.get(url);

    return data;
  }

  static async clearDateFulfilled(orderID) {
    return await this.newGet( 'clearDateFulfilled', [orderID] )
  }



  static async markOrderPaid(orderID, timestamp = null) {
    let data = null;
    const url = `${base_url}/SiteAdmin/OrdersAPI/markOrderPaid/${orderID}`;
    if(timestamp) data = await this.get(`${url}/${timestamp}`);
    else data = await this.get(url);

    return data;
  }

  static async clearDatePaid(orderID) {
    return await this.newGet( 'clearDatePaid', [orderID] )
  }



  static async cancelOrder(orderID, timestamp = null) {
    let data = null;
    const url = `${base_url}/SiteAdmin/OrdersAPI/cancelOrder/${orderID}`;
    if(timestamp) data = await this.get(`${url}/${timestamp}`);
    else data = await this.get(url);

    return data;
  }



  static async updateOrder(order) {
    return await this.sendJSON(`${base_url}/SiteAdmin/OrdersAPI/updateOrder`, order);
  }



  static async syncProductCopies(orderID) {
    const data = await this.get(`${base_url}/SiteAdmin/OrdersAPI/syncProductCopies/${orderID}`)
    return Order.import(data)
  }



  static async deleteOrder(orderID) {
    const url = `${base_url}/SiteAdmin/OrdersAPI/deleteOrder/${orderID}`;
    return await this.get(url);
  }



  static async sendReceiptEmail( orderID, userID ) {
    return await this.newGet( 'sendReceiptEmail', [ orderID, userID ] )
  }



  // static async addProductsToAllOrders(applyChanges = false) {
  //   applyChanges = applyChanges ? 'true' : ''
  //   return await this.get(`${base_url}/SiteAdmin/OrdersAPI/addProductsToAllOrders/${applyChanges}`)
  // }


  static async upgradeOrderLineItems() { return await this.newGet( 'upgradeOrderLineItems' ) }
  static async upgradeOrderTransactions() { return await this.newGet( 'upgradeOrderTransactions' ) }
  static async attachAffiliates() { return await this.newGet( 'attachV9Affiliates' ) }



  // --------------------------------------------------------------------------
  // Transactions
  // --------------------------------------------------------------------------

  /**
   * Get a set of monetary Transactions
   * @param {PaginatedRequest} req 
   * @returns {PaginatedResponse|Object}
   */
  static async getTransactions( req ) {
    const res = await this.newPost( 'getTransactions', [], req )
    if( req.paginate ) return PaginatedResponse.import( res.data )
    else return res
  }


  static async createTransaction( t, orderID = null ) {
    const args = orderID ? [ orderID ] : null
    return await this.newPost( 'createTransaction', args, t )
  }


  static async editTransaction( t ) {
    return await this.newPost( 'editTransaction', [], t )
  }

  static async deleteTransaction( id ) {
    return await this.newGet( 'deleteTransaction', [ id ] )
  }


  /**
   * 
   * @param {Object} t 
   */
  static async saveTransactionOrderAssignments( t ) {
    return await this.newPost( 'saveTransactionOrderAssignments', [], t )
  }


  static async saveTransactionNotes( id, notes ) {
    return await this.newPost( 'saveTransactionNotes', [ id ], { notes } )
  }


  static async saveTransactionBillingAddress( id, address ) {
    return await this.newPost( 'saveTransactionBillingAddress', [ id ], address )
  }
}

export default OrdersAPI;
