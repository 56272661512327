class StudentStatisticsRequest {

	// Required
	userID = null
	licenseKey = null

	// Timespan
	startTimestamp = null
	endTimestamp = null

	courseVersion = null


	constructor( userID, licenseKey, startDate = null, endDate = null ) {
		this.userID = userID
		this.licenseKey = licenseKey
		this.startTimestamp = startDate ? Math.round( startDate.getTime() / 1000 ) : null
		this.endTimestamp = endDate ? Math.round( endDate.getTime() / 1000 ) : null
	}

}
export default StudentStatisticsRequest