/**
 * Extends the TreeNode class;
 * this class is for video / audio / PDF / image / text content
 *
 * @author: Jordan Hildebrandt
 *
 */

import TreeNode from './TreeNode';

class MediaNode extends TreeNode {
  constructor(id, name = '', icon = '', type = 'media', file_name = '', file_type = '', display_method = 'inline', autoplay = true){
      super(id, name, icon, type);

      this.file_name = file_name;
      this.file_type = file_type;
      this.page_number = 0;
      this.display_method = display_method;

      // Video settings
      this.autoplay = autoplay;
      this.playNext = this.type == 'media-video';
      this.autoplayNext = this.type == 'media-video';
      this.limitFrameSize = true;
      this.limitWidthInPopup = false;
      this.duration = null; // length of video in seconds

      // Alignment
      this.alignLeft = false;
      this.alignCenter = true;
      this.alignRight = false;
      this.constrainWidth = false; // Limit to 720px width (for text line width, alignment & margin purposes)

      // Embedded mode settings
      this.embedded = false;                // Set to true when used within another node (question, test, etc)
      this.embeddedAllowComplete = true;    // Allow user to mark embedded node complete (& auto-complete videos when playhead reaches end)
      this.embeddedAutoComplete = false;    // Mark embedded node complete upon popup close
      this.displayLayoutExemption  = false; // Used for media embedded in Questions

      this.displayPosition = null; // Number 1 - 9: left-center-right on top row, then middle row, then bottom row
      this.maxHeight = null; // decimal 0 - 1 (percentage of mainPane height for popup)
      this.maxWidth = null; // decimal 0 - 1 (percentage of mainPane width for popup)

      // Whether or not to show the name of the node (beneath content, above "more info" section).  Default: true
      this.showName = true;

      this.width = null;
      this.height = null;

      // If type='media-text' then this is used to store the text.
      this.textContent = null;

      // Ephemeral -- where the node is embedded (if embedded e.g. in test or question)
      this.embeddedListName = null;


      // Getter-only (OPTIONAL)
      this.watchTime = null;   // Object representing user's video "watch time" for this node (seconds); As of Oct 2023, used only for CourseLibrary stats.
  }



}

export default MediaNode;
