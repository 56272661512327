<template>
  <div>
    <label>Test email addresses:</label><br /><br />

    <div v-for="(address, index) in testAddresses" :key="index">
      <input type="email" v-model="address.address"/>
      <span class="icon-bin" @click="removeAddress(address)"></span>
    </div>

    <button class="button"  @click="addAddress">Add Address</button>
    <button class="button"  @click="saveChanges">Save changes</button>

    <div v-if="isInitialized">
      <EditEmailTemplate v-for="(filename, index) in filenames" :key="filename"
        :EmailTemplate="getTemplate(filename)"
        :Title="titles[index]"
        @saved="initialize()"
        @test="testTemplate"
      />
    </div>

    <p v-if="changesSaved"
      :class="{'message-success': changesSaved}">
      Changes successfully saved
    </p>

  </div>
</template>

<script>
import EditEmailTemplate from './EditEmailTemplate'
import EmailTemplate from '@/models/EmailTemplate'
import EmailAddress from '@/models/EmailAddress'
import EmailAPI from '@/api/EmailAPI'
import OrderedSet from '@/libraries/OrderedSet'


export default {
  name: "email-template-list",

  data() {
    return {
      csAddresses: "",
      changesSaved: false,
      filenames: [
        // 'shop_receipt',
        // 'subscription_payment_receipt',
        'new_course_eu',
        // 'new_user_upgrade',
        'new_user',
        'account_registered',
        'forgot_password',
        'password_reset',
        'expire_soon',
        'expired'
        // 'report_daily',
        // 'report_weekly',
        // 'report_monthly'
      ],
      titles: [
        // 'Shop Receipt (new / upgraded licenses)',
        // 'Payment Receipt (subscription payment)',
        'New course registration (by admin or teacher)',
        // 'New v9 Upgrade User',
        'New User Account',
        'Account Registered',
        'Forgot password',
        'INFO: Password has been reset',
        'License will expire soon',
        'License has expired',
        // 'Parent progress report: daily',
        // 'Parent progress report: weekly',
        // 'Parent progress report: monthly'
      ],

      isInitialized: false,
      emailTemplates: new OrderedSet(),
      testAddresses: new OrderedSet(),
    }
  },

  computed: {

    fakeTemplates() {
      const templates = [];

      const NUM_TEMPLATES = this.filenames.length;

      for (let i = 0; i < NUM_TEMPLATES; i++) {
        templates.push(new EmailTemplate(this.filenames[i]));
      }
      return templates;
    }
  },


  async created() {
    await this.initialize()

    let addresses = ""
    let testAddresses = this.$store.state.testAddresses;
    for (let i in testAddresses) {
      const addr = testAddresses[i]
      addresses += addr;
      if (i < testAddresses.size - 1) addresses += ", ";
    }
    this.csAddresses = addresses
  },



  methods: {

    async initialize() {
      try {
        this.emailTemplates.objects = await EmailAPI.getEmails()
        // this.testAddresses.objects = await EmailAPI.getTestAddresses()
        this.isInitialized = true
      } catch (e) {
        console.error(e)
        alert( "An error occurred while loading email templates & test addresses" )
      }
    },


    async testTemplate(template) {
      console.debug(template);

      let functionName = ''
      if(template.filename == 'new_course_eu') functionName = 'sendNewLicenseEmail'
      else if(template.filename == 'new_user') functionName = 'sendNewUserEmail'
      else if(template.filename == 'account_registered') functionName = 'sendAccountRegisteredEmail'
      else if(template.filename == 'forgot_password') functionName = 'sendRequestPasswordResetEmail'
      else if(template.filename == 'password_reset') functionName = 'sendPasswordResetEmail'
      else if(template.filename == 'expire_soon') functionName = 'sendLicenseExpiringSoonEmail'
      else if(template.filename == 'expired') functionName = 'sendLicenseExpiredEmail'
      else throw new Error("Invalid template name; cannot test!")

      await EmailAPI.sendTestEmail(functionName)
    },

    async saveChanges() {

      // Save test addresses
      let addressSuccess = await EmailAPI.putTestAddresses(this.testAddresses)

      if (addressSuccess) {
        this.changesSaved = true
      } else {
        alert('An error occurred while saving test email addresses to the server.')
        return
      }
    },

    addAddress() {
      this.testAddresses.push(new EmailAddress());
    },

    removeAddress(address) {
      this.testAddresses.remove(address);
    },

    getTemplate(filename) {
      for (let template of this.emailTemplates) {
        if (template.filename === filename) return template;
      }
      return new EmailTemplate(filename);
    }
  },

  components: {
    EditEmailTemplate
  }
}
</script>

<style scoped>
.template {
  width: 75%;
  height: 10em;
}
</style>
