<template>
  <div>
    <section class='details-body'>
      <label class='body-item'>
        <div class='label'>Name:</div>
        <input type="text" v-model="newRole.name" />
      </label>

      <label class='body-item'>
        <div>Description:</div>
        <textarea v-model='newRole.description' />
      </label>
    </section>

    <button class='button' @click="showRoleFeatures">Features</button>
    <button class='button' @click="save" :disabled="!changesMade">Save Changes</button>

    <StretchModal ref="roleFeatures" :clickToClose="false">
      <template #header>Role Features</template>
      <RoleFeatures :Role="Role" :RoleFeatures='roleFeatures' />
    </StretchModal>

  </div>
</template>

<script>
import StretchModal from '@/components/utilities/StretchModal.vue'
import Role from '@/models/Role'
import RoleFeatures from './RoleFeatures'
import RolesAPI from '@/api/RolesAPI'
import OrderedSet from '@/libraries/OrderedSet'

export default {
  name: "role-details",

  props: {
    Role: Object
  },

  data() {
    return {
      newRole: new Role(),
      features: [],
      roleFeatures: new OrderedSet()
    }
  },

  computed: {
    changesMade() { return !this.Role.isSame(this.newRole); }
  },

  watch: {
    Role() { this.syncRoles(); }
  },

  created() { this.syncRoles() },

  methods: {
    async save() {
      try {
        let res
        if (this.newRole.id) {
          res = await RolesAPI.editRole(this.newRole.export())
          this.$store.state.Roles.updateElement('id', this.newRole)
        } else {
          res = await RolesAPI.addRole(this.newRole.export())
          this.$store.state.Roles.push( res )
          this.$emit('added', res)
        }
      } catch (e) {
        console.error(e)
        alert(`Could not save changes: ${e}`)
      }
    },

    async showRoleFeatures() {
      if (this.Role.id) {
        this.roleFeatures.objects = await RolesAPI.getFeatures(this.Role.id);
        this.$refs.roleFeatures.open()
      } else {
        alert('Please save this role before assigning features.');
      }

    },

    syncRoles() {
      Object.assign(this.newRole, this.Role);
    },

  },

  components: {
    StretchModal,
    RoleFeatures,
  }
}
</script>
