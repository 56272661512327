<template>
  <SearchDropdown
    :placeholder="placeholder"
    :DisplayFunction="displayFunction"
    :InitialValue="initialValue || displayFunction(blastList)"
    :ObjKey="'id'"
    :width="width"
    :browseIcon="true"
    :pages="pages"
    :externalResults="items"
    :dropUp="dropUp"
    @selected="item => select(item)"
    @search="(txt, pg) => getList(txt, pg)"
    @none="select(null)"
    ref="search"
  />
</template>


<script>
import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import BlastList from './BlastList.js'
import SearchDropdown from '@/components/utilities/SearchDropdown.vue'

export default {
  name: 'BlastListSearchDropdown',

  components: {
    SearchDropdown,
  },


  props: {
    blastList: {
      type: BlastList,
      default: null
    },

    width: {
      type: String,
      default: '50%'
    },

    placeholder: {
      type: String,
      default: 'Search Blast Lists'
    },

    initialValue: {
      type: String,
      default: null
    },

		dropUp: {
			type: Boolean,
			default: false
		},

  },


  model: {
    prop: 'blastList',
    event: 'update'
  },

  data() {
    return {
      items: [],
      pages: 1,
    }
  },

  methods: {

    select(item) {
      this.$emit('update', item);
    },

    clear() { this.$refs.search.clear() },

    displayFunction(obj) {
      if(!obj) return '';
      return `${ obj.name } (${ obj.numContacts } contacts)`
    },

    async getList( searchTerm, page = 1 ) {
      const req = new PaginatedRequest()
      req.page = page
      req.pageSize = 25
      req.searchString = searchTerm ? searchTerm : null
      const pr = await MessageCenterAPI.getBlastLists( req )

      this.items = []
      if(!pr) return
      this.pages = pr.pages
      for(let item of pr.data) this.items.push( BlastList.import( item ) )
    },
  }


}
</script>
