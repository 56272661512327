<template>
<section>
<div class="color-gray-40" :class="{ 'pointer pillButton secondary' : recipient.userID || recipient.pocID }" @click.stop="showDetails()">
	<span v-if="recipient.userID" class="icon-user mr-05 color-blue" />
	<span v-if="recipient.pocID" class="icon-user mr-05 color-orange" />
	<span v-if="recipient.name" class="mr-05">{{ recipient.name }}</span>
	<span v-if="recipient.name && recipient.address">&lt;</span>
	<span v-if="recipient.address">{{ recipient.address }}</span>
	<span v-if="recipient.name && recipient.address">&gt;</span>
</div>

<UserDetailsModal :user="user" ref="userDetails" />
<StretchModal padding="2em" ref="contactDetails">
	<PocDetails v-if="contact" :poc="contact" />
</StretchModal>

</section>
</template>



<script>
import UserAPI from "@/api/UserAPI.js"
import SalesManagementAPI from "@/api/SalesManagementAPI.js"
import POC from "@/features/SalesManagement/POC/POC.js"

import UserDetailsModal from '@/features/Users/UserDetailsModal.vue'
import PocDetails from '@/features/SalesManagement/POC/PocDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

export default {
	name: 'MessageRecipient',


	components: {
		UserDetailsModal,
		PocDetails,
		StretchModal,
	},


	props: {
		recipient: Object
	},


	data() {
		return {
			user: null,
			contact: null
		}
	},


	methods: {
		
		showDetails() {
			if( this.recipient.pocID ) this.showContactDetails()
			else if( this.recipient.userID ) this.showUserDetails()
		},

		async showUserDetails() {
			if( !this.recipient.userID ) return

			this.user = await UserAPI.getUser( this.recipient.userID )
			this.$refs.userDetails.open()
		},

		async showContactDetails() {
			if( !this.recipient.pocID ) return

			const data = await SalesManagementAPI.getPocsByID( { pocIDs: [ this.recipient.pocID ] } )
			if( !data || !data.length ) return
			
			this.contact = POC.import( data[0] )
			this.$refs.contactDetails.open()
		},
	}


}
</script>

<style>

</style>