class MathExtensions {

  static moneyFormatter = null

  static init() {
    Math.round2fixed = function(value, decimalPlaces = 2) {
      return (Math.round(value * (10 ** decimalPlaces)) / (10 ** decimalPlaces)).toFixed(decimalPlaces);
    }

    Math.moneyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })

    Math.moneyFormat = function( value ) {
      return Math.moneyFormatter.format( value )
    }

    Math.hhmmss = function ( numSeconds ) {
      if( numSeconds === undefined || numSeconds === null ) return null;
      var sec_num = parseInt(numSeconds, 10); // don't forget the second param
      var hours   = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      return hours+':'+minutes+':'+seconds;
    }

    Math.hhmm = function ( numMinutes ) {
      if( numMinutes === undefined || numMinutes === null ) return null;
      var min_num = parseInt(numMinutes, 10); // don't forget the second param
      var hours   = Math.floor(min_num / 60);
      var minutes = min_num - (hours * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      return hours+':'+minutes;
    }

    Math.hms = function ( numSeconds ) {
      if( numSeconds === undefined || numSeconds === null ) return null;
      var sec_num = parseInt(numSeconds, 10); // don't forget the second param
      var hours   = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      var str = ''
      if (hours   > 0) str += hours + 'h'
      if (hours > 0 && minutes > 0) str += ' '
      if (minutes > 0) str += minutes + 'm'
      if (minutes > 0 && seconds > 0) str += ' '
      if (seconds > 0) str += seconds +'s'

      if( str === '' ) str = '0s'
      
      return str
    }
  }

	// TODO: Make a "formatted" string version, using Intl.NumberFormat or Number.toLocaleString,
	// to fully format the number, w/ thousands separator, etc.

}

export default MathExtensions;
