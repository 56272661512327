<template>
  <label class='container'>
    <slot></slot>
    <input type='checkbox'
      :checked='checked'
      @change='$emit("change", $event.target.checked)'
    />
    <span class='checkmark'></span>
  </label>
</template>


<script>
export default {
  name: 'Checkbox',

  model: {
    prop: 'checked',
    event: 'change'
  },

  props: {
    checked: Boolean
  }
}
</script>


<style scoped>
/* Customize the label (the container) */
.container {
  min-height: inherit;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 25px;
  width: 25px;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  /* background-color: #eee; */
  border: 1px solid #ccc;
  border-radius: 4px;
  transition-duration: 0.3s
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  box-shadow: 0px 0px 4px #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
