<template>
<section>
	<VueApexCharts v-if="this.graph" :options="chartOptions" :series="series" :height="height" />
</section>
</template>



<script>
import VueApexCharts from 'vue-apexcharts'
export default {
	name: 'ApexChartGraph',

	components: {
		VueApexCharts,
	},


	props: {

		graph: {
			type: Object,
			default: null
		},

		type: {
			type: String,
			default: 'line'  // bar | column | line | area | donut ... (more, see https://apexcharts.com/docs/vue-charts)
		},

		stacked: {
			type: Boolean,
			default: false
		},

		dataLabels: {
			type: Boolean,
			default: true
		},

		colors: {
			type: Array,
			default: null
		},

		height: {
			type: [Number, String],
			default: 'auto'
		},

		options: {
			type: Object,
			default: null
		},

	},


	data() {
		return {
			chartOptions: {},
			series: [],
		}
	},



	watch: {
		graph() { this.initialize() },
		type() { this.initialize() },
		height() { this.initialize() },
		options() { this.initialize() },
	},



	created() { this.initialize() },



	methods: {

		initialize() {
			if( !this.graph ) return


			this.series = this.graph.series


			const options = {
				chart: {
					stacked: this.stacked,
					toolbar: {show: false},
					height: this.height,
					type: this.type,
				},
				stroke: {
					curve: 'smooth',
					// width: 4,
				},
				plotOptions: {
					bar: {
						borderRadius: 0,
						dataLabels: {
							position: 'top', // top, center, bottom
						},
					}
				},
				dataLabels: {
					enabled: this.dataLabels,
				},

				markers: {
					size: 5,
					strokeWidth: 1,
					// strokeOpacity: 0.75,
					hover: {
						size: 7,
						// sizeOffset: 3
					}
				},

				title: {
					text: this.graph.title,
					align: 'center',
					style: {
						fontSize: '16pt',
						fontWeight: 'bold'
					}
				},

				subtitle: {
					text: this.graph.subtitle,
					align: 'center',
					style: {
						fontSize: '13pt'
					}
				},

				tooltip: {
					enabled: true,
					shared: true,
					intersect: false,
					followCursor: true,
				},
				
				xaxis: {
					categories: this.graph.xLabels,
					axisBorder: {
						show: false
					},
					labels: {
						show: false
					},
					axisTicks: {
						show: false
					},
					crosshairs: {
						fill: {
							type: 'gradient',
							gradient: {
								colorFrom: '#D8E3F0',
								colorTo: '#BED1E6',
								stops: [0, 100],
								opacityFrom: 0.4,
								opacityTo: 0.5,
							}
						}
					},
					title: {
						text: this.graph.xLabel,
						align: 'center',
						style: {
							color: '#444',
							fontSize: '13pt'
						}
					},
					tooltip: { enabled: false },
				},

				yaxis: [{
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false,
					},
					labels: {
						show: true,
						showAlways: false,
						formatter: (val) => {
							return val + ( this.graph.yUnit ? ` ${this.graph.yUnit}` : '' );
						}
					},
					title: {
						text: this.graph.yLabel,
						align: 'center',
						style: {
							color: '#444',
							fontSize: '13pt'
						}
					}
				}],
				
			} // chartOptions

			if( this.graph.yMin !== undefined ) options.yaxis[0].min = this.graph.yMin
			if( this.graph.yMax !== undefined ) options.yaxis[0].max = this.graph.yMax

			if( this.graph.yUnit2 || this.graph.yLabel2 ) {
				options.yaxis.push({
					opposite: true,
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false,
					},
					labels: {
						show: true,
						showAlways: false,
						formatter: (val) => {
							return val + ( this.graph.yUnit2 ? ` ${this.graph.yUnit2}` : '' );
						}
					},
					title: {
						text: this.graph.yLabel2,
						align: 'center',
						style: {
							color: '#444',
							fontSize: '13pt'
						}
					}
				})

				if( this.graph.yMin2 !== undefined ) options.yaxis[1].min = this.graph.yMin2
				if( this.graph.yMax2 !== undefined ) options.yaxis[1].max = this.graph.yMax2
			}

			this.chartOptions = options
			if( this.colors ) this.chartOptions.colors = this.colors
			
			// this.mergeOptions( this.options )
		},


		// mergeOptions( source, destination = this.chartOptions ) {
		// 	if( !source ) return

		// 	for( var attrname in source ) {
		// 		if( Array.isArray( source[attrname] ) ) {
		// 			if( destination[attrname] === null ) destination[attrname] = []
		// 			if( typeof destination[attrname] === 'object' && !Array.isArray( destination[attrname] ) ) {
		// 				const arr = []
		// 				for( var i = 0; i < source[attrname].length; i++ ) arr.push( destination[attrname] )
		// 				destination[attrname] = arr
		// 			}

		// 			for( var i = 0; i < source[attrname].length; i++ ) {
		// 				if( i >= destination[attrname].length ) destination[attrname].push( source[attrname][i] )
		// 				else this.mergeOptions( source[attrname][i], destination[attrname][i])
		// 			}
		// 		} else if( typeof source[attrname] !== 'object' || source[attrname] === null ) destination[attrname] = source[attrname]
		// 		else this.mergeOptions( source[attrname], destination[attrname] )
		// 	}
		// },



	}
}
</script>



<style scoped>

</style>