<template>
<section>
  <ObjectTable
    :Source="quotes.objects"
    :Columns='columns'
    :Deletable="true"
    :DisplayFunction="priorityDisplay"
    @edit="editQuote"
    @delete="deleteQuote"
    ref="objectTable" />

  <button class="button" @click="newQuote">New Quote</button>

  <StretchModal ref="detailsModal" :clickToClose="false" :padding="'1em'" @close="$refs.objectTable.deselect()">
    <template #header>Quote Details</template>
    <QuoteDetails :Quote="currentQuote" @edit="(id, q) => updateQuote( id, q )" @add='q => pushQuote(q)' @close="$refs.detailsModal.close()" />
  </StretchModal>

  <ConfirmDialog :show="itemToDelete !== null" @cancel="itemToDelete = null" @confirm="confirmDelete()">
    <div>Are you sure you want to delete this quote?</div>
    <div style="font-size: 1.25em; font-weight: bold; margin: 0.25em 0;">{{ itemToDelete ? `"${itemToDelete.quote}"` : '' }}</div>
    <div><strong>This action CANNOT be undone!</strong></div>
  </ConfirmDialog>
</section>
</template>

<script>
import SettingsAPI from "@/api/SettingsAPI.js"
import LoadingQuote from "@/models/LoadingQuote.js"
import OrderedSet from "@/libraries/OrderedSet.js"
import QuoteDetails from "./QuoteDetails.vue"
import StretchModal from "@/components/utilities/StretchModal.vue"
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import ObjectTable from "./utilities/ObjectTable"

export default {
  name: "quotes-list",

  data() {
    return {
      quotes: new OrderedSet(),

      columns: [
        {
          displayName: "Priority",
          propertyName: 'priority'
        },
        {
          displayName: 'Quote',
          propertyName: 'quote'
        }
      ],
      currentQuote: null,
      itemToDelete: null,
      randomQuote: ""
    }
  },

  async created() {
    this.initialize()
  },

  methods: {

    async initialize() {
      try {
        let quotes = await SettingsAPI.getLoadingQuotes();
        this.quotes.objects = quotes
      } catch (e) {
        console.error(e);
        alert( 'error while loading quotes' )
      }
    },


    selectQuote(quote) {
      this.currentQuote = quote;
    },

    newQuote() {
      this.currentQuote = new LoadingQuote();
      this.$refs.detailsModal.open()
    },

    pushQuote( quote ) {
      this.quotes.push( quote )
      this.currentQuote = quote
    },

    updateQuote( id, quote ) {
      const idx = this.quotes.objects.findIndex( elem => elem.id == id )
      if( idx > -1 ) this.quotes.objects.splice( idx, 1, quote )
    },

    editQuote(quote) {
      this.currentQuote = quote;
      this.$refs.detailsModal.open()
    },

    deleteQuote(quote) {
      this.itemToDelete = quote
    },

    async confirmDelete() {
      const quote = this.itemToDelete
      try {
        await SettingsAPI.deleteLoadingQuote(quote);
        this.quotes.remove( quote )
      } catch (e) {
        console.error(e);
        alert("Error communicating with server; changes not saved.");
      }
      this.itemToDelete = null
    },

    priorityDisplay(item, propertyName) {
      if (propertyName === 'priority') {
        if (item.priority == 1) return 'High';
        else if (item.priority == 2) return 'Medium';
        else if (item.priority == 3) return 'Low';
      }
      return item[propertyName];
    },

  },

  components: {
    QuoteDetails,
    StretchModal,
    ConfirmDialog,
    ObjectTable,
  }
}
</script>
