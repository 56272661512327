<template>
<section>
  <div class='flex justify-center' v-if='showSpinner'>
    <div class='icon-spinner4 spin-loader'></div>
  </div>

  <div v-if='!showSpinner'>
    <div class="flex-row flex-justify-end flex-align-center py-05">
      <button class="button inline-block" @click="newStorefront">New Storefront</button>
    </div>

    <div v-if='storefronts.length'>
      <ObjectTable
        class='font-small'
        :Source='storefronts'
        :Columns='columns'
        :DisplayFunction='displayFunction'
        :Numbered='false'
        :SortBy='sortBy'
        :SortAsc='sortAsc'
        Duplicatable
        Deletable
        @duplicate="item => duplicateStorefront( item )"
        @delete='deleteStorefront'
        @edit="editStorefront"
        @sort='selectSortBy'
        ref="objectTable"
      />
    </div>
    <div v-else class="NoResults">No results</div>

  </div>

  <StretchModal ref="detailsModal" :clickToClose="false" @close="$refs.objectTable.deselect()">
    <template #header>Storefront Details</template>
    <StorefrontDetails v-if="currentStorefront" :storefrontIn="currentStorefront" :isNew="isNew" @create="id => setupNewStorefront( currentStorefront, id )" class="pa-1" />
  </StretchModal>

</section>
</template>


<script>
import ObjectTable from '@/components/utilities/ObjectTable.vue';
import StorefrontDetails from "./StorefrontDetails.vue";
import StretchModal from '@/components/utilities/StretchModal.vue'

import StorefrontAPI from '@/api/StorefrontAPI.js';
import Storefront from './Storefront.js';

export default {
  name: "StorefrontsList",

  components: {
    ObjectTable,
    StorefrontDetails,
    StretchModal,
  },

  data() {
    return {
      storefronts: [],
      currentStorefront: null,
      isNew: false,
      
      columns: [
        {
          displayName: 'id',
          propertyName: 'id',
          sortable: true
        },
        {
          displayName: 'Title',
          propertyName: 'title',
          sortable: true
        },
        {
          displayName: 'URL Slug',
          propertyName: 'slug',
          sortable: true
        }
      ],

      sortBy: 'id',
      sortAsc: true,
      showSpinner: false,
    }
  },

  async created() {
    this.initialize();
  },

  computed: {

  },


  methods: {

    async initialize() {
      this.showSpinner = true;
      this.storefronts = [];
      let res = await StorefrontAPI.getStorefronts();
      for(let item of res) this.storefronts.push(Storefront.import(item));
      this.showSpinner = false;
    },

    async duplicateStorefront( storefront ) {
      let res = await StorefrontAPI.getStorefront( storefront.slug )
      storefront = Storefront.import(res)

      this.isNew = true
      this.currentStorefront = storefront.clone()
      this.currentStorefront.id = null
      this.currentStorefront.slug = ''
      this.currentStorefront.title = ''

      this.storefronts.push( this.currentStorefront )
      this.$refs.detailsModal.open()
    },

    editStorefront(storefront) {
      this.isNew = false;
      this.currentStorefront = storefront;
      this.$refs.detailsModal.open()
    },

    async deleteStorefront(storefront) {
      let yes = confirm(`Really delete storefront '${storefront.title}'?`);
      if(!yes) return;

      await StorefrontAPI.deleteStorefront(storefront.id);
      let index = 0;
      for(let sf of this.storefronts) {
        if(sf.id == storefront.id) break;
        index++;
      }
      this.storefronts.splice(index, 1);
    },

    newStorefront() {
      this.isNew = true;
      this.currentStorefront = new Storefront();
      this.storefronts.push(this.currentStorefront);
      this.$refs.detailsModal.open()
    },

    setupNewStorefront( storefront, id ) {
      storefront.id = id
      this.isNew = false
    },


    displayFunction(item, prop) {
      return item[prop];
    },

    selectSortBy(prop) {
      if (prop == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = prop;
      }
    }
  },


}
</script>



