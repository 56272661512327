export default class OrderedSet {
  constructor(objects = [], orderAttribute = '') {
    this.objects = objects;
    this.orderAttribute = orderAttribute;
  }

  get length() { return this.objects.length; }

  [Symbol.iterator]() {
    let index = 0;
    return {
      next: () => {
        let value = (index < this.objects.length) ? this.objects[index] : 'none';
        let done = index >= this.objects.length;
        index++;
        return { value, done };
      }
    };
  }

  sort(condition = null){
    return condition ? this.objects.sort(condition) : this.objects.sort();
  }

  push(obj){
    if(!this.objects.includes(obj)){
      this.objects.push(obj);
      this.sort();
      return true;
    }
    return false;
  }

  pop(){
    return this.objects.pop();
  }

  find(callback = () => {}) {
    return this.objects.find(callback);
  }

  union(setIn) {
    let items = [];
    if (setIn instanceof OrderedSet) items = setIn.items;
    else items = setIn;
    items.forEach(item => this.push(item));
  }

  includes(obj, propName = '') {
    if (propName) {
      return this.objects.some(o => o[propName] === obj[propName])
    }
    return this.objects.includes(obj);
  }

  isEmpty() { return (this.objects.length === 0); }

  getFirst() { return this.objects[0]; }

  getLast() { return this.objects[this.objects.length-1]; }

  getNext(Obj) {
    let index = this.objects.indexOf(Obj);
    if (index < 0 || index+1 >= this.objects.length) return null;
    else return this.objects[index+1];
  }

  getPrevious(Obj) {
    let index = this.objects.indexOf(Obj);
    if (index < 1) return null;
    else return this.objects[index-1];
  }

  findByAttribute(attrName, attrVal) {
    if (!attrName || ! attrVal) {
      return null;
    }
    for (let item of this.objects){
      if (item[attrName] == attrVal) return item;
    }
    return null;
  }

  getItem(index) {
    if( index < 0 || index >= this.objects.length ) return null
    return this.objects[index]
  }

  remove(RemoveObj, propName = '') {
    this.objects = this.objects.filter(Item => {
      if (propName && Item[propName] === RemoveObj[propName]) return false;
      else if (Item === RemoveObj) return false;
      return true;
    });
  }

  filter(fn) {
    return this.objects.filter(fn);
  }

  search(properties = [], searchKey = "", numResults = 0) {
    let results = [];
    let regex = new RegExp(searchKey, "i");

    for (let prop of properties) {
      for (let obj of this.objects) {
        if (numResults && results.length > numResults) return results;
        if (regex.test(obj[prop])) {
          if (!results.includes(obj)) results.push(obj);
        }
      }
    }
    return results;
  }

  updateElement(attrName, obj) {
    for (let i in this.objects) {
      if (this.objects[i][attrName] == obj[attrName]) {
        let keys = Object.keys(this.objects[i]);
        keys.forEach(key => this.objects[i][key] = obj[key]);
      }
    }
  }

  export() {
    let data = [];
    for (let item of this.objects) {
      if (typeof item.export === "function") data.push(item.export());
      else data.push(item);
    }
    return data;
  }
}
