import PaginatedRequest from './PaginatedRequest.js'


export default class CustomerListRequest extends PaginatedRequest {
	
	includeActiveLicenseCount = true;
	includeAssignedLicenseCount = true;
	getPOCs = true;

	constructor(sortBy = null, sortAsc = true, page = 1, pageSize = 100, searchString = null, includeActiveLicenseCount = false, includeAssignedLicenseCount = false, getPOCs = false) {
		super(sortBy, sortAsc, page, pageSize, searchString);

		this.includeActiveLicenseCount = includeActiveLicenseCount;
		this.includeAssignedLicenseCount = includeAssignedLicenseCount;
		this.getPOCs = getPOCs;
	}
}