import Feature from './Feature'

/** @module Roles */

/**
 * Roles allow users to access various [features]{@link module:Roles.Feature} in the program. Admins
 * define which roles grant access to which features. For a user to have
 * access to a feature, they must have a role that contains the feature.
 *
 * Roles can be assigned for specific licenses. If this is the case, then
 * the user can only access the features in the role if they are using
 * a license that is assigned on the role. In addition, roles can also be
 * global, meaning that the user can access that role's features throughout
 * the program.
 *
 * @memberof module:Roles
 * @see module:Roles.Feature
 */
class Role {

	id = null
	name = ''
	description = ''
	licenses = []
	features = []
	isGlobal = false

  /**
   * @param {number} id - the role ID
   * @param {string} name - the role name
   * @param {string} description - a brief descrtiption of the role
   * @param {Array<string>} licenses - the list of the keys of the licenses that this role is valid for
   * @param {Array<Feature>} features - the list of features that this role grants access to
   * @param {boolean} global - whether this role is global or applies to only a single license
   */
  constructor(id = null, name = '', description = '', licenses = [], features = [], isGlobal = false) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.licenses = licenses;
    this.features = features;
    this.isGlobal = isGlobal;
  }


  /**
   * Returns whether this role grants access to the given tokenized [feature]{@link module:Roles.Feature}.
   *
   * If this is a global role, then this function returns `true` if at least one
   * of this role's features matches the given token list; otherwise, this function
   * returns `false`.
   *
   * If this is not a global role and a license key is provided, then this
   * function returns `true` if at least one of this role's features matches the
   * given token list and if this role is mapped to the license represented by
   * the given key; otherwise, this function returns `false`.
   *
   * If this role is not global and no license key is provided, then this
   * function returns `false`.
   *
   * Alternatively, if run in non-strict mode, this function returns `true` if this role
   * grants access to the given feature on any license, regardless of whether this role
   * is global or not.
   *
   * For more information regarding features, see [`Feature.hasAccess()`]{@link module:Roles.Feature#hasAccess}.
   *
   * @param {Array<string>} tokens - the list of tokens to check
   * @param {string} [licenseKey] - key for the license for which this feature must be granted (optional)
   * @param {boolean} [strictMode=true] - whether to operate in strict mode
   *
   * @returns {boolean} Whether this role grants access to the given tokenized feature as described above.
   */
  hasAccess(tokens, licenseKey, strictMode = true, print = false) {
    if (this.isGlobal || !strictMode) {
      return this.features.some(feature => feature.hasAccess(tokens, print));
    } else if (licenseKey) {
      return this.features.some(feature => feature.hasAccess(tokens, print)) &&
        this.licenses.includes(licenseKey);
    }
    return false;
  }



  /**
   * Returns an array of right-hand partial feature-strings that complete the `tokens` provided.
   *
   * @param {Array<String>} tokens - An array of strings (tokenized featureGrant string).
   * @returns {Array<String>} - an array of right-hand partial strings
   */
  getSubFeatureGrants(tokens) {
    var strings = [];
    for(let feature of this.features) {
      var featureString = feature.getSubFeatureGrants(tokens);
      if(featureString) strings.push(featureString);
    }
    return strings;
  }



  /**
   * Standard import function.
   *
   * @param {Object} role - an object containing role data, as returned by the server.
   * @returns {Object} A new instance of this class.
   */
  static import(role) {
    const features = [];
    for ( let feature of role.features ) {
      features.push( new Feature( feature.id, feature.name, feature.description ) );
    }
    return new Role(role.id, role.name, role.description, role.licenses, features, role.isGlobal);
  }
}

export default Role;
