<template>
<section class="pointer wrapper" :class="{'expand' : expanded}" style="width: 100%;" @click="expanded = !expanded">
  <p v-if="isTemplate">HTML Block</p>

  <div class='flex-column' style="width: 100%;">
    <div class="flex-row flex-justify-between">
      <div class="flex-row flex-align-center flex-gap-05">
        <div id="indicator" :class="{'expand' : expanded}" />
        <input v-if="isTemplate" type="text" v-model="section.name" placeholder="Block Label (admin)" />
        <div v-else>{{section.name}}<span style="color: #888; font-size: 0.8em;"> - HTML</span></div>
      </div>
      <div v-if="!isTemplate && !isDefault" id="revert" @click="revert"><span class="icon-undo" /> Revert</div>
    </div>

    <textarea v-model="section.value" @input="update" @click.stop="expand" placeholder="Default Value (Text/HTML)" class="invisible" :class="{'default': isDefault, 'expand' : expanded}" />
  </div>
</section>
</template>


<script>
export default {
  name: "LandingPageHTMLSection",

  props: {
    section: Object,

    isTemplate: {
      type: Boolean,
      default: true
    },

    isDefault: {
      type: Boolean,
      default: false
    },

  },


  data() {
    return {
      expanded: false,
    }
  },


  methods: {
    update(e) { console.debug("update..."); this.$emit('update'); },
    revert() { this.$emit('revert'); },
    expand(ev) {
      this.expanded = true
      this.$emit('click', ev)
    },
  }
}
</script>


<style scoped>
textarea {
  resize: vertical;
}
input, textarea { padding: 0.5em !important; }
textarea { min-height: 5em; transition: 0.1s; }

textarea.expand { min-height: 35em; }

.default {
  color: #777;
}
#revert {
  cursor: pointer;
}
#revert:hover { color: black; }

#indicator {
  min-width: 0.5em;
  min-height: 0.5em;
  width: 0.5em;
  height: 0.5em;

  border: 1px solid gray;
  border-radius: 0.5em;
}

#indicator.expand {
  background: green;
}
</style>
