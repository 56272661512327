import { render, staticRenderFns } from "./BulkLicenseEdit.vue?vue&type=template&id=5205c288&scoped=true"
import script from "./BulkLicenseEdit.vue?vue&type=script&lang=js"
export * from "./BulkLicenseEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5205c288",
  null
  
)

export default component.exports