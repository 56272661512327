<template>
<section>

	<div class="StickySaveButton">
		<button class="pillButton" @click="save()" :class="{ 'disabled' : !changesMade || !newItem.code }" :disabled="!changesMade || !newItem.code">
			Save Changes
		</button>
	</div>

	<section class='Tab sticky-top' style="margin-bottom: 0;">
		<button @click="mode='properties'" :class="{'active':mode==='properties'}">Properties</button>
		<button @click="mode='usage'" :class="{'active':mode==='usage'}">Usage (Orders)</button>
	</section>


	<!-- "Properties" tab -->
	<section v-if="mode === 'properties' && newItem !== null">

		<div id="isEnabled" class="flex-row flex-align-center flex-justify-center flex-gap-1 py-1 mb-1" :class="{ 'enabled' : newItem.enabled }">
			<ToggleButton v-model="newItem.enabled" :sync="true" />
			<span>
				Coupon Code is
				<span class="bold">{{ newItem.enabled ? 'Enabled' : 'Disabled' }}</span>
			</span>
		</div>



		<div class="flex-row flex-align-stretch flex-justify-center flex-gap-1 ma-2">

			<fieldset class="flex-column flex-gap-1 flex-grow">
				<legend>General</legend>

				<label>
					<div>Coupon Code:</div>
					<input type="text" v-model="newItem.code" style="min-width: 40vw" placeholder="Coupon Code" />
				</label>

				<label>
					<div>Admin Label:</div>
					<input type="text" v-model="newItem.adminLabel" style="min-width: 40vw" />
				</label>
				
				<label>
					<div>Admin Description:</div>
					<textarea v-model="newItem.adminDescription" style="min-width: 40vw" />
				</label>

				<label>
					<div>Attribute Purchases to Affiliate:</div>
					<AffiliateSearchDropdown :affiliate="affiliate" @update="aff => selectAffiliate( aff )" />
				</label>

			</fieldset>


			<fieldset class="flex-grow">
				<legend>Usage Limits</legend>
				<div class="flex-row flex-justify-center flex-gap-05 mb-1">
					<div class="nowrap">
						<v-date-picker v-model="newItem.startTime" mode="dateTime">
							<template #default="{ inputValue, togglePopover }">
								<button @click="togglePopover">{{ inputValue || 'No Start Date' }}</button>
							</template>
						</v-date-picker>
						<button v-if="newItem.startTime !== null" @click="newItem.startTime = null"><span class="icon-cross" /></button>
					</div>
					<div>-</div>
					<div class="nowrap">
						<v-date-picker v-model="newItem.endTime" mode="dateTime">
							<template v-slot="{ inputValue, togglePopover }">
								<button @click="togglePopover">{{ inputValue || 'No End Date' }}</button>
							</template>
						</v-date-picker>
						<button v-if="newItem.endTime !== null" @click="newItem.endTime = null"><span class="icon-cross" /></button>
					</div>
				</div>

				<div>
					Per-user limit: <input type="number" step="1" min="1" v-model="newItem.userUsageLimit" placeholder="unlimited" style="width: 7em;" />
					<button v-if="newItem.userUsageLimit !== null" @click="newItem.userUsageLimit = null"><span class="icon-cross" /></button>
				</div>

				<div>
					Global limit: <input type="number" step="1" min="1" v-model="newItem.globalUsageLimit" placeholder="unlimited" style="width: 7em;" />
					<button v-if="newItem.globalUsageLimit !== null" @click="newItem.globalUsageLimit = null"><span class="icon-cross" /></button>
				</div>
				
				<div>
					Global rate limit: <input type="number" step="1" min="1" v-model="newItem.globalUsageRateLimit" placeholder="unlimited" style="width: 7em;" />
					uses per
					<select v-model="newItem.globalRateLimitPerSeconds">
						<option :value="null">N/A</option>
						<option :value="60">minute</option>
						<option :value="3600">hour</option>
						<option :value="86400">day</option>
						<option :value="604800">week</option>
						<option :value="2592000">month (30 days)</option>
						<option :value="31536000">year (365 days)</option>
					</select>
					<button v-if="newItem.globalUsageRateLimit !== null" @click="newItem.globalUsageRateLimit = null"><span class="icon-cross" /></button>
				</div>

			</fieldset>
		</div>

		<div class="ma-2">
			<fieldset>
				<legend>Discounts</legend>
				<CouponDiscounts :enabled="!isNew || isSaved" :discounts="newItem.discountAssignments" @change="changeCounter++" />
			</fieldset>
		</div>




	</section>


</section>
</template>



<script>
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import Coupon from './Coupon.js';
import Affiliate from '@/features/SalesManagement/Affiliates/Affiliate.js'

import CouponDiscounts from './CouponDiscounts.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: 'CouponDetails',


	components: {
		CouponDiscounts,
		AffiliateSearchDropdown,
		ToggleButton,
	},


	props: {
		itemIn: Coupon,
		isNew: Boolean,
		view: {
			type: String,
			default: ''
		},
	},



	data() {
		return {
			mode: 'properties',
			newItem: null,
			originalItem: null,
			changeCounter: 0,

			isSaved: false,
			affiliate: null,
		}
	},

	created() {
		this.initialize();
	},

	computed: {
		changesMade() {
			this.changeCounter;
			return this.newItem && this.originalItem && !this.originalItem.isSame( this.newItem )
		},

		startTime: {
			get() { return this.newItem && this.newItem.startTime !== null ? this.newItem.startTime : new Date( 0 ) },
			set( value ) { this.newItem.startTime = value }
		},

		endTime: {
			get() { return this.newItem && this.newItem.endTime !== null ? this.newItem.endTime : new Date( 0 ) },
			set( value ) { this.newItem.endTime = value }
		}
	},



	watch: {
		itemIn() { this.initialize() },
	},



	methods: {

		async initialize() {
			this.originalItem = this.itemIn.clone()
			this.newItem = this.itemIn.clone()
			if(this.view) this.mode = this.view

			if( this.newItem.affiliateID ) {
				const data = await SalesManagementAPI.getAffiliateByID( this.newItem.affiliateID )
				this.affiliate = Affiliate.import( data )
			}
		},


		async save() {
			const itemToSave = this.newItem.clone();

			if(this.isNew) {
				await SalesManagementAPI.saveCoupon( itemToSave.export() )
				const obj = await SalesManagementAPI.getCouponDetails( itemToSave.code )
				const newItem = Coupon.import( obj )
				this.$emit( 'itemAdded', newItem )
				this.isSaved = true

			} else {
				await SalesManagementAPI.saveCoupon( itemToSave.export() )
				this.$emit('itemEdited', this.newItem.clone() )
			}
		},


		selectAffiliate( affiliate ) {
			this.newItem.affiliateID = affiliate.id
		}
	}

}
</script>



<style scoped>
#isEnabled {
	background: #999;
}
#isEnabled.enabled {
	background: lightgreen;
}
</style>