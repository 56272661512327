import API from "./API";



class ProxyAPI extends API {

	
	static controllerName = 'ProxyAPI'


	static async getRoutes( paginatedRequest ) {
		return await this.newPost( 'getRoutes', [], paginatedRequest )
	}


	static async searchRoutes( req ) {
		return await this.newPost( 'searchRoutes', [], req )
	}


	static async getRoute( id ) {
		return await this.newGet( 'getRoute', [ id ] )
	}


	static async createRoute( route ) {
		return await this.newPost( 'createRoute', [], route )
	}


	static enableRoute( routeID ) {
		return this.newGet( 'enableRoute', [ routeID ] )
	}


	static disableRoute( routeID ) {
		return this.newGet( 'disableRoute', [ routeID ] )
	}


	static async editRoute( route ) {
		return await this.newPost( 'editRoute', [], route )
	}


	static async deleteRoute( id ) {
		return await this.newGet( 'deleteRoute', [ id ] )
	}



	static async getRouteActivity( paginatedRequest ) {
		return await this.newPost( 'getRouteActivity', [], paginatedRequest )
	}


	static async getRecentActivity( paginatedRequest ) {
		return await this.newPost( 'getRecentActivity', [], paginatedRequest )
	}


}
export default ProxyAPI