<template>
	<SearchDropdown
    :placeholder="placeholder"
		:DisplayFunction="displayFunction"
		:selectDisplayFunction="selectDisplayFunction"
		:InitialValue="initialValue || selectDisplayFunction(user)"
		:ObjKey="'id'"
		:width="width"
		:browseIcon="true"
		:pages="pages"
		:externalResults="items"
		:dropUp="dropUp"
		@selected="(item) => selectUser(item)"
		@search="(txt, pg) => getUserList(txt, pg)"
    @confirm="txt => emitSearchList(txt)"
		@none="selectUser(null)"
		ref="search"
	/>
</template>


<script>
import UserAPI from "@/api/UserAPI.js";
import PaginatedRequest from '@/api/PaginatedRequest.js'
import User from '@/features/Users/User.js'
import SearchDropdown from "@/components/utilities/SearchDropdown.vue"

export default {
	name: "UserSearchDropdown",

	components: {
		SearchDropdown,
	},

	props: {
		user: {
			type: Object,
			default: null,
		},

		// Optional: limits universe of users
		courseID: {
			type: Number,
			default: null
		},

		// Optional: limits universe of users
		customerID: {
			type: Number,
			default: null
		},

		// Optional: limits universe of users
		cohortID: {
			type: Number,
			default: null
		},

		externalGetter: {
			type: Function,
			default: null
		},


		width: {
			type: String,
			default: '100%'
		},

		placeholder: {
      type: String,
      default: 'Search Users'
    },

		initialValue: {
			type: String,
			default: null
		},

		dropUp: {
			type: Boolean,
			default: false
		},
	},

	model: {
		prop: "user",
		event: "updateUser",
	},

	data() {
		return {
			items: [],
			pages: 1,
		};
	},

	methods: {
		selectUser(item) {
			this.$emit("updateUser", item);
		},

    clear() { this.$refs.search.clear() },
		focus() { this.$refs.search.focus() },

		displayFunction(obj) {
			if (!obj) return ""
			var name =  obj.firstName || obj.lastName ? `${obj.firstName}&nbsp;${obj.lastName}` : obj.fullName
			return `${name}&nbsp;<span style="color: #888; font-size: 0.75em;">${name ? '-' : ''}&nbsp;${ obj.emailAddress || "" }</span>`
		},
		selectDisplayFunction(obj) {
			if( !obj ) return ""
			var name = obj.firstName || obj.lastName ? `${obj.firstName} ${obj.lastName}` : obj.fullName
			return `${obj.userID}: ${name ? name + ' -' : ''} ${obj.emailAddress || ""}`
		},



		async emitSearchList( searchTerm ) {
			const data = await this.search( searchTerm, 1, 0 )
			const list = []
			for( let item of data.data ) list.push( User.import( item ) )
			this.$emit( 'matches', list, searchTerm )
		},


		async search( searchTerm, page = 1, pageSize = 25 ) {
			if( this.externalGetter ) return await this.externalGetter( searchTerm, page, 25 )

			const req = new PaginatedRequest( 'firstName', true, page, pageSize )
			req.forCourse = this.courseID
			if( this.courseID ) req.hasLicense = true
			if( this.customerID ) req.customerIDs = [ this.customerID ]
			if( this.cohortID ) req.cohortIDs = [ this.cohortID ]
			req.searchString = searchTerm ? searchTerm : null

			return await UserAPI.getUsers( req )
		},


		async getUserList( searchTerm, page = 1 ) {
			const data = await this.search( searchTerm, page )
			
			this.items = []
			if (!data || !data.data || !data.data.length) return
			this.pages = data.pages
			for (let item of data.data) {
				this.items.push( User.import(item) )
			}

		},
	},
};
</script>
