class LandingPageSlide {

  constructor(quote, name, position, imageName, id = null) {
    this.quote = quote;
    this.name = name;
    this.position = position;
    this.imageName = imageName;
    this.id = id || Date.now();
  }



  clone() {
    return LandingPageSlide.import(this.export());
  }

  isSame(obj) {
    return JSON.stringify(this.export()) == JSON.stringify(obj.export());
  }



  static import(obj) {
    // console.debug("LandingPageSlide.import()");
    const quote = obj.quote || '';
    const name = obj.name || '';
    const position = obj.position || '';
    const imageName = obj.imageName || '';
    const id = obj.id || null;

    return new LandingPageSlide(quote, name, position, imageName, id);
  }



  export() {
    // console.debug("LandingPageSlide.export()");
    return {
      quote: this.quote,
      name: this.name,
      position: this.position,
      imageName: this.imageName,
      id: this.id
    }
    // return JSON.parse(JSON.stringify(this));
  }



}

export default LandingPageSlide;
