import API from './API.js';
// import PaginatedRequest from './PaginatedRequest.js';

class ModalMessageAPI extends API {

	static controllerName = 'ModalMessageAPI'


	static async getModalMessages( req = {} ) {
		return await this.newPost( 'getModalMessages', [], req )
	}


	static async getModalMessage( modalMessageID ) {
		return await this.newGet( 'getModalMessage', [ modalMessageID ] )
	}


	static async getAssignedUsers( modalMessageID, triggerLogic = false ) {
		return await this.newGet( 'getAssignedUsers', [ modalMessageID, triggerLogic ] )
	}


	static async getAssignedUserCount( modalMessageID, triggerLogic = false ) {
		return await this.newGet( 'getAssignedUserCount', [ modalMessageID, triggerLogic ] )
	}


	static async dryRun( modalMessageID, triggerLogic = false ) {
		return await this.newGet( 'dryRun', [ modalMessageID, triggerLogic ] )
	}


	static async createModalMessage( modalMessage ) {
		return await this.newPost( 'createModalMessage', [], modalMessage )
	}


	static async editModalMessage( modalMessage ) {
		await this.newPost( 'editModalMessage', [], modalMessage )
	}


	static async deleteModalMessage( modalMessageID ) {
		await this.newPost( 'deleteModalMessage', [modalMessageID] )
	}


	static async enableModalMessage( modalMessageID ) {
		await this.newPost( 'enableModalMessage', [modalMessageID] )
	}


	static async disableModalMessage( modalMessageID ) {
		await this.newPost( 'disableModalMessage', [modalMessageID] )
	}




	static async assignCourse( modalMessageID, courseID ) {
		await this.newGet( 'assignCourse', [ modalMessageID, courseID ] )
	}

	static async removeCourse( modalMessageID, courseID ) {
		await this.newGet( 'removeCourse', [ modalMessageID, courseID ] )
	}


	static async assignAffiliate( modalMessageID, affiliateID ) {
		await this.newGet( 'assignAffiliate', [ modalMessageID, affiliateID ] )
	}

	static async removeAffiliate( modalMessageID, affiliateID ) {
		await this.newGet( 'removeAffiliate', [ modalMessageID, affiliateID ] )
	}


	static async assignCustomer( modalMessageID, customerID ) {
		await this.newGet( 'assignCustomer', [ modalMessageID, customerID ] )
	}

	static async removeCustomer( modalMessageID, customerID ) {
		await this.newGet( 'removeCustomer', [ modalMessageID, customerID ] )
	}


	static async assignCohort( modalMessageID, cohortID ) {
		await this.newGet( 'assignCohort', [ modalMessageID, cohortID ] )
	}

	static async removeCohort( modalMessageID, cohortID ) {
		await this.newGet( 'removeCohort', [ modalMessageID, cohortID ] )
	}


	static async assignRole( modalMessageID, roleID ) {
		await this.newGet( 'assignRole', [ modalMessageID, roleID ] )
	}

	static async removeRole( modalMessageID, roleID ) {
		await this.newGet( 'removeRole', [ modalMessageID, roleID ] )
	}


	static async assignUser( modalMessageID, userID ) {
		await this.newGet( 'assignUser', [ modalMessageID, userID ] )
	}

	static async removeUser( modalMessageID, userID ) {
		await this.newGet( 'removeUser', [ modalMessageID, userID ] )
	}


	static async updateRequiredSections( modalMessage ) {
		await this.newPost( 'updateRequiredSections', [], {
			modalMessageID: modalMessage.modalMessageID,
			requireCourseID: modalMessage.requireCourseID,
			requireAffiliateID: modalMessage.requireAffiliateID,
			requireCustomerID: modalMessage.requireCustomerID,
			requireCohortID: modalMessage.requireCohortID,
			requireRoleID: modalMessage.requireRoleID,
			listedUsersOnly: modalMessage.listedUsersOnly
		} )
	}


	/**
	 * 
	 * @param {PaginatedRequest} reqBody 
	 * @returns res
	 */
	static async getModalMessageViews( reqBody = null ) {
		return await this.newPost( 'getModalMessageViews', [], reqBody ) 
	}


	/**
	 * 
	 * @param {PaginatedRequest} reqBody 
	 * @returns res
	 */
	static async getModalMessageActions( reqBody = null ) {
		return await this.newPost( 'getModalMessageActions', [], reqBody ) 
	}


	/**
	 * Delete a record of an action taken by a user.
	 * This is included because "actions" can prevent a MM from displaying.
	 * 
	 */
	static async deleteModalMessageAction( actionID ) {
		return this.newGet( 'deleteModalMessageAction', [ actionID ] )
	}
	

	/**
	 * 
	 * @param {PaginatedRequest} reqBody 
	 * @returns res
	 */
	static async getResponses( reqBody = null ) {
		return await this.newPost( 'getResponses', [], reqBody ) 
	}



	static async getTypes() {
		return await this.newGet( 'getTypes' )
	}

	static async createType( type ) {
		return await this.newPost( 'createType', [], type )
	}

	static async editType( type ) {
		return await this.newPost( 'editType', [], type )
	}

	static async deleteType( typeID ) {
		return await this.newPost( 'deleteType', [typeID] )
	}

}

export default ModalMessageAPI