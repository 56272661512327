<template>
<section>

	<!-- <h2 class="center mt-0">Recent Traffic</h2> -->
	<div v-if='!loading && !error'>

		<div v-if='items.length'>
			<ObjectTable
				class='font-small'
				:Source='items'
				:Columns='columns'
				:Numbered='true'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				@edit="item => openItem( item )"
				@sort='prop => selectSortBy( prop )'
				ref="objectTable"
			/>
			<Paginator v-model="page" :numPages="pages" />
		</div>

		<div v-else class="NoResults">No results</div>
	</div>

	<div v-if="error" class="warning">Failed to get records</div>



	<StretchModal ref="detailsModal" @close="closeItem()" padding="2em">
		<template #header>Request #{{ currentItem.id }}: {{ currentItem.requestFQDN }} <span class="icon-arrow-right2" /> {{ currentItem.destinationFQDN }}</template>
		<ProxyTrafficDetails v-if="currentItem" :item="currentItem" />
	</StretchModal>


</section>
</template>

<script>
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ProxyTrafficDetails from './ProxyTrafficDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

import ProxyAPI from '@/api/ProxyAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import LogRecord from '@/features/Proxy/LogRecord.js'

export default {
	name: 'ProxyTrafficOverviewDetails',


	components: {
		ObjectTable,
		Paginator,
		ProxyTrafficDetails,
		StretchModal,
	},


	props: {

		mode: {
			type: String,
			default: 'destination'
		},

		mainFieldValue: {
			type: String,
			default: null
		},

		startDate: {
			type: Date,
			default: null
		},

		endDate: {
			type: Date,
			default: null
		},

	},


	data() {
		return {
			loading: false,
			error: false,

			sortBy: 'timeUpdated',
			sortAsc: false,
			count: 0,
			page: 1,
			pages: 1,
			pageSize: 100,

			items: [],
			currentItem: null,

			columns: [
				{
					displayName: 'Record #',
					propertyName: 'id',
					sortable: true
				},
				{
					displayName: 'Time',
					propertyName: 'timeUpdated',
					displayFunction: item => {
						return item.timeUpdated !== null ? item.timeUpdated.toLocaleString() : null
					},
					sortable: true
				},
				{
					displayName: 'Client IP',
					propertyName: 'clientIP',
					sortable: true
				},
				{
					displayName: 'Chosen Route ID',
					propertyName: 'routeID',
					sortable: true
				},
				{
					displayName: 'Requested URL',
					propertyName: 'requestFQDN',
					displayFunction: item => {
						return `<a href="${ item.requestFQDN }" target="_new">${ item.requestFQDN }</a>`
					},
					sortable: true
				},
				{
					displayName: 'Redirected to URL',
					propertyName: 'destinationFQDN',
					displayFunction: item => {
						return `<a href="${ item.destinationFQDN }" target="_new">${ item.destinationFQDN }</a>`
					},
					sortable: true
				},
				{
					displayName: 'Proxy Response Code',
					propertyName: 'responseCode',
					sortable: true
				},
			],

		}
	},



	created() { this.getTraffic() },



	watch: {
		mainFieldValue() { this.getTraffic() },
		page() { this.getTraffic() },
	},



	methods: {

		async getTraffic() {

			try {
				this.error = false
				this.loading = true

				this.items = []
				this.currentItem = null

				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.mainFieldValue )
				req.overview = false
				req.mode = this.mode
				if( this.startDate ) req.startTimestamp = Math.floor( this.startDate.getTime() / 1000 )
				if( this.endDate ) req.endTimestamp = Math.floor( this.endDate.getTime() / 1000 )

				const res = await ProxyAPI.getRecentActivity( req )
				for( let item of res.data.data ) this.items.push( LogRecord.import( item ) )


				this.count = res.data.count
				this.pages = res.data.pages

			} catch (e) {
				this.error = true
				console.error( e.message )

			} finally {
				this.loading = false
			}

		},


		openItem( item ) {
			this.currentItem = item
			this.$refs.detailsModal.open()
		},


		closeItem() {
			this.currentItem = null
			this.$refs.objectTable.deselect()
		},

	}
}
</script>



<style scoped>

</style>