class PaginatedRequest {
	
	sortBy = null
	sortAsc = true
	page = 1
	pageSize = 100

	searchString = null


	constructor( sortBy = null, sortAsc = true, page = 1, pageSize = 100, searchString = null ) {
		this.sortBy = sortBy
		this.sortAsc = sortAsc
		this.page = page
		this.pageSize = pageSize
		this.searchString = searchString
	}

}
export default PaginatedRequest