<template>
<section>

  <div class='py-05 flex-row flex-align-center flex-justify-between flex-gap-2 flex-wrap sticky-top bg-white border-bottom'>
    <SearchBox @search="search" />
    <div>
      <span>Show only:</span>&nbsp;
      <select v-model="orderStateValue" style="text-align: center;">
        <option value="">All</option>
        <option value="proposed">Proposed</option>
        <option value="canceled">Canceled</option>
        <option value="invoiced">Invoiced</option>
        <option disabled>-----</option>
        <option value="unpaid">Unpaid</option>
        <option value="paid">Paid</option>
        <option disabled>-----</option>
        <option value="unfulfilled">Unfulfilled</option>
        <option value="fulfilled">Fulfilled</option>
        <option disabled>-----</option>
        <option value="incomplete">Incomplete</option>
        <option value="complete">Complete</option>
      </select> &nbsp;
      <select v-model="contractStateValue" style="text-align: center;">
        <option :value="null">Filter By Contracts...</option>
        <option :value="false">Not a Contract</option>
        <option value="isContract">All Contracts</option>
        <option value="isExecutedContract">Executed Contracts</option>
        <option value="isActiveContract">Active Contracts</option>
        <option value="isExpiredContract">Expired Contracts</option>
        <option value="isPendingContract">Pending Contracts</option>
      </select>
    </div>

    <div v-if="!customerID" class="flex-row flex-gap-05">
      <select v-model="hasCustomer">
        <option :value="null">Filter by Customer...</option>
        <option :value="false">No Customer</option>
        <option :value="true">Has Customer</option>
      </select>
      <CustomerSearchDropdown @updateCustomer="cust => selectCustomer(cust)" width="250px" placeholder="Filter by Customer" ref="customerSearch" />
      <CustomerDetailsModal v-if="customerIDInternal" :customerIDInternal="customerIDInternal" />
    </div>
    
    <div v-if="!affiliateID" class="flex-row flex-gap-05">
      <select v-model="hasAffiliate">
        <option :value="null">Filter by Affiliate...</option>
        <option :value="false">No Affiliate</option>
        <option :value="true">Has Affiliate</option>
      </select>
      <AffiliateSearchDropdown @update="aff => selectAffiliate(aff)" width="250px" placeholder="Filter by Affiliate" ref="affiliateSearch" />
      <AffiliateDetailsModal v-if="affiliateIDInternal" :affiliateID="affiliateIDInternal" />
    </div>

    <!-- FILTER: timestamp / date -->
		<div class="flex-row flex-gap-05 flex-wrap">
			<div class="nowrap">
				<v-date-picker v-model="startDate" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="Start Date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
			</div>
			<div>-</div>
			<div class="nowrap">
				<v-date-picker v-model="endDate" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="End Date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
			</div>
		</div>

  </div>

  <OrdersDashboard
    v-if="showDashboard"
    :title="`${currentDate.getFullYear()} Sales`"
    :startMonth="1"
    :startYear="currentDate.getFullYear()"
    :endMonth="1"
    :endYear="currentDate.getFullYear() + 1"
    :showLinks="true"
    @gotoFindOrders="orderState => orderStateValue = orderState"
    class="mb-1"
  />

  <div class="flex-row flex-justify-center ma-1" v-if="userID || customerID">
		<AccountBalance :userID="userID" :customerID="customerID" />
	</div>

  <OrdersList
    :searchString="searchStr"
    :orderState="orderStateValue"
    :contractState="contractStateValue"
    :customerID="customerIDInternal"
    :hasCustomer="hasCustomer"
    :affiliateID="affiliateIDInternal"
    :hasAffiliate="hasAffiliate"
    :userID="userID"
    :RenderLinks='true'
    :start="start"
    :end="end" />

  <!-- <div>
    <button @click="addProductsToAllOrders">Add products &amp; PO's to orders</button>
    <label><input type="checkbox" v-model="applyChanges"> Save Changes</label>
  </div> -->
  <div>
    <!-- <button @click="upgradeOrderLineItems()">Upgrade ALL orders to new LineItem format</button> -->
    <!-- <button @click="upgradeOrderTransactions()">Upgrade ALL orders to new Transaction format</button> -->
    <button @click="attachAffiliates()">Attach V9 Affiliates to Orders</button>
  </div>

</section>
</template>


<script>
import OrdersAPI from '@/api/OrdersAPI.js'

import OrdersDashboard from './OrdersDashboard.vue'
import AccountBalance from '@/features/orders/AccountBalance.vue'
import OrdersList from './OrdersList.vue'
import CustomerDetailsModal from '@/features/SalesManagement/Customers/CustomerDetailsModal.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import AffiliateDetailsModal from '@/features/SalesManagement/Affiliates/AffiliateDetailsModal.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'

export default {
  name: 'OrderSearchPane',

  components: {
    OrdersDashboard,
    AccountBalance,
    OrdersList,
    CustomerDetailsModal,
    CustomerSearchDropdown,
    AffiliateDetailsModal,
    AffiliateSearchDropdown,
    SearchBox,
  },

  props: {
    orderState: {
      type: String,
      default: ''
    },

		contractState: {
			type: [String, Boolean],
			default: null
		},

    showDashboard: {
      type: Boolean,
      default: true
    },

    affiliateID: {
      type: Number,
      default: null
    },

    customerID: {
      type: Number,
      default: null
    },

    userID: {
      type: Number,
      default: null
    },
  },



  data() {
    return {
      searchStr: '',
      searchTimeoutID: 0,
      orderStateValue: '',
      hasCustomer: null,
      customerIDInternal: null,
      hasAffiliate: null,
      affiliateIDInternal: null,
			contractStateValue: null,
      applyChanges: false,
      currentDate: new Date(),

      startDate: null,  // JS Date
      endDate: null,    // JS Date
    }
  },



  created() { this.initialize() },



  computed: {
    start() {
      if( !this.startDate ) return null
      return Math.round( this.startDate.getTime() / 1000 )
    },
    end() {
      if( !this.endDate ) return null
      return Math.round( this.endDate.getTime() / 1000 )
    },
  },



  watch: {
    affiliateID( value ) { this.affiliateIDInternal = value },
    customerID( value ) { this.customerIDInternal = value },
  },



  methods: {
    initialize() {
      this.orderStateValue = this.orderState
			this.contractStateValue = this.contractState
      this.affiliateIDInternal = this.affiliateID
      this.customerIDInternal = this.customerID
    },


    search(str) {
      clearTimeout(this.searchTimeoutID);
      this.searchTimeoutID = setTimeout(this.updateSearchString, 500, str);
    },

    updateSearchString(searchStr) {
      this.searchStr = searchStr;
    },


    selectCustomer( cust ) {
      this.customerIDInternal = cust ? cust.id : null
    },
    
    selectAffiliate( aff ) {
      this.affiliateIDInternal = aff ? aff.id : null
    },


    // async addProductsToAllOrders() {
    //   await OrdersAPI.addProductsToAllOrders(this.applyChanges);
    //   alert("DONE!");
    // },


    async upgradeOrderLineItems() {
      await OrdersAPI.upgradeOrderLineItems()
      alert("DONE!")
    },

    async upgradeOrderTransactions() {
      await OrdersAPI.upgradeOrderTransactions()
      alert("DONE!")
    },


    async attachAffiliates() {
      await OrdersAPI.attachAffiliates().catch( () => { alert("FAILED!") } );
      alert("Affiliates Attached!")
    }

  }
}
</script>
