import API from "./API";
import { base_url } from '@/Config'
import ActionItem from '@/features/SalesManagement/ActionItems/ActionItem.js'
import PaginatedResponse from "./PaginatedResponse";



class SalesManagementAPI extends API {

	static controllerName = 'SalesManagementAPI'


	// --------------------------------------------
	// Landing Pages
	// --------------------------------------------

	static getLandingPages() {
		const url = `${base_url}/SalesManagementAPI/getLandingPages`;
		return this.get(url);
	}


	static getLandingPage(slug) {
		const url = `${base_url}/SalesManagementAPI/getLandingPage/${slug}`;
		return this.get(url);
	}


	static getLandingPageByID(id) {
		const url = `${base_url}/SalesManagementAPI/getLandingPageByID/${id}`;
		return this.get(url);
	}


	static createLandingPage(landingPage) {
		const url = `${base_url}/SalesManagementAPI/createLandingPage`;
		return this.sendJSON(url, landingPage);
	}


	static editLandingPage(landingPage) {
		const url = `${base_url}/SalesManagementAPI/editLandingPage`;
		return this.sendJSON(url, landingPage);
	}


	static deleteLandingPage(id) {
		const url = `${base_url}/SalesManagementAPI/deleteLandingPage/${id}`;
		return this.get(url);
	}





	// --------------------------------------------
	// Affiliates
	// --------------------------------------------

	static async getAffiliates( req = null ) {
		return await this.newPost( 'getAffiliates', [], req )
	}


	static getAffiliate(slug) {
		const url = `${base_url}/SalesManagementAPI/getAffiliate/${slug}`;
		return this.get(url);
	}


	static getAffiliateByID(id) {
		const url = `${base_url}/SalesManagementAPI/getAffiliateByID/${id}`;
		return this.get(url);
	}


	static async getAffiliatesByID( affiliateIDs ) {
		return await this.newPost( 'getAffiliatesByID', [], affiliateIDs )
	}


	static createAffiliate(affiliate) {
		const url = `${base_url}/SalesManagementAPI/createAffiliate`;
		return this.sendJSON(url, affiliate);
	}


	static isSlugAvailable( slug ) {
		return this.newGet( 'isSlugAvailable', [ slug ] )
	}


	static setSlug( affiliateID, slug ) {
		return this.newGet( 'setSlug', [ affiliateID, slug ] )
	}


	static editAffiliate(affiliate) {
		const url = `${base_url}/SalesManagementAPI/editAffiliate`;
		return this.sendJSON(url, affiliate);
	}


	static deleteAffiliate(id) {
		const url = `${base_url}/SalesManagementAPI/deleteAffiliate/${id}`;
		return this.get(url);
	}


	// --------------------------------------------
	// Points of Contact
	// --------------------------------------------
	
	static async getContacts( req, asCSV = false ) {
		var responseType = asCSV ? 'blob' : null
		const res = await this.newPost( 'getContacts', [ asCSV ], req, responseType )
		
		if( asCSV ) this.processResponseAsFileDownload( res.data )
		else return PaginatedResponse.import( res.data )
	}
	

	static saveContact(poc) { return this.newPost( 'saveContact', [], poc ) }
	static savePrimary( pocID, affID = null, custID = null, isPrimary = false ) {
		return this.newGet( 'savePrimary', [ pocID, affID, custID, isPrimary ] )
	}
	static saveContactEmail(pocEmail) { return this.newPost( 'saveContactEmail', [], pocEmail ) }
	static saveContactPhone(pocPhone) { return this.newPost( 'saveContactPhone', [], pocPhone ) }
	static saveContactAddress(pocAddr) { return this.newPost( 'saveContactAddress', [], pocAddr ) }

	static deleteContact(pocID) { return this.newGet( 'deleteContact', [ pocID ] ) }
	static deleteContactEmail(pocID, emailID) { return this.newGet( 'deleteContactEmail', [ pocID, emailID ] ) }
	static deleteContactPhone(pocID, phoneID) { return this.newGet( 'deleteContactPhone', [ pocID, phoneID ] ) }
	static deleteContactAddress(pocID, addressID) { return this.newGet( 'deleteContactAddress', [ pocID, addressID ] ) }

	static getPocsByID(pocIDs) {
		// todo: change pocIDs to pocRequest object
		const url = `${base_url}/SalesManagementAPI/getPocsByID`;
		return this.sendJSON(url, pocIDs);
	}

	static addContactToAffiliate(pocID, affiliateID) { return this.newGet( 'addContactToAffiliate', [ pocID, affiliateID ] ) }
	static addContactToCustomer(pocID, customerID) { return this.newGet( 'addContactToCustomer', [ pocID, customerID ] ) }



	// --------------------------------------------
	// Customers
	// --------------------------------------------

	static getCustomer(id) {
		const url = `${base_url}/SalesManagementAPI/getCustomer/${id}`;
		return this.get(url);
	}


	static async getCustomers(request) {
		const res = await this.newPost( 'getCustomers', [], request )
		return PaginatedResponse.import( res.data )
	}


	static getCustomersByID( customerIDs ) {
		const url = `${base_url}/SalesManagementAPI/getCustomersByID`;
		return this.sendJSON( url, customerIDs, false )
	}


	static createCustomer(customer) {
		const url = `${base_url}/SalesManagementAPI/createCustomer`;
		return this.sendJSON(url, customer);
	}


	static editCustomer(customer) {
		const url = `${base_url}/SalesManagementAPI/editCustomer`;
		return this.sendJSON(url, customer);
	}


	static deleteCustomer(id) {
		const url = `${base_url}/SalesManagementAPI/deleteCustomer/${id}`;
		return this.get(url);
	}


	static getCustomerInteractions(customerID) {
		const url = `${base_url}/SalesManagementAPI/getCustomerInteractions/${customerID}`;
		return this.get(url);
	}


	static createCustomerInteraction(customerID, interaction) {
		const url = `${base_url}/SalesManagementAPI/createCustomerInteraction/${customerID}`;
		return this.sendJSON(url, interaction);
	}


	static editCustomerInteraction(interaction) {
		const url = `${base_url}/SalesManagementAPI/editCustomerInteraction`;
		return this.sendJSON(url, interaction);
	}

	static getCustomerUserRelationships(customerID) {
		const url = `${base_url}/SalesManagementAPI/getCustomerUserRelationships/${customerID}`;
		return this.get(url);
	}

	static getCustomerUsers(customerID) {
		const url = `${base_url}/SalesManagementAPI/getCustomerUsers/${customerID}`;
		return this.get(url);
	}

	static createUserRelationship(parentID, childID, customerID) {
		console.log(parentID);
		const url = `${base_url}/SalesManagementAPI/createUserRelationship`;
		return this.sendJSON(url, { parentID: parentID, childID: childID, customerID: customerID });
	}

	static removeUserFromTree(parentID, childID, customerID) {
		const url = `${base_url}/SalesManagementAPI/removeUserFromTree`;
		return this.sendJSON(url, { parentID: parentID, childID: childID, customerID: customerID });
	}

	static createRootUser(userID, customerID) {
		const url = `${base_url}/SalesManagementAPI/createRootUser`;
		return this.sendJSON(url, { userID: userID, customerID: customerID });
	}




	// --------------------------------------------
	// Sales Reps
	// --------------------------------------------

	static getSalesRepresentatives() {
		const url = `${base_url}/SalesManagementAPI/getSalesRepresentatives`;
		return this.get(url);
	}


	static getSalesRepresentative(id) {
		const url = `${base_url}/SalesManagementAPI/getSalesRepresentative/${id}`;
		return this.get(url);
	}


	static createSalesRepresentative(rep) {
		const url = `${base_url}/SalesManagementAPI/createSalesRepresentative`;
		return this.sendJSON(url, rep);
	}


	static editSalesRepresentative(rep) {
		const url = `${base_url}/SalesManagementAPI/editSalesRepresentative`;
		return this.sendJSON(url, rep);
	}


	static deleteSalesRepresentative(id) {
		const url = `${base_url}/SalesManagementAPI/deleteSalesRepresentative/${id}`;
		return this.get(url);
	}





	// --------------------------------------------
	// Action Items
	// --------------------------------------------

	static async getActionItemsByHost(hostID, hostType, completed = false) {
		const data = await this.get(`${base_url}/SalesManagementAPI/getActionItemsByHost/${hostID}/${hostType}/${completed ? 'true' : ''}`)
		const items = []
		for (let item of data) items.push(ActionItem.import(item))
		return items
	}

	static async createActionItem(actionItem) {
		if (typeof actionItem.export == 'undefined') throw new Error("'actionItem' argument must be object of class 'ActionItem'")
		const obj = actionItem.export()
		const data = await this.sendJSON(`${base_url}/SalesManagementAPI/createActionItem`, obj)

		if (!data.id) throw new Error("Invalid response from server")
		actionItem.id = data.id
	}

	static updateActionItem(actionItem) {
		if (typeof actionItem.export !== 'undefined') actionItem = actionItem.export()
		return this.sendJSON(`${base_url}/SalesManagementAPI/updateActionItem`, actionItem)
	}

	static deleteActionItem(actionItemID) {
		if (!actionItemID || actionItemID <= 0) throw new Error("invalid actionItemID")
		return this.get(`${base_url}/SalesManagementAPI/deleteActionItem/${actionItemID}`)
	}

	static markActionItemComplete(actionItemID, timestamp = null) {
		return this.get(`${base_url}/SalesManagementAPI/markActionItemComplete/${actionItemID}/${timestamp !== null ? timestamp : ''}`)
	}





	// --------------------------------------------
	// Commissions
	// --------------------------------------------

	static getCommission(commissionID) {
		return this.get(`${base_url}/SalesManagementAPI/getCommission/${commissionID}`)
	}

	static getCommissions() {
		return this.get(`${base_url}/SalesManagementAPI/getCommissions`)
	}

	static getCommissionAssignments(affiliateID = null, customerID = null) {
		return this.newGet('getCommissionAssignments', [affiliateID, customerID])
	}

	static createCommission(commission) {
		return this.sendJSON(`${base_url}/SalesManagementAPI/createCommission`, commission)
	}

	static editCommission(commission) {
		return this.sendJSON(`${base_url}/SalesManagementAPI/editCommission`, commission)
	}

	static deleteCommission(commissionID) {
		return this.get(`${base_url}/SalesManagementAPI/deleteCommission/${commissionID}`)
	}

	static assignCommission(commID, repID, affiliateID = null, customerID = null, startDate = null, endDate = null) {
		return this.newGet('assignCommission', [commID, repID, affiliateID, customerID, startDate, endDate])
	}

	static unassignCommission(commID, repID, affiliateID = null, customerID = null) {
		return this.newGet('unassignCommission', [commID, repID, affiliateID, customerID])
	}





	// --------------------------------------------
	// Coupon Codes
	// --------------------------------------------

	static getCoupons( paginatedRequest ) {
		return this.newPost( 'getCoupons', [], paginatedRequest )
	}

	static getCouponDetails( couponCode ) {
		return this.newGet( 'getCouponDetails', [ couponCode ] )
	}

	static saveCoupon( couponObj ) {
		return this.newPost( 'saveCoupon', [], couponObj )
	}

	static deleteCoupon( couponCode ) {
		return this.newGet( 'deleteCoupon', [ couponCode ] )
	}


}

export default SalesManagementAPI;
