import Exim from '@/models/Exim.js'

class UserSession extends Exim {

	sessionID = null // this session's unique identifier
	userID = null
	firstName = null
	lastName = null
	email = null
	licenseKey = null
	courseID = null
	startTimestamp = null
	endTimestamp = null
	durationSeconds = null



	get durationString() {
		const mins = Math.round( (this.durationSeconds / 60 ) )
		if( mins <= 90 ) return mins + ' mins'

		return ( Math.round( (this.durationSeconds / 360 ) ) / 10 ) + ' hrs'
	}


	static import( obj ) {
		const item = super.import( obj )
		if( item.startTimestamp ) item.startTimestamp = new Date( item.startTimestamp * 1000 )
		if( item.endTimestamp ) item.endTimestamp = new Date( item.endTimestamp * 1000 )
		return item
	}

}
export default UserSession