<template>
  <div>
    <table class='table'>

      <tr class='table-row'>
        <th class='table-header'>Selected</th>
        <th class='table-header'>Role</th>
        <th class='table-header'>Global</th>
        <th class='table-header'>Licenses</th>
        <th class='table-header'>Cohorts</th>
      </tr>

      <UserRolesTableRow
        v-for='role in allRoles' 
        :key='role.id'
        :userID='userID'
        :Role='role'
        :userRole='getUserRole(role)'
        :Selected='isSelected(role)'
        @selected='toggleRole'
      />

    </table>
  </div>
</template>

<script>
import RolesAPI from '@/api/RolesAPI'
import OrderedSet from '@/libraries/OrderedSet.js'
import UserRolesTableRow from './UserRolesTableRow'

export default {
  name: 'user-roles',


  props: {
    userID: Number,
  },


  data() {
    return {
      userRoles: new OrderedSet()
    }
  },


  computed: {
    allRoles() { return this.$store.state.Roles.objects; },
  },


  created() { this.initialize() },


  methods: {
    
    async initialize() {
      this.userRoles.objects = await RolesAPI.getRoles(this.userID);
    },

    async toggleRole(role) {
      if (this.userRoles.includes(role, 'id')) {
        await RolesAPI.unassignRole(this.userID, role.id);
        this.userRoles.remove(role, 'id');
      } else {
        await RolesAPI.assignRole(this.userID, role);
        this.userRoles.push(role.clone());
      }
    },

    isSelected(role) {
      return this.userRoles.includes(role, "id");
    },

    getUserRole(role) {
      for (let r of this.userRoles) {
        if (r.id === role.id) return r;
      }
    }
  },

  components: {
    UserRolesTableRow
  }
}
</script>