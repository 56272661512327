<template>
<section class="flex-row flex-gap-2">

	<!-- PushReportDeliverySettingsDTO -->
	<section>
		<div class="section-header"><h1 class="left">Desired Reports</h1></div>
		<div class="reset-float"></div>
		<table>
			<thead>
				<tr>
					<th>License</th>
					<th>Session</th>
					<th>Daily</th>
					<th>Weekly</th>
					<th>Monthly</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item,i) in deliverySettings" :key="item.licenseKey">
					<td>{{ item.licenseKey }}</td>
					<td><label :for="'session-'+i"><input type="checkbox" v-model="item.session" @change="updateDelivery(item)" :id="'session-'+i" /></label></td>
					<td><label :for="'daily'+i"><input type="checkbox" v-model="item.daily" @change="updateDelivery(item)" :id="'daily'+i" /></label></td>
					<td><label :for="'weekly'+i"><input type="checkbox" v-model="item.weekly" @change="updateDelivery(item)" :id="'weekly'+i" /></label></td>
					<td><label :for="'monthly'+i"><input type="checkbox" v-model="item.monthly" @change="updateDelivery(item)" :id="'monthly'+i" /></label></td>
				</tr>
			</tbody>
		</table>
	</section>


	<!-- PushReportPreferencesDTO -->
	<section>
		<div class="section-header"><h1 class="left">Delivery Methods (global for {{user.firstName}})</h1></div>
		<div class="reset-float"></div>

		<div class="flex-row flex-gap-1">
			<label for="email"><input type="checkbox" v-model="pushReportPreferences.email" @change="updatePrefs()" id="email" />Reports by E-Mail</label>
			<label for="sms"><input type="checkbox" v-model="pushReportPreferences.sms" @change="updatePrefs()" id="sms" />Reports by SMS</label>
		</div>

		<div class="section-header mt-2"><h1 class="left">Desired Reports (global for {{user.firstName}})</h1></div>
		<div class="reset-float"></div>
		<label for="absentStudent"><input type="checkbox" v-model="pushReportPreferences.absentStudent" @change="updatePrefs()" id="absentStudent" />Absent Student Reports (Weekly)</label>
	</section>

</section>
</template>

<script>
import LicenseAPI from '@/api/LicenseAPI.js'
export default {
	name: 'PushReportSettings',

	props: {
		user: Object,
	},

	data() {
		return {
			pushReportPreferences: {
				email: false,
				sms: false,
				absentStudent: false,
			},

			deliverySettings: [],
		}
	},



	created() { this.initialize() },



	methods: {
		async initialize() {
			
			// Reset prefs
			this.pushReportPreferences = { email: false, sms: false, absentStudent: false }

			// Get prefs
			let prefs = await LicenseAPI.getPushReportPreferences(this.user.userID);
			this.pushReportPreferences.email = prefs.pushReportEmail;
			this.pushReportPreferences.sms = prefs.pushReportSMS;
			this.pushReportPreferences.absentStudent = prefs.absentStudentReport;

			// Reset delivery settings
			this.deliverySettings = []

			// Get delivery settings
			let settings = await LicenseAPI.getPushReportDeliverySettings(this.user.userID);
			if( !settings ) return

			for(var item of settings) {
				this.deliverySettings.push({
					licenseKey: item.licenseKey,
					session: item.sessionReport,
					daily: item.dailyReport,
					weekly: item.weeklyReport,
					monthly: item.monthlyReport
				})
			}
		},



		async updatePrefs() {
			let prefs = {
				pushReportEmail: this.pushReportPreferences.email,
				pushReportSMS: this.pushReportPreferences.sms,
				absentStudentReport: this.pushReportPreferences.absentStudent
			}
			await LicenseAPI.setPushReportPreferences(this.user.userID, prefs);
		},



		async updateDelivery(item) {
			let settings = {
				licenseKey: item.licenseKey,
				userID: this.user.userID,
				sessionReport: item.session,
				dailyReport: item.daily,
				weeklyReport: item.weekly,
				monthlyReport: item.monthly
			}
			await LicenseAPI.setPushReportDeliverySettings(this.user.userID, settings);
		},




	}
}
</script>

<style scoped>
table {
	text-align: center;
	border: 1px solid lightgray;
	border-collapse: collapse;
}
th, td {
	border: 1px solid lightgray;
	padding: 0.25em 0.5em;

}
</style>