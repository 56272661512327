<template>
<section>

	<div class="py-05 flex-row flex-align-center flex-justify-center flex-gap-1">
		<v-date-picker v-model="startDate" mode="dateTime">
			<template v-slot="{ inputValue, inputEvents }">
				<input
				placeholder="Start"
				:value="inputValue"
				v-on="inputEvents"
				/>
			</template>
		</v-date-picker> - 
		<v-date-picker v-model="endDate" mode="dateTime">
			<template v-slot="{ inputValue, inputEvents }">
				<input
				placeholder="End"
				:value="inputValue"
				v-on="inputEvents"
				/>
			</template>
		</v-date-picker>
	</div>

	<div class="center"><SearchBox :placeholder="'Filter by ' + mode" @search="str => search( str )" /></div>

	<section class="buttonGroup my-1">
		<button :class="{ 'selected' : mode === 'destination'}" @click="mode = 'destination'">Destination Page</button>
		<button :class="{ 'selected' : mode === 'request'}" @click="mode = 'request'">Requested Page</button>
		<button :class="{ 'selected' : mode === 'ip'}" @click="mode = 'ip'">Client IP</button>
		<button :class="{ 'selected' : mode === 'referrer'}" @click="mode = 'referrer'">Referrer</button>
	</section>

	<div v-if='!loading && !error'>
			
		<div v-if='items.length'>
			<ObjectTable
				class='font-small'
				:Source='items'
				:Columns='columns'
				:Numbered='true'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				@edit="item => openItem( item )"
				@sort='prop => selectSortBy( prop )'
				ref="objectTable"
			/>
			<Paginator v-model="page" :numPages="pages" />
		</div>

		<div v-else class="NoResults">No results</div>
	</div>

	<div v-if="error" class="warning">Failed to get records</div>


	<StretchModal ref="detailsModal" @close="closeItem()" padding="2em">
		<template #header>{{ mode }}: {{ currentItem.mainField }}</template>
		<ProxyTrafficOverviewDetails v-if="currentItem" :mode="mode" :mainFieldValue="currentItem.mainField" :startDate="startDate" :endDate="endDate" />
		<!-- <ProxyTrafficDetails v-if="currentItem" :item="currentItem" /> -->
	</StretchModal>

</section>
</template>



<script>
import ProxyTrafficOverviewDetails from './ProxyTrafficOverviewDetails.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

import ProxyAPI from '@/api/ProxyAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'

export default {
	name: 'ProxyDashboard',


	components: {
		ProxyTrafficOverviewDetails,
		ObjectTable,
		Paginator,
		SearchBox,
		StretchModal,
	},


	data() {
		var startDate = new Date()
		startDate.setDate( startDate.getDate() - 1 )

		return {
			mode: 'destination',

			loading: false,
			error: false,

			searchString: null,
			startDate: startDate,
      endDate: null,

			sortBy: 'occurrences',
			sortAsc: false,
			count: 0,
			page: 1,
			pages: 1,
			pageSize: 50,

			items: [],
			currentItem: null,

			columns: [
				{
					displayName: 'Destination',
					propertyName: 'mainField',
					sortable: true,
				},
				{
					displayName: 'Occurrences',
					propertyName: 'occurrences',
					sortable: true,
				},
				{
					displayName: 'Unique IPs',
					propertyName: 'uniqueIPs',
					sortable: true,
				},
				{
					displayName: 'Unique Request URLs',
					propertyName: 'uniqueRequests',
					sortable: true,
				},
				{
					displayName: 'Unique Destination URLs',
					propertyName: 'uniqueDestinations',
					sortable: true,
				},
				{
					displayName: 'Unique Referrers',
					propertyName: 'uniqueReferrers',
					sortable: true,
				},
				{
					displayName: 'Unique User Agents',
					propertyName: 'uniqueUserAgents',
					sortable: true,
				},


			],
		}
	},



	watch: {
		mode( value ) {
			this.columns[0].displayName = value.charAt(0).toUpperCase() + value.slice(1)
			this.page = 1
			this.getTraffic()
		},

		startDate() {
			this.page = 1
			this.getTraffic()
		},

		endDate() {
			this.page = 1
			this.getTraffic()
		},

		page() { this.getTraffic() },
	},


	
	created() { this.getTraffic() },



	methods: {

		search( str ) {
			this.page = 1
			this.searchString = ( str !== null && str !== '' ) ? str : null
			this.getTraffic()
		},
		

		async getTraffic() {
			try {
				this.error = false
				this.loading = true

				this.items = []
				this.currentItem = null

				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				req.mode = this.mode
				if( this.startDate ) req.startTimestamp = Math.floor( this.startDate.getTime() / 1000 )
				if( this.endDate ) req.endTimestamp = Math.floor( this.endDate.getTime() / 1000 )


				const res = await ProxyAPI.getRecentActivity( req )
				for( let item of res.data.data ) this.items.push( item )


				this.count = res.data.count
				this.pages = res.data.pages

			} catch (e) {
				this.error = true
				console.error( e.message )

			} finally {
				this.loading = false
			}
		},



		async openItem( item ) {			
			this.currentItem = item
			this.$refs.detailsModal.open()
		},


		closeItem() {
			this.currentItem = null
			this.$refs.objectTable.deselect()
		},



		selectSortBy( prop ) {
			if ( prop == this.sortBy ) {
				this.sortAsc = !this.sortAsc
				this.getTraffic()
				return
			}
			
			
			this.sortBy = prop
			this.sortAsc = false
			if( this.sortBy === 'mainField' ) this.sortAsc = true

			this.getTraffic()
		},

	}

}
</script>



<style scoped>

</style>