<template>
<section>
	<div class="flex-row flex-justify-end">
		<div class="pillButton small" @click="addContact()"><span class="icon-plus" /> New Contact</div>
	</div>
	<div v-for="(c, index) in contacts" :key="c.id">
		<PocDetails ref="pocList" :contact="c" :affiliateID="affiliateID" :customerID="customerID" :isNew="c.id === null" @update="(contact, isNew) => updateContact( contact, index, isNew )" @delete="confirmDelete( index )" />
	</div>

	<ConfirmDialog confirm="Delete" @confirm="deletePOC()" ref="deleteDialog">
		<div style="font-size: 2em;">Really <b class="color-red">DELETE</b> this point of contact?</div>
		<div>All of its information will be lost.</div>
	</ConfirmDialog>
</section>
</template>



<script>
import PocDetails from "./PocDetails.vue"
import ConfirmDialog from "@/components/utilities/ConfirmDialog.vue"
import POC from './POC.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'

export default {
	
	name: "PocList",
	
	
	components: {
		PocDetails,
		ConfirmDialog,
	},


	props: {

		affiliateID: {
			type: Number,
			default: null
		},
		customerID: {
			type: Number,
			default: null
		},
	},



	data() {
		return {
			contacts: [],
			deleteIndex: null,

			page: 1,
			pages: 1,
			count: 0,
		}
	},



	created() {
		this.initialize();
	},



	methods: {

		async initialize() {
			
			const req = new PaginatedRequest()
			if( this.affiliateID ) req.affiliateIDs = [ this.affiliateID ]
			if( this.customerID ) req.customerIDs = [ this.customerID ]
			req.sortBy = [ 'isPrimary', 'dateCreated' ]
			req.sortAsc = [ false, true ]
			req.includeEmails = true
			req.includePhones = true
			req.includeAddresses = true
			
			const pr = await SalesManagementAPI.getContacts( req )
			this.contacts = []
			for( var c of pr.data ) this.contacts.push( POC.import( c ) )
			this.count = pr.count
			this.pages = pr.pages			
		},


		async addContact() {
			const newContact = new POC()
			newContact.dateCreated = new Date()
			this.contacts.push( newContact )

			await this.$nextTick()
			this.$refs.pocList[ this.contacts.length -1 ].toggleEditMode( true )
		},


		updateContact( contact, index, isNew = false ) {
			if( isNew ) {
				if( this.affiliateID ) SalesManagementAPI.addContactToAffiliate( contact.id, this.affiliateID )
				if( this.customerID ) SalesManagementAPI.addContactToCustomer( contact.id, this.customerID )
			}

			this.$set( this.contacts, index, contact )
		},


		confirmDelete( index ) {
			this.deleteIndex = index;
			this.$refs.deleteDialog.open()
		},


		deletePOC() {
			const contact = this.contacts[ this.deleteIndex ]
			if( contact && contact.id !== null ) SalesManagementAPI.deleteContact( contact.id );
			this.contacts.splice(this.deleteIndex, 1);
		},
	}

}
</script>

<style scoped>

</style>