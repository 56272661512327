import API from "./API";
import {base_url} from './../Config';
// import store from "@/store";

export default class DemoAPI extends API {

  static async getDemoRequests() {
    var url = `${base_url}/DemoAPI/getDemoRequests`;
    return await this.get(url);
  }

  static async createDemoRequest(demo) {
    return await this.sendJSON(`${base_url}/DemoAPI/createDemoRequest`, demo);
  }

  static async editDemoRequest(demo) {
    return await this.sendJSON(`${base_url}/DemoAPI/editDemoRequest`, demo);
  }

  static async deleteDemoRequest(id) {
    if(!id) return;
    return await this.get(`${base_url}/DemoAPI/deleteDemoRequest/${id}`);
  }
}
