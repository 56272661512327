import Exim from '@/models/Exim.js'
import Product from '@/models/Product.js';



class ProductGroup extends Exim {


  // Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
  vueID = 0


  adminLabel = null

  title = ''
  description = ''
  products = []

  highlightProductSKU = null

  showPriceToggle = false
  priceTogglePositionDefault = false
  priceToggleLeftLabel = null
  priceToggleRightLabel = null



  constructor() {
    super()
    this.vueID = ++ProductGroup.idCounter
  }



  addProduct(product) {
    this.products.push(product);
  }


  removeProduct(product) {
    let i = 0;
    let found = false;
    for(let item of this.products) {
      if(item.sku === product.sku) {
        found = true;
        break;
      }
      i++;
    }

    if(found) {
      this.products.splice(i, 1);
      if( this.highlightProductSKU == product.sku ) this.highlightProductSKU = null
    }
  }



  static import( obj ) {

    const item = super.import( obj, [ 'products' ] )

    // Import products
    for(let prod of obj.products) item.products.push( Product.import( prod ) )

    return item
  }



  export( prodsAsSKUs = false ) {

    const data = super.export( [ 'products' ] )
    data.products = []

    if( prodsAsSKUs ) for( let prod of this.products ) data.products.push( prod.sku )
    else for(let prod of this.products) data.products.push( prod.export() )

    return data
  }


}
export default ProductGroup;
