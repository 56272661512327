<template>
  <div id="app">
    <router-view class='flex-grow' />
  </div>
</template>

<script>

export default {
  name: "app",

  async created() {
    if(this.$route.name !== 'home' && this.$route.name !== 'login') return
    if( this.$route.path == '/login' ) return
    this.$router.push( {name: 'login'} )
  },

}
</script>

<style>
@import url("assets/icons/icomoon/style.css");
@import url("assets/styles/main.css");
@import url("assets/styles/Boilerplate.css");
</style>

<style src="@/libraries/Tooltips/tooltips.css"></style>
