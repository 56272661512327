<template>
<section>

<h2 class="mt-0 center">{{ report.name }}</h2>

<div v-for="id in aggregateIDs" :key="id" class="inline-block border round-05 ma-1 pa-05">
	<div>Student / Cohort: {{ id }}</div>
	<template v-for="(range, idx) in ranges">
		<div v-if="range.hasValue( id )" :key="rangeNames[idx]">{{ range.label || rangeNames[idx] }}: {{ range.getValue( id ) }}</div>
	</template>
</div>

</section>
</template>



<script>
import ThresholdReportTemplate from './ThresholdReportTemplate.js'

export default {
	name: 'ThresholdReportViewer',

	props: {
		rawReport: {
			type: Object,
			required: true
		},
	},


	data() {
		return {
			aggregateIDs: null,
			report: null,
			ranges: [],
			rangeNames: [],
		}
	},


	watch: {
		rawReport() { this.initialize() },
	},


	created() { this.initialize() },


	methods: {
		initialize() {
			this.report = ThresholdReportTemplate.import( this.rawReport )
			this.aggregateIDs = this.report.getAllAggregateIDs()
			console.debug( this.aggregateIDs, this.report )


			this.ranges = []
			this.rangeNames = []
			for( let r of this.report.ranges.values() ) this.ranges.push( r )
			for( let r of this.report.ranges.keys() ) this.rangeNames.push( r )
		}
	}

}
</script>



<style scoped>

</style>