<template>
<section>
	
	<div class="flex-row flex-justify-between">
			
		<div class="flex-row flex-justify-start">
			<SearchBox @search="str => search(str)" />
		</div>
		
		<div class="flex-row flex-align-center flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} routes on {{ pages }} pages</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
			<button class="button inline-block" @click="newItem()">New Route</button>
		</div>

	</div>

	<div class='flex justify-center' v-if='loading'>
		<div class='icon-spinner4 spin-loader'></div>
	</div>

	<div v-if='!loading && !error'>

		<div v-if='items.length'>
			<ObjectTable
				class='font-small'
				:Source='items'
				:Columns='columns'
				:Numbered='false'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:StyleFunction='rowStyle'
				Deletable
				@delete='item => deleteItem( item )'
				@edit="item => editItem( item )"
				@sort='prop => selectSortBy( prop )'
				ref="objectTable"
			/>
			<Paginator v-model="page" :numPages="pages" />
		</div>
		<div v-else class="NoResults">No results</div>

	</div>

	<section v-if="error" class="warning">
		Failed to get records
	</section>


	<StretchModal ref="detailsModal" :clickToClose="false" @close="$refs.objectTable.deselect()" minHeight="95vh" padding="1em">
		<template #header>Route #{{ currentItem.id }}: {{ currentItem.label }}</template>
		<ProxyRouteDetails v-if="currentItem" :itemIn="currentItem" :isNew="isNew" @itemAdded="item => itemAdded( item )" @itemEdited="item => itemEdited( item )" />
	</StretchModal>

</section>
</template>



<script>
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ProxyRouteDetails from './ProxyRouteDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'

import ProxyAPI from '@/api/ProxyAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Route from '@/features/Proxy/Route.js'

export default {
	name: 'ProxyRouteList',

	
	components: {
		ObjectTable,
		Paginator,
		ProxyRouteDetails,
		StretchModal,
		SearchBox,
	},


	props: {

	},


	data() {
		return {
			mode: 'routes',
			loading: false,
			error: false,

			searchString: null,
			sortBy: 'id',
			sortAsc: true,
			count: 0,
			page: 1,
			pages: 1,
			pageSize: 100,

			items: [],
			currentItem: null,
			isNew: false,

			columns: [
				{
					displayName: 'id',
					propertyName: 'id',
					sortable: true
				},
				{
					displayName: 'Enabled',
					propertyName: 'enabled',
					sortable: true
				},
				{
					displayName: 'Route Label',
					propertyName: 'label',
					sortable: true
				},
				{
					displayName: 'Sources (FQDNs)',
					propertyName: 'sources',
					displayFunction: item => {
						const fqdns = []
						for( let src of item.sources ) fqdns.push( src.getFQDN() )
						return fqdns.join("<br />\n")
					},
					sortable: false
				},
				{
					displayName: 'Destinations (FQDNs)',
					propertyName: 'destinations',
					displayFunction: item => {
						const fqdns = []
						for( let dst of item.destinations ) fqdns.push( dst.getFQDN() )
						return fqdns.join("<br />\n")
					},
					sortable: false
				},
			]
		}
	},


	created() { this.initialize() },


	watch: {
		page() { this.getRoutes() },
		pageSize() { this.getRoutes() },
	},


	methods: {

		initialize() {
			this.getRoutes()
		},


		rowStyle( route ) {
			if( !route.enabled ) return { color: "#888" }
		},


		async getRoutes() {

			try {
				this.error = false
				this.loading = true

				this.items = []
				this.currentItem = null

				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				const res = await ProxyAPI.getRoutes( req )
				for( let item of res.data.data ) this.items.push( Route.import( item ) )


				this.count = res.data.count
				this.pages = res.data.pages

			} catch (e) {
				this.error = true
				console.error( e.message )

			} finally {
				this.loading = false
			}
			
		},


		search( str ) {
			this.searchString = str ? str : null
			this.getRoutes()
		},



		newItem() {
			this.isNew = true
			this.currentItem = new Route()
			this.$refs.detailsModal.open()
		},


		itemAdded( addedItem ) {
			this.currentItem = addedItem
			this.items.push( addedItem )
			this.isNew = false
			this.count++;
		},



		async editItem( item ) {
			this.isNew = false
			const data = await ProxyAPI.getRoute( item.id )
			this.currentItem = Route.import( data )
			this.$refs.detailsModal.open()
		},


		itemEdited( changedItem ) {
			this.currentItem = changedItem

			for(let i=0; i<this.items.length; i++) {
				if(this.items[i].id != changedItem.id) continue
				this.$set(this.items, i, changedItem)
				return
			}
		},



		async deleteItem( item ) {
			let yes = confirm( `Really delete Route '${item.label}' (${item.id})?` )
			if(!yes) return

			await ProxyAPI.deleteRoute( item.id ).catch( e => {
				console.error( e.message )
				alert( `FAILED to delete route #${item.id} ${item.label}:\n\n${e.message}` )
				throw e
			})

			let index = 0
			for( let obj of this.items ) {
				if( obj.id == item.id ) break
				index++
			}

			this.items.splice( index, 1 )
			if( !item.id ) return

			this.count--;

		},


		selectSortBy( prop ) {
			if ( prop == this.sortBy ) {
				this.sortAsc = !this.sortAsc
				this.getRoutes()
				return
			}
			
			
			this.sortBy = prop
			this.sortAsc = true
			if( this.sortBy === 'enabled' ) this.sortAsc = false

			this.getRoutes()
		}

	},
}
</script>



<style scoped>

</style>