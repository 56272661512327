class SalesRepresentative {


  constructor() {
    this.id = 0;
    this.firstName = '';
    this.lastName = '';
  }



  clone() {
    return this.constructor.import(this.export());
  }


  isSame(obj) {
    return JSON.stringify(this) == JSON.stringify(obj);
  }


  static import(obj) {
    const item = new this()
    for(let prop in obj) {
      if(item[prop] !== undefined) item[prop] = obj[prop];
    }
    return item;
  }


  export() {
    return JSON.parse(JSON.stringify(this));
  }



}

export default SalesRepresentative;
