<style scoped>
.activeFilter {
  background: var(--ekno-blue);
  color: white;
}
.filter {
  padding: 0.25em 0.5em;
  display: inline-block;
}
</style>



<template>
<div>
  <section class='py-05 flex-row flex-justify-between flex-align-center' style="position: sticky; top: 0; background: white;">

    <SearchBox @search="str => getUsers(str, true)" />
    <!-- <a class="link" v-if="Deletable" @click="courseStatsReport">ACT Stats Report</a> -->
		<section class='inline-block'>
			<div class="filter" :class="{'activeFilter' : filterLoggedIn}">
				<select v-model='filterLoggedIn'>
					<option value='' :selected='!filterLoggedIn'>---</option>
					<option value="is">Is</option>
					<option value="isnot">Is not</option>
				</select>

				<span> logged in</span>
			</div>

			<div v-if='!Course || !Course.courseID' class="filter" :class="{'activeFilter' : filterLicense}">
				<select v-model='filterLicense'>
					<option value=''>---</option>
					<option value='has'>Has</option>
					<option value='nothave'>Doesn't have</option>
				</select>

				<span> license for </span>

				<select v-model='filterCourse' :disabled='!filterLicense'>
					<option value='' disabled>Course</option>
					<option v-for='course in courses' :key='course.courseID' :value='course.courseID'>{{ course.courseName }}</option>
				</select>
			</div>


			<div class="filter" :class="{'activeFilter' : filterTypeRole}">
				<select v-model='filterTypeRole'>
					<option value=''>---</option>
					<option value='has'>Has</option>
					<option value='nothave'>Doesn't have</option>
				</select>

				<select v-model='filterRole' :disabled='!filterTypeRole'>
					<option value='' disabled>Role</option>
					<option v-for='role in roles' :key='role.id' :value='role.id'>{{ role.name }}</option>
				</select>
			</div>

		</section>

    <div>
      <label>Per page: </label>
      <select v-model="pageSize">
        <option :value='25'>25</option>
        <option :value='50'>50</option>
        <option :value='100'>100</option>
        <option :value='250'>250</option>
        <option :value='500'>500</option>
      </select>
    </div>
		<button class="button" v-if="Deletable" @click="newUser">New User</button>
  </section>



  <div class="flex-row flex-justify-between flex-align-center">
    <div>
      <button @click="selectAll">Select All</button>
      <button @click="selectNone">Select None</button>
      <span v-if="selectedUsers.length">
        &nbsp;
        <button @click="showDeleteSelected()">Delete Selected</button>
        <span>&nbsp;{{selectedUsers.length}} selected</span>
      </span>
    </div>
    <div>{{ count ? count.toLocaleString() : count }} users on {{ pages }} pages</div>
  </div>
  <div class='flex justify-center'>
    <div v-if='showSpinner' class='icon-spinner4 spin-loader'></div>
  </div>

  <div v-if='!showSpinner'>
    <div v-if='users.length'>
      <ObjectTable
        :Source="users"
        :Columns='columns'
        :DisplayFunction='tableDisplayFunction'
				:MultiSelect='true'
        :Deletable="Deletable"
        :PageNum="page"
        :Numbered="true"
        :PageSize="pageSize"
        :SortBy='sortBy'
        :SortAsc='sortAsc'
        @edit="user => editUser(user)"
        @delete="user => showDeleteSelected(user)"
        @sort='selectSortBy'
        @selectedItems='list => setSelectedUsers(list)'
        ref="objectTable"
      />

      <Paginator :value="page" :numPages="pages" @input="pg => toPage( pg )" />
    </div>
    <div v-else class="NoResults">No results</div>
  </div>

	<UserDetailsModal :user="currentUser" :show="showCurrentUser" @close="closeDetails()" />

  <ConfirmDialog :show="multiDeleteConfirm" @cancel="multiDeleteConfirm = false" @confirm="deleteSelectedUsers()">
		<div>Are you sure you want to delete the account(s) for:</div>
		<div v-for="user in usersToDelete" :key="user.userID" style="font-size: 1.1em; color: red; margin: 0.25em 0 0.25em 4em; text-align:left;">- {{ user.firstName }} {{user.lastName}} (#{{user.userID}})</div>
		<div class="mt-2"><strong>This action CANNOT be undone!</strong></div>
    <div class="mt-1">To confirm, type <span style="color:red; font-weight: bold;">DELETE</span> in the box below:</div>
    <input class="mt-05 center" style="font-size: 1.4em; width: 7em; color: red" ref="deleteInputBox" />
  </ConfirmDialog>

</div>
</template>

<script>
import UserDetailsModal from './UserDetailsModal.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

import API from '@/api/API.js'
import UserAPI from '@/api/UserAPI.js'
import RolesAPI from '@/api/RolesAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import User from '@/features/Users/User.js'
import Role from '@/models/Role.js'
import { powerprep_base_url } from '@/Config.js'

export default {
  name: "UsersList",

  components: {
    SearchBox,
    ObjectTable,
    UserDetailsModal,
    Paginator,
    ConfirmDialog,
  },



  props: {
    Course: {
      type: Object,
      default: null
    },

    customerID: {
      type: Number,
      default: null
    },

    Deletable: Boolean,

    showLoggedIn: {
      type: Number,
      default: 0
    },
  },



  data() {
    return {
      columns: [
        {
          displayName: '',
          propertyName: 'imp',
          hideInactive: true,
          link: true,
          clickHandler: async (event, user) => {
            event.stopPropagation()
            await API.impersonate(user.emailAddress).then(res => {
              window.open(powerprep_base_url, '_blank');
            }).catch(err => {
              alert(`Couldn't impersonate ${user.firstName}.  Make sure you're logged in as an administrator.`)
            });
          }
        },
        {
          displayName: 'ID',
          propertyName: 'userID',
          sortable: true
        },
        {
          displayName: 'First Name',
          propertyName: 'firstName',
          sortable: true
        },
        {
          displayName: 'Last Name',
          propertyName: 'lastName',
          sortable: true
        },
        {
          displayName: 'Email',
          propertyName: 'emailAddress',
          sortable: true
        },
        {
          displayName: 'Created',
          propertyName: 'creationDate',
          sortable: true
        },
        {
          displayName: 'PW',
          propertyName: 'hasPassword',
          sortable: true
        },
        {
          displayName: 'Last Login',
          propertyName: 'lastProgramLogin',
          sortable: true
        },
        {
          displayName: 'TIP <span class="icon-stopwatch" />',
          propertyName: 'timeInProgram',
          sortable: true
        },
        {
          displayName: '# Lic',
          propertyName: 'numLicenses',
          sortable: true
        },
        {
          displayName: 'Role',
          propertyName:'isMasterAccount',
          sortable: true
        },
        {
          displayName: 'Cust ID',
          propertyName: 'customerID',
          sortable: true
        }
      ],

      users: [],
      selectedUsers: [],
      currentUser: null,
			showCurrentUser: false,

      // currentLicense: null,
      showSpinner: false,

      count: 0,
      page: 1,
      pages: 1,

      // search
      prevSearch: "",

      licenseMode: 'new',

      searchString: '',
      filterLoggedIn: '',
      filterLicense: '',
      filterCourse: '',
      filterTypeRole: '',
      filterRole: '',
      showFilters: false,

      usersToDelete: [],
      multiDeleteConfirm: false,

      sortBy: 'creationDate',
      sortAsc: false,
    }
  },

  computed: {

    pageSize: {
      get() { return this.$store.state.userTableSettings.pageSize; },
      set(val) { this.$store.commit('setPageSize', val); }
    },

    courses() { return this.$store.state.Courses.objects; },

    roles() { return this.$store.state.Roles.objects; }
  },

  watch: {
    pageSize() { this.getFirstPage(); },
    filterLoggedIn() { this.getFirstPage(); },
    filterLicense() { if (this.filterCourse) this.getFirstPage(); },
    filterCourse() { this.getFirstPage(); },
    filterTypeRole() { if (this.filterRole) this.getFirstPage(); },
    filterRole() { this.getFirstPage(); },
    showLoggedIn() { this.enableLoggedInFilter() },
  },

  async created() {
    this.enableLoggedInFilter();
    await this.getUsers();
  },

  methods: {
    enableLoggedInFilter() {
      if(this.showLoggedIn > 0) this.filterLoggedIn = 'is'
      else this.filterLoggedIn = ''
    },

    async getUsers(searchString, setSearchString = false) {
      this.showSpinner = true

      try {
        if(setSearchString) this.searchString = searchString
  
        // populate filterOptions obj that will be sent in getUsers request
        const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
        if (searchString) req.searchString = searchString
        else if(this.searchString) req.searchString = this.searchString
        if (this.filterLoggedIn) req.isLoggedIn = this.filterLoggedIn === 'is'
        if (this.filterLicense) req.hasLicense = this.filterLicense === 'has'
        if (this.filterLicense && this.filterCourse) req.forCourse = this.filterCourse
        if (this.Course && this.Course.courseID) {
          // override filterLicense and filterCourse if you are viewing a course's users
          req.hasLicense = true
          req.forCourse = this.Course.courseID
        }
        if (this.filterTypeRole && this.filterRole) {
          req.hasRole = this.filterTypeRole === 'has'
          req.roleID = this.filterRole
        }
        if (this.customerID) req.customerIDs = [ this.customerID ]
  
        // reset to page 1 every new search
        if (req.searchString && req.searchString !== this.prevSearch) {
          this.page = 1
        }
  
        const data = await UserAPI.getUsers( req )
        this.users = []
        for(var item of data.data) this.users.push( User.import(item) )
        this.pages = data.pages
        this.count = data.count
        this.prevSearch = req.searchString

      } finally {
        this.showSpinner = false
      }
    },

    async getUsersLoggedIn(users = []) {
      const loggedIn = await UserAPI.areUsersLoggedIn(users);

      for (let user of this.users) {
        user.isLoggedIn = loggedIn[user.userID];
      }
    },

    async getUsersRoles(users = []) {
      const roles = await RolesAPI.getRolesFromUsersList(users);

      for (let user of this.users) {
        user.roles = [];
        if (roles[user.userID]) {
          for (let role of roles[user.userID]) {
            user.roles.push(Role.import(role));
          }
        }
      }
    },

    newUser() {
      this.currentUser = new User();
			this.showCurrentUser = true
    },

    editUser(user) {
      this.currentUser = user;
			this.showCurrentUser = true
      console.debug('edit user:', this.showCurrentUser)
    },

    async deleteUser(user) {
      try {
        await UserAPI.deleteUser(user);
      } catch (e) {
        console.error(e);
        alert("Error communicating with server; changes not saved.");
      }
    },


    toPage(page) {
      this.selectedUsers = []
      this.usersToDelete = []
      this.page = page;
      this.getUsers();
    },

    updateUser(user) {
      this.currentUser = user;
    },

    tableDisplayFunction(user, attr) {

      switch (attr) {

        case 'hasPassword':
          return user.hasPassword ?
            '<span class="icon-key message-success"></span>' :
            '<span class="icon-cross" style="color:#ddd"></span>';
            
        case 'lastProgramLogin':
          const date = user.lastProgramLogin ? user.lastProgramLogin.toLocaleDateString() : ''
          return user.isLoggedIn ? 
            `<div class="message-success"><span class="icon-checkmark"></span>&nbsp;${ date }</div>` :
            `<div><span class="icon-cross" style="color:#ddd;"></span>&nbsp;${date}</div>`
            
        case 'timeInProgram':
          const mins = user.timeInProgram;
          if (mins < 60) return `${Math.round(mins)} min`;
          const hours = mins / 60;
          if(hours < 10)return `${Math.round(hours * 10) / 10} h`;
          else return `${Math.round(hours)} h`;
        case 'isMasterAccount':
          if (user.numLicenses) return user.isMasterAccount ? 'Master' : 'Student';
          return '--';

        case 'creationDate':
          return user.creationDate.toLocaleDateString();
        case 'imp':
          return '<span class="icon-enter" />';
        default:
          return user[attr];
      }
    },

    clearAllFilters() {
      this.clearLoggedInFilter();
      this.clearLicenseFilter();
      this.clearRoleFilter();
    },

    clearLoggedInFilter() {
      this.filterLoggedIn = '';
    },

    clearLicenseFilter() {
      this.filterLicense = '';
      this.filterCourse = '';
    },

    clearRoleFilter() {
      this.filterTypeRole = '';
      this.filterRole = '';
    },

    getFirstPage() {
      this.page = 1;
      this.getUsers();
    },

    selectSortBy(prop) {
      if (prop == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = prop;
        if (
          this.sortBy === 'creationDate' ||
          this.sortBy === 'timeInProgram' ||
          this.sortBy === 'numLicenses' ||
          this.sortBy === 'isLoggedIn' ||
          this.sortBy === 'hasPassword' ||
          this.sortBy === 'lastProgramLogin' ||
          this.sortBy === 'isMasterAccount'
        ) {
          this.sortAsc = false;
        } else {
          this.sortAsc = true;
        }
      }
      this.getUsers();
    },



    // Multi-edit tools
		setSelectedUsers(list) {
			this.selectedUsers = [];
			for(let i=0; i < list.length; i++) {
				if(list[i] == true) this.selectedUsers.push( this.users.getItem(i) );
			}
		},

    selectAll() { this.$refs.objectTable.selectAll() },
    selectNone() { this.$refs.objectTable.selectNone() },
    closeDetails() {
      this.showCurrentUser = false
      this.$refs.objectTable.deselect()
    },
    
    showDeleteSelected( user = null ) {
      console.debug("USER: ", user)
      if( !this.selectedUsers.length && !user ) return

      if( user ) this.usersToDelete = [ user ]
      else {
        this.usersToDelete = []
        for(let u of this.selectedUsers) this.usersToDelete.push( u )
      }
      this.multiDeleteConfirm = true
    },

    async deleteSelectedUsers() {

      const input = this.$refs.deleteInputBox
      console.debug(input.value)
      if( input.value !== 'DELETE' ) return

      console.debug("MATCH! Deleting...")

      const promises = []
      for( let user of this.usersToDelete ) {
        promises.push( this.deleteUser(user) )
      }

      const outcomes = await Promise.allSettled(promises)
      
      var succeeded = 0
      var failedUsers = []
      for( var [index, item] of outcomes.entries() ) {
        if( item.status == 'fulfilled' ) succeeded++
        else if( item.status == 'rejected' ) {
          failedUsers.push( this.usersToDelete[ index ])
        }
      }

      alert(`${succeeded} users were deleted`)
      
      if( failedUsers.length > 0 ) {
        var str = ''
        for(let user of failedUsers) str += `\n${user.emailAddress}`
        alert(`Failed to delete ${failedUsers.length} users:\n${str}`)
      }

      this.multiDeleteConfirm = false
      this.usersToDelete = []
      this.selectedUsers = []
      this.getUsers()
    }


  }
}
</script>
