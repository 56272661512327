import Exim from '@/models/Exim.js'



class Score extends Exim {

	
	correct = 0
	total = 0
	scaledScore = '0'
	timestamp = null
	testName = null



	/**
	 * Get the percentage of questions answered correctly, as a floating-point number between 0 and 100.
	 * @returns {number} percent of questions answered correctly (floating-point).
	 * percent = (correct/total)*100
	 */
	get percent() {
		return (this.correct / (this.total || 1)) * 100;
	}


}
export default Score