<template>
<section class="pa-1">

<div>Name:
	<input v-if="!isAssignment" v-model="newCommission.name" />
	<span v-if="isAssignment">{{newCommission.name}}</span>
</div>


<div>Rate:
	<input v-if="!isAssignment" v-model.number="newCommission.rate" min="0" max="1" step="0.1" />
	<span v-if="isAssignment">{{newCommission.rate}}</span>
</div>
<div>Earned:
	<span v-if="!isAssignment">
		<select v-model="newCommission.dateEarned">
			<option value="POReceived">when purchase order received</option>
			<option value="Paid">when paid</option>
		</select>
	</span>
	<span v-if="isAssignment">{{ newCommission.dateEarned }}</span>
</div>
<div>Paid:
	<span v-if="!isAssignment">
		<select v-model="newCommission.datePaid">
			<option value="POReceived">when purchase order received</option>
			<option value="Paid">when paid</option>
		</select>
	</span>
	<span v-if="isAssignment">{{ newCommission.datePaid }}</span>
</div>

<!-- TODO: make this sane! -->
<div v-if="!isAssignment">Default Length (days): <input v-model.number="newCommission.defaultLengthDays" /></div>
<div v-if="isAssignment">
	<div>Begins:
		<v-date-picker v-model="startDateString" mode="dateTime">
			<template v-slot="{ inputValue, inputEvents }">
				<input
				class="date"
				placeholder="Start Date"
				:value="inputValue"
				v-on="inputEvents"
				/>
			</template>
		</v-date-picker>
	</div>
	<div>Ends:
		<v-date-picker v-model="endDateString" mode="dateTime">
			<template v-slot="{ inputValue, inputEvents }">
				<input
				class="date"
				placeholder="End Date"
				:value="inputValue"
				v-on="inputEvents"
				/>
			</template>
		</v-date-picker>
	</div>
</div>

<div><button class="SquareBlueButton" @click="save()" :class="{'disabled' : !changesMade}">Save Changes</button></div>

</section>
</template>



<script>
import Commission from './Commission.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'

export default {
	name: 'CommissionDetails',

	props: {
		commission: Object,
		isAssignment: {
			type: Boolean,
			default: false
		},
		order: {
			type: Object,
			default: null
		},
	},


	data() {
		return {
			newCommission: new Commission()
		}
	},


	created() { this.initialize() },


	computed: {
		changesMade() { return !this.newCommission.isSame(this.commission); },

		startDateString: {
			get() { return this.newCommission.startDateString },
			set(value) {
				if(value) value = new Date(value)
				else this.newCommission.startDate = null

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newCommission.startDate = value;
			}
		},

		endDateString: {
			get() { return this.newCommission.endDateString },
			set(value) {
				if(value) value = new Date(value)
				else this.newCommission.endDate = null

				if( !(value instanceof Date) || isNaN(value.valueOf()) ) return;
				this.newCommission.endDate = value;
			}
		},
	},


	watch: {
		commission() { this.initialize() },
	},


	methods: {
		initialize() {
			Object.assign(this.newCommission, this.commission)
		},


		async save() {
			var res = null

			// Editing Commission ASSIGNMENT
			if(this.isAssignment) {
				if(this.order) {
					// TODO
				} else {
					var com = this.newCommission
					await SalesManagementAPI.assignCommission(com.commissionID, com.salesRepID, com.affiliateID, com.customerID, com.startDateTS, com.endDateTS)
					Object.assign(this.commission, this.newCommission)
				}

			} else {

				// New Commission
				if(!this.commission.commissionID) {
					res = await SalesManagementAPI.createCommission( this.newCommission.export() )
					if(!res.commissionID) throw new Error("Invalid commission ID")
					this.newCommission.commissionID = res.commissionID
					Object.assign(this.commission, this.newCommission)

				// Edit existing Commission
				} else {
					res = await SalesManagementAPI.editCommission( this.newCommission.export() )
					this.newCommission = Commission.import(res)
					Object.assign(this.commission, this.newCommission)
				}

			}
		},



	}



}
</script>


<style scoped>
input {
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 0.25em;
	padding: 0.25em 0.5em;
	font-weight: bold;
}
input.date { width: 175px; }
</style>
