/**
 * Intances of this class grant access to various features in the program.
 * Admins assign features to [roles]{@link module:Roles.Role}, then assign
 * roles to users. If a user has a role that contains a feature, then the
 * user has access to that feature.
 * @memberof module:Roles
 * @see module:Roles.Role
 */
class Feature {

	
	id = ''
	name = ''
	description = ''


  /**
   * @param {number} id - the feature ID
   * @param {string} name - the feature name
   * @param {string} description - a brief description of the feature
   */
  constructor(id = '', name = '', description = '') {
    this.id = id;
    this.name = name;
    this.description = description;
  }


  /**
   * Checks whether this feature is included in the given list of tokens.
   *
   * Put simply, in order for this feature to be included in the list of tokens,
   * the tokens must describe this feature or one derived from this feature.
   * For example, if this feature's name was `"teacher:view_student_data"`, the
   * tokens `["teacher", "view_student_data"]` would pass because they reference
   * this feature. Likewise, the tokens `["teacher", "view_student_data", "view_questions"]`
   * would also pass, since they reference a feature derived from this feature.
   *
   * In addition, this function accepts the wildcard `"*"` as a token. The wildcard
   * matches any subfeature at the specified level. Using the previous example,
   * the tokens `["teacher:*"]` would pass since the `"view_student_data"` subfeature
   * appears at the same level as the wildcard.
   *
   * This function will not pass ancestors of this feature. Again, using the above
   * example, the token `["teacher"]` would not pass, since it describes a feature
   * that is more general than `"teacher:view_student_data"`.
   *
   * @param {Array<string>} tokens - the feature tokens to analyze
   *
   * @returns {boolean} Whether the given token matches this feature.
   */
  hasAccess(tokens, print = false) {
    const assignedTokens = this.name.split(':');

    // If this featureGrant is more specific than the one requested, FAIL
    if(assignedTokens.length > tokens.length) return false
    const length = Math.min(tokens.length, assignedTokens.length)

    if(print) console.debug(tokens, assignedTokens)

    // Check that the requested tokens match this Feature's tokens (or '*')
    for (let i = 0; i < length; ++i) {
      if(tokens[i] == '*' && i < (tokens.length-1)) throw new Error("Invalid token array:", tokens)
      if (tokens[i] !== '*' && tokens[i].localeCompare(assignedTokens[i]) !== 0) return false
    }
    return true;
  }



  /**
   * If this Feature is a subset of the feature indicated by the `tokens` array,
   * returns a right-hand partial feature-string that completes the `tokens` provided.
   *
   * @param {Array<String>} tokens - An array of strings (tokenized featureGrant string).
   * @returns {String|null} - a right-hand partial string or null
   */
  getSubFeatureGrants(tokens) {
    const nameTokens = this.name.split(':');
    if(tokens.length > nameTokens.length) return null;

    for(let i = 0; i < tokens.length; i++) {
      if(tokens[i] !== nameTokens[i]) return null;
    }

    var partialName = '';
    for(let i = tokens.length; i < nameTokens.length; i++) partialName += nameTokens[i];
    return partialName;
  }
}

export default Feature;
