<template>
<section>

	<section class="Tab">
		<button @click="mode = 'dashboard'" :class="{ active: mode === 'dashboard' }">Dashboard</button>
		<button @click="mode = 'routes'" :class="{ active: mode === 'routes' }">Routes</button>
		<button @click="mode = 'traffic'" :class="{ active: mode === 'traffic' }">All Traffic</button>
	</section>

	<ProxyDashboard v-if="mode === 'dashboard'" />
	<ProxyRouteList v-else-if="mode === 'routes'" />
	<ProxyTrafficList v-else-if="mode === 'traffic'" />
	
</section>
</template>



<script>
import ProxyDashboard from "./ProxyDashboard.vue"
import ProxyRouteList from "./ProxyRouteList.vue"
import ProxyTrafficList from "./ProxyTrafficList.vue"
export default {
	name: 'ProxyPane',

	
	components: {
		ProxyDashboard,
		ProxyRouteList,
		ProxyTrafficList,
	},


	props: {

	},


	data() {
		return {
			mode: 'dashboard',
		}
	},


	methods: {

	},
}
</script>



<style scoped>

</style>