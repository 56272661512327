
/**
 * Send messages directly between objects (event-driven pipes)
 * 
 * 1) register the receiving object by calling listen() and providing a callback
 * 2) send data from the sender to the receiver by calling send()
 * 
 * "channels" or "pipes" are created by name, when the first listener is added
 */
class DataBus {

	listeners = null

	constructor() {
		this.listeners = new Map()
	}


	listen( pipeName, callback ) {
		if( !this.pipeExists( pipeName ) ) this.createPipe( pipeName )
		const pipe = this.listeners.get( pipeName )
		pipe.add( callback )
	}


	pipeExists( pipeName ) {
		return this.listeners.has( pipeName )
	}


	createPipe( pipeName ) {
		if( this.pipeExists( pipeName ) ) return false
		
		const pipe = new Set()
		this.listeners.set( pipeName, pipe )
		return pipe
	}



	/**
	 * Remove a listener from a pipe
	 * @param {string} pipeName 
	 * @param {function} callback 
	 * @returns TRUE if the callback was found and removed; FALSE if it wasn't found
	 */
	unlisten( pipeName, callback ) {
		if( !this.pipeExists( pipeName ) ) return false
		const pipe = this.listeners.get( pipeName )
		return pipe.delete( callback )
	}


	send( pipeName, data ) {
		const listeners = this.listeners.get( pipeName )
		if( !listeners ) return

		for( var listener of listeners.values() ) {
			
			try {
				listener( data )

			} catch( e ) {
				console.error( `DataBus: Pipe callback FAILED on pipe ${pipeName}: ` + e )
				if( window.Sentry!== undefined ) window.Sentry.captureException(e)
			}

		}

	}


	list( pipeName = null ) {
		if( !pipeName ) return this.listeners
		else return this.listeners.get( pipeName )
	}

}
export default DataBus