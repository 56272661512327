export default class Role {
	constructor(id = null, name = '', description = '', features = [], licenses = [], isGlobal = false, cohorts = []) {
		this.id = parseInt(id) || null;
		this.name = name;
		this.description = description;
		this.features = features
		this.licenses = licenses;
		this.isGlobal = isGlobal;
		this.cohorts = cohorts;
	}

	isSame(role) {
		return this.id === role.id &&
			this.name === role.name &&
			this.description === role.description;
	}

	clone() {
		const role = new Role(this.id, this.name, this.description);
		const licenses = []
		for (let license of this.licenses) {
			licenses.push(license);
		}
		role.licenses = licenses;

		const cohorts = [];
		for (let cohort of this.cohorts) {
			cohorts.push(cohort);
		}
		role.cohorts = cohorts;

		return role;
	}

	export() {
		const data = {
			name: this.name,
			description: this.description,
			licenses: [],
			isGlobal: this.isGlobal,
			features: [],
			cohorts: []
		}

		for (let license of this.licenses) {
			if (license instanceof String) data.licenses.push(license);
			else if (license instanceof Object) data.licenses.push(license.serialKey);
		}

		for (let feature of this.features) {
			data.features.push(feature.export());
		}

		// I hope I did this right
		for (let cohort of this.cohorts) {
			data.cohorts.push(cohort);
		}
		//

		if (this.id) data.id = this.id;
		return data;
	}

	static import(json) {
		const licenses = [];
		for (let license of json.licenses) {
			licenses.push({ serialKey: license });
		}
		return new Role(json.id, json.name, json.description, json.features, licenses, json.isGlobal, json.cohorts);
	}
}