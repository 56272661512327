import Exim from '@/models/Exim.js'

class LoginAttempt extends Exim {

	id = null
	userID = null
	emailAddress = null
	ipAddress = null
	timestamp = null
	success = false
	impersonatorID = null
	method = null


	static import( obj ) {
		const item = super.import( obj )
		if( item.timestamp ) item.timestamp = new Date( item.timestamp * 1000 )
		return item
	}

}
export default LoginAttempt