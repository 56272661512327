import Exim from "@/models/Exim";

class ModalMessageResponse extends Exim {

	responseID = null
	userID = null;
	modalMessageID = null
	body = null
	createTimestamp = null
	editTimestamp = null

	// OPTIONAL: getter-only properties
	modalMessageName = null;
	userFirstName = null;
	userLastName = null;
	userEmail = null;


	static import(obj) {

    const newObj = super.import(obj)
    if(newObj.createTimestamp) newObj.createTimestamp = new Date(newObj.createTimestamp * 1000)
    if(newObj.editTimestamp) newObj.editTimestamp = new Date(newObj.editTimestamp * 1000)
    
		return newObj;
  }



	export() {
		
		const data = super.export()
		if(data.createTimestamp) data.createTimestamp = Math.round(data.createTimestamp.getTime() / 1000)
		if(data.editTimestamp) data.editTimestamp = Math.round(data.editTimestamp.getTime() / 1000)

		return data
	}

}
export default ModalMessageResponse