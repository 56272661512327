class CreditCardValidator {

	static cardholderNameIsValid(name) {
		if(name.length >= 2) return true;
		else return false;
	}


	static cardNumberIsValid(cardNumber) {
		// Existence
		if( !cardNumber ) return false;

		// Remove non-numeric chars
		cardNumber = cardNumber.replace(/\D/g, '');

		// Validate length (13-19 digits)
		if( !cardNumber.match(/[0-9]{13,19}/g) ) return false;

		return true;
	}


	static securityCodeIsValid(code) {

		// Strip out non-numeric chars
		let numericCode = code.replace(/[^-0-9]+/i, '');

		// Ensure code exists & is non-null
		if(!code || !numericCode) return false;

		// Ensure only numeric digits were entered
		if(code.length !== numericCode.length) return false;

		// Validate # of digits
		if(code.length < 3 || code.length > 4) return false;
		return true;
	}


	static expirationDateIsValid(month, year) {
		// Verify that month is an integer
		let intMonth = parseInt(month);
		if(!intMonth) return false;
		if(month.length !== intMonth.toString().length) return false;

		// Verify that year is an integer
		let intYear = parseInt(year);
		if(!intYear) return false;
		if(year.length !== intYear.toString().length) return false;

		// Verify month is a number is 1 thru 12
		if(intMonth < 1 || intMonth > 12) return false;

		// Verify card is still valid!
		let today = new Date();
		let todayMonth = today.getMonth() + 1;
		let todayYear = today.getFullYear();

		if(intYear < todayYear) return false;
		else if(intYear == todayYear && intMonth < todayMonth) return false;

		return true;
	}


	static zipCodeIsValid(zip) {
		// Strip out non-numeric chars
		let numericZip = zip.replace(/[^-0-9]+/i, '');

		// check validity
		if( !zip || zip.length < 5 || numericZip.length < 5 ) return false;
		return true;
	}
}
export default CreditCardValidator;
