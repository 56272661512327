<template>
  <tr class='table-row'>
    <td class='table-data'>
      <input type='checkbox' :checked='Selected' @click='$emit("selected", Role)' />
    </td>

    <td class='table-data'>{{ Role.name }}</td>

    <td class='table-data'>
      <input 
        v-if='userRole' 
        type='checkbox' 
        :checked='userRole.isGlobal'
        @input='toggleIsGlobal'
      />
    </td>

    <td class='table-data clickable'>
      <div v-if='userRole'>
        <div @click='licExpanded = !licExpanded'>
          <span v-if='licExpanded'>&#9662;</span>
          <span v-else>&#9656;</span>
          <span> {{ userRole.licenses.length }}</span>
        </div>
        <div v-if='licExpanded'>
          <div v-for='(license, index) in userRole.licenses' :key='index'>
            <input type='text' @input='inputSerialKey' v-model='license.serialKey' />
            <span class='icon-bin' @click='removeLicense(license)'></span>
          </div>

          <button class='button' @click='addLicense'>Add License</button>
        </div>
      </div>
    </td>

    <td class="table-data clickable">
      <div v-if="userRole">
        <div @click='cohortExpanded = !cohortExpanded'>
          <span v-if='cohortExpanded'>&#9662;</span>
          <span v-else>&#9656;</span>
          <span> {{ userRole.cohorts.length }}</span>
        </div>
        <div v-if="cohortExpanded">
          <div v-for="cohort in userRole.cohorts" :key="cohort.id" class="mb-1">
            <b>{{ cohort.name }} ({{ cohort.licenseKeys.length }}):</b>
            <div v-for="lic in cohort.licenseKeys" :key="lic">{{ lic }}</div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import RolesAPI from '@/api/RolesAPI';

export default {
  name: 'user-roles-table-row',

  props: {
    userID: Number, 
    Role: Object,
    userRole: Object,
    Selected: Boolean
  },

  data() {
    return {
      licExpanded: false,
      cohortExpanded: false,
      timeout: () => {}
    }
  },

  created() {
    if (this.userRole){
      for (let lic of this.userRole.licenses) {
        lic = { serialKey: lic };
      }
    }
  }, 

  methods: {
    addLicense() {
      this.userRole.licenses.push({ serialKey: '' });
    },

    async removeLicense(license) {
      this.userRole.licenses = this.userRole.licenses.filter(lic => lic.serialKey !== license.serialKey);
      await RolesAPI.assignRole(this.userID, this.userRole);
    },

    inputSerialKey() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.save, 1000);
    },

    async save() {
      try {
        if (this.userRole) {
          await RolesAPI.assignRole(this.userID, this.userRole);
        }
      } catch (e) {
        console.error(e);
        alert('Error while trying to save');
      }
    },

    async toggleIsGlobal() {
      this.userRole.isGlobal = !this.userRole.isGlobal;
      await this.save();
    }
  }
}
</script>