export default class Variation {
  constructor(id="" ,name="" ,description="") {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  static import(json) {
    return new Variation(json.id, json.name, json.description);
  }

  export() {
    let data = {};
    data.id = this.id;
    data.name = this.name;
    data.description = this.description;
    return data;
  }
}
