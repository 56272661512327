<template>
  <SearchDropdown
    :selectedItems="selectedNodes"
    :placeholder="placeholder"
    :DisplayFunction="displayFunction"
    :tagDisplayFunction="tagDisplayFunction"
    :InitialValue="initialValue"
    :ObjKey="'id'"
    :width="width"
    :browseIcon="true"
		:pages="pages"
    :externalResults="items"
		:disabled="courseID == null"
    :dropUp="dropUp"
		disabledMessage="Node selection is disabled"
    @selected="item => selectNode(item)"
    @remove="item => removeNode(item)"
    @search="(txt, pg) => getNodeList(txt, pg)"
    @none="selectNode(null)"
    ref="search"
  />
</template>


<script>
import CourseAdminAPI from '@/api/CourseAdminAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js';
import SearchDropdown from '@/components/utilities/SearchDropdown.vue';

export default {
  name: 'NodeSearchDropdown',

  components: {
    SearchDropdown,
  },

  props: {

		courseID: {
			type: Number,
			default: null
		},

    nodes: {
      type: Array,
      default: () => []
    },

    width: {
      type: String,
      default: '50%'
    },

    placeholder: {
      type: String,
      default: 'Select Nodes'
    },

    initialValue: {
      type: String,
      default: null
    },

		dropUp: {
			type: Boolean,
			default: false
		},

  },


  model: {
    prop: 'nodes',
    event: 'update'
  },


  data() {
    return {
      selectedNodes: [],
      items: [],
      pages: 1,
    }
  },
  

  watch: {
    nodes() { this.initialize() },
  },


  created() { this.initialize() },


  methods: {

    initialize() {
      this.selectedNodes = [ ...this.nodes ]
      // this.$emit( 'update', this.selectedNodes )
    },

    selectNode( item ) {
      if( !item ) return
      for( var n of this.selectedNodes ) if( n.id == item.id ) return

      this.selectedNodes.push( item )
      this.$emit( 'update', this.selectedNodes )
    },

    removeNode( item ) {
      this.selectedNodes = this.selectedNodes.filter( n => n.id !== item.id )
      const outputNodes = [ ...this.selectedNodes ]
      this.$emit( 'update', outputNodes )
    },


    clear() { this.$refs.search.clear() },

    displayFunction(obj) {
      if(!obj) return '';
      return `${obj.id}: ${obj.name} (${obj.type})`;
    },
    tagDisplayFunction(obj) {
      return obj ? obj.name : ''
    },

    async getNodeList(searchTerm, page = 1) {
			const req = new PaginatedRequest('name', true, page, 25, searchTerm);
			req.courseID = this.courseID;
      const pr = await CourseAdminAPI.getMatchingNodesMeta( req );

      this.items = []
      if( !pr || !pr.data ) return
      this.pages = pr.pages
      for( let item of pr.data ) this.items.push( item )
    },
  }


}
</script>
