import Store from "@/store";
import moment from 'moment';
import OrderedSet from "@/libraries/OrderedSet";
import User from '@/features/Users/User.js'
import TCLinkSet from './TCLinkSet.js';

export default class License {

	originOrderIDs = []
	renewOrderIDs = []

	constructor() {
		this.serialKey = "";
		this.masterID = null;
		this.studentID = null;
		this.customerID = null;
		this.affiliateID = null;
		this.affiliateName = null;
		this.assignedByRoleNames = null;
		this.assignedByCohortNames = null;
		this.courseID = "";
		this.orderID = null; 				// The order which created the license (if any)
		this.lineItemIndex = null;	// the lineItem within the order (if any)
		this.purchaseDate = moment();
		this.expirationDate = moment().add(1, 'year');
		this.studyStartDate = null;
		this.teacherChatEnabled = false;
		this.trackingOn = true;
		this.courseVariations = new OrderedSet();
		this.masterUser = null;
		this.studentUser = null;
		this.masterTimeInProgram = 0;
		this.studentTimeInProgram = 0;
		this.studentStatistics = null;
		this.sku = null;
		this.pricingOptionID = null;
		this.upgradeSKUs = [];
		this.teacherCohortLinks = new TCLinkSet();

		// Archive
		this.dateArchived = null;
		this.archiveBoxID = null;
	}

	get primaryUser() { return this.studentUser || this.masterUser; }
	get primaryUserID() { return this.studentID || this.masterID }
	get licenseKey() { return this.serialKey }
	get isArchived() { return this.archiveBoxID !== null && this.dateArchived <= Date.now() }





	isSame(license) {
		// check primitives
		if (!(
			this.serialKey == license.serialKey &&
			this.masterID == license.masterID &&
			this.studentID == license.studentID &&
			this.courseID == license.courseID &&
			this.trackingOn == license.trackingOn &&
			this.teacherChatEnabled == license.teacherChatEnabled &&
			this.sku === license.sku &&
			this.pricingOptionID === license.pricingOptionID &&
			this.customerID == license.customerID &&
			this.orderID == license.orderID &&
			this.dateArchived == license.dateArchived &&
			this.archiveBoxID == license.archiveBoxID
		)) {
			return false;
		}

		if( (this.affiliateID || license.affiliateID) && this.affiliateID !== license.affiliateID ) return false

		// check dates
		if (!(
			(this.expirationDate && license.expirationDate &&
				this.expirationDate.isSame(license.expirationDate)) ||
			(!this.expirationDate && !license.expirationDate)) &&
			((this.purchaseDate && license.purchaseDate &&
				this.purchaseDate.isSame(license.purchaseDate)) ||
			(!this.purchaseDate && !license.purchaseDate)
		)){
			return false;
		}

		// check arrays
		if (!this.upgradeSKUs.every(elem => license.upgradeSKUs.indexOf(elem) > -1)) {
			return false;
		}
		if (!license.upgradeSKUs.every(elem => this.upgradeSKUs.indexOf(elem) > -1)) {
			return false;
		}

		return true;
	}

	static import(json) {
		let license = new License();

		license.serialKey = json.serial_key || json.licenseKey;
		license.masterID = json.master_user_id || json.masterID;
		license.studentID = json.slave_user_id || json.studentID;
		license.customerID = json.customerID || null;
		license.affiliateID = json.affiliateID || null;
		license.affiliateName = json.affiliateName || null;

		license.assignedByRoleNames = json.assignedByRoleNames !== undefined ? json.assignedByRoleNames : null;
		license.assignedByCohortNames = json.assignedByCohortNames !== undefined ? json.assignedByCohortNames : null;

		license.courseID = json.course_id || json.courseID;
		license.trackingOn = json.tracking_on || json.trackingOn;
		license.studentStatistics = json.studentPerformanceStatistics;


		license.expirationDate = null;
		const expirationDate = json.expirationDate || json.expiration_date;
		if (expirationDate) {
			if (parseInt(expirationDate)) {
				license.expirationDate = moment.unix(expirationDate);
			} else if (typeof expirationDate === 'string') {
				license.expirationDate = moment('YYYY-MM-DDThh:mm:ssZ', expirationDate);
			}
		}

		license.purchaseDate = null;
		const purchaseDate = json.purchaseDate || json.purchase_date;
		if (purchaseDate !== null && purchaseDate !== undefined) {
			if (parseInt(purchaseDate)) {
				license.purchaseDate = moment.unix(purchaseDate);
			} else if (typeof purchaseDate === 'string') {
				license.purchaseDate = moment('YYYY-MM-DDThh:mm:ssZ', purchaseDate);
			}
		}

		const startDate = json.studyStartDate
		if( startDate !== null && startDate !== undefined ) {
			if( parseInt(startDate) ) {
				license.studyStartDate = new Date( startDate * 1000 );

			} else if( typeof startDate === 'string' ) {
				license.studyStartDate = this._dateFromString( startDate );
			}
		}

		const testDate = json.testDate
		if( testDate !== null && testDate !== undefined ) {
			if( parseInt(testDate) ) {
				license.testDate = new Date( testDate * 1000 );

			} else if( typeof testDate === 'string' ) {
				license.testDate = this._dateFromString( testDate );
			}
		}

		if (json.teacher_chat_enabled) license.teacherChatEnabled = json.teacher_chat_enabled;
		else if (json.teacherChatEnabled) license.teacherChatEnabled = json.teacherChatEnabled;
		else license.teacherChatEnabled = false;

		license.courseVariations = [];
		let course = Store.state.Courses.findByAttribute("courseID", license.courseID);

		// get variations from course that correspond with ids in json.course_variations
		let json_course_vars = json.course_variations || json.courseVariations;
		if (json_course_vars && course) {
			for (let v of json_course_vars) {
				for (let w of course.courseVariations) {
					if (v == w.id) license.courseVariations.push(w);
				}
			}
		}
		if (json.master_user || json.masterUser) {
			license.masterUser = User.import(json.master_user || json.masterUser);
		}
		if (json.student_user || json.studentUser) {
			license.studentUser = User.import(json.student_user || json.studentUser);
		}

		if (json.masterTimeInProgram) license.masterTimeInProgram = json.masterTimeInProgram
		if (json.studentTimeInProgram) license.studentTimeInProgram = json.studentTimeInProgram

		if (json.teacherCohortLinks) {
			json.teacherCohortLinks.forEach(link => license.teacherCohortLinks.createLink(link));
		}

		if (json.sku) license.sku = json.sku;
		if (json.pricingOptionID) license.pricingOptionID = json.pricingOptionID;
		if (json.upgradeSKUs) license.upgradeSKUs = json.upgradeSKUs;
		if (json.orderID) license.orderID = json.orderID;
		if (json.lineItemIndex !== undefined) license.lineItemIndex = json.lineItemIndex;

		// Order history
		if (json.originOrderIDs) license.originOrderIDs = json.originOrderIDs
		if (json.renewOrderIDs)  license.renewOrderIDs  = json.renewOrderIDs

		// Archives
		if( Number.isInteger(json.dateArchived) ) license.dateArchived = new Date( json.dateArchived * 1000 )
		if( Number.isInteger(json.archiveBoxID) ) license.archiveBoxID = json.archiveBoxID

		return license;
	}



	export() {
		let license = {};
		license.serial_key = this.serialKey;

		license.master_user_id = parseInt(this.masterID) || null;
		license.slave_user_id = parseInt(this.studentID) || null;
		license.customerID = parseInt(this.customerID) || null;
		license.affiliateID = this.affiliateID || null;
		license.teacher_chat_enabled = this.teacherChatEnabled;
		license.sku = this.sku;
		license.pricingOptionID = parseInt(this.pricingOptionID) || null;
		license.upgradeSKUs = this.upgradeSKUs;
		license.orderID = this.orderID;
		license.lineItemIndex = this.lineItemIndex;

		license.course_id = parseInt(this.courseID);
		if (this.expirationDate) {
			license.expiration_date = parseInt(this.expirationDate.format('X')) || null;
		} else license.expiration_date = null;
		if (this.purchaseDate) {
			license.purchase_date = parseInt(this.purchaseDate.format('X'));
		} else license.purchaseDate = null;

		license.tracking_on = this.trackingOn;

		license.course_variations = [];
		for (let v of this.courseVariations) {
			license.course_variations.push(v.id);
		}

		license.teacherCohortLinks = this.teacherCohortLinks.export();

		// Archives
		license.dateArchived = this.dateArchived ? Math.round( this.dateArchived.getTime() / 1000 ) : null
		license.archiveBoxID = this.archiveBoxID

		return license;
	}



	_dateFromString = function(str) {
		const year = parseInt(str.slice(0, 4));
		const month = parseInt(str.slice(5, 7)) - 1;
		const day = parseInt(str.slice(8, 10));
		const hours = parseInt(str.slice(11, 13)) || 0;
		const minutes = parseInt(str.slice(14, 16)) || 0;
		const seconds = parseInt(str.slice(17, 19)) || 0;
		const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds));
		if (isNaN(date.getTime())) throw new Error('Error creating Date object from string: resulting date is invalid');
		return date;
	}
}
