<template>
<section class="cartItem" :class="{'attention': !CartItem.pricingOptionID}">
	<b>{{CartItem.product.name}} ({{CartItem.product.sku}})</b>
	<a class="link" style="float:right;" @click="$emit('removeFromCart')">[remove]</a>
	<br>
	Pricing Option: <select v-model="CartItem.pricingOptionID" @change="updatePricingOption">
		<option v-for="po in CartItem.product.pricingOptions" :value="po.id" :key="po.id">
			{{po.name}} - ${{po.initialPrice}} &nbsp;
			<span v-if="po.isSubscription">[Subscription: auto-pay every {{po.intervalString}}]</span>
		</option>
	</select>

	<br>

	Qty: <input type="number" min="1" v-model="quantity" style="width: 5em;" />
	<span v-if="CartItem.pricingOptionID">
		@ $<input type="number" step="1" min="0.00" v-model="CartItem.initialPrice" style="width: 6em;" :disabled="!CartItem.getPricingOption().perUnitPricing" /> each
		= $<input type="number" step="1" min="0.00" :value="CartItem.totalInitialCost" @input="ev => updateTotalInitialCost( ev )" style="width: 6em;" :disabled="CartItem.getPricingOption().perUnitPricing" />
	</span>

	<div v-if="CartItem.pricingOptionID && CartItem.isSubscription">
		Subscription Price: {{CartItem.quantity}}
		@ $<input type="number" step="0.01" min="0.00" v-model="CartItem.subscriptionPrice" style="width: 6em;" :disabled="!CartItem.getPricingOption(CartItem.pricingOptionID).perUnitPricing" /> each
		= $<input type="number" step="0.01" min="0.00" :value="CartItem.totalRecurringCost" @input="ev => updateTotalRecurringCost( ev )" style="width: 6em;" :disabled="CartItem.getPricingOption(CartItem.pricingOptionID).perUnitPricing" />
		/ {{CartItem.intervalString}}
	</div>

	<div class="itemSubtotal">${{ CartItem.getTotalInitialCost().toLocaleString(undefined, {minimumFractionDigits:2}) }}
		<span v-if="CartItem.isSubscription">today + {{ Math.moneyFormat(CartItem.totalRecurringCost) }} / {{CartItem.intervalString}}</span>
	</div>
</section>
</template>



<script>
export default {
	name: 'CartItemEditor',



	props: {
		CartItem: Object,
	},



	data() {
		return {
			priv_quantity: null,
			pricingOption: null,

			totalInitialTimeoutID: null,
			totalRecurringTimeoutID: null,
		}
	},



	created() {
		this.initialize()
	},



	computed: {
		quantity: {
			get() { return this.$data.priv_quantity },
			set(value) { this.$data.priv_quantity = Math.round(value) }
		},
	},



	watch: {
		quantity(value) {
			this.CartItem.quantity = value
		},
	},



	methods: {
		initialize() {
			this.pricingOption = this.CartItem.getPricingOption()
			this.quantity = this.pricingOption ? this.pricingOption.defaultQuantity : this.CartItem.quantity
		},

		updatePricingOption() {
			this.initialize()
		},

		updateTotalInitialCost( event ) {
			const value = parseInt( event.target.value )
			if( this.totalInitialTimeoutID ) clearTimeout( this.totalInitialTimeoutID )
			this.totalInitialTimeoutID = setTimeout( () => { this.CartItem.totalInitialCost = value }, 750 )
		},


		updateTotalRecurringCost( event ) {
			const value = parseInt( event.target.value )
			if( this.totalRecurringTimeoutID ) clearTimeout( this.totalRecurringTimeoutID )
			this.totalRecurringTimeoutID = setTimeout( () => { this.CartItem.totalRecurringCost = value }, 750 )
		}
	}
}
</script>



<style scoped>
.itemSubtotal {
	margin-top: 1em;
	font-weight: bold;
}

.cartItem {
	padding: 1em;
	margin: 1em 0;
	border: 1px solid #ccc;
}
.cartItem:hover {
	background: #fafafa;
}
.cartItem.attention { background-color: #fdd; }
</style>
