<template>
  <div>

    <div class="my-1 flex-row flex-justify-center flex-align-center">
      <div class="link mr-1" @click="$emit('back')">&lt; back</div>
      <MonthSelector :month="selectedMonth" @month="val => selectedMonth = val" :year="selectedYear" @year="val => selectedYear = val" />
      <div>
        <AffiliateSearchDropdown @update="aff => selectAffiliate(aff)" :initialValue="'Affiliate #'+selectedAffiliateID" width="250px" placeholder="Filter by Affiliate" ref="affiliateSearch" />
        <AffiliateDetailsModal v-if="selectedAffiliateID" :affiliateID="selectedAffiliateID" />
      </div>
    </div>

    <OrdersDashboard
      :title="`Orders ${selectedMonth} / ${selectedYear}`"
      :startMonth='selectedMonth'
      :startYear='selectedYear'
      :endMonth="selectedMonth == 12 ? 1 : selectedMonth + 1"
      :endYear="selectedMonth == 12 ? selectedYear + 1: selectedYear"
      :affiliateID='selectedAffiliateID'
      @gotoFindOrders="orderState => $emit('gotoFindOrders', orderState)"
      class="mb-1"
    />

    <!-- <AggregateOrdersList
      v-if='aggregateOrders.length'
      :AggregateOrders='aggregateOrders'
      @edit='aggOrder => openDayDetails(aggOrder.aggregateValue, selectedMonth, selectedYear)'
    />
    <p v-else>No Results</p> -->

    <OrdersSummaryByTime
      :aggregateBy="'day'"
      :startMonth="selectedMonth"
      :startYear="selectedYear"
      :endMonth="selectedMonth == 12 ? 1 : selectedMonth + 1"
      :endYear="selectedMonth == 12 ? selectedYear + 1: selectedYear"
      :affiliateID="selectedAffiliateID"
      @gotoDay="(year, month, day) => openDayDetails(day, month, year)"
    />

    <OrdersSummaryByProduct :month="selectedMonth" :year="selectedYear" :affiliateID='selectedAffiliateID' class="mt-2" />


    <StretchModal :clickToClose='false' ref="modal">
      <template #header>
        <span v-if="selectedAffiliateID">Affiliate #{{selectedAffiliateID}} &mdash; </span>
        Orders on {{ selectedDate }}
      </template>
      <OrdersList :start="start" :end="end" :affiliateID="selectedAffiliateID" :RenderLinks='true' />
    </StretchModal>


  </div>
</template>


<script>
import MonthSelector from './MonthSelector.vue'
import AffiliateDetailsModal from '@/features/SalesManagement/Affiliates/AffiliateDetailsModal.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
// import AggregateOrdersList from './AggregateOrdersList.vue'
import OrdersDashboard from './OrdersDashboard.vue'
import OrdersList from './OrdersList.vue'
import OrdersSummaryByTime from './OrdersSummaryByTime.vue'
import OrdersSummaryByProduct from './OrdersSummaryByProduct.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

import OrdersAPI from '@/api/OrdersAPI.js'

export default {
  name: 'AggregateOrdersPane',

  components: {
    MonthSelector,
    AffiliateDetailsModal,
    AffiliateSearchDropdown,
    // AggregateOrdersList,
    OrdersDashboard,
    OrdersList,
    OrdersSummaryByTime,
    OrdersSummaryByProduct,
    StretchModal,
  },


  props: {
    month: {
      type: Number,
      default: null
    },

    year: {
      type: Number,
      default: null
    },

    affiliateID: {
      type: Number,
      default: null
    },
  },



  data() {
    return {
      selectedMonth: null,
      selectedYear: null,

      affiliates: [],
      selectedAffiliateID: null,

      aggregateOrders: [],
      selectedDate: '',
      start: null,
      end: null,
    }
  },



  computed: {
    selectedMonthYear() {
      return this.selectedMonth + ' / ' + this.selectedYear
    }
  },



  watch: {
    async selectedMonthYear() {
      await this.fetchAggregateOrders()
    },

    async selectedAffiliateID(value) {
      console.debug("AffID: ", value);
      await this.fetchAggregateOrders()
    },

    affiliateID(value) { console.debug("AffID: ", value); this.selectedAffiliateID = value },
  },



  async created() {

    if(this.month !== null) this.selectedMonth = this.month
    if(this.year !== null) this.selectedYear = this.year
    if(this.affiliateID !== null) this.selectedAffiliateID = this.affiliateID
  },



  methods: {

    async fetchAggregateOrders() {
      try {
        this.aggregateOrders = await OrdersAPI.getAggregateOrders(this.selectedYear, this.selectedMonth, this.selectedAffiliateID);
      } catch (error) {
        this.aggregateOrders = [];
      }
    },


    async openDayDetails(day, month, year) {
      console.debug(day, month, year)
      let startDate = new Date(year, month -1, day)
      let endDate = new Date(startDate)
      endDate.setDate(startDate.getDate() + 1)

      this.start = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
      this.end = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`

      this.selectedDate = startDate.toLocaleDateString()
      this.$refs.modal.open()
    },


    selectAffiliate( aff ) {
      this.selectedAffiliateID = aff ? aff.id : null
    },
  }

}
</script>
