import API from "./API.js";
import {base_url} from "@/Config"
import PaginatedResponse from "./PaginatedResponse.js";


class CourseAdminAPI extends API {

	static controllerName = "CourseAdminAPI";


	static async getMatchingNodesMeta( req ) {
    const res = await this.newPost( 'getMatchingNodesMeta', [], req )
		return PaginatedResponse.import( res.data )
  }

	static async getNode( nodeID, courseID, versionID = null ) {
		var url = base_url + `/CourseAPI/getNode/${nodeID}/${courseID}/${versionID ? versionID : ''}`;
    let res = await this.get( url )
    return res
  }

	static async getMtocDecompressionRules() {
		var url = base_url + `/CourseAPI/getMtocDecompressionRules`;
    let res = await this.get( url )
    return res
  }



  static async generateMTOCGraph( courseID, courseVersionID ) {
    return this.newGet( 'generateMTOCGraph', [ courseID, courseVersionID ] )
  }

}

export default CourseAdminAPI;