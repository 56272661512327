export default class Feature {
  constructor(id=null, name='', description='') {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  isSame(feature) {
    return feature.id === this.id &&
            feature.name === this.name &&
            feature.description === this.description;
  }

  export() {
    const data = {
      name: this.name,
      description: this.description
    }
    if (this.id) data.id = this.id;
    return data;
  }

  static import(json) {
    return new Feature(json.id, json.name, json.description);
  }
}