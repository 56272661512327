import API from "./API";
import {base_url} from './../Config';
import Role from "@/models/Role";
import Feature from "@/models/Feature";

export default class RolesAPI extends API {
  
  static controllerName = 'SiteAdmin/RolesAPI'


  static async getRoles(userID = null) {
    let url = `${base_url}/SiteAdmin/RolesAPI/getRoles`;
    if (userID) url += `/${userID}`;
    const res = await super.get(url);
    if (!res) throw new Error("Could not get roles from server. Changes will not be saved.");

    const roles = [];
    for (let r of res) {
      roles.push(Role.import(r));
    }

    return roles;
  }


  static async getRolesFromUsersList(users = []) {
    const url = `${base_url}/SiteAdmin/RolesAPI/getRolesFromUsersList`;
    return await super.add(url, users);
  }


  static async searchRoles( req ) {
    return await this.newPost( 'searchRoles', [], req )
  }


  static async getRolesByID( ids = [] ) {
    return await this.newPost( 'getRolesByID', [], ids )
  }


  static async addRole(role) {

    const url = `${base_url}/SiteAdmin/RolesAPI/addRole`;
    const res = await super.add(url, role);

    return Role.import(res);
  }


  static async editRole(role) {

    if (!role.id) throw new Error("Cannot edit role: invalid ID.");

    const url = `${base_url}/SiteAdmin/RolesAPI/editRole/${role.id}`;
    const res = await super.edit(url, role);
    if (!res) throw new Error("Error editing role.");
    return res;
  }


  static async deleteRole(roleID) {

    if (!roleID) throw new Error("Cannot delete role: invalid ID.");

    const url = `${base_url}/SiteAdmin/RolesAPI/deleteRole/${roleID}`;
    return await super.get(url);
  }


  static async assignRole(userID, role) {

    if (!userID) throw new Error("Cannot assign role: invalid user ID");
    if (!role) throw new Error("Cannot assign role: empty role body");
    if (!role.id) throw new Error("Cannot assign role: invalid role ID");

    const url = `${base_url}/SiteAdmin/RolesAPI/assignRole/${userID}/${role.id}`;
    return await super.edit(url, role.export());
  }


  static async unassignRole(userID, roleID) {

    if (!userID) throw new Error("Cannot unassign role: invalid user ID");
    if (!roleID) throw new Error("Cannot unassign role: invalid role ID");

    const url = `${base_url}/SiteAdmin/RolesAPI/unassignRole/${userID}/${roleID}`;
    return await super.add(url);
  }


  static async getFeatures(roleID = null, sortOptions = null) {
    let url = `${base_url}/SiteAdmin/RolesAPI/getFeatures`;
    if (roleID) url += `/${roleID}`;
    const res = await this.add(url, JSON.stringify(sortOptions));
    if (!res) throw new Error("Could not get features from server. Changes will not be saved.");

    const features = [];
    for (let f of res) {
      features.push(Feature.import(f));
    }

    return features;
  }


  static async addFeature(feature) {

    const url = `${base_url}/SiteAdmin/RolesAPI/addFeature`;
    const res = await super.add(url, feature);
    return Feature.import(res);
  }


  static async editFeature(feature) {
    if (!feature.id) throw new Error("Cannot edit feature: invalid ID");

    const url = `${base_url}/SiteAdmin/RolesAPI/editFeature/${feature.id}`;
    const res = super.edit(url, feature);
    if (!res) throw new Error("Error editing feature.");
    return res;
  }


  static async deleteFeature(featureID) {

    if (!featureID) throw new Error("Cannot delete feature: invalid ID.");

    const url = `${base_url}/SiteAdmin/RolesAPI/deleteFeature/${featureID}`;
    return await super.get(url);
  }


  static async assignFeature(roleID, featureID) {

    if (!roleID) throw new Error("Cannot assign feature: invalid role ID");
    if (!featureID) throw new Error("Cannot assign feature: invalid feature ID");

    const url = `${base_url}/SiteAdmin/RolesAPI/assignFeature/${roleID}/${featureID}`;
    return await super.add(url);
  }


  static async unassignFeature(roleID, featureID) {

    if (!roleID) throw new Error("Cannot unassign feature: invalid role ID");
    if (!featureID) throw new Error("Cannot unassign feature: invalid feature ID");

    const url = `${base_url}/SiteAdmin/RolesAPI/unassignFeature/${roleID}/${featureID}`;
    return await super.add(url);
  }
}