import ReportTemplate from './ReportTemplate.js'



class TableReportTemplate extends ReportTemplate {

	// Override
	type = 'table'

	// New properties
	name = null
	sections = []
	rowIDs = []
	rowLabels = []
	rowType = null    // 'user' | 'license' | 'cohort'
	title = null
	subtitle = null


}

export default TableReportTemplate