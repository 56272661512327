<template>
<section :class="{ 'mr-4' : singular && !editMode }">

	<div v-for="(item, index) in section" :key="item.id" class="line flex flex-row flex-justify-between flex-align-center" :class="{ 'editLine' : editMode }">
		<div class="flex-row flex-align-center w-100 flex-gap-05">
			<span v-if="iconClass" class="icon" :class="iconClass" />
			<PocField :item="item" :editKeys="editKeys" :editMode="editMode" :singleLine="isName" :labelOptions="labelOptions" :class="{ 'nameHeader': isName && !editMode }" />
			<div v-if="actionButton && !editMode" class="pillButton" @click="$emit( 'action', item.id )">{{ actionButton }}</div>
		</div>
		<div><span v-if="editMode && !singular" class="delete-button icon-bin2" @click="item.id ? confirmDelete(index) : deleteItem( index )"></span></div>
	</div>


	<a v-if="editMode && !singular" @click.prevent="addItem()" class="add-button ml-2">Add {{ itemName }}</a>


	<ConfirmDialog ref="deleteDialog" confirm="Delete" @confirm="deleteItem()">
		<div style="font-size: 2em;">Really <b class="color-red">DELETE</b> this {{ editKeys[0] }}?</div>
		<div>It will be IMMEDIATELY deleted</div>
	</ConfirmDialog>

</section>
</template>

<script>
import PocField from './PocField.vue'
import ConfirmDialog from "@/components/utilities/ConfirmDialog.vue"

import PocEmail from './PocEmail.js'
import PocPhone from './PocPhone.js'
import PocAddress from './PocAddress.js'

export default {
	name: "PocSection",

	components: {
		PocField,
		ConfirmDialog,
	},



	props: {
		section: Array,
		pocID: Number,
		itemName: {
			type: String,
			default: ""
		},
		editKeys: Array,
		singular: {
			type: Boolean,
			default: false
		}, 
		isName: {
			type: Boolean,
			default: false
		},
		labelOptions: {
			type: Array,
			default: null
		},
		actionButton: {
			type: String,
			default: null
		},
	},



	data() {
		return {
			editMode: false,
			deleteItemIndex: null,
			deleteItemInfo: null,
		}
	},



	computed: {
		iconClass() {
			if( this.itemName === 'email' ) return 'icon-envelop color-gray-60'
			if( this.itemName === 'phone' ) return 'icon-phone color-gray-60'
			if( this.itemName === 'address' ) return 'icon-map color-gray-60'
			return null
		}
	},



	methods: {

		addItem() {
			this.newItemSaved = false;
			let newItem = this.itemName === 'email' ? new PocEmail() : this.itemName === 'phone' ? new PocPhone() : this.itemName === 'address' ? new PocAddress() : {}
			this.editKeys.forEach((key) => {
				newItem[key] = ""
			});
			newItem["pocID"] = this.pocID;
			this.section.push(newItem);
		},

		confirmDelete( index ) {
			// todo: only pop up if item isn't completely empty. maybe make isempty function and also only save items that aren't empty
			this.deleteItemIndex = index;
			this.deleteItemInfo = this.section[this.deleteItemIndex][this.editKeys[0]];
			this.$refs.deleteDialog.open()
		},

		deleteItem( deleteIndex = null ) {
			const index = deleteIndex !== null ? deleteIndex : this.deleteItemIndex
			const id = this.section[ index ].id

			if( id ) this.$emit( "delete", id )

			this.section.splice( index, 1 )
			this.deleteItemIndex = null
			this.deleteItemInfo = null
		},

	}

}
</script>

<style scoped>
.section-title {
	font-weight: bold;
	margin-top: 5px;
}

.add-button, .save-button {
	color: blue;
	/* margin-top: 2px;
	margin-bottom: 8px;
	margin-left: 3px; */
	font-size: 10pt;
}


.add-button:hover, .save-button:hover, .section-title:hover {
	cursor: pointer;
	text-decoration: underline;
}

.nameHeader {
	font-weight: bold;
	display: flex;
	flex-direction: row;
	gap: 0.2em;
}

.line { padding: 0 0.5em; border-radius: 0.5em; }
.editLine { padding: 0.5em; transition: background-color 0.25s; }
.editLine:hover { background-color: var(--pp10-gray-90); }
.editLine:hover .icon { color: var(--pp10-green-30); }

</style>