import Exim from '@/models/Exim.js'


class File extends Exim {

  fileID = null
  storedFileName = null
  fileName = null
  mimeType = null
  dateCreated = null
  fileData = null


  get shortname() {
    if(this.fileName.length < 30) return this.fileName
    return this.fileName.slice(0, 27) + '...'
  }


  get mimeIconClass() {
    if(this.mimeType.includes('image')) return 'icon-file-picture'
    if(this.mimeType.includes('video')) return 'icon-file-video'
    if(this.mimeType.includes('audio')) return 'icon-file-music'
    if(this.mimeType.includes('pdf')) return 'icon-file-pdf'
    else return 'icon-file-text2'
  }


  static import( obj ) {
    return super.import( obj, [], [ 'dateCreated' ] )
  }

}

export default File
