import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import InvoiceViewer from '@/features/orders/InvoiceViewer.vue';

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/invoice/:orderID',
      name: 'displayinvoice',
      component: InvoiceViewer,
      props: true
    }
  ]
})
