<template>
<section class="MetricCard" :class="{'healthy': metric.health, 'alarm' : !metric.health}">
  <div><strong>{{metric.title}}</strong></div>
  <ProgressBar :pct="eventPct" :label="datapoint.eventCount + ' of ' + (metric.maxEvents ? metric.maxEvents : '&infin;')" type="success" />
  <ProgressBar :pct="errorPct" :label="datapoint.errorCount + ' of ' + metric.maxErrors" type="error" />
  <div style="display:inline-block">
    <span v-if="!metric.health && allowAlarm" @click="silenceAlarm()" class="icon-volume-high" />
    <span v-if="!metric.health && !allowAlarm" @click="allowAlarm = true" class="icon-volume-mute2" />
  </div>
</section>
</template>

<script>
import ProgressBar from '@/components/utilities/ProgressBar.vue';
import {base_url} from '@/Config.js';

export default {
  name: 'MetricCard',

  components: {
    ProgressBar,
  },

  props: {
    metric: Object,
  },

  data() {
    return {
      alarmAudio: null,
      alarmPlaying: false,
      allowAlarm: true,
    }
  },

  beforeDestroy() {
    this.alarmAudio = null;
  },

  computed: {

    datapoint() {
      return this.metric.datapoints[0];
    },

    eventPct() {
      let minEvents = this.metric.minEvents ? this.metric.minEvents : 0;
      let maxEvents = this.metric.maxEvents ? this.metric.maxEvents : 0;

      let scale = maxEvents - minEvents;
      let pct = 0;
      // max=null, min=value
      if(scale < 0) pct = 0;

      // max & min are null or 0
      else if(scale == 0) pct = this.datapoint.eventCount ? 1 : 0;
      else if(scale > 0) (this.datapoint.eventCount - minEvents) / maxEvents;

      if(pct == 0) pct = 0.01;
      if(pct > 1) pct = 1;
      return pct;
    },
    errorPct() {
      let maxErrors = this.metric.maxErrors ? this.metric.maxErrors : 0;
      let pct =  maxErrors ? (this.datapoint.errorCount ? this.datapoint.errorCount / maxErrors : 0) : this.datapoint.errorCount ? 1 : 0;
      if(pct == 0) pct = 0.01;
      if(pct > 1) pct = 1;
      return pct;
    },
  },

  watch: {
    datapoint() {
      var err = false;
      if(this.metric.minEvents !== null && this.datapoint.eventCount < this.metric.minEvents) err = true;
      if(this.metric.maxEvents !== null && this.datapoint.eventCount > this.metric.maxEvents) err = true;
      if(this.metric.maxErrors !== null && this.datapoint.errorCount > this.metric.maxErrors) err = true;

      this.metric.health = !err;
      if(!err) return;

      if(!this.alarmAudio) this.alarmAudio = new Audio( base_url + '/webAssets/content/site_wide_media/audio/alarm.mp3' );

      // Start the alarm!
      if(this.allowAlarm && !this.alarmPlaying) {
        this.alarmAudio.play();
        this.alarmPlaying = true;

        // Loop the alarm!
        this.alarmAudio.addEventListener('ended', () => {
          if(!this.metric.health && this.allowAlarm && this.alarmAudio) {
            this.alarmAudio.play();
            this.alarmPlaying = true;
          }
        }); // loop
      } // start


    }
  },

  methods: {
    silenceAlarm() {
      this.allowAlarm = false;
      this.alarmPlaying = false;
    }
  }

}
</script>

<style scoped>
.MetricCard {
  display: inline-block;
  margin: 0.5em;
  padding: 0.5em;
  width: 250px;
  /* min-height: 10em; */
  vertical-align: top;

}
.MetricCard.healthy {
  border-top: 3px solid green;
}
.MetricCard.alarm {
  border-top: 3px solid red;
  background: #fee;
}
</style>
