import API from '@/api/API.js'
import CourseAPI from "@/api/CourseAPI.js"
import RolesAPI from './api/RolesAPI.js'
import ProductsAPI from './api/ProductsAPI.js'



export const preloadStore = async ( store ) => {
    
  // Check for logged-in
  try {
    await API.login( {} )
  } catch( e ) { return }
  
  store.state.Courses.objects = await CourseAPI.getAllCourses()
  store.state.Products.objects = await ProductsAPI.getProducts()
  store.state.Roles.objects = await RolesAPI.getRoles()
  store.state.Features.objects = await RolesAPI.getFeatures(null, { sortBy: 'id', ascendingOrder: true })
}