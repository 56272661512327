<template>
  <div>

    <!-- <button class='button' @click='save' :disabled='!changesMade'>Save Changes</button> -->

    <div id="mainTable">
      <table class='table'>

        <tr class='table-row'>
          <th class='table-header'>Selected</th>
          <th class='table-header' @click='ascendingOrder = !ascendingOrder'>
            Feature
            <span v-if='ascendingOrder'>&#9652;</span>
            <span v-else>&#9662;</span>
          </th>
        </tr>

        <tr v-for='(feature, index) in features'
          :key='feature.id'
          class='table-row clickable'
          @click='toggleFeature(feature)'
        >
          <td class='table-data'>
            <input type='checkbox' v-model='included[index]' />
          </td>

          <td class='table-data'>{{ feature.name }}</td>

        </tr>

      </table>
    </div>

    <button class='button right mr-1 mb-1' @click='save' :disabled='!changesMade'>Save Changes</button>

  </div>
</template>

<script>
import OrderedSet from '@/libraries/OrderedSet'
import RolesAPI from '@/api/RolesAPI'

export default {
  name: 'role-features',

  props: {
    Role: Object,
    RoleFeatures: Object
  },

  data() {
    return {
      selectedFeatures: [],
      ascendingOrder: true
    }
  },

  computed: {
    features() { return this.$store.state.Features.objects; },

    changesMade() {
      for (let feature of this.RoleFeatures) {
        if (!this.selectedFeatures.includes(feature, 'id')) return true;
      }
      for (let feature of this.selectedFeatures) {
        if (!this.RoleFeatures.includes(feature, 'id')) return true;
      }
      return false;
    },

    included() {
      let included = [];
      for (let feature of this.features) {
        if (this.selectedFeatures.includes(feature, 'id')) included.push(true);
        else included.push(false);
      }

      return included;
    }
  },

  watch: {
    async ascendingOrder() {
      await this.fetchFeatures();
    }
  },

  async created() {
    await this.fetchFeatures();

    this.selectedFeatures = new OrderedSet();
    for (let f of this.RoleFeatures) {
      this.selectedFeatures.push(f);
    }
  },

  methods: {
    async save() {
      try {
        const add = [], remove = [];
        let res;
        // add features to server
        for (let feature of this.selectedFeatures) {
          if (!this.RoleFeatures.includes(feature)) {
            res = await RolesAPI.assignFeature(this.Role.id, feature.id);
            add.push(feature);
          }
        }
        // remove features from server
        for (let feature of this.RoleFeatures) {
          if (!this.selectedFeatures.includes(feature)) {
            res = await RolesAPI.unassignFeature(this.Role.id, feature.id);
            remove.push(feature);
          }
        }
        this.RoleFeatures = res;
        alert("Successfully saved feature assignments.")
      } catch (e) {
        console.error(e);
        alert("Save failed");
      }
    },

    async fetchFeatures() {
      this.$store.state.Features.objects = await RolesAPI.getFeatures( null, { sortBy: 'name', ascendingOrder: this.ascendingOrder } )
    },

    isSelected(feature) { return this.selectedFeatures.includes(feature); },

    toggleFeature(feature) {
      if (this.selectedFeatures.includes(feature, 'id')) this.selectedFeatures.remove(feature,'id');
      else this.selectedFeatures.push(feature);
    }
  }
}
</script>

<style scoped>
#mainTable {
  max-height: calc(100vh - 8em);
  margin-bottom: 1em;
  overflow: auto;
}
</style>
