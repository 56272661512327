<template>
<section class="w-100" style="width: calc( 100% - 3em ); max-width: 1000px;">
	<div class="flex-row flex-justify-between flex-align-start">
		<div>
			<!-- Name -->
			<h2 class="ma-0">
				<span v-if="!editName" class="name pointer round-05 pa-025" @click="editName = true">{{ template.name }}</span>
				<span v-else><input v-model="template.name" placeholder="Name" />&nbsp;<button class="pillButton bg secondary" @click="editName = false">done</button></span>
			</h2>
			<div class="">Threshold-Based Intervention Report</div>
			<div>ID: {{ template.id }}</div>
		</div>
		<slot name="save" />
	</div>


	<div class="mt-1">
		<div>Timeframe (optional)</div>
		<div class="flex-row flex-gap-1">
			<div class="nowrap">
				<v-date-picker v-if="!timeWindowMinutes" v-model="startTime" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="Start Date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
				<span v-if="!timeWindowMinutes && !startTime"> OR </span>
				<span v-if="!startTime"><input type="number" v-model="timeWindowMinutes" placeholder="TIP window" style="max-width: 10em;" /> mins</span>
			</div>
			<div class="center">-</div>
			<div class="nowrap">
				<v-date-picker v-model="endTime" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="End ('As-of') date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
			</div>
		</div>
	</div>


	<div class="flex-column flex-align-center mt-2">
		<div class="bold center">Ranges:</div>
		
		<section class="mt-1 w-100">
			<ThresholdReportRange v-for="(range, idx) in ranges" :key="rangeNames[idx]" :name="rangeNames[idx]" :range="range" :thresholds="range.thresholds" :dimensions="dimensions" @delete="deleteRange(idx)" @updateName="newName => updateRangeName( newName, idx )" />
		</section>

		<div class="mt-1 center"><button class="pillButton secondary bg" @click="addRange()">+ New Range</button></div>
	</div>

	


	<StretchModal ref="newRange" padding="1em" width="400px" :fullscreen="false">
		<h2 class="mt-0 mb-05">New Range</h2>
		<div><input v-model="newRangeName" ref="newDimName" class="w-100" placeholder="New Range Name" /></div>
		<div v-if="newRangeName" class="right mt-1"><button class="pillButton" @click="saveNewRange()">Save</button></div>
	</StretchModal>

</section>
</template>



<script>
import ThresholdReportRange from './ThresholdReportRange.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

export default {
	name: 'ThresholdReportTemplateEditor',


	components: {
		ThresholdReportRange,
		StretchModal,
	},


	props: {
		template: Object,
		dimensions: Array,
	},


	data() {
		return {
			editName: false,
			
			newRangeName: null,
			ranges: [],
			rangeNames: [],
		}
	},


	computed: {
		timeWindowMinutes: {
			get() { return this.template.timeWindow !== null ? this.template.timeWindow / 60 : null },
			set(value) { this.template.timeWindow = value ? value * 60 : null }
		},
		startTime: {
			get() { return this.template.startTime !== null ? new Date( this.template.startTime * 1000 ) : null },
			set(value) { this.template.startTime = value !== null ? Math.floor( value.getTime()/1000 ) : null }
		},
		endTime: {
			get() { return this.template.endTime !== null ? new Date( this.template.endTime * 1000 ) : null },
			set(value) { this.template.endTime = value !== null ? Math.floor( value.getTime()/1000 ) : null }
		},
	},


	watch: {
		template() { this.initialize() },
	},


	created() {
		this.initialize()
	},



	methods: {
		
		initialize() {
			this.ranges = []
			this.rangeNames = []
			for( let r of this.template.ranges.values() ) this.ranges.push( r )
			for( let r of this.template.ranges.keys() ) this.rangeNames.push( r )
		},

		addRange() {
			this.newRange
			this.$refs.newRange.open()
		},

		saveNewRange() {
			if( !this.newRangeName ) return

			const newRange = this.template.addRange( this.newRangeName )
			this.ranges.push( newRange )
			this.rangeNames.push( this.newRangeName )

			this.newRangeName = null
			this.$refs.newRange.close()
			this.$forceUpdate()
		},

		updateRangeName( newName, idx ) {
			const oldName = this.rangeNames[ idx ]
			this.template.renameRange( newName, oldName )
			this.initialize()
		},

		deleteRange( index ) {
			const name = this.rangeNames[ index ]
			if( !name ) return

			this.template.deleteRange( name )
			this.ranges.splice( index, 1 )
			this.rangeNames.splice( index, 1 )
		}
	}

}
</script>



<style scoped>
.name:hover {
	background-color: var(--ekno-blue-95);
}
</style>