import API from "./API";
import {base_url} from './../Config';

import Course from "@/models/Course";

class CourseAPI extends API {
  
  static controllerName = 'SiteAdmin/CoursesAPI' // CAN ONLY BE USED FOR ADMIN FUNCTIONS!
  
  
  static async getAllCourses() {
    let url = base_url + '/SiteAdmin/CoursesAPI/getAllCourses';
    let res = await super.get(url);
    if (!res) throw new Error("Could not get courses from server. Changes will not be saved.");
    let courses = [];
    for (let c of res) {
      courses.push(Course.import(c));
    }

    return courses;
  }

  static async getCoursesByID( courseIDs = [] ) {
    return await this.newPost( 'getCoursesByID', [], courseIDs )
  }

  static async searchCourses( pageNum, pageSize, searchStr ) {
		return await this.newGet( 'searchCourses', [pageNum, pageSize, searchStr] )
	}

  static async addCourse(course) {

    let url = base_url + '/SiteAdmin/CoursesAPI/addCourse';
    let res = await super.add(url, course.export());

    return Course.import(res);
  }

  static async editCourse(course) {
    if (!course.courseID) {
      throw new Error("Cannot edit course: invalid ID.");
    }

    let url = base_url + '/SiteAdmin/CoursesAPI/editCourse/' + course.courseID;
    let res = await super.edit(url, course.export());
    return Course.import(res);
  }

  static async deleteCourse(course) {

    if (!course.courseID) {
      throw new Error("Cannot delete course: invalid ID.");
    }
    let url = base_url + '/SiteAdmin/CoursesAPI/deleteCourse/' + course.courseID;
    return await super.delete(url);
  }
}

export default CourseAPI;