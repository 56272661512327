<template>
<section style="width: 100%;">
  <p v-if="isTemplate">Chat Block</p>

  <div class='flex-column' style="width: 100%;">
    <div class="flex-row flex-justify-between">
      <input v-if="isTemplate" type="text" v-model="section.name" placeholder="Block Label (admin)" />
      <div v-else>{{ section.name }}<span style="color: #888; font-size: 0.8em;"> - Chat</span></div>

      
      <div v-if="!isTemplate && !isDefault" id="revert" @click="revert"><span class="icon-undo" /> Revert</div>
    </div>
    <div class="my-1"><ToggleButton v-model="section.enabled" :sync="true" /> Enable chat (Tawk.to)</div>
  </div>

</section>
</template>


<script>
import { ToggleButton } from 'vue-js-toggle-button'

export default {
  name: 'LandingPageChatSection',


  components: {
    ToggleButton,
  },


  props: {
    section: Object,

    isTemplate: {
      type: Boolean,
      default: true
    },

    isDefault: {
      type: Boolean,
      default: false
    },

  },


  watch: {
    'section.enabled'() { this.update() },
  },


  methods: {
    update(e) { this.$emit('update'); },
    revert() { this.$emit('revert'); },
  }
}
</script>


<style scoped>
input, textarea { padding: 0.5em !important; }

.default {
  color: #777;
}
#revert {
  cursor: pointer;
}
</style>
