import Exim from '@/models/Exim.js'



class BlastListRow extends Exim {


	rowID = null;
	listID = null;
	userID = null;
	pocID = null;
	
	name = null;
	email = null;
	phone = null;

	dateCreated = null;
	extraColumns = [];


	setExtraColumn( key, value ) {
		const elem = this.extraColumns.find( elem => elem.key === key )
		if( elem ) elem.value = value
		else this.extraColumns.push( { key, value } )
	}


	static import( obj ) {
		return super.import( obj, [], [ 'dateCreated' ] )
	}


	export() {
		return super.export( [], [ 'dateCreated' ] )
	}


}

export default BlastListRow