<template>
<section>

	<div>
		<DiscountRow v-for="item in discountsArray" :key="item.key" :discount="item.value" :selected="selectedDiscount === item.value" @edit="edit( item.value )" @save="save( item.value, item.key )" @cancel="cancel()" @remove="remove( item.key )"  />
	</div>

	<div v-if="newDiscount">
		<DiscountRow :discount="newDiscount" :selected="true" :newRow="true" @save="save( newDiscount )" @cancel="cancel()" />
	</div>

	<div class="right mt-2">
		<button class="pillButton" :class="{ 'disabled' : !enabled }" :disabled="!enabled" @click="add()"><span class="icon-plus" /> Add Discount</button>
	</div>
</section>
</template>



<script>
import Discount from './Discount.js'

import DiscountRow from './DiscountRow.vue'

export default {
  name: 'CouponDiscounts',


	components: {
		DiscountRow,
	},


	props: {
		discounts: Map,

		enabled: {
			type: Boolean,
			default: true
		},
	},


	data() {
		return {
			discountsArray: [],
			selectedDiscount: null,
			newDiscount: null,
		}
	},


	watch: {
		discounts() {
			this.initialize()
		},
	},



	created() { this.initialize() },



	methods: {

		initialize() {
			this.discountsArray = []
			for( var [key, value] of this.discounts ) {
				this.discountsArray.push( { key, value } )
			}
		},


		add() {
			this.selectedDiscount = null
			this.newDiscount = new Discount()
		},


		edit( discount ) {
			this.selectedDiscount = discount
		},


		cancel() {
			this.selectedDiscount = null
			this.newDiscount = null
		},


		save( discount, oldKey = null ) {
			var newKey = `${ discount.sku }-${ discount.pricingOptionID }`
			
			// Discounts Map
			this.discounts.set( newKey, discount )
			if( oldKey && oldKey !== newKey ) this.discounts.delete( oldKey )
			this.selectedDiscount = null

			// Discounts Array
			if( oldKey ) {
				for( var i = 0; i < this.discountsArray.length; i++ ) {
					if( this.discountsArray[i].key !== oldKey ) continue
					this.discountsArray.splice( i, 1, { key: newKey, value: discount } )
				}
			}

			this.selectedDiscount = null
			this.newDiscount = null
			this.$emit('change')
		},


		remove( key ) {

			// Discounts Map
			this.discounts.delete( key )

			// Discounts Array
			for( var i = 0; i < this.discountsArray.length; i++ ) {
				if( this.discountsArray[i].key == key ) this.discountsArray.splice( i, 1 )
			}

			this.$emit('change')
		}

	}

}
</script>



<style scoped>

</style>