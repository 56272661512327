<template>
	<section>
	<StretchModal v-if="commission" :showModal='show' @close='close()' :closeIcon="true" :width="width" :height="height">
		<template #header>Edit Commission {{isAssignment ? 'Assignment' : 'Structure'}}: {{commission.name}}</template>
		<template #default="{ closed }">
			<div id="body">
				<CommissionDetails v-if="commission" :commission="commission" :isAssignment="isAssignment" @close="closed" ref="details" />
			</div>
		</template>
	</StretchModal>

	<ConfirmDialog :show="showConfirmation" :confirm="'Close Anyway'" :cancel="'Go Back'" @confirm="confirmClose()" @cancel="showConfirmation = false">
		<div>Are you sure?  <b>Unsaved changes will be lost!</b></div>
	</ConfirmDialog>
	</section>

</template>



<script>
import CommissionDetails from './CommissionDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
export default {
	name: 'CommissionDetailsModal',

	components: {
		CommissionDetails,
		StretchModal,
		ConfirmDialog,
	},


	props: {
		commission: {
			type: Object,
			default: null
		},
		isAssignment: {
			type: Boolean,
			default: false
		},
		show: {
			type: Boolean,
			default: false
		},
		width: {
			type: String,
			default: 'auto'
		},
		height: {
			type: String,
			default: 'auto'
		},
	},


	data() {
		return {
			showConfirmation: false,
		}
	},


	methods: {
		close() {
			if(this.$refs.details.changesMade) {
				this.showConfirmation = true
				return
			}

			this.$emit('close')
		},

		confirmClose() {
			this.showConfirmation = false
			this.$emit('close')
		},

		cancelClose() {
			this.showConfirmation = false
		},

	}

}
</script>



<style scoped>
#body {
	margin: 0 auto;
}
</style>
