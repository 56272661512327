class CustomerUser {
    constructor() {
        this.id = 0;
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.children = [];
    }

    static import(obj) {
        let dto = new CustomerUser();
        for (let property in obj) {
            dto[property] = obj[property];
        }
        return dto;
    }

    export() {
        let data = {};
        for (let property in this) {
            data[property] = this[property];
        }
        return data;
    }
}

export default CustomerUser;