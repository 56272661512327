<template>
<section style="width: 100%;">
  <MetricCard v-for="metric in metrics" :key="metric.id" :metric="metric" />
</section>
</template>

<script>
import EventMetricsAPI from '@/api/EventMetricsAPI';
import MetricCard from './MetricCard.vue';
export default {
  name: 'MetricsDashboard',

  components: {
    MetricCard,
  },

  data() {
    return {
      metrics: [],
      eventsList: [],
      eventsLabel: '',

      startText: null,
      endText: null,

      showLoadMore: true,

      eventsTS: 0,
      metricsTS: 0,
      intervalID: null,
      reloadDelay: 60, // in seconds
    }
  },

  created() {
    this.getAllMetrics();
  },


  mounted() {
    this.intervalID = window.setInterval(this.update, this.reloadDelay*1000);
  },

  beforeDestroy() {
    window.clearInterval(this.intervalID);
  },



  methods: {

    async update() {
      console.debug("Updating...");
      let delayMS = this.reloadDelay * 1000;
      let now = new Date().getTime();

      if(this.metricsTS + delayMS < now) await this.updateMetrics();
    },



    async getAllMetrics() {
      let graphStart = Math.floor(Date.now() / 1000) - 86400;

      this.metrics = [];
      let metrics = await EventMetricsAPI.getAllMetrics();
      for(let metric of metrics) {
        metric.datapoints = await EventMetricsAPI.getMetricDataPoints(metric.id, metric.frequency, graphStart);
        this.metrics.push(metric);
      }

      this.metricsTS = new Date().getTime();
    },


    async updateMetrics() {
      let graphStart = Math.floor(Date.now() / 1000) - 86400;

      for(let metric of this.metrics) {
        metric.datapoints = await EventMetricsAPI.getMetricDataPoints(metric.id, metric.frequency, graphStart);
      }
    },

  }


}
</script>
