<template>
<section>

	<StretchModal :showModal="showInternal" @close='close()' :clickToClose="false" :closeIcon="true" :height="null" :width="null" :fullscreen="false" ref="modal">
		<template #header>
			<span v-if="transaction && transaction.id !== null">Transaction: {{ transaction.id }}</span>
			<span v-else>New Transaction</span>&nbsp;
			<span v-if="!create && !edit" class="link" @click.stop="edit = true"><b>edit</b></span>
		</template>
		<template #default="{ closed }">
			<TransactionDetails
				v-if="transaction"
				:transaction="transaction"
				:create="createInternal"
				:createWithUser="createWithUser"
				:createWithCustomer="createWithCustomer"
				:edit="edit"
				:orderID="orderID"
				@close="closed"
				@reload="$emit('reload')"
				@created="id => created(id)"
				ref="details"
				class="ma-2"
			/>
		</template>
	</StretchModal>

	<ConfirmDialog :cancel="'Go Back'" :confirm="'Close Anyway'" @confirm="confirmClose()" ref="confirm">
		<div>Are you sure?  <b>Unsaved changes will be lost!</b></div>
	</ConfirmDialog>

</section>
</template>



<script>
import TransactionDetails from './TransactionDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
export default {
	name: 'TransactionModal',


	components: {
		TransactionDetails,
		StretchModal,
		ConfirmDialog,
	},


	props: {
		transaction: {
			type: Object,
			default: null
		},

		create: {
			type: Boolean,
			default: false
		},
		createWithUser: {
			type: Object,
			default: null
		},
		createWithCustomer: {
			type: Object,
			default: null
		},

		orderID: {
			type: Number,
			default: null
		}
	},


	data() {
		return {
			showInternal: false,
			edit: false,
		}
	},



	watch: {
		transaction() { this.initialize() },
	},



	methods: {

		initialize() {
			this.edit = false
			this.createInternal = this.create
		},

		open() {
			this.showInternal = true
		},

		close() {
			if(this.$refs.details.changesMade) {
				this.$refs.confirm.open()
				return
			}

			this.showInternal = false
			this.$emit('close')
		},

		confirmClose() {
			this.showInternal = false
			this.$emit('close')
		},

		created( id ) {
			this.transaction.id = id
			this.createInternal = false
			this.edit = true
			this.$nextTick(() => {
				this.close()
			})
		}

	}
}
</script>

<style>

</style>