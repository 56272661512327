<template>
<section>

	<StretchModal :showModal="show || showInternal" @close='close()' :clickToClose="false" :closeIcon="true" :width="width" :maxWidth="maxWidth" :height="height" ref="modal">
		<template #header>User: {{user.firstName}} {{user.lastName}}</template>
		<template #default="{ closed }">
			<UserDetails v-if="user" :User="user" :InitialView="initialView" @close="closed" ref="details" />
		</template>
	</StretchModal>

	<ConfirmDialog :show="showConfirmation" :cancel="'Go Back'" :confirm="'Close Anyway'" @confirm="confirmClose()" @cancel="showConfirmation = false">
		<div>Are you sure?  <b>Unsaved changes will be lost!</b></div>
	</ConfirmDialog>

</section>
</template>



<script>
import UserDetails from './UserDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
export default {
	name: 'UserDetailsModal',

	components: {
		UserDetails,
		StretchModal,
		ConfirmDialog,
	},


	props: {
		user: {
			type: Object,
			default: null
		},
		show: {
			type: Boolean,
			default: false
		},
		width: {
			type: String,
			default: '90%'
		},
		maxWidth: {
			type: String,
			default: null
		},
		height: {
			type: String,
			default: '100%'
		},
		initialView: {
			type: String,
			default: null
		},
	},


	data() {
		return {
			showConfirmation: false,
			showInternal: false,
		}
	},


	watch: {
		show(value) { if( !value ) this.showInternal = false },
	},


	methods: {
		open() {
			this.showInternal = true
		},

		close() {
			if(this.$refs.details.changesMade) {
				this.showConfirmation = true
				return
			}

			this.showInternal = false
			this.$emit('close')
		},

		confirmClose() {
			this.showConfirmation = false
			this.showInternal = false
			this.$emit('close')
		},

		cancelClose() {
			this.showConfirmation = false
		},

	}
}
</script>
