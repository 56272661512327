<template>
<section>

	<!-- hack for highlighting hovered column (style tags crash Vue CLI / Webpack / something) -->
	<component v-if="superColumns" :is="'style'">
		.table.highlightColumns td:nth-child( {{ hoverColumnIndex + 1 + (MultiSelect ? 1 : 0) + (Numbered ? 1 : 0) }} ), 
		.table.highlightColumns th:not(.table-super-header):nth-child( {{ hoverColumnIndex + 1 + (MultiSelect ? 1 : 0) + (Numbered ? 1 : 0) }} ) {
			background-color: rgba(11, 89, 198, 0.1) !important;
			transition: background-color 0.2s ease-in-out;
		}
	</component>


	<table :class="['table', {'highlightColumns' : superColumns}]" @mouseout="hoverColumnIndex = -10">
		

		<template v-if="superHeaders">
			
			<!-- Colgroups for "SuperColumns" bg-colors -->
			<colgroup>
				<col v-if="MultiSelect" class="table-col" />
				<col v-if="Numbered" class="table-col" />
				<col v-for="col in superColumns" :key="col.propertyName || col.displayName" :span="summaryMode && col.summaryColspan !== undefined ? col.summaryColspan : col.colspan" class="table-col" :style="col.style" />
			</colgroup>

			<!-- Multi-level, nested "Super" Columns (outer / grouped headers) -->
			<tr v-for="(level, idx) in superHeaders" :key="'super-header-'+idx">
				<th v-if="MultiSelect"></th>
				<th v-if="Numbered"></th>
				<th 
					v-for="(col, colIdx) in level" 
					:key="(col.propertyName || col.displayName)+colIdx" 
					:colspan="summaryMode && col.summaryColspan !== undefined ? col.summaryColspan : col.colspan" 
					v-html="col.displayName" 
					:class="'table-super-header table-super-header-'+(idx+1)" 
					:style="col.style"
					@mouseenter="col.tooltip ? tooltips.showTooltip( $event, 'bottom', 0, (col.tooltipTime || 3000), (col.tooltipAlign || 'center') ) : null"
					@mouseleave="col.tooltip ? tooltips.hideTooltip($event) : null"
					:tooltip="col.tooltip" />
			</tr>

		</template>


		<!-- Column Headers -->
		<tr>
			<th v-if="MultiSelect"></th>
			<th v-if='Numbered' class='table-header'>#</th>

			<template v-for="(column, index) in Columns">
				<th v-if="column.summaryColumn == true || !summaryMode"
					:key="index"
					class="sticky-top bg-white"
					:class="{
						'table-header': true,
						'sortable': column.sortable,
						'sticky-left' : column.sticky
					}"
					:style="!summaryMode && column.summaryColumn == true && column.summaryStyle !== undefined ? column.summaryStyle : (column.headerStyle ? column.headerStyle : null)"
					@click.left='sortByFunc($event, column)'
					@mouseover="hoverColumnIndex = -10"
					@mouseenter="column.tooltip ? tooltips.showTooltip( $event, 'bottom', 0, (column.tooltipTime || 3000), (column.tooltipAlign || 'center') ) : null"
					@mouseleave="column.tooltip ? tooltips.hideTooltip($event) : null"
					:tooltip="column.tooltip"
				>
					<div class="flex-row flex-align-center flex-gap-025">
						<span v-html="column.displayName || column.propertyName"></span>

						<!-- Sort: Up Arrow -->
						<span 
							v-if='( SortBy !== null && multisort && ( (SortBy.includes(column.sortByName) && SortAsc[ SortBy.indexOf(column.sortByName) ] === true) || (SortBy.includes(column.propertyName) && SortAsc[ SortBy.indexOf(column.propertyName) ] === true) ) ) 
							|| ( SortBy !== null && !multisort && SortAsc && (SortBy == column.propertyName || SortBy == column.sortByName || ( Array.isArray(column.sortByName) && column.sortByName[0] === SortBy && column.sortByName[1] === sortBySuperColumnIndex ) ) )'
							class="color-blue-50 font-size-0-9"
						>
						&#9652;
						ASC
						</span>

						<!-- Sort: Down Arrow -->
						<span
							v-else-if="( SortBy !== null && multisort && ( (SortBy.includes(column.sortByName) && SortAsc[ SortBy.indexOf(column.sortByName) ] === false) || (SortBy.includes(column.propertyName) && SortAsc[ SortBy.indexOf(column.propertyName) ] === false) ) ) 
							|| ( SortBy !== null && !multisort && !SortAsc && (SortBy == column.propertyName || SortBy == column.sortByName || ( Array.isArray(column.sortByName) && column.sortByName[0] === SortBy && column.sortByName[1] === sortBySuperColumnIndex ) ) )"
							class="color-blue-50 font-size-0-9"
						>
						&#9662;
						DESC
						</span>

						<span 
							v-if="multisort && ( SortBy.includes(column.sortByName) || SortBy.includes(column.propertyName ) )"
							class="color-blue-50 font-size-0-8"
						>
							{{ SortBy.indexOf( column.sortByName ) >= 0 ? SortBy.indexOf( column.sortByName ) + 1 : SortBy.indexOf( column.propertyName ) + 1 }}
							<span @click.stop="unsortFunc($event, column)" class="icon-cross unsortButton pa-1" />
						</span>
					</div>

				</th>
			</template>
			<th v-if="Duplicatable" style="width: 3em;"></th>
			<th v-if="Deletable" style="width: 3em;"></th>
		</tr>


		<!-- Data Rows -->
		<tr v-for="(item, rowIndex) in Source"
			:key="rowIndex"
			class="table-row clickable"
			:class="{'active' : item === activeItem && !inlineSelect, 'multiSelected' : selectedItems[ rowIndex ] == true, 'dragging' : draggingRow === rowIndex, 'drugOver' : drugOverRow === rowIndex }"
			:style="StyleFunction(item)"
			:draggable="draggable"
			@dragstart="ev =>dragStart( ev, rowIndex )"
			@dragend="ev => dragEnd( ev, rowIndex )"
			@dragenter="ev => dragEnter( ev, rowIndex )"
			@dragover="ev => dragOver( ev, rowIndex )"
			@drop="ev => drop( ev, rowIndex )"
		>
			<td v-if="MultiSelect"><input type="checkbox" v-model="selectedItems[rowIndex]" @click.exact="startRangeSelect( rowIndex )" @click.shift="continueRangeSelect( rowIndex )" />
			<td v-if='Numbered' class='table-data' style="color: #888; border-right: 1px solid #ccc; width: 1%; text-align: right; padding-right: 0.75em;">
				#&nbsp;{{ ( ((PageNum - 1) * PageSize) + (rowIndex + 1) ).toLocaleString() }}
			</td>

			<!-- Data Cells -->
			<template v-for="(column, colIndex) in Columns">
				<td v-if="column.summaryColumn == true || !summaryMode"
					:key="colIndex"
					class="table-data"
					:class="{ 'firstColumnSpacer' : Numbered && colIndex == 0, 'sticky-left' : column.sticky }"
					:style="!summaryMode && column.summaryColumn == true && column.summaryStyle !== undefined ? column.summaryStyle : (column.style ? column.style(item) : null)"
					@mouseover="hoverCol(colIndex)"
					@click="e => cellClick( e, item, column )"
					@mouseenter="column.itemTooltip ? tooltips.showTooltip( $event, 'bottom', 0 ) : null"
					@mouseleave="column.itemTooltip ? tooltips.hideTooltip( $event ) : null"
					:tooltip="column.itemTooltip ? column.itemTooltip( item ) : null"
				>
					<span v-if="inlineSelect && column.editable && item === activeItem && selectedColumn === column" @keydown.stop.exact.enter="selectRowByIndex( rowIndex + 1 )" @keydown.stop.shift.enter="selectRowByIndex( rowIndex - 1 )" @keydown.stop.prevent.exact.tab="selectColumnByIndex( colIndex, colIndex + 1, rowIndex )" @keydown.stop.prevent.shift.tab="selectColumnByIndex( colIndex, colIndex - 1, rowIndex )" ref="inlineEditCell">
						<input v-if="DisplayFunction || column.displayFunction" type="text" :value="DisplayFunction ? DisplayFunction( item, column.propertyName, colIndex ) : column.displayFunction( item )" @click.stop @input="ev => debounceInlineEditField( item, column.propertyName, ev.target.value )" />
						<span v-else-if="item[ column.propertyName ] === true" class="icon-checkmark message-success" @click="inlineEditField( item, column.propertyName, false )" />
						<span v-else-if="item[ column.propertyName ] === false" class="icon-cross" style="color:#aaa" @click="inlineEditField( item, column.propertyName, true )" />
						<input v-else :value="item[ column.propertyName ]" @click.stop @input="ev => debounceInlineEditField( item, column.propertyName, ev.target.value )" />
					</span>

					<span v-else-if="DisplayFunction" v-html='DisplayFunction(item, column.propertyName, colIndex)' :class="{'hideInactive': column.hideInactive, 'tableLink' : column.link}"></span>
					<span v-else-if="column.displayFunction" v-html="column.displayFunction( item )" />
					<span v-else>
						<span v-if='item[column.propertyName]===true' class='icon-checkmark message-success'></span>
						<span v-else-if='item[column.propertyName]===false' class='icon-cross' style='color:#aaa'></span>
						<div v-else v-html="item[column.propertyName]" />
					</span>

					<!-- Pop-over data -->
					<div v-if="column.showPopover && activeItem === item && column.getPopover" class="table-popover" v-html="column.getPopover( item )" />
				</td>
			</template>

			<td v-if="Duplicatable"><span class="icon-copy list-button" @click.stop="$emit('duplicate', item)" /></td>
			<td v-if="Deletable"><span class="icon-bin list-button" @click.stop="remove(item)" /></td>
		</tr>


	</table>
</section>
</template>

<script>
import Tooltips from '@/libraries/Tooltips/Tooltips.js';

export default {
	name: "object-table",


	props: {
		Source: [Array, Object],
		Columns: Array,

		// Multi-column "super-headers" (i.e. top-line spanning headers)
		// [ { displayName: String, colspan: Number }, ... ]
		superColumns: {
			type: Array,
			default: null
		},

    // If TRUE, show ONLY columns having attribute `summaryColumn: true`
		summaryMode: {
			type: Boolean,
      default: false
		},

		allowSelect: {
			type: Boolean,
			default: true
		},

		inlineSelect: {
			type: Boolean,
			default: false
		},

		// if true, displays checkboxes to allow the user to select multiple items
		MultiSelect: {
			type: Boolean,
			default: false
		},

		draggable: {
			type: Boolean,
			default: false
		},

		// if true, displays a clickable copy icon that emits "duplicate" event when clicked.
		Duplicatable: Boolean,

		// if true, displays a clickable bin icon that emits "delete" event when clicked.
		Deletable: Boolean,

		// A function for displaying table data. The function should take a source object
		// and a property name string as parameters.
		DisplayFunction: Function,

		// Programmatically style each row: ( itemRow ) => { //custom function body }
		StyleFunction: {
			type: Function,
			default: () => {}
		},

		// If true, displays the index (starting from 1) at the beginning of each row.
		Numbered: Boolean,

		// The number of results on a page.
		PageSize: {
			type: Number,
			default: 0
		},

		// The page number, used for pagination.
		PageNum: {
			type: Number,
			default: 1
		},

		// The propertyName of the column that the data is being sorted by.
		SortBy: {
			type: [String, Array],
			default: null
		},

		multisort: {
			type: Boolean,
			default: false
		},

		sortBySuperColumnIndex: {
			type: Number,
      default: null
		},

		// If true, places an up arrow next to the column header indicated by SortBy.  Otherwise, a down arrow is displayed.
		SortAsc: {
			type: [Boolean, Array],
			default: true
		},
	},


	data() {
		return {
			selectedItems: [],
			selectedRangeStartIndex: null,

			draggingRow: null,
			drugOverRow: null,

			selectedColumn: null,

			activeItem: null,
			hoverColumnIndex: null,
			superHeaders: null,
			superHeaderDepth: null,

			inlineEditTimeoutID: null,
		}
	},


	created() { this.initialize() },


	computed: {
		tooltips() { return Tooltips },

		hasSubheaders() {
			for( var col of this.Columns ) if( Array.isArray(col) ) return true
			return false
		},
	},


	watch: {
		Source() { this.initialize() },
		Columns() { this.initialize() },
		selectedItems() { this.activeItem = null },
		superColumns( value ) {
			if( value ) {
				this.superHeaderDepth = 0
				for( const col of value ) this.calculateSuperHeaderDepth( col )
				this.superHeaders = []
				for( const col of value ) this.createSuperHeaders( col, 0 )
			
			} else this.superHeaders = null
		},
	},



	methods: {

		sortByFunc( event, column ) {
			if( column.tooltip ) this.tooltips.hideTooltip(event)
			this.activeItem = null

			const prop = column.sortByName || column.propertyName
			const sortable = column.sortable

			if( sortable ) this.$emit( 'sort', prop )
		},


		unsortFunc( event, column ) {
			if( !this.multisort ) {
				this.sortByFunc( event, column )
				return
			}

			if( column.tooltip ) this.tooltips.hideTooltip( event )
			this.activeItem = null

			const prop = column.sortByName || column.propertyName
			this.$emit( 'unsort', prop )
		},


		initialize() {

			// Super Columns
			if( this.superColumns !== null ) {
				this.superHeaderDepth = 0
				for( col of this.superColumns ) this.calculateSuperHeaderDepth( col )
				this.superHeaders = []
				for( var col of this.superColumns ) this.createSuperHeaders( col )
			} else this.superHeaders = null

			// console.debug( this.superHeaders )

			// Multi-select
			this.activeItem = null;
			this.selectedItems = [];
			if(!this.MultiSelect) return;
			if(!Array.isArray(this.Source)) return;

			this.selectedItems = Array.from({length: this.Source.length}, () => false);
		},


		calculateSuperHeaderDepth( root, depth = 1 ) {
			if( depth > this.superHeaderDepth ) this.superHeaderDepth = depth
			if( !root.children ) return
			for( var col of root.children ) this.calculateSuperHeaderDepth( col, depth + 1 )
		},


		createSuperHeaders( root, depthIndex = 0 ) {
			

			while( depthIndex >= this.superHeaders.length ) this.superHeaders.push( [] )
			this.superHeaders[ depthIndex ].push( root )

			if( !root.children ) {
				if( depthIndex + 1 < this.superHeaderDepth ) this.createSuperHeaders( { displayName: '', colspan: root.colspan }, depthIndex + 1 )
				return
			}
			for( var superCol of root.children ) this.createSuperHeaders( superCol, depthIndex + 1 )
			
		},


		cellClick( event, row, column ) {

			// POPOVER: Open if closed; close if open
			const openPopover = !(column.showPopover && this.activeItem === row)
			
			// Select row (don't de-select if opening a new cell's popover on same row)
			if( column.getPopover === undefined || !openPopover || this.activeItem !== row ) this.selectRow( row, column.clickHandler == undefined )

			// select column
			this.selectedColumn = column

			// Call COLUMN's click handler
			if( column.clickHandler !== undefined ) column.clickHandler( event, row )


			// Handle Popover
			this.Columns.forEach( col => col.showPopover = false )
			// for( var item of this.Source ) item.showPopover = false
			
			if( column.getPopover && openPopover ) {
				column.showPopover = this.activeItem ? openPopover : false
			}

		},



		debounceInlineEditField( item, propertyName, newValue ) {
			if( this.inlineEditTimeoutID != null ) clearTimeout( this.inlineEditTimeoutID )
			this.inlineEditTimeoutID = setTimeout( () => { this.inlineEditField( item, propertyName, newValue ) }, 500)
		},
		
		inlineEditField( item, propertyName, newValue ) {
			this.$emit( 'inlineEdit', item, propertyName, newValue )
		},




		selectAll() {
			this.activeItem = null
			for( const [idx, elem] of this.selectedItems.entries() ) this.$set( this.selectedItems, idx, true )
			// this.selectedItems.forEach( elem => elem = true )
			// this.selectedItems = Array.from({length: this.Source.length}, (v, i) => true);
		this.$emit('selectedItems', this.selectedItems);
		},


		selectNone() {
			this.activeItem = null
			for( const [idx, elem] of this.selectedItems.entries() ) this.$set( this.selectedItems, idx, false )
			// this.selectedItems.forEach( elem => elem = false )
			// this.selectedItems = Array.from({length: this.Source.length}, (v, i) => false);
			this.$emit('selectedItems', this.selectedItems);
		},
		

		startRangeSelect( rowIndex ) {
			this.selectedRangeStartIndex = rowIndex
			this.selectedItems[ rowIndex ] = !this.selectedItems[ rowIndex ]
			this.$emit('selectedItems', this.selectedItems)
		},

		continueRangeSelect( rowIndex ) {
			const toSelect = !this.selectedItems[ rowIndex ] // Whether to select or deselect
			const startIndex = Math.min( this.selectedRangeStartIndex, rowIndex )
			const endIndex = Math.max( this.selectedRangeStartIndex, rowIndex )

			for( var i = startIndex; i <= endIndex; i++ ) this.selectedItems[i] = toSelect
			this.$emit('selectedItems', this.selectedItems)

			this.selectedRangeStartIndex = rowIndex
		},



		selectRow( item, emit = true ) {
			if( this.activeItem === item ) {
				this.activeItem = null
				return
			}

			if( !this.allowSelect ) return
			
			this.activeItem = item
			if( emit ) this.$emit('edit', item)
		},


		remove(item) {
			this.activeItem = item
			this.$emit('delete', item)
		},


		deselect() { this.activeItem = null }, // Called by parent component



		// ------------------------------------------------------------------------------------------------
		// Tab / "enter" through the table when inline-editing -- like a spreadsheet
		// ------------------------------------------------------------------------------------------------

		async selectRowByIndex( idx ) {
			if( idx < 0 || idx >= this.Source.length ) {
				this.activeItem = null
				return
			}
			
			this.activeItem = this.Source[ idx ]
			await this.$nextTick()
			this.$refs.inlineEditCell[0].firstChild.focus()
		},

		async selectColumnByIndex( srcIndex, dstIndex, rowIndex ) {
			const increasing = srcIndex < dstIndex ? true : false

			if( dstIndex < 0 ) {
				this.selectRowByIndex( rowIndex - 1 )
				this.selectColumnByIndex( this.Columns.length, this.Columns.length - 1, rowIndex - 1 )
				return
			}

			if( dstIndex >= this.Columns.length ) {
				this.selectRowByIndex( rowIndex + 1 )
				this.selectColumnByIndex( -1, 0, rowIndex + 1 )
				return
			}

			// Skip non-editable columns
			if( increasing ) {

				var nextLine = false
				while( !this.Columns[ dstIndex ].editable ) {
					if( dstIndex == this.Columns.length - 1 ) {
						if( nextLine == true ) break
						nextLine = true
						dstIndex = 0
					} else {
						dstIndex++
					}
				}

				if( nextLine ) this.selectRowByIndex( rowIndex + 1 )
			
			} else {
				var prevLine = false
				while( !this.Columns[ dstIndex ].editable ) {
					if( dstIndex == 0 ) {
						if( prevLine == true ) break
						prevLine = true
						dstIndex = this.Columns.length - 1
					} else {
						dstIndex--
					}
				}

				if( prevLine ) this.selectRowByIndex( rowIndex - 1 )
			}
			
			this.selectedColumn = this.Columns[ dstIndex ]

			if( this.selectedColumn.editable ) {
				await this.$nextTick()
				this.$refs.inlineEditCell[0].firstChild.focus()
			}
		},

		// ------------------------------------------------------------------------------------------------



		hoverCol(index) {

			if( !this.summaryMode ) {
				this.hoverColumnIndex = index
				return
			}

			var colIndex = 0
			for( var i = 0; i < this.Columns.length; i++ ) {
				if( i >= index ) break
				var col = this.Columns[i]
				if( col.summaryColumn === true ) colIndex++
			}
			this.hoverColumnIndex = colIndex
		},


		dragStart( event, rowIndex ) {
			this.draggingRow = rowIndex
		},

		dragEnd( event, rowIndex ) {
			this.draggingRow = null
			this.drugOverRow = null
		},

		dragEnter( event, rowIndex ) {
			this.drugOverRow = rowIndex
			event.preventDefault()
		},

		dragOver( event, rowIndex ) { event.preventDefault() },
		
		drop( event, newIndex ) {

			// emit: reorder( oldIndex, newIndex )
			this.$emit( 'reorder', this.draggingRow, newIndex )
		},


	},



}
</script>

<style scoped>
.sortable:hover {
	background-color: #eee;
	cursor: pointer;
}

.sort-asc::after {
	content: \9662;
}

.hide {
	display: none;
}
.table { font-size: 0.95em; }

.firstColumnSpacer { padding-left: 0.75em; }


.hideInactive { opacity: 0; }
tr:hover .hideInactive { opacity: 1; }
.tableLink:hover {
	color: var(--ekno-blue);
	cursor: pointer;
}

.active {
	background: var(--ekno-blue-dim) !important;
	/* color: white; */
}

.multiSelected {
	background-color: var(--pp10-orange-90) !important;
}

.dragging {
	background-color: var( --pp10-purple-90 ) !important;
}

.drugOver td {
	border-top: 5px solid var( --pp10-purple-50 );
}

.unsortButton:hover {
	color: red;
}
</style>
