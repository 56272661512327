<template>
<section>

	<div v-if="customerID === null && affiliateID === null" class="flex-row flex-justify-between flex-align-center flex-gap-2 my-05">
		<div class="flex-row flex-align-center flex-gap-05">
			<CustomerSearchDropdown @updateCustomer="cust => selectCustomer(cust)" width="250px" placeholder="Filter by Customer" ref="customerSearch" />
			<AffiliateSearchDropdown @update="aff => selectAffiliate(aff)" width="250px" placeholder="Filter by Affiliate" ref="affiliateSearch" />
			<select v-model="selectedTypeID">
				<option :value="null">All Types</option>
				<option value="">No Type</option>
				<option value="" disabled>&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;</option>
				<option v-for="type in modalMessageTypes.values()" :value="type.typeID" :key="type.typeID">{{ type.name }}</option>
			</select>
			<select v-model="isActive">
				<option :value="null">Active &amp; Inactive</option>
				<option :value="true">Active Only</option>
				<option :value="false">Inactive Only</option>
			</select>
		</div>
		<button class="button" @click="showCreateModalMessage()">New Modal Message</button>
	</div>

	<div v-if="modalMessages.length">
		<ObjectTable
			:Source="modalMessages"
			:Columns='columns'
			:Deletable="true"
			:Numbered="true"
			:PageNum='pageNum'
			:PageSize='pageSize'
			:SortBy='sortBy'
			:SortAsc='sortAsc'
			:StyleFunction="item => styleFunction(item)"
			@edit="item => showEditModalMessage(item)"
			@delete="item => showDeleteSelected(item)"
			@sort="prop => selectSortBy(prop)"
			ref="objectTable"
		/>
    <Paginator v-model="pageNum" :numPages="numPages" />
	</div>
	<div v-else class="NoResults">No Modal Messages</div>


	<ModalMessageDetailsModal
		ref="detailsModal"
		:item="currentModalMessage"
		@create="mm => addModalMessage(mm)"
		@save="newMM => updateModalMessage(newMM)"
		@close="$refs.objectTable.deselect()"
	/>

	<ConfirmDialog :show="showConfirmDelete" confirm="Delete" @cancel="cancelDelete()" @confirm="deleteSelected()">
		<div>Are you sure you want to delete the Modal Message:</div>
		<div v-if="currentModalMessage" style="font-size: 1.5em; margin: 0.5em 0; font-weight: bold">&ldquo;{{ currentModalMessage.name }}&rdquo;</div>
		<div class="mt-2"><strong>This action CANNOT be undone!</strong></div>
  </ConfirmDialog>
	

</section>
</template>



<script>
import ModalMessageAPI from '@/api/ModalMessageAPI.js'
import ModalMessageRequest from './ModalMessageRequest.js'
import PaginatedResponse from '@/api/PaginatedResponse.js'
import ModalMessage from './ModalMessage.js'
import ModalMessageType from './ModalMessageType.js'

import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import ModalMessageDetailsModal from './ModalMessageDetailsModal.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

export default {
	name: 'ModalMessageList',

	components: {
		AffiliateSearchDropdown,
		CustomerSearchDropdown,
		ModalMessageDetailsModal,
		ObjectTable,
		Paginator,
		ConfirmDialog,
	},



	props: {
		customerID: {
			type: Number,
			default: null
		},

		affiliateID: {
			type: Number,
			default: null
		},
	},



	data() {
		return {
			modalMessages: [],
			currentModalMessage: null,
			showConfirmDelete: false,

			modalMessageTypes: new Map(),

			sortBy: 'previousModalMessageID',
			sortAsc: true,
			pageNum: 1,
			pageSize: 100,
			numRows: 0,
			numPages: 0,

			selectedCustomerID: null,
			selectedAffiliateID: null,
			selectedTypeID: null,
			isActive: null,

			columns: [
				{
					displayName: 'ID',
					propertyName: 'modalMessageID',
					sortable: true
				},
        {
          displayName: 'Name',
          propertyName: 'name',
					displayFunction: ( item ) => {
						if( !item.previousModalMessageID ) return item.name
						if( this.sortBy == 'previousModalMessageID') return `<span class="icon-arrow-up2"></span>&nbsp;${item.name}`
						else return item.name
					},
          sortable: true
        },
				{
					displayName: 'Type',
					propertyName: 'typeID',
					displayFunction: item => {
						const type = this.modalMessageTypes.get( item.typeID )
						return type ? type.name : ''
					},
					sortable: true
				},
				{
					displayName: 'Priority',
					propertyName: 'priority',
					sortable: true
				},
				{
					displayName: 'User<br>Order',
					propertyName: 'previousModalMessageID',
					tooltip: '\n\
Sort ModalMessages according to the order they\'ll be displayed to users: \n\n\
1. Prioritize "Chained" Modal Messages \n\n\
2. Active: active first \n\n\
3. Priority: highest first \n\n\
4. End Date: prioritize nearly-expired \n\n\
5. Max Logins: lowest first \n\n\
6. Max Time in Program: lowest first \n\n\
7. Max License Age: lowest first \n\n\
8. Max Account Age: lowest first \n\n\
9. Max # Views: lowest first \n\n\
10. Date Created: newest messages first\n\n',
					tooltipAlign: "left",
					tooltipTime: 15000,
					sortable: true
				},
				{
					displayName: 'Users',
					propertyName: 'triggerUserCount',
					displayFunction: ( item ) => {
						return item.triggerUserCount !== null ? item.triggerUserCount : '?'
					}
				},
        {
          displayName: 'Active',
          propertyName: 'isActive',
          sortable: true,
					displayFunction: ( item ) => {
						if( !item.isActive) return `<span class='icon-cross' style='color:#aaa'></span>`
						if( !item.hasLocation ) return `<span class='icon-location2' style='color: red'></span>`
						if( !item.hasDisplayEvent ) return `<span class='icon-enter' style='color: red'></span>`
						if( !item.isAvailableCalendar ) return `<span class='icon-calendar' style='color: red'></span>`
						return `<span class='icon-checkmark message-success'></span>`
					},
					clickHandler: async (event, item) => {
            event.stopPropagation()
						if( !item.modalMessageID ) return
						if( !item.hasLocation ) return
						if( !item.hasDisplayEvent ) return
						if( !item.isAvailableCalendar ) return
            if( item.isActive ) await ModalMessageAPI.disableModalMessage( item.modalMessageID )
						else await ModalMessageAPI.enableModalMessage( item.modalMessageID )
						item.isActive = !item.isActive
          }
        },
				{
					displayName: 'Views',
					propertyName: 'viewCount',
					sortable: true,
					displayFunction: function( item ) {
						return `${item.viewCount} (${item.uniqueViewCount} users)`
					}
				},
				{
					displayName: 'Actions',
					propertyName: 'actionCount',
					sortable: true,
					displayFunction: function( item ) {
						return `${item.actionCount} (${item.uniqueActionCount} users)`
					}
				},
				{
					displayName: 'Responses',
					propertyName: 'responseCount',
					sortable: true,
					displayFunction: function( item ) {
						return `${item.responseCount} (${item.uniqueResponseCount} users)`
					}
				},
				{
					displayName: 'Reviews',
					propertyName: 'reviewCount',
					sortable: true,
					displayFunction: function( item ) {
						return `${item.reviewCount} (${item.uniqueReviewCount} users)`
					}
				},
				{
					displayName: 'Created',
					propertyName: 'dateCreated',
					sortable: true,
					displayFunction: function( item ) { return item.dateCreated.toLocaleDateString() }
				}
        
      ],
		}
	},



	created() { this.initialize() },


	watch: {
		pageNum() { this.getModalMessages() },
		selectedTypeID() { this.getModalMessages() },
		isActive() { this.getModalMessages() },
	},



	methods: {

		async initialize() {
			this.getTypes()
			this.getModalMessages()
		},

		async getTypes() {
			this.modalMessageTypes.clear()
			const data = await ModalMessageAPI.getTypes()
			for( var item of data ) this.modalMessageTypes.set( item.typeID, ModalMessageType.import(item) )
		},

		async getModalMessages() {
			this.modalMessages = []
			this.currentModalMessage = null

			// Prepare request
			const req = new ModalMessageRequest()
			req.sortBy = this.sortBy
			req.sortAsc = this.sortAsc
			req.page = this.pageNum
			req.pageSize = this.pageSize
			req.isActive = this.isActive

			if( this.affiliateID ) req.affiliateIDs = [ this.affiliateID ]
			else if( this.selectedAffiliateID ) req.affiliateIDs = [ this.selectedAffiliateID ]

			if( this.customerID ) req.customerIDs = [ this.customerID ]
			else if( this.selectedCustomerID ) req.customerIDs = [ this.selectedCustomerID ]

			if( this.selectedTypeID ) req.typeIDs = [ this.selectedTypeID ]
			else if( this.selectedTypeID === '' ) req.typeIDs = []

			req.includeNulls = req.shouldIncludeNulls()
			req.honorRequired = false

			// Response / Import
			const res = await ModalMessageAPI.getModalMessages( req )
			const pr = PaginatedResponse.import( res.data )
			this.numRows = pr.count
			this.numPages = pr.pages
			for(var item of pr.data) {
				const mm = ModalMessage.import(item)
				ModalMessageAPI.getAssignedUserCount( mm.modalMessageID, true ).then( res => mm.triggerUserCount = res.count )
				this.modalMessages.push( mm )
			}
		},



		async selectCustomer( cust ) {
			this.selectedCustomerID = cust ? cust.id : null
			this.getModalMessages()
		},


		async selectAffiliate( aff ) {
			this.selectedAffiliateID = aff ? aff.id : null
			this.getModalMessages()
		},



		showCreateModalMessage() {
			this.currentModalMessage = new ModalMessage()
			this.$refs.detailsModal.open()
		},


		async addModalMessage( newMM ) {
			this.modalMessages.push( newMM )
			this.currentModalMessage = newMM
		},


		async showEditModalMessage( item ) {
			
			// Get updated MM w/ counts
			const data = await ModalMessageAPI.getModalMessage( item.modalMessageID )
			const newMM = ModalMessage.import( data )

			// Copy updated MM props INTO current object
			Object.assign( item, newMM )

			this.currentModalMessage = item
			this.$refs.detailsModal.open()
		},


		updateModalMessage( changedMM ) {
			this.currentModalMessage = changedMM

      for(let i=0; i<this.modalMessages.length; i++) {
        if(this.modalMessages[i].modalMessageID != changedMM.modalMessageID) continue
        this.$set(this.modalMessages, i, changedMM)
        return
      }
		},


		showDeleteSelected( item ) {
			this.currentModalMessage = item
			this.showConfirmDelete = true
		},


		async deleteSelected() {
			if( !this.currentModalMessage || !this.currentModalMessage.modalMessageID ) return
			await ModalMessageAPI.deleteModalMessage( this.currentModalMessage.modalMessageID )
			this.showConfirmDelete = false
			this.getModalMessages()
		},


		cancelDelete() {
			this.$refs.objectTable.deselect()
			this.showConfirmDelete = false
		},


		selectSortBy(prop) {

      if (prop == this.sortBy) {
				this.sortAsc = !this.sortAsc
				this.getModalMessages();
				return
			}

			this.sortBy = prop;
			if( this.sortBy === 'name' || this.sortBy === 'modalMessageID' ) this.sortAsc = true;
			else this.sortAsc = false;

			this.getModalMessages();
    },



		styleFunction( item ) {
			const style = {};
			if( !item.isActive ) style.color = '#aaa'
			return style;
		}

	}
}
</script>



<style scoped>

</style>