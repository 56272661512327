<template>
<section>
	
	<div class='flex justify-space-between flex-align-center py-05' style="position: sticky; top: 0; background: white;">

		<div class="flex-row flex-justify-start flex-align-center flex-gap-05">
			<SearchBox @search="str => searchStr = str" />

			<div>
				<select v-model="status">
					<option :value="null">All Statuses</option>
					<option :value="null" disabled>-----------</option>
					<option value="active">Active</option>
					<option value="paused">Paused</option>
					<option value="canceled">Canceled</option>
					<option value="expired">Expired</option>
				</select>
			</div>
		</div>


		<div class="flex-row flex-align-center">
			<div class="mr-2">{{ count ? count.toLocaleString() : count }} subscriptions on {{ pages }} pages</div>
			<label>Per page: </label>
			<select v-model="pageSize" class="ml-05">
				<option :value='25'>25</option>
				<option :value='50'>50</option>
				<option :value='100'>100</option>
				<option :value='250'>250</option>
				<option :value='500'>500</option>
			</select>
		</div>
		<!-- <button class="button inline-block" @click="newLicense">New License</button> -->
	</div>


	<div v-if='subscriptions.length'>
		<ObjectTable
			:Source='subscriptions'
			:Columns='columns'
			:Numbered='false'
			:SortBy='sortBy'
			:SortAsc='sortAsc'
			@edit="sub => editSubscription(sub)"
			@sort="prop => sort(prop)"
			ref="objectTable"
		/>
		<Paginator :value="page" :numPages="pages" @input="pg => toPage( pg )" />
	</div>
	<div v-else-if="loading" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
	<div v-else-if="error" class="warning">Failed to get transactions</div>
	<div v-else class="NoResults">No results</div>


	<SubscriptionDetailsModal :subscription="selectedSubscription" ref="detailsModal" @close="closeDetails()" />
</section>
</template>

<script>
import SubscriptionAPI from '@/api/SubscriptionAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Subscription from '@/models/Subscription.js'

import SubscriptionDetailsModal from './SubscriptionDetailsModal.vue'
import SearchBox from "@/components/utilities/SearchBox.vue"
import ObjectTable from '@/components/utilities/ObjectTable'
import Paginator from '@/components/utilities/Paginator'
export default {
	name: 'SubscriptionsList',

	components: {
		SubscriptionDetailsModal,
		SearchBox,
		ObjectTable,
		Paginator,
	},

	data() {
		return {
			loading: false,
			error: false,

			selectedSubscription: null,
			subscriptions: [],

			count: 0,
			page: 1,
			pages: 1,
			pageSize: 100,
			
			sortBy: 'subscriptionID',
			sortAsc: false,
			searchStr: null,
			status: null,

			nameIndex: 3,
			priceIndex: 4,
			nextNameProp: 'firstName',
			nextPriceProp: 'price',

			columns: [
				{
					displayName: 'id',
					propertyName: 'subscriptionID',
					sortable: true
				},
				{
					displayName: 'License',
					propertyName: 'licenseKey',
					sortable: true
				},
				{
					displayName: 'SKUs',
					propertyName: 'skus',
					displayFunction: function( item ) { return item.skus.join(', ') }
					// sortable: true
				},
				{
					displayName: 'User',
					propertyName: 'firstName',
					displayFunction: function( item ) { return `${item.firstName} ${item.lastName} <span style="color: #999">&mdash; #${item.userID}` },
					sortable: true
				},
				{
					displayName: 'Price',
					propertyName: 'price',
					displayFunction: function( item ) {
						let freq = item.frequency;
						let unit = item.frequency == 1 ? item.frequencySingularUnit : item.frequencyUnit;
						if(freq == 12 && unit == 'months') {
							freq = 1;
							unit = 'year';
						}
						let price = Math.moneyFormat(item.dividedPrice);
						let bundle = item.pricingDivisor > 1 ? `<span style="color: #999">- (1 of ${item.pricingDivisor})</span>` : ''
						return `${price} / ${freq == 1 ? '' : freq} ${unit} ${bundle}`
					},
					sortable: true
				},
				{
					displayName: '# Billings',
					sortByName: 'completedOccurrences',
					displayFunction: function( item ) { return `${item.completedOccurrences} <span style="color: #999">/ ${item.totalOccurrences} (${item.percentComplete}%)</span>`},
					sortable: true
				},
				{
					displayName: 'Origination Date',
					propertyName: 'startTimestamp',
					displayFunction: function( item ) { return item.startTimestamp.toLocaleString() },
					sortable: true
				},
				{
					displayName: 'Recent Billing',
					propertyName: 'previousBillingTimestamp',
					sortByName: 'recentBillingOccurrenceDate',
					displayFunction: function( item ) { return item.previousBillingTimestamp ? item.previousBillingTimestamp.toLocaleString() : 'none' },
					sortable: true
				},
				{
					displayName: 'Next Billing',
					propertyName: 'nextBillingTimestamp',
					displayFunction: function( item ) { return item.nextBillingTimestamp ? item.nextBillingTimestamp.toLocaleString() : 'none' },
					sortable: true
				},
				{
					displayName: 'Status',
					propertyName: 'status',
					sortable: true
				},
			],
		}
	},

	created() {
		this.initialize();
	},


	
	watch: {
		searchStr() {
			this.page = 1
			this.initialize()
		},
		status() {
			this.page = 1
			this.initialize()
		},
		pageSize() {
			this.page = 1
			this.initialize()
		}
	},



	methods: {
		async initialize() {
			this.loading = true
			try {
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
				req.searchString = this.searchStr
				req.status = this.status
	
				let pr = await SubscriptionAPI.getSubscriptions( req );
	
				this.subscriptions = [];
				for(let item of pr.data) {
					this.subscriptions.push( Subscription.import(item) );
				}
	
				this.pages = pr.pages;
				this.count = pr.count;

			} catch (e) {
				this.error = true
				throw e

			} finally {
				this.loading = false
			}

		},


		editSubscription( sub ) {
			this.selectedSubscription = sub
			this.$refs.detailsModal.open()
		},


		closeDetails() {
			this.selectedSubscription = null
			this.$refs.objectTable.deselect()
		},


		toPage(page) {
			this.page = page
			this.initialize()
		},

		sort( propName ) {
			if( propName !== 'price' && propName !== 'frequency' && propName !== 'pricingDivisor' ) {
				this.columns[ this.priceIndex ].displayName = 'Price'
				this.columns[ this.priceIndex ].propertyName = 'price'
				this.nextPriceProp = 'price'
			}

			if( propName !== 'firstName' && propName !== 'lastName' && propName !== 'userID' ) {
				this.columns[ this.nameIndex ].displayName = 'User'
				this.columns[ this.nameIndex ].propertyName = 'firstName'
				this.nextNameProp = 'firstName'
			}

			if( propName == 'price' && this.nextPriceProp !== 'price' ) propName = this.nextPriceProp
			if( propName == 'frequency' && this.nextPriceProp !== 'frequency' ) propName = this.nextPriceProp
			if( propName == 'pricingDivisor' && this.nextPriceProp !== 'pricingDivisor' ) propName = this.nextPriceProp

			if( propName == 'firstName' && this.nextNameProp !== 'firstName' ) propName = this.nextNameProp
			if( propName == 'lastName' && this.nextNameProp !== 'lastName' ) propName = this.nextNameProp
			if( propName == 'userID' && this.nextNameProp !== 'userID' ) propName = this.nextNameProp
			
			console.debug(propName)
			
			if( propName == 'price' ) {

				if( this.sortBy !== propName ) {
					// Arriving at "price"
					this.columns[ this.priceIndex ].displayName = 'Price (high)'
					this.columns[ this.priceIndex ].propertyName = 'price'
					this.sortBy = propName
					this.sortAsc = false
				
				} else {
					// Switch directions
					this.sortAsc = true
					this.columns[ this.priceIndex ].displayName = 'Price (low)'
					this.nextPriceProp = 'frequency'
				}

				this.initialize()
				return
			}

			if( propName == 'frequency' ) {

				if( this.sortBy !== propName ) {
					// Arriving at "frequency"
					this.columns[ this.priceIndex ].displayName = 'Frequency (high)'
					this.columns[ this.priceIndex ].propertyName = 'frequency'
					this.sortBy = propName
					this.sortAsc = true
				
				} else {
					// Switch directions
					this.sortAsc = false
					this.columns[ this.priceIndex ].displayName = 'Frequency (low)'
					this.nextPriceProp = 'pricingDivisor'
				}

				this.initialize()
				return
			}

			if( propName == 'pricingDivisor' ) {
				
				if( this.sortBy !== propName ) {
					// Arriving at "pricingDivisor"
					this.columns[ this.priceIndex ].displayName = '# Bundled (high)'
					this.columns[ this.priceIndex ].propertyName = 'pricingDivisor'
					this.sortBy = propName
					this.sortAsc = false
				
				} else {
					// Switch directions
					this.sortAsc = true
					this.columns[ this.priceIndex ].displayName = '# Bundled (low)'
					this.nextPriceProp = 'price'
				}

				this.initialize()
				return
			}


			if( propName == 'firstName' ) {

				if( this.sortBy !== propName ) {
					// Arriving at "firstName"
					this.columns[ this.nameIndex ].displayName = 'First Name (A-Z)'
					this.columns[ this.nameIndex ].propertyName = 'firstName'
					this.sortBy = propName
					this.sortAsc = true
				
				} else {
					// Switch directions
					this.sortAsc = false
					this.columns[ this.nameIndex ].displayName = 'First Name (Z-A)'
					this.nextNameProp = 'lastName'
				}

				this.initialize()
				return
			}

			if( propName == 'lastName' ) {

				if( this.sortBy !== propName ) {
					// Arriving at "lastName"
					this.columns[ this.nameIndex ].displayName = 'Last Name (A-Z)'
					this.columns[ this.nameIndex ].propertyName = 'lastName'
					this.sortBy = propName
					this.sortAsc = true
				
				} else {
					// Switch directions
					this.sortAsc = false
					this.columns[ this.nameIndex ].displayName = 'Last Name (Z-A)'
					this.nextNameProp = 'userID'
				}

				this.initialize()
				return
			}

			if( propName == 'userID' ) {

				if( this.sortBy !== propName ) {
					// Arriving at "userID"
					this.columns[ this.nameIndex ].displayName = 'User ID (low)'
					this.columns[ this.nameIndex ].propertyName = 'userID'
					this.sortBy = propName
					this.sortAsc = true
				
				} else {
					// Switch directions
					this.sortAsc = false
					this.columns[ this.nameIndex ].displayName = 'User ID (high)'
					this.nextNameProp = 'firstName'
				}

				this.initialize()
				return
			}



			if( this.sortBy === propName ) this.sortAsc = !this.sortAsc
			else {
				this.sortBy = propName
				if( propName == 'firstName' || propName == 'lastName' || propName == 'licenseKey' || propName == 'status' ) this.sortAsc = true
				else this.sortAsc = false
			}

			this.initialize()
		},

	}


}
</script>

<style scoped>

</style>
