<template>
<StretchModal padding="1em" ref="modal" width="500px" :blur="true">
	<template #header>Add Modal Type</template>
	<template v-if="modalMessageType">
		<div><input type="text" v-model="modalMessageType.name" placeholder="Type Name" style="font-size: 1.2em;" /></div>
		<div class="mt-1">Default Priority: <input type="number" v-model.number="modalMessageType.defaultPriority" style="width: 6em;" /></div>
		<div class="mt-1"><ToggleButton v-model="modalMessageType.allowUserControl" /> Allow users to turn off</div>
		<div class="right"><button class="SquareBlueButton" :disabled="!modalMessageType.name" @click.stop="save()">Save</button></div>
	</template>
</StretchModal>
</template>



<script>
import ModalMessageAPI from '@/api/ModalMessageAPI.js'
import ModalMessageType from './ModalMessageType.js'

import StretchModal from '@/components/utilities/StretchModal.vue'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: 'EditModalMessageTypeModal',

	components: {
		StretchModal,
		ToggleButton,
	},
	

	props: {
		type: {
			type: Object,
			default: null
		},
	},


	data() {
		return {
			modalMessageType: null,
		}
	},


	created() { this.initialize() },
	watch: {
		type() { this.initialize() },
	},

	
	methods: {
		initialize() {
			this.modalMessageType = this.type ? this.type : new ModalMessageType()
		},


		save() {
			console.debug(this.modalMessageType)
			var promise = null
			if( this.modalMessageType.typeID ) promise = ModalMessageAPI.editType( this.modalMessageType.export() )
			else promise = ModalMessageAPI.createType( this.modalMessageType.export() )
			
			promise
			.then( () => {
				this.$refs.modal.close()
				this.$emit('save')
			})
			.catch( err => {
				alert("There was an error saving your ModalMessage Type: \n\n" + err)
			})
		},


		// Called Externally
		open() {
			this.initialize()
			this.$refs.modal.open()
		},
	}

}
</script>



<style scoped>

</style>