<template>
<section>

	<div v-if="showTotal && totals" class="my-4">
		<div class="flex-row flex-justify-center flex-align-center flex-gap-2">
			<div v-if="totals.totalTime !== undefined" class="center">
				<div class="bigDigits" v-html="Math.hhmmss(totals.totalTime)" />
				<div>Time</div>
			</div>
			<div class="bigDigits">/</div>
			<div v-if="totals.numNodes !== undefined" class="center">
				<div class="bigDigits" v-html="totals.numNodes" />
				<div>{{ lessonTitle }}</div>
			</div>
			<div class="bigDigits">=</div>
			<div v-if="totals.avgTime !== undefined" class="center">
				<div class="bigDigits" v-html="Math.hhmmss(totals.avgTime)" />
				<div>avg per {{ lessonTitle }}</div>
			</div>
		</div>
	</div>

	<div v-if="itemsCompleted.size || itemsWorkedOn.size" class="mt-3 flex-row flex-align-start flex-justify-around flex-wrap">
		<div>
			<div class="center title">{{ itemsCompleted.size }} {{ lessonTitle }} Completed</div>
			<LessonWidget v-for="node in itemsCompleted.values()" :node="node" :key="node.id" @openNode="openNode( node.id )" />
			<div v-if="itemsCompleted.size == 0" class="NoResults" style="font-size: 1.25rem;">No {{ lessonTitle }} were completed<br>during the specified time period.</div>
		</div>
		<div>
			<div class="center title">{{ itemsWorkedOn.size }} Other {{ lessonTitle }} Worked On</div>
			<LessonWidget v-for="node in itemsWorkedOn.values()" :node="node" :key="node.id" @openNode="openNode( node.id )" />
			<div v-if="itemsWorkedOn.size == 0" class="NoResults" style="font-size: 1.25rem;">No additional {{ lessonTitle }} were worked on<br>during the specified time period.</div>
		</div>
	</div>
	<div v-else-if="loading" class="flex-row flex-justify-center"><span class="icon-spinner4 spin1 ml-05" style="font-size: 2em; color: var(--ekno-blue);" /></div>
  <div v-else-if="error" class="warning">Failed to get details</div>
  <div v-else class="NoResults">No Details</div>



	<!-- <StretchModal
		:showModal="activeOpenNode != null"
		@close="activeOpenNode = null"
		:scrollable="true"
		:closeIcon="true"
		:clickToClose="false"
		width="70vw"
		height="70vh"
		maxHeight="70vh"
		style="z-index: 12;"
	>
		<NodeView :activeNode="activeOpenNode" :courseID="courseID" :courseConfig="courseConfig" @selectQuestion="q => initTestQuestion(q)" />
	</StretchModal> -->

</section>
</template>



<script>
import CourseAPI from '@/api/CourseAPI.js'
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import StudentStatisticsRequest from './StudentStatisticsRequest.js'
// import TreeFactory from '@/features/CourseTree/models/TreeFactory.js'
// import { Config } from '@/Config.js'

import TreeNode from '@/features/CourseCore/Classes/TreeNode.js'
import MediaNode from '@/features/CourseCore/Classes/MediaNode.js'
import QuestionNode from '@/features/CourseCore/Classes/QuestionNode.js'

import LessonWidget from './LessonWidget.vue'
// import NodeView from '@/features/CohortDashboard/components/NodeView.vue'
// import StretchModal from '@/components/utilities/StretchModal.vue'

export default {
	name: 'LessonsList',


	components: {
		LessonWidget,
		// NodeView,
		// StretchModal,
	},


	props: {
		
		userID: Number,
		licenseKey: String,
		courseID: Number,
		
		questionsOnly: {
			type: Boolean,
      default: false,
		},

		videosOnly: {
			type: Boolean,
      default: false,
		},

		showTotal: {
			type: Boolean,
      default: true,
		},

		startDate: {
			type: Date,
			default: null
		},

		endDate: {
			type: Date,
			default: null
		},
	},


	data() {
		return {

			itemsCompleted: new Map(),
			itemsWorkedOn: new Map(),
			totals: null,
			loading: false,
			error: false,

			activeOpenNode: null,
			courseConfig: null,
		}
	},


	created() { this.initialize() },


	
	computed: {
		lessonTitle() {
			if( this.questionsOnly ) return 'Questions'
			if( this.videosOnly ) return 'Videos'
			return 'Lessons'
		}
	},



	watch: {
		startDate() { this.initialize() },
		endDate() { this.initialize() },
	},


	methods: {

		async initialize() {
			
			this.loading = true
			try {
				const req = new StudentStatisticsRequest( this.userID, this.licenseKey, this.startDate, this.endDate )
				
				var res = null
				if( this.questionsOnly ) res = await StudentStatisticsAPI.getQuestionsWorkedOn( req ) 
				else if( this.videosOnly ) res = await StudentStatisticsAPI.getVideosWorkedOn( req )
				else res = await StudentStatisticsAPI.getLessonsWorkedOn( req )
				
				const resCompleted = res.data.completed
				const resWorkedOn = res.data.timed

				this.itemsCompleted = new Map()
				for( let item of resCompleted ) {
					if( item.type == 'question' ) this.itemsCompleted.set( item.id, QuestionNode.import( item ) )
					else if( item.type == 'media-video' ) this.itemsCompleted.set( item.id, MediaNode.import( item ) )
					else this.itemsCompleted.set( item.id, TreeNode.import( item ) )
				}

				this.itemsWorkedOn = new Map()
				for( let item of resWorkedOn ) {
					if( item.type == 'question' ) this.itemsWorkedOn.set( item.id, QuestionNode.import( item ) )
					else if( item.type == 'media-video' ) this.itemsWorkedOn.set( item.id, MediaNode.import( item ) )
					else this.itemsWorkedOn.set( item.id, TreeNode.import( item ) )
				}

				this.totals = res.data.totals
			
			} catch(e) {
				this.error = true
				throw e

			} finally {
				this.loading = false
			}

		},


		/**
		 * Open a preview of the node selected (most likely the question node)
		 */
		// async openNode( nodeID ) {
			
		// 	// Load courseConfig (once)
		// 	if( this.courseConfig === null ) {
		// 		let meta = await CourseAPI.getCourseMeta( this.licenseKey );
		// 		this.courseConfig = new Config(meta);
		// 	}

    //   // Fetch the selected node
		// 	let data = await CourseAPI.getNode( nodeID, this.courseID );
		// 	const treeFactory = new TreeFactory();
		// 	this.activeOpenNode = await treeFactory.newNode(data, null)

		// 	// Attach user's answer to the selected Question node
		// 	if( this.activeOpenNode.type == 'question' ) {
		// 		var sourceNode = this.itemsCompleted.get( this.activeOpenNode.id )
		// 		if( !sourceNode ) sourceNode = this.itemsWorkedOn.get( this.activeOpenNode.id )

		// 		if( sourceNode && sourceNode.userAnswer && this.activeOpenNode.answers.length ) {
		// 			for( var ans of this.activeOpenNode.answers ) {
		// 				if( ans.name == sourceNode.userAnswer ) this.activeOpenNode.userAnswer = ans
		// 			}
		// 		}
		// 	}

		// },



		// async initTestQuestion( question ) {
		// 	if( question === null ) return

		// 	var qSource = this.itemsCompleted.get( question.id )
		// 	if( !qSource ) qSource = this.itemsWorkedOn.get( question.id )
		// 	if( !qSource ) return
		// 	if( !qSource.userAnswer ) return

		// 	for( var ans of question.answers ) {
		// 		if( ans.name == qSource.userAnswer ) question.userAnswer = ans
		// 	}
		// }



	}


}
</script>



<style scoped>
.title {
	font-size: 1.5em;
  font-weight: bold;
	color: #555;
}
.bigDigits {
	font-size: 2em;
	font-weight: bold;
}
</style>