<template>
<div>
  <div style="display: inline-block;" class="link" @click.stop="openAffiliate()">{{ link }}</div>

  <StretchModal
    :width="'75%'"
    :height="'auto'"
    :scrollable="true"
    :clickToClose='false'
    ref="modal"
  >
    <template #header v-if="affiliate !== null">Affiliate: {{ affiliate.name }}</template>
    <AffiliateDetails v-if="affiliate !== null" :itemIn="affiliate" :isNew="false" :view="view" />
  </StretchModal>

</div>
</template>


<script>
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import Affiliate from './Affiliate.js'
import AffiliateDetails from './AffiliateDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'


export default {
  name: 'AffiliateDetailsModal',

  components: {
    AffiliateDetails,
    StretchModal
  },

  model: {
		prop: 'affiliateID',
		event: 'change'
	},

  props: {
    affiliateID: {
      type: Number,
      default: null
    },
    view: {
      type: String,
      default: 'orders'
    },
    link: {
      type: String,
      default: 'Affiliate\'s Order History >'
    },
  },

  data() {
    return {
      nestedTitle: '',
      nestedComponent: null,
      nestedProps: () => {},
      affiliate: null,
    }
  },


  methods: {

    async openAffiliate() {
      const data = await SalesManagementAPI.getAffiliateByID( this.affiliateID )
      this.affiliate = Affiliate.import( data )
      this.$refs.modal.open()
    },


  }



}
</script>
