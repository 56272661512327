import { render, staticRenderFns } from "./GenericTagAssignDropdown.vue?vue&type=template&id=137f3d3d"
import script from "./GenericTagAssignDropdown.vue?vue&type=script&lang=js"
export * from "./GenericTagAssignDropdown.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports