import { render, staticRenderFns } from "./FileAttachments.vue?vue&type=template&id=6d30ce60&scoped=true"
import script from "./FileAttachments.vue?vue&type=script&lang=js"
export * from "./FileAttachments.vue?vue&type=script&lang=js"
import style0 from "./FileAttachments.vue?vue&type=style&index=0&id=6d30ce60&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d30ce60",
  null
  
)

export default component.exports