<template>
<section>

	<ApexChartGraph
		v-if="graph"
		:graph="graph"
		type="bar"
		:stacked="true"
		:colors="['#1cba00', '#b1ff87']"
		:dataLabels="false"
		height="300px"
	/>
	<div v-else-if="loadingGraph" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
  <div v-else-if="graphError" class="warning">Failed to get graph</div>
  <div v-else class="NoResults">No Graph</div>


	<LessonsList :userID="userID" :licenseKey="licenseKey" :startDate="startDate" :endDate="endDate" />


</section>
</template>



<script>
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import StudentStatisticsRequest from './StudentStatisticsRequest.js'
import StudentStatisticsGraphRequest from './StudentStatisticsGraphRequest.js'
import GraphHelper from './GraphHelper.js'
import TreeNode from '@/features/CourseCore/Classes/TreeNode.js'
import MediaNode from '@/features/CourseCore/Classes/MediaNode.js'
import QuestionNode from '@/features/CourseCore/Classes/QuestionNode.js'

import ApexChartGraph from '@/components/ApexChartGraph.vue'
import LessonsList from './LessonsList.vue'

export default {
	name: 'LessonDetails',


	components: {
		ApexChartGraph,
		LessonsList,
	},


	props: {
		userID: Number,
		licenseKey: String,
		startDate: {
			type: Date,
			default: null
		},
		endDate: {
			type: Date,
			default: null
		},
	},


	data() {
		return {
			graph: null,
			loadingGraph: false,
			graphError: false,

			itemsCompleted: [],
			itemsWorkedOn: [],
			loadingDetails: false,
			detailsError: false,
		}
	},


	created() { this.initialize() },


	watch: {
		startDate() { this.initialize() },
		endDate() { this.initialize() },
	},


	methods: {
		initialize() {
			this.getGraph()
			this.getDetails()
		},


		async getGraph() {
			this.graph = null
			this.graphError = false
			this.loadingGraph = true
			try {
				const graphReq = new StudentStatisticsGraphRequest( this.userID, this.licenseKey, this.startDate, this.endDate )
				const graphRes = await StudentStatisticsAPI.getLessonsGraph( graphReq )
				this.graph = graphRes.data
	
				GraphHelper.generateTimeLabels( this.graph )

			} catch(e) {
				this.graphError = true
				throw e

			} finally {
				this.loadingGraph = false
			}

		},


		async getDetails() {
			this.loadingDetails = true
			try {
				const req = new StudentStatisticsRequest( this.userID, this.licenseKey, this.startDate, this.endDate )
				const res = await StudentStatisticsAPI.getLessonsWorkedOn( req )
				const resCompleted = res.data.completed
				const resWorkedOn = res.data.timed

				this.itemsCompleted = []
				for( let item of resCompleted ) {
					if( item.type == 'question' ) this.itemsCompleted.push( QuestionNode.import( item ) )
					else if( item.type == 'media-video' ) this.itemsCompleted.push( MediaNode.import( item ) )
					else this.itemsCompleted.push( TreeNode.import( item ) )
				}

				this.itemsWorkedOn = []
				for( let item of resWorkedOn ) {
					if( item.type == 'question' ) this.itemsWorkedOn.push( QuestionNode.import( item ) )
					else if( item.type == 'media-video' ) this.itemsWorkedOn.push( MediaNode.import( item ) )
					else this.itemsWorkedOn.push( TreeNode.import( item ) )
				}
			
			} catch(e) {
				this.detailsError = true
				throw e

			} finally {
				this.loadingDetails = false
			}
		}
	}

}
</script>



<style scoped>
.title {
	font-weight: bold;
  font-size: 20px;
}
</style>