<template>
<section>

  <div class="flex-row flex-align-center flex-justify-end py-05">
    <button class='button' @click='newRole()'>New Role</button>
  </div>
  
  <div>
    <ObjectTable
      :Source="roles.objects"
      :Columns='columns'
      Deletable
      @edit="editRole"
      @delete='deleteRole'
      ref="objectTable"
    />

    <StretchModal ref="detailsModal" :clickToClose="false" :padding="'1em'" @close="$refs.objectTable.deselect()">
      <template #header>Role Details</template>
      <RoleDetails :Role="currentRole" @added='selectRole' />
    </StretchModal>

    <ConfirmDialog :show="itemToDelete !== null" @cancel="itemToDelete = null" @confirm="confirmDelete()">
      <div>Are you sure you want to delete this login screen?</div>
      <div style="font-size: 1.25em; font-weight: bold; margin: 0.25em 0;">{{ itemToDelete ? itemToDelete.name : '' }}</div>
      <div><strong>This action CANNOT be undone!</strong></div>
    </ConfirmDialog>
  </div>

</section>
</template>

<script>
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import ObjectTable from "@/components/utilities/ObjectTable"
import RoleDetails from "./RoleDetails"
import Role from "@/models/Role"

import RolesAPI from "@/api/RolesAPI"

export default {
  name: "roles-list",

  data() {
    return {
      displayNames: ['ID', 'Name'],
      propertyNames: ['id', 'name'],
      columns: [
        {
          displayName: 'ID',
          propertyName: 'id'
        },
        {
          displayName: 'Name',
          propertyName: 'name'
        }
      ],
      currentRole: null,
      itemToDelete: null,
    }
  },

  async created() {
    this.$store.state.Roles.objects = await RolesAPI.getRoles()
  },

  computed: {
    roles() { return this.$store.state.Roles; }
  },

  methods: {
    selectRole(role) {
      this.currentRole = role;
    },

    newRole() {
      this.currentRole = new Role();
      this.$refs.detailsModal.open()
    },

    editRole(role) {
      this.currentRole = role;
      this.$refs.detailsModal.open()
    },

    deleteRole(role) { this.itemToDelete = role },

    async confirmDelete() {
      const role = this.itemToDelete
      try {
        await RolesAPI.deleteRole(role.id);
        this.$store.state.Roles.remove(role);
      } catch (e) {
        console.error(e);
        alert("Error communicating with server; changes not saved.");
      }
      this.itemToDelete = null
    }
  },

  components: {
    StretchModal,
    ConfirmDialog,
    ObjectTable,
    RoleDetails,
  }
}
</script>
