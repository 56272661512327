/**
 * Extends the QuestionNode class; this class is for the answers to interactive questions
 *
 * @author: Jordan Hildebrandt
 *
 */

// import MediaNode from './MediaNode.js';
import TreeNode from './TreeNode.js';


class AnswerNode extends TreeNode {

	constructor(name = '', type = 'answer') {
		super(null, name, null, type);
		this.embedded = true;
	}

}

export default AnswerNode;
