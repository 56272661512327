<template>
  <SearchDropdown
    :placeholder="placeholder"
    :DisplayFunction="displayFunction"
    :InitialValue="initialValue || displayFunction(customer)"
    :ObjKey="'id'"
    :width="width"
    :browseIcon="true"
		:pages="pages"
    :externalResults="items"
    :dropUp="dropUp"
    @selected="item => selectCustomer(item)"
    @search="(txt, pg) => getCustomerList(txt, pg)"
		@confirm="txt => emitSearchList(txt)"
    @none="selectCustomer(null)"
    ref="search"
  />
</template>


<script>
import SalesManagementAPI from '@/api/SalesManagementAPI.js';
import CustomerListRequest from '@/api/CustomerListRequest.js';
import Customer from '@/features/SalesManagement/Customers/Customer.js';
import SearchDropdown from '@/components/utilities/SearchDropdown.vue';

export default {
  name: 'CustomerSearchDropdown',

  components: {
    SearchDropdown,
  },


  props: {
    customer: {
      type: Object,
      default: null
    },

    width: {
      type: String,
      default: '50%'
    },

    placeholder: {
      type: String,
      default: 'Search Customers'
    },

    initialValue: {
      type: String,
      default: null
    },

		dropUp: {
			type: Boolean,
			default: false
		},

  },


  model: {
    prop: 'customer',
    event: 'updateCustomer'
  },

  data() {
    return {
      items: [],
      pages: 1,
    }
  },

  methods: {

    selectCustomer(item) {
      this.$emit('updateCustomer', item);
    },


    clear() { this.$refs.search.clear() },


    displayFunction(obj) {
      if(!obj) return '';
      var str = obj.name
      if( obj.city || obj.state ) str += ` (${obj.city || ''}, ${obj.state || ''})`
      return str
    },



    async emitSearchList( searchTerm ) {
			const data = await this.search( searchTerm, 1, 0 )
			const list = []
			for( let item of data.data ) list.push( Customer.import( item ) )
			this.$emit( 'matches', list, searchTerm )
		},


		async search( searchTerm, page = 1, pageSize = 25 ) {
			return await SalesManagementAPI.getCustomers( new CustomerListRequest( 'name', true, page, pageSize, searchTerm ) )
		},


    async getCustomerList( searchTerm, page = 1 ) {
      const data = await this.search( searchTerm, page )

      this.items = []
      if( !data || !data.data ) return
      this.pages = data.pages
      for( let item of data.data ) this.items.push( Customer.import(item) )
    },
  }


}
</script>
