<template>
    <div>
        <div class="data-container pointer flex-row flex-justify-between flex-align-center" @click.stop="showUserDetails()">
            <div style="padding-top: 2px; padding-bottom: 2px">
                <div>{{user.id}}: {{ user.firstName }} {{ user.lastName }}</div>
                <p style="margin: 0">{{ user.email }}</p>
            </div>
            <div>
                <span
                    v-if="user.children.length === 0"
                    class="icon-bin userButton"
                    id="deleteUserButton"
                    @click.stop="emitDeleteUser"
                />
                <span
                    class="icon-plus userButton"
                    id="addUserButton"
                    @click.stop="showAddUser = true"
                />
            </div>
        </div>

        <div
            v-if="showAddUser"
            class="userTreeChild"
            style="padding-bottom: 5px"
        >
            <UserSearchDropdown
                v-model="userToAdd"
                style="margin-bottom: 2px"
            />
        <!-- <button v-if="userToAdd" @click="addUser">Add User</button> -->
        <span class="link ml-1" @click="showAddUser = false">cancel</span>
        </div>

        <UserTreeItem
            v-for="(child, index) in user.children"
            :key="index"
            :user="child"
            :customer="customer"
            @deleteUser="deleteUser"
            class="userTreeChild"
        />


        <UserDetailsModal :user="fullUser" ref="userDetails" width="80%" height="90%" />



    </div>
</template>



<script>
import SalesManagementAPI from "@/api/SalesManagementAPI.js"
import CustomerUser from "./CustomerUser.js"
import User from '@/features/Users/User.js'
import UserAPI from '@/api/UserAPI.js'

import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'

export default {
    name: "UserTreeItem",

    components: {
        UserSearchDropdown,
        UserDetailsModal: () => import('@/features/Users/UserDetailsModal.vue'),
    },

    props: {
        user: CustomerUser,
        customer: Object,
    },

    data() {
        return {
            showAddUser: false,
            userToAdd: null,
			fullUser: null,
        };
    },



    watch: {
        userToAdd( value ) {
            if( !value ) return
            this.addUser()
        },
    },




    methods: {
        addUser() {
            this.showAddUser = false;
            SalesManagementAPI.createUserRelationship(
                this.user.id,
                this.userToAdd.userID,
                this.customer.id
            );

            let newChild = CustomerUser.import({
                id: this.userToAdd.userID,
                firstName: this.userToAdd.firstName,
                lastName: this.userToAdd.lastName,
                email: this.userToAdd.emailAddress,
            });

            this.user.children.push(newChild);

            this.userToAdd = null;
        },


        emitDeleteUser() {
            this.$emit("deleteUser", this.user);
        },


        async deleteUser(child) {
            const choice = confirm(
                `Remove user ${child.id} - ${child.firstName} ${child.lastName}?`
            );
            if (!choice) return;

            const childIndex = this.user.children.indexOf(child);

            await SalesManagementAPI.removeUserFromTree(
                this.user.id,
                this.user.children[childIndex].id,
                this.customer.id
            );
            this.user.children.splice(childIndex, 1);
        },


				showUserDetails() {
					if(!this.fullUser) {
						UserAPI.getUser(this.user.id).then(res => {
							this.fullUser = User.import(res)
						}).catch( e => {
							console.error(e)
							this.fullUser = null
							return
						})
					}

					this.$refs.userDetails.open();
				},
    },
};
</script>

<style>
#addUserButton {
    margin-left: 5px;
    padding-right: 5px;
    font-size: 12px;
}

#addUserButton:hover {
    color: rgb(0, 175, 0);
}

#deleteUserButton:hover {
    color: red;
}

.userButton {
    visibility: hidden;
}

.data-container:hover .userButton /* #deleteButon */ {
    visibility: visible;
}

.data-container:hover {
    background-color: rgb(216, 216, 216);
}

.userTreeChild {
    border-left: 1px solid #ccc;
    padding-left: 12px;
}
</style>
