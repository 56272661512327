import API from './API.js'
import PaginatedRequest from './PaginatedRequest.js'
import PaginatedResponse from './PaginatedResponse.js'


class SystemToolsAPI extends API {


	static controllerName = 'SystemToolsAPI'


	static async runCronjob( commandName ) {
		return this.newGet( 'runCronjob', [commandName] )
	}

	static async runLongJob( commandName ) {
		return this.newGet( 'runLongJob', [commandName] )
	}

	
	static async generateUserSessionSummaries() {
		return this.newGet( 'generateUserSessionSummaries' )
	}


	static async verifyUserTracking( studentIDs = null, licenseKey = null ) {
		if( studentIDs || licenseKey ) return this.newPost( 'verifyUserTracking', [studentIDs, licenseKey] )
	}


	static async verifyCourseTracking( courseID, page = 1, pageSize = 100 ) {
		const req = new PaginatedRequest('serial_key', true, page, pageSize )
		const res = await this.newPost( 'verifyTracking', [ courseID ], req )
		return PaginatedResponse.import( res.data )
	}


	static async verifyAllTracking( courseID, customerID = null, activeOnly = true ) {
		return this.newGet('verifyAllTracking', [ courseID, customerID, activeOnly ] )
	}

	static async verifyAllPlans( courseID, customerID = null, activeOnly = true ) {
		return this.newGet('verifyAllPlans', [ courseID, customerID, activeOnly ] )
	}

	static async setPlanRegenFlag( planID, value = false ) {
		return this.newGet('setPlanRegenFlag', [planID, value] )
	}


	static async initializeUserMediaIndex( userID, licenseKey = null ) {
		return await this.newGet( 'initializeUserMediaIndex', [userID, licenseKey] )
	}

	static async initializeMediaIndex( courseID = null ) {
		return await this.newGet( 'initializeMediaIndex', [courseID] )
	}


}
export default SystemToolsAPI