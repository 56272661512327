<template>
<div>

	<section class='Tab'>
		<button @click="mode='general'" :class="{'active':mode==='general'}">General</button>
		<button v-if="newUser.userID" @click="mode='licenses'" :class="{'active':mode==='licenses'}">Licenses</button>
		<button v-if="newUser.userID" @click="mode='pushReports'" :class="{'active':mode==='pushReports'}">Push Reports</button>

		<button v-if="newUser.userID" @click="mode='orders'" class="ml-1" :class="{'active':mode==='orders'}">Orders</button>
		<button v-if="newUser.userID" @click="mode='roles'" :class="{'active':mode==='roles'}">Roles</button>
		<button v-if="newUser.userID" @click="mode='reviews'" :class="{'active':mode==='reviews'}">Reviews</button>

		<button v-if="newUser.userID" @click="mode='logins'" class="ml-1" :class="{'active':mode==='logins'}">Logins</button>
		<button v-if="newUser.userID" @click="mode='sessions'" :class="{'active':mode==='sessions'}">Study Sessions</button>
	</section>


	<div class="px-1 pb-1">

		<GeneralUserDetails
			v-if='mode === "general"'
			:User="User"
			:NewUser='newUser'
			ref="generalDetails"
		/>

		<div v-else-if='mode === "licenses" && User'>
			<LicensesList
				v-if='User.userID'
				:User="User"
				:StudentStatistics="true"
				:isArchived="false"
			/>
			<p v-else>Please save your changes to the user first</p>
		</div>

		<PushReportSettings v-else-if="mode === 'pushReports'" :user="User" />
		<OrderSearchPane v-else-if="mode === 'orders'" :userID="User.userID" :showDashboard="false" />
		<UserRoles v-else-if='mode === "roles"' :userID="User.userID" />
		<ReviewsDashboard v-else-if="mode === 'reviews'" :userID="User.userID" />
		<LoginList v-else-if="mode === 'logins'" :userID="User.userID" />
		<UserSessionList v-else-if="mode === 'sessions'" :userID="User.userID" />


	</div>
</div>
</template>

<script>
import LicensesList from '@/features/Licenses/LicensesList.vue'
import UserRoles from '@/features/Roles/UserRoles.vue'
import GeneralUserDetails from '@/features/Users/GeneralUserDetails.vue'
import OrderSearchPane from '@/features/orders/OrderSearchPane.vue'
import PushReportSettings from './PushReportSettings.vue'
import ReviewsDashboard from '@/features/Reviews/ReviewsDashboard.vue'
import LoginList from '@/features/LoginTracking/LoginList.vue'
import UserSessionList from '@/features/LoginTracking/UserSessionList.vue'

import User from '@/features/Users/User.js'

export default {
	name: "UserDetails",

	components: {
		LicensesList,
		UserRoles,
		GeneralUserDetails,
		OrderSearchPane,
		PushReportSettings,
		ReviewsDashboard,
		LoginList,
		UserSessionList,
	},

	props: {
		User: Object,

		InitialView: {
			type: String,
			default: 'general'
		}
	},

	data() {
		return {
			newUser: new User(),
			mode: 'general',
		}
	},


	computed: {
		changesMade() {
			return (this.$refs.generalDetails && this.$refs.generalDetails.changesMade) || false
		}
	},


	watch: {
		User() {
			console.debug("Updating User properties... (does this work???)")
			Object.assign(this.newUser, this.User)
		},
	},

	async created() {
		Object.assign(this.newUser, this.User);

		this.mode = this.InitialView ? this.InitialView : this.mode;
	},



}
</script>
