<template>
<section style="width: 100%;">
  <p v-if="isTemplate">Storefront Block</p>

  <!-- Name + template slug -->
  <div style="width: 100%;">
    <div class="flex-row flex-justify-between">
      <input v-if="isTemplate" type="text" v-model="section.name" placeholder="Block Label (admin)" />
      <div v-else>{{section.name}}<span style="color: #888; font-size: 0.8em;"> - Storefront</span></div>
      
      <div v-if="!isTemplate && !isDefault" id="revert" @click="revert()"><span class="icon-undo" /> Revert</div>
    </div>

    <select v-model="section.slug" @change="update">
      <option v-for="sf in storefronts" :key="sf.slug" :value="sf.slug">{{ sf.title }} ({{ sf.slug }})</option>
    </select>
  </div>

  <!-- Title & Subtitle Display Options -->
  <div class="my-2 pl-05 border-left">
    <div class="bold mb-05">Title & Subtitle Display</div>
    <div class="mt-05 flex-row flex-align-center flex-gap-05">
      <ToggleButton v-model="section.showTitle" :sync="true" />
      <span>Show storefront title</span>
      <div v-if="(isTemplate && section.showTitle !== true) || (!isTemplate && templateSection && section.showTitle !== templateSection.showTitle)" id="revert" @click="revert('showTitle')" ><span class="icon-undo" /> Revert</div>
    </div>
    <div v-if="section.showTitle" class="ml-1 mt-05 flex-row flex-align-center flex-gap-05">
      <ToggleButton v-model="section.showSlashes" :sync="true" />
      <span>Show decorative slashes around storefront title</span>
      <div v-if="(isTemplate && section.showSlashes !== true) || (!isTemplate && templateSection && section.showSlashes !== templateSection.showSlashes)" id="revert" @click="revert('showSlashes')" ><span class="icon-undo" /> Revert</div>
    </div>
    
    <div class="mt-1 flex-row flex-align-center flex-gap-05">
      <ToggleButton v-model="section.showGroupTitle" :sync="true" />
      <span>Show Product Group Title</span>
      <div v-if="(isTemplate && section.showGroupTitle !== true) || (!isTemplate && templateSection && section.showGroupTitle !== templateSection.showGroupTitle)" id="revert" @click="revert('showGroupTitle')" ><span class="icon-undo" /> Revert</div>
    </div>
    <div class="mt-05 flex-row flex-align-center flex-gap-05">
      <ToggleButton v-model="section.showGroupDescription" :sync="true" />
      <span>Show Product Group Description</span>
      <div v-if="(isTemplate && section.showGroupDescription !== true) || (!isTemplate && templateSection && section.showGroupDescription !== templateSection.showGroupDescription)" id="revert" @click="revert('showGroupDescription')" ><span class="icon-undo" /> Revert</div>
    </div>
  </div>
  

  <!-- Pricing display options -->
  <div class="my-2 pl-05 border-left">
    <div class="bold mb-05">Price Settings</div>
    <div class="mt-05 flex-row flex-align-center flex-gap-05">
      <ToggleButton v-model="showComparePrograms" :sync="true" />
      <span>Show "Compare All Programs" button &amp; popup</span>
      <div v-if="(isTemplate && section.showComparePrograms !== null) || (!isTemplate && templateSection && section.showComparePrograms !== templateSection.showComparePrograms)" id="revert" @click="revert('showComparePrograms')" ><span class="icon-undo" /> Revert</div>
    </div>
    <div class="mt-05 flex-row flex-align-center flex-gap-05">
      <ToggleButton v-model="quickSelectPrices" :sync="true" />
      <span>Show price selector on cards (quick select)</span>
      <div v-if="(isTemplate && section.quickSelectPrices !== null) || (!isTemplate && templateSection && section.quickSelectPrices !== templateSection.quickSelectPrices)" id="revert" @click="revert('quickSelectPrices')" ><span class="icon-undo" /> Revert</div>
    </div>
  </div>
  
  <!-- Slider Mode -->
  <div class="my-2 pl-05 border-left">
    <div class="bold mb-05">Slider Mode Settings</div>
    <div class="flex-row flex-align-center flex-gap-05">
      <ToggleButton v-model="sliderMode" :sync="true" />
      <span>Slider Mode</span>
      <div v-if="(isTemplate && section.sliderMode !== null) || (!isTemplate && templateSection && section.sliderMode !== templateSection.sliderMode)" id="revert" @click="revert('sliderMode')" ><span class="icon-undo" /> Revert</div>
    </div>
    <div class="ml-1 mt-05 flex-row flex-align-center flex-gap-05">
      <ToggleButton v-model="openByDefault" :sync="true" :disabled="!sliderMode" />
      <span>Open by default</span>
      <div v-if="(isTemplate && section.openByDefault !== null) || (!isTemplate && templateSection && section.openByDefault !== templateSection.openByDefault)" id="revert" @click="revert('openByDefault')" ><span class="icon-undo" /> Revert</div>
    </div>
    <div v-if="sliderMode && openByDefault" class="ml-1 mt-05">
      Default Slide: 
      <select v-if="storefront" v-model="defaultSlideIndex">
        <option v-for="( pg, idx ) in storefront.productGroups" :key="pg.title" :value="idx">&ldquo;{{ pg.title }}&rdquo; {{ pg.adminLabel ? `- ${ pg.adminLabel }` : '' }}</option>
      </select>
      <div v-if="(isTemplate && section.defaultSlideIndex !== null) || (!isTemplate && templateSection && section.defaultSlideIndex !== templateSection.defaultSlideIndex)" id="revert" @click="revert('defaultSlideIndex')" ><span class="icon-undo" /> Revert</div>
    </div>
  </div>

  <!-- Default Coupon -->
  <div class="my-2 pl-05 border-left">
    <div class="bold mb-05">Coupon Code Settings</div>
    <div class="flex-row flex-align-center flex-gap-05">
      <ToggleButton v-model="autofillDefaultCoupon" :sync="true" />
      <span>Autofill default coupon</span>
      <div v-if="(isTemplate && section.autofillDefaultCoupon !== null) || (!isTemplate && templateSection && section.autofillDefaultCoupon !== templateSection.autofillDefaultCoupon)" id="revert" @click="revert('autofillDefaultCoupon')" ><span class="icon-undo" /> Revert</div>
    </div>
    <div class="my-05 flex-row flex-align-center flex-gap-05">
      <ToggleButton v-model="section.showCouponCode" :sync="true" />
      <span>Show Coupon Code</span>
      <div v-if="(isTemplate && section.showCouponCode !== true) || (!isTemplate && templateSection && section.showCouponCode !== templateSection.showCouponCode)" id="revert" @click="revert('showCouponCode')" ><span class="icon-undo" /> Revert</div>
    </div>
    <div class="flex-row flex-align-center flex-gap-05 mt-05">
      <span>Default Coupon Code:</span>
      <input type="text" v-model="defaultCoupon" :placeholder="defaultCouponSource" class="flex-grow" style="max-width: 30em;" />
      <div v-if="(isTemplate && section.defaultCoupon !== null) || (!isTemplate && templateSection && section.defaultCoupon !== templateSection.defaultCoupon)" id="revert" @click="revert('defaultCoupon')" ><span class="icon-undo" /> Revert</div>
    </div>
  </div>


  <div class="center"><button @click="$refs.storefrontModal.open()">Edit Storefront Details</button></div>



  <StretchModal ref="storefrontModal" padding="1em" maxHeight="90%" maxWidth="1200px">
    <template #header>Storefront Details: {{ storefront.title }}</template>
    <StorefrontDetails :storefrontIn="storefront" :isNew="false" />
  </StretchModal>


</section>
</template>


<script>
import StorefrontAPI from "@/api/StorefrontAPI.js"
import Storefront from "@/features/Storefronts/Storefront.js"

import StorefrontDetails from '@/features/Storefronts/StorefrontDetails.vue'
import { ToggleButton } from 'vue-js-toggle-button'
import StretchModal from '@/components/utilities/StretchModal.vue'

export default {
  name: 'LandingPageStorefrontSection',


  components: {
    StorefrontDetails,
    ToggleButton,
    StretchModal,
  },


  props: {
    section: Object,

    templateSection: {
      type: Object,
      default: null
    },

    isTemplate: {
      type: Boolean,
      default: true
    },

    isDefault: {
      type: Boolean,
      default: false
    },

  },


  data() {
    return {
      storefronts: [],
      storefront: null,
    }
  },


  computed: {

    sliderMode: {
      get() { return this.section && this.section.sliderMode !== null ? this.section.sliderMode : this.templateSection && this.templateSection.sliderMode !== null ? this.templateSection.sliderMode : this.storefront ? this.storefront.sliderMode : null },
      set( value ) { this.section.sliderMode = value }
    },
    openByDefault: {
      get() { return this.section && this.section.openByDefault !== null ? this.section.openByDefault : this.templateSection && this.templateSection.openByDefault !== null ? this.templateSection.openByDefault : this.storefront ? this.storefront.openByDefault : null },
      set( value ) { this.section.openByDefault = value }
    },
    defaultSlideIndex: {
      get() { return this.section && this.section.defaultSlideIndex !== null ? this.section.defaultSlideIndex : this.templateSection && this.templateSection.defaultSlideIndex !== null ? this.templateSection.defaultSlideIndex : this.storefront ? this.storefront.defaultSlideIndex : null },
      set( value ) { this.section.defaultSlideIndex = value }
    },

    showComparePrograms: {
      get() { return this.section && this.section.showComparePrograms !== null ? this.section.showComparePrograms : this.templateSection && this.templateSection.showComparePrograms !== null ? this.templateSection.showComparePrograms : this.storefront ? this.storefront.showComparePrograms : null },
      set( value ) { this.section.showComparePrograms = value }
    },
    quickSelectPrices: {
      get() { return this.section && this.section.quickSelectPrices !== null ? this.section.quickSelectPrices : this.templateSection && this.templateSection.quickSelectPrices !== null ? this.templateSection.quickSelectPrices : this.storefront ? this.storefront.quickSelectPrices : null },
      set( value ) { this.section.quickSelectPrices = value }
    },

    autofillDefaultCoupon: {
      get() { return this.section && this.section.autofillDefaultCoupon !== null ? this.section.autofillDefaultCoupon : this.templateSection && this.templateSection.autofillDefaultCoupon !== null ? this.templateSection.autofillDefaultCoupon : this.storefront ? this.storefront.autofillDefaultCoupon : null },
      set( value ) { this.section.autofillDefaultCoupon = value }
    },

    defaultCoupon: {
      get() { return this.section && this.section.defaultCoupon !== null ? this.section.defaultCoupon : null },
      set( value ) { this.section.defaultCoupon = value || null }
    },
    defaultCouponSource() {
      var cpn = null
      var src = 'N/A'

      if( this.storefront && this.storefront.defaultCoupon !== null ) {
        cpn = this.storefront.defaultCoupon
        src = 'Storefront'
      }
      if( this.templateSection && this.templateSection.defaultCoupon !== null ) {
        cpn = this.templateSection.defaultCoupon
        src = 'Template'
      }
      if( this.section && this.section.defaultCoupon !== null ) {
        cpn = this.section.defaultCoupon
        src = 'Affiliate'
      }

      return `${cpn ? cpn : ''} - from ${src}`
    }

  },


  watch: {
    storefront() { this.update() },
    'section.slug'() { this.loadStorefront() },
    'section.sliderMode'() { this.update() },
    'section.openByDefault'() { this.update() },
    'section.defaultSlideIndex'() { this.update() },
    'section.showComparePrograms'() { this.update() },
    'section.quickSelectPrices'() { this.update() },
    'section.autofillDefaultCoupon'() { this.update() },
    'section.defaultCoupon'() { this.update() },
    'section.showSlashes'() { this.update() },
  },


  created() { this.initialize() },


  methods: {
    update(e) { this.$emit('update'); },
    revert( propertyName = null ) {
      
      if( propertyName ) {
        this.section[ propertyName ] = this.templateSection ? this.templateSection[ propertyName ] : null
        return
      }

      this.$emit('revert')
    },


    async initialize() {
      
      // Get list of available storefronts
      this.storefronts = []
      const allData = await StorefrontAPI.getStorefronts()
      for( var item of allData ) this.storefronts.push( Storefront.import( item ) )
      
      if( !this.section.slug ) return

      this.loadStorefront()
    },

    async loadStorefront() {
      const sfData = await StorefrontAPI.getStorefront( this.section.slug )
      this.storefront = Storefront.import( sfData )
    },

  }
}
</script>


<style scoped>
input, textarea { padding: 0.5em !important; }

.default {
  color: #777;
}
#revert {
  cursor: pointer;
}
</style>
