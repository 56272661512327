import API from "./API";
import {base_url} from "@/Config";



export default class MediaLibraryAPI extends API {

  
  static controllerName = 'SiteAdmin/MediaLibraryAPI'


  static async getMedia( path, search ) {
    return super.newPost( 'getMedia', [], { path, search } )
  }



  static checkFileExists( path ) {
    return super.newPost( 'checkFileExists', [], { path } )
  }  
  
  static uploadFileBase64Chunk( path, chunk ) {
    return super.newPost( 'uploadFileBase64Chunk', [], { path, chunk } )
  }



  static async renameMedia(path, oldName, newName) {
    await super.newPost( 'renameMedia', [], { path, oldName, newName } )
  }

  static async deleteMedia(path, name) {
    await super.newPost( 'deleteMedia', [], { path, name } )
  }


  static async getProductImages(sku) {
    if (!sku) {
      throw new Error('Missing property "sku".');
    }

    const url = `${base_url}/SiteAdmin/MediaLibraryAPI/getProductImages/${sku}`;
    return await super.get(url);
  }


  static getSubfolders( path ) {
    return super.newPost( 'getSubfolders', [], { path } )
  }

  
  static createFolder( path ) {
    return super.newPost( 'createFolder', [], { path } )
  }


  static deleteFolder( path ) {
    return super.newPost( 'deleteFolder', [], { path } )
  }
}
