import Role from './Role'


/**
 * Contains and manages [roles]{@link module:Roles.Role}
 *
 * @memberof module:Roles
 * @see module:Roles.Role
 */
class RoleManager {
  /**
   * @param {Array<Role>} roles - the list of roles that have been assigned to the user
   */
  constructor(roles = []) {
    this.roles = roles;
  }


  /**
   * Checks whether the given [feature]{@link module:Roles.Feature} or features are
   * contained in a [role]{@link module:Roles.Role} in this role manager.
   * Every provided feature must be found within this role manager for this function
   * to return `true`. For more information regarding roles, see [`Role.hasAccess()`]{@link module:Roles.Role#hasAccess}. For
   * more information regarding features, see [`Feature.hasAccess()`]{@link module:Roles.Feature#hasAccess}.
   *
   * @param {(string | Array<string>)} feature - the feature or features to check
   * @param {string} [licenseKey=""] - key for the license for which these feature(s) must be granted
   * @param {boolean} [strictMode=true] - whether to operate in strict mode.
   * if run in non-strict mode, this function returns `true` if `feature` is
   * granted on any license, regardless of whether the grant is global or per-license
   *
   * @returns {boolean} `true` if this role manager grants access to each feature in `features`.
   */
  hasAccess(feature, licenseKey = '', strictMode = true, print = false) {

    // init _features array
    let _features;
    if (typeof feature === 'string') _features = [ feature ] ;
    else if (feature instanceof Array) _features = feature.slice();
    else throw new Error('"feature" must be a string or an array');

    let featureIncluded;
    let tokens;
    for (let feature of _features) {
      // split feature into tokens array
      tokens = feature.split(':');

      featureIncluded = this.roles.some(role => role.hasAccess(tokens, licenseKey, strictMode, print));
      if (!featureIncluded) return false;
    }

    // all features were found in at least one role
    return true;
  }



  reset() { this.roles = [] }



  /**
   * Adds the given roles to `this.roles`.
   *
   * @param {Array<Role>} roles - the list of Role objects to add
   * @returns {void} Nothing.
   */
  addRoles(roles) {
    for (let role of roles) {
      if(role instanceof Role) this.roles.push(role);
      else this.roles.push(Role.import(role));
    }
  }



  getRoleNames() {
    let names = [];
    for(let role of this.roles) names.push(role.name);
    return names;
  }



  /**
   * Returns an array of licenseKey strings, representing the licenses this user
   * has the requested role on.
   *
   * @param {string} roleName - the string name of the role
   * @returns {Array<String>} - Array of licenseKey strings.
   */
  getLicensesForRole(roleName) {
    const role = this.roles.find(elem => elem.name == roleName);
    if(!role) return null;
    return [...role.licenses];
  }



  /**
   * Returns an array of licenseKey strings, representing the licenses
   * that 1) have no student, and 2) this user can assign a student
   *
   * @param {array<License>} availableLicenses - an array of ALL License objects
   *  to which the user has access (via roles and/or slots).
   *  Needed in order to check whether the license is already assigned
   *  to a student, because Roles store only licenseKeys, NOT License objects.
   */
  getAssignableLicenses(availableLicenses) {
    console.debug("roleManager.getAssignableLicenses")
    if(!Array.isArray(availableLicenses)) return null

    const licenseKeys = []
    for(let role of this.roles) {
      for(let licenseKey of role.licenses) {

        let license = availableLicenses.find(item => item.licenseKey == licenseKey)
        if(!license) continue
        if(license.studentUser) continue

        if(!role.hasAccess(['modify_license','assign','student'], licenseKey)) continue
        if(!licenseKeys.includes(licenseKey)) licenseKeys.push(licenseKey)
      }
    }
    console.debug(licenseKeys)
    return licenseKeys
  }



  /**
   * Returns an array of RoleNames representing the roles the user has on the given license
   *
   * @param {string} licenseKey - the license key
   * @returns {Array<String>} - Array of RoleNames
   */
  getRolesForLicense(licenseKey) {
    const roles = []
    for(let role of this.roles) {
      if(role.licenses.includes(licenseKey)) roles.push(role.name)
    }
    return roles
  }



  /**
   * Returns an array of partial feature-strings that, when concatenated with
   * the `featureString` string, create valid feature grant strings.
   *
   * @param {string} featureString - the (partial) name of a feature: i.e "org:sub_user:add_role[:]"
   * @returns {Array<String>} - An array of right-hand portions to complete the featureString.
   */
  getSubFeatureGrants(featureString) {
    const tokens = featureString.split(':');

    var featureEndings = [];
    for(let role of this.roles) {
      featureEndings = featureEndings.concat( role.getSubFeatureGrants(tokens) )
    }

    return featureEndings;
  }



  getViewableRoleNames() {
    return this._getRightHandPartials('org:sub_user:view_role:');
  }


  getAssignableRoleNames() {
    return this._getRightHandPartials('org:sub_user:add_role:');
  }


  _getRightHandPartials(leftPartial) {
    const rightPartials = [];
    for(let role of this.roles) {
      for(let feature of role.features) {
        if( feature.name.startsWith(leftPartial) ) rightPartials.push( feature.name.substr(leftPartial.length) );
      }
    }

    // De-dupe & return
    return [...new Set(rightPartials)];
  }

}

export default RoleManager;
