import Exim from '@/models/Exim.js'


class ThresholdReportThreshold extends Exim {

	// Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
	vueCounter = 0


	minValue = null
	label = null
	description = null
		
	isHealthy = true
	interventionDescription = null


	static import( obj, skipProps = [] ) {
		const item = super.import( obj, skipProps )
		item.vueCounter = ++ThresholdReportThreshold.idCounter
		return item
	}


}

export default ThresholdReportThreshold