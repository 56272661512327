<template>
  <div>
    <p>The program contains data about college admissions, obtained from the US Department of Education.<br>
      Click below to update this data to match the latest available from US DOE.</p>
    <p>If the auto-updater service is working, then the data will be auto-updated on the first day of every month</p>
    <p>Please Note: This button does NOT use the auto-updater service, and performs its action SYNCHRONOUSLY with the browser request.  However, the request WILL continue to work in the background if you navigate away from this page or if the request times out.</p>
    <button class='button' @click='downloadLatestCollegeData' :disabled="this.updating">{{ this.updating ? "Updating..." : "Update College Data" }}</button>
  </div>
</template>


<script>
import CollegeAPI from '@/api/CollegeAPI.js';

export default {
  name: 'CollegeSettings',

  data() {
    return {
      updating: false,
    }
  },

  methods: {
    downloadLatestCollegeData() {
      this.updating = true;
      CollegeAPI.downloadLatestCollegeData()
      .then( () => {
        alert("Data updated successfully");
      }).catch( () => {
        alert("There was an ERROR updating college data");
      }).finally(() => {
        this.updating = false;
      });
    }
  }
}
</script>
