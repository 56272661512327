<template>
<section class="flex-column flex-align-center flex-justify-center flex-gap-2 h-100">
	<div>Welcome, {{ $store.state.user.firstName }}</div>
	<div class="font-size-2 bold">Dashboard Coming Soon</div>
</section>
</template>

<script>
export default {
	name: 'SiteDashboard',
}
</script>

<style>

</style>