<template>
<div>
  <section class="Tab">
		<button @click="mode = 'general'" :class="{ active: mode === 'general' }">General</button>
		<button @click="mode = 'variations'" :class="{ active: mode === 'variations' }">Course Variations</button>
		<button @click="mode='users'" :class="{ active: mode === 'users' }">Users</button>
	</section>

  <section v-if="mode == 'general'">
    <section>
      <label>Course Name:</label><br />
      <input type="text" v-model="newCourse.courseName" /><br /><br />

    </section>


    <div class="flex-row flex-align-center flex-gap-1">
      <div>Active Version ID: {{ newCourse.activeVersionID }}</div>
      <button class="pillButton" @click="generateMTOCGraph()">Re-Generate MTOC Graph</button>
      <div v-if="busy">
        <span class="icon-loop2 spin1" /> {{ unitsComplete }} / {{ unitsTotal }} ({{ percent }}%)
      </div>
    </div>
    <div v-if="error" class="warning">There was an error while re-generating the MTOC graph</div>


    <br><br><br>

    <section>
      <h1 class="section-header">Base URLs</h1>
      <label>Icon URL:</label><br />
      <input type="text" v-model="newCourse.baseUrlIcon" /><br /><br />

      <label>Image URL:</label><br />
      <input type="text" v-model="newCourse.baseUrlImage" /><br /><br />

      <label>Video URL:</label><br />
      <input type="text" v-model="newCourse.baseUrlVideo" /><br /><br />

      <label>Audio URL:</label><br />
      <input type="text" v-model="newCourse.baseUrlAudio" /><br /><br />

      <label>PDF URL:</label><br />
      <input type="text" v-model="newCourse.baseUrlPdf" /><br /><br />

      <label>Text URL:</label><br />
      <input type="text" v-model="newCourse.baseUrlText" /><br /><br />

      <label>Other URL:</label><br />
      <input type="text" v-model="newCourse.baseUrlOther" /><br /><br />
    </section>

    <button class="button" @click="save" :disabled="!changesMade">Save Changes</button>
  </section>


  <section v-if="mode == 'variations'">
    <VariationsList :Variations="Course.courseVariations" />
  </section>

  <section v-if="mode == 'users'">
    <UsersList v-if="Course" :Course="Course" :Deletable="false" />
  </section>


</div>
</template>

<script>
import Course from "@/models/Course"
import VariationsList from "./VariationsList"

import CourseAPI from "@/api/CourseAPI"
import CourseAdminAPI from '@/api/CourseAdminAPI.js'
import LongJobAPI from '@/api/LongJobAPI.js'

export default {
  name: 'course-details',

  props: {
    Course: Object,
  },

  data() {
    return {
      newCourse: new Course(),
      mode: 'general',

      graphJobID: null,
      graphIntervalID: null,
      
      unitsComplete: 0,
      unitsTotal: 0,
      percent: null,

      busy: false,
      report: null,
      error: false,
    }
  },

  computed: {
    changesMade() { return !this.Course.isSame(this.newCourse); }
  },

  watch: {
    Course() { Object.assign(this.newCourse, this.Course); }
  },

  created() {
    Object.assign(this.newCourse, this.Course);
  },

  methods: {


    async generateMTOCGraph() {

      try {
        this.error = false
        this.busy = true
  
        const data = await CourseAdminAPI.generateMTOCGraph( this.newCourse.courseID, this.newCourse.activeVersionID )
        this.graphJobID = data.jobID
        
  
        // Poll for job updates until job is complete
        if( this.graphIntervalID !== null ) clearInterval( this.graphIntervalID )
  
        const updateJobStatus = async () => {
          const data = await LongJobAPI.getJobProgress( this.graphJobID )
          this.unitsComplete = data.workUnitsComplete
          this.unitsTotal = data.workUnitsTotal
          this.percent = data.workUnitsTotal ? (data.workUnitsComplete / data.workUnitsTotal) : 1
          this.report = data.jobReport
  
          if( data.error || data.errorReport ) {
            this.error = true
            console.error( data.errorReport )
          }
          
          if( data.complete ) {
            this.busy = false
            
            clearInterval( this.graphIntervalID )
            this.graphIntervalID = null
          }
        }
  
        updateJobStatus()
        
        this.graphIntervalID = setInterval( updateJobStatus, 2500 )

      } catch ( e ) {
        this.busy = false
        this.error = true
        if( this.graphIntervalID ) clearInterval( this.graphIntervalID )
        console.error( e )
      }
		},


    async save() {
      try {
        if (this.newCourse.courseID) {
          // edit course
          let res = await CourseAPI.editCourse(this.newCourse)
          this.$emit('edit', res)
          Object.assign( this.Course, this.newCourse )

        } else {
          // add course
          let res = await CourseAPI.addCourse(this.newCourse)
          this.$emit('add', res)

        }
      } catch (e) {
        alert(`Could not save changes: ${e}`)
      }
    },

  },

  components: {
    UsersList: () => import("@/features/Users/UsersList"),
    VariationsList
  }
}
</script>
