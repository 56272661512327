import Exim from '@/models/Exim.js'


class UnsubscribeRecord extends Exim {


	email = null
	topic = null
	source = null
	userID = null
	emailGUID = null
	time = null



	static import( obj ) {
		return super.import( obj, [], [ 'time' ] )
	}


	export() {
		return super.export( [], [ 'time' ] )
	}


}
export default UnsubscribeRecord