import API from './API.js'
import PaginatedResponse from '@/api/PaginatedResponse.js'



class ReviewsAPI extends API {

  static controllerName = 'ReviewsAPI'


  static async getReviews( reqBody ) {
    const res = await this.newPost( 'getReviews', [], reqBody )
    return PaginatedResponse.import( res.data )
  }


  static async getReviewsCSV( req, allPages = true ) {
    const res = await this.newPost( 'getReviewsCSV', [ allPages ], req, 'blob' )
    this.processResponseAsFileDownload( res.data )
  }


  static async multiEditPublication( req ) {
    return await this.newPost( 'multiEditPublication', [], req )
  }


  static async addAffiliate( reviewID, affiliateID, isPrimary = false ) {
    return await this.newGet( 'addAffiliate', [ reviewID, affiliateID, isPrimary ] )
  }


  static async removeAffiliate( reviewID, affiliateID ) {
    return await this.newGet( 'removeAffiliate', [ reviewID, affiliateID ] )
  }


  static async reorderSuperReview( reviewID, newIndex ) {
    return await this.newGet( 'reorderSuperReview', [ reviewID, newIndex ] )
  }

  static async modifyAuthorName( reviewID, authorName ) {
    return await this.newPost( 'modifyAuthorName', [], { reviewID, authorName } )
  }

  static async modifyBody( reviewID, body ) {
    return await this.newPost( 'modifyBody', [], { reviewID, body } )
  }

  static async deleteReviews( req ) {
    return await this.newPost( 'deleteReviews', [], req )
  }


  static async getTagPublicationSettings() {
    return await this.newGet( 'getTagPublicationSettings' )
  }

  static async publishTag( tag ) {
    return await this.newGet( 'publishTag', [ tag ] )
  }

  static async unpublishTag( tag ) {
    return await this.newGet( 'unpublishTag', [ tag ] )
  }

  static async setTagLabel( tag, label ) {
    return await this.newGet( 'setTagLabel', [ tag, label ] )
  }


  static async getAdminReviewsPageSettings( affiliateSlug = null ) {
    return await this.newGet( 'getAdminReviewsPageSettings', [ affiliateSlug ] )
  }

  static async setReviewsPageSettings( req ) {
    return await this.newPost( 'setReviewsPageSettings', [], req )
  }

  static async deleteReviewsPageSettings( affiliateSlug, reviewTag ) {
    return await this.newGet( 'deleteReviewsPageSettings', [ affiliateSlug, reviewTag ] )
  }


  // ----------------------------------------------------------------
  // Educator Reviews
  // ----------------------------------------------------------------

  static async getEducatorReviews( req ) {
    const res = await this.newPost( 'getEducatorReviews', [], req )
    return PaginatedResponse.import( res.data )
  }

  static addEducatorReview( rvw ) {
    return this.newPost( 'addEducatorReview', [], rvw )
  }

  static patchEducatorReview( patchReq ) {
    return this.newPost( 'patchEducatorReview', [], patchReq )
  }

  static async reorderEducatorReview( reviewID, newIndex ) {
    return await this.newGet( 'reorderEducatorReview', [ reviewID, newIndex ] )
  }

  static deleteEducatorReview( id ) {
    return this.newGet( 'deleteEducatorReview', [ id ] )
  }

}

export default ReviewsAPI
