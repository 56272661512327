<template>
  <SearchDropdown
    :placeholder="placeholder"
    :DisplayFunction="displayFunction"
    :InitialValue="displayFunction(course)"
    :ObjKey="'id'"
    :width="width"
    :browseIcon="true"
    :externalResults="items"
    :dropUp="dropUp"
    @selected="item => select(item)"
    @search="txt => getList(txt)"
    @none="select(null)"
    ref="search"
  />
</template>


<script>
import CourseAPI from '@/api/CourseAPI.js';
import Course from '@/models/Course.js';
import SearchDropdown from '@/components/utilities/SearchDropdown.vue';

export default {
  name: 'CourseSearchDropdown',

  components: {
    SearchDropdown,
  },

  props: {
    course: {
      type: Object,
      default: null
    },

    width: {
      type: String,
      default: '50%'
    },

    placeholder: {
      type: String,
      default: 'Search Courses'
    },

		dropUp: {
			type: Boolean,
			default: false
		},

  },


  model: {
    prop: 'course',
    event: 'update'
  },

  data() {
    return {
      items: [],
      pages: 1,
    }
  },

  methods: {

    select(item) {
      this.$emit('update', item);
    },

    clear() { this.$refs.search.clear() },

    displayFunction(obj) {
      if(!obj) return '';
      return `${ obj.courseName } (${ obj.courseID })`
    },

    async getList(searchTerm, page = 1) {
      const data = await CourseAPI.searchCourses(page, 25, searchTerm);

      this.items = [];
      if(!data) return;
      for(let item of data) this.items.push( Course.import(item) );
    },
  }


}
</script>
