import Exim from '@/models/Exim.js'
import ProductGroup from './ProductGroup.js'
import PricingRule from './PricingRule.js'



class Storefront extends Exim {

  id = null
  slug = ''
  title = ''
  productGroups = []
  pricingRules = []

  showTitle = true
  showSlashes = true
  showGroupTitle = true
  showGroupDescription = true
  showCouponCode = true
  
  sliderMode = false
  openByDefault = true
  defaultSlideIndex = 0

  showComparePrograms = false  // whether or not to show the "Compare Programs" button (and popup table)
  quickSelectPrices = false    // whether or not to show price dropdown on product cards.

  autofillDefaultCoupon = false
  defaultCoupon = null



  addProductGroup() {
    let pg = new ProductGroup();
    this.productGroups.push(pg);
    return pg;
  }

  removeProductGroup(index) {
    this.productGroups.splice(index, 1);
  }

  swapProductGroups(idxa, idxb) {
    let pg = this.productGroups;
    if(idxa < 0 || idxb < 0 || idxa >= pg.length || idxb >= pg.length) return;
    pg[idxa] = pg.splice(idxb, 1, pg[idxa])[0];
  }

  swapProducts( productGroup, idxa, idxb ) {
    let prods = productGroup.products
    if(idxa < 0 || idxb < 0 || idxa >= prods.length || idxb >= prods.length) return
    prods[idxa] = prods.splice(idxb, 1, prods[idxa])[0]
  }



  pricingOptionAvailable(productSKU, pricingOptionID) {
    for(let pr of this.pricingRules) {
      if(productSKU !== pr.productSKU) continue;
      for(let poID of pr.availablePricingOptions) {
        if(poID === pricingOptionID) return true;
      }
    }
    return false;
  }



  addAvailablePricingOption(productSKU, pricingOptionID) {

    let pricingRuleExists = false;
    for(let pr of this.pricingRules) {
      if(productSKU !== pr.productSKU) continue;
      pricingRuleExists = true;
      pr.addPricingOption(pricingOptionID);
    }

    if(pricingRuleExists) return;

    // Add new rule if necessary
    let pr = new PricingRule(null, this.id, productSKU, pricingOptionID);
    pr.addPricingOption(pricingOptionID);
    this.pricingRules.push(pr);
  }



  removeAvailablePricingOption(productSKU, pricingOptionID) {
    for(let pr of this.pricingRules) {
      if(productSKU !== pr.productSKU) continue;
      pr.removePricingOption(pricingOptionID);
    }
  }



  isSame(obj) {
    return JSON.stringify(this.export()) == JSON.stringify(obj.export());
  }

  clone() {
    return Storefront.import(this.export(false));
  }



  static import(obj) {

    const sf = super.import( obj, [ 'productGroups', 'pricingRules' ] )

    // Import Product groups
    for( var pg of obj.productGroups ) sf.productGroups.push( ProductGroup.import( pg ) )

    // Import Pricing Rules
    for( var pr of obj.pricingRules ) sf.pricingRules.push( PricingRule.import( pr ) )

    // Return Storefront
    return sf
  }


  export( prodsAsSKUs = true ) {

    const data = super.export( [ 'productGroups', 'pricingRules' ] )

    // Prepare ProductGroups
    data.productGroups = []
    for( let grp of this.productGroups ) data.productGroups.push( grp.export( prodsAsSKUs ) )

    // Prepare PricingRules
    data.pricingRules = []
    for( let pr of this.pricingRules ) data.pricingRules.push( pr.export() )
    
    return data
  }
}

export default Storefront;
