<template>
<section>


  <!-- Save button -->
  <div class="StickySaveButton">
		<button class="pillButton" @click="save()" :class="{ 'disabled' : !changesMade }" :disabled="!changesMade">
			Save Changes
		</button>
	</div>

  <div class="absolute" style="top: 0.5em; right: 14em;"><strong><a target="new" :href="`${baseURL}/edittemplate/${newItem.urlSlug}`">Preview</a></strong></div>

  <!-- Top Matter -->
  <div class="flex-row flex-grow flex-gap-1 ma-1">
    <label class='flex-column' style="width: 400px;">
      <span>URL slug:</span>
      <input type="text" v-model="newItem.urlSlug" :disabled="!isNew" />
    </label>

    <label class='flex-column' style="width: 400px;">
      <span>Admin Label (Internal Name):</span>
      <input type="text" v-model="newItem.adminLabel" />
    </label>
  </div>

  <label class='flex-column ma-1' style="width: 400px;">
    <div class="mt-05">Default Coupon Code:</div>
    <input type="text" v-model="newItem.defaultCoupon" placeholder="inherit from Affiliate" />
  </label>


  <!-- Tabs -->
	<section v-if="newItem.affiliates !== null" class="Tab mt-2">
		<button @click="mode = 'landingPageSections'" :class="{ active: mode === 'landingPageSections' }">Landing Page Sections</button>
		<button @click="mode = 'affiliateList'" :class="{ active: mode === 'affiliateList' }">Used by {{ newItem.affiliates.length }} Affiliate{{ newItem.affiliates.length != 1 ? 's' : '' }}</button>
	</section>


  <!-- Landing Page Sections -->
  <section v-if="mode === 'landingPageSections'" id="sectionsWrapper" class="relative" @click.stop="selectedSectionIndex = null">
    <!-- <h2 class="mt-3">Sections:</h2> -->

    <div class="flex-row flex-align-center flex-justify-center flex-gap-05 pt-2">
      <button @click.stop="newItem.addHTMLSection( 0 )"><span class="icon-plus" /> HTML</button>
      <button @click.stop="newItem.addPrimaryQuoteSection( 0 )"><span class="icon-plus" /> Primary Quote</button>
      <button @click.stop="newItem.addImageSection( 0 )"><span class="icon-plus" /> Image</button>
      <button @click.stop="newItem.addSliderSection( 0 )"><span class="icon-plus" /> Slider</button>
      <button @click.stop="newItem.addStorefrontSection( 0 )"><span class="icon-plus" /> Storefront</button>
      <button @click.stop="newItem.addChatSection( 0 )"><span class="icon-plus" /> Chat</button>
      <button @click.stop="newItem.addBuiltInSection( 0 )"><span class="icon-plus" /> Built-In</button>
    </div>

    <div v-for="(section, index) in newItem.sections" :key="section.vueID">
      <div class="section" :class="{ 'selected' : selectedSectionIndex === index }" @click.stop="selectedSectionIndex = index">
        <div class="leftNav">
          <div @click.prevent="newItem.swapSections(index, index-1)" class="icon-arrow-up" />&nbsp;
          <div @click.prevent="newItem.swapSections(index, index+1)" class="icon-arrow-down" />
        </div>&nbsp;
        <div class="pa-05 w-100">
          <LandingPageHTMLSection v-if="!section.type || section.type == 'html'" :section="section" @click.stop="selectedSectionIndex = index" />
          <LandingPagePrimaryQuoteSection v-else-if="section.type == 'primaryQuote'" :section="section" @click.stop="selectedSectionIndex = index" />
          <LandingPageImageSection v-else-if="section.type == 'image'" :section="section" />
          <LandingPageSliderSection v-else-if="section.type == 'slider'" :section="section" />
          <LandingPageStorefrontSection v-else-if="section.type == 'storefront'" :section="section" />
          <LandingPageChatSection v-else-if="section.type == 'chat'" :section="section" />
          <LandingPageBuiltInSection v-else-if="section.type == 'builtin'" :section="section" />
        </div>
        <span class="icon-cross" style="position: absolute; right: 0.5em; top: 0.5em;" @click.stop="deleteSection(index)" />

      </div>
      <div v-if="selectedSectionIndex == index" class="flex-row flex-align-center flex-justify-center flex-gap-05 pa-05">
        <button @click.stop="newItem.addHTMLSection( ++selectedSectionIndex )"><span class="icon-plus" /> HTML</button>
        <button @click.stop="newItem.addPrimaryQuoteSection( ++selectedSectionIndex )"><span class="icon-plus" /> Primary Quote</button>
        <button @click.stop="newItem.addImageSection( ++selectedSectionIndex )"><span class="icon-plus" /> Image</button>
        <button @click.stop="newItem.addSliderSection( ++selectedSectionIndex )"><span class="icon-plus" /> Slider</button>
        <button @click.stop="newItem.addStorefrontSection( ++selectedSectionIndex )"><span class="icon-plus" /> Storefront</button>
        <button @click.stop="newItem.addChatSection( ++selectedSectionIndex )"><span class="icon-plus" /> Chat</button>
        <button @click.stop="newItem.addBuiltInSection( ++selectedSectionIndex )"><span class="icon-plus" /> Built-In</button>
      </div>
      
    </div>

  </section>


  <!-- List of affiliates using this page -->
  <section v-if="mode === 'affiliateList'">
    <ObjectTable
      v-if="newItem.affiliates !== null"
      :Source="newItem.affiliates"
      :Columns='columns'
    />
  </section>


</section>
</template>

<script>
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import LandingPage from './LandingPage.js'
import { powerprep_base_url } from '@/Config.js'

import LandingPageHTMLSection from './LandingPageSections/LandingPageHTMLSection.vue'
import LandingPagePrimaryQuoteSection from './LandingPageSections/LandingPagePrimaryQuoteSection.vue'
import LandingPageImageSection from './LandingPageSections/LandingPageImageSection.vue'
import LandingPageSliderSection from './LandingPageSections/LandingPageSliderSection.vue'
import LandingPageStorefrontSection from './LandingPageSections/LandingPageStorefrontSection.vue'
import LandingPageChatSection from './LandingPageSections/LandingPageChatSection.vue'
import LandingPageBuiltInSection from './LandingPageSections/LandingPageBuiltInSection.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'

export default {
  name: "LandingPageDetails",

  components: {
    LandingPageHTMLSection,
    LandingPagePrimaryQuoteSection,
    LandingPageImageSection,
    LandingPageSliderSection,
    LandingPageStorefrontSection,
    LandingPageChatSection,
    LandingPageBuiltInSection,
    ObjectTable,
  },

  props: {
    itemIn: Object,
    isNew: Boolean,
  },

  data() {
    return {
      newItem: Object,
      mode: 'landingPageSections',
      selectedSectionIndex: null,

      columns: [
        {
          displayName: 'Affiliate ID',
          propertyName: 'id',
          sortable: false
        },
        {
          displayName: 'Affiliate Name',
          propertyName: 'name',
          sortable: false
        },
        {
          displayName: 'URL Slug',
          propertyName: 'urlSlug',
          sortable: false
        },
      ],
    }
  },

  async created() {
    this.newItem = this.itemIn.clone();
  },

  computed: {
		baseURL() { return powerprep_base_url },
    changesMade() {
      if(this.newItem && !this.itemIn.isSame(this.newItem)) return true;
      return false;
    }
  },


  methods: {
    deleteSection(index) {
      const choice = confirm("Really delete this section?");
      if(choice) this.newItem.deleteSection(index);
      this.selectedSectionIndex = null
    },



    async save() {
      if(this.isNew) {
        await SalesManagementAPI.createLandingPage(this.newItem.export());
        const obj = await SalesManagementAPI.getLandingPage(this.newItem.urlSlug);
        const newItem = LandingPage.import(obj);
        this.$emit('itemAdded', newItem);
      } else {
        await SalesManagementAPI.editLandingPage(this.newItem.export());
        this.$emit('itemEdited', this.newItem.clone());
      }
    },


  }



}
</script>

<style scoped>
input, textarea { padding: 0.5em !important; }
#sectionsWrapper {
  background-color: var(--pp10-gray-95);
  padding-bottom: 3em;
}
.section {
  display: flex;
  border: 1px solid #888;
  border-radius: 5px;
  /* background: #f8f8f8; */
  box-shadow: 0 6px 12px -6px #888;
  /* padding: 0.5em 0.5em 0.5em 0; */
  margin: 3em 4em;
  position: relative;
  transition: all 0.5s;
  background-color: white;
}

.leftNav {
  border-right: 1px solid lightgray;
  padding: 1em 0.5em;
}

.section.selected {
  border-color: var(--pp10-purple) !important;
  border-width: 4px;
}

.section.selected .leftNav {
  background-color: var(--pp10-purple-95);
}
</style>
