class GraphHelper {


	static generateTimeLabels( graph ) {
		const start = new Date( graph.start * 1000 )
		const end = new Date( graph.end * 1000 )
		
		const midnightAligned = graph.xUnit == 'day' && start.getHours() == 0 && start.getMinutes() == 0 && start.getSeconds() == 0 ? true : false


		if( !graph.subtitle ) {
			// if( midnightAligned ) 
			graph.subtitle = `${start.toLocaleDateString()} - ${end.toLocaleDateString()}, by ${graph.xUnit}`
			// else graph.subtitle = `${start.toLocaleString()} - ${end.toLocaleString()}`
		}

		if( !graph.xLabels.length && graph.xUnit ) {
			for( var i=0; i < graph.xValues.length; i++ ) {
				const currTS = graph.xValues[i] * 1000
				
				var nextTS = null
				if( i+1 < graph.xValues.length ) nextTS = graph.xValues[ i+1 ] * 1000
				else nextTS = this.endDate ? this.endDate.getTime() : Date.now()

				const start = new Date( currTS )
				const end = new Date( nextTS )

				const label = midnightAligned ? start.toLocaleDateString() : `${start.toLocaleString()} - ${end.toLocaleString()}`
				graph.xLabels.push( label )
			}
		}
	}


}
export default GraphHelper