<template>
<section>

	<div class="flex-row flex-align-center flex-justify-between flex-gap-1 py-05 sticky-tools">
		<!-- FILTER: success -->
		<select v-model="isSuccessful">
			<option :value="null">All</option>
			<option :value="true">Only Successful</option>
			<option :value="false">Only Unsuccessful</option>
		</select>

		<!-- FILTER: timestamp / date -->
		<div>
			<v-date-picker v-model="startDate" mode="dateTime">
				<template v-slot="{ inputValue, inputEvents }">
					<input
					placeholder="Start Date"
					:value="inputValue"
					v-on="inputEvents"
					/>
				</template>
			</v-date-picker> - 
			<v-date-picker v-model="endDate" mode="dateTime">
				<template v-slot="{ inputValue, inputEvents }">
					<input
					placeholder="End Date"
					:value="inputValue"
					v-on="inputEvents"
					/>
				</template>
			</v-date-picker>
		</div>
		
		<!-- COUNT -->
		<div>{{ itemCount }} <span v-if="isSuccessful !== null">{{ isSuccessful ? 'successful' : 'unsuccessful' }} </span>login attempts</div>
	</div>

	
	<div v-if="!loading">
		<ObjectTable
			:Columns="columns"
			:Source="items"
			:Numbered="true"
			:SortBy="sortBy"
			:SortAsc="sortAsc"
			:PageNum="page"
			:PageSize="pageSize"
			@sort="prop => sort(prop)"
			class="mt-1"
		/>
		<Paginator v-model="page" @input="initialize()" :numPages="numPages" />
	</div>

	<div v-if="!loading && !error && !items.length" class="NoResults">No Records</div>

	<section v-if='loading' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

	<section v-if="error" class="warning">
		Failed to get records
	</section>

</section>
</template>



<script>
import LoginTrackingAPI from '@/api/LoginTrackingAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import LoginAttempt from '@/features/LoginTracking/LoginAttempt.js'

import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'

export default {
	name: 'LoginList',


	components: {
		ObjectTable,
		Paginator,
	},


	props: {
		userID: Number,
	},


	data() {
		const cols = []
		if( !this.$props.userID ) {
			cols.push({
				displayName: 'User ID',
				propertyName: 'userID',
				sortable: true
			},
			{
				displayName: 'Email',
				propertyName: 'emailAddress',
				sortable: true
			})
		}
		cols.push(
			{
				displayName: 'Date',
				propertyName: 'timestamp',
				displayFunction: function( item ) {
					return item.timestamp ? item.timestamp.toLocaleString() : null
				},
				sortable: true
			},
			{
				displayName: 'IP Address',
				propertyName: 'ipAddress',
				sortable: true
			},
			{
				displayName: 'Success',
				propertyName: 'success',
				sortable: true
			},
			{
				displayName: 'Impersonated By',
				propertyName: 'impersonatorID',
				sortable: true
			},
			{
				displayName: 'Method',
        propertyName:'method',
        sortable: true
			}
		)
		
		return {
			isSuccessful: null,
			startDate: null,
			endDate: null,

			sortBy: 'timestamp',
			sortAsc: false,
			page: 1,
			pageSize: 100,

			loading: false,
			error: false,

			items: [],
			itemCount: 0,
			numPages: 1,

			columns: cols,
		}
	},



	computed: {
		start() {
      if (!this.startDate) return null;
      return Math.floor(this.startDate.getTime()/1000);
    },

    end() {
      if (!this.endDate) return null;
      return Math.floor(this.endDate.getTime()/1000);
    },
	},



	watch: {
		isSuccessful() { this.page = 1; this.initialize() },
		startDate() { this.page = 1; this.initialize() },
		endDate() { this.page = 1; this.initialize() },
	},



	created() { this.initialize() },



	methods: {

		async initialize() {

			try {
				this.loading = true
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
				req.userID = this.userID
				req.isSuccessful = this.isSuccessful
				req.startTimestamp = this.start
				req.endTimestamp = this.end
	
				const pr = await LoginTrackingAPI.getUserLoginAttempts( req )
				this.numPages = pr.pages
				this.itemCount = pr.count
	
				this.items = []
				for( var item of pr.data ) this.items.push( LoginAttempt.import(item) )

				this.error = false

			} catch(e) {
				this.error = true

			} finally {
				this.loading = false
			}

		},


		sort( propName ) {
			if( this.sortBy === propName ) this.sortAsc = !this.sortAsc
			else {
				this.sortBy = propName
				this.sortAsc = propName === 'ipAddress' ? true : false
			}

			this.initialize()
		},

	}

}
</script>



<style scoped>

</style>