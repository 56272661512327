import API from "./API"
import {base_url} from './../Config'

class UserProfileAPI extends API {

  static async regenerateAvatars(offset = 0) {
    const url = `${base_url}/UserProfileAPI/regenerateAvatars/${offset}`
    const res = await super.get(url)
    return res
    
  }

}

export default UserProfileAPI
