<template>
<section>

	<div class="StickySaveButton">
		<button class="pillButton" @click="save()" :class="{ 'disabled' : !changesMade }" :disabled="!changesMade">
			Save Changes
		</button>
	</div>

	<section class="Tab sticky-top" style="z-index: 3">
		<button @click="mode = 'contents'" :class="{ active: mode === 'contents' }">Contents</button>
		<button @click="mode = 'triggers'" :class="{ active: mode === 'triggers' }">
			Triggers
			<span v-if="!newModalMessage.hasLocation" class='icon-location2' style='color:red' />
			<span v-if="!newModalMessage.isAvailableCalendar" class='icon-calendar' style='color:red' />
			<span v-if="!newModalMessage.hasDisplayEvent" class='icon-enter' style='color:red' />
			<span>({{ newModalMessage.triggerUserCount }})</span>
		</button>
		<button @click="mode = 'assignments'" :class="{ active: mode === 'assignments' }">Assignments ({{ newModalMessage.assignedUserCount }})</button>
		<div class="ml-2" />
		<button @click="mode = 'views'" :class="{ active: mode === 'views' }">Views ({{ modalMessage.viewCount }})</button>
		<button @click="mode = 'actions'" :class="{ active: mode === 'actions' }">Actions ({{ modalMessage.actionCount }})</button>
		<button @click="mode = 'responses'" :class="{ active: mode === 'responses' }">Responses ({{ modalMessage.responseCount }})</button>
		<button @click="mode = 'reviews'" :class="{ active: mode === 'reviews' }">Reviews ({{ modalMessage.reviewCount }})</button>
	</section>

	<div class="ma-1">
		<ModalMessageContents v-if="mode == 'contents'" :modalMessage="newModalMessage" :dVals="dVals" @reset="prop => reset(prop)" ref="contents" />
		<ModalMessageTriggers v-if="mode == 'triggers'" :modalMessage="newModalMessage" :dVals="dVals" @reset="prop => reset(prop)" @changed="changeCounter++" @updateActive="$emit('updateActive', newModalMessage.isActive, newModalMessage.isUsed)" ref="triggers" />
		<ModalMessageAssignments v-if="mode == 'assignments'" :modalMessage="newModalMessage" @saveError="showSaveErrorDialog = true" @changed="changeCounter++" ref="assignments" />
	</div>

	<ModalMessageViews v-if="mode === 'views'" :modalMessageID="modalMessage.modalMessageID" />
	<ModalMessageActions v-if="mode === 'actions'" :modalMessageID="modalMessage.modalMessageID" />
	<ModalMessageResponses v-if="mode === 'responses'" :modalMessageID="modalMessage.modalMessageID" />
	<ReviewsDashboard v-if="mode === 'reviews'" :modalMessageID="modalMessage.modalMessageID" />

	<AlertDialog :show="showSaveErrorDialog" @close="showSaveErrorDialog = false">
		<div>FAILED to save ModalMessage!</div>
	</AlertDialog>

	<ConfirmDialog :show="showSaveWarningDialog" confirm="Save Anyway" @cancel="showSaveWarningDialog = false" @confirm="save( true )">
		<h1 style="color:red;">PRE-FLIGHT FAILURE</h1>
		<div class="mt-05" v-if="warningNoContent"><span class="icon-warning" style="color:red" /> The Modal Message is blank.</div>
		<div class="mt-05" v-if="warningNotCloseable"><span class="icon-warning" style="color:red" /> There is no way for users to close the popup.</div>
		<div class="mt-05" v-if="warningTooPersistent"><span class="icon-warning" style="color:red" /> The Modal Message will show up <strong>EVERY</strong> time assigned user(s) log in.</div>
	</ConfirmDialog>
</section>
</template>



<script>
import ModalMessageAPI from '@/api/ModalMessageAPI.js'
import ModalMessage from './ModalMessage.js'

import ModalMessageContents from './ModalMessageContents.vue'
import ModalMessageAssignments from './ModalMessageAssignments.vue'
import ModalMessageTriggers from './ModalMessageTriggers.vue'
import ModalMessageViews from './ModalMessageViews.vue'
import ModalMessageActions from './ModalMessageActions.vue'
import ModalMessageResponses from './ModalMessageResponses.vue'
import ReviewsDashboard from '@/features/Reviews/ReviewsDashboard.vue'
import AlertDialog from '@/components/utilities/AlertDialog.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

export default {
	name: 'ModalMessageDetails',

	components: {
		ModalMessageContents,
		ModalMessageAssignments,
		ModalMessageTriggers,
		ModalMessageViews,
		ModalMessageActions,
		ModalMessageResponses,
		ReviewsDashboard,
		AlertDialog,
		ConfirmDialog,
	},


	props: {
		modalMessage: Object,
		isActive: Boolean,
	},

	data() {
		const defaultValues = new ModalMessage()
		return {
			dVals: defaultValues,
			mode: 'contents',
			changeCounter: 0,
			newModalMessage: {},
			showSaveErrorDialog: false,
			showSaveWarningDialog: false,
		}
	},



	computed: {
		changesMade() {
			this.changeCounter
			if (!this.newModalMessage.export) return false
			return !this.modalMessage.isSame(this.newModalMessage);
		},

		warningNotCloseable() {	return !this.newModalMessage.allowDismiss && !this.newModalMessage.acceptButtonText },
		warningNoContent() {
			return !(this.newModalMessage.showBody || !this.newModalMessage.body) && 
			!this.newModalMessage.showTextResponse && 
			!this.newModalMessage.showVideoResponse && 
			(
				!this.newModalMessage.showReviewResponse || 
				(!this.newModalMessage.showStarRating && !this.newModalMessage.showTextReview && !this.newModalMessage.showVideoReview)
			)
		},
		warningTooPersistent() {
			return !this.newModalMessage.maxRepeats &&
			!this.newModalMessage.triggerStartDate && 
			!this.newModalMessage.triggerEndDate && 
			!this.newModalMessage.intervalDateMinutes &&
			!this.newModalMessage.triggerMinLogins &&
			!this.newModalMessage.triggerMaxLogins &&
			!this.newModalMessage.intervalLogins &&
			!this.newModalMessage.triggerMinMinutesInProgram &&
			!this.newModalMessage.triggerMaxMinutesInProgram &&
			!this.newModalMessage.intervalMinutesInProgram &&
			!this.newModalMessage.triggerMinMinutesPurchase &&
			!this.newModalMessage.triggerMaxMinutesPurchase &&
			!this.newModalMessage.intervalMinutesPurchase &&
			!this.newModalMessage.triggerMinMinutesAccount &&
			!this.newModalMessage.triggerMaxMinutesAccount &&
			!this.newModalMessage.intervalMinutesAccount &&
			!this.newModalMessage.triggerNodesCompleted.size && 
			this.newModalMessage.displayOnLogin && 
			(this.newModalMessage.displayInCourse || this.newModalMessage.displayInLibrary)
		},
	},


	watch: {
		modalMessage() { this.initialize() },
		isActive( value ) { this.newModalMessage.isActive = value }
	},


	created() { this.initialize() },


	methods: {

		initialize() {
			this.newModalMessage = this.modalMessage.clone()
			this.newModalMessage.triggerUserCount = this.modalMessage.triggerUserCount
			this.newModalMessage.assignedUserCount = this.modalMessage.assignedUserCount
		},


		async save( force = false ) {
			this.showSaveWarningDialog = false
			if( !force && (this.warningNoContent || this.warningNotCloseable || this.warningTooPersistent) ) {
				this.showSaveWarningDialog = true
				return
			}

			try {
				if( this.newModalMessage.modalMessageID === null ) {
					ModalMessageAPI.createModalMessage( this.newModalMessage.export() )
					.then( res => {
						const mm = ModalMessage.import( res.data )
						this.$emit( 'create', mm )
						if( this.$refs.contents ) this.$refs.contents.tiptapSaved = true
						this.updateAssignedUsers()
					} )
					.catch( e => { this.showSaveErrorDialog = true } )

				}	else {
					ModalMessageAPI.editModalMessage( this.newModalMessage.export() )
					.then( res => {
						this.$emit( 'save', this.newModalMessage )
						if( this.$refs.contents ) this.$refs.contents.tiptapSaved = true
						this.updateAssignedUsers()
					} )
					.catch( e => { this.showSaveErrorDialog = true } )
				}
				this.changeCounter++

			} catch(e) {
				console.error(e)
				this.showSaveErrorDialog = true
			}

		},


		async updateAssignedUsers() {
			if( this.mode == 'triggers' ) this.$refs.triggers.updateAssignedUsers()
			else if( this.mode == 'assignments' ) this.$refs.assignments.updateAssignedUsers()
		},


		reset( propertyName ) {
			if( !propertyName ) return
			this.newModalMessage[ propertyName ] = this.dVals[ propertyName ]
		},

	}

}
</script>



<style scoped>

</style>