import TreeNode from './TreeNode.js'
import AnswerNode from './AnswerNode.js'



class QuestionNode extends TreeNode {


	display_method = 'inline'

	displayPosition = null; // Number 1 - 9: left-center-right on top row, then middle row, then bottom row
	maxHeight = null; // decimal 0 - 1 (percentage of mainPane height for popup)
	maxWidth = null; // decimal 0 - 1 (percentage of mainPane width for popup)

	// Display method after the question is answered.
	reviewDisplayPosition = null; // Number 1 - 9: left-center-right on top row, then middle row, then bottom row
	reviewMaxHeight = null; // decimal 0 - 1 (percentage of mainPane height for popup)
	reviewMaxWidth = null; // decimal 0 - 1 (percentage of mainPane width for popup)

	// MediaNodes that express the question
	media = [];

	// AnswerNodes that are the answer choices
	answers = [];

	// Array of references to the AnswerNode(s) that is/are correct answer(s)
	correctAnswers = [];

	// A reference to the AnswerNode the user chose (after they've answered the question)
	userAnswer = null;

	// "Description" meta (text).
	description = null;

	// MediaNodes that express the explanation(s) of the correct answer
	detailedExplanations = [];

	// Student-input responses
	freeResponseMode = false;
	freeResponseMaxLength = 50;

	// suppress test-wide media
	suppressedTestWideMediaBefore = [];
	suppressedTestWideMediaAfter = [];

	// "More Info" section (comments / related / TC)
	showMoreInfoBefore = false     // Whether or not to show ENTIRE "more info" section *before* test is complete
	showCommentsBefore = false     // Show "Comments" tab *before* test is complete
	enableCommentsBefore = false   // Whether or not to allow users to leave NEW comments *before* test is complete
	showRelatedBefore = false      // Show "Related" tab *before* test is complete
	showTeacherChatBefore = false  // Show "Teacher Chat" tab *before* test is complete

	showMoreInfoAfter = true       // Whether or not to show ENTIRE "more info" section *after* test is complete
	showCommentsAfter = true       // Show "Comments" tab *after* test is complete
	enableCommentsAfter = true     // Whether or not to allow users to leave NEW comments *after* test is complete
	showRelatedAfter = true        // Show "Related" tab *after* test is complete
	showTeacherChatAfter = true    // Show "Teacher Chat" tab *after* test is complete


	// Question point value
	scoreValue = 1;


	constructor(id, name = '', icon = '', type = 'question', display_method) {

		super(id, name, icon, type);

		// Display method (before question is answered)
		if (display_method) this.display_method = display_method;
		
		// Display method after the question is answered.
		this.reviewDisplayMethod = this.display_method;
	}


	
	
	/**
	 * Whether or not the question HAS (at least one) correct answer.
	 */
	hasCorrectAnswer() { return this.correctAnswers.length > 0 ? true : false; }



	/**
	 * Determines whether a student's answer is correct.
	 * If 'answer' argument is provided, it is used.
	 * Otherwise, this.userAnswer is used.
	 */
	isCorrect(answer = null) {
		if (!this.hasCorrectAnswer()) return false;
		if (!answer) answer = this.userAnswer;
		if (!answer) return false;

		return this._answerIsCorrect(answer);
	}


	_answerIsCorrect(answer) {
		if (!answer) return false;

		if (this.freeResponseMode) {
			return this._isCorrectFreeResponse(answer);
		} else {
			if (typeof answer == 'string') {
				for (let corrAns of this.correctAnswers) {
					if (corrAns.name == answer) return true;
				}
			} else {
				return this.correctAnswers.includes(answer);
			}
		}

		return false;
	}


	/**
	 * Helper function for `this.isCorrect()`. Tests whether the given answer is one of the correct answers.
	 * Should only be used in free-response mode. Answers are trimmed of whitespace, and
	 * the comparison is case-insensitive.
	 * @param {String} answer - answer to check against the correct answers
	 * @returns {Boolean} Whether the given answer matches a correct answer.
	 * @private
	 */
	_isCorrectFreeResponse(answer = null) {
		let normalizedCorrectAnswer;
		let normalizedAnswer = this._normalizeAnswer(answer);

		for (let correctAnswer of this.correctAnswers) {
			normalizedCorrectAnswer = this._normalizeAnswer(correctAnswer);
			if (normalizedAnswer === normalizedCorrectAnswer) {
				return true;
			}
		}

		return false;
	}


	/**
	 * Gets a normalized version of the given string. Currently, the string is trimmed
	 * of whitespace and converted to lowercase.
	 *
	 * @param  {String} answer - The answer to normalize
	 * @return {String} The normalized answer.
	 */
	_normalizeAnswer(answer) {
		let _answer = answer.trim();
		_answer = _answer.toLowerCase();
		return _answer;
	}



	static import(obj) {
		const newObj = super.import(obj)

	
		if( obj.answers ) {
			
			newObj.answers = []
			for( var ans of obj.answers ) {
				const ansNode = new AnswerNode( ans.name, ans.type )
				newObj.answers.push( ansNode )
				
				// Set correctAnswer Link (currently by name -- could be improved)
				if (newObj.correctAnswer) {
					if(ansNode.name === newObj.correctAnswer) {
						newObj.correctAnswers.push(ansNode);
					}
				}
				if (newObj.correctAnswers) {
					if (newObj.correctAnswers.includes(ansNode.name)) {
						newObj.correctAnswers.push(ansNode);
					}
				}
	
			}

		}

		return newObj
	}



}

export default QuestionNode;