<template>
	<SearchDropdown
    :placeholder="placeholder"
		:DisplayFunction="displayFunction"
		:InitialValue="initialValue"
		:ObjKey="'id'"
		:width="width"
		:browseIcon="true"
		:pages="pages"
		:externalResults="items"
		:dropUp="dropUp"
		@selected="item => select( item )"
		@search="(txt, pg) => getItemList( txt, pg )"
		@confirm="item => confirmSelect( item )"
		@none="select()"
		ref="search"
	/>
</template>


<script>
import GenericTagsAPI from '@/api/GenericTagsAPI.js'
import SearchDropdown from "@/components/utilities/SearchDropdown.vue"

export default {
	name: "GenericTagSearchDropdown",



	components: {
		SearchDropdown,
	},



	props: {

		tagType: {
			type: String,
			required: true
		},

		width: {
			type: String,
			default: '100%'
		},

		placeholder: {
      type: String,
      default: 'Search Tags'
    },

		initialValue: {
			type: String,
			default: null
		},

		dropUp: {
			type: Boolean,
			default: false
		},
	},



	model: {
		prop: "item",
		event: "update",
	},



	data() {
		return {
			selectedItems: [],

			items: [],
			pages: 1,
		};
	},



	methods: {

		confirmSelect( item ) {
			this.select( item )
		},

		select( item = null ) {
			this.$emit( "update", item )
		},

    clear() { this.$refs.search.clear() },

		displayFunction( tagString ) {
			if( !tagString ) return ""
			return tagString
		},

		async getItemList( searchTerm, page = 1 ) {
			this.items = await GenericTagsAPI.getTags( this.tagType, searchTerm )
		},
	},
};
</script>
