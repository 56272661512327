<template>
<section>
	
	<div class="flex-row">
		<div>User:</div>
		<div class="ml-05 mb-05">
			<b>
				<div>{{ modalMessageResponse.userFirstName }} {{ modalMessageResponse.userLastName }}</div>
				<div>{{ modalMessageResponse.userEmail }}</div>
			</b>
		</div>
	</div>

	<div id="body" class="mb-05" v-html="`&ldquo;${modalMessageResponse.body}&rdquo;`" />
	<div class="timestamp">created: {{ modalMessageResponse.createTimestamp.toLocaleString() }}</div>
	<div class="timestamp">edited: {{ modalMessageResponse.editTimestamp.toLocaleString() }}</div>

</section>
</template>


<script>
export default {
	name: 'ModalMessageResponseDetails',

	props: {
		modalMessageResponse: Object,
	},
}
</script>


<style scoped>
#body {
	color: #555;
	font-size: 1.5em;
	font-style: italic;
	max-width: 7in;
}
</style>