import LandingPageSlide from './LandingPageSlide.js';


class LandingPageSliderSection {


  // Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
  vueID = 0

  type = 'slider'
  

  constructor( name = '', slides = [] ) {
    this.vueID = this.type + ++LandingPageSliderSection.idCounter
    this.name = name;
    this.slides = slides;
  }



  addSlide() { this.slides.push( new LandingPageSlide() ); }

  swapSlides(idxa, idxb) {
    if(idxa < 0 || idxb < 0 || idxa >= this.slides.length || idxb >= this.slides.length) return;
    this.slides[idxa] = this.slides.splice(idxb, 1, this.slides[idxa])[0];
  }

  deleteSlide(index) { this.slides.splice(index, 1); }



  clone() {
    return LandingPageSliderSection.import(this.export());
  }

  isSame(obj) {
    return JSON.stringify(this.export()) == JSON.stringify(obj.export());
  }

  getSlide(id) {
    return this.slides.find(elem => elem.id == id);
  }



  static import(obj) {
    // console.debug("LandingPageSliderSection.import()");
    const name = obj.name || '';
    let slides = [];
    for(let slide of obj.slides) slides.push( LandingPageSlide.import(slide) );
    return new LandingPageSliderSection(name, slides);
  }



  export() {
    // console.debug("LandingPageSliderSection.export()");
    const data = {
      type: this.type,
      name: this.name,
      slides: []
    }
    for(let slide of this.slides) data.slides.push(slide.export())
    return data;
    // return JSON.parse(JSON.stringify(this));
  }



}

export default LandingPageSliderSection;
