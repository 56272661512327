import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar'
import {ECOMM_SANDBOX, ECOMM_PRODUCTION} from './Config'

Vue.use(VCalendar);

Vue.config.productionTip = false


//-----------------------
// Prototype Overrides
//-----------------------
import MathExtensions from '@/libraries/MathExtensions.js';
MathExtensions.init();



// Accept.js
var script = document.createElement('script');
document.head.appendChild(script);

if (ECOMM_SANDBOX) {
  window.acceptKey = '9Aj9cw5rKZAW36AxVjgV6LaZQdfZaUE3SE364jEHjC6k3Jb8wZK77X2B2Rd3vuAn',
  window.acceptLoginID = '4gE3fF4m3d3j'
  script.src="https://jstest.authorize.net/v1/Accept.js"

} else if (ECOMM_PRODUCTION) {
  window.acceptKey = '5GMRC47Ufn9Kas5Ff43crfRSjSN8Um8rLypZwF7H2g74NUEEqkVH8Mg2ya45kbqh';
  window.acceptLoginID = 'x9de33j';
  script.src="https://js.authorize.net/v1/Accept.js"
}



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.VueStore = store



// Pre-load store (if already logged in when loading app -- see login.vue for login process)
import { preloadStore } from './basicUtils.js'
preloadStore( store )