<template>
<section>

<ObjectTable
  :Source='demoRequests'
  :Columns='columns'
  :Deletable='true'
  :DisplayFunction='displayFunction'
  :SortBy="sortBy"
  :SortAsc="sortAsc"
  @edit='showEditRequest'
  @delete='deleteRequest'
  @sort='sort'
/>

<p><button @click="newDemoRequest"><span class="icon-plus" /> Create Demo Request</button></p>

<StretchModal width="80%" padding="2em" :clickToClose="false" ref="detailsModal">
  <template #header>Demo Request Details</template>
  <section v-if="selectedDemoRequest">
    <div>Request Received: {{ selectedDemoRequest.timestamp.toLocaleString() }}</div>
    <p><input type="text" placeholder="Name" v-model="selectedDemoRequest.name" /></p>
    <p><input type="text" placeholder="Organization" v-model="selectedDemoRequest.organization" /></p>
    <p><input type="text" placeholder="Email" v-model="selectedDemoRequest.email" /></p>
    <p><input type="text" placeholder="Phone" v-model="selectedDemoRequest.phone" /></p>
    <p><input type="text" placeholder="Requested Schedule" v-model="selectedDemoRequest.schedule" /></p>
    <p><input type="datetime-local" placeholder="Demo Date" v-model="selectedDemoRequest.demoDateString" /></p>
    <p><textarea placeholder="Notes" v-model="selectedDemoRequest.notes" /></p>
    <p v-if="editNewDemo"><button @click="saveCreateRequest">Save</button></p>
    <p v-else><button @click="saveEditRequest">Save</button></p>
  </section>
</StretchModal>


</section>
</template>


<script>
import DemoAPI from '@/api/DemoAPI.js'
import DemoRequest from './DemoRequest.js'

import StretchModal from '@/components/utilities/StretchModal.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'

export default {
  name: 'DemoPane',

  components: {
    StretchModal,
    ObjectTable,
  },

  data() {
    return {
      demoRequests: [],
      selectedDemoRequest: null,
      editNewDemo: false,
      sortBy: 'timestamp',
      sortAsc: true,
      columns: [
        {
          displayName: 'ID',
          propertyName: 'id',
          sortable: true
        },
        {
          displayName: 'Request Received',
          propertyName: 'timestamp',
          sortable: true
        },
        {
          displayName: 'Name',
          propertyName: 'name',
          sortable: true
        },
        {
          displayName: 'Requested Time',
          propertyName: 'schedule',
          sortable: true
        },
        {
          displayName: 'Demo Date',
          propertyName: 'demoDate',
          sortable: true
        },
        {
          displayName: 'Notes',
          propertyName: 'notes',
          sortable: true
        }
      ],

    }
  },

  created() {
    this.initialize();
  },


  methods: {
    async initialize() {
      var data = await DemoAPI.getDemoRequests();
      this.demoRequests = [];
      for(let demo of data) {
         this.demoRequests.push( DemoRequest.import(demo) );
      }
    },

    sort(propName) {
      if(propName == this.sortBy) this.sortAsc = !this.sortAsc;
      else this.sortAsc = true;
      this.demoRequests.sort((a,b) => {
        if(a[propName] < b[propName]) return this.sortAsc ? -1 : 1;
        if(a[propName] > b[propName]) return this.sortAsc ? 1 : -1;
        else return 0;
      })
      this.sortBy = propName;
    },

    showEditRequest(demo) {
      this.selectedDemoRequest = demo;
      this.$refs.detailsModal.open()
    },

    async saveEditRequest() {
      await DemoAPI.editDemoRequest(this.selectedDemoRequest.export());
      this.selectedDemoRequest = null;
      this.editNewDemo = false;
      this.$refs.detailsModal.close()
    },

    newDemoRequest() {
      const demo = new DemoRequest();
      this.editNewDemo = true;
      this.showEditRequest(demo);
    },

    async saveCreateRequest() {
      await DemoAPI.createDemoRequest(this.selectedDemoRequest.export());
      this.$refs.detailsModal.close()
      this.selectedDemoRequest = null;
      this.editNewDemo = false;
      this.initialize();
    },

    async deleteRequest(demoRequest) {
      const yes = confirm(`Are you sure you want to delete Demo Request ${demoRequest.id}?`);
      if(yes) await DemoAPI.deleteDemoRequest(demoRequest.id);
      this.initialize();
    },

    displayFunction(source, propName) {
      if(propName == 'id') return source.id;
      if(propName == 'timestamp') return source.timestamp ? source.timestamp.toLocaleString() : '';
      if(propName == 'name') return `<div style="line-height: 1.4;">${source.name}<br />${source.organization}<br />${source.email}<br />${source.phone}</div>`;
      if(propName == 'schedule') return source.schedule;
      if(propName == 'demoDate') return `${source.demoDate.toLocaleString()}`
      if(propName == 'notes') return source.notes;
    },

  }
}
</script>


<style scoped>
table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ccc;
  padding: 0.5em;
}
</style>
