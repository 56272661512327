<template>
<section>


	<div class="center mt-1">
		Type:
		<select v-model="orderType">
			<option value="">SELECT</option>
			<option value="proposal">Proposal</option>
			<option value="invoice">Invoice</option>
			<option value="order">Order (pay now)</option>
		</select>
	</div>

	<div v-if="orderType == 'order'" class="center">
		Fulfill Order?:
		<select v-model="fulfillOrder">
			<option v-if="allowFulfill" value="true">Yes</option>
			<option value="false">No</option>
		</select>
	</div>

	<br>

	<div v-if="orderType">
		<div class="flex-row flex-justify-center flex-align-center flex-gap-05">
			<div>User: <span v-if="externalUser">{{user.firstName}} {{user.lastName}} {{user.emailAddress || ""}}</span></div>
			<UserSearchDropdown v-if="!externalUser" width="25em" @updateUser="user => selectUser(user)" />
		</div>
		<div class="flex-row flex-justify-center flex-align-center flex-gap-05 mt-05">
			<div>Customer: <span v-if="externalCustomer">{{externalCustomer.name}} ({{externalCustomer.address || ''}} {{externalCustomer.city || ''}}, {{externalCustomer.state || ''}} {{externalCustomer.zip || ''}})</span></div>
			<CustomerSearchDropdown v-if="!externalCustomer" width="25em" v-model="customer" />
		</div>
	</div>


	<br><br>


	<fieldset v-if="showProducts" id="ProductList">
		<legend>Select Product(s)</legend>
		<div class="center">
			Find a Product:
			<SearchBox @dynamicSearch="updateSearchTerm" @search="updateSearchTerm" /> &nbsp;
			<span v-if="!showAllProducts" class="link" @click="showAllProducts = true">show all</span>
			<span v-else class="link" @click="showAllProducts = false">show searched only</span>
		</div>
		<div class="TablePane" v-if="products.length">
			<table>
				<thead>
					<tr>
						<th>SKU</th>
						<th>Name</th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="product in products" @click.stop="addToCart(product)" :key="product.sku">
						<td>{{product.sku}}</td>
						<td>{{product.name}}</td>
						<td v-if="product.shortDescription.length < 400" v-html="product.shortDescription" />
						<td v-else-if="product.description.length < 400" v-html="product.description" />
						<td v-else class="center">[ description too long ]</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div v-else-if="!showAllProducts && !searchTerm" class="prodSearchMsg">Search for a product above</div>
		<div v-else class="prodSearchMsg">No Products Found</div>
	</fieldset>


	<br><br>


	<fieldset v-if="showCart" id="ShoppingCart">
		<legend>Shopping Cart</legend>
		<CartItemEditor v-for="(item,index) in cartItems" :key="item.vueID" :CartItem="item" @removeFromCart="removeFromCart(index)" />
	</fieldset>


	<div v-if="orderReady" class="rightButton">
		<span class="totalPrice">${{totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2})}}</span> &nbsp; &nbsp;
		<div v-if="errorMessage" id="ErrorMessage">{{errorMessage}} <span class="link" @click="clearMessage()">[clear]</span></div>
		<button v-else-if="!orderID" class="button" @click="createInvoice" :disabled="!orderReady">Create {{orderType}}</button>
		<button v-if="orderID" class="button" @click="displayInvoice">Display {{orderType}}</button>
	</div>

	<StretchModal padding="2em" :showModal="$data.priv_processPayment && orderID && !suppressPaymentModal ? true : false" @close="suppressPaymentModal = true" ref="processPayment">
		<template #header>Payment Details</template>
		<select v-model="useExistingCard">
			<option value="false">Use New Credit Card</option>
			<option value="true">Use Saved Credit Card</option>
		</select>

		<div v-if="(user || customer) && !$data.priv_useExistingCard">
			<PayInvoice :orderID="orderID" :hasSubscription="hasSubscription" :totalPrice="totalPrice" :userID="user ? user.userID : null" :customer="customer" :fulfill="$data.priv_fulfillOrder" />
		</div>
	</StretchModal>

</section>
</template>

<script>
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from "@/features/SalesManagement/Customers/CustomerSearchDropdown.vue"
import SearchBox from "@/components/utilities/SearchBox.vue"
import PayInvoice from './PayInvoice.vue'
import CartItemEditor from './CartItemEditor.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

import EcommerceAPI from '@/api/EcommerceAPI.js'
import CartItem from './CartItem.js'

export default {
	name: 'CreateOrderPane',

	components: {
		UserSearchDropdown,
		CustomerSearchDropdown,
		SearchBox,
		PayInvoice,
		CartItemEditor,
		StretchModal,
	},



	props: {
		externalUser: {
			type: Object,
			default: null
		},
		externalCustomer: {
			type: Object,
			default: null
		},
	},



	data() {
		return {

			searchTerm: '',
			showAllProducts: false,
			cartItems: [],
			orderType: '',
			priv_processPayment: true,
			priv_fulfillOrder: true,
			priv_useExistingCard: false,
			suppressPaymentModal: false,

			orderID: 0,
			user: null,
			customer: null,
			errorMessage: '',
		}
	},



	created() {
		this.initialize();
	},



	computed: {
		products() {

			if(!this.$store.state.Products.objects) return []
			if(!this.searchTerm && !this.showAllProducts) return []


			let prods = [];
			let term = this.searchTerm ? this.searchTerm.toLowerCase() : ''

			for(let prod of this.$store.state.Products.objects) {
				if(
					term
					&& !prod.name.toLowerCase().includes(term)
					&& !prod.sku.toLowerCase().includes(term)
					&& !prod.shortDescription.toLowerCase().includes(term)
					&& !prod.description.toLowerCase().includes(term)
				) continue
				prods.push(prod)
			}
			return prods
		},


		hasSubscription() {
			for(let item of this.cartItems) {
				if(item.isSubscription) return true;
			}
			return false;
		},

		allowFulfill() {
			return this.$data.priv_processPayment == true || !this.hasSubscription
		},

		displayUser() {
			return this.user ? `${this.user.firstName} ${this.user.lastName} (${this.user.emailAddress})` : '';
		},

		totalPrice() {
			let accumulator = 0;
			for(let Item of this.cartItems) accumulator += parseFloat(Item.getTotalInitialCost());
			return accumulator
		},


		showProducts() {
			if(this.orderType == 'order' && !this.user) return false
			if(!this.user && !this.customer) return false
			return this.orderType != ''
		},

		showCart() {
			return this.showProducts && this.cartItems.length
		},


		orderReady() {
			for(let item of this.cartItems) if(!item.pricingOptionID) return false         // All items must have pricing option
			if( this.user == null && (this.$data.priv_processPayment || this.$data.priv_fulfillOrder) ) return false    // Must have a user to pay/fulfill
			return (this.user || this.customer) && this.orderType && this.cartItems.length // Must have user|customer, orderType, & items in cart
		},


		processPayment: {
			get() { return this.$data.priv_processPayment ? 'true' : 'false' },
			set(value) {this.$data.priv_processPayment = (value == 'true') ? true : false}
		},

		fulfillOrder: {
			get() { return this.$data.priv_fulfillOrder ? 'true' : 'false' },
			set(value) {this.$data.priv_fulfillOrder = (value == 'true') ? true : false}
		},

		useExistingCard: {
			get() { return this.$data.priv_useExistingCard ? 'true' : 'false' },
			set(value) {this.$data.priv_useExistingCard = (value == 'true') ? true : false}
		},
	},


	watch: {

		// By default, only fulfill if they're paying now.
		processPayment(value) {
			this.fulfillOrder = value;
		},

		orderType(value) {
			if(value == 'proposal') {
				this.$data.priv_processPayment = false
				this.$data.priv_fulfillOrder = false

			}else if(value == 'invoice') {
				this.$data.priv_processPayment = false
				this.$data.priv_fulfillOrder = false

			}else if(value == 'order') {
				this.$data.priv_processPayment = true
				this.$data.priv_fulfillOrder = true
			}
		},

	},


	methods: {

		initialize() {
			// this.orderType = 'order'
			if(this.externalUser) {
				this.user = this.externalUser
				// this.orderType = 'invoice'
			}
			if(this.externalCustomer) {
				this.customer = this.externalCustomer
				// this.orderType = 'proposal'
			}
		},

		updateSearchTerm(term) { this.searchTerm = term },


		addToCart(product) {
			let item = new CartItem(product);
			if(product.pricingOptions.length == 1) item.pricingOptionID = product.pricingOptions[0].id
			this.cartItems.push(item)
		},

		removeFromCart(index) {
			this.cartItems.splice(index, 1);
		},


		selectUser(user) { this.user = user },


		updateQuantity(item, event) {
			console.debug(item, event, event.eventPhase)
			item.quantity = parseInt(event.srcElement.value)
		},


		async createInvoice() {
			const errorHandler = (error, details) => {
				this.showOrderError(details);
				alert(`Error while placing order:\n\nDetails: ${error}`)
				throw error;
			}

			// ----- Create payload object -----
			let items = [];
			for(let item of this.cartItems) {
				items.push(item.export());
			}

			let payload = null;
			try {
				payload = {
					productCart: {
						items,
						pricingData: {
							subtotal: this.totalPrice,
							salesTax: 0,
							total: this.totalPrice
						},
					},
					adminMode: true,
					verifyPrice: false,
					processPayment: false,
					fulfillOrder: false,
					isProposal: this.orderType == 'proposal' ? true : false,
				}
				if(this.user)     payload.userID     = this.user.userID;
				if(this.customer) payload.customerID = this.customer.id;

			} catch (error) {
				errorHandler(error);
			}

			try {
				const res = await EcommerceAPI.placeOrder(payload)
				this.orderID = res.orderID;

				this.errorMessage = "Order placed successfully";

			} catch (error) {
				if (error.response && error.response.data && error.response.data.description) {
					errorHandler(error, "ERROR while processing payment. Details: " + error.response.data.description);
				} else {
					errorHandler(error, "ERROR while placing your order!");
				}
			}

		},

		showOrderError(details) {
			this.errorMessage = details;
		},

		clearMessage() {
			this.errorMessage = ''
		},

		displayInvoice() {
			const routeData = this.$router.resolve({name: 'displayinvoice', params: {orderID: this.orderID}});
			window.open(routeData.href, '_blank');
		}
	},



}
</script>

<style scoped>
#ProductList {
	padding: 1em 0 0 0;
}
.prodSearchMsg {
	text-align: center;
	font-size: 14pt;
	color: #888;
	margin-top: 1em;
}

.TablePane {
	max-height: 300px;
	overflow: auto;
	margin-top: 1em;
}
table {width: 100%; border-collapse: collapse; }
thead {
	position:sticky;
	top:0;
}

th, td { border: 1px solid #aaa; padding: 0.1em 0.55em;}
td { padding: 0.5em; }

tr { cursor: pointer; }
tr:nth-child(even) {
	background-color: #f7f7f7;
}
tr:nth-child(odd) {
	background-color: #fff;
}
tr:hover {
	background-color: #ddf !important;
}



#ShoppingCart {
	max-height: 400px;
	overflow: auto;
}


#PlaceOrder {
	margin-top: 2em;
	/* float: right; */
	text-align: right;
}

.totalPrice {
	font-size: 14pt;
	font-weight: bold;
}

.rightButton {
	margin-top: 0.5em;
	/* float: right; */
	text-align: right;
}
</style>
