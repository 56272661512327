
export default class LoadingQuote {

  constructor(quote="", priority=1, id=null) {
    this.quote = quote;
    this.priority = priority;
    this.id = id;
  }

  export() {
    let data = {};
    data.quote = this.quote;
    data.priority = parseInt(this.priority);
    return data;
  }

  isSame(quote) {
    if (this.quote === quote.quote && this.priority === quote.priority) return true;
    return false;
  }

}
