<template>
<section>
  <div class="flex-row flex-align-center flex-justify-end py-05">
    <button class="button inline-block" @click="newItem">New Sales Rep</button>
  </div>


  <div class='flex justify-center' v-if='showSpinner'>
    <div class='icon-spinner4 spin-loader'></div>
  </div>

  <div v-if='!showSpinner'>

    <div v-if='items.length'>
      <ObjectTable
        class='font-small'
        :Source='items'
        :Columns='columns'
        :DisplayFunction='displayFunction'
        :Numbered='false'
        :SortBy='sortBy'
        :SortAsc='sortAsc'
        Deletable
        @delete='deleteItem'
        @edit="editItem"
        @sort='selectSortBy'
        ref="objectTable"
      />
    </div>
    <div v-else class="NoResults">No results</div>
  </div>

  <StretchModal ref="detailsModal" :clickToClose="false" @close="$refs.objectTable.deselect()">
    <template #header>Sales Rep Details</template>
    <SalesRepresentativeDetails v-if="currentItem" :itemIn="currentItem" :isNew="isNew" @itemAdded="itemAdded" @itemEdited="itemEdited" class="pa-1" />
  </StretchModal>

</section>
</template>



<script>
import ObjectTable from '@/components/utilities/ObjectTable';
import SalesRepresentativeDetails from './SalesRepresentativeDetails.vue';
import StretchModal from '@/components/utilities/StretchModal.vue'

import SalesRepresentative from './SalesRepresentative.js';
import SalesManagementAPI from '@/api/SalesManagementAPI.js';

export default {
  name: 'SalesRepresentativeList',


  components: {
    ObjectTable,
    SalesRepresentativeDetails,
    StretchModal,
  },

  data() {
    return {
      items: [],
      currentItem: null,
      columns: [
        {
          displayName: 'id',
          propertyName: 'id',
          sortable: true
        },
        {
          displayName: 'First Name',
          propertyName: 'firstName',
          sortable: true
        },
        {
          displayName: 'Last Name',
          propertyName: 'lastName',
          sortable: true
        }
      ],

      sortBy: 'id',
      sortAsc: true,
      showSpinner: false,
      isNew: false,
    }
  },

  async created() {
    this.initialize();
  },


  methods: {

    async initialize() {
      this.showSpinner = true;
      this.items = [];
      let res = await SalesManagementAPI.getSalesRepresentatives();
      for(let item of res) this.items.push(SalesRepresentative.import(item));
      this.showSpinner = false;
    },

    editItem(item) {
      this.isNew = false;
      this.currentItem = item;
      this.$refs.detailsModal.open()
    },

    async deleteItem(item) {
      let yes = confirm(`Really delete Sales Rep '${item.firstName} ${item.lastName}'?`);
      if(!yes) return;

      let index = 0;
      for(let obj of this.items) {
        if(obj.id == item.id) break;
        index++;
      }
      this.items.splice(index, 1);
      if(!item.id) return;

      await SalesManagementAPI.deleteSalesRepresentative(item.id);
    },


    newItem() {
      this.isNew = true;
      this.currentItem = new SalesRepresentative();
      this.$refs.detailsModal.open()
    },


    itemAdded(addedItem) {
      this.currentItem = addedItem;
      this.items.push(addedItem);
      this.isNew = false;
    },


    itemEdited(changedItem) {
      this.currentItem = changedItem;

      for(let i=0; i<this.items.length; i++) {
        if(this.items[i].id != changedItem.id) continue;
        this.$set(this.items, i, changedItem)
        return;
      }
    },


    displayFunction(item, prop) {
      return item[prop];
    },

    selectSortBy(prop) {
      if (prop == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = prop;
      }
    }
  },



}
</script>



<style scoped>

</style>
