<template>
<StretchModal :showModal="show" :height="null" :width="null" :closeIcon="false" :fullscreen="false" ref="modal">
	<div class="body">
		<slot></slot>
	</div>
	<div class="flex-row flex-justify-center">
		<div style="flex-grow:1" class="dialogButton cancel" @click.stop="doCancel()">{{cancel}}</div>
		<div style="flex-grow:1" class="dialogButton confirm" :class="{'highlight': highlight}" @click.stop="doConfirm()">{{confirm}}</div>
	</div>
</StretchModal>
</template>


<script>
export default {
	name: 'ConfirmDialog',

	components: {
		StretchModal: () => import('@/components/utilities/StretchModal.vue'),
	},


	props: {
		show: {
			type: Boolean,
			default: false
		},

		cancel: {
			type: String,
			default: "Cancel"
		},

		confirm: {
			type: String,
			default: "Confirm"
		},

		highlight: {
			type: Boolean,
			default: true
		}
	},



	methods: {

		open() {
			this.$refs.modal.open()
		},



		doConfirm() {
			this.$emit('confirm')
			this.$refs.modal.close()
		},

		doCancel() {
			this.$emit('cancel')
			this.$refs.modal.close()
		},

	}


}
</script>


<style scoped>
.body {
	background: #eee;
	padding: 1.5rem;
	text-align: center;
	border-radius: 0.5em;
}
.dialogButton {
	text-align: center;
	padding: 1em 0.25em;
	cursor: pointer;
	font-weight: bold;
}

.cancel {
	background: #ccc;
	border-bottom-left-radius: 5px;
	border-right: 3px solid #eee;
}
.cancel:hover {
	background: #ddd;
}

.confirm {
	background: #ccc;
	border-bottom-right-radius: 5px;
}
.confirm:hover {
	background: #ddd;
}
.confirm.highlight {
	color: white;
	background: var(--ekno-blue);
}
.confirm.highlight:hover {
	background: var(--ekno-blue-35);
}
</style>
