<template>
<section id="events">
	
	<h2 v-if="exceptionSummary">Exceptions Summary (24 hrs)</h2>
	<section v-if="exceptionSummary" class="flex-row flex-justify-center flex-gap-2 flex-wrap">
		<div v-for="(obj, key) in exceptionSummary" :key="key" @click="searchFromSummary( key )" class="pointer summaryButton">
			<div style="font-size: 16pt; font-weight: bold;">{{ obj.value }}</div>
			<div>{{ obj.label }}</div>
		</div>
	</section>
	
	<div class="py-05 mb-1 flex-row flex-align-center flex-gap-1 sticky-tools">
		<v-date-picker v-model="startDate" mode="dateTime">
			<template v-slot="{ inputValue, inputEvents }">
				<input
				placeholder="Start"
				:value="inputValue"
				v-on="inputEvents"
				/>
			</template>
		</v-date-picker> - 
		<v-date-picker v-model="endDate" mode="dateTime">
			<template v-slot="{ inputValue, inputEvents }">
				<input
				placeholder="End"
				:value="inputValue"
				v-on="inputEvents"
				/>
			</template>
		</v-date-picker>
		<div>Label / IP: <SearchBox :placeholder="eventsLabel" @search="searchEvents" /></div>
	</div>



	<section v-if="!busy && !error && eventsList.length > 0">
		<div v-for="event in eventsList" :key="event.eventID"
			class="event"
			:class="{'error': event.isError, 'active': event.showDetails}"
		>
			<div v-if="event.showDetails" style="position:absolute; right: 5px; top: 5px;" class="icon-cross" @click.stop="event.showDetails = false" />
			<div @click="toggleDetails(event)" class="pointer eventTitle w-100 flex-row flex-align-start">
				<span class="title">{{event.eventLabel}}</span>
				<span class="date">{{event.time.toLocaleString()}}</span>
				<span class="preview" v-if="!event.showDetails">{{event.preview}}</span>
			</div>

			<div v-if="event.showDetails" class="eventDetails">
				<p class="flex-row flex-align-start flex-gap-1">Details: <code v-html="event.details" /></p>
				<p class="flex-row flex-align-start flex-gap-1">Tags: <code>{{event.tags}}</code></p>
				<p class="flex-row flex-align-start flex-gap-1">Error: <code>{{event.isError}}</code></p>
				<p class="flex-row flex-align-start flex-gap-1">DTO: <code>{{event.dtoClassName}}</code></p>
			</div>
		</div>
		<div v-if="showLoadMore"><button @click.stop="loadMoreEvents()">Load More</button></div>
	</section>
	<div v-else-if="busy" class="flex-row flex-justify-center"><span class="icon-spinner4 spin1" style="font-size: 2em; color: var(--ekno-blue);" /></div>
	<div v-else-if="error" class="Warning">Error loading events</div>
	<div v-else class="NoResults">No Matching Events</div>


</section>
</template>

<script>
import EventMetricsAPI from '@/api/EventMetricsAPI.js'
import SearchBox from '@/components/utilities/SearchBox.vue'

export default {
	name: 'EventLog',

		components: {
		SearchBox
	},

	data() {
		return {
			eventsList: [],
			eventsLabel: '',

			exceptionSummary: null,

			startDate: null,
			endDate: null,

			showLoadMore: true,
			busy: false,
			error: false,

			eventsTS: 0,
			metricsTS: 0,
			intervalID: null,
			reloadDelay: 60, // in seconds
		}
	},

	created() {
		this.getExceptionSummary();
		this.getEvents();
	},


	mounted() {
		this.intervalID = window.setInterval(this.update, this.reloadDelay*1000);
	},

	beforeDestroy() {
		window.clearInterval(this.intervalID);
	},


	computed: {
		start() {
			if (!this.startDate) return null;
			return Math.floor(this.startDate.getTime()/1000);
		},

		end() {
			if (!this.endDate) return null;
			return Math.floor(this.endDate.getTime()/1000);
		}
	},


	watch: {
		startDate(val) { console.debug(val) },
		endDate(val) { console.debug(val) },
		start() { this.showLoadMore = true; this.getEvents(this.eventsLabel, this.start, this.end) },
		end() { this.showLoadMore = true; this.getEvents(this.eventsLabel, this.start, this.end) },
	},


	methods: {

		async update() {
			console.debug("Updating...");
			let delayMS = this.reloadDelay * 1000;
			let now = new Date().getTime();
			let start = Math.floor(this.eventsTS / 1000);
			let end = this.end || Math.floor(now / 1000);

			if(this.eventsTS + delayMS < now) await this.getEvents(this.eventsLabel, start, end, false);
		},


		async getExceptionSummary() {
			const data = await EventMetricsAPI.getExceptionSummary()
			console.debug(data);
			this.exceptionSummary = data
		},


		async loadMoreEvents() {
			let lastEvent = this.eventsList[this.eventsList.length -1]
			let events = await EventMetricsAPI.getEvents(this.eventsLabel, this.start, this.end, lastEvent.eventID)

			let i = 0
			for(let event of events) {
				event.showDetails = false
				event.time = new Date(event.time * 1000)
				this.eventsList.push(event)
				i++
			}
			if(i < 100) this.showLoadMore = false
		},


		async getEvents(label = '', start = null, end = null, erase = true) {
			this.busy = true
			this.error = false

			try {
				if(erase) this.eventsList = [];
				var newEvents = [];
				let events = await EventMetricsAPI.getEvents(label, start, end);
	
				let i = 0
				for(let event of events) {
					event.showDetails = false;
					event.time = new Date(event.time * 1000);
					if (erase) this.eventsList.push(event);
					else newEvents.push(event)
					i++
				}
				if(i < 100) this.showLoadMore = false
	
				if(!erase) this.eventsList.unshift(...newEvents);
	
				this.eventsTS = new Date().getTime();

			} catch(e) {
				this.error = true
				throw e
				
			} finally {
				this.busy = false
			}
		},


		searchFromSummary( label ) {
			this.eventsLabel = label
			this.searchEvents( label )
		},


		searchEvents(label = '') {
			this.eventsLabel = label;
			this.showLoadMore = true
			this.getEvents(label, this.start, this.end)
		},


		async toggleDetails(event) {
			if(event.showDetails) {
				event.showDetails = false
				return
			}

			const evt = await EventMetricsAPI.getEvent(event.eventID)
			if(evt && evt.details) event.details = evt.details
			else console.error('failed to get event details')

			event.showDetails = !event.showDetails
		}

	}

}
</script>

<style scoped>
#events {
	min-height: 100vh;
	max-width: calc(100vw - 16em);
}

.event {
	border-left: 5px solid #48c;
	margin-bottom: 0.5em;
	position: relative;
	width: 100%;
}

.eventDetails {
	overflow: auto;
	padding: 0.35em 0 0.35 2em;
	margin: 0 10px;
	font-size: 0.9em;
}
.eventTitle {
	overflow: auto;
	padding: .35em;
}


.event:hover, .event:active, .event.active {
	background: #e8f4ff;
	/* cursor: pointer; */
	transition: border 0.25s;
}
.event.error {
	border-left-color: red;
}
.event.error:hover, .event.error:active, .event.error.active {
	border-left-color: red;
	background: #FAEDED;
}
.event .date {
	color: #555;
	font-size: 0.85em;
	margin-left: 3em;
	white-space: nowrap;
}

.event .preview {
	color: #ccc;
	overflow: hidden;
	white-space: nowrap;
	/* max-width: 50vw; */
	padding: 0 1em 0 3em;
}

.event:hover .preview {
	color: #aaa;
}

.event .title {
	font-weight: bold;
	white-space: nowrap;
}

.event code {
	display: inline-block;
	background: #fff;
	/* border: 1px solid red; */
	padding: 0.25em;
	white-space: pre;
}
h1 {
	border-bottom: 1px solid #ccc;
}
.summaryButton {
	border: 1px solid white;
	padding: 0.5em;
	border-radius: 0.5em;
}
.summaryButton:hover {
	border: 1px solid #ccc;
	background-color: #eee;
}
</style>