<template>
<section>
	<div class="flex-row flex-justify-around">
		<div class="flex-column flex-align-center">
			<div class="head">{{ userSession.firstName }} {{ userSession.lastName }}</div>
			<div>{{ userSession.email }}</div>
			<div>License: {{ userSession.licenseKey }}</div>
		</div>

		<div class="flex-column flex-align-center">
			<div>{{ userSession.startTimestamp.toLocaleDateString() }}</div>
			<div>{{ userSession.startTimestamp.toLocaleTimeString() }} - {{ userSession.endTimestamp.toLocaleTimeString() }}</div>
			<div class="head">{{ userSession.durationString }}</div>
		</div>
	</div>

	<section class="flex-row flex-align-start flex-justify-around flex-gap-2 mt-1 pt-1 border-top">
		<div>
			<div class="head center mb-1">Activity During Session</div>
			<LessonsList :userID="userSession.userID" :licenseKey="userSession.licenseKey" :courseID="userSession.courseID" :startDate="userSession.startTimestamp" :endDate="userSession.endTimestamp" />
		</div>

		<div>
			<div class="head center">Statistics</div>
			<div v-if="sessionStats">
				<div v-for="(value, propertyName) in sessionStats" :key="propertyName">
					{{ propertyName }}: {{ value }}
				</div>
			</div>
			<div v-else-if="loadingStats" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
			<div v-else-if="statsError" class="warning">Failed to get details</div>
		</div>

	</section>
</section>
</template>



<script>
import LoginTrackingAPI from '@/api/LoginTrackingAPI.js'
// import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
// import StudentStatisticsRequest from '@/features/StudentStatistics/StudentStatisticsRequest.js'
// import TreeNode from '@/features/CourseCore/Classes/TreeNode.js'
// import QuestionNode from '@/features/CourseCore/Classes/QuestionNode.js'

// import LessonWidget from '@/features/StudentStatistics/LessonWidget.vue'
import LessonsList from '@/features/StudentStatistics/LessonsList.vue'

export default {
	name: 'UserSessionDetails',


	components: {
		// LessonWidget,
		LessonsList,
	},


	props: {
		userSession: Object,
	},


	data() {
		return {
			sessionStats: null,
			loadingStats: false,
			statsError: false,

			// sessionDetails: null,
			// loadingDetails: false,
			// detailsError: false,
		}
	},


	created() { this.initialize() },


	methods: {
		async initialize() {
			this.getStatistics()
			// this.getDetails()
		},


		async getStatistics() {
			this.statsError = false
			this.loadingStats = true
			try {
				const data = await LoginTrackingAPI.getUserSessionActivity( this.userSession.sessionID )
				this.sessionStats = data

			} catch(e) {
				this.statsError = true
			} finally {
				this.loadingStats = false
			}
		},


		// async getDetails() {
		// 	this.loadingDetails = true
		// 	try {
		// 		const req = new StudentStatisticsRequest( this.userSession.userID, this.userSession.licenseKey, this.userSession.startTimestamp, this.userSession.endTimestamp )
		// 		const res = await StudentStatisticsAPI.getLessonsWorkedOn( req )
		// 		const itemsCompleted = res.data.completed

		// 		this.sessionDetails = []
		// 		for( var item of res.data.completed ) {
		// 			if( item.type == 'question' ) this.sessionDetails.push( QuestionNode.import( item ) )
		// 			else this.sessionDetails.push( TreeNode.import( item ) )
		// 		}
			
		// 	} catch(e) {
		// 		this.detailsError = true
		// 		throw e

		// 	} finally {
		// 		this.loadingDetails = false
		// 	}
		// }
	}
}
</script>



<style scoped>
.head {
	font-size: 1.5em;
	font-weight: bold;
}
</style>