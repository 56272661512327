<template>
<section class="mt-1" :class="{ 'mb-2' : expanded }">
	
	<section class="flex-row flex-justify-start flex-align-center">
		<div v-if="progressData.children.length" :class="{ 'arrow-down' : expanded, 'arrow-right' : !expanded }" @click="expanded = !expanded" />
		<div class="title">{{ progressData.name }} - {{ percentComplete }} ({{ progressData.complete }}/{{ progressData.total }})</div>
	</section>
  <section class='Widget'>
    <div class="blue progress" :style="'width:'+percentComplete" />
  </section>

	<section v-if="expanded" class="ml-2">
		<PlanProgressBar v-for="child in progressData.children" :key="child.name" :progressData="child" />
	</section>

</section>
</template>

<script>
export default {
  name: 'PlanProgressBar',

  props: {
		progressData: Object,
  },

	data() {
		return {
			expanded: false,
		}
	},

  computed: {
    percentComplete() {
			var decimal = this.progressData.complete / this.progressData.total
			
			if( isNaN( decimal ) ) decimal = 0
			const pct = Math.round( decimal * 1000 ) / 10
			
			return pct + '%'
    },
  },

}
</script>



<style scoped>

.Widget {
  position: relative;
  border-radius: 5px;
  color: white;
  background: #ddd;
  width: calc(100%);
  height: .5em;
  align-items: center;
  transition: all ease-in-out 0.2s;
}

.title {
  color: #666;
  text-align: left;
  line-height: 1.5em;
  margin-bottom: 0.25em;
  z-index: 2;
}
.progress {
  height: .5em;
  border-radius: 5px;
}

.blue {
  background: rgb(57,57,176);
  background: linear-gradient(45deg, rgba(57,57,176,1) 34%, rgba(0,212,255,1) 100%);
}

</style>
