import API from './API.js';
import PaginatedResponse from './PaginatedResponse.js';


class CohortAPI extends API {

	static controllerName = 'CohortAPI'

	static async getCustomerCohorts(customerID) {
		const data = await this.newGet('getCustomerCohorts', [customerID])
		return data
	}


	static async searchCohorts( req ) {
		const res = await this.newPost( 'searchCohorts', [], req )
		return res.data
	}


	static async getCohortsByID( cohortIDs ) {
		const res = await this.newPost( 'getCohortsByID', [], cohortIDs )
		PaginatedResponse.import( res.data )
	}
	

}

export default CohortAPI