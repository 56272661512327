import Exim from '@/models/Exim.js'


class LandingPageChatSection extends Exim {


  // Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
  vueID = 0

  type = 'chat'
  name = 'Tawk.to Chat'
  enabled = true



  constructor() {
    super()
    this.vueID = this.type + ++LandingPageChatSection.idCounter
  }



  clone() {
    return LandingPageChatSection.import(this.export());
  }

  isSame(obj) {
    return JSON.stringify(this.export()) == JSON.stringify(obj.export());
  }

}

export default LandingPageChatSection;
