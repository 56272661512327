<style scoped>
.CardWrapper {
  margin: 0 1em 0.5em 1em;

}
.card {
  border-radius: 0.5em;
  border: 1px solid #ccc;
  box-shadow: 0 10px 8px -10px #ccc;
  margin: 0 0 0.25em 0;
  font-size: 11pt;
  color: #666;
  text-align: right;
  min-width: 150px;
}
legend {
  color: #555;
  font-weight: bold;
  font-style: italic;
}
.column {
  padding: 0 1em;
  /* text-align: right; */
}
.column:not(:last-child) {
  border-right: 1px solid #ccc;
}
.header {
  text-align: center;
  font-size: 0.8em;
  color: var(--ekno-blue);
}
.Line {
  margin: 0.25rem 0.25rem;
  white-space: nowrap;
  /* text-align: right; */
}
.Number { font-size: 1.1em; color: #444; }
.SubtotalLine .Number { font-size: 1.2em; }
.TotalLine .Number { font-size: 1.3em; color: #4a4a4a; font-weight: bold; }
.red { color: red; }
.small { font-size: 0.75em; }
</style>


<template>
<div>
  <h1 v-if="title" class="center mt-05">{{ title }}</h1>
  <div class='flex-row flex-justify-center'>

    <section class="CardWrapper">
      <fieldset id="OrdersCard" class="card">
        <legend>Orders</legend>
        <div class="Line"><span class="Number">{{stats.orgOrderCount}}</span>&thinsp;&nbsp; orgs</div>
        <div class="Line"><span class="Number">{{stats.indivOrderCount}}</span> other</div>
        <hr>
        <div class="TotalLine"><span class="Number">{{stats.orgOrderCount+stats.indivOrderCount}}</span> total</div>
        <div class="Line small red" v-if="stats.indivUnfulfilledOrderCount || stats.orgUnfulfilledOrderCount">({{stats.indivUnfulfilledOrderCount + stats.orgUnfulfilledOrderCount}} unfulfilled)</div>
      </fieldset>
      <div v-if="showLinks" class="center link" @click.stop="$emit('gotoFindOrders', 'unfulfilled')">{{stats.unfulfilledOrderCount}} unfulfilled</div>
    </section>

    <section class="CardWrapper">
      <fieldset id="LicensesCard" class="card">
        <legend>Licenses</legend>
        <div class="header">New / Renewal</div>
        <div class="Line"><span class="Number">{{stats.orgNewLicenseCount}} / {{stats.orgRenewedLicenseCount}}</span>&thinsp;&nbsp; orgs</div>
        <div class="Line"><span class="Number">{{stats.indivNewLicenseCount}} / {{stats.indivRenewedLicenseCount}}</span> other</div>
        <hr>
        <div class="Line TotalLine"><span class="Number">{{stats.orgNewLicenseCount+stats.indivNewLicenseCount}} / {{stats.orgRenewedLicenseCount+stats.indivRenewedLicenseCount}}</span> total </div>
      </fieldset>
    </section>

    <section class="CardWrapper">
      <fieldset id="RevenueCard" class="card">
        <legend>Revenue</legend>
        <div class="flex-row">
          <div class="column">
            <div class="header">Proposed</div>
            <div class="Line"><span class="Number">{{Math.moneyFormat(stats.orgProposedRevenue)}}</span>&thinsp;&nbsp; orgs</div>
            <div class="Line"><span class="Number">{{Math.moneyFormat(stats.indivProposedRevenue)}}</span> other</div>
            <hr>
            <div class="Line SubtotalLine"><span class="Number">{{Math.moneyFormat(stats.orgProposedRevenue+stats.indivProposedRevenue)}}</span></div>
          </div>
          <div class="column">
            <div class="header">Unpaid</div>
            <div class="Line"><span class="Number">{{Math.moneyFormat(stats.orgUnpaidRevenue)}}</span>&thinsp;&nbsp; orgs</div>
            <div class="Line"><span class="Number">{{Math.moneyFormat(stats.indivUnpaidRevenue)}}</span> other</div>
            <hr>
            <div class="Line TotalLine"><span class="Number">{{Math.moneyFormat(stats.orgUnpaidRevenue+stats.indivUnpaidRevenue)}}</span></div>
          </div>
          <div class="column">
            <div class="header">Paid</div>
            <div class="Line"><span class="Number">{{Math.moneyFormat(stats.orgPaidRevenue)}}</span>&thinsp;&nbsp; orgs</div>
            <div class="Line"><span class="Number">{{Math.moneyFormat(stats.indivPaidRevenue)}}</span> other</div>
            <hr>
            <div class="Line TotalLine"><span class="Number">{{Math.moneyFormat(stats.orgPaidRevenue+stats.indivPaidRevenue)}}</span></div>
          </div>
        </div>

      </fieldset>
      <div v-if="showLinks" class="center">AR: {{Math.moneyFormat(stats.accountsReceivable)}} (<span class="link" @click.stop="$emit('gotoFindOrders', 'unpaid')">{{stats.unpaidOrderCount}} orders</span>)</div>
    </section>

  </div>
</div>
</template>


<script>
import OrdersAPI from '@/api/OrdersAPI.js'

export default {
  name: 'OrdersDashboard',


  props: {

    title: {
      type: String,
      default: null
    },

    startMonth: {
      type: Number,
      default: null
    },

    startYear: {
      type: Number,
      default: null
    },

    endMonth: {
      type: Number,
      default: null
    },

    endYear: {
      type: Number,
      default: null
    },

    affiliateID: {
      type: Number,
      default: null
    },

    showLinks: {
      type: Boolean,
      default: false
    },
  },


  data() {
    return {
      stats : {
        orgUnfulfilledOrderCount: '--',
        indivUnfulfilledOrderCount: '--',
        orgOrderCount: '--',
        indivOrderCount: '--',

        orgRenewedLicenseCount: '--',
        indivRenewedLicenseCount: '--',
        orgNewLicenseCount: '--',
        indivNewLicenseCount: '--',


        orgProposedRevenue: '--',
        indivProposedRevenue: '--',

        orgUnpaidRevenue: '--',
        indivUnpaidRevenue: '--',
        purchaseOrderReceivedRevenue: '--',

        orgPaidRevenue: '--',
        indivPaidRevenue: '--',

        orgProposedCanceledRevenue: '--',
        indivProposedCanceledRevenue: '--',
        orgInvoicedCanceledRevenue: '--',
        indivInvoicedCanceledRevenue: '--',

        accountsReceivable: '--',
        unfulfilledOrderCount: '--',
        unpaidOrderCount: '--',
      },

      initTimeoutID: null,
    }
  },


  created() {
    this.initialize()
  },

  watch: {
    startMonth() { this.scheduleInit() },
    startYear() { this.scheduleInit() },
    endMonth() { this.scheduleInit() },
    endYear() { this.scheduleInit() },
    affiliateID() { this.scheduleInit() },
  },

  methods: {

    // Prevent multiple init requests to server, when multiple props are "settling" at the same time
    // (i.e. when parent components are updating after first render)
    scheduleInit() {
      if(this.initTimeoutID) clearTimeout(this.initTimeoutID)
      this.initTimeoutID = setTimeout(this.initialize, 20)
    },

    async initialize() {

      var data = null
      console.debug(`${this.startYear}-${this.startMonth}-01`, `${this.endYear}-${this.endMonth}-01`)


      // ALL-TIME stats
      if(!this.startYear || !this.startMonth || !this.endYear || !this.endMonth) {
        var date = new Date()
        var year = date.getFullYear() + 1
        data = await OrdersAPI.getOrderStatistics('1970-01-01', `${year}-01-01`, this.affiliateID)


      // MONTH stats
      } else {
        var startMonth = this.startMonth
        var endMonth = this.endMonth

        startMonth = startMonth < 10 ? '0'+startMonth : ''+startMonth
        endMonth = endMonth < 10 ? '0'+endMonth : ''+endMonth

        data = await OrdersAPI.getOrderStatistics(`${this.startYear}-${startMonth}-01`, `${this.endYear}-${endMonth}-01`, this.affiliateID)
      }

      for(let item in this.stats) {
        // console.debug(`${item}:`, data[item]);
        this.stats[item] = data[item]
      }
    }
  }
}
</script>
