/**
 * Teacher-Cohort Link Set
 * Models the list of class/teacher relationships a license has. 
 */
export default class TCLinkSet {
	constructor() {
		this.cohorts = new Map();
		this.teachers = new Map();
		this.updateCounter = 0;
	}

	/**
	 * Create a new Teacher-Cohort link to the license.
	 * 3 possible relationships: 
	 *   1. License assigned to class with teacher(s): cohort & teacher created, teacher added to cohort, teacher's numClasses increments
	 *   2. License assigned to class w/o teachers: cohort created w/ no teacher
	 *   3. License assigned to a teacher directly (no cohortID/name): teacher created with numClasses = 0
	 * @param {Object} link a teacher-cohort relationship to the license
	 */
	createLink(link) {
		let teacher = null;
		if (link.masterUserID) {
			// Add teacher to set if not present, and reassign teacher from set to prevent copies
			teacher = new Teacher(link.masterUserID, `${link.masterFirstName} ${link.masterLastName}`, link.roleName);
			if (!this.teachers.has(teacher.userID)) this.teachers.set(teacher.userID, teacher);
			teacher = this.teachers.get(teacher.userID);
		}

		if (link.cohortID) {
			// Add cohort to set if not present, and reassign cohort from set to prevent copies
			let cohort = new Cohort(link.cohortID, link.cohortName);
			if (!this.cohorts.has(cohort.cohortID)) this.cohorts.set(cohort.cohortID, cohort);
			cohort = this.cohorts.get(cohort.cohortID);
			if (teacher) {
				cohort.addTeacher(teacher);
				teacher.numClasses++;
			}
		}

		this.updateCounter++;
	}

	get cohortsArray() {
		return Array.from(this.cohorts.values());
	}

	get teachersArray() {
		return Array.from(this.teachers.values());
	}

	/**
	 * Returns all teachers that don't have a role over any cohort, but have a role over the license directly
	 * (will not return a teacher that has both a cohort role and a direct role);
	 */
	getNonClassTeachers() {
		return this.teachersArray.filter(teacher => teacher.numClasses == 0);
	}

	clear() {
		this.teachers = new Map();
		this.cohorts = new Map();
		this.updateCounter++;
	}

	export() {
		return {
			teachers: this.teachersArray,
			cohorts: this.cohortsArray
		}
	}
}

class Teacher {
	constructor(userID = null, name = '', roleName = '') {
		this.userID = userID;
		this.name = name;
		this.numClasses = 0;
		this.roleName = roleName
	}
}

class Cohort {
	constructor(cohortID = null, cohortName = null, teachers = []) {
		this.cohortID = cohortID;
		this.cohortName = cohortName;

		this.teachers = new Map();
		teachers.forEach(teacher => this.teachers.set(teacher.useID, teacher));
	}

	/**
	 * Add a teacher to the cohort's teacher map only if not already present
	 * @param {Teacher} teacher the teacher to add
	 */
	addTeacher(teacher) {
		if (!this.teachers.has(teacher.userID)) this.teachers.set(teacher.userID, teacher);
	}

	get teachersArray() {
		return Array.from(this.teachers.values);
	}
}