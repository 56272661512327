<template>
<section>
<div v-for="(item, index) of actionItems" class="flex-row flex-align-start flex-justify-start ActionItem" :class="{'complete' : item.isComplete}" :key="item.id">
  <input type="checkbox" v-model="item.isComplete" @change="toggleComplete(item, $event)" />
  <div style="flex-grow:1" class="ActionItemText" :class="{'attention' : item.needsAttention(), 'pastDue' : item.isPastDue}">
    <div contenteditable="true" :class="{'strikethrough' : item.isComplete}" @input="setName(index, $event)">{{names[index] || 'Event Name'}}</div>
    <div contenteditable="true" :class="{'strikethrough' : item.isComplete}" @input="setDetails(index, $event)">{{details[index] || 'details'}}</div>
    <div>Due:
      <v-date-picker v-if="!item.isComplete" v-model="item.dateDueString" mode="dateTime" @input="setDateDue(index)">
        <template v-slot="{ inputValue, inputEvents }">
          <input
          class=""
          placeholder="Due Date"
          :value="inputValue"
          v-on="inputEvents"
          />
        </template>
      </v-date-picker>
      <span v-else>{{item.dateDueString}}</span>
    </div> <!-- due date -->
    <div v-if="item.isComplete">Completed on {{item.dateCompletedString}} by user #{{item.completedByUserID}}</div>
  </div> <!-- body wrapper -->
  <button @click.stop="deleteActionItem(item, index)"><span class="icon-bin" /></button>
</div> <!-- v-for -->
</section>
</template>


<script>
import ActionItem from '../ActionItems/ActionItem.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'

export default {
  name: 'ActionItemList',

  props: {
    hostID: {
      type: Number,
      default: null
    },
    hostType: {
      type: String,
      default: 'customer'
    }
  },


  data() {
    return {
      actionItems: [],
      names: [],
      details: [],
    }
  },


  created() { this.initialize() },


  watch: {
    hostID() { this.initialize() },
    hostType() { this.initialize() },
  },


  methods: {

    async initialize() {
      // Populate ActionItems (for existing customer/host)
      this.actionItems = []
      if(this.hostID) {
        const complete = await SalesManagementAPI.getActionItemsByHost(this.hostID, this.hostType, true)
        const incomplete = await SalesManagementAPI.getActionItemsByHost(this.hostID, this.hostType, false)
        this.actionItems = incomplete.concat(complete)
      }
      for(let i=0; i < this.actionItems.length; i++) {
        this.names[i] = this.actionItems[i].name
        this.details[i] = this.actionItems[i].details
      }
    },



    addActionItem() {
      if(!this.hostID || !this.hostType) {
        alert(`You must save the ${this.hostType || 'host item'} before adding tasks!`)
        return
      }
      const item = new ActionItem()
      item.hostType = this.hostType
      item.hostID = this.hostID
      this.actionItems.unshift( item )
			this.names.unshift(null)
			this.details.unshift(null)
    },

    async saveActionItem(actionItem) {
      if(actionItem.id) await SalesManagementAPI.updateActionItem(actionItem)
      else await SalesManagementAPI.createActionItem(actionItem)
    },

    toggleComplete(actionItem, event) {
      console.debug(event.target.checked)
      if(event.target.checked) this.markActionItemComplete(actionItem)
      else this.markActionItemUncomplete(actionItem)
    },

    async markActionItemComplete(actionItem) {
      actionItem.complete()
      await SalesManagementAPI.markActionItemComplete(actionItem.id, actionItem.completedTimestamp)
    },

    async markActionItemUncomplete(actionItem) {
      actionItem.uncomplete()
      await SalesManagementAPI.updateActionItem(actionItem)
    },

    async deleteActionItem(actionItem, index) {
      const yes = confirm(`Are you sure you want to delete task "${actionItem.name}"?`)
      if(!yes) return

      if(actionItem.id) await SalesManagementAPI.deleteActionItem(actionItem.id)
      this.actionItems.splice(index, 1)
      this.names.splice(index, 1)
      this.details.splice(index, 1)
    },

    setName(index, event) {
      const actionItem = this.actionItems[index]
      this.names[index] = event.target.innerText
      actionItem.name = event.target.innerText
      console.debug(`Updated actionItem: ${actionItem.name}`)
      this.saveActionItem(actionItem)
    },
    setDetails(index, event) {
      const actionItem = this.actionItems[index]
      this.details[index] = event.target.innerText
      this.actionItems[index].details = event.target.innerText
      console.debug(`Updated actionItem: ${actionItem.name}`)
      this.saveActionItem(actionItem)
    },
    setDateDue(index) {
      const actionItem = this.actionItems[index]
      console.debug(`Updated actionItem: ${actionItem.name}`)
      this.saveActionItem(actionItem)
    }
  }

}
</script>


<style scoped>
.ActionItem {
  margin-bottom: 1em;
}
.ActionItemText {
	padding: 3px;
}
.ActionItem.complete {
  color: gray;
}
.complete .strikethrough {
	text-decoration:line-through;
}

.attention {
	background: #ffddaa;
}
.pastDue {
	background: #faa;
	border: 3px solid red;
}
</style>
