import API from "./API";
import { base_url } from './../Config';
import User from '@/features/Users/User.js'
import axios from 'axios'


export default class UserAPI extends API {

    static controllerName = 'UserAPI'


    static async getUsers( req ) {
        let url = `${base_url}/SiteAdmin/UsersAPI/getUsers`;

        let data = await super.add(url, req);
        if (!data) throw new Error("Could not get users from server. Changes will not be saved.");
        return data
    }

    static async getUsersByID( userIDs ) {
        const url = `${base_url}/SiteAdmin/UsersAPI/getUsersByID`;
        return await this.sendJSON( url, userIDs, false )
    }

    static async getUser(userID, throwException = true) {
        const url = `${base_url}/SiteAdmin/UsersAPI/getUser/${userID}`;
        const res = await super.get(url);
        

        if (!res && throwException) throw new Error(`UserAPI.getUser(${userID}): invalid response from server`);
        if( !res ) return res
        return User.import(res);
    }


    /**
	 * @static async - Fetches the current user's roles (with feature grants (permissions)).
	 * @return {array}  A list of objects containing role data.
	 */
	static async getRoles() {
		const url = `${base_url}/UserAPI/getRoles`;
		const data = await super.get( url );
		return data;
	}


    static async getNonces( userID ) {
        let url = `${base_url}/SiteAdmin/UsersAPI/getNonces/${ userID }`;
        return await super.get( url )
    }


    /**
	 * Fetches the given (or current) user's timezone.
	 * @return {object} An object containing information about the current user's timezone.
	 */
	static async getTimezone(userID = null) {
		return await this.newGet( 'getTimezone', userID ? [userID] : null );
	}

    static _importUsers(json) {
        let users = [];
        for (let u of json) {
            users.push(User.import(u));
        }

        return users;
    }

    static async addUser(user, sendEmail = false) {

        let url = base_url + '/SiteAdmin/UsersAPI/addUser';
        if (sendEmail) url += '/' + sendEmail;
        let res = await super.add(url, user.export());

        return User.import(res);
    }

    static async editUser(user, sendEmail = false) {

        if (!user.userID) {
            throw new Error("Invalid ID.");
        }
        let url = base_url + '/SiteAdmin/UsersAPI/editUser/' + user.userID;
        if (sendEmail) url += '/' + sendEmail;
        let res = await super.edit(url, user.export());
        return User.import(res);
    }

    static async deleteUser(user) {

        if (!user.userID) {
            throw new Error("Invalid ID.");
        }
        let url = base_url + '/SiteAdmin/UsersAPI/deleteUser/' + user.userID;
        return await super.delete(url);
    }


    static async resetPassword(user, initializeOnly = false) {

        if (!user.userID) {
            throw new Error("Invalid ID.");
        }
        if (!user.newPassword) {
            throw new Error("Invalid password.  Changes not saved.");
        }

        let url = base_url + '/SiteAdmin/UsersAPI/setPassword/' + user.userID;
        if (initializeOnly) url += '/true';
        return await this.sendJSON(url, { password: user.newPassword });
    }


    static async checkUserExists(email) {
        const url = `${base_url}/SiteAdmin/UsersAPI/checkUserExists/${email}`;
        return await super.get(url);
    }


    static async bulkImportStudents(courseID, file, preflight = false) {
        let url = base_url + '/CourseLibraryAPI/bulkImportStudents/' + courseID + '/siteAdmin';
        if (preflight) url += '/true'

        return await axios.post(url, file, { withCredentials: true })
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw new Error(error.response.data);
            });
    }


    static async areUsersLoggedIn(userIDs) {
        let url = base_url + "/CourseLibraryAPI/areUsersLoggedIn/";
        try {
            let res = await super.add(url, userIDs);
            return res;
        } catch (e) {
            console.log(e);
        }
    }


    static async requestPasswordReset(email) {
        let url = base_url + '/UserAPI/getPasswordResetEmail';
        let res = await this.add(url, { email: email })
        if (res) return res.status
        else return null
    }


    static async sendNewUserEmail(userID) {
        const url = `${base_url}/SiteAdmin/UsersAPI/sendNewUserEmail/${userID}`;
        await this.add(url);
    }


    static async getNumUsersLoggedIn() {
        const url = `${base_url}/SiteAdmin/UsersAPI/getNumUsersLoggedIn/`;
        return await this.get(url);
    }


    static async getDailyUniqueUsers() {
        return await this.get(`${base_url}/SiteAdmin/UsersAPI/getDailyUniqueUsers/`)
    }
}
