import Exim from '@/models/Exim.js'

class BlastList extends Exim {

	
	id = null
	name = null
	description = null
	dateCreated = null
	dateModified = null

	contacts = []
	mailMergeTags = []

	numContacts = null

	// Computed number of each contact modality in this list.
	numEmails = null
	numPhones = null
	numApps = null
	numUsers = null
	numPOCs = null



	get stringValue() {
		return `Blast List: ${ this.name } (${ this.numContacts } contacts)`
	}



	static import( obj ) {
		return super.import( obj, [], [ 'dateCreated', 'dateModified' ] )
	}


	export() {
		return super.export( [], [ 'dateCreated', 'dateModified' ] )
	}


}

export default BlastList