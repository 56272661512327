import API from './API.js';


class LongJobAPI extends API {

	static controllerName = 'LongJobAPI'

	
	static async getJobProgress( jobID ) {
		const data = await this.newGet( 'getJobProgress', [ jobID ] )
		return data
	}



}
export default LongJobAPI;