import StudentStatisticsRequest from './StudentStatisticsRequest.js'



class StudentStatisticsGraphRequest extends StudentStatisticsRequest {

	// Graph
	graphUnit = null     // hour | day | week | month | year

}
export default StudentStatisticsGraphRequest