<template>
  <div class="eknowLogoCont">
    <img src="@/assets/images/eknowledge-logo-white.png" alt="eKnowledge">
  </div>
</template>

<script>
export default {
  name: 'LoginHeader'
}
</script>

<style scoped>

</style>
