<template>
<section>
	
	<div class="StickySaveButton">
		<button class="pillButton" @click="save()" :class="{ 'disabled' : !changesMade }" :disabled="!changesMade">
			Save Changes
		</button>
	</div>

	<section>
		<div class="flex-row flex-align-center flex-justify-center flex-gap-1">
			<ToggleButton v-model="newItem.enabled" />
			<span style="font-size: 2em; font-weight: bold">Route is {{ newItem.enabled ? "Enabled" : "Disabled" }}</span>
		</div>
		<div>Admin Label: <input v-model="newItem.label" /></div>
		<div>Description:<br><textarea v-model="newItem.description" /></div>
		
		<div class="flex-row flex-justify-center flex-align-start flex-gap-3 mt-2">
			
			<!-- Sources -->
			<div>
				<div class="sectionTitle mb-1">Sources: <button class="pillButton secondary bg" @click="addSource()"><span class="icon-plus" /></button></div>
				<LocationEditor v-for="source in newItem.sources" :key="source.vueID" :location="source" :showWeight="false" @delete="deleteSource( source )" />
			</div>


			<!-- Destinations -->
			<div>
				<div class="sectionTitle mb-1">Destinations: <button class="pillButton secondary bg" @click="addDestination()"><span class="icon-plus" /></button></div>
				<LocationEditor v-for="destination in newItem.destinations" :key="destination.vueID" :location="destination" :showWeight="newItem.destinations.length > 1" :totalWeight="totalWeight" @updateWeight="updateTotalWeight()" @delete="deleteDestination( destination )" />
			</div>

		</div>

	</section>


	<!-- Recent activity on this route! -->
	<section class="mt-3 hr-light" />
	<section class="mt-3">
		<h2 class="center mt-0">Recent Traffic</h2>
		<div v-if='!loading && !error'>
			
			<div v-if='items.length'>
				<ObjectTable
					class='font-small'
					:Source='items'
					:Columns='columns'
					:Numbered='true'
					:SortBy='sortBy'
					:SortAsc='sortAsc'
					@edit="item => openItem( item )"
					@sort='prop => selectSortBy( prop )'
					ref="objectTable"
				/>
				<Paginator v-model="page" :numPages="pages" />
			</div>

			<div v-else class="NoResults">No results</div>
		</div>

		<div v-if="error" class="warning">Failed to get records</div>

	</section>


	<StretchModal ref="detailsModal" @close="closeItem()" padding="2em">
		<template #header>Request #{{ currentItem.id }}: {{ currentItem.requestFQDN }} <span class="icon-arrow-right2" /> {{ currentItem.destinationFQDN }}</template>
		<ProxyTrafficDetails v-if="currentItem" :item="currentItem" />
	</StretchModal>


</section>
</template>



<script>
import LocationEditor from './LocationEditor.vue'
import { ToggleButton } from 'vue-js-toggle-button'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ProxyTrafficDetails from './ProxyTrafficDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

import ProxyAPI from '@/api/ProxyAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Route from '@/features/Proxy/Route.js'
import LogRecord from '@/features/Proxy/LogRecord.js'

export default {
	name: 'ProxyRouteDetails',


	components: {
		LocationEditor,
		ToggleButton,
		ObjectTable,
		Paginator,
		ProxyTrafficDetails,
		StretchModal,
	},


	props: {
		itemIn: Route,
		isNew: Boolean,
	},


	data() {
		return {
			newItem: Route,
			originalItem: Route,

			totalWeight: 1,


			loading: false,
			error: false,

			searchString: null,
			sortBy: 'timeUpdated',
			sortAsc: false,
			count: 0,
			page: 1,
			pages: 1,
			pageSize: 100,

			items: [],
			currentItem: null,

			columns: [
				{
					displayName: 'Record #',
					propertyName: 'id',
					sortable: true
				},
				{
					displayName: 'Time',
					propertyName: 'timeUpdated',
					displayFunction: item => {
						return item.timeUpdated !== null ? item.timeUpdated.toLocaleString() : null
					},
					sortable: true
				},
				{
					displayName: 'Client IP',
					propertyName: 'clientIP',
					sortable: true
				},
				{
					displayName: 'Chosen Route ID',
					propertyName: 'routeID',
					sortable: true
				},
				{
					displayName: 'Requested URL',
					propertyName: 'requestFQDN',
					sortable: true
				},
				{
					displayName: 'Redirected to URL',
					propertyName: 'destinationFQDN',
					sortable: true
				},
				{
					displayName: 'Response Code',
					propertyName: 'responseCode',
					sortable: true
				},
			],

		}
	},


	created() { this.initialize() },


	computed: {
		changesMade() {
			return this.newItem && this.originalItem && !this.originalItem.isSame(this.newItem)
		},
	},



	watch: {
		page() { this.getRouteActivity() },
	},



	methods: {

		async initialize() {
			this.originalItem = this.itemIn.clone()
			this.newItem = this.itemIn.clone()

			this.updateTotalWeight()
			this.getRouteActivity()
		},






		async getRouteActivity() {

			try {
				this.error = false
				this.loading = true

				this.items = []
				this.currentItem = null

				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				req.routeID = this.newItem.id

				const res = await ProxyAPI.getRouteActivity( req )
				for( let item of res.data.data ) this.items.push( LogRecord.import( item ) )


				this.count = res.data.count
				this.pages = res.data.pages

			} catch (e) {
				this.error = true
				console.error( e.message )

			} finally {
				this.loading = false
			}
			
		},


		selectSortBy( prop ) {
			if ( prop == this.sortBy ) {
				this.sortAsc = !this.sortAsc
				this.getRouteActivity()
				return
			}
			
			
			this.sortBy = prop
			this.sortAsc = true
			if( this.sortBy === 'timeUpdated' ) this.sortAsc = false

			this.getRouteActivity()
		},



		openItem( item ) {
			this.currentItem = item
			this.$refs.detailsModal.open()
		},


		closeItem() {
			this.currentItem = null
			this.$refs.objectTable.deselect()
		},






		async save() {
			const itemToSave = this.newItem.clone();


			if(this.isNew) {

				const idRes = await ProxyAPI.createRoute( itemToSave.export() )
				const data = await ProxyAPI.getRoute( idRes.data.id )
				const newItem = Route.import( data )
				this.$emit( 'itemAdded', newItem )

			} else {

				await ProxyAPI.editRoute( itemToSave.export() )
				this.$emit( 'itemEdited', this.newItem.clone() )

			}
		},


		addSource() {
			this.newItem.createSource()
		},


		deleteSource( source ) {
			const yes = confirm( `Are you sure you want to delete ${ source.getFQDN() }?` )
			if( !yes ) return

			this.newItem.deleteSource( source )
		},


		addDestination() {
			this.newItem.createDestination()
		},


		deleteDestination( dest ) {
			const yes = confirm( `Are you sure you want to delete ${ dest.getFQDN() }?` )
			if( !yes ) return
			
			this.newItem.deleteDestination( dest )
		},


		updateTotalWeight() {
			this.totalWeight = this.newItem.getTotalDestinationWeight()
			console.debug( "Update total weight:", this.totalWeight )
		},

	},

}
</script>



<style scoped>
.sectionTitle {
	font-size: 1.5em;
	font-weight: bold;
}
</style>