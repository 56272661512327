class FileAttachment {

  constructor(fileID, fileName, mimeType, date, fileBlobID) {
    this.fileID = fileID || 0
    this.fileBlobID = fileBlobID || 0
    this.fileName = fileName || ''
    this.mimeType = mimeType || ''
    this.dateCreated = date ? new Date(date*1000) : 0
  }


  get shortname() {
    if(this.fileName.length < 30) return this.fileName
    return this.fileName.slice(0, 27) + '...'
  }


  get mimeIconClass() {
    if(this.mimeType.includes('image')) return 'icon-file-picture'
    if(this.mimeType.includes('video')) return 'icon-file-video'
    if(this.mimeType.includes('audio')) return 'icon-file-music'
    if(this.mimeType.includes('pdf')) return 'icon-file-pdf'
    else return 'icon-file-text2'
  }


  static import(json) {
    return new this(json.fileID, json.fileName, json.mimeType, json.dateCreated, json.fileBlobID)
  }

}

export default FileAttachment
