<template>
<section class="mt-05">

	<div class="flex-row flex-align-center flex-justify-between flex-gap-1">
		
		<div class="flex-row flex-align-center flex-justify-start flex-gap-1">
			<div class="flex-row flex-align-center flex-gap-1">
				product SKU:
				<ProductSearchDropdown v-if="selected" v-model="selectedProduct" :initialValue="discount.sku" />
				<span v-else>{{ discount.sku }}</span>
			</div>

			<div class="flex-row flex-align-center flex-gap-1">
				pricingOption:
				<PricingOptionSearchDropdown v-if="selected && discount.sku" v-model="selectedPricingOption" :productSKU="discount.sku" :initialValue="discount.pricingOptionID" />
				<span v-else>{{ discount.pricingOptionID }}</span>
			</div>

			<div>
				<span v-if="selected">
					<select v-model="discount.type">
						<option :value="null">--</option>
						<option value="percent">% Discount</option>
						<option value="dollar">$ Discount</option>
					</select>
					<input v-model.number="discount.amount" />
				</span>

				<span v-else class="discountTag round-05 pa-05 border">-
					<span v-if="discount.type === 'dollar'">$</span>
					<span>{{ discount.amount }}</span>
					<span v-if="discount.type === 'percent'">%</span>
				</span>
			</div>

			<div class="border-left pl-1 flex-row flex-align-center flex-gap-05">
				<div v-if="selected"><ToggleButton v-model="discount.isSponsored" :sync="true" /> Sponsored Discount</div>
				<div v-else-if="discount.isSponsored" class="sponsoredTag round-05 pa-05 border">Sponsored</div>
			</div>
		</div>

		<div class="flex-row flex-gap-1">
			<button v-if="selected && newRow" class="pillButton secondary bg" @click="$emit('cancel')"><span class="icon-cross" /> Cancel</button>
			<button v-if="selected" class="pillButton" @click="$emit('save')">Done</button>
			<button v-if="!selected" class="pillButton secondary" @click="$emit('edit')">Edit</button>
			<button v-if="!newRow" @click="$emit('remove')"><span class="icon-bin2" /></button>
		</div>

	</div>
	
</section>
</template>



<script>
import ProductSearchDropdown from '@/features/Products/ProductSearchDropdown.vue'
import PricingOptionSearchDropdown from '@/features/Products/PricingOptionSearchDropdown.vue'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: 'DiscountRow',


	components: {
		ProductSearchDropdown,
		PricingOptionSearchDropdown,
		ToggleButton,
	},


	props: {
		discount: Object,

		selected: {
			type: Boolean,
			default: false
		},

		newRow: {
			type: Boolean,
			default: false
		},
	},


	data() {
		return {
			selectedProduct: null,
			selectedPricingOption: null,
		}
	},


	watch: {
		selectedProduct( prod ) {
			if( prod === null ) {
				this.discount.sku = null
				return
			}

			this.discount.sku = prod.sku
		},

		selectedPricingOption( po ) {
			if( po === null ) {
				this.discount.pricingOptionID = null
				return
			}

			this.discount.pricingOptionID = po.id
		}
	},


}
</script>



<style scoped>
.discountTag {
	color: var(--pp10-red);
	background: #fdd;
	font-weight: bold;
}
.sponsoredTag {
	color: green;
	background: rgb(217, 255, 217);
	font-weight: bold;
}
</style>