<template>
<div>

  <!-- Statistics -->
	<section class="flex-row flex-justify-center mt-3">
		<table>
			<tr>
				<th></th>
				<th colspan="6">E-mails</th>
				<th>SMS</th>
				<th colspan="2">App</th>
			</tr>
			<tr>
				<th></th>
				<th>Sent</th>
				<th>Bounced</th>
				<th>Delivered</th>
				<th>Spam / Complaint</th>
				<th>Un-Subscribed</th>
        <th>Opened</th>
				<th>Sent</th>
				<th>Sent</th>
				<th>Opened</th>
			</tr>
			<tr v-if="statsToday">
				<td>Today</td>
        <td>{{ statsToday.email.sent.toLocaleString() }}</td>
        <td>{{ (statsToday.email.bouncedTemporary + statsToday.email.bouncedPermanent).toLocaleString() }}</td>
        <td>{{ statsToday.email.delivered.toLocaleString() }}</td>
        <td>{{ statsToday.email.complaints.toLocaleString() }}</td>
        <td>{{ statsToday.email.unsubscribes.toLocaleString() }}</td>
        <td>{{ statsToday.email.opened.toLocaleString() }}</td>
        <td>{{ statsToday.sms.toLocaleString() }}</td>
        <td>{{ statsToday.app.sent.toLocaleString() }}</td>
        <td>{{ statsToday.app.opened.toLocaleString() }}</td>
			</tr>
			<tr v-if="statsWeek">
				<td>7 Days</td>
        <td>{{ statsWeek.email.sent.toLocaleString() }}</td>
        <td>{{ (statsWeek.email.bouncedTemporary + statsWeek.email.bouncedPermanent).toLocaleString() }}</td>
        <td>{{ statsWeek.email.delivered.toLocaleString() }}</td>
        <td>{{ statsWeek.email.complaints.toLocaleString() }}</td>
        <td>{{ statsWeek.email.unsubscribes.toLocaleString() }}</td>
        <td>{{ statsWeek.email.opened.toLocaleString() }}</td>
        <td>{{ statsWeek.sms.toLocaleString() }}</td>
        <td>{{ statsWeek.app.sent.toLocaleString() }}</td>
        <td>{{ statsWeek.app.opened.toLocaleString() }}</td>
			</tr>
      <tr v-if="statsMonth">
				<td>30 Days</td>
        <td>{{ statsMonth.email.sent.toLocaleString() }}</td>
        <td>{{ (statsMonth.email.bouncedTemporary + statsMonth.email.bouncedPermanent).toLocaleString() }}</td>
        <td>{{ statsMonth.email.delivered.toLocaleString() }}</td>
        <td>{{ statsMonth.email.complaints.toLocaleString() }}</td>
        <td>{{ statsMonth.email.unsubscribes.toLocaleString() }}</td>
        <td>{{ statsMonth.email.opened.toLocaleString() }}</td>
        <td>{{ statsMonth.sms.toLocaleString() }}</td>
        <td>{{ statsMonth.app.sent.toLocaleString() }}</td>
        <td>{{ statsMonth.app.opened.toLocaleString() }}</td>
			</tr>
		</table>
	</section>

</div>
</template>



<script>
import MessageCenterAPI from '@/api/MessageCenterAPI.js'

export default {
  name: 'MessageCenter',


  data() {
    return {
      statsToday: null,
      statsWeek: null,
      statsMonth: null,
    }
  },

  computed: {

  },



  created() { this.getStats() },
  


  methods: {
    async getStats() {
      const endTS = Math.round( new Date().getTime() / 1000 )

      const start = new Date()
      start.setHours(0)
			start.setMinutes(0)
			start.setSeconds(0)
			start.setMilliseconds(0)

      const start1TS = Math.round( start.getTime() / 1000 )
      const res1 = await MessageCenterAPI.getMessageDeliveryStatistics( start1TS, endTS )
      this.statsToday = res1.data

      start.setDate( start.getDate() - 7 )
      const start7TS = Math.round( start.getTime() / 1000 )
      const res7 = await MessageCenterAPI.getMessageDeliveryStatistics( start7TS, endTS )
      this.statsWeek = res7.data

      start.setDate( start.getDate() - 23 )
      const start30TS = Math.round( start.getTime() / 1000 )
      const res30 = await MessageCenterAPI.getMessageDeliveryStatistics( start30TS, endTS )
      this.statsMonth = res30.data
    }
  }
}
</script>



<style scoped>

table {
	border-collapse: collapse;

}
th, td {
	border: 1px solid #888;
	padding: 0.5em;
}
</style>
