import Exim from '@/models/Exim.js'


class MessageTemplate extends Exim {


	id = null
	name = null
	description = null

	subject = null
	body = null
	altBody = null
	
	dateCreated = null
	dateModified = null



	static import( obj ) {
		return super.import( obj, [], [ 'dateCreated', 'dateModified' ] )
	}


	export() {
		return super.export( [], [ 'dateCreated', 'dateModified' ] )
	}


}

export default MessageTemplate