import axios from 'axios'
import {base_url} from "@/Config"
import {powerprep_base_url} from "@/Config"

export default class API {

	static controllerName = ''

  static verifyStatus(code) {
    return code == 200;
  }

  static async get( url, options = {withCredentials:true} ) {
    let res = await axios.get(url, options);
    if (API.verifyStatus(res.status)) return res.data;
    throw new Error(res.status + " response code from server");
  }

  static async newGet( methodName, args = [], options = {withCredentials:true} ) {
		let url = base_url + `/${this.controllerName}/${methodName}`;
    for(let arg of args) {
      if(arg === false || arg == null || arg === '') url += '/';
      else url += `/${arg}`;
    }
    let res = await axios.get(url, options);
    if (API.verifyStatus(res.status)) return res.data;
    throw new Error(res.status + " response code from server");
  }

  static async newPost( methodName, args = [], data = null, responseType = null ) {
    let url = base_url + `/${this.controllerName}/${methodName}`;
    for(let arg of args) {
      if(arg === false || arg == null || arg === '') url += '/';
      else url += `/${arg}`;
    }
    return await this.sendJSON(url, data, false, true, false, responseType)
  }


  static async add( url, item, options = {withCredentials:true} ) {

    let res = await axios.post(url, item, options);
    if (API.verifyStatus(res.status)) return res.data;
    throw new Error(res.status + " response code from server");
  }


  static async edit( url, item, options = {withCredentials:true} ) {

    let res = await axios.put(url, item, options);
    if (API.verifyStatus(res.status)) return res.data;
    throw new Error(res.status + " response code from server");
  }

  static async newEdit( methodName, args = [], data = null ) {
		let url = base_url + `/${this.controllerName}/${methodName}`;
    for(let arg of args) {
      if(arg === false || arg == null || arg === '') url += '/';
      else url += `/${arg}`;
    }
    return await this.sendJSON(url, data, false, true, false )
  }


  static async delete(url, body=null, options = {withCredentials:true}) {

    let res;
    if (body) {
      res = await axios.post(url, body, options);
    }
    else {
      res = await axios.get(url, options);
    }
    if (API.verifyStatus(res.status)) return res.data;
    throw new Error(res.status + " response code from server");
  }

  static async newDelete( methodName, args = [], data=null, options = {withCredentials:true} ) {
    let url = base_url + `/${this.controllerName}/${methodName}`;
    for(let arg of args) {
      if(arg === false || arg == null || arg === '') url += '/';
      else url += `/${arg}`;
    }

    let res;
    if (data) {
      res = await axios.post(url, data, options);
    }
    else {
      res = await axios.get(url, options);
    }
    if (API.verifyStatus(res.status)) return res.data;
    throw new Error(res.status + " response code from server");
  }





  static async save(url, item, options = {withCredentials:true}) {

    let res = await axios.put(url, item, options);
    if (API.verifyStatus(res.status)) return res.data;
    throw new Error(res.status + " response code from server");
  }

  static async sendJSON(url, data, returnData = true, throwOnError = true, nullOnError = false, responseType = null) {

    const req = {
      url: url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data),
      withCredentials: true
    }
    if( responseType ) req.responseType = responseType

    let res = await axios( req );

    let retVal = returnData ? res.data : res;
    if (API.verifyStatus(res.status)) return retVal;
    if(throwOnError) throw new Error(res.status + " response code from server");
    if(nullOnError) return null;
    return retVal;
  }

  static async login(data) {
    let bodyFormData = new FormData();
    bodyFormData.set("email", data.email);
    bodyFormData.set("password", data.password);
    let url = base_url + "/SiteAdmin/UsersAPI/login";
    let res = await axios({
      url: url,
      method: 'POST',
      data: bodyFormData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      withCredentials: true
    });
    return res.data;
  }

  static async impersonate(email) {
    let bodyFormData = new FormData();
    bodyFormData.set("email", email);
    let url = powerprep_base_url + "/UserAPI/impersonate";
    let res = await axios({
      url: url,
      method: 'POST',
      data: bodyFormData,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
      withCredentials: true
    });
    return res.data;
  }

  static async logout(){
    try {
      await axios.get(base_url + '/UserAPI/logout', {
        withCredentials: true
      });
      return true;
    } catch (e) {
      console.error("Error logging out: " + e);
      return false;
    }
  }


  static processResponseAsFileDownload( data ) {
    const downloadURL = window.URL.createObjectURL( data )
    window.open(downloadURL, '__blank')
    window.URL.revokeObjectURL(downloadURL)
  }

}
