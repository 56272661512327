<template>
<section class='flex-column' style="width: 100%;">

  <p v-if="isTemplate" style="font-weight: bold">Slider</p>
  <input type="text" v-if="isTemplate" v-model="section.name" placeholder="Slider Label (admin)" />
  <div v-else>{{section.name}}<span style="color: #888; font-size: 0.8em;"> - Slider / Carousel</span></div>

  <!-- Slides -->
  <section class="mt-1">

    <!-- Each Slide -->
    <div v-for="(slide, index) in section.slides" :key="slide" class="slide">
      <div v-if="isTemplate" style="border-right: 1px solid lightgray; padding: 1em 0.5em 1em 0;">
        <div @click.prevent="swapSlides(index, index-1)" class="icon-arrow-up" />&nbsp;
        <div @click.prevent="swapSlides(index, index+1)" class="icon-arrow-down" />
      </div>&nbsp;

      <div class="flex-column w-100 my-1">
        <textarea v-model="slide.quote" @input="update(slide)" placeholder="Quote" :class="{'default': slide.isDefault || false}" />
        <input type="text" v-model="slide.name" @input="update(slide)" placeholder="Person's Name" :class="{'default': slide.isDefault || false}" />
        <input type="text" v-model="slide.position" @input="update(slide)" placeholder="Person's Position" :class="{'default': slide.isDefault || false}" />
        <input type="text" v-model="slide.imageName" @input="update(slide)" placeholder="Image file name" :class="{'default': slide.isDefault || false}" />
      </div>
      <span class="icon-cross" v-if="isTemplate" style="position: absolute; right: 0.5em; top: 0.5em;" @click.stop="deleteSlide(index)" />
      <div v-if="!isTemplate && !(slide.isDefault || false)" id="revert" @click="revertSlide(slide, index)"><span class="icon-undo" /> Revert Slide</div>
    </div> <!-- /Each Slide -->

    <!-- <div v-if="!isTemplate && !isDefault" id="revert" @click="revert"><span class="icon-undo" /> Revert</div> -->
  </section> <!-- /Slides -->


  <!-- "Add Slides" Button -->
  <div v-if="isTemplate" style="text-align: right;">
    <button @click.stop="section.addSlide()" class="my-1"><span class="icon-plus" /> Add Slide</button>
  </div>

</section>
</template>


<script>
export default {
  name: "LandingPageSliderSection",

  props: {
    section: Object,

    isTemplate: {
      type: Boolean,
      default: true
    },

    landingPage: {
      type: Object,
      default: null
    },

    // isDefault: {
    //   type: Boolean,
    //   default: false
    // },
  },

  // data() {
  //   return {
  //     isDefault: true,
  //   }
  // },

  created() {
    for(let slide of this.section.slides) this.compareSlideToOriginal(slide);
    // console.debug(this.section, this.lpSection);
  },

  computed: {
    lpSection() {
      if(!this.landingPage) return null;
      return this.landingPage.sections.find(elem => elem.name == this.section.name) || null;
    }
  },



  methods: {
    deleteSlide(index) {
      const choice = confirm("Really delete this slide?");
      if(choice) this.section.deleteSlide(index);
    },

    swapSlides(idxa, idxb) {
      this.section.swapSlides(idxa, idxb);
      this.update();
    },

    compareSlideToOriginal(slide) {
      const lpSlide = this.lpSection ? this.lpSection.getSlide(slide.id) : null;
      slide.isDefault = lpSlide && slide.isSame(lpSlide);
    },

    update(slide) {
      this.compareSlideToOriginal(slide);
      this.$emit('update');
    },

    revert() { this.$emit('revert'); },
    revertSlide(slide, index) {
      const lpSlide = this.lpSection.getSlide(slide.id);
      if(!lpSlide) return;

      this.$set(this.section.slides, index, lpSlide);
    },
  }


}
</script>


<style scoped>
input, textarea { padding: 0.5em !important; }
.slide {
  position: relative;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: #f6f6f6;
  margin: 1em 2em;
  padding: 0.5em;
  display: flex;
}
.slide input, .slide textarea {
  margin: 0.5em 2em;
}
.default {
  color: #777;
}
#revert {
  cursor: pointer;
}
</style>
