<template>
<section>
	<div class="flex-row flex-align-center flex-justify-between my-05">
		<SearchBox @search="str => searchString = str" />
		
		<div class="flex-row flex-align-center flex-justify-end flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} contact "blast" lists on {{ pages }} page{{ pages == 1 ? '' : 's' }}</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
			<div class="flex-row flex-align-center flex-gap-1">
				<button class="pillButton" @click="createList()"><span class="icon-plus" /> New List</button>
				<button class="pillButton" @click="$refs.uploadModal.open()">New List from CSV</button>
			</div>
		</div>

	</div>


	<div v-if='!loading && !error'>

		<div v-if='items.length'>
			<ObjectTable
				:Source='items'
				:Columns='columns'
				:Numbered='true'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:Deletable='true'
				@edit='item => select( item )'
				@sort='prop => sort( prop )'
				@delete='item => showDeleteItem( item )'
				ref='objectTable'
			/>
			<Paginator v-model="page" :numPages="pages" @input="pg => toPage( pg )" />
		</div>

		<div v-else class="NoResults">No results</div>

	</div>

	<div v-if="error" class="warning">Failed to get records</div>



	<StretchModal ref="blastListDetailsModal" padding="1em" width="max(80vw, 400px)" @close="deselect()">
		<template #header v-if="selectedItem">Blast List #{{ selectedItem.id }}: {{ selectedItem.name }}</template>
		<BlastListDetails :list="selectedItem" />
	</StretchModal>

	<StretchModal ref="uploadModal" padding="1em" maxWidth="600px">
		<template #header>New List from CSV</template>
		<div>
			<div>Built-in Columns: <b>userID | pocID | name | email | phone</b></div>
			<div>Custom Mail-Merge Columns: <b>Any name; spaces will be replaced with underscores ( _ )</b></div>
			<div><input type="text" v-model="uploadName" class="w-100 font-size-1-5 mt-1" placeholder="List Name" /></div>
			<div><textarea v-model="uploadDescription" class="w-100 mt-1 round-05 border pa-05" placeholder="List Description (optional)" /></div>
			<form enctype="multipart/form-data" ref="fileForm" class="flex-row flex-justify-between flex-align-center mt-1">
				<input type="file" name="file" ref="fileInput" @change="uploadCSV()" style="width: 100%" />
				<div v-if="uploading" class="icon-spinner4 spin-loader inline-block" />
			</form>
			<div v-if="uploadError" class="Warning my-1" v-html="uploadError" />
		</div>
	</StretchModal>

	<ConfirmDialog ref="confirmDelete" @cancel="itemToDelete = null" @confirm="deleteItem()">
		<div v-if="itemToDelete">Really delete <b>{{ itemToDelete.name }}</b>?</div>
	</ConfirmDialog>
</section>
</template>



<script>
import BlastListDetails from './BlastListDetails.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import BlastList from './BlastList.js'

export default {
	name: 'BlastListList',


	components: {
		BlastListDetails,
		SearchBox,
		ObjectTable,
		Paginator,
		StretchModal,
		ConfirmDialog,
	},



	data() {
		return {
			page: 1,
			pages: 1,
			pageSize: 100,
			count: 0,
			sortBy: 'dateModified',
			sortAsc: false,
			searchString: null,

			items: [],
			loading: false,
			error: false,

			uploadName: null,
			uploadDescription: null,
			uploading: false,
			uploadError: null,

			selectedItem: null,
			itemToDelete: null,

			columns: [
				{
					displayName: 'List ID',
					propertyName: 'id',
					sortable: true,
				},
				{
					displayName: 'Name',
					propertyName: 'name',
					sortable: true,
				},
				{
					displayName: 'Description',
					propertyName: 'description',
					sortable: true,
				},
				{
					displayName: 'Total Contacts',
					propertyName: 'numContacts',
					sortable: true,
				},
				{
					displayName: '# Email Contacts',
					propertyName: 'numEmails',
					sortable: true,
				},
				{
					displayName: '# Phone Contacts',
					propertyName: 'numPhones',
					sortable: true,
				},
				{
					displayName: '# In-app Contacts',
					propertyName: 'numApps',
					sortable: true,
				},
				{
					displayName: 'Date Created',
					propertyName: 'dateCreated',
					displayFunction: ( item ) => { return item.dateCreated ? item.dateCreated.toLocaleString() : null },
					sortable: true,
				},
				{
					displayName: 'Date Modified',
					propertyName: 'dateModified',
					displayFunction: ( item ) => { return item.dateModified ? item.dateModified.toLocaleString() : null },
					sortable: true,
				},
			],
		}
	},
	
	
	
	watch: {
		pageSize() { this.page = 1; this.initialize() },
		searchString() { this.page = 1; this.initialize() },
	},



	created() { this.initialize() },



	methods: {

		async initialize() {
			this.items = []
			this.error = false
			this.loading = true

			try {
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				const pr = await MessageCenterAPI.getBlastLists( req )

				this.count = pr.count
				this.pages = pr.pages

				for( var item of pr.data ) this.items.push( BlastList.import( item ) )

			} catch (e) {
				this.error = true
				throw e

			} finally {
				this.loading = false
			}

		},


		async createList() {
			const newList = new BlastList()
			newList.name = "New List"
			const res = await MessageCenterAPI.saveBlastList( newList.export() )
			newList.id = res.data.id
			this.items.push( newList )
		},


		select( list ) {
			this.selectedItem = list
			this.$refs.blastListDetailsModal.open()
		},

		deselect() {
			this.selectedItem = null
			this.$refs.objectTable.deselect()
		},

		showDeleteItem( item ) {
			this.itemToDelete = item
			this.$refs.confirmDelete.open()
		},

		async deleteItem() {
			await MessageCenterAPI.deleteBlastList( this.itemToDelete.id )
			this.items = this.items.filter( elem => elem.id != this.itemToDelete.id )
			this.itemToDelete = null
		},


		toPage( page ) {
			this.page = page
			this.initialize()
		},


		sort( prop ) {
			
			if( this.sortBy === prop ) {
				this.sortAsc = !this.sortAsc
				this.page = 1
				this.initialize()
				return
			}
			
			this.sortBy = prop
			if (
				prop === 'numEmails' ||
				prop === 'numPhones' ||
				prop === 'numApps' ||
				prop === 'dateCreated' ||
				prop === 'dateModified'
			) {
				this.sortAsc = false
			} else {
				this.sortAsc = true
			}

			this.page = 1
			this.initialize()
		},



		async uploadCSV() {
			this.uploadError = null
			let file = this.$refs.fileInput.files[0]

			if(file.type != "text/csv") {
				this.uploadError = "Invalid file type.  Please upload a CSV (comma-separated values) file."
				this.$refs.fileInput.value = ''
				return
			}


			try {
				if( !file ) throw new Error("No CSV file available")
				
				this.uploading = true

				const contents = await file.text()
				await MessageCenterAPI.uploadBlastListCSV( contents, this.uploadName || 'New List', this.uploadDescription )
				this.uploadName = null
				this.uploadDescription = null
				this.initialize()
				this.$refs.uploadModal.close()

			} catch (e) {

				this.uploadError = e.message || e
				throw e

			} finally {
				this.uploading = false
			}
			
		},

	}

}
</script>



<style scoped>

</style>