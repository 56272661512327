<template>
<section id="Interaction">
  <div id="InteractionBox">
    <v-date-picker v-model="interaction.dateString" mode="dateTime" id="DatePicker">
      <template v-slot="{ inputValue, inputEvents }">
        <input
        class=""
        placeholder="Date"
        :value="inputValue"
        v-on="inputEvents"
        />
      </template>
    </v-date-picker>
    <textarea v-model="interaction.notes" class="interactionNotes" placeholder="Take notes here..." />
  </div>
  <FileAttachments v-if="interaction.id" hostType="customerInteraction" :hostID="interaction.id" />
</section>
</template>


<script>
import SalesManagementAPI from '@/api/SalesManagementAPI.js';
import FileAttachments from '@/features/FileAttachments/FileAttachments.vue';

export default {
  name: 'CustomerInteractionComponent',

  components: {
    FileAttachments,
  },

  props: {
    interaction: Object,
  },

  data() {
    return {
      timeoutID: null,
      startChangeTS: 0,
      maxWait: 500,
    }
  },

  computed: {
    timestamp() { return this.interaction.timestamp; },
    notes() { return this.interaction.notes; },
  },

  watch: {
    timestamp() { this.debounceSave(); },
    notes() { this.debounceSave(); },
  },

  methods: {
    debounceSave() {
      if(!this.timeoutID) this.startChangeTS = new Date();
      else {
        if(Date.getTime < this.startChangeTS + this.maxWait) clearTimeout(this.timeoutID);
        else this.startChangeTS = new Date();
      }
      this.timeoutID = setTimeout(this.saveChanges, this.maxWait);
    },

    async saveChanges() {
      try {
        await SalesManagementAPI.editCustomerInteraction(this.interaction.export());
      } catch(e) {
        alert("FAILED to save changes to Customer Interaction.  Please try again.");
      }
      this.timeoutID = null;
    }
  }
}
</script>


<style scoped>
#Interaction {
  border-bottom: 1px solid #ccc;
  margin: 1em 0;
  padding-bottom: 1em;
}
#InteractionBox {
  align-items: flex-start;
  display: flex;

}
#DatePicker {
  max-width: 10em;
}
#DatePicker:hover {
  max-width: 10em;
  cursor: pointer;
}
.interaction:nth-child(odd) {
  /* background-color: #eee; */
}
.interactionNotes {
  height: 5em;
  margin-left: 1em;
  flex-grow: 1;
}

input, textarea {
  border: 1px solid white;
  transition: border 0.25s;
}
input:hover, textarea:hover, input:active, textarea:active {
  border: 1px solid #ddd;
  transition: border 0.25s;
}
</style>
