<template>
<div class="flex-row flex-align-center flex-justify-between" :class="{ 'w-100' : editMode }">

	<div class="flex-row flex-align-center flex-gap-1 w-100">

		<!-- Field(s) -->
		<div class="w-100" :class="{ 'flex-row flex-gap-025' : singleLine }">
			<div v-for="key in editKeys" :key="key" class="w-100">
				<input v-if="editMode" type="text" v-model="item[key]" :placeholder="key" class="w-100" />
				<div v-else>{{ item[key] }}</div>
			</div>
		</div>
		
		<!-- Label dropdown or textbox -->
		<div v-if="labelOptions && editMode">

			<select v-if="!showOtherLabel" v-model="item.label">
				<option :value="null">NO LABEL</option>
				<option disabled :value="null">-----------</option>
				<option v-for="value in labelOptions" :key="value" :value="value">{{ value }}</option>
				<option disabled :value="null">-----------</option>
				<option value="other">Other...</option>
				<option v-if="item.label && !labelOptions.includes( item.label )" :value="item.label">{{ item.label }}</option>
			</select>

			<span v-if="showOtherLabel" class="flex-row flex-align-center">
				<input type="text" v-model="item.label" @keyup.enter="showOtherLabel = false" style="max-width: 8em;" />
				<span id="LabelOkButton" class="pa-05 round-05 pointer" @click="showOtherLabel = false"><span class="icon-checkmark color-green-10" /></span>
			</span>

		</div>
	</div>
	
	<!-- Label (non-edit mode) -->
	<span v-if="labelOptions && !editMode" id="label" class="one-line">{{ item.label }}</span>

</div>
</template>



<script>
import POC from './POC.js'
import PocEmail from './PocEmail.js'
import PocPhone from './PocPhone.js'
import PocAddress from './PocAddress.js'

export default {
	name: 'PocField',

	
	props: {
		item: [POC, PocEmail, PocPhone, PocAddress],

		editKeys: {
			type: Array,
			default: () => []
		},

		editMode: {
			type: Boolean,
			default: false
		},

		singleLine: {
			type: Boolean,
			default: false
		},

		labelOptions: {
			type: Array,
			default: null
		},
	},



	data() {
		return {
			showOtherLabel: false,
		}
	},



	watch: {
		'item.label'( label ) {
			if( label === 'other' ) this.showOtherLabel = true
			else if( Array.isArray( this.labelOptions ) && this.labelOptions.includes( label ) ) this.showOtherLabel = false
			else if( label ) this.showOtherLabel = true
		}
	},

}
</script>



<style scoped>
#LabelOkButton {
	border: 1px solid rgba( 255,255,255, 0);
}
#LabelOkButton:hover {
	border-color: #ddd;
}
#LabelOkButton:hover span {
	color: var(--pp10-green-35);
}

#label {
	font-size: 0.7em;
	/* background: #fff; */
	color: var(--pp10-gray-40);
	/* border-radius: 0.5em; */
	padding: 0.15em 0.5em;
	margin: 0.1em 0.5em;
}
</style>