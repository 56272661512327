<template>
<section>
  <div class="center my-05" style="font-size: 2em;">
    $ <input v-model.number="amount" class="center" style="font-size: 1em; width: 5em;" />
  </div>
  <CreditCardForm :data="creditCardData" :hasSubscription="hasSubscription" :saveCard="saveCardData" @saveCardData="val => saveCardData = val" />

  <div id="PlaceOrder" class="flex-row flex-justify-end">
    <div v-if="errorMessage" id="ErrorMessage" class="text-right">{{errorMessage}} <span class="link" @click="errorMessage = ''">[clear]</span></div>
    <button v-else class="button" @click="submitOrder" :disabled="!ready">
      <span v-if="processingOrder">Processing...</span>
      <span v-else>Place Order (${{amount}})</span>
    </button>
  </div>
</section>
</template>


<script>
import CreditCardForm from './CreditCardForm.vue';
import AcceptAPI from '@/api/AcceptAPI.js';
import EcommerceAPI from '@/api/EcommerceAPI.js';

export default {
  name: 'PayInvoice',

  components: {
    CreditCardForm,
  },

  props: {
    orderID: {
      type: Number,
      required: true
    },
    hasSubscription: {
      type: Boolean,
      required: true
    },
    totalPrice: {
      type: Number,
      required: true
    },
    userID: {
      type: Number,
      required: true
    },
    customer: {
      type: Object,
      default: () => { return {} }
    },
    fulfill: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      creditCardData: {
        cardType: 'unknown card type',
        cardholderName: '',
        cardNumber: null,
        securityCode: null,
        exDateMonth: null,
        exDateYear: null,
        zipCode: null,
      },

      amount: this.$props.totalPrice,

      saveCardData: true,
      processingOrder: false,
      errorMessage: '',

    }
  },

  created() {
    this.initialize();
  },

  computed: {
    ready() {
      const ccd = this.creditCardData;
      return this.amount !== null && ccd.cardholderName && ccd.cardNumber && ccd.securityCode && ccd.exDateMonth && ccd.exDateYear && ccd.zipCode && this.userID
    }
  },

  methods: {

    initialize() {

    },


    async submitOrder() {
      const paymentProfileID = await this.handleCardDataSubmission();
      console.debug("PaymentProfileID:", paymentProfileID);
      this.processOrder(paymentProfileID);
    },

    async handleCardDataSubmission() {
      console.debug("HandleCardDataSubmission");

      try {
        const cardData = {
          cardNumber: this.creditCardData.cardNumber.replace(/-/g, ''),
          month: this.creditCardData.exDateMonth,
          year: this.creditCardData.exDateYear,
          cardCode: this.creditCardData.securityCode,
          fullName: this.creditCardData.cardholderName,
          zip: this.creditCardData.zipCode
        };

        let userID = this.userID;

        const payProf = await AcceptAPI.addPaymentProfile(userID, cardData);
        return payProf.paymentProfileID;

      } catch (error) {
        console.error(error);
        this.showOrderError(error);
      }
    },


    async processOrder(paymentProfileID = null) {
      const errorHandler = (error, details) => {
        this.showOrderError(details);
        alert(`Error while placing order:\n\nDetails: ${error}`)
        this.processingOrder = false;
        throw error;
      }

      this.processingOrder = true;

      let payload = null;
      try {
        payload = {
          // userID: this.user.userID,
          productCart: {
            pricingData: {
              subtotal: this.amount,
              salesTax: 0,
              total: this.amount
            },
          },
          saveCardData: this.saveCardData,
          fulfillOrder: this.fulfill,
          paymentProfileID,
        };
        // if(this.customer) payload.customerID = this.customer.id;
        // payload.paymentProfileID = paymentProfileID;

        console.debug(payload);
      } catch (error) {
        errorHandler(error);
      }

      try {
        console.debug(this.orderID)
        const res = await EcommerceAPI.payInvoice(this.orderID, payload)
        this.$emit("paymentSuccess");
        this.errorMessage = "Order placed successfully";

      } catch (error) {
        this.processingOrder = false;
        if (error.response && error.response.data && error.response.data.description) {
          errorHandler(error, "ERROR while processing payment. Details: " + error.response.data.description);
        } else {
          errorHandler(error, "ERROR while placing your order!");
        }
      }

    },

    showOrderError(details) {
      this.errorMessage = details;
    },
  }


}
</script>
