<template>
<section>
  <select v-model='selectedMonth'>
    <option disabled>Select a month</option>
    <option v-for='(month, index) in months' :key='index' :value='index'>{{ month }}</option>
  </select>&nbsp;

  <select v-model='selectedYear'>
    <option disabled>Select a year</option>
    <option v-for='(year, index) in years' :key='index' :value='year'>{{ year }}</option>
  </select>&nbsp; &nbsp; &nbsp;
</section>
</template>



<script>
export default {
  name: 'MonthSelector',

  props: {
    month: {
      type: Number,
      default: null
    },
    year: {
      type: Number,
      default: null
    },
  },



  data() {
    return {
      selectedMonth: null,
      selectedYear: null,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      years: [],
    }
  },



  created() { this.initialize() },



  watch: {
    selectedMonth(value) { this.$emit('month', this.selectedMonth + 1) },
    selectedYear(value) { this.$emit('year', this.selectedYear) },
  },



  methods: {

    initialize() {
      const currentDate = new Date();
      this.selectedMonth = currentDate.getMonth();

      this.years = [];
      let currentYear = currentDate.getFullYear();
      this.selectedYear = currentYear;

      while (currentYear >= 2018) {
        this.years.push(currentYear);
        currentYear -= 1;
      }

      if(this.month !== null) this.selectedMonth = this.month - 1
      if(this.year !== null) this.selectedYear = this.year
    },

  },



}

</script>
