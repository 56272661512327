import API from './API.js';
import { base_url } from '../Config.js';
import Product from '@/models/Product.js';
import PaginatedResponse from './PaginatedResponse.js';


class ProductsAPI extends API {

	static controllerName = 'SiteAdmin/ProductsAPI'


  static async getProducts() {
    const url = `${base_url}/ecommerceAPI/getProducts/sku`;
    const res = await super.get(url);
    if (!res) throw new Error("Could not get products from server. Changes will not be saved.");

    let products = [];
    for (let p of res) {
      products.push(Product.import(p));
    }

    return products;
  }



  static async deleteProduct(product) {

    if (!product.sku) {
      throw new Error("Invalid SKU.");
    }
    const url = `${base_url}/SiteAdmin/ProductsAPI/deleteProduct/${product.sku}`;
    await super.delete(url);
  }


  static async addProduct(product) {

    if (!product) {
      throw new Error("Invalid product.");
    }

    const url = `${base_url}/SiteAdmin/ProductsAPI/addProduct`;
    await super.add(url, product.export());
  }


  static async editProduct(product, prevSKU) {

    if (!product) {
      throw new Error("Missing product.");
    } else if (!product.sku) {
      throw new Error('Missing product SKU.');
    } else if (!prevSKU) {
      throw new Error('Missing property "prevSKU"');
    }

    const url = `${base_url}/SiteAdmin/ProductsAPI/editProduct/${prevSKU}`;
    await super.edit(url, product.export());
  }







  // ----------------------------------------------------------------
  // Product & Pricing Option Paginated Requests (Search Dropdowns)
  // ----------------------------------------------------------------

  static async getProductsPaginated( req ) {
    const res = await super.newPost( 'getProducts', [], req )
    return PaginatedResponse.import( res.data )
  }

  static async getPricingOptions( req ) {
    const res = await super.newPost( 'getPricingOptions', [], req )
    return PaginatedResponse.import( res.data )
  }


}

export default ProductsAPI;
