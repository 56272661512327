<template>
<section>

	<!-- Basic Info -->
	<div class="flex-row flex-justify-between flex-gap-1">
		
		<!-- Left Column -->
		<div>
			<div>Total Amount:</div>
			
			<div>
				<!-- Payment Amount -->
				<input v-if="create || edit" class="center" style="font-size: 2em; width: 4.5em;" v-model.number="newTransaction.amount" @input="updateAmount()" />
				<span v-else style="font-size: 3em;">{{ newTransaction.amount }}</span>
				
				<!-- Currency -->
				<select v-if="create || edit" v-model="newTransaction.currency">
					<option :value="null">Currency</option>
					<option value='USD'>USD</option>
				</select>
				<span v-else style="font-size: 1.5em;"> {{ newTransaction.currency }}</span>
			</div>
		</div>

		<!-- Right Column -->
		<div>
			<div v-if="create || edit">
				<v-date-picker v-model="newTransaction.timestamp" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="Transaction Date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
			</div>
			<div v-else>{{ newTransaction.timestamp ? newTransaction.timestamp.toLocaleString() : 'N/A' }}</div>

			<div v-if="create || edit" class="mt-1">
				<div>
					Type:
					<select v-model="newTransaction.type">
						<option :value="null" disabled>Select Type</option>
						<option value="creditcard">Credit Card</option>
						<option value="check">Check</option>
						<option value="ach">ACH</option>
						<option value="wiretransfer">Wire Transfer</option>
						<option value="paypal">PayPal</option>
						<option value="v9">PowerPrep v9</option>
						<option value="other">Other</option>
					</select>
				</div>
				<div v-if="newTransaction && newTransaction.type == 'creditcard'"><input v-model="newTransaction.ccTransactionID" placeholder="Transaction ID" /></div>
				<div v-if="newTransaction && newTransaction.type == 'creditcard'"><input v-model="newTransaction.authCode" placeholder="Authorization Code" /></div>
			</div>
			<div v-else class="mt-1">
				<div>Type: {{ newTransaction.type }}</div>
				<div v-if="newTransaction.ccTransactionID">Trans ID: {{ newTransaction.ccTransactionID }}</div>
				<div v-if="newTransaction.authCode">Auth Code: {{ newTransaction.authCode }}</div>
			</div>
		</div>
	</div>


	<!-- Payment Method -->
	<div v-if="create || edit"><input v-model="newTransaction.paymentMethod" style="width: 100%;" placeholder="Payment Method / Note (i.e. 'V10 Order')" /></div>
	<div v-else>{{ newTransaction.paymentMethod }}</div>
	
	<!-- Payee -->
	<div v-if="create || edit" class="mt-2">
		<div>Paid By:</div>
		<div><UserSearchDropdown :user="user" :initialValue="newTransaction.userFirstName || newTransaction.userLastName ? `${newTransaction.userFirstName || ''} ${newTransaction.userLastName || ''}` : null" width="100%" @updateUser="u => updateUser(u)" /></div>
		<div><CustomerSearchDropdown :customer="customer" :initialValue="newTransaction.customerName" width="100%" @updateCustomer="c => updateCustomer(c)" /></div>
	</div>
	<div class="mt-2" v-else>
		<div>Paid By:</div>
		<div v-if="newTransaction.userID"><b>{{ newTransaction.userFirstName }} {{ newTransaction.userLastName }}</b> [User #{{ newTransaction.userID }}]</div>
		<div v-if="newTransaction.customerID"><b>{{ newTransaction.customerName }}</b> [Cust #{{ newTransaction.customerID }}]</div>
		<div v-if="newTransaction.pocID"><b>{{ newTransaction.pocFirstName }} {{ newTransaction.pocLastName }}</b> ({{ newTransaction.pocID }})</div>
	</div>


	<!-- Address -->
	<div class="mt-2">Billing Address: <button v-if="edit && !newTransaction.billingAddress" @click.stop="addBillingAddress()">Add Address</button></div>
	<div v-if="newTransaction.billingAddress && !edit">
		<div>{{ newTransaction.billingAddress.address }}</div>
		<div>{{ newTransaction.billingAddress.city }}, {{ newTransaction.billingAddress.state }}</div>
		<div>{{ newTransaction.billingAddress.zip}}</div>
		<div>{{ newTransaction.billingAddress.country}}</div>
	</div>
	<div v-else-if="newTransaction.billingAddress && edit">
		<div class="mt-05"><input v-model="newTransaction.billingAddress.address" placeholder="Street Address" class="w-100" /></div>
		<div class="flex-row flex-justify-between mt-05">
			<input v-model="newTransaction.billingAddress.city" placeholder="City" />
			<input v-model="newTransaction.billingAddress.state" placeholder="State" />
		</div>
		<div class="mt-05"><input v-model="newTransaction.billingAddress.zip" placeholder="Zip" class="w-100" /></div>
		<div class="mt-05"><input v-model="newTransaction.billingAddress.country" placeholder="Country" class="w-100" /></div>
	</div>
	<div v-else>No Address</div>


	<!-- Notes -->
	<div class="mt-3">
		<textarea v-model="newTransaction.notes" placeholder="Notes..." style="width: 100%; min-height: 5em;" @input="saveNotes()" />
		<div v-if="notesSaved">saved</div>
		<div v-if="saveNotesError" class="warning">Failed to save notes</div>
	</div>


	<!-- Order Assignments -->
	<div v-if="newTransaction.orderAssignments" class="mt-2">
		
		<!-- Title -->
		<div class="flex-row flex-justify-between">
			Applied to Invoice(s): 
			<span v-if="!edit && !editAssignments && !create" class="link" @click.stop="editAssignments = true"><b>edit</b></span>
			<span v-if="editAssignments" class="link" @click.stop="revertAssignments()"><b>cancel / revert</b></span>
		</div>

		<!-- Rows -->
		<div v-for="oa in newTransaction.orderAssignments" :key="oa.orderID" class="flex-row flex-justify-between">
			<div class="flex-row flex-align-center flex-gap-1">
				<div>
					<input v-if="edit || editAssignments" v-model.number="oa.amount" @input="validateExistingAmount()" :class="{ 'error' : existingAmountError }" />
					<span v-else style="font-size: 1.5em;">{{ oa.amount }}</span>
					<span> {{ newTransaction.currency }}</span>
				</div>
				<span class="icon-arrow-right2" />
				<span>Order # {{ oa.orderID }}</span>
			</div>
			<div v-if="editAssignments" class="icon-bin2" @click.stop="deleteAssignment( oa.orderID )" />
		</div>

		<!-- Create new assignment -->
		<div v-if="editAssignments" class="mt-1">
			<button v-if="!newAssignment" @click.stop="createAssignment()">New</button>
			<div v-else class="flex-row flex-align-center flex-gap-1">
				<span><input v-model.number="newAssignment.amount" @input="validateNewAmount()" :class="{ 'error' : newAmountError }" /> USD</span>
				<span class="icon-arrow-right2" />
				<span>Order #<input v-model.number="newAssignment.orderID" @input="validateOrderID()" :class="{ 'error' : newOrderIDError }" /></span>
				<button @click.stop="addAssignment()" :class="{ 'disabled' : !newAssignment.orderID || newAmountError || newOrderIDError }" :disabled="!newAssignment.orderID || newAmountError || newOrderIDError">Add</button>
				<span class="icon-cross" @click.stop="newAssignment = null" />
			</div>
		</div>
	</div>

	<div v-if="!newAssignment && ( changesMade || assignmentsChanged )" class="flex-row flex-justify-end w-100">
		<button class="SquareBlueButton flex-row flex-align-center flex-gap-05" :class="{ 'disabled' : disableSave }" @click.stop="saveTransaction()" :disabled="disableSave">
			{{ create ? 'Record Transaction' : 'Save' }}
			<span v-if="saving" class='icon-spinner4 spin-loader-15'></span>
		</button>
	</div>


	<AlertDialog :show="saveError" @close="saveError = false">
		<div>FAILED to save Transaction!</div>
	</AlertDialog>


</section>
</template>



<script>
import OrdersAPI from '@/api/OrdersAPI.js'
import Address from './Address.js'

import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import AlertDialog from '@/components/utilities/AlertDialog.vue'
export default {
	name: 'TransactionDetails',


	components: {
		UserSearchDropdown,
		CustomerSearchDropdown,
		AlertDialog,
	},


	props: {
		transaction: Object,
		
		create: {
			type: Boolean,
			default: false
		},
		createWithUser: {
			type: Object,
			default: null
		},
		createWithCustomer: {
			type: Object,
			default: null
		},

		edit: {
			type: Boolean,
			default: false
		},
		orderID: {
			type: Number,
			default: null
		}
	},


	data() {
		return {
			user: null,
			customer: null,
			origTransaction: null,
			newTransaction: null,

			changeCounter: 0,
			notesSaved: false,
			saveNotesError: false,

			saving: false,
			saveError: false,
			
			assignmentsChanged: false,
			editAssignments: false,
			newAssignment: null,
			existingAmountError: false,
			newAmountError: false,
			newOrderIDError: false,
		}
	},



	computed: {
		changesMade() {
			this.changeCounter;
			if(!this.newTransaction || !this.origTransaction || !this.newTransaction.export) {
				return false;
			}
			return !this.origTransaction.isSame(this.newTransaction);
		},

		disableSave() {
			return  this.existingAmountError || 
							this.newAmountError ||
							this.newOrderIDError ||
							!this.newTransaction.type || 
							!this.newTransaction.currency || 
							(!this.newTransaction.amount && this.newTransaction.amount !== 0) || 
							(
								!this.newTransaction.userID && 
								!this.newTransaction.customerID && 
								!this.newTransaction.pocID
							)
		}
	},


	created() { this.initialize() },


	methods: {
		
		initialize() {
			this.saveNotes.timeoutID = null
			this.origTransaction = this.transaction.clone()
			this.newTransaction = this.transaction.clone()
			this.notesSaved = false
			this.saveNotesError = false

			this.assignmentsChanged = false
			this.editAssignments = false
			this.newAssignment = null

			this.existingAmountError = false
			this.newAmountError = false
			this.newOrderIDError = false

			if( this.create && this.createWithUser ) {
				this.user = this.createWithUser
				this.newTransaction.userID = this.user.userID
				this.newTransaction.userFirstName = this.user.firstName
				this.newTransaction.userLastName = this.user.lastName
			}

			if( this.create && this.createWithCustomer ) {
				this.customer = this.createWithCustomer
				this.newTransaction.customerID = this.customer.id
				this.newTransaction.customerName = this.customer.name
			}
		},


		updateAmount() {
			if( !this.orderID ) return
			if( !this.create ) {
				if( this.newTransaction.orderAssignments.length == 1 && this.newTransaction.orderAssignments[0].orderID == this.orderID && this.transaction.orderAssignments.length == 1 && this.transaction.orderAssignments[0].orderID == this.orderID && this.transaction.orderAssignments[0].amount == this.transaction.amount ) {
					this.newTransaction.orderAssignments = [ { transactionID: this.newTransaction.id, orderID: this.orderID, amount: this.newTransaction.amount } ]
					this.assignmentsChanged = true
				}
				return
			}

			this.newTransaction.orderAssignments = [ { transactionID: this.newTransaction.id, orderID: this.orderID, amount: this.newTransaction.amount } ]
		},

		updateUser( user ) { this.newTransaction.userID = user ? user.userID : null },
		updateCustomer( customer ) { this.newTransaction.customerID = customer ? customer.id : null },

		async saveTransaction() {

			this.saving = true
			this.saveError = false
			
			try {

				// Determine whether address is both present and edited.
				var saveAddress = this.newTransaction.billingAddress && !this.newTransaction.billingAddress.isSame( this.origTransaction.billingAddress )
				var newAddress = this.newTransaction.billingAddress

				
				// Edit whole transaction
				if( (this.create || this.edit) && this.changesMade ) {
					
					// Create
					if( this.newTransaction.id === null ) {

						const res = await OrdersAPI.createTransaction( this.newTransaction.export(), this.orderID )
						if( !res || !res.data ) return
						this.newTransaction.id = res.data
						this.$emit( 'created', this.newTransaction.id )

					// Edit
					} else await OrdersAPI.editTransaction( this.newTransaction.export() )
					
					// Clean-up
					this.origTransaction = this.newTransaction
					this.newTransaction = this.origTransaction.clone()
					Object.assign( this.transaction, this.newTransaction )
				}

				// Edit OrderAssignments
				console.debug("Assignments:", this.editAssignments, this.assignmentsChanged )
				if( this.assignmentsChanged ) {
					await OrdersAPI.saveTransactionOrderAssignments( this.newTransaction.export() )
					this.assignmentsChanged = false
					this.editAssignments = false
				}

				// Edit Billing Address
				if( saveAddress ) {
					if( !this.newTransaction.id ) throw new Error('Cannot edit billing address without a transaction ID')
					await OrdersAPI.saveTransactionBillingAddress( this.newTransaction.id, newAddress.export() )
				}

			} catch (e) {
				this.saveError = true
				throw e

			} finally {
				this.saving = false
			}
		},



		saveNotes() {
			if( !this.newTransaction.id ) return

			this.notesSaved = false
			this.saveNotesError = false

			if( this.saveNotes.timeoutID ) clearTimeout( this.saveNotes.timeoutID )

			this.saveNotes.timeoutID = setTimeout( () => {
				OrdersAPI.saveTransactionNotes( this.newTransaction.id, this.newTransaction.notes )
				.then( () => { this.notesSaved = true })
				.catch( () => { this.saveNotesError = true })
			}, 500)
		},





		validateExistingAmount() {
			const total = this.newTransaction.orderAssignmentTotal
			if( total > this.newTransaction.amount ) this.existingAmountError = true
			else {
				this.existingAmountError = false
				this.assignmentsChanged = true
			}
		},
		
		validateNewAmount() {
			const total = this.newTransaction.orderAssignmentTotal
			if( total + this.newAssignment.amount > this.newTransaction.amount ) this.newAmountError = true
			else this.newAmountError = false
		},
		
		async validateOrderID() {
			if( this.validateOrderID.timeoutID ) clearTimeout( this.validateOrderID.timeoutID )

			const elemIndex = this.newTransaction.orderAssignments.findIndex( elem => elem.orderID == this.newAssignment.orderID )
			if( elemIndex > -1 ) {
				this.newOrderIDError = true
				return
			}

			this.validateOrderID.timeoutID = setTimeout( () => {
				console.debug('validateOrderID')
				OrdersAPI.getOrder( this.newAssignment.orderID )
				.then( () => { this.newOrderIDError = false })
				.catch( () => { this.newOrderIDError = true })
			}, 500)
		},




		createAssignment() {
			this.newAssignment = { orderID: null, amount: 0.00 }
		},
		addAssignment() {
			if( !this.newAssignment ) return
			this.newTransaction.addOrderAssignment( this.newAssignment )
			this.newAssignment = null
			this.assignmentsChanged = true
		},
		deleteAssignment( orderID ) {
			this.newTransaction.deleteOrderAssignment( orderID )
			this.assignmentsChanged = true
		},

		revertAssignments() {
			this.initialize()
		},



		addBillingAddress() {
			if( !this.newTransaction ) return
			if( this.newTransaction.billingAddress ) return
			this.newTransaction.billingAddress = new Address()
		}



	},
	
}
</script>



<style scoped>
.error {
	border: 1px solid red;
	background: pink;
}
</style>