<template>
  <div>
    <section>
      <label>Title:</label><br />
      <input type="text" v-model="newScreen.title" style="width:100%;" /><br /><br />

      <label>Priority:</label>
        <select v-model="newScreen.priority">
          <option value="1">High</option>
          <option value="2">Medium</option>
          <option value="3">Low</option>
        </select><br /><br />
      </section>

      <label>Image:</label><br />
      <p>{{newScreen.imageUrl}}</p>
      <img v-if="newScreen.imageUrl" :src="imageUrl" width="200"/><br />
      <button class="pillButton" @click="$refs.mediaLibrary.open()">Choose Image</button>
      <br /><br />

    <section class="flex-row flex-justify-end">
      <button class="button" @click="save" :disabled="!changesMade">Save Changes</button>
    </section>

    <StretchModal ref="mediaLibrary" :clickToClose="false">
      <template #header>Media Library</template>
      <MediaLibrary :Selecting="true" @chosen="chooseImage"/>
    </StretchModal>
  </div>
</template>

<script>
import SettingsAPI from "@/api/SettingsAPI.js"
import LoginScreen from "@/models/LoginScreen.js"
import { base_url_image } from "@/Config.js";

import StretchModal from '@/components/utilities/StretchModal.vue'
import MediaLibrary from "./MediaLibrary.vue"

export default {
  name: "screen-details",

  props: {
    LoginScreen: Object
  },

  data() {
    return {
      newScreen: new LoginScreen(),
    }
  },

  computed: {
    changesMade() { return !this.LoginScreen.isSame(this.newScreen); },

    imageUrl() {
      if( this.newScreen.imageUrl ) return base_url_image + "/" + this.newScreen.imageUrl
      return null
    }
  },

  watch: {
    LoginScreen() { this.syncScreens(); }
  },

  created() { this.syncScreens(); },

  methods: {
    async save() {
      try {
        if (this.newScreen.id) {
          // edit login screen
          let res = await SettingsAPI.editLoginScreen(this.newScreen);
          this.$emit('edit', res)
        } else {
          // add login screen
          let res = await SettingsAPI.addLoginScreen(this.newScreen);
          this.$emit('add', res);
        }

        this.$emit( 'close' )

      } catch (e) {
        console.error(e);
        alert(`Changes could not be saved: ${e}`);
      }
    },

    chooseImage(image) {
      this.newScreen.imageUrl = image.filename
      this.$refs.mediaLibrary.close()
    },

    syncScreens() {
      Object.assign(this.newScreen, this.LoginScreen)
    }
  },

  components: {
    StretchModal,
    MediaLibrary,
  }
}
</script>
