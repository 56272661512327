import Product from '@/models/Product.js'
import PricingOption from '@/models/PricingOption.js'
import Discount from '@/features/SalesManagement/CouponCodes/Discount';



class LineItem {

  id = null
  orderID = null
  sku = '';
  _quantity = 0;
  licenseKeys = [];
  newLicenseKeys = [];
  upgradeSKUs = [];
  _unitPrice = null;         // UNIT price for initial purchase + manual renewal
  _subscriptionPrice = null; // UNIT price for auto-billing (subscription)
  isUpgrade = false;
  
  fulfill = true
  renewOnAnyContract = false
  
  // Embedded Product / PO obj's
  _product = null
  productIsGlobal = false // set TRUE if the attached product was not embedded in the order
  productNameOverride = null
  
  pricingOptionID = 0;
  _pricingOption = null;
  pricingOptionIsGlobal = false // set TRUE if the attached PO was not embedded in the order

  // Explicit expiration date (overrides PO licenseLength)
  licenseExpirationDate = null

  discount = null    // IFF this item had a coupon-based discount applied


  get quantity() { return this._quantity }
  set quantity(value) { this._quantity = parseInt(value) }


  get price() { return this._unitPrice }
  set price(value) {
    this._unitPrice = parseFloat(value)
    if(isNaN(this._unitPrice)) this._unitPrice = 0
  }


  get licenseExpirationDateString() {
    if(!this.licenseExpirationDate) return null;
    return this.licenseExpirationDate.toLocaleString();
  }


  get totalPrice() { return this.quantity * this.price }
  set totalPrice(value) { this._unitPrice = ( parseFloat(value) / this.quantity ) }

  get subscriptionPrice() { return this._subscriptionPrice }
  set subscriptionPrice(value) { this._subscriptionPrice = parseFloat(value) }

  get totalSubscriptionPrice() { return this.quantity * this.subscriptionPrice }
  set totalSubscriptionPrice(value) { this._subscriptionPrice = ( parseFloat(value) / this.quantity ) }


  get product() { return this._product }
  set product(prod) {
    this._product = prod
    this.sku = prod ? prod.sku : ''
  }

  get pricingOption() { return this._pricingOption }
  set pricingOption(po) {
    this._pricingOption = po
    this.pricingOptionID = po ? po.id : 0
    this.price = po ? po.initialPrice : null
    this.subscriptionPrice = po ? po.subscriptionPrice : null
  }



  get transactionType() {
    let subStr = '';
    if(this.isSubscription) subStr = " (subscription)";

    if(this.isSubscriptionBilling) return 'Subscription Billing';
    if(this.isUpgrade) return 'Upgrade' + subStr;
    if(this.licenseKeys.length) return 'Renewal' + subStr;
    else return 'New Purchase' + subStr;
  }



  static import(obj) {
    let lineItem = new LineItem();

    for(let property in obj) {
      if(property == 'product') continue
      if(property == 'pricingOption') continue
      lineItem[property] = obj[property]
    }

    // Expiration Date override
    if(lineItem.licenseExpirationDate) lineItem.licenseExpirationDate = new Date(lineItem.licenseExpirationDate * 1000);

    lineItem.fulfill = obj.fulfill
    lineItem.renewOnAnyContract = obj.renewOnAnyContract

    // Instantiate Product & PO
    if(obj.product) lineItem._product = Product.import(obj.product)
    if(obj.pricingOption) lineItem._pricingOption = PricingOption.import(obj.pricingOption)

    // Coupon discount
    if( obj.discount ) lineItem.discount = Discount.import( obj.discount )

    return lineItem
  }



  export(exportProduct = false) {
    const data = {}
    data.id = this.id
    data.orderID = this.orderID
    data.sku = this.sku
    data.quantity = this.quantity
    data.pricingOptionID = this.pricingOptionID
    if(this.price) data.price = this.price
    if(this.subscriptionPrice) data.subscriptionPrice = this.subscriptionPrice
    data.isUpgrade = this.isUpgrade


    data.licenseKeys = []
    for(let key of this.licenseKeys) data.licenseKeys.push(key)

    data.newLicenseKeys = []
    for(let key of this.newLicenseKeys) data.newLicenseKeys.push(key)

    data.upgradeSKUs = []
    for(let key of this.upgradeSKUs) data.upgradeSKUs.push(key)

    // Expiration Date override
    if(this.licenseExpirationDate) data.licenseExpirationDate = Math.floor(this.licenseExpirationDate.getTime() / 1000)

    data.fulfill = this.fulfill
    data.renewOnAnyContract = this.renewOnAnyContract

    // By default, don't include product (i.e. for sending to server -- it will be ignored, and it's big)
    if(this.pricingOption && exportProduct) data.pricingOption = this.pricingOption.export()
    if(this.product && exportProduct) data.product = this.product.export()

    if(this.productIsGlobal) data.productIsGlobal = this.productIsGlobal
    if(this.pricingOptionIsGlobal) data.pricingOptionIsGlobal = this.pricingOptionIsGlobal

    data.productNameOverride = this.productNameOverride;
    data.discount = this.discount ? this.discount.export() : null;

    return data
  }



}

export default LineItem;
