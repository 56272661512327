class LandingPagePrimaryQuoteSection {

  
  // Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
  vueID = 0

  type = 'primaryQuote'
  quoteBody = null
  quoteName = null
  quotePosition = null
  quoteOrganization = null
  quoteImage = null


  constructor(name = '') {
    this.vueID = this.type + ++LandingPagePrimaryQuoteSection.idCounter
    this.name = name
  }



  clone() {
    return LandingPagePrimaryQuoteSection.import(this.export());
  }

  isSame(obj) {
    return JSON.stringify(this.export()) == JSON.stringify(obj.export());
  }



  static import(obj) {
    // console.debug("LandingPagePrimaryQuoteSection.import()");
    const name = obj.name || '';
    const sec = new LandingPagePrimaryQuoteSection(name);
    
    sec.quoteBody = obj.quoteBody || '';
    sec.quoteName = obj.quoteName || '';
    sec.quotePosition = obj.quotePosition || '';
    sec.quoteOrganization = obj.quoteOrganization || '';
    sec.quoteImage = obj.quoteImage || '';

    return sec
  }


  export() {
    // console.debug("LandingPageHTMLSection.export()");
    return {
      type: this.type,
      name: this.name,
      quoteBody: this.quoteBody,
      quoteName: this.quoteName,
      quotePosition: this.quotePosition,
      quoteOrganization: this.quoteOrganization,
      quoteImage: this.quoteImage,
    };

  }

}


export default LandingPagePrimaryQuoteSection;
