import API from "./API";
import {base_url} from './../Config';

class FilesAPI extends API {

  static controllerName = 'SiteAdmin/FilesAPI'


  static createEmptyFile( req ) {
    return super.newPost( 'createEmptyFile', [], req )
  }
  
  static async uploadFileBase64Chunk( fileID, fileData ) {
    return await super.newPost( 'uploadFileBase64Chunk', [ fileID ], { chunk: fileData } )
  }

  static getPublicFilePath( fileID, folder = null ) {
    return super.newGet( 'getPublicFilePath', [ fileID, folder ] )
  }

  static async getAttachmentList(hostID, hostType) {
    let url = `${base_url}/SiteAdmin/FilesAPI/getAttachmentList/${hostID}/${hostType}`
    let data = super.get(url)
    return data
  }

  static async getAttachment(fileID, hostID, hostType) {
    let url = `${base_url}/SiteAdmin/FilesAPI/getAttachment/${fileID}/${hostID}/${hostType}`
    let data = super.get(url)
    return data
  }

  static async removeFile(fileID, hostID = null, hostType = null) {
    let url = `${base_url}/SiteAdmin/FilesAPI/removeFile/${fileID}/true`
    if(hostID && hostType) url += `/${hostID}/${hostType}`
    let data = super.get(url)
    return data
  }

}

export default FilesAPI
