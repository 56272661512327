import Exim from '@/models/Exim.js'

class Address extends Exim {

	
	id = null
	address = null
	city = null
	state = null
	country = null
	zip = null




	isSame( other ) {
		if( !other ) return false
		return JSON.stringify( this.export() ) == JSON.stringify( other.export() )
	}


}
export default Address;