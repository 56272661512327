<template>
<div id="library">
  
  <!-- File Uploader -->
  <div class="mt-1"><FileUploader :path="folderPath" @upload="initialize()" style="min-height: 100px;" /></div>


  <section class="flex-row flex-align-start flex-justify-start flex-gap-1 mt-2">
    
    <!-- File List -->
    <div v-if="!loading && !error" id="media-list-container" class="half">
      <MediaLibraryList :items="mediaLibrary" :iconName="'icon-file-empy'" :folderPath="folderPath" :subfolders="subfolders" :selectNewest="selectNewest" :update="updateList" @select="f => selectItem( f )" @renameItem="(oldName, newName) => renameItem( oldName, newName)" @delete="deleteItemPopup" @selectFolder="f => selectFolder( f )" @createFolder="name => createFolder( name )" @deleteFolder="name => deleteFolder( name )" @goto="idx => gotoFolder(idx)" />
    </div>
    <div v-else-if="error">There was an error fetching the media library</div>
    <div v-else-if="loading" class="center">Loading...</div>


    <!-- Previews -->
    <div v-if="selectedFile" class="sticky-top" style="max-width: min( 800px, 40vw );">

      <div v-if="selectedMediaType == 'image'" style="max-width: 50vw;">
        <img :src="getURL(selectedFile)" style="max-width: 100%;" />
      </div>

      <div v-else-if="selectedMediaType == 'video'">
        <video v-if="getURL(selectedFile)" controls controlslist="nodownload" style="object-fit: contain; max-width: min( 800px, 40vw );" ref="video">
          <source type="video/mp4" ref="videoSource">
        </video>
        <p v-else>No preview</p>
      </div>

      <div><a :href="getURL(selectedFile)" target="_blank">{{ getURL(selectedFile) }}</a></div>

      <div class="center mt-3"><button v-if="Selecting" class="button font-size-1-3" style="padding-left: 2em; padding-right: 2em;" @click="assignFile">Assign File</button></div>
    </div>
  </section>


  <ConfirmDialog confirm="Delete File" ref="confirmDeleteModal" @confirm="deleteItem()">
    <div>Are you sure you want to delete this file?</div>
    <div class="bold font-size-1-3">This action CAN NOT be undone!</div>
  </ConfirmDialog>

</div>
</template>

<script>
import MediaLibraryList from "@/components/MediaLibraryList.vue";
import FileUploader from '@/features/FileUploader/FileUploader.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

import MediaLibraryAPI from "@/api/MediaLibraryAPI.js";
import { base_url } from "@/Config";


export default {
  name: "media-library",


  components: {
    MediaLibraryList,
    FileUploader,
    ConfirmDialog,
  },


  props: {
    Selecting: Boolean
  },



  data() {
    return {
      mediaLibrary: [],
      subfolders: [],

      itemToDelete: null,
      loading: false,
      error: false,

      folderPath: [],
      mediaTypeString: '',
      textData: "",
      // config: this.$store.state.courseConfig,
      selectNewest: 0,
      selectedFile: null,
      selectedFilename: "",
      selectedMediaType: null,
      mediaLocations: [],
      updateList: 0,

    }
  },



  created() { this.initialize() },



  methods: {

    async initialize() {

      this.loading = true
      this.error = false
      this.selectedFile = null

      try {
        const promises = []
        promises.push( MediaLibraryAPI.getMedia( this.folderPath ).then( res => this.mediaLibrary = res.data ) )
        promises.push( this.loadSubfolders() )
        
        await Promise.all( promises )

      } catch ( e ) {
        console.error( e.message )
        this.error = true

      } finally {
        this.loading = false
      }
    },

    async loadSubfolders() {
      return MediaLibraryAPI.getSubfolders( this.folderPath ).then( res => this.subfolders = res.data )
    },


    gotoFolder( idx ) {
      if( idx + 1 >= this.folderPath.length ) return
      
      while( this.folderPath.length > idx + 1 ) this.folderPath.pop()
      this.initialize()
    },


    selectFolder( folderName ) {
      if( folderName == '..' ) {
        if( this.folderPath.length == 0 ) return
        this.folderPath.pop()
      
      } else this.folderPath.push( folderName )

      this.initialize()
    },


    async createFolder( folderName ) {
      const path = []
      for( let f of this.folderPath ) path.push( f )
      path.push( folderName )

      await MediaLibraryAPI.createFolder( path ).catch( err => alert( `Failed to create folder ${folderName}:\n${err.message}` ) )
      this.loadSubfolders()
    },


    async deleteFolder( folderName ) {
      const path = []
      for( let f of this.folderPath ) path.push( f )
      path.push( folderName )
    
      const res = await MediaLibraryAPI.deleteFolder( path ).catch( err => alert( `Failed to delete folder ${folderName}:\n${err.message}` ) )
      if( res.data ) alert( `Failed to delete folder ${folderName}:\n${res.data}` )
      this.loadSubfolders()
      
    },

  

    selectMediaType(type) {
      this.selectedFile = null;
      this.selectedFilename = '';
    },

    selectItem(file) {
      if(file) this.selectedFilename = file.filename;
      else this.selectedFilename = '';
      this.selectedFile = file;
      this.selectedMediaType = this.getSelectedMediaType( file )
    },


    getSelectedMediaType( file ) {
      if( !file || !file.filename ) return null
      
      const fn = file.filename
      if( fn.endsWith('.png') || fn.endsWith('.jpg') || fn.endsWith('.jpeg') || fn.endsWith('.gif') || fn.endsWith('.jxl') ) return 'image'
      if( fn.endsWith('.mp4') || fn.endsWith('.m4v') || fn.endsWith('.mkv') || fn.endsWith('.webm') || fn.endsWith('.mov') ) return 'video'
      return null
    },


    getURL( file ) {
      const subfolderPath = this.folderPath.join('/') + ( this.folderPath.length > 0 ? '/' : '' )
      const src = `${base_url}/webAssets/content/site_wide_media/${subfolderPath}${file.filename}`
      
      if( this.selectedMediaType == 'video' ) {
        this.$nextTick( () => {
          this.$refs.videoSource.setAttribute( 'src', src )
          this.$refs.video.load()
        })
      }
      
      return src
    },


    // If a file is changed, it will need to be renamed in each screen
    async renameItem( oldName, newName ) {

      await MediaLibraryAPI.renameMedia(this.folderPath, oldName, newName)
      .catch( err => {
        alert("Error while renaming file.");
      })

      this.initialize()
    },


    deleteItemPopup( item ) {
      this.itemToDelete = item
      this.$refs.confirmDeleteModal.open()
    },


    async deleteItem() {
      
      try {
        await MediaLibraryAPI.deleteMedia(this.folderPath, this.itemToDelete.filename);
        this.selectItem();
        await this.initialize()
        this.updateList++;
      
      } catch (e) {
        console.error(e);
        alert("Error communicating with server. Changes not saved.");
      
      } finally {
        this.itemToDelete = null
      }
    },


    async fileUploaded() {
      await this.initialize()
    },

    assignFile() {
      this.$emit('chosen', this.selectedFile);
    }
  },
}
</script>
