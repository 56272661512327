<template>
<div class="border round-05 pa-05">
	<div>Total Billed: {{ Math.moneyFormat(accountBilled) }}</div>
	<div>Total Paid: {{ Math.moneyFormat(accountPaid) }}</div>
	<hr>
	<div>Balance: <b>{{ Math.moneyFormat(accountBilled - accountPaid, 2) }}</b></div>
</div>
</template>



<script>
import OrdersAPI from '@/api/OrdersAPI.js'

export default {
	name: 'AccountBalance',


	props: {
		userID: {
			type: Number,
			default: null
		},

		customerID: {
			type: Number,
			default: null
		},
	},


	data() {
		return {
			accountBilled: null,
			accountPaid: null,
		}
	},


	created() { this.getAccountBalance() },


	methods: {
		async getAccountBalance() {
			if( !this.userID && !this.customerID ) return

			const id = this.userID || this.customerID
			const type = this.userID ? 'user' : 'customer'
			const data = await OrdersAPI.getAccountBalance( id, type )
			if( !data ) return

			this.accountBilled = data.amountBilled
			this.accountPaid = data.amountPaid
		},
	}
}
</script>



<style scoped>

</style>