<template>
	<SearchDropdown
		:selectedItems="selectedItems"
		:placeholder="placeholder"
		:DisplayFunction="displayFunction"
		:tagDisplayFunction="tagDisplayFunction"
		:InitialValue="initialValue"
		:ObjKey="'id'"
		:width="width"
		:browseIcon="true"
		:pages="pages"
		:externalResults="items"
		:disabled="courseID == null"
		:dropUp="dropUp"
		disabledMessage="Taxonomy selection is disabled"
		@selected="item => selectItem(item)"
		@remove="item => removeItem(item)"
		@search="(txt, pg) => getItemList(txt, pg)"
		@none="selectItem(null)"
		ref="search"
	/>
</template>


<script>
import TaxonomyAPI from '@/api/TaxonomyAPI.js';
import PaginatedRequest from '@/api/PaginatedRequest.js';
import SearchDropdown from '@/components/utilities/SearchDropdown.vue';

export default {
	name: 'TaxonomyItemSearchDropdown',

	components: {
		SearchDropdown,
	},

	props: {

		courseID: {
			type: Number,
			default: null
		},

		taxonomy: {
			type: Object,
			default: null
		},

		taxonomyItems: {
			type: Array,
			default: () => []
		},

		width: {
			type: String,
			default: '50%'
		},

		placeholder: {
			type: String,
			default: 'Select Taxonomy Items'
		},

		initialValue: {
			type: String,
			default: null
		},

		dropUp: {
			type: Boolean,
			default: false
		},

	},


	model: {
		prop: 'taxonomyItems',
		event: 'update'
	},


	data() {
		return {
			selectedItems: [],
			items: [],
			pages: 1,
		}
	},
	

	watch: {
		taxonomyItems() { this.initialize() },
	},


	created() { this.initialize() },


	methods: {

		initialize() {
			this.selectedItems = [ ...this.taxonomyItems ]
			// this.$emit( 'update', this.selectedItems )
		},

		selectItem( item ) {
			if( !item ) return
			for( var taxItem of this.selectedItems ) if( taxItem.id == item.id ) return

			this.selectedItems.push( item )
			this.$emit( 'update', this.selectedItems )
		},

		removeItem( item ) {
			this.selectedItems = this.selectedItems.filter( t => t.id !== item.id )
			const outputItems = [ ...this.selectedItems ]
			this.$emit( 'update', outputItems )
		},


		clear() { this.$refs.search.clear() },


		displayFunction(obj) {
			if(!obj) return '';

			// const keyName = this.taxonomy.displayKey || this.taxonomy.primaryKey
			const attrID = this.taxonomy.getDisplayKeyID() || this.taxonomy.getPrimaryKeyID()
			if( !attrID ) return 'No Attr Key'

			for( var attr of obj.attributeValues ) {
				if( attr.attributeID !== attrID ) continue
				return attr.value;
			}
			return 'No Attribute Value to Display'
		},


		tagDisplayFunction(obj) {
			if(!obj) return '';

			// const keyName = this.taxonomy.displayKey || this.taxonomy.primaryKey
			const attrID = this.taxonomy.getDisplayKeyID() || this.taxonomy.getPrimaryKeyID()
			if( !attrID ) return 'N/A'

			for( var attr of obj.attributeValues ) {
				if( attr.attributeID !== attrID ) continue
				return attr.value;
			}
			return 'N/A'
		},


		async getItemList(searchTerm, page = 1) {
			if( !this.taxonomy ) return

			const req = new PaginatedRequest(null, true, page, 25, searchTerm);
			req.courseID = this.courseID;
			req.taxonomyID = this.taxonomy.taxonomyID;
			const pr = await TaxonomyAPI.getMatchingTaxonomyItems( req );

			this.items = []
			if( !pr || !pr.data ) return
			this.pages = pr.pages
			for( let item of pr.data ) this.items.push( item )
		},
	}


}
</script>
