import Exim from '@/models/Exim.js'
import LandingPageStorefrontSection from './LandingPageStorefrontSection.js'



class LandingPageBuiltInSection extends Exim {


  // Used to create unique identifiers for Vue's v-for :keys.  
	// These are ephemeral and not guaranteed to be the same each session.
	static idCounter = 0
  vueID = 0

  type = 'builtin'
  name = 'Built-in Component'
  componentName = null
  subsection = null          // LandingPage Section - if this "BuiltIn" wraps another type of "section" component, such as a Storefront
  vars = {}                  // object - arbitrary key:value map




  constructor() {
    super()
    this.vueID = this.type + ++LandingPageBuiltInSection.idCounter
  }



  clone() {
    return LandingPageBuiltInSection.import( this.export() )
  }

  isSame( obj ) {
    return JSON.stringify( this.export() ) == JSON.stringify( obj.export() )
  }



  static import( obj ) {
    const item = super.import( obj, [ 'subsection', 'vueID' ] )
    if( obj.subsection ) {
      if( obj.subsection.type == 'storefront' ) item.subsection = LandingPageStorefrontSection.import( obj.subsection )
    }

    return item
  }


  export( obj ) {
    return super.export( [ 'vueID' ] )
  }

}

export default LandingPageBuiltInSection;
