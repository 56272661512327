<template>
<section class="listItem">
	<div class="flex-row flex-gap-1 flex-align-start flex-justify-between">
		<div class="w-100">
			<div class="flex-row flex-justify-between flex-align-start flex-gap-1">

				<!-- Name (admin label) -->
				<div class="bold mb-1">
					Range:
					<span v-if="!editName" class="name pointer round-05 pa-025" @click="editName = true">{{ name }}</span>
					<span v-else><input v-model="nameInternal" placeholder="Name" />&nbsp;<button class="pillButton bg secondary" @click="updateName()">save</button></span>
				</div>

				<button class="pillButton secondary red" @click="$emit('delete')"><span class="icon-bin2" /></button>
			</div>
			<div class="flex-row flex-align-center flex-gap-05"><div>Label (Public):</div><input v-model="range.label" class="flex-grow" /></div>
			<div class="flex-row flex-align-center flex-gap-05"><div>Description:</div><input v-model="range.description" class="flex-grow" /></div>
			<div class="mt-05"><ToggleButton v-model="range.showStudentValue" :sync="true" /> Display Student's Score</div>

		</div>

		<div>
			<div>This range is based on:</div>
			<div>
				<select v-model="range.dimension" @change="updateDimension( $event.target.value )">
					<option :value="null">(select Factor)</option>
					<option v-for="dim in dimensions" :value="dim" :key="dim.id">{{ dim.name }}</option>
				</select>
			</div>

			<div v-if="range._dimension">
				<select v-model="range.variableName">
					<option :value="null">(select Output Variable)</option>
					<option v-for="varName in range._dimension.outputVariableNames" :value="varName" :key="varName">{{ varName }}</option>
				</select>
			</div>
			
			<div v-if="range.unit">Unit: {{ range.unit }} ({{ range.shortUnit }})</div>
			<div v-else>Unit: none</div>

		</div>
	</div>



	<div class="bold center mt-2">Thresholds:</div>
	<div class="round-05 pa-1 mt-05" style="background-color: #f3f3f3; box-shadow: inset 0 2px 12px #888;">

		<div v-for="(t, idx) in range.thresholds" :key="t.vueCounter" class="threshold flex-row flex-align-stretch flex-justify-between flex-gap-2 mt-1 pa-05 border round-05">
			<div>
				<div class="flex-row flex-align-center flex-gap-05">
					<div>Label:</div>
					<input v-model="t.label" class="flex-grow" />
				</div>
				<div class="mt-05">Description:</div>
				<div><textarea class="w-100" v-model="t.description" placeholder="Description" /></div>
				<div class="mt-05">Min Value: <input type="number" v-model.number="t.minValue" /></div>
			</div>
			<div class="flex-row flex-justify-end flex-align-stretch flex-gap-2 flex-grow">
				<div class="flex-grow">
					<div><ToggleButton v-model="t.isHealthy" :sync="true" /> In Healthy Range</div>
					<textarea v-if="!t.isHealthy" v-model="t.interventionDescription" placeholder="Intervention" class="mt-1 w-100" style="min-height: 6.75em;" />
				</div>
				<button class="pillButton secondary red" @click="deleteThreshold(idx)"><span class="icon-bin2" /></button>
			</div>
		</div>

		<div v-if="range.thresholds.length" class="mt-1" />

		<div class="center"><button class="pillButton secondary bg blue" @click="addThreshold()">+ New Threshold</button></div>

	</div>
</section>
</template>



<script>
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: 'ThresholdReportRange',


	components: {
		ToggleButton,
	},



	props: {
		name: String,
		range: {
			type: Object,
			required: true
		},
		dimensions: Array,
	},



	data() {
		return {
			editName: false,
			nameInternal: null,
		}
	},



	watch: {
		range() { this.initialize() },
	},


	created() { this.initialize() },



	methods: {

		initialize() {
			this.nameInternal = this.name
		},

		updateName() {
			this.$emit( 'updateName', this.nameInternal )
			this.editName = false
		},

		updateDimension( dim ) {
			this.$forceUpdate()
		},

		addThreshold() {
			this.range.addThreshold()
		},

		deleteThreshold( index ) {
			this.range.deleteThreshold( index )
		}

	}


}
</script>



<style scoped>
.name:hover {
	background-color: var(--ekno-blue-95);
}

.listItem {
	padding: 1em;
	border: 1px solid #ccc;
	border-radius: 0.5em;
	box-shadow: 0 0 6px -2px #888;
}
.listItem:not(:last-child) {
	margin-bottom: 3em;
}
.listItem:last-child {
	margin-bottom: 1em;
}
.listItem:hover {
	box-shadow: 0 0 8px 0 #888;
	transition: box-shadow 0.25s;
}

.threshold {
	background-color: #f8f8f8;
}
.threshold:hover, .threshold:focus {
	background-color: #fff;
}
</style>