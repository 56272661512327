import PricingOption from '@/models/PricingOption.js';

class PricingRule {

  constructor(id, storefrontID, sku, defaultPO = null, availablePricingOptions = []) {
    this.id = id;
    this.storefrontID = storefrontID;
    this.productSKU = sku;
    this.defaultPricingOption = defaultPO;
    this.availablePricingOptions = availablePricingOptions;
  }



  addPricingOption(poID) {
    if(this.availablePricingOptions.includes(poID)) return;
    this.availablePricingOptions.push(poID);
  }


  removePricingOption(poID) {
    if(!this.availablePricingOptions.includes(poID)) return;
    let index = this.availablePricingOptions.indexOf(poID);
    this.availablePricingOptions.splice(index, 1);
  }



  static import(obj) {
    // var poArray = [];
    // for(let po of obj.availablePricingOptions) poArray.push(PricingOption.import(po));
    return new PricingRule(obj.id, obj.storefrontID, obj.productSKU, obj.defaultPricingOption, obj.availablePricingOptions);
  }



  export() {
    return {
      id: this.id,
      storefrontID: this.storefrontID,
      productSKU: this.productSKU,
      defaultPricingOption: this.defaultPricingOption,
      availablePricingOptions: this.availablePricingOptions
    };
  }


}

export default PricingRule;
