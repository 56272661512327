import Exim from '@/models/Exim.js'
import Score from './Score.js'



class ScoreGauge extends Exim {

	
	gaugeRadius = 100
	lineBreakAfter = false

	minimumScore = null
	perfectScore = null

	adjustedScore = null
	showAdjustedScore = false

	realScore = null
	showRealScore = false

	targetScore = null
	testScores = []

	name = null
	scoreTooltip1 = null
	scoreTooltip2 = null



	static import(obj) {
		const item = super.import(obj, [ 'minimumScore', 'perfectScore', 'adjustedScore', 'realScore', 'testScores' ] )
		
		if( obj.minimumScore ) item.minimumScore = Score.import( obj.minimumScore )
		if( obj.perfectScore ) item.perfectScore = Score.import( obj.perfectScore )
		if( obj.adjustedScore ) item.adjustedScore = Score.import( obj.adjustedScore )
		if( obj.realScore ) item.realScore = Score.import( obj.realScore )
		if( obj.targetScore ) item.targetScore = Score.import( obj.targetScore )

		if( obj.testScores ) {
			for( const testScore of obj.testScores ) item.testScores.push( Score.import( testScore ) )
		}

		return item
	}


}

export { ScoreGauge };
export default ScoreGauge;