<style scoped>
table, tr, td, th {
  border-collapse: collapse;
  border: 1px solid #555;
}
.h1 {
  font-size: 14pt;
  padding: 0.5em 1em;
}
.h2 {
  padding: 0.5em;
  font-size: 10pt;
  font-weight: lighter;
}

td {
  font-size: 11pt;
  padding: 0.5em;
  color: black;
}

tbody tr:hover {
  border-left: 5px solid red !important;
  border-right: 5px solid red !important;
  background-color: rgb(255, 222, 228);
}
tbody tr:hover td {
  border-bottom: 1px solid red !important;
}


th.colMonth { background-color: #fff; }
th.colCum { background-color: #eee; }
th.colTotal { background-color: #bfffff; }
th.colPaid { background-color: #9dfd7a; }
th.colPending { background-color: #ffff00; }
th.colPendingPO { background-color: #dfff00; }
th.colPendingDue { background-color: #ff8f00; }
th.colProposed { background-color: pink; }
th.colCanceled { background-color: #ddd; }

td { border-color: #aaa !important; }
td.colMonth { background-color: #fff; text-align:right; }
td.colCum { background-color: #f4f4f4; }
td.colTotal { background-color: #d5ffff; }
td.colPaid { background-color: #bffea9; }
td.colPending { background-color: #ffff5a; }
td.colPendingPO { background-color: #eaff5a; }
td.colPendingDue { background-color: #ffb65a; }
td.colProposed { background-color: #ffd6dd; }
td.colCanceled { background-color: #e9e9e9; }

.zero { filter: opacity(50%); }
</style>


<template>
<section>
  <h1>Orders By Product
    <span v-if="!year && !month"> (All-Time)</span>
    <span v-if="year || month"> ({{month ? (month)+' / ' : ''}}{{year ? year : ''}})</span>
    <span v-if="affiliateID"> (Affiliate #{{affiliateID}})</span>
    <span v-if="salesRepID"> (Sales Rep #{{salesRepID}})</span>
  </h1>
  <table>
    <thead>
      <tr>
        <th class="h1 colMonth">Product</th>
        <th class="h1 colTotal" colspan="2">Paid+Pending</th>
        <th class="h1 colPaid" colspan="2">Paid</th>
        <th class="h1 colPending" colspan="6">Pending</th>
        <th class="h1 colProposed" colspan="2">Proposed</th>
        <th class="h1 colCanceled" colspan="2">Canceled</th>
      </tr>
      <tr>
        <th class="h2 colMonth"></th>
        <th class="h2 colTotal">#</th>
        <th class="h2 colTotal">Dollars</th>
        <th class="h2 colPaid">#</th>
        <th class="h2 colPaid">Dollars</th>

        <th class="h2 colPending" colspan="2">Total</th>
        <th class="h2 colPendingPO" colspan="2">PO Recv</th>
        <th class="h2 colPendingDue" colspan="2">Past Due</th>

        <th class="h2 colProposed">#</th>
        <th class="h2 colProposed">Dollars</th>
        <th class="h2 colCanceled">#</th>
        <th class="h2 colCanceled">Dollars</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="row in rows" :key="row.sku">
        <td class="colMonth link" @click.stop="$emit('gotoProduct', row.sku)">{{row.sku}}</td>
        <!-- Totals -->
        <td class="colTotal" :class="{'zero' : !row.numTotal || row.numTotal == 0}">{{row.numTotal || '0'}}</td>
        <td class="colTotal" :class="{'zero' : !row.amtTotal || row.amtTotal == '0.00'}">$ {{row.amtTotal || '0.00'}}</td>
        <!-- Paid -->
        <td class="colPaid" :class="{'zero' : !row.numPaid || row.numPaid == 0}">{{row.numPaid || '0'}}</td>
        <td class="colPaid" :class="{'zero' : !row.amtPaid || row.amtPaid == '0.00'}">$ {{row.amtPaid || '0.00'}}</td>
        <!-- Pending -->
        <td class="colPending" :class="{'zero' : !row.numPending || row.numPending == 0}">{{row.numPending || '0'}}</td>
        <td class="colPending" :class="{'zero' : !row.amtPending || row.amtPending == '0.00'}">$ {{row.amtPending || '0.00'}}</td>
        <td class="colPendingPO" :class="{'zero' : !row.numPO || row.numPO == 0}">{{row.numPO || '0'}}</td>
        <td class="colPendingPO" :class="{'zero' : !row.amtPO || row.amtPO == '0.00'}">$ {{row.amtPO || '0.00'}}</td>
        <td class="colPendingDue" :class="{'zero' : !row.numPastDue || row.numPastDue == 0}">{{row.numPastDue || '0'}}</td>
        <td class="colPendingDue" :class="{'zero' : !row.amtPastDue || row.amtPastDue == '0.00'}">$ {{row.amtPastDue || '0.00'}}</td>
        <!-- Proposed -->
        <td class="colProposed" :class="{'zero' : !row.numProposed || row.numProposed == 0}">{{row.numProposed || '0'}}</td>
        <td class="colProposed" :class="{'zero' : !row.amtProposed || row.amtProposed == '0.00'}">$ {{row.amtProposed || '0.00'}}</td>
        <!-- Canceled -->
        <td class="colCanceled" :class="{'zero' : !row.numCanceled || row.numCanceled == 0}">{{row.numCanceled || '0'}}</td>
        <td class="colCanceled" :class="{'zero' : !row.amtCanceled || row.amtCanceled == '0.00'}">$ {{row.amtCanceled || '0.00'}}</td>
      </tr>
    </tbody>
  </table>

</section>
</template>


<script>
import OrdersAPI from '@/api/OrdersAPI.js'


export default {
  name: 'OrdersSummaryByProduct',

  props: {

    month: {
      type: Number,
      default: null
    },

    year: {
      type: Number,
      default: null
    },

    affiliateID: {
      type: Number,
      default: null
    },

    salesRepID: {
      type: Number,
      default: null
    },
  },


  data() {
    return {
      rows: [],
    }
  },


  created() {
    this.initialize()
  },

  watch: {
    month() { this.initialize() },
    year() { this.initialize() },
    affiliateID() { this.initialize() },
    salesRepID() { this.initialize() },
  },


  methods: {
    async initialize() {
      const data = await OrdersAPI.getSummaryByProduct(this.year, this.month, this.affiliateID, this.salesRepID)
      this.rows = []
      for(let item of data) {
        this.rows.push(item)
      }
    }
  }

}
</script>
