import API from "./API";
import {base_url} from '@/Config'

class StorefrontAPI extends API {

  static getStorefronts() {
    const url = `${base_url}/StorefrontAPI/getStorefronts`;
    return this.get(url);
  }


  static getStorefront(slug) {
    const url = `${base_url}/StorefrontAPI/getStorefrontAdmin/${slug}`;
    return this.get(url);
  }


  static getStorefrontSlug(id) {
    const url = `${base_url}/StorefrontAPI/getStorefrontSlug/${id}`;
    return this.get(url);
  }


  static createStorefront(storefront) {
    const url = `${base_url}/StorefrontAPI/createStorefront`;
    return this.sendJSON(url, storefront);
  }


  static editStorefront(storefront) {
    const url = `${base_url}/StorefrontAPI/editStorefront`;
    return this.sendJSON(url, storefront);
  }

  static deleteStorefront(id) {
    const url = `${base_url}/StorefrontAPI/deleteStorefront/${id}`;
    return this.get(url);
  }
}

export default StorefrontAPI;
