import Exim from "@/models/Exim.js"

class PaginatedResponse extends Exim {

	// REQUIRED
	count = 0
	pages = 0
	data = []


	// OPTIONAL
	summary = null

}
export default PaginatedResponse