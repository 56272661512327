<template>
<section>
<StretchModal v-if="customer" :showModal='show' @close='close()' :closeIcon="true" :width="width" :height="height">
	<template #header>Customer #{{customer.id}}: {{customer.name}}</template>
	<template #default="{ closed }">
		<CustomerDetails v-if="customer" :customerID="customer.id" :isNew="isNew" @itemAdded="item => $emit('itemAdded', item)" @itemEdited="item => $emit('itemEdited', item)" @close="closed" ref="details" />
	</template>
</StretchModal>

<ConfirmDialog :show="showConfirmation" :confirm="'Close Anyway'" :cancel="'Go Back'" @confirm="confirmClose()" @cancel="showConfirmation = false">
	<div>Are you sure?  <b>Unsaved changes will be lost!</b></div>
</ConfirmDialog>
</section>
</template>



<script>
import CustomerDetails from './CustomerDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
export default {
	name: 'CustomerDetailsModal',

	components: {
		CustomerDetails,
		StretchModal,
		ConfirmDialog,
	},


	props: {
		customer: {
			type: Object,
			default: null
		},
		show: {
			type: Boolean,
			default: false
		},
		isNew: {
			type: Boolean,
			default: false
		},
		width: {
			type: String,
			default: '90%'
		},
		height: {
			type: String,
			default: '100%'
		},
	},


	data() {
		return {
			showConfirmation: false,
		}
	},


	methods: {
		close() {
			if(this.$refs.details.changesMade) {
				this.showConfirmation = true
				return
			}

			this.$emit('close')
		},

		confirmClose() {
			this.showConfirmation = false
			this.$emit('close')
		},

	}
}
</script>
