<template>
<section id="wrapper" class="flex-row flex-justify-between flex-align-center flex-gap-05 mb-05 pb-05 pt-05" :class="{'new' : isNew}">
	<div>
		Student has
		<select v-model="score.type" @change="updateType()">
			<option :value="null">completed</option>
			<option value="raw">scored (raw)</option>
			<option value="adjusted">scored (raw, adjusted)</option>
		</select>
		<span v-if="type !== null">
			<select v-model="score.compare" @change="updateCompare()">
				<option :value="1">greater than</option>
				<option :value="0">exactly</option>
				<option :value="-1">less than</option>
			</select>
			<input type="number" min="0" v-model.number="percentScore" placeholder="score" style="width: 5em;" @change="updateScore()" /> %
			<br>
			on
		</span>
		node # 
		<input 
			type="number"
			min="1"
			v-model.number="internalNodeID"
			:disabled="!isNew"
			placeholder="Node ID"
			style="width: 7em;"
			:class="{'error' : hasError}"
			@input="updateNodeID()"
		/>
		<span v-if="showRequired"><input type="checkbox" v-model="score.required" @change="updateRequired()" /> Required</span>

		<ConfirmDialog v-if="!isNew" :show="showDelete" confirm="Delete" @confirm="remove()" @cancel="showDelete = false">
			Really delete rule for Node # {{ nodeID }}?
		</ConfirmDialog>
	</div>
	<button v-if="isNew" class="ml-05 button" :disabled="!nodeID || hasError" @click.stop="$emit('add')">Add</button>
	<span v-if="!isNew" class="icon-bin icon-button" @click="showDelete = true" />
</section>	
</template>



<script>
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
export default {
	name: 'ScoreWidget',


	components: {
		ConfirmDialog,
	},


	model: {
		prop: 'nodeID',
    event: 'changeNodeID'
	},


	props: {
		nodeID: {
			type: Number,
			default: null
		},
		score: Object,
		hasError: {
			type: Boolean,
			default: false
		},
		isNew: {
			type: Boolean,
			default: false
		},
		showRequired: {
			type: Boolean,
			default: false
		},
	},


	data() {
		return {
			internalNodeID: null,
			percentScore: null,
			type: null,
			showDelete: false,
		}
	},


	watch: {
		nodeID( value ) {
			this.internalNodeID = value
			this.type = this.score.type
		},
	},


	created() {
		this.internalNodeID = this.nodeID
		this.type = this.score.type
	},


	methods: {
		updateNodeID() {
			this.internalNodeID = parseInt( this.internalNodeID )
			this.$emit('changeNodeID', this.internalNodeID)
		},

		updateType() {
			this.type = this.score.type
			if( this.score.type === null ) {
				this.score.compare = 1
				this.score.score = null
			}
			if( !this.isNew ) this.$emit('changed')
		},

		updateCompare() {
			if( !this.isNew ) this.$emit('changed')
		},

		updateScore() {
			this.score.score = (this.percentScore / 100)
			if( !this.isNew ) this.$emit('changed')
		},

		updateRequired() {
			if( !this.isNew ) this.$emit('changed')
		},

		remove() {
			this.showDelete = false
			this.$emit('delete')
		}
	}

}
</script>



<style scoped>
#wrapper {
	border-bottom: 1px solid #ccc;
}
#wrapper.new {
	border: 2px solid var(--ekno-blue);
	background: #eef;
	border-radius: 0.25em;
	padding: 0.5em 1em;
	margin: 0;
}
.error {
	background-color: pink;
}
</style>