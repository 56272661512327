<style scoped>
table, tr, td, th {
  border-collapse: collapse;
  border: 1px solid #555;
}
.h1 {
  font-size: 14pt;
  padding: 0.5em 1em;
}
.h2 {
  padding: 0.5em;
  font-size: 10pt;
  font-weight: lighter;
}

td {
  font-size: 11pt;
  padding: 0.5em;
  color: black;
}

tbody tr:hover {
  border-left: 5px solid red !important;
  border-right: 5px solid red !important;
  background-color: rgb(255, 222, 228);
}
tbody tr:hover td {
  border-bottom: 1px solid red !important;
}


.yearDivider { border-top: 3px solid red; }

th.colMonth { background-color: #fff; }
th.colCum { background-color: #eee; }
th.colTotal { background-color: #bfffff; }
th.colPaid { background-color: #9dfd7a; }
th.colPending { background-color: #ffff00; }
th.colPendingPO { background-color: #dfff00; }
th.colPendingDue { background-color: #ff8f00; }
th.colProposed { background-color: pink; }
th.colCanceled { background-color: #ddd; }

td { border-color: #aaa !important; }
td.colMonth { background-color: #fff; text-align:right; }
td.colCum { background-color: #f4f4f4; }
td.colTotal { background-color: #d5ffff; }
td.colPaid { background-color: #bffea9; }
td.colPending { background-color: #ffff5a; }
td.colPendingPO { background-color: #eaff5a; }
td.colPendingDue { background-color: #ffb65a; }
td.colProposed { background-color: #ffd6dd; }
td.colCanceled { background-color: #e9e9e9; }

.zero { filter: opacity(50%); }
</style>


<template>
<section>
  <h1>Orders By {{title}}
    <span v-if="!sYear && !sMonth && !eYear && !eMonth"> (All-Time)</span>
    <span v-else>
      <span v-if="sYear && sMonth"> ({{sMonth}}/{{sYear}} - </span>
      <span v-else>(&infin; - </span>
      <span v-if="eYear && eMonth">{{eMonth}}/{{eYear}})</span>
      <span v-else>Present)</span>
    </span>
    <span v-if="affiliateID"> (Affiliate #{{affiliateID}})</span>
    <span v-if="salesRepID"> (Sales Rep #{{salesRepID}})</span>
  </h1>
  <table>
    <thead>
      <tr>
        <th class="h1 colMonth">{{title}}</th>
        <th class="h1 colCum" colspan="2">Cumulative</th>
        <th class="h1 colTotal" colspan="2">Paid+Pending</th>
        <th class="h1 colPaid" colspan="2">Paid</th>
        <th class="h1 colPending" colspan="6">Pending</th>
        <th class="h1 colProposed" colspan="2">Proposed</th>
        <th class="h1 colCanceled" colspan="2">Canceled</th>
      </tr>
      <tr>
        <th class="h2 colMonth"></th>
        <th class="h2 colCum">#</th>
        <th class="h2 colCum">Dollars</th>
        <th class="h2 colTotal">#</th>
        <th class="h2 colTotal">Dollars</th>
        <th class="h2 colPaid">#</th>
        <th class="h2 colPaid">Dollars</th>

        <th class="h2 colPending" colspan="2">Total</th>
        <th class="h2 colPendingPO" colspan="2">PO Recv</th>
        <th class="h2 colPendingDue" colspan="2">Past Due</th>

        <th class="h2 colProposed">#</th>
        <th class="h2 colProposed">Dollars</th>
        <th class="h2 colCanceled">#</th>
        <th class="h2 colCanceled">Dollars</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(row, index) in rows" class="pointer" :class="{'yearDivider' : index > 0 && row.year !== rows[index-1].year}" @click.stop="goto(row.year, row.month, row.day)">
        <td class="colMonth link">{{row.month}}<span v-if="timeBasis == 'day'"> / {{row.day}}</span> / {{row.year}}</td>

        <!-- Cumulative -->
        <td class="colCum" :class="{'zero' : !row.numCumulative || row.numCumulative == 0}">{{row.numCumulative || '0'}}</td>
        <td class="colCum" :class="{'zero' : !row.amtCumulative || row.amtCumulative == '0.00'}">$ {{row.amtCumulative || '0.00'}}</td>
        <!-- Totals -->
        <td class="colTotal" :class="{'zero' : !row.numTotal}">{{row.numTotal || '0'}}</td>
        <td class="colTotal" :class="{'zero' : !row.amtTotal || row.amtTotal == '0.00'}">$ {{row.amtTotal || '0.00'}}</td>
        <!-- Paid -->
        <td class="colPaid" :class="{'zero' : !row.numPaid}">{{row.numPaid || '0'}}</td>
        <td class="colPaid" :class="{'zero' : !row.amtPaid || row.amtPaid == '0.00'}">$ {{row.amtPaid || '0.00'}}</td>
        <!-- Pending -->
        <td class="colPending" :class="{'zero' : !row.numPending}">{{row.numPending || '0'}}</td>
        <td class="colPending" :class="{'zero' : !row.amtPending || row.amtPending == '0.00'}">$ {{row.amtPending || '0.00'}}</td>
        <td class="colPendingPO" :class="{'zero' : !row.numPO}">{{row.numPO || '0'}}</td>
        <td class="colPendingPO" :class="{'zero' : !row.amtPO || row.amtPO == '0.00'}">$ {{row.amtPO || '0.00'}}</td>
        <td class="colPendingDue" :class="{'zero' : !row.numPastDue}">{{row.numPastDue || '0'}}</td>
        <td class="colPendingDue" :class="{'zero' : !row.amtPastDue || row.amtPastDue == '0.00'}">$ {{row.amtPastDue || '0.00'}}</td>
        <!-- Proposed -->
        <td class="colProposed" :class="{'zero' : !row.numProposed}">{{row.numProposed || '0'}}</td>
        <td class="colProposed" :class="{'zero' : !row.amtProposed || row.amtProposed == '0.00'}">$ {{row.amtProposed || '0.00'}}</td>
        <!-- Canceled -->
        <td class="colCanceled" :class="{'zero' : !row.numCanceled}">{{row.numCanceled || '0'}}</td>
        <td class="colCanceled" :class="{'zero' : !row.amtCanceled || row.amtCanceled == '0.00'}">$ {{row.amtCanceled || '0.00'}}</td>
      </tr>
    </tbody>
  </table>

</section>
</template>


<script>
import OrdersAPI from '@/api/OrdersAPI.js'


export default {
  name: 'OrdersSummaryByTime',

  props: {

    aggregateBy: {
      type: String,
      default: 'month' // or 'day'
    },

    startMonth: {
      type: Number,
      default: null
    },

    startYear: {
      type: Number,
      default: null
    },

    endMonth: {
      type: Number,
      default: null
    },

    endYear: {
      type: Number,
      default: null
    },

    affiliateID: {
      type: Number,
      default: null
    },

    salesRepID: {
      type: Number,
      default: null
    },
  },


  data() {
    return {
      sMonth: null,
      eMonth: null,
      sYear: null,
      eYear: null,
      rows: [],
      timeBasis: null,
      gotoEventName: null,
      title: null,
    }
  },


  mounted() {
    this.initialize()
  },

  watch: {
    aggregateBy() { this.initialize() },
    startMonth() { this.initialize() },
    startYear() { this.initialize() },
    endMonth() { this.initialize() },
    endYear() { this.initialize() },

    affiliateID() { this.initialize() },
    salesRepID() { this.initialize() },
  },


  methods: {
    async initialize() {

      this.timeBasis = this.aggregateBy == 'day' ? 'day' : 'month',
      this.gotoEventName = this.aggregateBy == 'day' ? 'gotoDay' : 'gotoMonth',
      this.title = this.aggregateBy == 'day' ? 'Day' : 'Month'

      this.sMonth = this.startMonth
      this.eMonth = this.endMonth
      this.sYear = this.startYear
      this.eYear = this.endYear

      var now = new Date()
      if( this.startMonth && !this.startYear ) this.sYear = now.getFullYear()
      if( this.startYear && !this.startMonth ) this.sMonth = 1
      if( this.endMonth && !this.endYear ) this.eYear = now.getFullYear()
      if( this.endYear && !this.endMonth ) this.eMonth = 12

      const data = await this.getData()
      this.rows = []
      for(let item of data) {
        this.rows.push(item)
      }

    },


    getData() {
      if(this.timeBasis == 'day') return OrdersAPI.getSummaryByDay(this.sYear, this.sMonth, this.eYear, this.eMonth, this.affiliateID, this.salesRepID)
      else return OrdersAPI.getSummaryByMonth(this.sYear, this.sMonth, this.eYear, this.eMonth, this.affiliateID, this.salesRepID)
    },


    goto(year, month, day) {
      this.$emit(this.gotoEventName, year, month, day)
    }
  }

}
</script>
