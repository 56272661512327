<template>
  <SearchDropdown
    :placeholder="placeholder"
    :DisplayFunction="displayFunction"
    :InitialValue="initialValue || displayFunction(item)"
    :ObjKey="'id'"
    :width="width"
    :browseIcon="true"
		:pages="pages"
    :externalResults="items"
    :dropUp="dropUp"
    @selected="item => select(item)"
    @search="(txt, pg) => getItems(txt, pg)"
    @none="select(null)"
    ref="search"
  />
</template>


<script>
import ProductsAPI from '@/api/ProductsAPI.js'
import Product from '@/models/Product.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import SearchDropdown from '@/components/utilities/SearchDropdown.vue';

export default {
  name: 'ProductSearchDropdown',

  components: {
    SearchDropdown,
  },

  props: {
    item: {
      type: Object,
      default: null
    },

    width: {
      type: String,
      default: '50%'
    },

    placeholder: {
      type: String,
      default: 'Search Products'
    },

    initialValue: {
      type: String,
      default: null
    },

		dropUp: {
			type: Boolean,
			default: false
		},

  },


  model: {
    prop: 'item',
    event: 'update'
  },

  data() {
    return {
      items: [],
      pages: 1,
    }
  },

  methods: {

    select(item) {
      this.$emit('update', item);
    },

    clear() { this.$refs.search.clear() },

    displayFunction( obj ) {
      if( !obj ) return '';
      var str = `${ obj.sku }: ${ obj.name }`
      return str
    },

    async getItems( searchTerm, page = 1 ) {
      this.items = []
			
      const data = await ProductsAPI.getProductsPaginated( new PaginatedRequest( 'sku', true, page, 25, searchTerm ) )

      if( !data || !data.data ) return
      this.pages = data.pages
      for( let item of data.data ) this.items.push( Product.import(item) )
    },
  }


}
</script>
