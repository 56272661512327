<template>
	<SearchDropdown
    :placeholder="placeholder"
		:DisplayFunction="displayFunction"
		:selectDisplayFunction="selectDisplayFunction"
		:InitialValue="initialValue || selectDisplayFunction(contact)"
		:ObjKey="'id'"
		:width="width"
		:filterIcon="( affiliateIDs && affiliateIDs.length ) || ( customerIDs && customerIDs.length ) ? true : false"
		:browseIcon="true"
		:pages="pages"
		:externalResults="items"
		:dropUp="dropUp"
		@selected="(item) => select(item)"
		@search="(txt, pg) => getList(txt, pg)"
    @confirm="txt => emitSearchList(txt)"
		@none="select(null)"
		ref="search"
	/>
</template>


<script>
import SalesManagementAPI from "@/api/SalesManagementAPI.js";
import PaginatedRequest from '@/api/PaginatedRequest.js'
import POC from "./POC.js"
import SearchDropdown from "@/components/utilities/SearchDropdown.vue"

export default {
	name: "ContactSearchDropdown",

	components: {
		SearchDropdown,
	},

	props: {
		contact: {
			type: Object,
			default: null,
		},

		// Optional: limits universe of Contacts
		affiliateIDs: {
			type: Array,
			default: null
		},

		// Optional: limits universe of Contacts
		customerIDs: {
			type: Array,
			default: null
		},

		externalGetter: {
			type: Function,
			default: null
		},


		width: {
			type: String,
			default: '100%'
		},

		placeholder: {
      type: String,
      default: 'Search Contacts'
    },

		initialValue: {
			type: String,
			default: null
		},

		dropUp: {
			type: Boolean,
			default: false
		},
	},

	model: {
		prop: "contact",
		event: "update",
	},

	data() {
		return {
			items: [],
			pages: 1,
		};
	},

	methods: {
		select(item) {
			this.$emit("update", item);
		},

    clear() { this.$refs.search.clear() },
		focus() { this.$refs.search.focus() },

		displayFunction(obj) {
			if (!obj) return ""
			var name =  `${obj.firstName}&nbsp;${obj.lastName}`
			var emails = obj.emails.map( elem => elem.email ).join(', ')
			var phones = obj.phones.map( elem => elem.phone ).join(', ')
			return `${name}&nbsp;<span style="color: #888; font-size: 0.85em;"> - ${ emails }${ emails && phones ? ' | ' : ''}${ phones }${ !emails && !phones ? 'no contact information' : '' }</span>`
		},

		selectDisplayFunction(obj) {
			if( !obj ) return ""
			var name = obj.firstName || obj.lastName ? `${obj.firstName} ${obj.lastName}` : obj.fullName
			return `${obj.userID}: ${name ? name + ' -' : ''} ${obj.emailAddress || ""}`
		},



		async emitSearchList( searchTerm ) {
      const data = await this.search( searchTerm, 1, 0 )
      const list = []
      for( let item of data.data ) list.push( POC.import( item ) )
      this.$emit( 'matches', list, searchTerm )
    },


    async search( searchTerm, page = 1, pageSize = 25 ) {
      if( this.externalGetter ) return await this.externalGetter( searchTerm, page, pageSize )

			console.debug( this.affiliateIDs, this.customerIDs )

			const req = new PaginatedRequest( 'firstName', true, page, pageSize )
			req.searchString = searchTerm ? searchTerm : null
			if( this.affiliateIDs ) req.affiliateIDs = this.affiliateIDs
			if( this.customerIDs ) req.customerIDs = this.customerIDs
			req.includeEmails = true
			req.includePhones = true

			return await SalesManagementAPI.getContacts( req )
    },


		async getList( searchTerm, page = 1 ) {

			const data = await this.search( searchTerm, page )

			this.items = []
			if (!data || !data.data || !data.data.length) return
			this.pages = data.pages
			
			for (let item of data.data) {
				this.items.push( POC.import(item) )
			}

		},
	},
};
</script>
