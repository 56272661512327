/**
 * NOT a direct model of the Cohort Table in the database. 
 * Includes cohort ID, name, and a map of licenses under that cohort (K: licenseKey, V: license).
 */
export default class Cohort {

	cohortID     = null
	cohortName   = null
	customerID   = null
	licenses     = null
	licenseCount = null

	constructor( cohortID = null, cohortName = '', customerID = null ) {
		this.cohortID = cohortID;
		this.cohortName = cohortName;
		this.licenses = new Map(); // Map: (licenseKey => license object)
		this.customerID = customerID
	}



	get licensesArray() {
		return Array.from(this.licenses)
	}


	
	get studentCourses() {
		var courseSet = new Set();

		this.licenses.forEach(license => {
			courseSet.add(license.courseID)
		})

		return Array.from(courseSet)
	}



	setLicenses(licenses) {
		licenses.forEach( lic => this.licenses.set(lic.licenseKey, lic) );
	}

	removeLicenses(licenses) {
		licenses.forEach( lic => this.licenses.delete(lic.licenseKey) )
	}

	hasLicense(license) {
		return this.licenses.has(license.licenseKey)
	}



	/**
	 * update: figure out how to import license map. probably need to see what json looks like from server.
	 * Only used to import the cohortID and cohortName, need to set the license map as well.
	 * @param {*} json 
	 */
	static import(json) {
		let cohort = new Cohort(json.id, json.name, json.customerID);
		json.licenseKeys.forEach(licenseKey => cohort.licenses.set(licenseKey, null))
		if( json.licenseCount !== undefined ) cohort.licenseCount = json.licenseCount
		return cohort;
	}
}