<template>
  <SearchDropdown
    :selectedItems="selectedItems"
    :placeholder="placeholder"
    :DisplayFunction="displayFunction"
    :tagDisplayFunction="tagDisplayFunction"
    :InitialValue="initialValue"
    :ObjKey="'id'"
    :width="width"
    :browseIcon="true"
		:pages="pages"
    :externalResults="items"
		:disabled="courseID == null"
    :dropUp="dropUp"
		disabledMessage="Taxonomy selection is disabled"
    @selected="item => selectItem(item)"
    @remove="item => removeItem(item)"
    @search="(txt, pg) => getItemList(txt, pg)"
    @none="selectItem(null)"
    ref="search"
  />
</template>


<script>
import TaxonomyAPI from '@/api/TaxonomyAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Taxonomy from './Taxonomy.js'
import SearchDropdown from '@/components/utilities/SearchDropdown.vue'

export default {
  name: 'TaxonomySearchDropdown',

  components: {
    SearchDropdown,
  },

  props: {

		courseID: {
			type: Number,
			default: null
		},

    taxonomies: {
      type: Array,
      default: () => []
    },

    width: {
      type: String,
      default: '50%'
    },

    placeholder: {
      type: String,
      default: 'Select Taxonomies'
    },

    initialValue: {
      type: String,
      default: null
    },

		dropUp: {
			type: Boolean,
			default: false
		},

  },


  model: {
    prop: 'taxonomies',
    event: 'update'
  },


  data() {
    return {
      selectedItems: [],
      items: [],
      pages: 1,
    }
  },
  

  watch: {
    taxonomies() { this.initialize() },
  },


  created() { this.initialize() },


  methods: {

    initialize() {
      this.selectedItems = [ ...this.taxonomies ]
      // this.$emit( 'update', this.selectedItems )
    },

    selectItem( item ) {
      if( !item ) return
      for( var tax of this.selectedItems ) if( tax.taxonomyID == item.taxonomyID ) return

      this.selectedItems.push( item )
      this.$emit( 'update', this.selectedItems )
    },

    removeItem( item ) {
      this.selectedItems = this.selectedItems.filter( t => t.taxonomyID !== item.taxonomyID )
      const outputItems = [ ...this.selectedItems ]
      this.$emit( 'update', outputItems )
    },


    clear() { this.$refs.search.clear() },

    displayFunction(obj) {
      if(!obj) return '';
      return `${obj.taxonomyID}: ${obj.name}`;
    },
    tagDisplayFunction(obj) {
      return obj ? obj.name : ''
    },

    async getItemList(searchTerm, page = 1) {
			const req = new PaginatedRequest('name', true, page, 25, searchTerm);
			req.courseID = this.courseID;
      const pr = await TaxonomyAPI.getMatchingTaxonomies( req );

      this.items = []
      if( !pr || !pr.data ) return
      this.pages = pr.pages
      for( let item of pr.data ) this.items.push( Taxonomy.import( item ) )
    },
  }


}
</script>
