import Exim from '@/models/Exim.js'

class Review extends Exim {

	reviewID = null
	authorName = null
	authorLine2 = null    // Aug 2024: ONLY available for EducatorReviews
	authorLine3 = null    // Aug 2024: ONLY available for EducatorReviews
	userID = null
	title = null
	body = null
	email = null
	originalBody = null
	stars = 0
	modalMessageID = null
	affiliateID = null
	affiliates = []       // [ { id: Number, name: String, primary: Boolean }, ... ]
	tags = []             // [ string, string, ... ]
	customerID = null
	licenseKey = null
	starPrompt = null
	bodyPrompt = null
	timestamp = 0
	datePublished = null  // Aug 2024: ONLY available for EducatorReviews
	selectedFileID = null // Aug 2024: ONLY available for EducatorReviews -- the FileID of the image represented by imageURL
	imageURL = null       // Aug 2024: ONLY available for EducatorReviews

	// Publication management properties
	isPublished = null
	isFeatured = null
	isSuper = null
	rank = null
	superIndex = null
	category = null

	loginCount = null
	userSessionCount = null
	minutesInProgram = null
	minutesPurchase = null
	minutesAccount = null


	// Getter-only props (optional)
	authorEmail = null
	affiliateName = null
	customerName = null
	modalMessageName = null


	get hoursInProgramString() {
		if( this.minutesInProgram === null ) return null
		return `${ (Math.round( (this.minutesInProgram / 6) ) / 10).toLocaleString() } h`
	}
	
	get hoursPurchaseString() {
		if( this.minutesPurchase === null ) return null
		return `${ (Math.round( (this.minutesPurchase / 6) ) / 10).toLocaleString() } h`
	}

	get yearsDaysPurchaseString() {
		if( this.minutesPurchase === null ) return null
		const totalDays = Math.round( this.minutesPurchase / 1440 )

		const years = Math.floor( totalDays / 365 )
		const days = totalDays % 365
		return `${ years > 0 ? years + ' y, ' : '' }${ days } d`
		// return `${ years } y, ${ days } d`
	}
	
	get hoursAccountString() {
		if( this.minutesAccount === null ) return null
		return `${ (Math.round( (this.minutesAccount / 6) ) / 10).toLocaleString() } h`
	}

	get yearsDaysAccountString() {
		if( this.minutesAccount === null ) return null
		const totalDays = Math.round( this.minutesAccount / 1440 )

		const years = Math.floor( totalDays / 365 )
		const days = totalDays % 365
		return `${ years > 0 ? years + ' y, ' : '' }${ days } d`
		// return `${ years } y, ${ days } d`
	}


	get primaryAffiliate() {
		for( var aff of this.affiliates ) if( aff.primary ) return aff
		return null
	}


	get secondaryAffiliates() {
		return this.affiliates.filter( aff => !aff.primary )
	}
	
	
	getAffiliateNames( maxNameLength = null ) {
		return this.affiliates.map( elem => {
			const name = (elem.primary ? '⭐ ' : '') + (elem.name || elem.id || '')
			return maxNameLength && name.length > maxNameLength ? name.substring( 0, maxNameLength - 5 ) + ' ...' : name
		})
	}


	addAffiliate( affSummary ) {
		// short-circuit if null
		if( affSummary == null ) return

		// short-circuit if aff already present
		if( this.affiliates.find( elem => elem.id == affSummary.id ) ) return

		// Add new affiliate
		this.affiliates.push( affSummary )
	}
	
	
	removeAffiliate( affID ) {
		this.affiliates = this.affiliates.filter( elem => elem.id !== affID)
	}



	addTag( tag ) {
		if( tag === '' || tag === null || tag === undefined ) return
		if( this.tags.indexOf(tag) === -1 ) this.tags.push( tag )
	}

	removeTag( tag ) {
		var idx = this.tags.indexOf( tag )
		if( idx >= 0 ) this.tags.splice( idx, 1 )
	}
	



	static import(obj) {
		return super.import( obj, [], [ 'timestamp', 'datePublished' ] )
	}
}

export default Review
