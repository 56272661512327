class StringUtils {


	static ellipsify( text, maxLength = 250 ) {
		if( text === null || text === undefined ) return ''
		
		text = text.replace(/<[^>]+>/g, ' ');
		if( text.length > maxLength ) text = text.slice( 0, maxLength-5 ) + '...'
		return text
	}


}

export default StringUtils