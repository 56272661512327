import Exim from '@/models/Exim.js'


export default class Feedback extends Exim {
  
  id = null
  title = ''
  body = ''
  type = ''
  state = 'open'
  notes = null
  importance = 0
  timestamp = null
  license = null

  powerPrepInfo = null
  browserInfo = null
  unread = true
  userID = null
  
  firstName = ''
  lastName = ''
  email = ''
  
  hasScreenshot = false



  get preview() {
    const numChars = 40;
    return this.body.length > numChars ? this.body.slice(0, numChars + 1) + '...' : this.body;
  }


  static import( obj ) {
    const item = super.import( obj, [], [ 'timestamp' ] )
    return item
  }
}
