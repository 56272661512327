<template>
  <div>
    <ExpandBox :title="Title">
      <label>Subject Line</label><br />
      <input type="text" v-model="newTemplate.subjectLine" class="input-box" /><br /><br />

      <label>HTML Template:</label><br />
      <textarea v-model="newTemplate.templateHTML" rows="10" class="input-box" /><br /><br />

      <label>Text Template:</label><br />
      <textarea v-model="newTemplate.templateText" rows="10" class="input-box" /><br /><br />

      <button class="button"  @click="$emit('test', EmailTemplate)">Test</button>
      <button class="button" @click="saveEmail()" :disabled="changesMade" >Save Changes</button>

      <p v-if="changesSaved"
        :class="{'message-success': changesSaved}">
        Changes successfully saved
      </p>

      <p v-if="saveFailed" class="message-warning">
        Something went wrong; changes not saved.
      </p>
    </ExpandBox>
  </div>
</template>

<script>
import EmailTemplate from '@/models/EmailTemplate'
import ExpandBox from './utilities/ExpandBox'
import EmailAPI from '@/api/EmailAPI'
import OrderedSet from '@/libraries/OrderedSet'

export default {
  name: 'edit-email-template',

  props: {
    EmailTemplate: Object,
    Title: String
  },

  data() {
    return {
      newTemplate: new EmailTemplate(),
      saveFailed: false,
      changesSaved: false
    }
  },

  computed: {
    changesMade() {
      return this.newTemplate.isSame(this.EmailTemplate);
    }
  },

  watch: {
    changesMade(value) {
      if (!value) {
        this.saveFailed = false;
        this.changesSaved = false;
      }
    }
  },

  created() {
    Object.assign(this.newTemplate, this.EmailTemplate);
  },

  methods: {
    async saveEmail() {
      let emails = new OrderedSet([this.newTemplate]);
      try {
        await EmailAPI.putEmails(emails);
        this.changesSaved = true;
        this.$emit('saved', this.newTemplate);
      } catch (e) {
        console.error(e);
        this.saveFailed = true;
      }
    }
  },

  components: {
    ExpandBox
  }
}
</script>

<style scoped>
.input-box {
  width: 70%;
}
</style>
