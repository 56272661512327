class SectionManager {


  constructor() {
    this.sections = new Map();
  }

  reset() { this.sections.clear() }

  setSection(section, overrideFlag = false) {
    this.sections.set(section.name, {section, overrideFlag});
  }

  isOverride(section) {
    const value = this.sections.get(section.name)
    if(!value) return null
    return value.overrideFlag
  }
}

export default SectionManager;
