import Vue from 'vue'
import Vuex from 'vuex'
import Router from '@/router'

import RoleManager from '@/features/Roles/RoleManager.js'
import OrderedSet from '@/libraries/OrderedSet.js'
import DataBus from '@/libraries/DataBus.js'

import API from "@/api/API.js"
// import UserAPI from "@/api/UserAPI.js"


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Courses: new OrderedSet(),
    Products: new OrderedSet(),

    Roles: new OrderedSet(),
    Features: new OrderedSet(),


    user: {},
    userRoles: new RoleManager(),   // Roles & features assigned to the logged-in user

    pipes: new DataBus(),

    showOverlay: false,

    userTableSettings: {
      pageSize: 50
    },

    ExpandBoxes: [],
  },

  mutations: {
    setUser( state, user ) { state.user = user },
    setUserRoles(state, roles) {
      state.userRoles.reset()
      state.userRoles.addRoles( roles )
    },

    setPageSize(state, size) { state.userTableSettings.pageSize = size; },
  },

  actions: {

    async logout(context) {
      let success = false;
      try {
        success = await API.logout();
      } catch (e) {
        console.error(e);
      }

      if (success) {
        context.dispatch('unloadSession');
        Router.push('login');
      }
    },


    unloadSession(context) {
      context.state.Courses = new OrderedSet();
      context.state.Roles = new OrderedSet();
      context.state.Features = new OrderedSet();
      context.state.Products = new OrderedSet();
      context.state.user = null
      context.state.userRoles.reset()
      context.state.ExpandBoxes = [];
    }
  }
})
