<template>
  <div>
    <section class='details-body'>
      <label class='body-item'>
        Name: <input type='text' v-model='newFeature.name' placeholder="Name" @keyup.enter="save()" @keyup.esc="save()" />
      </label>

      <label class='body-item'>
        <div>Description:</div>
        <textarea v-model='newFeature.description' placeholder="Description" @keyup.enter="save()" @keyup.esc="save()" />
      </label>
    </section>

    <button class='button' @click='save' :disabled='!changesMade'>Save Changes</button>
  </div>
</template>

<script>
import Feature from '@/models/Feature'
import RolesAPI from '@/api/RolesAPI'

export default {
  name: 'feature-details',

  props: {
    Feature: Object
  },

  data() {
    return {
      newFeature: new Feature()
    }
  },

  computed: {
    changesMade() { return !this.Feature.isSame(this.newFeature); }
  },

  watch: {
    Feature() { this.syncFeatures(); }
  },

  created() { this.syncFeatures(); },

  methods: {
    async save() {
      if( !this.newFeature.name ) return
      try {
        let res;
        if (this.newFeature.id) {
          await RolesAPI.editFeature(this.newFeature.export());
          this.$store.state.Features.updateElement('id', this.newFeature)
        } else {
          res = await RolesAPI.addFeature(this.newFeature.export());
          this.$store.state.Features.push(res)
          this.$emit('added', res);
        }
      } catch (e) {
        console.log(e);
        alert(`Could not save changes: ${e}`);
      }
    },

    syncFeatures() {
      Object.assign(this.newFeature, this.Feature);
    }
  }
}
</script>
