<template>
<section>

	<div class='flex justify-space-between flex-align-center py-05' style="position: sticky; top: 0; background: white;">
		
		<div class="flex-row flex-justify-start flex-align-center flex-gap-05">
			<SearchBox @search="str => search(str)" />
			<!-- Insert left-align controls here -->
		</div>

		<!-- Insert right-aligned controls here -->
		<div>{{ count ? count.toLocaleString() : count }} transactions on {{ pages }} pages</div>
	</div>

	<section v-if="!loading">
		<ObjectTable
			:Columns="columns"
			:Source="items"
			:Numbered="true"
			:SortBy="sortBy"
			:SortAsc="sortAsc"
			:PageNum="page"
			:PageSize="pageSize"
			:Deletable="true"
			@sort="prop => sort(prop)"
			@edit="item => open(item)"
			@delete="item => showDelete(item)"
			class="mt-1"
			ref="objectTable"
		/>
		<Paginator v-model="page" @input="initialize( false )" :numPages="pages" />
		<TransactionModal :transaction="selectedItem" ref="details" @close="$refs.objectTable.deselect()" />
	</section>

	<section v-if='loading' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

	<section v-if="error" class="warning">
		Failed to get transactions
	</section>


	<ConfirmDialog @cancel="selectedItem = null" @confirm="confirmDelete()" ref="confirmDeleteModal">
		<div v-if="selectedItem !== null">
			<div>Are you sure you want to delete this Transaction?</div>
			<div style="font-size: 1.25em; font-weight: bold; margin: 0.25em 0;">
				Transaction # {{ selectedItem.id }}<br><br>
				<span style="color: red;">${{ selectedItem.amount.toLocaleString() }} {{ selectedItem.currency }}</span><br>
				Paid by
				{{ selectedItem.userID ? `${selectedItem.userFirstName} ${selectedItem.userLastName}` : '' }}
				<br v-if="selectedItem.userID && selectedItem.customerID">
				{{ selectedItem.customerName ? selectedItem.customerName : '' }}
			</div><br>
			<div><strong>This action CANNOT be undone!</strong></div>
		</div>
	</ConfirmDialog>

</section>
</template>



<script>
import OrdersAPI from '@/api/OrdersAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Transaction from '@/features/orders/Transactions/Transaction.js'

import SearchBox from '@/components/utilities/SearchBox.vue'
import TransactionModal from './TransactionModal.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'

export default {
	name: 'TransactionList',


	components: {
		SearchBox,
		TransactionModal,
		ObjectTable,
		Paginator,
		ConfirmDialog,
	},


	props: {
		orderID: {
			type: Number,
			default: null
		},

		userID: {
			type: Number,
			default: null
		},

		customerID: {
			type: Number,
			default: null
		},

		pocID: {
			type: Number,
			default: null
		},

	},



	data() {
		return {
			loading: false,
			error: false,

			sortBy: 'timestamp',
			sortAsc: false,
			page: 1,
			pageSize: 100,
			searchString: null,

			items: [],
			selectedItem: null,
			count: 0,
			pages: 1,

			columns: [
				{
					displayName: '$',
					propertyName: 'amount',
					displayFunction: function( item ) {
						return `$${item.amount.toLocaleString()}`
					},
					sortable: true
				},
				{
					displayName: 'Payee',
					displayFunction: function( item ) {
						const userName = item.userID ? `${item.userFirstName} ${item.userLastName}` : ''
						const custName = item.customerID ? (item.customerName || item.customerID) : ''
						return userName && custName ? `${userName}<br>${custName}` : userName || custName
					},
				},
				{
					displayName: 'Type',
					propertyName: 'type',
					sortable: true
				},
				{
					displayName: 'Payment Method',
					propertyName: 'paymentMethod',
					sortable: true
				},
				{
					displayName: 'Date',
					propertyName: 'timestamp',
					displayFunction: function( item ) { return item.timestamp ? item.timestamp.toLocaleString() : '' },
					sortable: true
				},
			],
		}
	},



	created() { this.initialize() },



	methods: {
		async initialize() {
			try {
				this.loading = true
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
				req.searchString = this.searchString
				req.paginate = true
				req.orderIDs = this.order ? [ this.order.orderID ] : this.orderID ? [ this.orderID ] : null
				req.userIDs = this.user ? [ this.user.userID ] : this.userID ? [ this.userID ] : null
				req.customerIDs = this.customer ? [ this.customer.customerID ] : this.customerID ? [ this.customerID ] : null
				req.pocID = this.poc ? [ this.poc.pocID ] : this.pocID ? [ this.pocID ] : null
				req.includeBillingAddress = true

				// Get main table data
				const pr = await OrdersAPI.getTransactions( req )
				this.count = pr.count
				this.pages = pr.pages

				this.items = []
				for( var item of pr.data ) this.items.push( Transaction.import(item) )

				this.error = false

			} catch( e ) {
				this.items = []
				this.count = 0
				this.pages = 1
				this.error = true
				throw e

			} finally {
				this.loading = false
			}
		},


		search( searchStr ) {
			this.searchString = searchStr || null
			this.initialize()
		},


		open( item ) {
			this.selectedItem = item
			this.$refs.details.open()
		},


		showDelete( item ) {
			this.selectedItem = item
			this.$refs.confirmDeleteModal.open()
		},

		confirmDelete() {
			if( !this.selectedItem ) return
			OrdersAPI.deleteTransaction( this.selectedItem.id )
			this.initialize()
		},


		sort( propName ) {
			if( this.sortBy === propName ) this.sortAsc = !this.sortAsc
			else {
				this.sortBy = propName
				this.sortAsc = propName == 'timestamp' || propName == 'amount' ? false : true
			}

			this.initialize()
		},

	}

}
</script>



<style scoped>

</style>