<template>
<section>
	<StretchModal
		:showModal="showDetails"
		:clickToClose="false" @close="close()"
		:width="width"
		:height="height"
		:blur="true"
		:borderColor="isActive && inUse ? 'green': null"
		:headerColor="isActive && inUse ? 'lightgreen': (isActive ? 'orange' : 'red')"
	>
		<template #header>
			<div class="flex-row flex-justify-between flex-gap-1">
				<div>
					Modal Message<span v-if="item">: {{ item.name }}</span>
				</div>
				<div>
					<ToggleButton v-model="isActive" :sync="true" />
					Activate Modal
				</div>
			</div>
		</template>

		<ModalMessageDetails 
			v-if="item"
			:modalMessage="item"
			:isActive="isActive"
			@create="mm => $emit('create', mm)"
			@save="newMM => $emit('save', newMM)"
			@updateActive="(isActive, isUsed) => updateActive(isActive, isUsed)"
			ref="details"
		/>
	</StretchModal>

	<ConfirmDialog
		:show="showConfirm"
		:confirm="'Close Anyway'"
		:cancel="'Go Back'"
		@confirm="confirmClose()"
		@cancel="showConfirm = false"
	>
		<div>WARNING:<br>Changes NOT Saved!<br><br>Close anyway?</div>
	</ConfirmDialog>
</section>
</template>
 

<script>
import ModalMessage from './ModalMessage.js'

import ModalMessageDetails from './ModalMessageDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
	name: 'ModalMessageDetailsModal',

	components: {
		ModalMessageDetails,
		StretchModal,
		ConfirmDialog,
		ToggleButton,
	},


	props: {
		item: ModalMessage,

		width: {
			type: String,
			default: '80%'
		},
		height: {
			type: String,
			default: '90%'
		},
	},


	data() {
		return {
			showDetails: false,
			showConfirm: false,
			isActive: this.item ? this.item.isActive : false,
			inUse: this.item ? this.item.isUsed : false,
		}
	},


	watch: {
		item() {
			if ( !this.item ) {
				this.isActive = false
				this.isUsed = false
				return
			}

			this.isActive = this.item.isActive
			this.inUse = this.item.isUsed
		},

	},


	methods: {
		open() {
			this.showDetails = true
		},

		close() {
			if(this.$refs.details.changesMade) {
				this.showConfirm = true
				return
			}

			this.showDetails = false
			this.$emit('close')
		},

		confirmClose() {
			this.showConfirm = false
			this.showDetails = false
			this.$emit('close')
		},

		updateActive( isActive, isUsed ) {
			console.debug("updateActive", isActive, isUsed)
			if( !this.item ) return

			this.isActive = isActive
			this.inUse = isUsed
		},

	}
}
</script>


<style scoped>

</style>