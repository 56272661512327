<template>
<section>

	<div class="flex-row flex-justify-between my-05">
		<SearchBox @search="str => searchString = str" />
		
		<div class="flex-row flex-justify-end flex-gap-2">
			<div>{{ count ? count.toLocaleString() : count }} entries on {{ pages }} page{{ pages == 1 ? '' : 's' }}</div>
			<div>
				<label>Per page: </label>
				<select v-model="pageSize">
					<option :value='25'>25</option>
					<option :value='50'>50</option>
					<option :value='100'>100</option>
					<option :value='250'>250</option>
					<option :value='500'>500</option>
				</select>
			</div>
		</div>

	</div>


	<div v-if='!loading && !error'>

		<div v-if='items.length'>
			<ObjectTable
				:Source='items'
				:Columns='columns'
				:allowSelect="false"
				:Numbered='true'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				:Deletable='true'
				@sort='prop => sort( prop )'
				@delete='item => showDeleteSelected( item )'
				ref='objectTable'
			/>
			<Paginator v-model="page" :numPages="pages" @input="pg => toPage( pg )" />
		</div>

		<div v-else class="NoResults">No results</div>

	</div>

	<div v-if="error" class="warning">Failed to get records</div>


	<ConfirmDialog ref="confirmDeleteRow" @cancel="itemToDelete = null" @confirm="deleteItem()">
		<div v-if="itemToDelete">Really delete <b>{{ itemToDelete.email }}</b>?</div>
	</ConfirmDialog>


	<StretchModal ref="selectedMessageModal" padding="1em" width="auto" @close="selectedMessage = null">
		<template #header>Message Details</template>
		<MessageDetails v-if="selectedMessage" :message="selectedMessage" />
		<div v-else class="center">Message Not Found</div>
  </StretchModal>

</section>
</template>



<script>
import MessageDetails from './MessageDetails.vue'
import SearchBox from '@/components/utilities/SearchBox.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import UnsubscribeRecord from './UnsubscribeRecord.js'

export default {
	name: 'UnsubscribeList',

	components: {
		MessageDetails,
		SearchBox,
		ObjectTable,
		Paginator,
		ConfirmDialog,
		StretchModal,
	},


	data() {
		return {
			page: 1,
			pages: 1,
			pageSize: 100,
			count: 0,
			sortBy: 'time',
			sortAsc: false,
			searchString: null,

			items: [],
			loading: false,
			error: false,

			selectedItem: null,
			itemToDelete: null,

			selectedMessage: null,

			tagToAdd: null,
			tagToDelete: null,

			columns: [
				{
					displayName: 'E-Mail Address',
					propertyName: 'email',
					sortable: true,
				},
				{
					displayName: 'User ID',
					propertyName: 'userID',
					sortable: true,
				},
				{
					displayName: 'Topic',
					propertyName: 'topic',
					sortable: true,
				},
				{
					displayName: 'Source',
					propertyName: 'source',
					sortable: true,
				},
				{
					displayName: 'Message ID',
					propertyName: 'emailGUID',
					displayFunction: function( item ) {
						return `<span class="link">${ item.emailGUID }</span>`
					},
					clickHandler: ( event, row ) => {
						this.showMessageDetails( row.emailGUID )
					},
					sortable: true,
				},
				{
					displayName: 'Timestamp',
					propertyName: 'time',
					displayFunction: function( item ) { return item.time ? item.time.toLocaleString() : null },
					sortable: true,
				},
			]
		}
	},



	watch: {
		pageSize() { this.page = 1; this.initialize() },
		searchString() { this.page = 1; this.initialize() },
	},


	created() { this.initialize() },



	methods: {
		async initialize() {
			this.items = []
			this.error = false
			this.loading = true

			try {
				const req = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize, this.searchString )
				const pr = await MessageCenterAPI.getUnsubscribeList( req )

				this.count = pr.count
				this.pages = pr.pages

				for( var item of pr.data ) this.items.push( UnsubscribeRecord.import( item ) )

			} catch (e) {
				this.error = true
				throw e

			} finally {
				this.loading = false
			}
		},


		showDeleteSelected( item ) {
			this.itemToDelete = item
			this.$refs.confirmDeleteRow.open()
		},


		async deleteItem() {
			const item = this.itemToDelete

			const req = { email: item.email, topic: item.topic, source: item.source }
			await MessageCenterAPI.deleteUnsubscribeRecord( req )
			
			const idx = this.items.findIndex( elem => elem.email == item.email && elem.topic == item.topic && elem.source == item.source )
			if( idx < 0 ) return

			this.items.splice( idx, 1 )
			this.itemToDelete = null
		},


		async showMessageDetails( emailGUID ) {
			
			const req = new PaginatedRequest()
			req.guids = [ emailGUID ]
			const pr = await MessageCenterAPI.getSentEmails( req )

			this.selectedMessage = pr && pr.data && pr.data.length > 0 ? pr.data[0] : null
			this.$refs.selectedMessageModal.open()
		}

	}
}
</script>



<style scoped>

</style>