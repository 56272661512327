import API from './API.js'
// import PaginatedRequest from './PaginatedRequest.js'
import PaginatedResponse from './PaginatedResponse.js'

class LoginTrackingAPI extends API {

	static controllerName = 'LoginTrackingAPI'


	/**
	 * Get a PaginatedResponse representing a set of LoginAttempts
	 * @param {PaginatedRequest} req 
	 * @returns res
	 */
	static async getUserLoginAttempts( req ) {
		const res = await this.newPost( 'getUserLoginAttempts', [], req )
		return PaginatedResponse.import( res.data )
	}


	/**
	 * Get a PaginatedResponse representing a set of UserSessions
	 * @param {PaginatedRequest} req 
	 * @returns res
	 */
	static async getUserSessions( req ) {
		const res = await this.newPost( 'getUserSessions', [], req )
		return PaginatedResponse.import( res.data )
	}



	/**
	 * Get the nodes & questions worked on during a UserSession
	 * @param {Number} sessionID 
	 * @returns 
	 */
	static async getUserSessionActivity( sessionID ) {
		return await this.newGet( 'getUserSessionActivity', [ sessionID ] )
	}



	/**
	 * Get a PaginatedResponse representing a set of UserTime items
	 * @param {PaginatedRequest} req 
	 * @returns res
	 */
	static async getUserTimes( req ) {
		const res = await this.newPost( 'getUserTimes', [], req )
		return PaginatedResponse.import( res.data )
	}



	/**
	 * Get a JSON object representing UserTimes summary data
	 * @param {PaginatedRequest} req 
	 * @returns res
	 */
	static async getUserTimeSummary( req ) {
		const res = await this.newPost( 'getUserTimeSummary', [], req )
		return res
	}



	static async getUserTimeHistogram( name, req ) {
		return await this.newPost( 'getUserTimeHistogram', [ name ], req )
	}


}

export default LoginTrackingAPI