import Exim from "@/models/Exim";

class ModalMessageView extends Exim {

	id = null;
	modalMessageID = null;
	userID = null;
	time = null;

	displayLocation = null;
	licenseKey = null;
	numLogins = null;
	minutesInProgram = null;
	minutesPurchase = null;
	minutesAccount = null;


	// OPTIONAL: getter-only properties
	modalMessageName = null;
	userFirstName = null;
	userLastName = null;
	userEmail = null;


	get hoursInProgramString() { return `${ (Math.round( (this.minutesInProgram / 6) ) / 10).toLocaleString() } h` }
	get hoursPurchaseString() { return `${ (Math.round( (this.minutesPurchase / 6) ) / 10).toLocaleString() } h` }
	get hoursAccountString() { return `${ (Math.round( (this.minutesAccount / 6) ) / 10).toLocaleString() } h` }


	static import(obj) {

    const newObj = super.import(obj)
		if(newObj.time) newObj.time = new Date(newObj.time * 1000)
		return newObj;
  }

}
export default ModalMessageView