<template>
	<SearchDropdown
		:placeholder="placeholder"
		:DisplayFunction="displayFunction"
		:InitialValue="initialValue || displayFunction(affiliate)"
		:ObjKey="'id'"
		:width="width"
		:filterIcon="affiliateTags !== null"
		:browseIcon="true"
		:pages="pages"
		:externalResults="items"
		:dropUp="dropUp"
		@selected="item => select(item)"
		@search="(txt, pg) => getList(txt, pg)"
		@confirm="txt => emitSearchList(txt)"
		@none="select(null)"
		ref="search"
	/>
</template>


<script>
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Affiliate from './Affiliate.js'
import SearchDropdown from '@/components/utilities/SearchDropdown.vue'

export default {
	name: 'AffiliateSearchDropdown',

	components: {
		SearchDropdown,
	},


	props: {
		affiliate: {
			type: Object,
			default: null
		},

		affiliateTags: {
			type: [Array, false],
			default: null
		},

		width: {
			type: String,
			default: '50%'
		},

		placeholder: {
			type: String,
			default: 'Search Affiliates'
		},

		initialValue: {
			type: String,
			default: null
		},

		dropUp: {
			type: Boolean,
			default: false
		},

	},


	model: {
		prop: 'affiliate',
		event: 'update'
	},

	data() {
		return {
			items: [],
			pages: 1,
		}
	},

	methods: {

		select(item) {
			this.$emit('update', item);
		},


		clear() { this.$refs.search.clear() },


		displayFunction(obj) {
			if(!obj) return '';
			return `${ obj.name } (${ obj.urlSlug })`
		},


		async emitSearchList( searchTerm ) {
			const res = await this.search( searchTerm, 1, 0 )
			const list = []
			for( let item of res.data.data ) list.push( Affiliate.import( item ) )
			this.$emit( 'matches', list, searchTerm )
		},


		async search( searchTerm, page = 1, pageSize = 25 ) {
			const req = new PaginatedRequest()
			req.page = page
			req.pageSize = pageSize
			req.tags = this.affiliateTags
			req.searchString = searchTerm ? searchTerm : null
			return await SalesManagementAPI.getAffiliates( req )
		},


		async getList( searchTerm, page = 1 ) {
			const res = await this.search( searchTerm, page )

			this.items = []
			if(!res) return
			this.pages = res.data.pages
			for(let item of res.data.data) this.items.push( Affiliate.import( item ) )
		},
	}


}
</script>
