<template>
<section>
	<select v-model="location.scheme">
		<option :value="null">ANY SCHEME</option>
		<option value="http">HTTP</option>
		<option value="https">HTTPS</option>
	</select>://
	<input v-model="location.domain" placeholder="domain" :class="{ 'invalid' : !domainValid }" @change="validateDomain()" />:
	<input v-model.number="location.port" placeholder="port" style="width: 4em;" />
	<span v-if="location.path === ''" class="px-05"><button class="pillButton secondary blue bg" @click="location.path = null"><span class="icon-plus" /> Add Path</button></span>
	<span v-else class="px-05">
		/<input v-model="location.path" placeholder="path/to/page" :class="{ 'invalid' : !pathValid }" @change="validatePath()" />
		<button class="pillButton secondary blue bg" @click="location.path = ''"><span class="icon-cross" /></button>
	</span>
	?<input v-model="location.query" placeholder="query=string" :class="{ 'invalid' : !queryValid }" @change="validateQuery()" />
	<input v-if="showWeight" v-model.number="location.weightPercent" type="number" step="1" min="0" max="100" class="ml-05" style="width: 5em;" :class="{ 'invalid' : location.weight < 0 || location.weight > 1 || totalWeight > 1 }" @change="$emit( 'updateWeight' )" />
	<button class="pillButton secondary bg red" style="margin-left: 0.5em;" @click.stop="$emit( 'delete' )"><span class="icon-bin2" /></button>
</section>
</template>



<script>
import Location from './Location.js'
export default {
	name: 'LocationEditor',


	props: {
		location: Location,

		showWeight: {
			type: Boolean,
			default: false
		},

		totalWeight: {
			type: Number,
			default: 0
		},
	},


	data() {
		return {
			domainValid: true,
			pathValid: true,
			queryValid: true,

		}
	},



	watch: {
		domainValid() { console.debug( "domainValid:", this.domainValid ) },
		pathValid() { console.debug( "pathValid:", this.pathValid ) },
		queryValid() { console.debug( "queryValid:", this.queryValid ) },
	},


	created() { this.initialize() },


	methods: {

		initialize() {
			this.validateDomain()
			this.validatePath()
			this.validateQuery()
		},


		validateDomain() {
			console.debug("validating domain")
			this.domainValid = this.location.checkDomain()
		},

		validatePath() {
			console.debug("validating path")
			this.pathValid = this.location.checkPath()
		},

		validateQuery() {
			console.debug("validating query")
			this.queryValid = this.location.checkQuery()
		},

	}
}
</script>



<style scoped>
input.invalid {
	background-color: #faa;
	border: 1px solid red;
}
</style>