<template>
<section>
<StretchModal @close='close()' :closeIcon="true" :width="width" :height="height" ref="mainModal">
	<template #header>
		<span class="link" v-if="orderIndex > 0" @click.stop="$emit('previous')"> &lt;</span>
		Order #{{order ? order.orderID : ''}}
		<span class="link" v-if="orderIndex < numOrders -1" @click.stop="$emit('next')"> &gt;</span>
	</template>
	<template #default="{ closed }">
		<OrderDetails v-if="order" :order="order" @orderSaved="newOrder => $emit('orderSaved', newOrder)" @next="$emit('next')" @previous="$emit('previous')" @close="closed" ref="orderDetails" />
	</template>
</StretchModal>

<ConfirmDialog :confirm="'Close Anyway'" :cancel="'Go Back'" @confirm="confirmClose()" ref="confirmModal">
	<div>Are you sure?  <b>Unsaved changes will be lost!</b></div>
</ConfirmDialog>
</section>
</template>



<script>
import OrderDetails from './OrderDetails.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
export default {
	name: 'OrderDetailsModal',

	components: {
		OrderDetails,
		StretchModal,
		ConfirmDialog,
	},


	props: {
		order: {
			type: Object,
			default: null
		},
		show: {
			type: Boolean,
			default: false
		},
		width: {
			type: String,
			default: '90%'
		},
		height: {
			type: String,
			default: '100%'
		},
		orderIndex: Number,
		numOrders: Number,
	},



	methods: {
		open() { this.$refs.mainModal.open() },
		close() {
			if( this.$refs.orderDetails.changesMade ) {
				this.$refs.confirmModal.open()
				this.$refs.mainModal.open() // keep main modal open
				return
			}

			this.$emit('close')
		},

		confirmClose() {
			this.$refs.mainModal.close( false )
		},

	}
}
</script>
