<template>
<section>
	<!-- <div class='flex justify-space-between flex-align-center py-05' style="position: sticky; top: 0; background: white;"> -->

		<!-- <div class="flex-row flex-justify-start flex-align-center flex-gap-05">
			<SearchBox @search="str => searchStr = str" />
		</div> -->

	<div class='flex justify-center' v-if='showSpinner'>
		<div class='icon-spinner4 spin-loader'></div>
	</div>


	<section v-if='!showSpinner'>

		<div v-if='rows.length'>
			<div class="flex-row flex-justify-between flex-align-center">
			</div>
			<ObjectTable
				class='font-small'
				ref="objectTable"
				:Source='rows'
				:Columns='columns'
				:DisplayFunction='(item, prop) => displayFunction(item, prop)'
				:Numbered='true'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				@sort='selectSortBy'
			/>

		</div>
		<div v-else class="NoResults">No results</div>
	</section>


</section>
</template>

<script>

// import SearchBox from "@/components/utilities/SearchBox.vue";
import ObjectTable from '@/components/utilities/ObjectTable.vue';
// import StretchModal from '@/components/utilities/StretchModal.vue';

import ExternalTestsAPI from '@/api/ExternalTestsAPI.js'


export default {
	name: "ExternalTestsList",

	components: {
		// SearchBox,
		ObjectTable,
		// StretchModal,
	},

	props: {

		customerID: {
			type: Number,
			default: null
		},
	},

	data() {
		return {
			rows: [],

			columns: [
				{
					displayName: 'Teacher',
					propertyName: 'teacherName',
					sortable: true,
				},
				{
					displayName: 'Test',
					propertyName: 'testName',
					sortable: true
				},
				{
					displayName: '# Students',
					propertyName: 'studentEntryCount',
					sortable: true
				},
			],

			// searchStr: '',
			sortBy: 'studentEntryCount',
			sortAsc: false,
			showSpinner: false,

		}
	},

	async created() {
		this.getExternalTestReport()
	},

	computed: {

	},

	watch: {

		// searchStr() {
		// 	this.getExternalTestReport()
		// },
	},

	methods: {
		async getExternalTestReport() {
			this.showSpinner = true;
			this.rows = await ExternalTestsAPI.getDataEntryReport(this.customerID)
			this.showSpinner = false;
		},


		displayFunction(item, prop) {
			if (prop == 'teacherName') {
				return `${item.teacherFirstName} ${item.teacherLastName}`;
			} else return item[prop];
		},


		selectSortBy(prop) {
			if (prop == this.sortBy) {
				this.sortAsc = !this.sortAsc;
			
			} else {
				this.sortBy = prop;

				if ( this.sortBy === 'studentEntryCount' ) {
					this.sortAsc = false;
				
				} else this.sortAsc = true;
			}
			this.getExternalTestReport();
		}
	},
}
</script>





<style scoped>

</style>
