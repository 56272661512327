import API from "./API";
import {base_url} from "./../Config";

import LoadingQuote from "@/models/LoadingQuote";
import LoginScreen from "@/models/LoginScreen";

export default class SettingsAPI extends API {

  // ***************************************************************************
  // Loading Quotes
  // ***************************************************************************

  static async getLoadingQuotes() {
    let url = base_url + '/SiteAdmin/SettingsAPI/getLoadingQuotes';
    let res = await super.get(url);

    if (!res) {
      throw new Error("Could not get quotes from server. Changes will not be saved.");
    }

    let quotes = [];
    for (let q of res) {
      quotes.push(new LoadingQuote(q.quote, q.priority, q.id));
    }

    return quotes;
  }

  static async addLoadingQuote(quote) {

    let url = base_url + '/SiteAdmin/SettingsAPI/addLoadingQuote';
    let res = await super.add(url, quote.export());

    return new LoadingQuote(res.quote, res.priority, res.id);
  }

  static async editLoadingQuote(quote) {

    if (quote.id === null) {
      throw new Error("Cannot edit loading quote: invalid ID.");
    }

    let url = base_url + '/SiteAdmin/SettingsAPI/editLoadingQuote/' + quote.id;
    return await super.edit(url, quote.export());
  }

  static async deleteLoadingQuote(quote) {

    if (quote.id === null) {
      throw new Error("Cannot delete loading quote: invalid ID.");
    }
    let url = base_url + '/SiteAdmin/SettingsAPI/deleteLoadingQuote/' + quote.id;
    return await super.delete(url);
  }

  static async getRandomQuote() {
    let url = base_url + '/BootstrapAPI/getRandomLoadingQuote';
    let res = await super.get(url);
    return res.quote;
  }

  // ***************************************************************************
  // Login Screens
  // ***************************************************************************

  static async getLoginScreens() {
    let url = base_url + '/SiteAdmin/SettingsAPI/getLoginScreens';
    let res = await super.get(url);

    if (!res) {
      throw new Error("Could not get loading screens from server. Changes will not be saved.")
    }

    let screens = [];
    for (let s of res) {
      screens.push(new LoginScreen(s.title, s.image_url, s.priority, s.id));
    }

    return screens;
  }

  static async addLoginScreen(loginScreen) {
    let url = base_url + '/SiteAdmin/SettingsAPI/addLoginScreen';
    let res = await super.add(url, loginScreen.export());
    return new LoginScreen(res.title, res.image_url, res.priority, res.id);
  }

  static async editLoginScreen(loginScreen) {
    if (loginScreen.id === null) {
      throw new Error("Cannot edit login screen: invalid ID.");
    }
    const url = base_url + '/SiteAdmin/SettingsAPI/editLoginScreen/' + loginScreen.id;
    let res = await super.edit(url, loginScreen.export());
    return LoginScreen.import(res);
  }

  static async deleteLoginScreen(loginScreen) {
    if (loginScreen.id === null) {
      throw new Error("Cannot delete login screen: invalid ID.");
    }
    let url = base_url + '/SiteAdmin/SettingsAPI/deleteLoginScreen/' + loginScreen.id;
    return await super.delete(url);
  }

  static async getRandomLoginScreen() {
    let url = base_url + '/BootstrapAPI/getRandomLoginScreen';
    let res = await super.get(url);

    return new LoginScreen(res.title, res.image_url);
  }
}
