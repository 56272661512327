<template>
<div class="border round-05 mt-1 py-05 relative bg-white" :class="{ 'primary' : updatedContact.isPrimary }" style="min-height: 2em;">
	
	<div v-if="editMode" class="flex-row flex-align-center flex-gap-05 mx-05">
		<ToggleButton v-model="updatedContact.isPrimary" :sync="true" />
		<span>Primary Contact</span>
	</div>


	<PocSection
		ref="name"
		:section="[ updatedContact ]"
		:pocID="updatedContact.id"
		:editKeys="['firstName', 'lastName']"
		:singular="true"
		:isName="true"
	/>

	<PocSection 
		ref="position"
		style="font-size: 0.9em; font-style: italic;"
		:section="[ updatedContact ]" 
		:pocID="updatedContact.id"
		:editKeys="['position']"
		:singular="true"
	/>

	<PocSection
		ref="emails"
		:class="{ 'mt-1' : updatedContact.emails.length }"
		:section="updatedContact.emails" 
		:pocID="updatedContact.id"
		:itemName="'email'"
		:editKeys="['email']"
		:labelOptions="['Personal', 'Work']"
		actionButton="send email"
		@action="emailID => sendEmail( emailID )"
		@delete="emailID => deleteContactEmail( emailID )"
	/>

	<PocSection 
		ref="phones"
		:class="{ 'mt-1' : updatedContact.phones.length }"
		:section="updatedContact.phones" 
		:pocID="updatedContact.id"
		:itemName="'phone'"
		:editKeys="['phone']"
		:labelOptions="['Personal Cell', 'Work Cell', 'Office', 'Home']"
		@delete="(itemID) => deleteContactPhone(itemID)"
	/>

	<PocSection
		ref="addresses"
		:class="{ 'mt-1' : updatedContact.addresses.length }"
		:section="updatedContact.addresses" 
		:pocID="updatedContact.id"
		:itemName="'address'"
		:editKeys="['address', 'city', 'state', 'country', 'zip']"
		:labelOptions="['Home', 'Office']"
		@delete="(itemID) => deleteContactAddress(itemID)"
	/>

	
	<div v-if="editMode" class="mt-2 mb-05 mx-05">
		<div>Notes:</div>
		<textarea v-model="updatedContact.notes" placeholder="Add notes here..." class="w-100" style="height: 4em;" />
	</div>
	<div v-if="!editMode && updatedContact.notes" class="mt-1 mb-05 mx-05 color-gray-50 font-size-0-8">
		Notes: {{ updatedContact.notes }}
	</div>


	<div v-if="!editMode" class="buttons-list ma-05 absolute" style="top: 0; right: 0;">
		<span @click="toggleEditMode(true)" class="simple-button icon-pencil"/>
		<span @click="$emit('delete')" class="delete-button icon-bin2"/>
	</div>

	<div v-if="editMode" class="flex-row flex-align-center flex-justify-between flex-gap-2 mt-1 mb-05 mx-05">
		<div class="flex-row flex-align-center flex-gap-05 font-size-0-8">
			<div v-if="updatedContact.importV9" class="bg-green-25 color-white round-05 px-05">V9</div>
			<div v-if="updatedContact.dateCreated" class="color-gray-50">created {{ updatedContact.dateCreated.toLocaleDateString() }}</div>
		</div>
		
		<div class="flex-row flex-align-center flex-justify-center flex-gap-05">
			<div @click="savePOC()" class="save-button mr-1">Save</div>
			<div v-if="!isNew" @click="cancelChanges()" class="save-button">Cancel</div>
		</div>
	</div>
	
	<div v-if="!editMode" class="color-gray-50 mt-05 mx-05 flex-row flex-align-center flex-justify-end flex-gap-05 font-size-0-8">
		<div v-if="updatedContact.importV9" class="bg-green-25 color-white round-05 px-05">V9</div>
		<div>{{ updatedContact.dateCreated ? `created ${updatedContact.dateCreated.toLocaleDateString()}` : 'unknown creation date' }}</div>
	</div>

</div>
</template>



<script>
import PocSection from './PocSection.vue'
import { ToggleButton } from 'vue-js-toggle-button'

import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import POC from './POC.js'

export default {
	name: "PocDetails",

	components: {
		PocSection,
		ToggleButton,
	},



	props: {
		contact: Object,
		isNew: {
			type: Boolean,
			default: false
		},
		
		affiliateID: {
			type: Number,
			default: null
		},
		customerID: {
			type: Number,
			default: null
		},

		edit: {
			type: Boolean,
			default: false
		},
	},



	created() {
		this.initialize();
	},


	mounted() {
		if( this.edit ) this.toggleEditMode( this.edit )
	},



	data() {
		return {
			updatedContact: {},
			editMode: false,
		}
	},



	watch: {
		contact() { this.initialize() },
		edit( value ) { if( value ) this.toggleEditMode( value ) },
	},



	methods: {

		async initialize() {
			this.updatedContact = POC.import( this.contact.export() )
		},


		toggleEditMode(toggle) {
			this.editMode = toggle;
			for (let ref in this.$refs) {
				this.$refs[ref].editMode = toggle
			}
		},


		async savePOC() {
			this.toggleEditMode(false);

			// Save new/updated Contact
			const isNewContact = !this.updatedContact.id
			if( isNewContact || !this.updatedContact.shallowIsSame( this.contact ) ) {
				const cRes = await SalesManagementAPI.saveContact( this.updatedContact.export() )
				this.updatedContact.id = cRes.data
			}


			// Update isPrimary
			if( this.updatedContact.isPrimary !== this.contact.isPrimary ) {
				SalesManagementAPI.savePrimary( this.updatedContact.id, this.affiliateID, this.customerID, this.updatedContact.isPrimary )
			}


			// Save emails
			for( var email of this.updatedContact.emails ) {
				const isNewEmail = email.id === null
				const otherEmail = isNewEmail ? null : this.contact.emails.find( elem => elem.id === email.id )
				
				if( isNewEmail || !otherEmail || !email.isSame( otherEmail ) ) {
					email.pocID = this.updatedContact.id
					const emailRes = await SalesManagementAPI.saveContactEmail( email )
					email.id = emailRes.data
				}
			}

			// Save phones
			for( var phone of this.updatedContact.phones ) {
				const isNewPhone = phone.id === null
				const otherPhone = isNewPhone ? null : this.contact.phones.find( elem => elem.id === phone.id )
				
				if( isNewPhone || !otherPhone || !phone.isSame( otherPhone ) ) {
					phone.pocID = this.updatedContact.id
					const phoneRes = await SalesManagementAPI.saveContactPhone( phone )
					phone.id = phoneRes.data
				}
			}

			// Save addresses
			for( var address of this.updatedContact.addresses ) {
				const isNewAddr = address.id === null
				const otherAddress = isNewAddr ? null : this.contact.addresses.find( elem => elem.id === address.id )
				
				if( isNewAddr || !otherAddress || !address.isSame( otherAddress ) ) {
					address.pocID = this.updatedContact.id
					const addressRes = await SalesManagementAPI.saveContactAddress( address )
					address.id = addressRes.data
				}
			}


			// Replace the old POC with the new one, in the PocList
			this.$emit( "update", this.updatedContact, isNewContact )

		},



		cancelChanges() {
			this.initialize()
			this.toggleEditMode(false)
		},


		sendEmail( emailID ) {
			const newContact = POC.import( this.contact.export() )
			newContact.emails = newContact.emails.filter( e => e.id == emailID )
			this.$store.state.pipes.send( 'teleport', { mode: 'messages.compose', props: { recipientsIn: [ newContact ] }, header: `Message to ${this.contact.firstName} ${this.contact.lastName}` } )
		},


		async deleteContactEmail( emailID ) {
			await SalesManagementAPI.deleteContactEmail( this.contact.id, emailID )
			const idx = this.contact.emails.findIndex( elem => elem.id === emailID )
			if( idx >= 0 ) this.contact.emails.splice( idx, 1 )
		},

		async deleteContactPhone( phoneID ) {
			await SalesManagementAPI.deleteContactPhone( this.contact.id, phoneID )
			const idx = this.contact.phones.findIndex( elem => elem.id === phoneID )
			if( idx >= 0 ) this.contact.phones.splice( idx, 1 )
		},

		async deleteContactAddress( addressID ) {
			await SalesManagementAPI.deleteContactAddress( this.contact.id, addressID )
			const idx = this.contact.addresses.findIndex( elem => elem.id === addressID )
			if( idx >= 0 ) this.contact.addresses.splice( idx, 1 )
		},
	}
}
</script>

<style scoped>
.save-button {
	color: blue;
}
.save-button:hover {
	cursor: pointer;
	text-decoration: underline;
}
.buttons-list {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 5px;
}
.primary {
	border: 3px solid var(--pp10-green-25) !important;
}

</style>