<template>
<section>
	
	<div class="flex-row flex-gap-1 flex-justify-center flex-align-center py-05 sticky-top bg" style="z-index: 2;">

		<UserSearchDropdown
			placeholder="Filter by User..."
			width="200px"
			@updateUser="user => selectUser(user)" />

		<CustomerSearchDropdown
			placeholder="Filter by Customer..."
			width="200px"
			@updateCustomer="cust => selectCustomer(cust)" />

		<AffiliateSearchDropdown
			placeholder="Filter by Affiliate..."
			width="200px"
			@update="aff => selectAffiliate(aff)" />

		<ModalMessageSearchDropdown
			placeholder="Filter by Modal Message..."
			width="200px"
			@update="modal => selectModal(modal)" />
		
		<select v-model="displayLocation">
			<option :value="null">All Locations</option>
			<option value="course">Course</option>
			<option value="library">Library</option>
		</select>
		<div>{{ count ? count.toLocaleString() : count }} views</div>
	</div>

	<div v-if="!loading">
		<ObjectTable
			:Source="views"
			:Columns='columns'
			:Deletable="false"
			:SortBy='sortBy'
			:SortAsc='sortAsc'
			:Numbered="true"
			:PageNum='page'
			:PageSize='pageSize'
			@edit="item => showDetails(item)"
			@sort="prop => selectSortBy(prop)"
			ref="objectTable"
		/>
		<Paginator v-model="page" @input="initialize()" :numPages="pages" />
	</div>
	
	<div v-if="!loading && !error && !views.length" class="NoResults">No Views</div>

	<section v-if='loading' class='flex justify-center'>
		<div class='icon-spinner4 spin-loader'></div>
	</section>

	<section v-if="error" class="warning">
		Failed to get views
	</section>

	<StretchModal ref="detailsModal" @close="deselect()" :width="null" padding="3em">
		<template #header>View #{{ selectedItem.id }} (ModalMessage #{{ selectedItem.modalMessageID }})</template>
		<ModalMessageViewDetails v-if="selectedItem" :modalMessageView="selectedItem" />
	</StretchModal>

</section>
</template>



<script>
import ModalMessageAPI from '@/api/ModalMessageAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import PaginatedResponse from '@/api/PaginatedResponse.js'
import ModalMessageView from './ModalMessageView.js'

import ModalMessageViewDetails from './ModalMessageViewDetails.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import ModalMessageSearchDropdown from '@/features/ModalMessages/ModalMessageSearchDropdown.vue'

export default {
	name: 'ModalMessageViews',

	components: {
		ModalMessageViewDetails,
		ObjectTable,
		Paginator,
		StretchModal,
		UserSearchDropdown,
		CustomerSearchDropdown,
		AffiliateSearchDropdown,
		ModalMessageSearchDropdown,
	},


	props: {
		modalMessageID: {
			type: Number,
			default: null
		},
	},


	data() {
		var cols = [
			{
				displayName: 'User',
				propertyName: 'userFirstName',
				displayFunction: function( item ) {
					return `${item.userFirstName} ${item.userLastName} (${item.userEmail})`
				},
				sortable: true
			},
			{
				displayName: 'Location',
				propertyName: 'displayLocation',
				sortable: true
			},
			{
				displayName: 'TIP <span class="icon-stopwatch" />',
				propertyName: 'minutesInProgram',
				displayFunction: function( item ) {
					return item.hoursInProgramString
				},
				sortable: true
			},
			{
				displayName: 'date',
				propertyName: 'time',
				displayFunction: function( item ) { return item.time ? item.time.toLocaleString() : '' },
				sortable: true
			}
		]

		if( !this.$props.modalMessageID ) {
			cols.unshift({
				displayName: 'Modal Message',
				propertyName: 'modalMessageName',
				displayFunction: function( item ) {
					return `<span style="color: #888;">${item.modalMessageID}: ${item.modalMessageName ? item.modalMessageName : '[ No Name ]'}</span>`
				},
				sortable: true
			})
		}

		return {
			views: [],
			count: 0,
			pages: 1,

			loading: false,
			error: false,

			selectedItem: null,

			sortBy: 'time',
			sortAsc: false,
			page: 1,
			pageSize: 100,

			columns: cols,

			user: null,
			customer: null,
			affiliate: null,
			modal: null,
			
			displayLocation: null,
		}
	},


	watch: {
		modalMessageID() { this.page = 1; this.initialize() },
		displayLocation() { this.page = 1; this.initialize() },
	},


	created() { this.initialize() },



	methods: {
		async initialize() {
			
			try {
				this.loading = true

				this.views = []
				const reqBody = new PaginatedRequest( this.sortBy, this.sortAsc, this.page, this.pageSize )
				reqBody.location = this.displayLocation
	
				if( this.user ) reqBody.userIDs = [ this.user.userID ]
				if( this.customer ) reqBody.customerID = this.customer.id
				if( this.affiliate ) reqBody.affiliateID = this.affiliate.id
	
				if( this.modalMessageID ) reqBody.modalMessageIDs = [ this.modalMessageID ]
				else if ( this.modal ) reqBody.modalMessageIDs = [ this.modal.modalMessageID ]
				
	
				const res = await ModalMessageAPI.getModalMessageViews( reqBody )
				
				const pr = PaginatedResponse.import( res.data )
				for(var item of pr.data) this.views.push( ModalMessageView.import( item ) )
				this.count = pr.count
				this.pages = pr.pages

				this.error = false

			} catch(e) {
				this.error = true
			} finally {
				this.loading = false
			}

		},


		selectSortBy( prop ) {
			
			if (prop == this.sortBy) {
				this.sortAsc = !this.sortAsc
				this.initialize()
				return
			}

			this.sortBy = prop;
			if( this.sortBy == 'minutesInProgram' ||
					this.sortBy == 'time'
			) this.sortAsc = false
			else this.sortAsc = true

			this.initialize()
			
		},


		showDetails( item ) {
			this.selectedItem = item
			this.$refs.detailsModal.open()
		},


		deselect() {
			this.selectedItem = null
			this.$refs.objectTable.deselect()
		},


		async selectUser( user = null ) {
			this.user = user
			this.page = 1
			this.initialize()
		},

		async selectCustomer( customer = null ) {
			this.customer = customer
			this.page = 1
			this.initialize()
		},
		
		async selectAffiliate( affiliate = null ) {
			this.affiliate = affiliate
			this.page = 1
			this.initialize()
		},

		async selectModal( modal = null ) {
			this.modal = modal
			this.page = 1
			this.initialize()
		},

	},

}
</script>



<style scoped>

</style>