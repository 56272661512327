<template>
<div class='ComposeNotification'>


	<section v-if="!sending && !sent" class="mt-1" id="step1" :class="{ 'topSpace' : !recipients.length }">
		
		<!-- Select Recipients -->
		<div class="numberedTitle flex-row flex-align-center flex-justify-center flex-gap-05 mt-2"><span class="number">1</span>Select Recipients / Create Lists</div>
		<div class="buttons-list flex-row flex-justify-center mt-1 mb-2">
			<button :class="{ 'active' : recipientType === 'contacts' }" @click.stop="recipientType = 'contacts'">Send to Business Contacts</button>
			<button :class="{ 'active' : recipientType === 'users' }" @click.stop="recipientType = 'users'">Send to Users</button>
		</div>
		
		<div class="flex-row flex-align-center flex-justify-center flex-gap-1">
			<div class="flex-row flex-gap-05 pa-05 border round-05 bg-red-95">
				<GenericTagSearchDropdown v-model="selectedAffiliateTag" tagType="affiliate" placeholder="Affiliate Tags" style="width: 200px;" />
				<AffiliateSearchDropdown :affiliateTags="selectedAffiliateTag ? [ selectedAffiliateTag ] : null" @update="aff => aff ? selectedAffiliates = [ aff ] : selectedAffiliates = []" @matches="arr => selectedAffiliates = arr" placeholder="Affiliates" style="min-width: 200px;" />
			</div>
			<CustomerSearchDropdown @updateCustomer="cust => cust ? selectedCustomers = [ cust ] : selectedCustomers = []" @matches="arr => selectedCustomers = arr" placeholder="Customers" />
			<CohortSearchDropdown v-if="recipientType === 'users'" v-model="selectedCohort" :customerIDs="selectedCustomerIDs" placeholder="Cohorts" />
			<CourseSearchDropdown v-if="recipientType === 'users'" v-model="selectedCourse" placeholder="Courses" />
		</div>
		
		<div v-if="recipientType === 'users'" class="flex-row flex-align-center flex-justify-center flex-gap-1 mt-1">
			<div class="buttons-list">
				<button :disabled="selectedCourse || hasActiveLicense !== null || isStudent !== null" :class="{ 'active' : hasLicense === false }" @click.stop="hasLicense = hasLicense === false ? null : false">No License</button>
				<button :disabled="selectedCourse || hasActiveLicense !== null || isStudent !== null" :class="{ 'active' : hasLicense === true }" @click.stop="hasLicense = hasLicense === true ? null : true">Must have License</button>
			</div>

			<div class="buttons-list">
				<button :disabled="hasLicense === false" :class="{ 'active' : hasActiveLicense === false && hasOnlyExpiredLicenses }" @click.stop="setHasActiveLicense( false, true )">Has Only Expired</button>
				<button :disabled="hasLicense === false" :class="{ 'active' : hasActiveLicense === false && !hasOnlyExpiredLicenses }" @click.stop="setHasActiveLicense( false )">Has Expired</button>
				<button :disabled="hasLicense === false" :class="{ 'active' : hasActiveLicense === true }" @click.stop="setHasActiveLicense( true )">Has Active</button>
			</div>

			<div class="buttons-list">
				<button :disabled="hasLicense === false" :class="{ 'active' : isStudent === false }" @click.stop="isStudent = isStudent === false ? null : false">Not Student</button>
				<button :disabled="hasLicense === false" :class="{ 'active' : isStudent === true }" @click.stop="isStudent = isStudent === true ? null : true">Is Student</button>
			</div>
			<div>
				<div>Role:</div>
				<select v-model='selectedRoleID'>
					<option :value="null">All Roles</option>
					<option v-for='role in roles' :key='role.id' :value='role.id'>{{ role.name }}</option>
				</select>
			</div>
			<div>
				<div>
					<select v-model="recentLogin">
						<option :value="true">Has</option>
						<option :value="false">Has not</option>
					</select>
					Logged In Within:
				</div>
				<select v-model='recentSessionStart'>
					<option :value="null">Any</option>
					<option :value="3600">1 Hour</option>
					<option :value="21600">6 Hours</option>
					<option :value="43200">12 Hours</option>
					<option disabled>------------</option>
					<option :value="86400">1 Day</option>
					<option :value="259200">3 Days</option>
					<option :value="432000">5 Days</option>
					<option disabled>------------</option>
					<option :value="604800">1 Week</option>
					<option :value="1209600">2 Weeks</option>
					<option :value="1814400">3 Weeks</option>
					<option :value="2419200">4 Weeks</option>
					<option disabled>------------</option>
					<option :value="2592000">1 Month</option>
					<option :value="5184000">2 Months</option>
					<option :value="7776000">3 Months</option>
					<option :value="15811200">6 Months</option>
					<option :value="23673600">9 Months</option>
					<option disabled>------------</option>
					<option :value="31536000">1 Year</option>
					<option :value="63072000">2 Years</option>
					<option :value="94608000">3 Years</option>
				</select>
			</div>
		</div>

		<div v-if="recipientType == 'users'" class="flex-row flex-align-stretch flex-justify-center flex-gap-1 mt-1">
			<UserSearchDropdown :externalGetter="getMatchingUsers" @updateUser="u => selectUser( u )" @matches="(userArray, searchTerm) => updateMatchingRecipientCount( searchTerm )" :placeholder="`Search Matching Users (${ matchingRecipientCount.toLocaleString() })`" class="font-size-1-2" ref="userSearch" />
			<button class="pillButton" style="min-width: 15em;" @click="addMatchingUsers()">Add All Matching ({{ matchingRecipientCount.toLocaleString() }})</button>
		</div>
		<div v-if="recipientType == 'contacts'" class="flex-row flex-align-stretch flex-justify-center flex-gap-1 mt-1">
			<ContactSearchDropdown :externalGetter="getMatchingContacts" @update="x => selectContact( x )" @matches="(pocArray, searchTerm) => updateMatchingRecipientCount( searchTerm )" :placeholder="`Search Matching Contacts (${ matchingRecipientCount.toLocaleString() })`" class="font-size-1-2" ref="contactSearch" />
			<button class="pillButton" style="min-width: 15em;" @click="addMatchingContacts()">Add All Matching ({{ matchingRecipientCount.toLocaleString() }})</button>
		</div>


		<div class="icon-arrow-down2 center mt-1 font-size-2" style="cursor: inherit;" />


		<!-- List of Selected Recipients -->
		<div class='NotificationInputLabel mt-2 flex-row flex-align-center flex-justify-between'>
			<div>Send Message To <b>{{ totalRecipientCount }}</b> recipients:</div>
			<div v-if="validateRecipientsBusy">verifying recipients...</div>
			<div v-else-if="totalRecipientCount && invalidRecipientCount" class="bold color-red">{{ invalidRecipientCount }} invalid recipient{{ invalidRecipientCount == 1 ? '' : 's' }}</div>
			<div v-else-if="getMergeTagsError" class="bold color-red">Error downloading Merge Tags</div>
			<div v-else-if="getMergeTagsBusy"><span class="icon-spinner4 spin1" /> downloading Mail Merge tags...</div>
			<div class="flex-row flex-gap-1">
				<button class="pillButton secondary small" @click="$refs.selectListModal.open()"><span class="icon-plus" /> send to a list</button>
				<button class="pillButton secondary small" @click="recipients = []">remove all</button>
			</div>
		</div>
		<div class='toField border round-05 flex-row'>
			<TagField
				v-if="recipients.length"
				:Source='recipients'
				:DisplayPath='"stringValue"'
				:displayFunction="function( item ) { return item.stringValue ? item.stringValue : item.email }"
				:styleFunction="recipientTagStyles"
				:clickableFunction="recipientTagClickable"
				@click="item => showTagDetails( item )"
				@remove='removeRecipient'
				style="margin: 0; max-height: 15em; overflow: auto;"
				ref="recipientTagField"
			/>
			<div class="border round-05 flex-row flex-align-center ml-05 flex-grow" :class="{ 'bg-red-95' : toEmailAddressError }">
				<input type="text" class="flex-grow" style="border:none; outline:none;" v-model="toEmailAddress" @keyup.enter="addToEmailAddress()" placeholder="Add a raw e-mail address" />
				<div v-if="toEmailAddress" id="addRecipientButton" class="flex-row flex-align-center" :class="{ 'error' : toEmailAddressError }" @click.stop="addToEmailAddress()"><span class="icon-plus px-05" /></div>
			</div>
		</div>
		<div class="flex-row flex-justify-end"><button v-if="nonListRecipientCount" class="pillButton secondary small" @click="addRecipientsToList()">add {{ nonListRecipientCount }} recipients to list</button></div>


	</section>


	<!-- Delivery Methods -->
	<div v-if="(recipients.length || templateIn) && !sending && !sent" class="center mt-4">
		<div class="numberedTitle flex-row flex-align-center flex-justify-center flex-gap-05"><span class="number">2</span>Choose Delivery Methods</div>
		<div class="flex-row flex-align-start flex-justify-center flex-gap-2 mb-1">
			<div class="flex-row flex-align-center flex-gap-05"><ToggleButton v-model="deliverEmail" /><span class="icon-mail3" /> E-mail</div>
			<div class="flex-row flex-align-center flex-gap-05"><ToggleButton v-model="deliverSMS" /><span class="icon-mobile" /> SMS</div>
			<div v-if="hasRecipientUsers">
				<div class="flex-row flex-align-center flex-gap-05"><ToggleButton v-model="deliverApp" :disabled="!selectedCourse" /><div><span class="icon-warning" /> in-app notification<div v-if="!selectedCourse">(Select a course above)</div></div></div>
				<div v-if="selectedCourse && deliverApp" class="flex-row flex-align-center flex-gap-05 mt-05"><ToggleButton v-model="autoPopApp" /><span class="icon-enlarge" /> Pop-up notification</div>
			</div>
		</div>
		<div v-if="hasRecipientUsers" class="flex-row flex-align-center flex-justify-center flex-gap-05"><ToggleButton v-model="respectPreferences" />Respect student delivery method preferences</div>
	</div>



	<!-- Compose Message -->
	<section v-if="(hasDeliveryMethod && recipients.length) || templateIn" class="mt-4">
		<div class="numberedTitle flex-row flex-align-center flex-justify-center flex-gap-05"><span class="number">3</span>Compose Message</div>

		<div class="flex-row flex-justify-center buttons-list">
			<button :class="{ 'active' : messageRole === 'transactional' }" @click.stop="messageRole = 'transactional'">Transactional</button>
			<button :class="{ 'active' : messageRole === 'marketing' }" @click.stop="messageRole = 'marketing'">Marketing / newsletter</button>
		</div>

		<div class="flex-row flex-align-start flex-justify-center flex-gap-2">

			<!-- E-Mail -->
			<div v-if="deliverEmail" class="flex-grow">
				<div v-if="template" class="mt-1 mb-3 bold">
					<div>Template: <input type="text" v-model="template.name" placeholder="Name" /></div>
					<div><textarea class="w-100 border round-05 pa-05" v-model="template.description" placeholder="Template Description" /></div>
				</div>
				<div v-if="!editFrom" class="my-05"><label class="NotificationInputLabel">From: {{ fromEmailUsername || "PowerPrep 10" }} &lt;{{ fromEmailBox || 'accounts' }}@powerprep10.eknowledge.com&gt;</label> <span class="icon-pencil ml-05" @click="editFrom = true" /></div>
				<div v-else class="my-05">
					<label class="NotificationInputLabel">From: </label>
					<input type="text" v-model="fromEmailUsername" placeholder="Name" />
					<!-- <label class="NotificationInputLabel"> </label> -->
					&lt;<input type="text" v-model="fromEmailBox" placeholder="mailbox" @keyup.enter="editFrom = false" />@powerprep10.eknowledge.com&gt;
					<button class="pillButton" @click="editFrom = false">done</button>
				</div>
				<div :style="{ borderLeft: from === null ? '3px solid #ccc' : 'none', paddingLeft: from === null ? '0.5em' : '0' }">
					<div class="mt-05">
						<label class="NotificationInputLabel">Reply to:</label>&nbsp;
						<select v-model="from">
							<option :value="false">None</option>
							<option :value="true">eKnowledge &lt;support@eknowledge.com&gt;</option>
							<option :value="currentUser">Me - {{ currentUser.firstName }} {{ currentUser.lastName }} &lt;{{ currentUser.emailAddress }}&gt;</option>
							<option :value="null">other...</option>
						</select>
					</div>
					<div v-if="from === null">
						<div class="NotificationInput">
							<label class="NotificationInputLabel">Reply to Name:</label>
							<input class="NotificationInputField" v-model="fromName" />
						</div>
						<div class="NotificationInput">
							<label class="NotificationInputLabel">Reply to E-Mail:</label>
							<input class="NotificationInputField" v-model="fromEmail" />
						</div>
					</div>
				</div>
				<div class='NotificationInput my-1'>
					<label class='NotificationInputLabel'>Subject:</label>
					<input type='text' v-model='message.subject' class='NotificationInputField ml-05' />
				</div>

				<div class="flex-row flex-align-center flex-justify-between flex-gap-3">
					<div class="Tab" style="background: none; margin-bottom: 0px;">
						<button :class="{'active' : showBodyMode == 'tiptap'}" @click.stop="showBodyMode = 'tiptap'">Preview</button>
						<button :class="{'active' : showBodyMode == 'plain'}" @click.stop="showBodyMode = 'plain'">HTML Code</button>
					</div>
					<div v-if="draftSaveError" class="color-red-50 one-line">error saving draft!</div>
					<div v-else-if="savingDraft" class="color-gray-60 one-line">saving draft...</div>
				</div>

				<Tiptap 
					v-show="showBodyMode === 'tiptap'"
					class="flex-grow"
					:content="message.htmlBody"
					:saveDelayMS="500"
					:saved="tiptapSaved"
					:allowedShortcodes="mergeTags"
					:loadingShortcodes="getMergeTagsBusy"
					:shortcodesError="getMergeTagsError"
					:allowNodeLinkShortcode="false"
					:hostID="draft ? draft.messageBlastID : null"
					hostType="email"
					@update="tiptapSaved = false"
					@sendContentAsHTML="html => updateHTMLContent(html)"
					ref="tiptap"
				/>
				<textarea
					v-show="showBodyMode == 'plain'"
					v-model="message.htmlBody"
					class="border round-1 pa-1"
					style="width: 100%; min-height: 15em"
				/>
			</div>

			<!-- Plain Text Box -->
			<div v-if="deliverSMS || deliverApp" class="flex-row flex-align-center flex-gap-2">

				<div v-if="deliverEmail && editPlain" id="copyBodyButton" class="pa-05" @click="copyRichToPlain( message.htmlBody )" @mouseenter="t.s($event)" @mouseleave="t.h($event)" tooltip="Update SMS / App to match"><span class="icon-arrow-right2" /></div>

				<div class="pa-1" style="min-width: min( 250px, 100vw ); position: relative;">
					
					<div class="mt-05 mb-1">
						<label class="NotificationInputLabel">From:</label>&nbsp;
						<select v-model="from" :disabled="deliverEmail">
							<option :value="false">None</option>
							<option v-if="deliverSMS" :value="true">eKnowledge &lt;support@eknowledge.com&gt;</option>
							<option :value="currentUser">{{ currentUser.firstName }} {{ currentUser.lastName }} &lt;{{ currentUser.emailAddress }}&gt;</option>
							<option v-if="deliverSMS" :value="null">other...</option>
						</select>
					</div>
					<div v-if="deliverSMS && from === null">
						<div class="NotificationInput">
							<label class="NotificationInputLabel">From Name:</label>
							<input class="NotificationInputField" v-model="fromName" :disabled="deliverEmail" />
						</div>
						<div class="NotificationInput">
							<label class="NotificationInputLabel">From E-Mail:</label>
							<input class="NotificationInputField" v-model="fromEmail" :disabled="deliverEmail" />
						</div>
					</div>

					<div v-if="!deliverEmail && deliverApp">
						<div class='NotificationInput my-1'>
							<label class='NotificationInputLabel'>Subject:</label>
							<input type='text' v-model='message.subject' class='NotificationInputField ml-05' />
						</div>
					</div>

					<textarea rows='20' v-model='message.plainBody' id='NotificationField' class='NotificationEntry border round-2 pa-1' :style="plainTextStyle" :placeholder="deliverSMS ? 'SMS Message' : 'In-app notification'" :disabled="disableEditPlain" />
					<p :class='{ "CharCount" : true, "CharCountWarning" : messLen > 500 }'>Character count: {{ messLen }}</p>
					<div v-if="disableEditPlain" class="flex-row flex-align-center flex-justify-center round-1" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba( 150,150,150, 0.5 );">
						<button class="pillButton" @click="editPlain = true">Edit Independently</button>
					</div>
				</div>

			</div>
		</div>

		<div v-if="!sending && !sent" class="mt-2 flex-row flex-align-center flex-justify-between flex-gap-2">
			<div>
				<button class="pillButton secondary blue" @click="$refs.loadTemplateModal.open()">Load Template</button>
			</div>
			<div v-if="!sending && !sent" class="flex-row flex-align-center flex-justify-end flex-gap-05">
				<button v-if="template" class="pillButton secondary blue" @click="replaceTemplate()"><span class="icon-floppy-disk" /> save as new...</button>
				<button class="pillButton secondary blue" @click="createOrUpdateTemplate()">
					<span class="icon-floppy-disk" />
					{{ template && template.id ? 'update template' : 'save as template' }}
					<span class="saveIcon icon-checkmark color-green" :class="{ 'show' : templateSaved }" />
					<span class="saveIcon icon-cross color-red" :class="{ 'show' : templateSaveError }" />
				</button>
				<div class="ml-2">
					<button class='pillButton large' :disabled="!hasBody || preflightLoading" :class="{ 'disabled' : !hasBody || preflightLoading }" @click='preflight()'>
						Preview Message{{ totalRecipientCount != 1 ? 's' : '' }}
						<span v-if="preflightLoading" class="icon-spinner4 spin1 ml-05" />
					</button>
				</div>
			</div>
		</div>

		<div v-if="sending || sent" style="position: relative; min-height: 3rem; margin-top: 2em; margin-bottom: 1em;">
			<div id="sending" class="flex-row flex-align-center flex-justify-center flex-gap-025" :class="{ 'show' : sending }"><span class="icon-spinner4 spin-loader" />SENDING...</div>
			<div id="sent" :class="{ 'show' : sent }">Message Sent!</div>
		</div>

		<div v-if="sending || sent">
			<div style="text-align: right;">sent {{ sendingUnitsComplete }} of {{ sendingUnitsTotal }}</div>
			<ProgressBar :showBusy="sending && sendingPercent !== null && sendingPercent < 1" :pct="sendingPercent" :label="Math.round( sendingPercent * 100 ) + '%'" />
		</div>

		<div v-if="sendingReport" id="sendReport">
			<div v-for="row in sendingReport" :key="row"><code>{{ row }}</code></div>
		</div>
		<div v-if="sendingErrors" id="errorReport">
			<div class="mb-1">Errors:</div>
			<div v-for="row in sendingErrors" :key="row"><code>{{ row }}</code></div>
		</div>

		<div v-if="sent && sendingErrors" class="flex-row flex-justify-end mt-1"><button class="pillButton secondary" @click="resetSend()">try again</button></div>
	</section>



	
	<StretchModal ref="preflightModal" padding="1em" maxHeight="85vh" width="auto" :blur="true" :fullscreen="false">
		<template #header>Preflight Report</template>
		<div class="flex-row flex-align-center flex-justify-center flex-gap-05 ma-1"><ToggleButton v-model="showPreflightTags" :sync="true" /> Show Mail Merge Values</div>
		<div v-if="preflightReport" style="max-height: calc( 85vh - 13em ); overflow: auto; position: relative;">
			<table>
				<thead style="position: sticky; top: 0; background: white;">
					<tr>
						<th class="name pa-05">Name</th>
						<th class="pa-05">Email ({{ numDeliveryEmail }})</th>
						<th class="pa-05">SMS ({{ numDeliverySMS }})</th>
						<th class="pa-05">In-app ({{ numDeliveryApp }})</th>
						<th class="pa-05"></th>
						<th class="pa-05" :class="{ 'border-right' : showPreflightTags }"></th>
						<template v-if="showPreflightTags"><th v-for="tag in preflightReport.usedTags" :key="tag" class="px-1">{{ tag }}</th></template>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(row, idx) in preflightReport.rows" :key="row.userID" class="pointer" :class="{ 'warning' : row.error || row.unsubscribe }" @click.stop="getMergedPreview( idx )">
						<td class="name">{{ row.name ? row.name : row.email }}</td>
						<td class="center" :class="{ 'green' : willSendEmail( row ), 'red' : !willSendEmail( row ) }">{{ willSendEmail( row ) ? 'YES' : 'NO' }}</td>
						<td class="center" :class="{ 'green' : row.deliverSMS, 'red' : !row.deliverSMS }">{{ row.deliverSMS ? 'YES' : 'NO' }}</td>
						<td class="center" :class="{ 'green' : row.deliverApp, 'red' : !row.deliverApp }">{{ row.deliverApp ? 'YES' : 'NO' }}</td>
						<td class="center"><span class="link" @click.stop="getMergedPreview( idx )">preview</span></td>
						<td class="center" :class="{ 'border-right' : showPreflightTags }"><div v-if="row.error || row.unsubscribe" class="font-size-2 bold color-red" style="padding: 0.25em;">!</div></td>
						<template v-if="showPreflightTags"><td v-for="tag in preflightReport.usedTags" :key="tag" v-html="row.usedTags[ tag ]" class="center" :class="{ 'bg-red-80 round-05' : !row.usedTags[ tag ] }" /></template>
					</tr>
				</tbody>
			</table>
			
		</div>

		<div class="mt-2 flex-row flex-align-center flex-justify-between flex-gap-3">
			<div style="color: #606060;">Message will be sent to <b>{{ numDelivery }}</b> {{ numDelivery == 1 ? 'person' : 'people' }}</div>
			<div class="flex-row flex-align-center flex-gap-05">
				<button class="pillButton secondary blue" @click="$refs.preflightModal.close()">cancel</button>
				<button class='pillButton large' :disabled="!numDelivery" :class="{ 'disabled' : !numDelivery }" @click='confirmSend()'>Send Messages</button>
			</div>
		</div>
	</StretchModal>
	<AlertDialog ref="preflightErrorModal"><div class="warning">There was an error while preparing the pre-flight report. <br>Please remove any malformed or blank email addresses from your recipient list.</div></AlertDialog>


	<AlertDialog ref="tooManyRecipientsModal"><div class="center font-size-1-5 mb-05">Too many matching recipients!</div><div>Please narrow your search.</div><div>You can add up to {{ maxMatchingRecipientCount }} recipients at a time.</div></AlertDialog>
	<ConfirmDialog ref="confirmBlankSubjectModal" @cancel="preflightLoading = false" @confirm="preflight( false )" cancel="Oops - Go Back" confirm="Continue Anyway"><div>Your e-mail subject line is blank!</div></ConfirmDialog>
	<ConfirmDialog ref="confirmNoUnsubscribeModal" @cancel="preflightLoading = false" @confirm="preflight( true, false )" cancel="Oops - Go Back" confirm="Continue Anyway"><div>Your message is missing an &ldquo;unsubscribe&rdquo; link!</div></ConfirmDialog>
	<ConfirmDialog ref="confirmSendModal" @confirm="send()"><div>Are you sure you want to send this message to {{ numDelivery }} {{ numDelivery == 1 ? 'person' : 'people' }}?</div></ConfirmDialog>


	<StretchModal ref="mergedPreviewModal" padding="1em" width="60vw" minHeight="40vh">
		<div v-if="previewMessage" class="flex-column flex-align-center flex-gap-1" style="margin: 0 auto;">
			
			<div class="bold mb-1 font-size-1-5">
				<span class="icon-arrow-left2 pa-05 mr-05 link" @click.stop="previewPrevious()" />
				<span>To: {{ previewMessage.name || previewMessage.email }}<span v-if="previewMessage.userID" class="icon-user ml-05 color-blue" /></span>
				<span class="icon-arrow-right2 pa-05 ml-05 link" @click.stop="previewNext()" />
			</div>

			<div class="flex-row flex-align-start flex-justify-center flex-gap-3">
				<div v-if="previewMessage.deliverEmail" class="email border round-1">
					<div class="previewTitle">E-Mail: {{ previewMessage.email }}</div>
					<div class="bold mt-1 mx-1 border-bottom">Subject: {{ previewMessage.subject }}</div>
					<div class="mx-1 mb-1" v-html="previewMessage.htmlBody" />
				</div>
				
				<div v-if="previewMessage.deliverSMS || previewMessage.deliverApp" class="border round-1">
					<div class="previewTitle"><span v-if="previewMessage.deliverSMS">SMS: {{ previewMessage.phone }}</span><span v-if="previewMessage.deliverSMS && previewMessage.deliverApp"> &amp; </span><span v-if="previewMessage.deliverApp">In-App Notification</span>:</div>
					<div class="smsBubble mx-1 mb-1" v-html="previewMessage.plainBody.replace(/(?:\r\n|\r|\n)/g, '<br>')" />
				</div>
			</div>

			<div v-if="previewMessage.unsubscribe" class="warning">
				<b>WARNING:</b> This user has unsubscribed from <b>&ldquo;{{ previewMessage.unsubscribe.topic || 'all' }}&rdquo;</b> e-mails.<br>They unsubscribed via <b>{{ previewMessage.unsubscribe.source }}</b> on <b>{{ new Date( previewMessage.unsubscribe.time * 1000 ).toLocaleDateString() }}</b>
			</div>
			<div v-if="previewMessage.deliverEmail && !willSendEmail( previewMessage )" class="warning bold">E-MAIL WILL NOT BE SENT</div>
			<div v-if="!previewMessage.deliverEmail && !previewMessage.deliverSMS && !previewMessage.deliverApp" class="warning bold">NO MESSAGE WILL BE SENT</div>
			<div v-if="previewMessage.error" class="warning">{{ previewMessage.error }}</div>

		</div>
	</StretchModal>


	<ConfirmDialog padding="1em" maxWidth="300px" ref="createTemplateModal" @confirm="saveTemplate()" @cancel="template = oldTemplate">
		<div v-if="template" class="flex-column flex-align-start">
			<input type="text" v-model="template.name" class="w-100 bold font-size-1-3" placeholder="Template Name" />
			<div class="mt-1 w-100"><textarea v-model="template.description" class="w-100 border round-05" placeholder="Template Description (optional)" /></div>
		</div>
	</ConfirmDialog>


	<ConfirmDialog ref="loadTemplateModal" @confirm="loadTemplate( selectingTemplate )" @cancel="selectingTemplate = null">
		<div style="min-height: 300px;">
			<MessageTemplateSearchDropdown v-model="selectingTemplate" width="100%" />
		</div>
	</ConfirmDialog>


	<StretchModal padding="1em" maxWidth="600px" ref="recipientListModal">
		<template #header>Add To Blast List</template>
		<div v-if="isNewBlastList === null" class="flex-row flex-align-center flex-justify-center flex-gap-2">
			<button class="pillButton" @click="isNewBlastList = true">New List</button>
			<button class="pillButton" @click="isNewBlastList = false">Add to Existing List</button>
		</div>
		<div v-else-if="isNewBlastList === true">
			<div>List Name: <input type="text" v-model="newBlastListName" /></div>
			<div>Description:<br><textarea v-model="newBlastListDescription" /></div>
			<div class="right"><button class="pillButton" @click="createBlastList()">Create List + add {{ nonListRecipientCount }} recipients</button></div>
		</div>
		<div v-else-if="isNewBlastList === false" style="min-height: 400px;">
			<div><BlastListSearchDropdown v-model="selectedBlastList" /></div>
			<div class="right"><button class="pillButton" :class="{ 'disabled' : !selectedBlastList }" :disabled="!selectedBlastList" @click="addToExistingBlastList( selectedBlastList.id )">Add {{ nonListRecipientCount }} recipients</button></div>
		</div>
	</StretchModal>


	<ConfirmDialog ref="selectListModal" @confirm="addListToMessage( selectedBlastList )" @cancel="selectedBlastList = null">
		<div style="min-height: 300px;">
			<BlastListSearchDropdown v-model="selectedBlastList" width="100%" />
		</div>
	</ConfirmDialog>


	<StretchModal padding="1em" maxWidth="1200px" ref="listDetailsModal" @close="selectedBlastList = null">
		<BlastListDetails :list="selectedBlastList" />
	</StretchModal>


	<StretchModal padding="1em" maxWidth="600px" ref="pocDetailsModal">
		<div v-if="selectedRecipient">
			<div v-for="aff in selectedRecipient.affiliates" :key="aff.id"><b>Affiliate: {{ aff.name }}</b></div>
			<div v-for="cust in selectedRecipient.customers" :key="cust.id"><b>Customer: {{ cust.name }}</b></div>
		</div>
		<PocDetails :contact="selectedRecipient" :edit="true" />
	</StretchModal>


	<UserDetailsModal :user="selectedRecipient" ref="userDetailsModal" height="70%" width="70%" />



</div>
</template>



<script>
import LongJobAPI from '@/api/LongJobAPI.js'
import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import SalesManagementAPI from '@/api/SalesManagementAPI.js'
import UserAPI from "@/api/UserAPI.js"
import CourseAPI from '@/api/CourseAPI.js'
import Course from '@/models/Course.js'
import User from '@/features/Users/User.js'
import POC from '../../SalesManagement/POC/POC.js'
import PocEmail from '../../SalesManagement/POC/PocEmail.js'
import Message from './Message.js'
import MessageBlast from './MessageBlast.js'
import MessageTemplate from './MessageTemplate.js'
import BlastList from './BlastList.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'

import CourseSearchDropdown from '@/features/Courses/CourseSearchDropdown.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import GenericTagSearchDropdown from '@/components/utilities/GenericTagSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import CohortSearchDropdown from '@/features/Cohorts/CohortSearchDropdown.vue'
import ContactSearchDropdown from '@/features/SalesManagement/POC/ContactSearchDropdown.vue'
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import MessageTemplateSearchDropdown from './MessageTemplateSearchDropdown.vue'
import BlastListSearchDropdown from './BlastListSearchDropdown.vue'
import BlastListDetails from './BlastListDetails.vue'
import PocDetails from '@/features/SalesManagement/POC/PocDetails.vue'
import UserDetailsModal from '@/features/Users/UserDetailsModal.vue'
import TagField from '../../../components/utilities/TagField.vue'
import Tiptap from '@/features/Tiptap/Tiptap.vue'
import { ToggleButton } from 'vue-js-toggle-button'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import AlertDialog from '@/components/utilities/AlertDialog.vue'
import ProgressBar from '@/components/utilities/ProgressBar.vue'

export default {
	name: 'ComposeNotification',


	components: {
		CourseSearchDropdown,
		AffiliateSearchDropdown,
		GenericTagSearchDropdown,
		CustomerSearchDropdown,
		CohortSearchDropdown,
		ContactSearchDropdown,
		UserSearchDropdown,
		PocDetails,
		UserDetailsModal,
		MessageTemplateSearchDropdown,
		BlastListSearchDropdown,
		BlastListDetails,
		TagField,
		Tiptap,
		ToggleButton,
		StretchModal,
		ConfirmDialog,
		AlertDialog,
		ProgressBar,
	},


	
	props: {

		// Array of POC or User objects
		recipientsIn: {
			type: Array,
			default: null
		},

		draftIn: {
			type: MessageBlast,
			default: null
		},

		templateIn: {
			type: MessageTemplate,
			default: null
		},

		listIn: {
			type: BlastList,
			default: null
		},
	},



	data() {
		return {
			from: null,
			fromName: null,
			fromEmail: null,
			toEmailAddress: null,
			toEmailAddressError: false,
			fromNameTimeoutID: null,
			fromEmailTimeoutID: null,
			toEmailAddressTimeoutID: null,

			recipientSearchTerm: null,
			matchingRecipientCount: 0,
			maxMatchingRecipientCount: 1000,

			recipientType: 'contacts',
			recipients: [],
			validateRecipientsBusy: false,
			selectedRecipient: null,    // for showing details of a single recipient (i.e. click on recipient in tag field)
			invalidRecipientCount: 0,
			recipTimeoutID: null,

			message: null,
			messageTimeoutID: null,

			draft: null,    // MessageDraft - used for saving the working draft
			savingDraft: false,
			draftSaveRetries: 0,
			draftSaveError: false,
			
			mergeTags: {},
			getMergeTagsBusy: false,
			getMergeTagsError: false,
			tiptapSaved: true,
			editPlain: false,
			checkSubject: true,
			checkUnsubscribe: true,

			selectedCourse: null,
			selectedAffiliateTag: null,
			selectedAffiliates: [],
			selectedCustomers: [],
			selectedCohort: null,

			hasLicense: null,
			hasActiveLicense: null,
			hasOnlyExpiredLicenses: false,
			isStudent: null,
			selectedRoleID: null,
			recentLogin: true,
			recentSessionStart: null,

			editFrom: false,
			fromEmailUsername: null,
			fromEmailBox: null,
			fromEmailUsernameTimeoutID: null,
			fromEmailMailboxTimeoutID: null,

			showBodyMode: 'tiptap',   // or 'plain'

			deliverEmail: false,
			deliverSMS: false,
			deliverApp: false,
			autoPopApp: false,
			respectPreferences: false,
			respectUnsubscribe: true,
			messageRole: 'transactional',

			preflightLoading: false,
			preflightReport: null,
			showPreflightTags: false,
			previewMessage: null,
			previewRowIndex: 0,

			sending: false,
			sent: false,
			sendingErrors: null,
			sendingReport: null,
			sendingPollIntervalID: null,
			sendingUnitsComplete: 0,
			sendingUnitsTotal: 0,
			sendingPercent: null,

			oldTemplate: null,
			template: null,
			selectingTemplate: null,  // Template selected in the dropdown, but not yet applied
			templateSaved: false,
			templateSaveError: false,

			isNewBlastList: null,
			newBlastListName: null,
			newBlastListDescription: null,
			selectedBlastList: null,

		}
	},


	created() {
		this.message = new Message()
		
		this.loadDraft()
		this.importRecipientsIn()
		this.importListIn()

		if( !this.draftIn ) this.from = this.currentUser

		this.loadTemplate()
		this.updateMatchingRecipientCount()
	},



	computed: {

		t() { return Tooltips },

		currentUser() { return this.$store.state.user || null },
		roles() { return this.$store.state.Roles.objects; },


		// hasBody(): 1) must have a body (rich || plain).  2) if sending SMS or APP, then MUST have plainBody
		hasBody() { return this.message && ( this.message.htmlBody || this.message.plainBody ) && ( this.message.plainBody || ( !this.deliverSMS && !this.deliverApp ) ) },

		messLen() {
			return this.message && this.message.plainBody ? this.message.plainBody.length : 0
		},

		selectedCourseID() { return this.selectedCourse ? this.selectedCourse.courseID : null },
		selectedAffiliateIDs() { return this.selectedAffiliates ? this.selectedAffiliates.map( elem => elem.id ) : [] },
		selectedCustomerIDs() { return this.selectedCustomers ? this.selectedCustomers.map( elem => elem.id ) : [] },
		selectedCohortID() { return this.selectedCohort ? this.selectedCohort.cohortID : null },
		hasDeliveryMethod() { return this.deliverEmail || this.deliverSMS || this.deliverApp },

		disableEditPlain() { return this.deliverEmail && !this.editPlain },
		plainTextStyle() { return this.disableEditPlain ? { backgroundColor: '#eee' } : { backgroundColor: 'white' } },

		numDeliveryEmail() {
			if( !this.preflightReport ) return 0
			return this.preflightReport.rows.reduce( (acc, item) => {
				return this.willSendEmail( item ) ? acc + 1 : acc
			}, 0)
		},

		numDeliverySMS() {
			if( !this.preflightReport ) return 0
			return this.preflightReport.rows.reduce( (acc, item) => {
				return item.deliverSMS ? acc + 1 : acc
			}, 0)
		},
		
		numDeliveryApp() {
			if( !this.preflightReport ) return 0
			return this.preflightReport.rows.reduce( (acc, item) => {
				return item.deliverApp ? acc + 1 : acc
			}, 0)
		},

		numDelivery() {
			if( !this.preflightReport ) return 0
			return this.preflightReport.rows.reduce( (acc, item) => {
				return this.willSendEmail( item ) || item.deliverSMS || item.deliverApp ? acc + 1 : acc
			}, 0)
		},

		// Count the recipients which are NOT BlastLists
		nonListRecipientCount() {
			this.recipients.length;
			return this.recipients.filter( elem => !(elem instanceof BlastList) ).length
		},

		totalRecipientCount() {
			var count = this.nonListRecipientCount
			for( var r of this.recipients ) {
				if( !( r instanceof BlastList ) ) continue
				count += r.numContacts
			}

			return count
		},

		hasRecipientUsers() {
			for( var r of this.recipients ) {
				if( r instanceof User ) return true
				if( r instanceof BlastList && r.numUsers > 0 ) return true
			}
			return false
		},

	},



	watch: {
		recipients() {
			clearTimeout( this.recipTimeoutID )
			this.recipTimeoutID = setTimeout( () => {
				this.validateRecipients()
				this.getAvailableMergeTags()
				this.saveMessageDraft( 'recipients', this.prepareRecipients() )
			}, 1000)
		},
		recipientType() { this.updateMatchingRecipientCount() },
		selectedCourseID() {
			if( this.selectedCourseID ) this.hasLicense = true
			this.updateMatchingRecipientCount()
			this.saveMessageDraft( 'courseID', this.selectedCourseID )
		},
		selectedAffiliateTag() { this.updateMatchingRecipientCount() },
		selectedAffiliateIDs() { this.updateMatchingRecipientCount() },
		selectedCustomerIDs() { this.updateMatchingRecipientCount() },
		selectedCohortID() { this.updateMatchingRecipientCount() },
		hasLicense() {
			if( this.hasLicense === false ) {
				this.hasActiveLicense = null;
				this.isStudent = null;
				this.courseID = null;
			}
			this.updateMatchingRecipientCount()
		},

		hasActiveLicense() {
			if( this.hasActiveLicense !== null ) this.hasLicense = true
			this.updateMatchingRecipientCount()
		},
		hasOnlyExpiredLicenses() { this.updateMatchingRecipientCount() },

		isStudent() {
			if( this.isStudent !== null ) this.hasLicense = true
			this.updateMatchingRecipientCount()
		},

		selectedRoleID() { this.updateMatchingRecipientCount() },
		recentLogin() { this.updateMatchingRecipientCount() },
		recentSessionStart() { this.updateMatchingRecipientCount() },

		toEmailAddress( value ) {
			if( this.toEmailAddressTimeoutID ) clearTimeout( this.toEmailAddressTimeoutID )
			this.toEmailAddressTimeoutID = setTimeout( () => this.validateEmailAddress( value ), 500 )
		},

		draftIn() { this.loadDraft() },
		templateIn() { this.loadTemplate() },
		showBodyMode( value ) { if( value == 'tiptap' ) this.$refs.tiptap.reload() },

		// Auto-save message draft
		deliverEmail( value ) { this.saveMessageDraft( 'deliverEmail', value ) },
		deliverSMS( value ) { this.saveMessageDraft( 'deliverSMS', value ) },
		deliverApp( value ) { this.saveMessageDraft( 'deliverApp', value ) },
		autoPopApp( value ) { this.saveMessageDraft( 'autoPopApp', value ) },
		respectPreferences( value ) { this.saveMessageDraft( 'overrideDeliveryPreferences', !value ) },
		'message.subject'() {
			clearTimeout( this.messageTimeoutID )
			this.messageTimeoutID = setTimeout( () => {
				this.saveMessageDraft( 'message', this.message )
			}, 1500)
		},
		'message.htmlBody'() {
			clearTimeout( this.messageTimeoutID )
			this.messageTimeoutID = setTimeout( () => {
				this.saveMessageDraft( 'message', this.message )
			}, 1500)
		},
		'message.plainBody'() {
			clearTimeout( this.messageTimeoutID )
			this.messageTimeoutID = setTimeout( () => {
				this.saveMessageDraft( 'message', this.message )
			}, 1500)
		},
		from() {
			if( this.from === this.currentUser ) {
				this.saveMessageDraft( 'fromID', this.currentUser.userID )
				this.saveMessageDraft( 'fromName', null )
				this.saveMessageDraft( 'fromEmail', null )

			} else if ( this.from === true ) {
				this.saveMessageDraft( 'fromID', null )
				this.saveMessageDraft( 'fromName', "eKnowledge Support" )
				this.saveMessageDraft( 'fromEmail', "support@eknowledge.com" )
			
			} else if( this.from === null ) {
				this.saveMessageDraft( 'fromID', null )
				this.saveMessageDraft( 'fromName', this.fromName )
				this.saveMessageDraft( 'fromEmail', this.fromEmail )

			} else {
				this.saveMessageDraft( 'fromID', null )
				this.saveMessageDraft( 'fromName', null )
				this.saveMessageDraft( 'fromEmail', null )
			}
		},
		fromName( value ) {
			clearTimeout( this.fromNameTimeoutID )
			this.fromNameTimeoutID = setTimeout( () => {
				this.saveMessageDraft( 'fromName', value ? value : null )
			}, 500)
		},
		fromEmail( value ) {
			clearTimeout( this.fromEmailTimeoutID )
			this.fromEmailTimeoutID = setTimeout( () => {
				this.saveMessageDraft( 'fromEmail', value ? value : null )
			}, 500)
		},
		fromEmailUsername( value ) {
			clearTimeout( this.fromEmailUsernameTimeoutID )
			this.fromEmailUsernameTimeoutID = setTimeout( () => {
				this.saveMessageDraft( 'fromEmailUsername', this.deliverEmail && value ? value : null )
			}, 500)
		},
		fromEmailBox( value ) {
			clearTimeout( this.fromEmailMailboxTimeoutID )
			this.fromEmailMailboxTimeoutID = setTimeout( () => {
				this.saveMessageDraft( 'fromEmailMailbox', this.deliverEmail && value ? value : null )
			}, 500)
		},
	}, 



	methods: {


		importRecipientsIn() {
			if( !this.recipientsIn || !this.recipientsIn.length ) return

			if( this.recipientsIn[0] instanceof POC ) this.recipientType = 'contacts'
			else if( this.recipientsIn[0] instanceof User ) this.recipientType = 'users'

			for( var recip of this.recipientsIn ) {
				if( recip instanceof POC ) this.selectContact( recip, true )
				else if( recip instanceof User ) this.selectUser( recip, true )
			}
		},


		importListIn() {
			if( !this.listIn ) return
			this.addRecipient( this.listIn )
		},


		setHasActiveLicense( hasActive = null, hasOnlyExpired = false ) {
			if( this.hasActiveLicense === hasActive && this.hasOnlyExpiredLicenses === hasOnlyExpired ) {
				this.hasActiveLicense = null
				this.hasOnlyExpiredLicenses = false
				return
			}

			this.hasActiveLicense = hasActive
			this.hasOnlyExpiredLicenses = hasOnlyExpired
		},


		willSendEmail( item ) {
			if( !item.deliverEmail ) return false
			if( item.unsubscribe && ( !item.unsubscribe.topic || item.unsubscribe.topic === 'messagecenter' ) ) return false
			return true
		},


		validateEmailAddress( email ) {
			if( email == '' || email == null ) {
				this.toEmailAddressError = false
				return
			}

			const matches = String( email ).toLowerCase().match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ )
			if( matches ) this.toEmailAddressError = false
			else this.toEmailAddressError = true
			
			return !this.toEmailAddressError
		},


		addToEmailAddress() {
			this.toEmailAddressError = false
			
			
			const valid = this.validateEmailAddress( this.toEmailAddress )
			if( !valid ) return

			this.addRecipient( this.toEmailAddress )
			this.toEmailAddress = null
		},


		selectContact( poc, importMode = false ) {
			if( !poc ) {
				this.updateMatchingRecipientCount()
				return
			}
			this.addRecipient( poc )

			if( !importMode ) {
				this.$refs.contactSearch.clear()
				this.$refs.contactSearch.focus()
			}
		},


		selectUser( user, importMode = false ) {
			if( !user ) {
				this.updateMatchingRecipientCount()
				return
			}
			this.addRecipient( user )

			if( !importMode ) {
				this.$refs.userSearch.clear()
				this.$refs.userSearch.focus()
			}
		},


		async getAvailableMergeTags() {

			try {
				this.getMergeTagsBusy = true
				this.getMergeTagsError = false

				const req = {
					messageBlast: {
						recipients: this.prepareRecipients(),
						message: this.message.export(),
						deliverEmail: this.deliverEmail,
						deliverSMS: this.deliverSMS,
						deliverApp: this.deliverApp,
						autoPopApp: this.autoPopApp,
						overrideDeliveryPreferences: !this.respectPreferences,
						useMailMerge: true,
					}
				}
	
				if( this.selectedCourse ) req.courseID = this.selectedCourse.courseID
	
				const res = await MessageCenterAPI.getAvailableMergeTags( req )
				this.mergeTags = Array.isArray( res.data ) ? {} : res.data

			} catch( e ) {
				this.getMergeTagsError = true
				console.error( e.message )

			} finally {
				this.getMergeTagsBusy = false
			}
		},


		async validateRecipients() {
			if( this.recipients.length == 0 ) return

			try {
				this.validateRecipientsBusy = true
				
				const res = await MessageCenterAPI.validateRecipients( this.prepareRecipients() )
	
				this.invalidRecipientCount = 0
				for( var idx in res.data ) {
					this.recipients[ idx ].valid = res.data[idx]
					if( !res.data[idx] ) this.invalidRecipientCount++
				}
	
				this.$refs.recipientTagField.$forceUpdate()

			} finally {
				this.validateRecipientsBusy = false
			}

		},


		
		setMatchingAffiliates( affiliates ) {
			this.selectedAffiliates = affiliates
		},



		updateRecipientSearchTerm( searchTerm ) {
			if( this.recipientSearchTerm == searchTerm ) return
			this.recipientSearchTerm = searchTerm

			if( !searchTerm ) {
				if( this.$refs.contactSearch ) this.$refs.contactSearch.clear()
				if( this.$refs.userSearch ) this.$refs.userSearch.clear()
			}
		},
		


		async updateMatchingRecipientCount( searchTerm = null ) {
			this.updateRecipientSearchTerm( searchTerm )
			this.matchingRecipientCount = 0

			var data = null
			if( this.recipientType === 'users' ) {
				data = await this.getMatchingUsers( searchTerm )
				if( !data ) return

			} else {
				data = await this.getMatchingContacts( searchTerm )
				if( !data ) return
			}

			this.matchingRecipientCount = data.count

		},


		async addMatchingContacts() {
			if( this.matchingRecipientCount == 0 ) return
			if( this.matchingRecipientCount > this.maxMatchingRecipientCount ) {
				this.$refs.tooManyRecipientsModal.open()
				return
			}

			var page = 1
			while( 1 ) {
				const pr = await this.getMatchingContacts( this.recipientSearchTerm, page, 100 )
				if( !pr ) return
				if( !pr.data || !pr.data.length ) return

				for( let item of pr.data ) {
					this.addRecipient( POC.import( item ) )
				}

				page++
			}
		},

		
		async addMatchingUsers() {
			if( this.matchingRecipientCount == 0 ) return
			if( this.matchingRecipientCount > this.maxMatchingRecipientCount ) {
				this.$refs.tooManyRecipientsModal.open()
				return
			}

			var page = 1
			while( 1 ) {
				const pr = await this.getMatchingUsers( this.recipientSearchTerm, page, 100 )
				if( !pr ) return
				if( !pr.data || !pr.data.length ) return

				for( let item of pr.data ) {
					this.addRecipient( User.import( item ) )
				}

				page++
			}
		},


		async getMatchingContacts( searchTerm = null, page = 1, pageSize = 1 ) {

			const req = new PaginatedRequest( 'firstName', true, page, pageSize )
			req.searchString = searchTerm ? searchTerm : null
			if( this.selectedAffiliateTag ) req.affiliateTags = [ this.selectedAffiliateTag ]
			if( this.selectedAffiliateIDs.length ) req.affiliateIDs = this.selectedAffiliateIDs
			if( this.selectedCustomerIDs.length ) req.customerIDs = this.selectedCustomerIDs
			req.includeEmails = true
			req.includePhones = true
			req.includeAffiliates = true
			req.includeCustomers = true

			const pr = await SalesManagementAPI.getContacts( req )
			return pr

		},


		async getMatchingUsers( searchTerm = null, page = 1, pageSize = 1 ) {
			
			const req = new PaginatedRequest( 'firstName', true, page, pageSize )
			req.searchString = searchTerm ? searchTerm : null

			req.forCourse = this.selectedCourseID
			if( this.selectedAffiliateTag ) req.affiliateTags = [ this.selectedAffiliateTag ]
			if( this.selectedAffiliateIDs.length ) req.affiliateIDs = this.selectedAffiliateIDs
			if( this.selectedCustomerIDs.length ) req.customerIDs = this.selectedCustomerIDs
			if( this.selectedCohortID ) req.cohortIDs = [ this.selectedCohortID ]
			req.sessionIsRecent = this.recentLogin
			req.recentSessionStart = this.recentSessionStart


			if( this.selectedCourseID ) req.hasLicense = true
			
			if( this.hasLicense === true ) {
				req.hasLicense = true

			} else if( this.hasLicense === false ) {
				req.hasLicense = false
			}


			if( this.hasLicense !== false ) {

				req.hasOnlyExpiredLicenses = this.hasOnlyExpiredLicenses
				
				if( this.hasActiveLicense === true ) {
					req.hasLicense = true
					req.licenseIsActive = true
	
				} else if( this.hasActiveLicense === false ) {
					req.hasLicense = true
					req.licenseIsActive = false
				}

				if( this.isStudent === true ) {
					req.hasLicense = true
					req.isPrimary = true
	
				} else if( this.isStudent === false ) {
					req.hasLicense = true
					req.isPrimary = false
				}

			}


			if( this.selectedRoleID ) {
				req.hasRole = true
				req.roleID = this.selectedRoleID
			}
			
			const pr = await UserAPI.getUsers( req )
			return pr
		},



		addRecipient( newItem ) {

			for( let existingRecipient of this.recipients ) {
				
				// Null or exact object match
				if( !newItem || newItem === existingRecipient ) return

				// User objects
				else if( newItem instanceof User ) {
					if( newItem.userID === existingRecipient.userID ) return
					if( !newItem.primaryContact ) return        // No contact information
				}

				// POC objects
				else if( newItem instanceof POC ) {
					if( newItem.id === existingRecipient.id ) return
					if( !newItem.primaryContact ) return        // No contact information
				}

				// BlastLists
				else if( newItem instanceof BlastList ) {
					if( existingRecipient instanceof BlastList && newItem.id === existingRecipient.id ) return
				}

			}

			// Object-ify "raw" email address
			if( typeof newItem === 'string' || newItem instanceof String ) newItem = { email: newItem }

			// Add recipient
			this.recipients.push( newItem )
		},


		removeRecipient( recipient ) {
			this.recipients = this.recipients.filter(r => r !== recipient);
		},



		updateHTMLContent( html ) {
			this.message.htmlBody = html
			this.tiptapSaved = true

			if( !this.editPlain ) {
				this.copyRichToPlain( html )
			}
		},



		copyRichToPlain( html ) {
			html = html.replaceAll('</p>', '</p>\n')
			html = html.replaceAll('<br>', '\n')
			html = html.replaceAll('<br />', '\n')
			this.message.plainBody = html.replace(/<[^>]+>/g, '');
		},



		prepareRecipients() {
			const recipients = []

			for( var item of this.recipients ) {
				if( item instanceof User ) recipients.push( { name: `${ item.firstName } ${ item.lastName }`, address: item.emailAddress, userID: item.userID } )
				else if( item instanceof POC ) recipients.push( { name: `${ item.firstName } ${ item.lastName }`, address: item.emailAddress, pocID: item.id } )
				else if( item instanceof BlastList ) recipients.push( { name: item.name, listID: item.id })
				else recipients.push( { address: item.email } ) // "string" value (it's an object so the email validator can add .valid)
			}

			return recipients
		},



		prepareBlastListRows() {
			const rows = []

			for( var item of this.recipients ) {
				if( item instanceof User ) rows.push( { name: `${ item.firstName } ${ item.lastName }`, email: item.emailAddress, phone: item.phone, userID: item.userID } )
				else if( item instanceof POC ) rows.push( { name: `${ item.firstName } ${ item.lastName }`, email: item.emailAddress, phone: item.phoneNumber, pocID: item.id } )
				else if( item.listID === undefined ) rows.push( { email: item.email } ) // "string" value (it's an object so the email validator can add .valid)
			}

			return rows
		},



		async preflight( checkSubject = true, checkUnsubscribe = true ) {
			this.preflightLoading = true
			this.showPreflightTags = false

			if( !checkSubject ) this.checkSubject = false
			if( !checkUnsubscribe ) this.checkUnsubscribe = false

			if( this.checkSubject && this.deliverEmail && ( this.message.subject == null || !this.message.subject.length ) ) {
				this.$refs.confirmBlankSubjectModal.open()
				return  
			}

			if( this.checkUnsubscribe && this.messageRole === 'marketing' && !this.message.hasUnsubscribe() ) {
				this.$refs.confirmNoUnsubscribeModal.open()
				return
			}

			const req = {
				messageBlast: {
					recipients: this.prepareRecipients(),
					message: this.message.export(),
					deliverEmail: this.deliverEmail,
					deliverSMS: this.deliverSMS,
					deliverApp: this.deliverApp,
					autoPopApp: this.autoPopApp,
					overrideDeliveryPreferences: !this.respectPreferences,
					useMailMerge: this.message.useMailMerge(),
				}
			}

			if( this.selectedCourse ) req.courseID = this.selectedCourse.courseID

			try {
				const res = await MessageCenterAPI.getPreflightReport( req )
				this.preflightReport = res.data
				this.$refs.preflightModal.open()

			} catch( e ) {
				this.$refs.preflightErrorModal.open()
				console.error( e.message )

			} finally {
				this.preflightLoading = false
			}
		},


		async getMergedPreview( rowIndex ) {

			const row = this.preflightReport.rows[ rowIndex ]
			this.previewRowIndex = rowIndex

			this.previewMessage = null

			const req = {
				messageBlast: {
					recipients: [ { userID: row.userID, address: row.email, pocID: row.pocID, listID: row.listID, rowID: row.rowID } ],
					message: this.message.export(),
					deliverEmail: this.deliverEmail,
					deliverSMS: this.deliverSMS,
					deliverApp: this.deliverApp,
					autoPopApp: this.autoPopApp,
					overrideDeliveryPreferences: !this.respectPreferences,
					useMailMerge: this.message.useMailMerge(),
				}
			}

			if( this.selectedCourse ) req.courseID = this.selectedCourse.courseID

			const res = await MessageCenterAPI.getMailMergePreviews( req )
			this.previewMessage = res.data[0]
			if( row.error && ! this.previewMessage.error ) this.previewMessage.error = row.error
			this.$refs.mergedPreviewModal.open()
		},


		previewPrevious() {
			if( this.previewRowIndex <= 0 ) return
			this.getMergedPreview( this.previewRowIndex - 1 )
		},

		previewNext() {
			if( this.previewRowIndex >= this.preflightReport.rows.length - 1 ) return
			this.getMergedPreview( this.previewRowIndex + 1 )
		},




		confirmSend() {
			this.$refs.confirmSendModal.open()
		},


		resetSend() {
			this.sending = false
			this.sent = false
			this.sendingErrors = null
			this.sendingReport = null
			if( this.sendingPollIntervalID ) clearInterval( this.sendingPollIntervalID )
			this.sendingPollIntervalID = null
			this.sendingUnitsComplete = 0
			this.sendingUnitsTotal = 0
			this.sendingPercent = null
		},


		async send() {
			this.$refs.preflightModal.close()

			this.resetSend()
			this.sending = true
			
			try {
				const req = {
					messageBlast: {
						recipients: this.prepareRecipients(),
						message: this.message.export(),
						deliverEmail: this.deliverEmail,
						deliverSMS: this.deliverSMS,
						deliverApp: this.deliverApp,
						autoPopApp: this.autoPopApp,
						overrideDeliveryPreferences: !this.respectPreferences,
						useMailMerge: this.message.useMailMerge(),
					}
				}

				if( this.draft && this.draft.messageBlastID ) req.messageBlast.messageBlastID = this.draft.messageBlastID

				// Override default sender account
				if( this.deliverEmail && this.fromEmailUsername ) req.messageBlast.fromEmailUsername = this.fromEmailUsername
				if( this.deliverEmail && this.fromEmailBox ) req.messageBlast.fromEmailMailbox = this.fromEmailBox

				if( this.from === this.currentUser ) {
					req.messageBlast.fromID = this.currentUser.userID

				} else if ( this.from === true ) {
					req.messageBlast.fromName = "eKnowledge Support"
					req.messageBlast.fromEmail = "support@eknowledge.com"
				
				} else if( this.from === null ) {
					req.messageBlast.fromName = this.fromName
					req.messageBlast.fromEmail = this.fromEmail
				}

				if( this.selectedCourse ) req.courseID = this.selectedCourse.courseID
				
				const res = await MessageCenterAPI.send( req )
				this.sendingJobID = res.data.jobID

				this.pollSendingProgress( res.data.jobID )

			} catch( e ) {
				this.sendingErrors = [ e ]
				this.sending = false
			}

		},


		pollSendingProgress( jobID ) {

			// Poll for job updates until job is complete
			if( this.sendingPollIntervalID !== null ) clearInterval( this.sendingPollIntervalID )

			const updateJobStatus = async () => {
				const data = await LongJobAPI.getJobProgress( jobID )
				this.sendingUnitsComplete = data.workUnitsComplete
				this.sendingUnitsTotal = data.workUnitsTotal
				this.sendingPercent = data.workUnitsTotal ? (data.workUnitsComplete / data.workUnitsTotal) : 1
				this.sendingReport = data.jobReport

				if( data.error || data.errorReport ) this.sendingErrors = data.errorReport
				
				if( data.complete ) {
					this.sending = false
					this.sent = true
					
					clearInterval( this.sendingPollIntervalID )
					this.sendingPollIntervalID = null
				}
			}

			updateJobStatus()
			
			this.sendingPollIntervalID = setInterval( updateJobStatus, 2500 )
		},



		async loadDraft( draft = null ) {
			draft = draft ? draft : this.draftIn
			if( !draft ) return

			if( draft.messageBlastID && draft.sentTimestamp ) {
				draft.messageBlastID = null
				draft.sentTimestamp = null
			}

			this.draft = draft
			this.message.subject = draft.message.subject
			this.message.htmlBody = draft.message.htmlBody
			this.message.plainBody = draft.message.plainBody
			this.deliverEmail = draft.deliverEmail
			this.deliverSMS = draft.deliverSMS
			this.deliverApp = draft.deliverApp
			this.autoPopApp = draft.autoPopApp
			this.respectPreferences = !draft.overrideDeliveryPreferences


			// Recipients
			this.recipients = []
			for( const r of draft.recipients ) {
				if( r.userID != null ) {
					const user = new User()
					user.userID = r.userID
					user.firstName = r.name
					user.emailAddress = r.address
					this.recipients.push( user )

				} else if( r.pocID != null ) {
					const poc = new POC()
					poc.id = r.pocID
					poc.firstName = r.name

					const email = new PocEmail()
					email.email = r.address
					email.pocID = r.pocID
					poc.emails.push( email )
					this.recipients.push( poc )

				} else if( r.listID != null ) {
					const res = await MessageCenterAPI.getBlastList( r.listID )
					this.recipients.push( BlastList.import( res.data ) )

				} else if( r.address != null ) {
					this.recipients.push( { email: r.address } )

				} else console.error("Invalid recipient in draft:", r, draft)
			}


			// Override default sender account
			this.fromEmailUsername = draft.fromEmailUsername
			this.fromEmailBox = draft.fromEmailMailbox

			this.from = false

			if( draft.fromID === this.currentUser.userID ) {
				this.from = this.currentUser

			} else if ( draft.fromName === "eKnowledge Support" && draft.fromEmail === "support@eknowledge.com" ) {
				this.from = true

			} else {
				this.from = null
				this.fromName = draft.fromName
				this.fromEmail = draft.fromEmail
			}


			// Course
			if( draft.courseID ) {
				const res = await CourseAPI.getCoursesByID( [ draft.courseID ] )
				if( res.data && res.data.length > 0 ) this.selectedCourse = Course.import( res.data[ 0 ] )
			}

		},



		// Set message to match incoming/existing template
		async loadTemplate( template = null ) {
			template = template ? template : this.templateIn
			if( !template ) return

			this.template = template
			this.deliverEmail = true
			this.message.subject = this.template.subject
			this.message.htmlBody = this.template.body
			this.message.plainBody = this.template.altBody

			this.selectingTemplate = null

			await this.$nextTick()
			if( this.$refs.tiptap ) this.$refs.tiptap.reload()
		},



		replaceTemplate() {
			this.oldTemplate = this.template
			this.template = new MessageTemplate()

			this.template.subject = this.message.subject
			this.template.body = this.message.htmlBody
			this.template.altBody = this.message.plainBody

			this.$refs.createTemplateModal.open()
		},


		createOrUpdateTemplate() {
			var newTemplate = false
			
			if( !this.template ) {
				newTemplate = true
				this.template = new MessageTemplate()
			}
			
			this.template.subject = this.message.subject
			this.template.body = this.message.htmlBody
			this.template.altBody = this.message.plainBody

			if( newTemplate ) this.$refs.createTemplateModal.open()
			else this.saveTemplate()
		},


		async saveTemplate() {
			this.templateSaved = false
			this.templateSaveError = false
			try {
				const res = await MessageCenterAPI.saveMessageTemplate( this.template.export() )
				this.template.id = res.data.id
				this.templateSaved = true
				this.oldTemplate = null

				setTimeout( () => this.templateSaved = false, 2000 )

			} catch ( e ) {
				console.error( e )
				this.templateSaveError = true
			}
		},



		
		async saveMessageDraft( property, value = null ) {

			// Create initial draft
			if( this.draft == null ) this.createMessageDraft()
			
			// Short-circuit if unchanged from initial state
			if( this.draft.isDefault() && !this.draft.isPropertyChanged( property, value ) ) return

			// Save initial draft
			if( !this.draft.messageBlastID && !this.savingDraft ) {

				try {
					this.savingDraft = true
					this.createMessageDraft()
					const res = await MessageCenterAPI.saveMessageBlast( this.draft )
					this.draft.messageBlastID = res.data.id

				} catch( e ) {
					this.draftSaveError = true
					console.error( e.message )
					
				} finally {
					this.savingDraft = false
				}

				return
			}

			// Draft created, but awaiting initial save (retry later)
			if( !this.draft.messageBlastID && this.savingDraft ) {

				if( this.draftSaveRetries > 5 ) {
					this.draftSaveError = true
					return
				}
				setTimeout( () => {
					this.saveMessageDraft( property, value )
				}, 2000 )
				return
			}

			// Short-circuit if unchanged
			if( !this.draft.isPropertyChanged( property, value ) ) return

			// Patch draft
			const patchReq = {
				id: this.draft.messageBlastID,
				key: property,
				value
			}

			try {
				this.savingDraft = true
				this.draftSaveError = false
				
				await MessageCenterAPI.patchMessageBlast( patchReq )
				if( property === 'message' ) this.draft.message = value.export()
				else this.draft[ property ] = value

			} catch( e ) {
				this.draftSaveError = true
				console.error( e.message )

			} finally {
				this.savingDraft = false
			}
			
		},


		async createMessageDraft() {

			this.draft = new MessageBlast()
			this.draft.recipients = this.prepareRecipients()
			this.draft.message = this.message.export()
			this.draft.deliverEmail = this.deliverEmail
			this.draft.deliverSMS = this.deliverSMS
			this.draft.deliverApp = this.deliverApp
			this.draft.autoPopApp = this.autoPopApp
			this.draft.overrideDeliveryPreferences = !this.respectPreferences
			this.draft.useMailMerge = this.message.useMailMerge()
			this.draft.courseID = this.selectedCourseID

			// Override default sender account
			if( this.deliverEmail && this.fromEmailUsername ) this.draft.fromEmailUsername = this.fromEmailUsername
			if( this.deliverEmail && this.fromEmailBox ) this.draft.fromEmailMailbox = this.fromEmailBox

			if( this.from === this.currentUser ) {
				this.draft.fromID = this.currentUser.userID

			} else if ( this.from === true ) {
				this.draft.fromName = "eKnowledge Support"
				this.draft.fromEmail = "support@eknowledge.com"
			
			} else if( this.from === null ) {
				this.draft.fromName = this.fromName
				this.draft.fromEmail = this.fromEmail
			}


		},




		addRecipientsToList() {
			this.isNewBlastList = null
			this.$refs.recipientListModal.open()
		},


		async createBlastList() {
			const res = await MessageCenterAPI.saveBlastList( { name: this.newBlastListName, description: this.newBlastListDescription } )
			const id = res.data.id
			this.addToExistingBlastList( id )

			this.newBlastListName = null
			this.newBlastListDescription = null
		},


		addToExistingBlastList( blastListID ) {
			const rows = this.prepareBlastListRows()
			MessageCenterAPI.saveBlastListRows( blastListID, rows )
			this.selectedBlastList = null
			this.$refs.recipientListModal.close()
		},


		addListToMessage( blastList ) {
			this.addRecipient( blastList )
			this.selectedBlastList = null
		},



		recipientTagStyles( item ) {
			const style = {}
			if( item instanceof BlastList ) style.backgroundColor = 'var(--pp10-orange)'
			if( item.valid === false ) style.backgroundColor = 'var(--pp10-red)'
			return style
		},

		recipientTagClickable( item ) {
			return item instanceof BlastList || item instanceof POC || item instanceof User
		},

		showTagDetails( item ) {
			if( item instanceof BlastList ) {
				this.selectedBlastList = item
				this.$refs.listDetailsModal.open()
			
			} else if( item instanceof POC ) {
				this.selectedRecipient = item
				this.$refs.pocDetailsModal.open()

			} else if( item instanceof User ) {
				this.selectedRecipient = item
				this.$refs.userDetailsModal.open()

			}
		},


	},

}
</script>



<style scoped>
.ComposeNotification {
	margin: 2em auto;
	max-width: 1200px;
	text-align: left;
}

.numberedTitle {
	text-align: center;
	font-size: 24pt;
	font-weight: bold;
	margin-bottom: 0.5em;
	transition: margin-top 0.5s;
}

.numberedTitle .number {
	border: 0.25em solid #444444;
	border-radius: 25pt;
	width: 2em;
	height: 2em;
	padding: 0.125em 0;
}


#step1 {
	margin-top: 0;
	transition: margin-top 0.5s;
}


.topSpace {
	margin-top: 20vh !important;
	transition: margin-top 0.5s;
}

.toField {
	max-height: 10em;
	padding: 0.25em;
	overflow: auto;
}

#addRecipientButton {
	padding: 0.25em;
	cursor: pointer;
	background: var(--ekno-blue-pastel-80);
	color: var(--ekno-blue-20);
	
	border-top-right-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
}

#addRecipientButton:hover {
	background: var(--ekno-blue-80);
	color: var(--ekno-blue-30);
}

#addRecipientButton.error {
	background: var(--pp10-red-pastel-70);
	color: var(--pp10-red-20);
	cursor: not-allowed;
}

#addRecipientButton.error * { cursor: not-allowed; }

#copyBodyButton {
	cursor: pointer;
	border-radius: 0.5em;
	transition: background-color 0.5s;
}
#copyBodyButton:hover {
	background-color: var(--ekno-blue-80);
	color: var(--ekno-blue-30);
	transition: background-color 0.5s;
}

.NotificationEntry {
	width: 100%;
	height: auto;
	resize: none;
}
.NotificationInput {
	border-bottom: 1px solid #bbb;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	/* margin: 1em 0; */
	position: relative;
}
.NotificationInputField {
	border: none;
	box-shadow: none;
	font-size: 12pt;
	flex-grow: 1;
}
.NotificationInputLabel {
	color: #777;
}
.DropdownAutocomplete {
	position: absolute;
	top: 100%;
	z-index: 1;
	background-color: white;
	border: 1px solid #aaa;
	min-width: 200px;
}
.DropdownAutocomplete p {
	margin: 0;
	padding: .5em 1em;
	font-size: 10pt;
}
.DropdownAutocomplete p:hover {
	background-color: #ddd;
	cursor: pointer;
}

.ToAll input {
	margin-left: 2em;
}
.ToAll label {
	font-size: 10pt;
}

.CharCount {
	font-size: 8pt;
	color: #888;
}
.CharCountWarning {
	color: rgba(255, 111, 111, 0.989);
}

.ComposeNotification select,
.ComposeNotification input,
.ComposeNotification textarea {
	background-color: inherit;
}


th { text-align: center; }
td { padding: 0.5em 1em; }
tr:hover, tr:active { background: var(--ekno-blue-95);}
th.name, td.name { text-align: right; }
td.green { color: var(--pp10-green); font-weight: bold; }
td.red { color: var(--pp10-red); font-weight: bold; }


.previewTitle {
	background-color: var(--pp10-purple);
	color: white;
	font-weight: bold;
	padding: 0.5em 1em;
	border-top-left-radius: 1em;
	border-top-right-radius: 1em;
}

.email {
	min-width: 400px;
	max-width: 800px;
}

.smsBubble {
	background: var(--ekno-blue);
	color: white;
	padding: 0.5em;
	margin: 3em 1em 1em 3.5em;
	border-radius: 0.5em;
	min-width: 200px;
	max-width: 250px;
}

#sending, #sent {
	width: 100%;
	position: absolute;

	font-size: 3em;
	text-align: center;
	
	opacity: 0;
	color: #472274;
}


#sending.show, #sent.show {
	opacity: 1;
}

#sendReport, #errorReport {
	margin-top: 2em;
	padding: 1em;
	border-radius: 1em;

	max-height: 25em;
	overflow: auto;
}
#sendReport{
	background: var(--pp10-green-pastel-80);
}
#errorReport {
	background: var(--pp10-red-90);
}

.saveIcon {
	display: inline-block;
	width: 0;
	height: 0;
	overflow: hidden;
	opacity: 0;
	transition: width 0.5s, opacity 0.5s;
}

.saveIcon.show {
	width: 1em;
	height: 1em;
	opacity: 1;
	transition: width 0.5s, opacity 0.5s;
}
</style>
