<template>
<section>
	<div class='flex-row flex-justify-between flex-align-center py-05' style="position: sticky; top: 0; background: white;">

		<div class="flex-row flex-justify-start flex-align-center flex-gap-05">
			<SearchBox @search="str => searchStr = str" />

			<div>
				<select v-model='filterCourse'>
					<option :value='null' :selected='!filterCourse'>All Courses</option>
					<option v-for='course in courses' :key='course.courseID' :value='course.courseID'>{{ course.courseName }}</option>
				</select>
			</div>

			<div>
				<select v-model="isArchivedInternal">
					<option :value="null">Archived + Un-archived</option>
					<option :value="false">Un-archived</option>
					<option :value="true">Archived</option>
				</select>
			</div>
			<div>
				<select v-model="isActive">
					<option :value="null">Active + Expired</option>
					<option :value="true">Active</option>
					<option :value="false">Expired</option>
				</select>
			</div>
		</div>

		<!-- <div id="SpreadsheetLink"><a class="link" @click="cohortStatsReport">Generate Stats (CSV) for visible licenses</a></div> -->
		<div v-if="filterUser" class="flex-row flex-align-center flex-gap-2">
			<div class="flex-row flex-align-center flex-gap-05">
				<ToggleButton color="var(--pp10-green-30)" v-model="includeLicensesByRoles" :sync="true" />
				<span class="bold" :class="{ 'color-gray-60' : !includeLicensesByRoles, 'color-green-25' : includeLicensesByRoles }">Role Licenses</span>
			</div>

			<div class="flex-row flex-align-center flex-gap-05">
				<ToggleButton color="var(--pp10-purple-30)" v-model="includeLicensesByCohorts" :sync="true" />
				<span class="bold" :class="{ 'color-gray-60' : !includeLicensesByCohorts, 'color-purple-25' : includeLicensesByCohorts }">Cohort Licenses</span>
			</div>
		</div>

		<div>
			<label>Per page: </label>
			<select v-model="pageSize">
				<option :value='25'>25</option>
				<option :value='50'>50</option>
				<option :value='100'>100</option>
				<option :value='250'>250</option>
				<option :value='500'>500</option>
			</select>
		</div>
		<button class="button inline-block" @click="newLicense">New License</button>
	</div>



	<div class='flex justify-center' v-if='showSpinner'>
		<div class='icon-spinner4 spin-loader'></div>
	</div>


	<section v-if='!showSpinner'>


		<div v-if='licenses.length'>
			<div class="flex-row flex-justify-between flex-align-center">
				<div class="flex-row flex-gap-05">
					<span class="link" @click="selectAll">Select All</span>
					<span class="link" @click="selectNone">Select None</span>
					<button v-if="selectedLicenses.length" @click="showBulkEdit = true" :disabled="!selectedLicenses.length">Edit {{ selectedLicenses.length }} Selected</button>
					<!-- <span v-if="selectedLicenses.length">&nbsp;{{selectedLicenses.length}} selected</span> -->
				</div>
				<span>{{licenseSet.numLicenses}} licenses on {{licenseSet.numPages}} pages</span>
			</div>
			<ObjectTable
				class='font-small'
				ref="objectTable"
				:Source='licenses'
				:Columns='columns'
				:StyleFunction='(item) => rowStyle(item)'
				:PageNum='pageNum'
				:Numbered='true'
				:MultiSelect='true'
				:PageSize='pageSize'
				:SortBy='sortBy'
				:SortAsc='sortAsc'
				Deletable
				@delete='lic => licenseToDelete = lic'
				@selectedItems='setSelectedLicenses'
				@edit="editLicense"
				@sort='selectSortBy'
			/>

			<Paginator :value="pageNum" :numPages="numPages" @input="pg => toPage( pg )" />
		</div>
		<div v-else class="NoResults">No results</div>
	</section>


	<StretchModal :showModal="showEditLicense" :clickToClose="false" @close="showEditLicense = false" height="99vh" minWidth="80%">
		<template #header>License Details: {{ currentLicense ? currentLicense.serialKey : '' }}</template>
		<LicenseDetails v-if="currentLicense" :License="currentLicense" @success="edited" @close="showEditLicense = false" :Mode="mode" />
	</StretchModal>

	<StretchModal :showModal="showNewLicense" :clickToClose="false" @close="showNewLicense = false">
		<template #header>New License</template>
		<LicenseDetails :License="currentLicense" @success="added" :Mode="mode" @close="showNewLicense = false" />
	</StretchModal>

	<StretchModal :showModal="showBulkEdit" :clickToClose="false" @close="showBulkEdit = false">
		<div class="pa-1">
			<div class="section-header">
				<h1 class="left">Bulk-Edit Licenses</h1>

			</div>
			<div class="reset-float"></div>
			<BulkLicenseEdit :licenses="selectedLicenses" :user="user" :customer="customer" />
		</div>
	</StretchModal>

	<ConfirmDialog :show="licenseToDelete !== null" @cancel="licenseToDelete = null" @confirm="deleteLicense(licenseToDelete)">
		<div>Are you sure you want to delete this license?</div>
		<div style="font-size: 1.25em; font-weight: bold; margin: 0.25em 0;">{{ licenseToDelete ? licenseToDelete.serialKey : '' }}</div>
		<div><strong>This action CANNOT be undone!</strong></div>
	</ConfirmDialog>

</section>
</template>

<script>
import License from "@/models/License.js" // This has to be first, to avoid cyclic dependency issues! 
                                          // (LicenseAPI.js & LicenseSet.js require License.js)

import LicenseAPI from "@/api/LicenseAPI.js"
import LicensesAPI from "@/api/LicensesAPI.js"
import LicenseSet from '@/libraries/LicenseSet.js'
import PaginatedRequest from "@/api/PaginatedRequest.js"

import LicenseDetails from "./LicenseDetails.vue"
import SearchBox from "@/components/utilities/SearchBox.vue"
import Paginator from '@/components/utilities/Paginator.vue'
import ObjectTable from '@/components/utilities/ObjectTable.vue'
import BulkLicenseEdit from '@/features/Licenses/BulkLicenseEdit.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'
import ConfirmDialog from '@/components/utilities/ConfirmDialog.vue'
import { ToggleButton } from 'vue-js-toggle-button'


import Moment from 'moment'


export default {
	name: "licenses-list",

	components: {
		LicenseDetails,
		SearchBox,
		Paginator,
		ObjectTable,
		BulkLicenseEdit,
		StretchModal,
		ConfirmDialog,
		ToggleButton,
	},

	props: {
		User: {
			type: Object,
			default: null
		},
		Customer: {
			type: Object,
			default: null
		},
		affiliate: {
			type: Object,
      default: null
		},
		StudentStatistics: {
			type: Boolean,
			default: false
		},
		isArchived: {
			type: Boolean,
			default: null
		}
	},

	data() {
		return {
			licenseSet: new LicenseSet(),
			rowStyle: function(license) {
				if( license.isArchived ) return { backgroundColor: 'rgb(255, 230, 220)' }
				if( license.assignedByRoleNames && license.masterID != this.user.userID && license.studentID != this.user.userID ) return { backgroundColor: 'var(--pp10-green-95)' }
				if( license.assignedByCohortNames && license.masterID != this.user.userID && license.studentID != this.user.userID ) return { backgroundColor: 'var(--pp10-purple-95)' }
			},
			columns: [
				{
					displayName: 'Key',
					propertyName: 'serialKey',
					displayFunction: function( item ) { return item.serialKey + `<div style="font-size: 0.8em; color: gray;">SKU: ${item.sku} ${item.pricingOptionID ? ` // ${item.pricingOptionID}` : '' }</div>` },
					sortable: true,
				},
				{
					displayName: 'Course',
					propertyName: 'courseID',
					store: this.$store,
					displayFunction: function( item ) {
						const course = this.store.state.Courses.findByAttribute('courseID', item.courseID);
						return course ? (course.courseName.length > 20 ? course.courseName.substring(0, 15) + '...' : course.courseName) : item.courseID;
					},
					sortable: true
				},
				{
					displayName: 'Created / Expires',
					propertyName: 'purchaseDate',
					displayFunction: function( item ) {
						if (item.purchaseDate === null) return '';
						const purchaseDate = Moment(item.purchaseDate);
						const isExpired = item.expirationDate !== null && item.expirationDate < Moment()
						
						var expString = null
						if(item.expirationDate !== null) {
							const expirationDate = Moment(item.expirationDate)
							expString = expirationDate.format('YYYY-MM-DD')
						} else expString = '&infin;'

						const formattedDate = purchaseDate.format('YYYY-MM-DD') + '<br>' + expString
						return `<span style="${isExpired ? 'color:red' : 'color: green'}">${formattedDate}</span>`
					},
					sortable: true
				},
				{
					displayName: 'Assigned Via',
					displayFunction: ( item ) => {
						if( !this.filterUser ) return 'N/A'
						if( item.masterID == this.filterUser ) return 'Master Slot'
						if( item.studentID == this.filterUser ) return 'Student Slot'
						if( item.assignedByRoleNames ) return `Roles:<br><span class="color-green-25">${item.assignedByRoleNames.join(",<br>")}</span>`
						if( item.assignedByCohortNames ) return `Cohorts:<br><span class="color-purple-25">${item.assignedByCohortNames.join(",<br>")}</span>`
						return 'none'
					}
				},
				{
					displayName: 'Users',
					propertyName: 'primaryUser',
					displayFunction: function( item ) {
						const master = item.masterUser ? `${item.masterUser.firstName} ${item.masterUser.lastName}` : '--';
						const student = item.studentUser ? `${item.studentUser.firstName} ${item.studentUser.lastName}` : '--';
						return `<div>${master}</div><div>${student}</div>`
					},
					sortable: true
				},
				{
					displayName: 'Cust',
					propertyName: 'customerID',
					sortable: true
				},
				{
					displayName: 'Affiliate',
					propertyName: 'affiliateName',
					displayFunction: function( license ) {
						if( !license.affiliateID || license.affiliateID == '0' ) return ''
						return ( license.affiliateName && license.affiliateName.length > 25 ? license.affiliateName.substring(0, 20) + '...' : license.affiliateName ) + ` (${license.affiliateID})`
					},
					sortable: true
				},
				{
					displayName: 'Class Info',
					propertyName: 'teacherCohortLinks',
					displayFunction: function( item ) {
						let numTeachers = item.teacherCohortLinks.teachersArray.length;
						let numClasses = item.teacherCohortLinks.cohortsArray.length;
						
						return `<span style="white-space: nowrap"><span><span class="icon-users" />${numClasses} </span><span class="icon-user-tie"/>${numTeachers}</span>`;
					},
					sortable: false
				},
				{
					displayName: 'Time in program',
					propertyName: 'timeInProgram',
					displayFunction: function( item ) {
						const mMins = item.masterTimeInProgram
						const mTime = mMins < 60 ? `${Math.floor(mMins)}min` : `${Math.floor(mMins/60)}hrs`

						const sMins = item.studentTimeInProgram
						const sTime = sMins < 60 ? `${Math.floor(sMins)}min` : `${Math.floor(sMins/60)}hrs`
						return `${item.masterID ? mTime : '--'} / ${item.studentID ? sTime : '--'}`
					},
					sortable: true
				},
				{
					displayName: 'Diag (real)',
					propertyName: 'initialScore',
					displayFunction: function( item ) { return item.studentStatistics ? item.studentStatistics.initialScore : '--' },
					sortable: true
				},
				{
					displayName: 'Projected (adjusted)',
					propertyName: 'projectedScore',
					displayFunction: function( item ) { return item.studentStatistics ? item.studentStatistics.projectedScore : '--' },
					sortable: true
				},
				{
					displayName: '&Delta;&nbsp;Score',
					propertyName: 'deltaProjectedScore',
					displayFunction: function( item ) {
						let stats = item.studentStatistics;
						if( !stats || isNaN(parseFloat(stats.projectedScore)) || isNaN(parseFloat(stats.initialScore)) ) return '--';
						let val = Math.round(parseFloat(stats.projectedScore) - parseFloat(stats.initialScore));
						return val >= 0 ? '+' + val : val;
					},
					sortable: true
				},
				{
					displayName: '%&nbsp;Imprv',
					propertyName: 'percentImprovement',
					displayFunction: function( item ) {
						let stats = item.studentStatistics;
						if(!stats || isNaN(stats.percentImprovement)) return '--';
						let val = stats.percentImprovement;
						return val >= 0 ? '+' + val : val;
					},
					sortable: true
				},
				{
					displayName: 'Order',
					propertyName: 'orderID',
					displayFunction: function( item ) { return `<div>${item.orderID}/${item.lineItemIndex}</div>` },
					sortable: true
				},
			],

			isInitialized: false,

			searchStr: null,
			sortBy: 'serialKey',
			sortAsc: true,
			isArchivedInternal: this.isArchived,

			currentLicense: null,
			mode: 'edit',
			filterCourse: null,
			filterUser: null,
			filterCustomer: null,
			filterRole: null,
			includeLicensesByRoles: true,
			includeLicensesByCohorts: true,

			pageNum: 1,
			pageSize: 50,
			showSpinner: false,
			searchLicense: '',
			showFilters: false,
			isActive: null,
			user: null,
			users: null,
			customer: null,

			selectedLicenses: [],
			addNumLicenses: 0,

			showNewLicense: false,
			showEditLicense: false,
			showBulkEdit: false,
			licenseToDelete: null,
		}
	},

	async created() {
		if (this.User) {
			this.user = this.User;
			this.filterUser = this.User.userID;
		}
		if (this.Customer) {
			this.customer = this.Customer;
			this.filterCustomer = this.Customer.id;
		}
		this.isArchivedInternal = this.isArchived
		await this.initialize();
	},


	computed: {
		licenses() {
			return this.licenseSet ? (this.licenseSet.objects || []) : []
		},

		numPages() {
			return this.licenseSet.numPages || 1
		},

		courses() { return this.$store.state.Courses.objects; },

		roles() { return this.$store.state.Roles.objects; },
	},


	watch: {

		showNewLicense(value) { if( !value ) this.$refs.objectTable.deselect() },
		showEditLicense(value) { if( !value ) this.$refs.objectTable.deselect() },

		searchStr() {
			this.pageNum = 1
			this.initialize()
		},

		filterCourse() {
			this.pageNum = 1
			this.initialize()
		},

		includeLicensesByRoles() {
			this.pageNum = 1
			this.initialize()
		},

		includeLicensesByCohorts() {
			this.pageNum = 1
			this.initialize()
		},

		onlyMaster() {
			this.pageNum = 1
			this.initialize()
		},

		onlyStudent() {
			this.pageNum = 1
			this.initialize()
		},

		pageSize() {
			this.pageNum = 1
			this.initialize()
		},

		isActive() {
			this.pageNum = 1
			this.initialize()
		},

		isArchivedInternal() {
			this.pageNum = 1
			this.initialize()
		},
	},



	methods: {
		
		async initialize() {
			
			this.showSpinner = true;
			let req = new PaginatedRequest( this.sortBy, this.sortAsc, this.pageNum, this.pageSize );
			req.paginate = true
			req.searchString = this.searchStr || null

			req.courseID = this.filterCourse;
			req.userID = this.filterUser;
			req.customerID = this.filterCustomer;
			if( this.affiliate ) req.affiliateID = this.affiliate.id;
			req.roleID = this.filterRole;
			req.includeLicensesByRoles = this.includeLicensesByRoles
			req.includeLicensesByCohorts = this.includeLicensesByCohorts
			
			req.isActive = this.isActive;
			req.isArchived = this.isArchivedInternal
			
			req.includeStatistics = this.StudentStatistics
			req.includeOrderHistory = true

			try {
				const res = await LicensesAPI.filterGetLicenses( req );
				this.licenseSet = LicenseSet.import( res.data );
				await this.getTeacherCohortLinks();

			} catch (e) {
				console.error(e);
				alert('Could not get licenses from the server.');
			}
			this.showSpinner = false;
		},

		async getTeacherCohortLinks() {
			let licenseMap = new Map();
			this.licenseSet.objects.forEach(license => licenseMap.set(license.serialKey, license));
			const licenseKeys = this.licenseSet.objects.map(lic => lic.serialKey);
			let links = await LicenseAPI.getTeacherCohortLinks(licenseKeys)
			
			links.forEach(link => {
				licenseMap.get(link.licenseKey).teacherCohortLinks.createLink(link);	
			});
		},


		newLicense() {
			this.mode = 'new';
			this.currentLicense = new License();
			if (this.User) this.currentLicense.masterID = this.User.userID;
			this.showNewLicense = true;
		},



		// Multi-edit tools
		setSelectedLicenses(list) {
			this.selectedLicenses = [];
			for(let i=0; i < list.length; i++) {
				if(list[i] == true) this.selectedLicenses.push( this.licenses[i] );
			}
		},

		selectAll() {
			this.$refs.objectTable.selectAll();
		},

		selectNone() {
			this.$refs.objectTable.selectNone();
		},


		async deleteLicense(license) {

			try {
				await LicenseAPI.deleteLicense(license)
				this.licenseSet.remove(license);

			} catch (e) {
				console.error(e);
				alert("Error communicating with server; changes not saved.");
			}

			this.licenseToDelete = null
		},


		editLicense(license) {
			this.mode = 'edit';
			this.currentLicense = license;
			this.showEditLicense = true;
		},

		async edited(license) {
			if (this.licenseSet) this.licenseSet.updateElement('serialKey', license);
			this.currentLicense = license;
		},

		async added(license) {
			this.licenseSet.push(license);
			this.currentLicense = license;
			this.mode = 'edit';
		},


		toPage(pageNum) {
			this.pageNum = pageNum;
			this.initialize();
		},


		selectSortBy(prop) {
			if (prop == this.sortBy) {
				this.sortAsc = !this.sortAsc;
			} else {
				this.sortBy = prop;
				if (
					this.sortBy === 'purchaseDate' ||
					this.sortBy === 'timeInProgram' ||
					this.sortBy === 'initialScore' ||
					this.sortBy === 'projectedScore' ||
					this.sortBy === 'deltaProjectedScore' ||
					this.sortBy === 'percentImprovement'
				) {
					this.sortAsc = false;
				} else {
					this.sortAsc = true;
				}
			}
			this.pageNum = 1;
			this.initialize();
		}
	},
}
</script>





<style scoped>
#addLicenses {
	width: 5em;
}

#header-left button {
	margin: 0 0 0 1em;
}
#header-left #SpreadsheetLink {
	margin: 0 1em;
	display: inline-block;
}

#AddLicensesWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1em;
}
.SquareBlueButton {
	margin: 0 0 0 0;
}
</style>
