<template>
<section id="queryVarDetails" class="mx-1 pa-1 w-100 h-100" style="max-height: calc( 100vh - 12em ); overflow: auto;">
	
	<div class="w-100">
		<h2 class="center mt-0" style="color: var(--pp10-orange);">
			Input Parameter 
			<span v-if="!editName" class="name pointer round-05 pa-025" @click="editName = true">"{{ nameInternal }}"</span>
			<span v-else><input v-model="nameInternal" placeholder="Name" />&nbsp;<button class="pillButton bg secondary" @click="editName = false">done</button></span>
		</h2>
	</div>

	<div class="center">
		Query Type:
		<select v-model="queryVariable.queryType">
			<option :value="null">( select )</option>
			<option value="course">Course</option>
			<option value="user">User Tracking</option>
		</select>
	</div>


	<section v-if="queryVariable.queryType" style="max-width: 900px; margin: auto;">

		<div class="flex-row flex-justify-start flex-align-center flex-gap-1 mt-2" :class="{ 'warning round-05' : !queryVariable.field || (queryVariable.queryType === 'user' && (!queryVariable.function || !queryVariable.aggregateBasis)) }">
			<strong>SELECT</strong>
			<select v-model="queryVariable.function">
				<option :value="null">(select function)</option>
				<option value="count">count</option>
				<option value="distinctCount">count distinct</option>
				<option value="sum">sum</option>
				<option value="avg">avg</option>
				<option value="min">min</option>
				<option value="max">max</option>
			</select>
			<strong>of</strong>
			<select v-model="queryVariable.field">
				<option :value="null">(select field)</option>
				<option value="*">* (whole row)</option>
				<option value="nodeID">Node ID</option>
				<option v-if="queryVariable.queryType == 'user'" value="durationSeconds">Time Spent on Node (durationSeconds)</option>
				<option v-if="queryVariable.queryType == 'user'" value="timeBeforeComplete">Time Spent Before Completion (timeBeforeComplete)</option>
				<option v-if="queryVariable.queryType == 'user'" value="timeAfterComplete">Time Spent After Completion (timeAfterComplete)</option>
				<option v-if="queryVariable.queryType == 'user'" value="videoWallTime">Video Wall Time (Student Time Spent Watching)</option>
				<option v-if="queryVariable.queryType == 'user'" value="videoPlayheadTime">Video Playhead Time (Amount of Video Watched)</option>
				<option v-if="queryVariable.queryType == 'user'" value="videoFileDuration">Video duration (per unique video filename)</option>
				<option v-if="queryVariable.queryType == 'user'" value="mediaFilename">Unique media filenames</option>
			</select>
			
			<strong v-if="queryVariable.queryType == 'user'">for each</strong>
			<select v-if="queryVariable.queryType == 'user'" v-model="queryVariable.aggregateBasis">
				<option :value="null">(select)</option>
				<option value="userID">user</option>
				<option value="licenseKey">license</option>
				<option value="cohortID">cohort</option>
			</select>
		</div>


		<div v-if="showNodeParams" class="flex-row flex-justify-start flex-align-center flex-gap-1 mt-2">
			<strong>For </strong>
			<select v-model="queryVariable.embedded">
				<option :value="null">embedded AND non-embedded</option>
				<option :value="false">non-embedded</option>
				<option :value="true">embedded</option>
			</select>
			<div>
				<div v-if="!queryVariable.nodeTypes" class="center">ALL</div>
				<TagField :Source="queryVariable.nodeTypes" @remove="item => removeNodeType( item )" joinHTML="or" />
				<select v-model="selectedNodeType">
					<option :value="null">[ add node type ]</option>
					<option value="collection">Collection</option>
					<option value="question">Question</option>
					<option value="test">Test</option>
					<option value="folder">Folder</option>
					<option value="media-">Any Media</option>
					<option value="media-image">Image</option>
					<option value="media-video">Video</option>
					<option value="media-audio">Audio</option>
					<option value="media-pdf">PDF</option>
					<option value="media-text">Text</option>
				</select>
			</div>

			<strong>nodes that are</strong>

			<div>
				<div v-if="!queryVariable.nodeStates" class="center">in ANY state</div>
				<TagField :Source="queryVariable.nodeStates" @remove="item => removeNodeState( item )" joinHTML="or" />
				<select v-model="selectedNodeState">
					<option :value="null">[ add node state ]</option>
					<option value="unopened">Un-Opened</option>
					<option value="opened">Opened</option>
					<option value="exposed">Exposed (includes nodes embedded in opened nodes)</option>
					<option value="incomplete">Incomplete (opened but not completed)</option>
					<option value="complete">Complete</option>
					<option value="answered">Answered</option>
					<option value="correct">Answered Correctly</option>
					<option value="incorrect">Answered Incorrectly</option>
				</select>
			</div>
		</div>

		<div class="hr-light mt-3" />

		<div v-if="queryVariable.dim.courseID" style="max-width: 800px; margin: 3em auto;" :class="{ 'warning round-05' : lacksRootNode }">
			<div class="mb-05"><strong>Include only nodes that are children of:</strong></div>
			<NodeSearchDropdown v-model="queryVariable.rootNodes" :courseID="queryVariable.dim.courseID" width="100%" />
		</div>

		<div v-if="showNodeParams" class="hr-light mt-3" />

		<div v-if="queryVariable.dim.courseID && showNodeParams" style="max-width: 800px; margin: 3em auto;">
			<div class="mb-05"><strong>INCLUDE only nodes tagged with Taxonomy Item(s):</strong></div>
			<TaxonomySearchDropdown v-model="queryVariable.includeTaxItems" :courseID="queryVariable.dim.courseID" width="100%" />
			<div class="mt-1">
				<div v-for="tax in queryVariable.includeTaxItems" :key="tax.taxonomyID" class="flex-row flex-justify-start flex-align-center flex-gap-1 mb-2">
					<div>{{ tax.name }}:</div>
					<TaxonomyItemSearchDropdown v-model="tax.selectedTaxonomyItems" :courseID="queryVariable.dim.courseID" :taxonomy="tax" width="100%" />
				</div>
			</div>

			<div class="mt-3 mb-05"><strong>EXCLUDE nodes tagged with Taxonomy Item(s):</strong></div>
			<TaxonomySearchDropdown v-model="queryVariable.excludeTaxItems" :courseID="queryVariable.dim.courseID" width="100%" />
			<div class="mt-1">
				<div v-for="tax in queryVariable.excludeTaxItems" :key="tax.taxonomyID" class="flex-row flex-justify-start flex-align-center flex-gap-1 mb-2">
					<div>{{ tax.name }}:</div>
					<TaxonomyItemSearchDropdown v-model="tax.selectedTaxonomyItems" :courseID="queryVariable.dim.courseID" :taxonomy="tax" width="100%" />
				</div>
			</div>
		</div>


		<div v-if="isValidQuery && (queryVariable.queryPreview === null || queryVariable.queryPreviewError)" class="flex-row flex-justify-end my-3"><span class="pillButton secondary blue" @click="updateQuery()">Show DB Query</span></div>
			<div v-if="isValidQuery && queryVariable.queryPreview !== null && !queryVariable.queryPreviewError" class="my-2 round-05 border relative" style="margin: 0 auto; max-width: min(40vw, 1000px); ">
			<div class="pa-1" style="overflow: auto; white-space:nowrap; max-height: 500px;">
				<div class="bold">Query:</div>
				<div class="pa-1" v-html="queryPreview" />
				<div class="mt-1"><span class="bold">Types:</span> {{ queryVariable.queryPreview.types }}</div>
				<div class="mt-1"><span class="bold">Args:</span> {{ queryVariable.queryPreview.args }}</div>
			</div>
			<div class="simple-button" style="position: absolute; top: 0.5em; right: 2.5em;" @click="updateQuery()"><span class="icon-loop2" /></div>
			<div class="simple-button" style="position: absolute; top: 0.5em; right: 0.5em;" @click="queryVariable.queryPreview = null"><span class="icon-cross" /></div>
		</div>
		<div v-if="queryVariable.queryPreviewError" class="warning">There was an error while parsing your query!</div>

	</section>

</section>
</template>



<script>
import NodeSearchDropdown from '@/features/Courses/NodeSearchDropdown.vue'
import TaxonomySearchDropdown from '@/features/CourseCore/Taxonomies/TaxonomySearchDropdown.vue'
import TaxonomyItemSearchDropdown from '@/features/CourseCore/Taxonomies/TaxonomyItemSearchDropdown.vue'
import TagField from '@/components/utilities/TagField.vue'

export default {
	name: 'EknoStatDimensionQueryVariableEditor',


	components: {
		NodeSearchDropdown,
		TaxonomySearchDropdown,
		TaxonomyItemSearchDropdown,
		TagField,
	},


	props: {
		queryVariable: {
			type: Object,
			required: true
		},

		name: {
			type: String,
			required: true
		},
	},



	data() {
		return {
			editName: false,

			selectedNodeType: null,
			selectedNodeState: null,

			initializingName: false,
			nameInternal: null,

			showNodeParams: true,
		}
	},



	created() {
		this.initialize()
	},



	computed: {
		queryPreview() {
			if( !this.queryVariable.queryPreview ) return null
			var q = this.queryVariable.queryPreview.query.replace(/(?:\r\n|\r|\n)/g, '<br>');
			q = q.replace(/(?:\t)/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
			q = q.replace(/\?/g, '<b style="font-size: 1.25em; color: red;">?</b>')
			return q
		},

		lacksRootNode() {
			if( (this.queryVariable.field === 'exposedVideoLength' || this.queryVariable.field === 'numExposedVideos') && !this.queryVariable.rootNodes.length ) return true
			return false
		},

		isValidQuery() {
			if( !this.queryVariable.queryType || !this.queryVariable.field ) return false
			if( this.queryVariable.queryType === 'user' && ( !this.queryVariable.function || !this.queryVariable.aggregateBasis ) ) return false
			if( this.lacksRootNode ) return false
			
			return true
		},
	},



	watch: {
		'queryVariable.queryType'() {
			this.queryVariable.field = null
			this.queryVariable.aggregateBasis = null
		},

		'queryVariable.field'() {
			this.setNodeParams()
		},

		selectedNodeType( value ) {
			if( !value ) return
			this.queryVariable.addNodeType( value )
			this.selectedNodeType = null
		},

		selectedNodeState( value ) {
			if( !value ) return
			this.queryVariable.addNodeState( value )
			this.selectedNodeState = null
		},


		nameInternal( value ) {
			if( this.initializingName ) {
				this.initializingName = false
				return
			}

			this.$emit( 'updateName', value )
		},
		
		queryVariable() {
			this.initialize()
		},

	},



	methods: {

		async initialize() {
			console.debug("Initializing QueryVar:", this.name)

			// Name
			this.initializingName = true
			this.nameInternal = this.name
			
			this.setNodeParams()
		},

		setNodeParams() {
			this.showNodeParams = true
			var hideNodeParams = false
			if( !this.queryVariable.field ) hideNodeParams = true
			if( this.queryVariable.field === 'exposedVideoLength' || this.queryVariable.field === 'numExposedVideos' ) hideNodeParams = true
			

			if( hideNodeParams ) {
				this.showNodeParams = false
				this.queryVariable.embedded = null
				this.queryVariable.nodeTypes = null
				this.queryVariable.nodeStates = null
				this.queryVariable.includeTaxItems = []
				this.queryVariable.excludeTaxItems = []
			}
		},

		removeNodeType( value ) {
			this.queryVariable.removeNodeType( value )
		},

		removeNodeState( value ) {
			this.queryVariable.removeNodeState( value )
		},


		async updateQuery() {
			this.$emit('updateDBQuery')
		}


	}


}
</script>



<style scoped>
#queryVarDetails {
	border: 1px solid var(--pp10-orange);
	border-radius: 0.5em;
}
.name:hover {
	background-color: var(--ekno-blue-95);
}
</style>