import API from '@/api/API';
import {base_url} from '@/Config';
import axios from 'axios';

/**
 * Facilitates ecommerce requests.
 * @memberof module:API
 * @extends module:API.API
 */
class EcommerceAPI extends API {

  /**
   * Submits the given cart object to the server for processing.
   * @param {Object} cartJSON - object containing cart contents
   * @returns {object} Response data.
   */
  static async placeOrder(cartJSON) {
    let url = base_url + "/ecommerceAPI/placeOrder";
    let res = await axios({
      url: url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(cartJSON),
      withCredentials: true
    });

    return res.data;
  }



  static async bulkRenewalOrder(licenseKeys, userID = 0, customerID = 0) {
    let url = `${base_url}/ecommerceAPI/bulkRenewalOrder/${userID}`;
    if(customerID) url += `/${customerID}`;
  const res = await this.sendJSON(url, licenseKeys);
    return res.data;
  }



  static async fulfillOrder(orderID) {
    const res = await this.get('ecommerceAPI', 'fulfillOrder', [orderID]);
    return res.data;
  }



  static async payInvoice(orderID, payload) {
    const url = `${base_url}/ecommerceAPI/payInvoice/${orderID}`;
    const res = await this.sendJSON(url, payload);
    return res;
  }



  // A "shim" to make the methods in this file (imported from the client project) work in this project.
  static async get(controller, method, args) {
    let url = `${base_url}/${controller}/${method}`;
    for(let arg of args) url += `/${arg}`;
    let res = await axios.get(url, {withCredentials:true});
    if (this.verifyStatus(res.status)) return res;
  }



  static async getLicense(licenseKey) {
    let res = await this.get('CourseLibraryAPI', 'getLicense', [licenseKey]);
    return res.data;
  }



  /**
   * Adds a payment profile for this user.
   * @param {Object} opaqueDataValue - `opaqueDataValue` from Accept.js, (`Accept.dispatchData()`)
   * @param {Object} opaqueDataDescriptor - `opaqueDataDescriptor` from Accept.js, ()`Accept.dispatchData()`)
   * @returns {String} Payment profile ID.
   */
  static async addPaymentProfile(userID, opaqueDataValue, opaqueDataDescriptor) {
    if (!userID) {
      throw new Error('Missing property "userID".');
    } else if (!opaqueDataValue) {
      throw new Error('Missing property "opaqueDataValue".');
    } else if (!opaqueDataDescriptor) {
      throw new Error('Missing property "opaqueDataDescriptor".');
    }

    const url = `${base_url}/ecommerceAPI/addPaymentProfile`;
    const payload = {
      userID,
      opaqueDataValue,
      opaqueDataDescriptor
    }
    let res = await this.sendJSON(url, payload, false, false, false);
    return res;
  }



  /**
   * Removes one of this user's payment profiles.
   * @param {String} paymentProfileID - a payment profile ID
   * @returns {Void} Nothing.
   */
  static async removePaymentProfile(paymentProfileID) {
    if (!paymentProfileID) {
      throw new Error('Missing property "paymentProfileID".');
    }

    const url = `${base_url}/ecommerceAPI/removePaymentProfile`;
    const res = await this.sendJSON(url, {paymentProfileID}, false, true);
    return res.data;
  }



  /**
   * Fetches the Anet customer profile for this user.
   * @returns {Object} The `CustomerProfile` Object.
   */
  static async getCustomerProfile(userID) {
    if (!userID) throw new Error('Missing property "userID".');

    const url = `${base_url}/ecommerceAPI/getCustomerProfile/${userID}`;
    const res = await axios.get(url, { withCredentials: true });
    return res.data;
  }


  static async getProduct(sku) {
    const res = await this.get('ecommerceAPI', 'getProduct', [sku]);
    return res.data;
  }


  static async getUpgradeProducts(sku) {
    const res = await this.get('ecommerceAPI', 'getUpgradeProducts', [sku]);
    console.log(res.data);
    return res.data;
  }


  static async getRenewalProducts(licenseKey, isSubscription = false) {
    const args = [licenseKey];
    if(isSubscription) args.push('true');
    let res = await this.get('ecommerceAPI','getRenewalProducts', args);
    return res.data;
  }


  static async getProductImages(sku) {
    if (!sku) {
      throw new Error('Missing property "sku".');
    }

    const url = `${base_url}/SiteAdmin/MediaLibraryAPI/getProductImages/${sku}`;
    const res = await axios.get(url, { withCredentials: true });
    return res.data;
  }


  static async getPricingOption(id) {
    const res = await this.get('ecommerceAPI', 'getPricingOption', [id]);
    return res.data;
  }


  static async getSubscriptions() {
    const url = `${base_url}/ecommerceAPI/getSubscriptions`;
    const res = await axios.get(url, { withCredentials: true });
    return res.data;
  }


  static async getSubscription(licenseKey) {
    if (!licenseKey) throw new Error('Missing property "licenseKey".');
    const res = await this.get('ecommerceAPI', 'getSubscription', [licenseKey]);
    return res.data;
  }


  static async cancelSubscription(licenseKey) {
    if (!licenseKey) throw new Error('Missing property "licenseKey".');
    return await this.get('ecommerceAPI', 'cancelSubscription', [licenseKey]);
  }


  static async pauseSubscription(licenseKey) {
    if (!licenseKey) throw new Error('Missing property "licenseKey".');
    return await this.get('ecommerceAPI', 'pauseSubscription', [licenseKey]);
  }


  static async activateSubscription(licenseKey, price) {
    if (!licenseKey) throw new Error('Missing property "licenseKey".');
    const url = `${base_url}/ecommerceAPI/activateSubscription/${licenseKey}`;
    return await this.sendJSON(url, {price}, false);
  }


  static async updateSubscriptionPaymentProfile(licenseKey, paymentProfileID) {
    if (!licenseKey) {
      throw new Error('Missing property "licenseKey".');
    } else if (paymentProfileID === null || paymentProfileID === undefined) {
      throw new Error('Missing property "paymentProfileID".');
    }

    const url = `${base_url}/ecommerceAPI/updateSubscriptionPaymentMethod/${licenseKey}`;
    await this.sendJSON(url, { paymentProfileID }, false);
  }


  static async getVariationGrantingProducts(licenseKey, payload) {
    if (!licenseKey) {
      throw new Error('Missing property "licenseKey".');
    } else if (!payload || !payload.variations) {
      throw new Error('Payload is missing or empty.');
    }

    const url = `${base_url}/ecommerceAPI/getVariationGrantingProducts/${licenseKey}`;
    const res = await this.sendJSON(url, payload, false);
    return res.data;
  }


  static async getTeacherChatGrantingProducts(licenseKey) {
    if (!licenseKey) {
      throw new Error('Missing property "licenseKey".');
    }

    const url = `${base_url}/ecommerceAPI/getTeacherChatGrantingProducts/${licenseKey}`;
    const res = await axios.get(url, { withCredentials: true });
    return res.data;
  }


  static async getLandingPage(affSlug = '') {
    return await this.get('ecommerceAPI', 'getLandingPage', [affSlug]);
  }

}
export default EcommerceAPI;
