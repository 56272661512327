export default class EmailTemplate {
  constructor(filename="", subjectLine="", templateHTML="", templateText="") {
    this.filename = filename;
    this.subjectLine = subjectLine;
    this.templateHTML = templateHTML;
    this.templateText = templateText;

  }

  isSame(template) {
    return this.filename === template.filename && 
           this.subjectLine === template.subjectLine &&
           this.templateHTML === template.templateHTML &&
           this.templateText === template.templateText;
  }

  export() {
    let data = {};
    data.filename = this.filename;
    data.subjectLine = this.subjectLine;
    data.templateHTML = this.templateHTML;
    data.templateText = this.templateText;

    return data;
  }

  static import(json) {
    return new EmailTemplate(json.filename, json.subjectLine, json.templateHTML, json.templateText);
  }
}
