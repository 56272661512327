<template>

<!-- Vertical wrapper -->
<section v-if="hasAccess" class="flex-column flex-justify-between h-100 pt-1" style="max-height: calc( 100vh - 55px );">
	

	<!-- 2-Pane wrapper -->
	<section class="flex-row flex-justify-start flex-align-start flex-grow w-100">
	
	
		<!-- "Reports" & "Factors" Pane -->
		<section class="w-100 flex-column flex-gap-3" style="max-width: min(33%, 300px); max-height: calc( 100vh - 12em );" @click.stop="selectReportTemplate()">

			<!-- Reports list -->
			<div id="reportTemplates" class="flex-grow">

				<div class="flex-row flex-align-start flex-justify-between pr-1">
					<div class="icon-stack" />
					<div class="bold">Report Templates</div>
					<div id="reportI" @mouseenter="showReportInfo = true" @mouseleave="showReportInfo = false">
						<span class="icon-info" />
						<p v-if="showReportInfo" id="reportInfo">Report Templates organize the outputs from one or more "factors" and define visual formatting.</p>
					</div>
				</div>

				<div class="pr-1" style="max-height: 35vh; overflow: auto;">
					<div v-for="rpt in reportTemplates" :key="rpt.id" class="listItem flex-row flex-justify-between flex-align-center w-100 border round-05 pointer pa-05 mt-05" :class="{ 'selected' : rpt === selectedReportTemplate }" style="max-width: calc(100% - 1em);" @click.stop="selectReportTemplate(rpt)">
						<div>{{ rpt.name }}</div>
					</div>
				</div>

				<div class="center mt-1 mr-2"><button class="pillButton secondary bg" @click="showCreateReportTemplate()">Create New Report Template</button></div>
			</div>

			<!-- Factors list -->
			<div id="factors" class="flex-grow">

				<div class="flex-row flex-align-start flex-justify-between pr-1">
					<div class="icon-price-tags" />
					<div class="bold">Factors</div>
					<div id="factorI" @mouseenter="showFactorInfo = true" @mouseleave="showFactorInfo = false">
						<span class="icon-info" />
						<p v-if="showFactorInfo" id="factorInfo">"Factors" are bundles containing database queries, operations, and variables.  They output user-defined data, which can be used in reports.</p>
					</div>
				</div>

				<div class="pr-1" style="max-height: 35vh; overflow: auto;">
					<div v-for="dim in dimensions" :key="dim.id" class="listItem flex-row flex-justify-between flex-align-center w-100 border round-05 pointer pa-05 mt-05" :class="{ 'selected' : dim === selectedDimension }" style="max-width: calc(100% - 1em);" @click.stop="selectDimension(dim)">
						<div>{{ dim.name }}</div>
					</div>
				</div>

				<div class="center mt-1 mr-2"><button class="pillButton secondary bg" @click="createDimension()">Create New Factor</button></div>
			</div>
		</section>


		<EknoStatReportTemplateEditor v-if="selectedReportTemplate" :template="selectedReportTemplate" :dimensions="dimensions" />
		<EknoStatDimensionEditor v-if="selectedDimension" :dimension="selectedDimension" @updateOutputVariableName="(newName, oldName) => updateOutputVariableName(selectedDimension, newName, oldName)" @updateDBQuery="qvName => getQueryPreview(selectedDimension, qvName)" ref="dimensionEditor" />

	</section>



	<!-- Test pane (@ bottom) -->
	<section v-if="selectedDimension || selectedReportTemplate" id="testPane" class="flex-row flex-align-center flex-justify-center flex-gap-3 border-top pa-1 mt-1">

		<div>
			<div class="flex-row flex-align-center flex-gap-05">
				<!-- FILTER: Customer -->
				<CustomerSearchDropdown
					placeholder="Filter by Customer..."
					width="200px"
					ref="customerSearch"
					:dropUp="true"
					@updateCustomer="cust => selectCustomer(cust)" />

				<!-- FILTER: Cohort -->
				<CohortSearchDropdown
					:multiselect="true"
					placeholder="Filter by Cohort..."
					:cohort="cohorts"
					:customerIDs="customer ? [ customer.id ] : []"
					:dropUp="true"
					@update="cohorts => selectCohort(cohorts)"
					width="400px" />

				<!-- ENTER: LicenseKey -->
				<input v-model="licenseKey" placeholder="single license key" />
			</div>

			<div class="flex-row flex-gap-05 mt-05">
				<!-- FILTER: User -->
				<UserSearchDropdown
					placeholder="Filter by User..."
					width="200px"
					:dropUp="true"
					@updateUser="user => selectUser(user)" />
				
				<!-- FILTER: Affiliate -->
				<AffiliateSearchDropdown
					placeholder="Filter by Affiliate..."
					width="200px"
					:dropUp="true"
					@update="aff => selectAffiliate(aff)" />
				
				<select v-model="activeLicenseFilter">
					<option :value="null">Active AND Expired</option>
					<option :value="true">Active Only</option>
					<option :value="false">Expired Only</option>
				</select>
				<select v-model="archivedLicenseFilter">
					<option :value="null">Archived AND Un-archived Licenses</option>
					<option :value="true">Archived Licenses only</option>
					<option :value="false">Un-archived Licenses Only</option>
				</select>

			</div>
		</div>


		<!-- FILTER: timestamp / date -->
		<div>
			<div class="nowrap">
				<v-date-picker v-if="!timeWindowMinutes" v-model="startDate" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="Start Date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
				<input v-if="!startDate" type="number" v-model="timeWindowMinutes" placeholder="TIP window (mins)" style="max-width: 5em;" />
			</div>
			<div class="center">-</div>
			<div class="nowrap">
				<v-date-picker v-model="endDate" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
							placeholder="End ('As-of') date"
							:value="inputValue"
							v-on="inputEvents"
							style="max-width: 140px;"
						/>
					</template>
				</v-date-picker>
			</div>
		</div>

		<div class="right mt-1"><button class="SquareBlueButton" :class="{'disabled' : !canRunReport}" :disabled="!canRunReport" @click="runTestReport()">Test-Fire "{{ selectedDimension ? selectedDimension.name : selectedReportTemplate.name }}"</button></div>

	</section> <!-- /testPane -->



	<StretchModal ref="testResultModal" padding="1em" width="600px" :fullscreen="false">
		<div v-if="testResult">
			<TableReportViewer v-if="testResult.type === 'table'" :report="testResult" />
			<ThresholdReportViewer v-else-if="testResult.type === 'threshold'" :rawReport="testResult" />
		</div>
	</StretchModal>


	<StretchModal ref="newRpt" padding="1em" width="400px" :fullscreen="false">
		<h2 class="mt-0 mb-05">New Report Template</h2>
		<div>
			<select v-model="newReportTemplateType">
				<option :value="null">(select type)</option>
				<option value="table">Table</option>
				<option value="threshold">Threshold Intervention</option>
			</select>
		</div>
		<div v-if="newReportTemplate"><input v-model="newReportTemplate.name" ref="newRptName" class="w-100" placeholder="New Report Name" /></div>
		<div v-if="newReportTemplate && newReportTemplate.name" class="right mt-1"><button class="pillButton" @click="saveNewReportTemplate()">Save</button></div>
	</StretchModal>

	<StretchModal ref="newDim" padding="1em" width="400px" :fullscreen="false">
		<h2 class="mt-0 mb-05">New Factor</h2>
		<div v-if="newDimension"><input v-model="newDimension.name" ref="newDimName" class="w-100" placeholder="New Factor Name" /></div>
		<div v-if="newDimension && newDimension.name" class="right mt-1"><button class="pillButton" @click="saveNewDimension()">Save</button></div>
	</StretchModal>


	<div v-if="testReportBusy || testReportError" class="flex-row flex-justify-center flex-align-center" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 2; background-color: rgba(0,0,0, 0.25); backdrop-filter: blur(12px);">
		<div v-if="testReportBusy" class="center">
			<div class="icon-spinner4 spin1" style="font-size: 3em; transform-origin: center;" />
			<div>Running Query...</div>
		</div>
		<div v-if="testReportError" class="warning">
			<div>Sorry, that query didn't work right.</div>
			<div class="right mt-1"><button class="pillButton secondary red" @click="testReportError =false">OK</button></div>
		</div>
	</div>


</section>
<section v-else class="flex-row flex-justify-center flex-align-center h-100"><span class="icon-lock" /> You do not have access to this section &nbsp;<span class="icon-lock" /></section>
</template>



<script>
import StudentStatisticsAPI from '@/api/StudentStatisticsAPI.js'
import TableReportTemplate from './ReportTemplates/TableReportTemplate.js'
import ThresholdReportTemplate from './ReportTemplates/ThresholdReportTemplate.js'
import EknoStatDimension from './EknoStatDimension.js'

import EknoStatReportTemplateEditor from './EknoStatReportTemplateEditor.vue'
import EknoStatDimensionEditor from './EknoStatDimensionEditor.vue'
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import CustomerSearchDropdown from '@/features/SalesManagement/Customers/CustomerSearchDropdown.vue'
import CohortSearchDropdown from '@/features/Cohorts/CohortSearchDropdown.vue'
import AffiliateSearchDropdown from '@/features/SalesManagement/Affiliates/AffiliateSearchDropdown.vue'
import TableReportViewer from './ReportTemplates/TableReportViewer.vue'
import ThresholdReportViewer from './ReportTemplates/ThresholdReportViewer.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

export default {
	name: 'EknoStatBuilder',


	components: {
		EknoStatReportTemplateEditor,
    EknoStatDimensionEditor,
		UserSearchDropdown,
    CustomerSearchDropdown,
		CohortSearchDropdown,
    AffiliateSearchDropdown,
		TableReportViewer,
		ThresholdReportViewer,
		StretchModal,
	},


	data() {
		return {
			dimensions: [],
			newDimension: null,
			selectedDimension: null,

			reportTemplates: [],
			newReportTemplate: null,
			newReportTemplateType: null,
			selectedReportTemplate: null,

			showReportInfo: false,
			showFactorInfo: false,

			// Template/Dimension Testing variables
			user: null,
			customer: null,
			cohorts: [],
			licenseKey: null,
			affiliate: null,
			activeLicenseFilter: null,
			archivedLicenseFilter: null,
			timeWindowMinutes: null,
			startDate: null,
			endDate: null,

			testResult: null,
			hasAccess: false,

			testReportBusy: false,
			testReportError: false,
		}
	},



	computed: {
		canRunReport() {
			return this.user || this.customer || this.cohorts.length || this.licenseKey || this.affiliate
		},
	},



	watch: {
		newReportTemplateType( value ) {
			if( !value ) return
			this.createReportTemplate()
		}
	},



	created() {
		this.initialize()
	},


	methods: {

		async initialize() {
			if( this.$store.state.userRoles.hasAccess('ekno_stat:edit') ) this.hasAccess = true
			await this.loadDimensions()
			this.loadReportTemplates()
		},


		async loadReportTemplates() {
			const data = await StudentStatisticsAPI.getEknoStatReportTemplates()

			for( var item of data ) {
				var rt = null
				
				if( item.type === 'table' ) rt = TableReportTemplate.import( item )
				else if( item.type === 'threshold' ) rt = ThresholdReportTemplate.import( item )
				
				if( !rt ) continue
				rt.initialize( this.dimensions )
				this.reportTemplates.push( rt )
			}
		},


		showCreateReportTemplate() {
			this.$refs.newRpt.open()
		},


		createReportTemplate() {
			
			if( this.newReportTemplateType === 'table' ) this.newReportTemplate = new TableReportTemplate()
			else if( this.newReportTemplateType === 'threshold' ) this.newReportTemplate = new ThresholdReportTemplate()
			if( !this.newReportTemplate ) return

			this.$nextTick( () => {
				this.$refs.newRptName.focus()
			})
		},


		async saveNewReportTemplate() {
			this.reportTemplates.push( this.newReportTemplate )
			this.selectedReportTemplate = this.newReportTemplate
			this.newReportTemplate = null
			this.newReportTemplateType = null
			this.$refs.newRpt.close()

			// Save to server!
			const selectedRT = this.selectedReportTemplate
			const res = await StudentStatisticsAPI.saveEknoStatReportTemplate( selectedRT.export() )
			selectedRT.id = res.data.id
		},


		selectReportTemplate( rpt ) {
			this.selectedDimension = null
			this.selectedReportTemplate = rpt
		},


		async loadDimensions() {
			const data = await StudentStatisticsAPI.getEknoStatDimensions()

			for( var item of data ) {
				var dim = await EknoStatDimension.import( item )
				this.dimensions.push( dim )
			}

		},


		createDimension() {
			this.newDimension = new EknoStatDimension()
			this.$refs.newDim.open()
			this.$nextTick( () => {
				this.$refs.newDimName.focus()
			})

		},


		async saveNewDimension() {
			this.dimensions.push( this.newDimension )
			this.selectedDimension = this.newDimension
			this.newDimension = null
			this.$refs.newDim.close()
			
			// Save to server!
			const selectedDim = this.selectedDimension
			const res = await StudentStatisticsAPI.saveEknoStatDimension( selectedDim.export() )
			selectedDim.id = res.data.id
		},


		selectDimension( dim ) {
			this.selectedReportTemplate = null
			this.selectedDimension = dim
		},


		async selectUser( user = null ) {
			this.user = user
		},


		selectCustomer( customer = null ) {
			this.customer = customer
		},


		selectCohort( cohorts = [] ) {
			this.cohorts = cohorts
		},


		selectAffiliate( affiliate = null ) {
			this.affiliate = affiliate
		},


		updateOutputVariableName( dim, newName, oldName ) {
			for( var rt of this.reportTemplates ) rt.updateOutputVariableName( dim, newName, oldName )
		},


		async runTestReport() {
			this.testResult = null
			this.testReportBusy = false
			this.testReportError = false

			const req = {
				activeLicenses: this.activeLicenseFilter,
				archivedLicenses: this.archivedLicenseFilter,
			}

			if( this.selectedDimension ) {
				req.dimensions = [ this.selectedDimension.export() ]

				// ----------------------------------------------------------------
				// If a QueryVariable is selected in the UI, run the query as though that were the ONLY query variable
				// ----------------------------------------------------------------
				if( this.selectedDimension && this.$refs.dimensionEditor && this.$refs.dimensionEditor.isQueryVariableSelected() ) {
					const qvarName = this.$refs.dimensionEditor.getSelectedQueryVariableName()
					const qvar = this.$refs.dimensionEditor.getSelectedQueryVariable()
					req.dimensions[0].queryVariables = {}
					req.dimensions[0].queryVariables[ qvarName ] = qvar.export()
					
					const outputVars = {}
					eachvar: for( var ovName in req.dimensions[0].outputVariables ) {
						var ovar = req.dimensions[0].outputVariables[ ovName ]
						
						for( var operation of ovar.operations ) {
							for( var operand of operation.operands ) {
								if( typeof operand === "string" && operand !== qvarName ) continue eachvar;  // Skip this outputVar if any operand is a queryVar that isn't the one we've selected!
							}
						}

						outputVars[ ovName ] = ovar
					}

					req.dimensions[0].outputVariables = outputVars
				}

				// ----------------------------------------------------------------
				// If an OutputVariable is selected in the UI, run the query as though that were the ONLY output variable
				// ----------------------------------------------------------------
				if( this.selectedDimension && this.$refs.dimensionEditor && this.$refs.dimensionEditor.isOutputVariableSelected() ) {
					req.dimensions[0].outputVariables = {}
					req.dimensions[0].outputVariables[ this.$refs.dimensionEditor.getSelectedOutputVariableName() ] = this.$refs.dimensionEditor.getSelectedOutputVariable().export()
				}
			}

			if( this.selectedReportTemplate ) req.reportTemplateID = this.selectedReportTemplate.id
			if( this.user ) req.userIDs = [ this.user.userID ]
			if( this.customer ) req.customerIDs = [ this.customer.id ]
			if( this.cohorts.length ) req.cohortIDs = this.cohorts.map( c => c.cohortID )
			if( this.licenseKey ) req.licenseKeys = [ this.licenseKey ]
			if( this.affiliate ) req.affiliateIDs = [ this.affiliate.id ]
			if( this.timeWindowMinutes && parseInt(this.timeWindowMinutes) && !isNaN(parseInt(this.timeWindowMinutes)) ) req.timeWindow = Math.round( parseInt(this.timeWindowMinutes) * 60 )
			if( this.startDate ) req.startTime = this.startDate ? Math.floor(this.startDate.getTime()/1000) : null
			if( this.endDate )   req.endTime = this.endDate ? Math.floor(this.endDate.getTime()/1000) : null

			this.testReportBusy = true

			const res = await StudentStatisticsAPI.runEknoStatBuilderQuery( req ).catch( e => {
				this.testReportError = true
				this.testReportBusy = false
				throw e
			})
			this.testReportBusy = false
			this.testResult = res.data
			this.$refs.testResultModal.open()
		},


		async getQueryPreview( dimension, queryVariableName ) {
			const dim = dimension.export()
			const qv = dimension.getQueryVariable( queryVariableName )
			if( !qv ) return
			
			qv.queryPreviewError = false
			
			dim.outputVariables = {}
			dim.queryVariables = {}
			dim.queryVariables[queryVariableName] = qv.export()


			const req = {
				dimensions: [ dim ],
				activeLicenses: this.activeLicenseFilter,
				archivedLicenses: this.archivedLicenseFilter,
			}

			if( this.user ) req.userIDs = [ this.user.userID ]
			if( this.customer ) req.customerIDs = [ this.customer.id ]
			if( this.cohorts.length ) req.cohortIDs = this.cohorts.map( c => c.cohortID )
			if( this.licenseKey ) req.licenseKeys = [ this.licenseKey ]
			if( this.affiliate ) req.affiliateIDs = [ this.affiliate.id ]
			if( this.timeWindowMinutes && parseInt(this.timeWindowMinutes) && !isNaN(parseInt(this.timeWindowMinutes)) ) req.timeWindow = Math.round( parseInt(this.timeWindowMinutes) * 60 )
			if( this.startDate ) req.startTime = this.startDate ? Math.floor(this.startDate.getTime()/1000) : null
			if( this.endDate )   req.endTime = this.endDate ? Math.floor(this.endDate.getTime()/1000) : null

			StudentStatisticsAPI.getEknoStatVariableQueryPreview( req )
			.then( res => {
				qv.queryPreview = res.data
			}).catch( e => {
				qv.queryPreview = null
				qv.queryPreviewError = true
			})


		},

	}

}
</script>



<style scoped>
#reportI, #factorI {
	position: relative;
}

#reportInfo, #factorInfo {
	position: absolute;
	right: 0.5em;
	top: 0.5em;
	width: 250px;
	background-color: white;
	border: 1px solid black;
	border-radius: 0.5em;
	padding: 1em;
	box-shadow: 3px 3px 15px -4px #888;
	z-index: 2;
	text-align: justify;
}


.listItem {
	position: relative;
}
.listItem:hover {
	background-color: var(--ekno-blue-95);
}
.listItem.selected {
	background-color: var(--ekno-blue);
	color: white;
}
.listItem.selected:after {
	content: '';
	position: absolute;
	/* width: 2.25em; */
	height: 100%;
	/* background-color: pink; */
	right: -1.75em;
	z-index: 1;
	border: 1em solid transparent;
	border-left: 1em solid var(--ekno-blue);
}

#factors .listItem.selected {
	background-color: var(--pp10-purple);
}
#factors .listItem.selected:after {
	border-left-color: var(--pp10-purple);
}

#testPane {
	background-color: #f3f3f3;
	box-shadow: 0 0 18px -5px #888;
}

</style>