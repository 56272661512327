<template>
<section>
	<div>ID: {{ item.id }}</div>
	<div class="mt-1">client IP: {{ item.clientIP }}</div>
	<div>Request URL: {{ item.requestFQDN }}</div>
	<div>Response URL: {{ item.destinationFQDN }}</div>
	<div>Response Code: {{ item.responseCode }}</div>
	<div>Time: {{ item.timeUpdated }}</div>
	<div class="mt-1">
		<b>Request Headers:</b>
		<div v-for="(value, key) of item.requestHeaders" :key="key" class="ml-1">{{ key }}: {{ value }}</div>
	</div>

	<div class="flex-row flex-align-start flex-justify-center flex-gap-3 mt-3">
		<div><b>Raw Request:</b><br>{{ item.rawRequest || 'N/A' }}</div>
		<div><b>Raw Response:</b><br>{{ item.rawResponse }}</div>
	</div>

</section>
</template>



<script>
export default {
	name: "ProxyTrafficDetails",


	props: {
		item: Object,
	}
}
</script>



<style scoped>

</style>