import OrderedSet from './OrderedSet'
import License from '../models/License'

export default class LicenseSet extends OrderedSet {
  constructor(objects=[], orderAttribute='', numPages=1, numLicenses=null) {
    super(objects, orderAttribute);
    this.numPages = numPages;
		this.numLicenses = numLicenses
  }

  static import(json) {
    const objects = [];
    const orderAttribute = '';
    const numPages = json.num_pages || json.pages || 0;
		const numLicenses = json.numLicenses || json.count || null

    const licenses = json.licenses || json.data
    for (let l of licenses) objects.push( License.import(l) );
    return new LicenseSet(objects, orderAttribute, numPages, numLicenses);
  }
}
