class Commission {

	constructor() {
		this.commissionID = 0
		this.name = null
		this.defaultLength = null		// time in SECONDS
		this.rate = 0
		this.dateEarned = 'POReceived'
		this.datePaid = 'Paid'

		// Commission Assignment props
		this.salesRepID = null
		this.startDate = null			// DATE
		this.endDate = null				// DATE
		this.affiliateID = null
		this.customerID = null
		this.paidDate = null			// DATE actually paid
	}


	get startDateTS() {
		if(this.startDate === null) return null
		return Math.round(this.startDate.getTime() / 1000)
	}

	get startDateString() {
		if(this.startDate === null) return null;
		return this.startDate.toLocaleString();
	}

	get startDateOnlyString() {
		if(this.startDate === null) return null;
		return this.startDate.toLocaleDateString();
	}

	get endDateTS() {
		if(this.endDate === null) return null
		return Math.round(this.endDate.getTime() / 1000)
	}

	get endDateString() {
		if(this.endDate === null) return null;
		return this.endDate.toLocaleString();
	}

	get endDateOnlyString() {
		if(this.endDate === null) return null;
		return this.endDate.toLocaleDateString();
	}

	get paidDateString() {
		console.debug(this)
		if(this.paidDate === null) return null;
		return this.paidDate.toLocaleString();
	}

	get paidDateOnlyString() {
		console.debug(this)
		if(this.paidDate === null) return null;
		return this.paidDate.toLocaleDateString();
	}

	get isPaid() { return this.paidDate !== null }



	get defaultLengthDays() {
		return Math.round( this.defaultLength / 86400 )
	}

	set defaultLengthDays(value) {
		this.defaultLength = value * 86400
	}




	clone() {
		return this.constructor.import(this.export());
	}


	isSame(obj) {
		return JSON.stringify(this) == JSON.stringify(obj);
	}
	

	static import(obj) {
		const item = new this()
		for(let prop in obj) {
			if(item[prop] !== undefined) item[prop] = obj[prop];
		}

		if( obj.startDate !== null && obj.startDate !== undefined ) item.startDate = new Date(obj.startDate * 1000)
		if( obj.endDate !== null && obj.endDate !== undefined )     item.endDate = new Date(obj.endDate * 1000)
		if( obj.paidDate !== null && obj.paidDate !== undefined )   item.paidDate = new Date(obj.paidDate * 1000)
		return item
	}


  export() {
		var obj = {}
		for(let prop in this) {
			if(this[prop] !== undefined) obj[prop] = this[prop]
		}

		if( this.startDate !== null && this.startDate !== undefined ) obj.startDate = Math.round(this.startDate.getTime() / 1000)
		if( this.endDate !== null && this.endDate !== undefined )     obj.endDate = Math.round(this.endDate.getTime() / 1000)
		if( this.paidDate !== null && this.paidDate !== undefined )   obj.paidDate = Math.round(this.paidDate.getTime() / 1000)

		return obj
	}

}

export default Commission
