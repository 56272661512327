<template>
<section>

	<!-- Controls / Top Matter -->
	<section class="flex-row flex-align-center flex-justify-center flex-gap-3 mt-1">
		<div>
			Source:
			<select v-model="selectedSource">
				<option :value="null">All Sources</option>
				<option :value="null" disabled>--------------------</option>
				<option v-for="source in messageSources" :key="source">{{ source }}</option>
			</select>

			<div class="buttons-list mt-1 flex-row flex-align-center flex-justify-center">
				<button class="email" :class="{ 'active' : email }" @click.stop="email = !email">E-Mail</button>
				<button class="sms" :class="{ 'active' : sms }" @click.stop="sms = !sms">SMS</button>
				<button class="app" :class="{ 'active' : app }" @click.stop="app = !app">App</button>
			</div>
		</div>


		<section id="searchArea">

			<!-- FILTER: timestamp / date -->
			<div class="center mb-05">
				<v-date-picker v-model="startDate" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
						placeholder="Start Date"
						:value="inputValue"
						v-on="inputEvents"
						/>
					</template>
				</v-date-picker> - 
				<v-date-picker v-model="endDate" mode="dateTime">
					<template v-slot="{ inputValue, inputEvents }">
						<input
						placeholder="End Date"
						:value="inputValue"
						v-on="inputEvents"
						/>
					</template>
				</v-date-picker>
			</div>
			
			<div class="flex-row flex-align-center flex-gap-05 pa-05 bg-gray-90 round-05">
				From:
				<select v-model="fromType">
					<option value="self">Me</option>
					<option value="support">support@eknowledge.com</option>
					<option value="" disabled>----------------</option>
					<option :value="null">All</option>
					<option value="" disabled>----------------</option>
					<option value="user">Other User</option>
					<option value="text">Non-User</option>
				</select>
				<div v-if="fromType == 'self'">{{ fromUser ? `${fromUser.firstName} ${fromUser.lastName}` : 'none' }}</div>
				<UserSearchDropdown v-else-if="fromType == 'user'" v-model="fromUser" />
				<div v-else-if="fromType == 'support'">{{ fromText ? fromText : 'none' }}</div>
				<div v-else-if="fromType == 'text'" class="flex-grow"><input type="text" v-model="fromText" placeholder="E-Mail address or Phone Number" /></div>
			</div>

			<div class="flex-row flex-align-center flex-gap-05 pa-05 mt-05 bg-gray-90 round-05">
				To:
				<select v-model="toType">
					<option :value="null">All</option>
					<option value="" disabled>--------------</option>
					<option value="user">User</option>
					<option value="text">Non-User</option>
				</select>
				<UserSearchDropdown v-if="toType == 'user'" v-model="toUser" />
				<div v-else-if="toType == 'text'" class="flex-grow"><input type="text" v-model="toText" placeholder="E-Mail address or Phone Number" /></div>
			</div>
		</section>
	</section>


	<!-- Message Lists -->
	<section v-if="messages && messages.length">
		<div v-for="(row, idx) in messages" :key="row.timestampMinute" :style="{ marginTop : idx > 0 && messages[idx].getDate().getDate() !== messages[idx - 1].getDate().getDate() ? '15em' : '' }">

			<div class="mt-3 flex-row flex-align-center flex-gap-1">
				<div class="border-top flex-grow" />
				<div class="color-gray-50">{{ row.getDate().toLocaleString() }}</div>
				<div class="border-top flex-grow" />
			</div>

			<div class="flex-row flex-gap-05" style="max-width: calc( 100vw - 250px - 2em )">
				<div v-if="email" :style="{ width: Math.round( 100 / (0 + email + sms + app) ) + '%' }">
					<div v-if="row.email.length" class="center flex-row flex-gap-1 flex-align-center flex-justify-center">
						<span>Emails:</span>
						<span>{{ row.email.length }} sent</span>
						<span>{{ row.numEmailsDelivered }} delivered</span>
						<span>{{ row.numEmailsOpened }} opened
						</span>
					</div>
					<MessageRow v-for="message in row.email" :message="message" :key="message.id" :selectedMessage="selectedMessage" class="mt-05" @open="openMessage( message )" />
				</div>
				<div v-if="sms" :style="{ width: Math.round( 100 / (0 + email + sms + app) ) + '%' }">
					<div v-if="row.sms.length" class="center">{{ row.sms.length }} SMS{{ row.sms.length == 1 ? '' : '\'s' }}</div>
					<MessageRow v-for="message in row.sms" :message="message" :key="message.id" :selectedMessage="selectedMessage" class="mt-05" @open="openMessage( message )" />
				</div>
				<div v-if="app" :style="{ width: Math.round( 100 / (0 + email + sms + app) ) + '%' }">
					<div v-if="row.app.length" class="center">{{ row.app.length }} in-app message{{ row.app.length == 1 ? '' : 's' }}</div>
					<MessageRow v-for="message in row.app" :message="message" :key="message.id" :selectedMessage="selectedMessage" class="mt-05" @open="openMessage( message )" />
				</div>
			</div>
		</div>
		<Paginator :value="page" :numPages="pages" @input='p => this.page = p' />
	</section>
  <div v-else-if="loading" class="flex-row flex-justify-center"><span class="icon-spinner4 spin-loader ml-05" /></div>
  <div v-else-if="error" class="warning">Failed to get messages</div>
  <div v-else class="NoResults">No results</div>



	<StretchModal ref="selectedMessageModal" padding="1em" width="auto" @close="selectedMessage = null">

		<template #header>
			<div v-if="selectedMessage.deliveryMethod == 'email'">E-Mail Message</div>
			<div v-else-if="selectedMessage.deliveryMethod == 'sms'">SMS Message</div>
			<div v-else-if="selectedMessage.deliveryMethod == 'app'">In-App Message</div>
		</template>

		<MessageDetails v-if="selectedMessage" :message="selectedMessage" />

  </StretchModal>


</section>
</template>



<script>
import MessageRow from './MessageRow.vue'
import MessageDetails from './MessageDetails.vue'
import UserSearchDropdown from '@/features/Users/UserSearchDropdown.vue'
import Paginator from '@/components/utilities/Paginator.vue'
import StretchModal from '@/components/utilities/StretchModal.vue'

import MessageCenterAPI from '@/api/MessageCenterAPI.js'
import PaginatedRequest from '@/api/PaginatedRequest.js'
import MessageTimeline from './MessageTimeline.js'
import Tooltips from '@/libraries/Tooltips/Tooltips.js'


export default {
	name: 'SentMessages',


	components: {
		MessageRow,
		MessageDetails,
		UserSearchDropdown,
		Paginator,
		StretchModal,
	},


	data() {
		return {

			loading: false,
			error: false,

			page: 1,
			pages: 1,
			pageSize: 100,
			count: 0,

			email: true,
			sms: true,
			app: true,

			startDate: null,
			endDate: null,

			selectedSource: null,
			messageSources: [],

			selectedMessage: null,
			messages: [],

			fromType: null,
			fromUser: null,
			fromText: null,

			toType: null,
			toUser: null,
			toText: null,

			fromTextTimeoutID: null,
			toTextTimeoutID: null,
		}
	},



	computed: {
		t() { return Tooltips },
	},



	watch: {
		startDate() { this.page = 1; this.getMessages() },
		endDate() { this.page = 1; this.getMessages() },
		selectedSource() { this.page = 1; this.getMessages() },
		email() { this.page = 1; this.getMessages() },
		sms() { this.page = 1; this.getMessages() },
		app() { this.page = 1; this.getMessages() },
		page() { this.getMessages() },

		fromType( value ) {
			this.page = 1
			this.fromUser = null
			this.fromText = null

			if( value === 'self' ) this.fromUser = this.$store.state.user
			if( value === 'support' ) this.fromText = 'support@eknowledge.com'
		},

		fromUser() { this.getMessages() },
		fromText() {
			if( this.fromTextTimeoutID ) {
				clearTimeout( this.fromTextTimeoutID )
				this.fromTextTimeoutID = null
			}

			this.fromTextTimeoutID = setTimeout( () => this.getMessages(), 500 )
		},

		toType() {
			this.page = 1
			this.toUser = null
			this.toText = null
		},

		toUser() { this.getMessages() },
		toText() {
			if( this.toTextTimeoutID ) {
				clearTimeout( this.toTextTimeoutID )
				this.toTextTimeoutID = null
			}

			this.toTextTimeoutID = setTimeout( () => this.getMessages(), 500 )
		}
	},



	created() {
		this.getMessageSources()
		this.getMessages()
	},



	methods: {

		async getMessageSources() {
			const req = new PaginatedRequest( null, null, this.page, this.pageSize )
			req.email = true
			req.sms = true
			req.app = true

			const res = await MessageCenterAPI.getSentMessageSources( req )
			this.messageSources = res.data
		},


		async getMessages() {
			this.loading = true

			this.messages = []
			const timeline = new MessageTimeline()

			try {
				const req = new PaginatedRequest( 'sendTime', false, this.page, this.pageSize )
				req.email = this.email
				req.sms = this.sms
				req.app = this.app
				req.source = this.selectedSource

				if( this.startDate ) req.startTime = Math.round( this.startDate.getTime() / 1000 )
				if( this.endDate ) req.endTime = Math.round( this.endDate.getTime() / 1000 )

				if( this.fromText ) req.fromAddress = this.fromText
				if( this.fromUser ) req.fromUserID = this.fromUser.userID

				if( this.toText ) req.toAddress = this.toText
				if( this.toUser ) req.toUserID = this.toUser.userID
				
				const emailPromise = req.email ? MessageCenterAPI.getSentEmails( req ) : null
				const smsPromise = req.sms ? MessageCenterAPI.getSentSMS( req ) : null
				const appPromise = req.app ? MessageCenterAPI.getSentApp( req ) : null

				var prEmail, prSMS, prApp
				[ prEmail, prSMS, prApp ] = await Promise.all( [ emailPromise, smsPromise, appPromise ] )

				this.pages = Math.max( prEmail ? prEmail.pages : 0, prSMS ? prSMS.pages : 0, prApp ? prApp.pages : 0 )
				this.count = Math.max( prEmail ? prEmail.count : 0, prSMS ? prSMS.count : 0, prApp ? prApp.count : 0 )

				if( prEmail ) {
					for( let item of prEmail.data ) {
						if( item.sendTime ) item.sendTime = new Date( item.sendTime * 1000 )
						timeline.addMessage( item )

						// Import "open" timestamps into js Dates
						for( let to of [ ...item.to, ...item.cc, ...item.bcc ] ) {
							if( to.opens && to.opens.length > 0 ) {
								const newOpenDates = []
								for( let ts of to.opens ) newOpenDates.push( new Date( ts * 1000 ) )
								to.opens = newOpenDates
							}
						}
					}
				}

				if( prSMS ) {
					for( let item of prSMS.data ) {
						if( item.sendTime ) item.sendTime = new Date( item.sendTime * 1000 )
						timeline.addMessage( item )
					}
				}

				if( prApp ) {
					for( let item of prApp.data ) {
						if( item.sendTime ) item.sendTime = new Date( item.sendTime * 1000 )
						if( item.readTime ) item.readTime = new Date( item.readTime * 1000 )
						timeline.addMessage( item )
					}
				}

				this.messages = timeline.getRows()

			} catch( e ) {
				this.error = true
				console.error( e )

			} finally {
				this.loading = false
			}
		},


		openMessage( message ) {
			this.selectedMessage = message
			this.$refs.selectedMessageModal.open()
		},

	}
}
</script>



<style scoped>

.buttons-list button {
	padding: 0.75em 1.25em;
}

.buttons-list button.email.active {
	background-color: var(--pp10-orange-95);
	border-color: var(--pp10-orange);
	color: var(--pp10-orange-35);
}

.buttons-list button.sms.active {
	background-color: var(--pp10-green-pastel-80);
	border-color: var(--pp10-green-20);
	color: var(--pp10-green);
}

.buttons-list button.app.active {
	background-color: var(--pp10-purple-95);
	border-color: var(--pp10-purple);
	color: var(--pp10-purple);
}


#searchArea {
	min-width: 600px;
	max-width: 75vw;
}


input[type="text"] {
	padding: 0.25em;
	min-height: 2em;
	width: 100%;
}



.previewTitle {
  background-color: var(--pp10-purple);
  color: white;
  font-weight: bold;
  padding: 0.5em 1em;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.emailMessage {
  min-width: min( 600px, 90vw );
  max-width: 1000px;
}

.tag {
	padding: 0.5em;
	border-radius: 0.5em;
}

.statusTag {
	background-color: var(--pp10-red-95);
	color: var(--pp10-red);
	border: 1px solid var(--pp10-red);
}
.statusTag.goodStatus {
	background-color: var(--pp10-green-95);
	color: var(--pp10-green);
	border: 1px solid var(--pp10-green);
}

.complaintTag {
	background-color: var(--pp10-orange-95);
	color: var(--pp10-orange-35);
	border: 1px solid var(--pp10-orange);
}

.smsBubble {
  background: var(--ekno-blue);
  color: white;
  padding: 0.5em;
  margin: 3em 1em 1em 3.5em;
  border-radius: 0.5em;
  min-width: 200px;
  max-width: 250px;
}

</style>