import Exim from '@/models/Exim'



class Subscription extends Exim {

  subscriptionID = null
  licenseKey = '';
  baseSKU = '';
  upgradeSKUs = [];
  userID = null;
  firstName = '';
  lastName = '';

  startTimestamp = null;
  resumeTimestamp = null;
  frequency = 1;
  frequencyUnit = 'months';
  frequencySingularUnit = 'month';
  completedOccurrences = 0
  totalOccurrences = 9999;

  previousBillingTimestamp = null;
  nextBillingTimestamp = null;

  _price = 0;
  status = null;
  transactions = [];
  orderIDs = [];

  AnetCustomerProfileID = null;
  AnetPaymentProfileID = '';
  subscriptionObject = {};
  pricingDivisor = 1;



  get price() {
    if(this._price) return this._price;

    let price = 0;
    for(let sku in this.subscriptionObject) {
      price += this.subscriptionObject[sku].price;
    }
    return price;
  }


  get dividedPrice() {
    return this.price / this.pricingDivisor
  }


  get skus() {
    let skus = [];
    for(let sku in this.subscriptionObject) skus.push(sku);
    return skus;
  }


  get percentComplete() {
    return Math.round( (this.completedOccurrences / this.totalOccurrences) * 10000 ) / 100
  }


  static import(obj) {
    if (!obj.subscriptionID) throw new Error('Missing property "subscriptionID".')

    const sub = super.import(obj)
    if( sub.startTimestamp !== null ) sub.startTimestamp = new Date( sub.startTimestamp * 1000 )
    if( sub.resumeTimestamp !== null ) sub.resumeTimestamp = new Date( sub.resumeTimestamp * 1000 )
    if( sub.previousBillingTimestamp !== null ) sub.previousBillingTimestamp = new Date( sub.previousBillingTimestamp * 1000 )
    if( sub.nextBillingTimestamp !== null ) sub.nextBillingTimestamp = new Date( sub.nextBillingTimestamp * 1000 )

    return sub

    // const subscription = new Subscription(obj.subscriptionID);
    // if (obj.licenseKey) subscription.licenseKey = obj.licenseKey;
    // if (obj.baseSKU) subscription.baseSKU = obj.baseSKU;
    // if (obj.upgradeSKUs && obj.upgradeSKUs.length) subscription.upgradeSKUs = obj.upgradeSKUs;
    // if (obj.userID) subscription.userID = obj.userID;
    // if (obj.firstName) subscription.firstName = obj.firstName;
    // if (obj.lastName) subscription.lastName = obj.lastName;
    // if (obj.startTimestamp) subscription.startTimestamp = obj.startTimestamp;
    // if (obj.resumeTimestamp) subscription.resumeTimestamp = obj.resumeTimestamp;
    // if (obj.frequency) subscription.frequency = obj.frequency;
    // if (obj.frequencyUnit) subscription.frequencyUnit = obj.frequencyUnit;
    // if (obj.frequencySingularUnit) subscription.frequencySingularUnit = obj.frequencySingularUnit;
    // if (obj.totalOccurrences) subscription.totalOccurrences = obj.totalOccurrences;
    // if (obj.price) subscription._price = obj.price;
    // if (obj.status) subscription.status = obj.status;
    // if (obj.transactions) subscription.this.transactions = obj.transactions;
    // if (obj.AnetCustomerProfileID) subscription.AnetCustomerProfileID = obj.AnetCustomerProfileID;
    // if (obj.AnetPaymentProfileID) subscription.AnetPaymentProfileID = obj.AnetPaymentProfileID || '';
    // if (obj.subscriptionObject) subscription.subscriptionObject = obj.subscriptionObject;
    // if (obj.pricingDivisor) subscription.pricingDivisor = obj.pricingDivisor;

    // return subscription;
  }
}

export default Subscription;
